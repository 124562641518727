import React from 'react'
import {useState} from 'react'
import TableCustom from 'src/components/Table/CustomTable'
import PaymentService from '../../../services/PaymentService'
import {useEffect} from 'react'
import http from "../../../services";
import { PAYMENT_TYPE } from 'src/constants/constants'
export const HistoryPayment = () => {
    const url = window.location.href;
    const [dataSource, setDataSource] = useState([])
    const [total, setTotal] = useState(0)
    const getListPayment = () => {
        PaymentService.historyPayment({
            ...pagination,
        })
            .then(res => {
                if (res?.isError) return
                setDataSource(res?.object?.list)
                setTotal(res?.object?.total)
            })
    }

    useEffect(() => {
        getListPayment()
    }, [])

    /**
     * @description set title tab web
     * @author hddung
     */
    useEffect(() => {
        if(url) {
            document.title = 'Lịch sử giao dịch'; // Thay đổi title thành 'New Title'
        
            return () => {
            document.title = 'TẠP HÓA SỐ'; // Đặt lại title khi component bị unmount
            };
        }
    }, [url]);

    /**
     * @description format date
     * @author HDDUNG
     */
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            time = d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-') + ` ${time}`;
    };

    /**
     * @description Format số tiền
     * @author hddung 
     */
    const formatCurrency = (value) => {
        return new Intl.NumberFormat('vi-VN', {
            style: 'currency',
            currency: 'VND',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value).replace('VND', 'đ');
    };


    const [pagination, setPagination] = useState({
        PageSize: 50,
        CurrentPage: 1,
    })
    const columns = [
        {
            title: "STT",
            dataIndex: "id",
            key: "id",
            width: 60,
            render: (val, record, idx) => (
                <div className="text-center">
                    {idx + 1 + pagination.PageSize * (pagination.CurrentPage - 1)}
                </div>
            ),
            align: "center",
        },
        {
        title: "Mã giao dịch",
        dataIndex: "transactionID",
        key: "transactionID",
        width: 200,
        align: "left",
        },
        // {
        // title: "Tên tài khoản",
        // dataIndex: "userName",
        // key: "userName",
        // width: 200,
        // align: "left",
        // render: value => <strong>{value}</strong>,
        // },
        {
        title: "Số tiền",
        dataIndex: "money",
        key: "money",
        width: 150,
        align: "right",
        render: value => formatCurrency(value),
        },
        {
        title: "Nội dung",
        dataIndex: "transactionContent",
        key: "transactionContent",
        width: 300,
        align: "left",
        },
        {
        title: "Thời gian chuyển khoản",
        dataIndex: "transactionTime",
        key: "transactionTime",
        width: 150,
        align: "center",
        render: value => formatDate(value),
        },
        {
        title: "Thời gian nạp tiền",
        dataIndex: "createDate",
        key: "createDate",
        width: 150,
        align: "center",
        render: value => formatDate(value),
        },
        {
        title: "Hình thức nạp",
        dataIndex: "type",
        key: "type",
        width: 140,
        align: "center",
        render: (value) => (<strong>{value == PAYMENT_TYPE.VIETCOMBANK ? 'Vietcombak' : 'VNPay'}</strong>)
        },
    ]
    return (
        <div className="responsive-table-container"style={{minHeight:650}}>
            <div className="p-24 flex-wrap" style={{width:'100%',marginBottom:50}}>
                <h3>Lịch sử giao dịch</h3>
            </div>

            <TableCustom
                isPrimary
                columns={columns}
                dataSource={dataSource}
                sticky={{offsetHeader: 60}}
                style={{marginBottom: 4}}
                pagination={{
                    hideOnSinglePage: total <= 10,
                    current: pagination.CurrentPage,
                    PageSize: pagination.PageSize,
                    responsive: true,
                    total,
                    locale: {items_per_page: ''},
                    showSizeChanger: total > 10,
                    onChange: (page, PageSize) => {
                        setPagination({
                            ...pagination,
                            CurrentPage: page,
                            PageSize,
                        });
                        getListPayment({...pagination, CurrentPage: page, PageSize});
                    },
                }}
                showPagination
                rowKey={record => `${record?.roleId}${record?.LastUpdate}`}
                footerLeft={<div className="d-flex mt-20"/>}
                widthScroll={1200}
                textEmpty="Không có lịch sử giao dịch nào!"

            />
        </div>
    )
}
