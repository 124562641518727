import { Col, Form, Row, Space, TreeSelect, message } from "antd"
import { useState } from "react"
import { useSelector } from "react-redux"
import CustomModal from "src/components/Modal/CustomModal"
import SpinCustom from "src/components/Spin"
import SvgIcon from "src/components/SvgIcon"
import UploadCustom from "src/components/Upload"
import { downloadFileBlob, downloadFromUrl } from "src/lib/base64"
import UserService from "src/services/UserService"
import { apiImportUser } from "src/services/UserService/urls"
import { ImportStyled } from "../styled"
import Button from "src/components/MyButton/Button"
import ButtonCircle from "src/components/MyButton/ButtonCircle"
import TableCustom from "src/components/Table/CustomTable"
import TextArea from "antd/es/input/TextArea"
import { useEffect } from "react"
import ProductionService from "src/services/ProductionService"
import CommonService from "src/services/CommonService"
import { apiImportInventoryByProductItem } from "src/services/ProductionService/urls"
import UploadSingle from "src/components/UploadSingle"
import ProductService from "src/services/ProductionService"
import Notice from "src/components/Notice"

const ImportInventory = ({ onOk, reloadTable, ...props }) => {
  const { importLoading } = useSelector(state => state.common);
  const [loading, setLoading] = useState(false);
  const [listProduct, setListProduct] = useState([]);
  const [listAllProductParent, setListAllProductParent] = useState([]); // list product phục vụ đển prevent việc chọn
  const [selectedValue, setSelectedValue] = useState(null);
  const [form] = Form.useForm();

  //#region useEffect

  useEffect(() => {
    getListProduct();
  }, [])
  
  //#endregion
    
  //#region function

  /**
     * @description lấy danh sách sản phẩm
     * @author hddung
     */
  async function getListProduct() {
    const res = await ProductionService.getAllProductAndProductItem();
    if(res?.isError) return
    const arrData = res?.object?.list || [];
    setListProduct(
        arrData.filter(x => x.lv == 1).map(x => ({
            value: x.id, 
            title: x.objectName, 
            children: getChildrenArr(x.id, arrData.filter(x => x.lv != 1))
        }))
    );
    setListAllProductParent(arrData.filter(x => x.lv == 1));
}

/**
 * @description đệ quy lấy ra chidrent của từng node
 * @author hddung
 */
function getChildrenArr(parentID, data){
    const lstChildren = data.filter(x => x.parentID == parentID);
    
    return data?.length > 0 
    ? 
    //đệ quy tiếp tục với những node k thuộc parentID
    lstChildren.map(x => ({
        value: x.id, 
        title: x.objectName, 
        children: getChildrenArr(x.id, data.filter(x => x.parentID != parentID))
    })) 
    : []; 
}

  const getTemplateUpload = () => {
    setLoading(true)
    CommonService.getSystemKey('IMPORT_INVENTORY_FILE')
      .then(res => {
        if (res?.isError) return
        downloadFromUrl(res?.object[0]?.description, "Mẫu danh sách tồn kho");
      })
      .finally(() => {
        setLoading(false)
      })
  }

  /**
   * @description sử lý import inventory khi nhấn lưu
   * @author hddung
   */
  function onFinish() {
    form.validateFields().then( async values=>{
        setLoading(true);
        const formData = new FormData();
        formData.append('file', values.file.fileList[0].originFileObj);
        const res = await ProductService.importInventoryByProductItem(values.ProductItemId, formData);
        if(res?.isError) return

        Notice({msg: `Bạn đã tải file thành công`});
        onOk && onOk();
        props?.onCancel();
        reloadTable();
    }).finally(() => {
      setLoading(false);
    })
  };

  const renderFooter = () => (
    <div className="d-flex justify-content-flex-end">
        <Button
            className="btn-hover-shadow"
            onClick={() => props?.onCancel()}
        >
            Đóng
        </Button>
        <Button
          btnType="primary"
          className="btn-hover-shadow"
          onClick={() => onFinish()}
          disabled={loading}
        >
          Lưu
        </Button>
    </div>
  )

  //#endregion
  
  return (
    <CustomModal
      title="Nhập excel"
      footer={renderFooter()}
      width={824}
      {...props}
      style={{padding: '20px 20px 0px 10px'}}
    >
      <SpinCustom spinning={loading}>
        <ImportStyled>
        <Form
            form={form}
            layout="vertical"
        >
          <Row gutter={[16, 16]} className="justify-content-center">
            <Col span={24}>
                <Form.Item
                    label="Phân loại sản phẩm"
                    name="ProductItemId"
                    rules={[
                    {
                        required: true,
                        message: "Vui lòng chọn phân loại trước",
                    },
                    ]}
                    valuePropName="select"
                >
                    {listProduct?.length > 0 &&
                        <TreeSelect
                            showSearch
                            treeNodeFilterProp="title"
                            style={{
                                width: '100%',
                            }}
                            dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                            }}
                            placeholder="Chọn phân loại sản phẩm"
                            allowClear
                            treeDefaultExpandAll
                            onChange={(record) => {
                                if(listAllProductParent.find(x => x.id == record)) {
                                    setSelectedValue(null);
                                    return
                                }
                                setSelectedValue(record);
                            }}
                            treeData={listProduct}
                        />
                    }
                </Form.Item>
            </Col>
          </Row>
          <SpinCustom spinning={!!importLoading}>
            <div style={{ marginBottom: 10 }}>
              Tải file mẫu{" "}
              <span
                onKeyPress={() => {}}
                onClick={getTemplateUpload}
                style={{ color: "#154398", cursor: "pointer" }}
              >
                Tại đây
              </span>
            </div>
            <Form.Item 
              name="file" 
              label="Upload File" 
              style={{marginBottom: 20}}
              valuePropName="file"
              rules={[
                  {
                      required: true,
                      message: 'Xin hãy upload file của bạn',
                  },
              ]}
            >
              <UploadSingle maxCount={1}/>
              </Form.Item>
          </SpinCustom>{" "}
        </Form>
        </ImportStyled>
      </SpinCustom>
    </CustomModal>
  )
}

export default ImportInventory