import CustomModal from "../../../../components/Modal/CustomModal";
import SpinCustom from "../../../../components/Spin";
import {Col, DatePicker, Divider, Form, Input, InputNumber, Row, Select,Radio} from "antd";
import Button from "../../../../components/MyButton/Button";
import Styled from "../../../../components/StatusLabel/Styled";
import React, {useState} from "react";
import {GUIDE_EMPTY, PROMOTE_CODE_TYPE} from "../../../../constants/constants";
import {ProductSearch} from "./ProductSearch";
import {UserSelectedList, UsersSelected} from "../../ListUser/modal/UserSelectedList";
import http from "../../../../services";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import moment from "moment/moment";
import {RadioChangeEvent} from "antd";


export const VoucherCreate = ({id, open, cancel,handleRefreshUpdate}) => {

    const [form] = Form.useForm();
    const categories: TabsProps['categoriesItem'] = [

        {
            value: PROMOTE_CODE_TYPE.VOUCHER,
            label: 'Mã giảm giá',
        },
        {
            value: PROMOTE_CODE_TYPE.MA_GIA,
            label: 'Mã giá',
        }
    ];
    const userModeList = [
        { label: 'Tất cả', value: 0 },
        { label: 'Tùy chọn', value: '1' },
    ];
    const [loading, setLoading] = useState(false);
    const [usersParam, setUsersParam] = useState([]);
    const [productParam, setProductParam] = useState([]);
    const [price, setPrice] = useState(-1);
    const [userQuantity, setUserQuantity] = useState(1);
    const [userMode, setUserMode] = useState(0);
    const renderFooter = () => (
        <div className="d-flex justify-content-flex-end">
            <Button
                btnType="primary"
                className="btn-hover-shadow"
                onClick={() => handleSubmit()}
            >
                Lưu
            </Button>
            <Button
                className="btn-hover-shadow"
                onClick={() => cancel()}
            >
                Đóng
            </Button>
        </div>
    )
    const handleSubmit = () => {
        form.validateFields().then(values => {
            if(userMode!==0){ // optional choose
                const expired=values.expiredDateCode?dayjs(values.expiredDateCode).format('YYYY-MM-DDTHH:mm:ss').toString():undefined;
                // const dateObject = moment(values.expiredDateCode).toDate();
                const model = {
                    promoteCode: values.promoteCode,
                    productItemID: productParam,
                    promoteValue: values?.promoteValue,
                    productItemPrice: price,
                    promoteType: PROMOTE_CODE_TYPE.VOUCHER,
                    expiredDateCode:expired,
                    addPromoteUserRequests: usersParam,
                }
                http.post('Promote/AddPromote', model).then(value => {
                    if(!value.isError){
                        showSuccess("Thêm mã giảm giá thành công");
                        handleRefreshUpdate();
                        cancel();
                    }
                }).finally(() => {
                });

            }else{  //all user
                // const dateObject = moment(values.expiredDateCode).toDate();
                const expired=values.expiredDateCode?dayjs(values.expiredDateCode).format('YYYY-MM-DDTHH:mm:ss').toString():undefined;
                const model = {
                    promoteCode: values.promoteCode,
                    productItemID: productParam,
                    promoteValue: values?.promoteValue,
                    productItemPrice: price,
                    promoteType: PROMOTE_CODE_TYPE.VOUCHER,
                    expiredDateCode: expired,
                    addPromoteUserRequests: [
                        {
                            "userID": GUIDE_EMPTY,
                            "quantityPromoteCode": userQuantity
                        }
                    ]
                }
                http.post('Promote/AddPromote', model).then(value => {
                    if(!value.isError){
                        showSuccess("Thêm mã giảm giá thành công");
                        handleRefreshUpdate();
                        cancel();
                    }
                }).finally(() => {
                });
            }

        })

    }
    const showSuccess = (message) => {
        Swal.fire({
            icon: 'success',
            title: 'Thành Công',
            text: message,
        })
    };


    const refreshUsers = (model) => {
        setUsersParam(model);
    }
    const refreshProductParam = (model,price) => {
        setProductParam(model);
        setPrice(price);
    }
    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
        // Can not select days before today and today
        return current && current < dayjs().endOf('day');
    };

    const disabledDateTime = () => ({
        disabledHours: () => range(0, 24).splice(4, 20),
        disabledMinutes: () => range(30, 60),
        disabledSeconds: () => [55, 56],
    });
    const range = (start: number, end: number) => {
        const result = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    };
    const onChangeUserMode = ({ target: { value } }: RadioChangeEvent) => {
        setUserMode(value);
    };

    return (
        <>
            <CustomModal
                title={id ? "Cập nhật voucher" : "Thêm voucher"}
                footer={renderFooter()}
                width={1600}
                open={!!open}
                onCancel={cancel}
            >
                <SpinCustom spinning={loading}>
                    <Styled>
                        <Form
                            form={form}
                            layout="vertical"
                        >

                            <Row gutter={[24]} style={{marginTop: '15px'}}>
                                <Col span={12}>
                                    <Form.Item
                                        name="promoteCode"
                                        label="Mã"
                                        required
                                        rules={[{required: true, message: "Mã không được để trống"},
                                            {
                                                pattern: /^[^\s]+$/,
                                                message: "Mã không được chứa dấu cách",
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Nhập mã giảm giá"/>
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        name="promoteValue"
                                        label="Giá trị mã"
                                        required
                                        rules={[{required: true, message: "giá trị của mã phải lớn hơn 0%"}]}
                                    >

                                        <InputNumber
                                            defaultValue={0}
                                            min={0}
                                            max={100}
                                            formatter={(value) => `${value}%`}
                                            parser={(value) => value.replace('%', '')}
                                            // onChange={onChange}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[24]} style={{marginTop: '15px'}}>
                                <Col span={12}>
                                    <Form.Item
                                        name="category"
                                        label="Chọn sản phẩm"
                                        required
                                        rules={[
                                            {
                                                required: !productParam,
                                                message: 'Vui lòng chọn sản phẩm',
                                            },
                                        ]}
                                    >
                                        <ProductSearch refreshProductParam={refreshProductParam} type={0}/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="expiredDateCode"
                                        label="Ngày hết hạn"
                                        required
                                        rules={[{required: true, message: "Hãy chọn ngày hết hạn"}]}
                                    >
                                        <DatePicker placeholder="Chọn ngày hết hạn"
                                                    format="YYYY-MM-DD HH:mm:ss"
                                                    // disabledDate={disabledDate}
                                                    // disabledTime={disabledDateTime}
                                                    showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={[24]} style={{marginTop: '15px'}}>
                                <Col span={12}>
                                    {
                                        userMode==1&&(
                                            <Form.Item
                                                name="userQuantity"
                                                label="Danh sách người dùng"
                                                required
                                                rules={[
                                                    {
                                                        required: usersParam&&usersParam.length < 1,
                                                        message: 'Vui lòng nhập danh sách người dùng',
                                                    },
                                                ]
                                                }

                                            >
                                                <div style={{display:"flex",justifyContent:"flex-start"}}>
                                                    <Radio.Group
                                                        className={"d-flex-start"}
                                                        options={userModeList}
                                                        onChange={onChangeUserMode}
                                                        value={userMode}
                                                        optionType="button"
                                                        buttonStyle="solid"
                                                    />
                                                </div>
                                                {
                                                        <UsersSelected refreshUsers={refreshUsers}/>
                                                }
                                            </Form.Item>
                                        )
                                    }
                                    {
                                        userMode==0&&(
                                            <>
                                            <Form.Item
                                                name="userQuantity"
                                                label="Danh sách người dùng"
                                                required
                                                rules={[
                                                    {
                                                        required: userQuantity<1,
                                                        message: 'Vui lòng nhập số lượng lớn hơn 0'
                                                    },
                                                ]
                                                }

                                            >
                                                <div className={"d-flex-start"} style={{marginBottom:16}}>
                                                    <Radio.Group
                                                        className={"d-flex-start"}
                                                        options={userModeList}
                                                        onChange={onChangeUserMode}
                                                        value={userMode}
                                                        optionType="button"
                                                        buttonStyle="solid"
                                                    />
                                                </div>
                                                <div className={"d-flex-start"}>
                                                    <label aria-required={true} style={{marginRight:10}}>Nhập số lượng</label>
                                                    <InputNumber min={1} value={userQuantity} onChange={(e)=>setUserQuantity(e)}/>
                                                </div>

                                            </Form.Item>
                                            </>
                                        )
                                    }
                                </Col>
                            </Row>
                        </Form>
                    </Styled>
                </SpinCustom>

            </CustomModal>


        </>
    )
}
