import React, { useState } from 'react';
import { Select, Image } from 'antd';

const { Option } = Select;

export const SignatureSelect = ({ signatureData }) => {
    const [selectedSignature, setSelectedSignature] = useState(null);

    const handleSignatureChange = (value) => {
        setSelectedSignature(value);
    };

    return (
        <div>
            <Select
                style={{ width: 200 }}
                placeholder="Chọn signature"
                onChange={handleSignatureChange}
            >
                {signatureData.listSignature?.map((signature) => (
                    <Option key={signature.signatureID} value={signature.signatureID}>
                        {signature.signatureName}
                    </Option>
                ))}
            </Select>

            {selectedSignature && (
                <div>
                    {/*<Image*/}
                    {/*    className="sign-item"*/}
                    {/*    src={`data:image/png;base64,${signatureData.listSignature.find(*/}
                    {/*        (signature) => signature.signatureID === selectedSignature*/}
                    {/*    ).signatureImage}`}*/}
                    {/*    alt="Chữ kí"*/}
                    {/*/>*/}
                    <div style={{cursor:"pointer"}} dangerouslySetInnerHTML={{ __html: `${signatureData.listSignature?.find(
                            (signature) => signature.signatureID === selectedSignature
                        )?.signatureImage}`}} />
                </div>
            )}
        </div>
    );
};

