import QueryString from "qs"
import {
  apiGetListPost,
  apiGetListCategoryPost,
  apiInsertCategory,
  apiUpdateCategory,
  apiDeleteCategory,
  apiInsertPost,
  apiGetDetailPost,
  apiUpdatePost,
  apiDeletePost,
  apiRePost,
  apiCancelPost,
  apiGetCategoryPostCate,
  apiSortCategories,
} from "./url"
import http from "../index"

const getListCategoryPost = () => http.get(apiGetListCategoryPost)
const insertCategory = body => http.post(apiInsertCategory, body)
const updateCategory = body => http.put(apiUpdateCategory, body)
const deleteCategory = categoryPostID =>
  http.patch(`${apiDeleteCategory}?categoryPostID=${categoryPostID}`)

const getListPost = body => http.post(apiGetListPost, body)
const insertPost = body => http.post(apiInsertPost, body)
const updatePost = body => http.put(apiUpdatePost, body)
const getPost = params => http.get(apiGetDetailPost, { params })
const deletePost = PostID => http.patch(apiDeletePost + `?PostID=${PostID}`)
const rePost = PostID => http.patch(`${apiRePost}?PostID=${PostID}`)
const cancelPost = PostID => http.patch(`${apiCancelPost}?PostID=${PostID}`)
const getCategoryPostCate = params =>
  http.get(apiGetCategoryPostCate, { params })
const sortCategories = body => http.post(apiSortCategories, body)

const PostService = {
  getListPost,
  getListCategoryPost,
  insertCategory,
  updateCategory,
  deleteCategory,
  insertPost,
  updatePost,
  getPost,
  deletePost,
  rePost,
  cancelPost,
  getCategoryPostCate,
  sortCategories,
}
export default PostService
