import React, {useEffect, useState} from 'react';
// import './index.css';
import {Select, Spin} from 'antd';
import debounce from 'lodash/debounce';
import ProductionService from "../../../../services/ProductionService";
import {Option} from "antd/es/mentions";
import http from "../../../../services";

export const DebounceSelect = ({fetchOptions, debounceTimeout = 800, initValue,refreshProductParam, ...props}) => {
    const [fetching, setFetching] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [initialLoad, setInitialLoad] = useState(false); // Biến trạng thái mới
    const fetchRef = React.useRef(0);
    const debounceFetcher = React.useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            fetchOptions(value).then((newOptions) => {
                // console.log('new Option', newOptions);
                // console.log('prop', ...props);
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }

                setOptions(newOptions);
                setFetching(false);
            });
        };

        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);
    // console.log(options);
    const CustomOption = ({name, image, productItemName,price}) => (
        <div style={{display: 'flex', alignItems: 'center'}}>
            <img src={image} alt={name} style={{width: '24px', marginRight: '8px'}}/>
            {name} (Phân loại:{productItemName})
        </div>
    );

    useEffect(() => {

        if (initValue) {
            fetchOptions(initValue).then((newOptions) => {
                setOptions(newOptions);
                console.log('new op ',newOptions)
                newOptions.find(x=>x.productItemID=initValue);
                refreshProductParam(newOptions)
                setInitialLoad(true);
            });
        } else {
            fetchOptions('').then((newOptions) => {
                setOptions(newOptions);

                setInitialLoad(true);
            });
        }
    }, [initValue])

    return (

        <Select
            labelInValue
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small"/> : null}
            {...props}
        >
            {options.map((product) => (
                <Option key={product.productItemID} price={product.price} value={product.productItemID}>
                    <CustomOption name={product.productName} image={product.image}
                                  productItemName={product.productItemName} price={product.price}/>
                </Option>
            ))}
        </Select>
    );

} // Usage of DebounceSelect


export const ProductSearch = ({refreshProductParam, productName, productItemID,type}) => {
    const [value, setValue] = useState([]);
    const [initValue, setInitValue] = useState();

    const [dataBodySource, setDataBodySource] = useState({
        pageSize: 5,
        currentPage: 1,
        textSearch: "",
        categoryID: "",
        sortBy: 2,
        reviewType: 0,
        priceType: 0,
        status: 1
    });

    async function getDataSource(name) {
        try {
            const response = await http.get(`Promote/GetListProductForAdd?TextSearch=${name}&CurrentPage=1&Type=${type}`);
            if (response?.isError) return
            const listProducts = response?.object?.list;
            const allProductItems = listProducts.flatMap((product) =>
                product.listProductItems.map((item) => ({
                    productName: product.productName,
                    image: product.image,
                    productId: product.productID,
                    price:product.price,
                    ...item,
                })));
            // setProducts(response?.object?.products);
            return allProductItems;
        } catch (error) {
            return [];
        } finally {
            // setLoading(false);
        }
    };
    useEffect(() => {
        console.log('search',value);
        refreshProductParam(value.key,value?.label?.props.price)
    }, [value])
    useEffect(() => {
        if (productName && productItemID) {
            setValue(productItemID);
            setInitValue(productName);
        }
    }, [productName, productItemID])

    return (
        <DebounceSelect
            // mode="multiple"
            disabled={productName}
            initValue={initValue}
            showSearch
            value={value}
            placeholder="Chọn sản phẩm"
            fetchOptions={getDataSource}
            onChange={(newValue) => {
                setValue(newValue);
            }}
            style={{
                width: '100%',
            }}
        />
    );
};

