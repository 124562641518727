// MainComponent.js
import React, {useEffect, useState} from 'react';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import TinyEditor from "../../../../components/TinyEditor";

import CustomModal from "../../../../components/Modal/CustomModal";
import Button from "../../../../components/MyButton/Button";
import http from "../../../../services";
import {Divider, Form, Input} from "antd";
import {Label} from "../../../../components/FloatingLabel/styled";
import {getRegexUsername} from "../../../../lib/stringsUtils";
import {useNavigate} from "react-router-dom";
import ROUTER from "../../../../router";
import Swal from "sweetalert2";
import SpinCustom from "../../../../components/Spin";
import {DraggablePanel} from "../panel/DraggablePanel";
import {SignatureSelect} from "../components/SignatureSelect";

export const TemplateEditModal = ({contract,open, cancel,refreshData}) => {
    const [content, setContent] = useState("");
    const [attributes, setAttributes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [contractModal, setContractModal] = useState([]);



    useEffect(()=>{
        setContractModal(contract);
        },[contract])
    useEffect(() => {
        form.setFieldsValue(contractModal);
    },[contractModal])

    const handleDrop = (event) => {
        event.preventDefault();

        // Lấy chuỗi JSON từ dataTransfer
        const dataTransferString = event.dataTransfer.getData("application/json");

        // Chuyển đổi chuỗi JSON thành đối tượng
        const dataTransferObject = JSON.parse(dataTransferString);

        // Sử dụng thông tin từ đối tượng để chèn vào vị trí đúng
        const {type, value} = dataTransferObject;
        const newContent = `${content}<${type} value="${value}" />`;
        setContent(newContent);
    };
    const navigate = useNavigate()
    const handleSubmit = () => {
        setLoading(true);
        form.validateFields().then(values => {
            const formData = new FormData();
            formData.append('file', new Blob([values.html], {type: 'text/html'}));
            http.post(`/Contract/UpdateContractTemplate?ContractID=${contract.contractID}&ContractName=${values.contractName}`, formData).then(resp => {
                if (!resp.isError) {
                    showSuccess();
                    setLoading(false);
                }else{
                    setLoading(false);
                }
            })
        }).catch(reason => {
            setLoading(false);
        }).finally(() => {
            // setLoading(false);
        })

    }
    const showSuccess = () => {
        Swal.fire({
            icon: 'success',
            title: 'Thành Công',
            text: 'Bạn đã cập nhật template thành công thành công!',
        }).then(() => {
            // navigate(ROUTER.TEMPLATE_HOP_DONG);
            refreshData();
        });
    };
    const getAttribute = () => {
        setLoading(true);
        http.post('/Contract/GetAllAtributeContract').then(resp => {
            if (!resp.isError) {
                setAttributes(resp.object);
            } else {
                setAttributes([]);
            }
        })
    }
    useEffect(() => {
        getAttribute();
        getListSignatures();
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    }, [])
    const renderFooter = () => (
        <div className="d-flex justify-content-flex-end">
            <Button
                btnType="primary"
                className="btn-hover-shadow"
                onClick={() => handleSubmit()}
            >
                Lưu
            </Button>
            <Button
                className="btn-hover-shadow"
                onClick={() => cancel()}
            >
                Đóng
            </Button>
        </div>
    )
    const getListSignatures = () => {
        const model = {textSearch: ""};
        http.post('/Contract/GetContractSignature', model)
            .then(res => {
                if (!res?.isError) {
                    setSignatures(res.object)
                } else {
                    setSignatures([]);
                }

            })
    }
    const [signatures, setSignatures] = useState([]);

    const [form] = Form.useForm();
    return (
        <>
            <CustomModal
                title={ "Sửa thông tin template hợp đồng"}
                footer={renderFooter()}
                width={1600}
                open={!!open}
                onCancel={cancel}
            >
                <SpinCustom spinning={loading} >
                <div className={"d-flex flex-column"}>
                    <Form form={form}>
                        <div className={"col-12"} style={{padding: '30px 10px'}}>
                            <h4>Thêm Template hợp đồng</h4>
                        </div>
                        <div className={"col-12"}>

                            <Form.Item
                                label="Tên hợp đồng"
                                required
                                name="contractName"
                                rules={[
                                    {
                                        required: true,
                                        message: "Tên hợp đồng không được để trống",
                                    },
                                ]}
                            >
                                <Input placeholder="Nhập tên" value={contract?.contractName}/>
                            </Form.Item>

                            <Divider className="mv-16"/>
                        </div>
                        <div className={"d-flex"} onDrop={handleDrop} onDragOver={(e) => e.preventDefault()}>
                            <Form.Item className={"d-flex flex-column"}
                                       label="Nội dung hợp đồng"
                                       required={true}
                                       name="html"
                                       trigger="onEditorChange"
                                       validateTrigger={["onEditorChange"]}
                                       rules={[
                                           {
                                               required: true,
                                               message: "Nội dung hợp đồng không được để trống",
                                           },
                                       ]}
                            >
                                <TinyEditor setLoading={setLoading}/>
                            </Form.Item>

                            <div className={"col-4"} style={{marginRight: 20}}>
                                <DndProvider backend={HTML5Backend}>
                                    <div className="d-flex">
                                        
                                    <div className={"d-flex flex-column"}>
                                        <div>
                                            <p style={{fontSize: 14, fontWeight: 600, marginLeft: '10px'}}>Thuộc
                                                tính</p>
                                        </div>
                                        <div>
                                            {
                                                attributes.map(item => (
                                                    <DraggablePanel name={item.atributeName}
                                                                    value={item.atributeValue}/>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className={"d-flex flex-column"}>
                                                <div>
                                                    <div className={"d-flex"}><p
                                                        style={{fontSize: 14, fontWeight: 600, marginLeft: '10px'}}>Chọn chữ ký</p>
                                                    </div>
                                                    <SignatureSelect signatureData={signatures}/>
                                                </div>

                                            </div>
                                                        </div>
                                </DndProvider>
                            </div>
                        </div>
                    </Form>
                    {/*<div className={"col-12 d-flex-end container"} style={{padding: '30px 10px'}}>*/}
                    {/*    <Button btnType={"primary"} onClick={() => handleSubmit()}>Xác nhận</Button>*/}
                    {/*</div>*/}
                </div>
                </SpinCustom>
            </CustomModal>
        </>
    );
};

