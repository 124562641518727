import {Anchor, Avatar, Col, Divider, Row, Tag} from "antd";
import {UserOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import {ORDER_STATUS} from "../../../../constants/constants";
import SpinCustom from "../../../../components/Spin";
import {ListUserStyled} from "../../Company/styled";
import TableCustom from "../../../../components/Table/CustomTable";
import {SearchOrderHistory, SearchTakeCare} from "../component/SearchOrderHistory";
import {DisplayId} from "../../../../lib/utils";

export const OrdersHistoryListTable = ({
                                           data,
                                           totalPage,
                                           pagination,
                                           onChangeKeyword,
                                           changePage,
                                            onFilter
                                       }) => {

    const [loading, setLoading] = useState(false);


    const handleChangePage = (page, pageSize) => {
        const model = {...pagination, pageSize: pageSize || 10, currentPage: page || 1}
        changePage(model);
    }


    const formatCurrency = (value) => {
        return new Intl.NumberFormat('vi-VN', {
            style: 'currency',
            currency: 'VND',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value).replace('VND', 'đ');
    };
    const renderNewPrice = (price, discount) => {
        return price * (100 - discount) / 100;
    }
    const renderStatusTitle = (title) => {
        switch (title) {
            case ORDER_STATUS.DA_HUY:
                return "Đã huỷ";
            case ORDER_STATUS.CHO_XAC_NHAN:
                return "Chờ xác nhận"
            case ORDER_STATUS.HOAN_THANH:
                return "Hoàn thành"
            case ORDER_STATUS.CHO_KY:
                return "Chờ ký"
            case ORDER_STATUS.DA_DANH_GIA:
                return "Đã Đánh giá"
            default:
                return ""
        }
    }


    const columns = [
        {
            title: "Đơn hàng",
            dataIndex: "orderId",
            key: "orderId",
            width: 100,
            align: "left",
            render:(value,record)=>(
                <div>
                    {DisplayId(record?.orderId)}
                </div>
            )
        },
        {
            title: "Tên cửa hàng",
            dataIndex: "shopName",
            key: "shopName",
            width: 100,
            align: "left",
        },
        {
            title: "sản phẩm",
            dataIndex: "productName",
            key: "productName",
            render: (value, order) =>
                (
                    <div className={"shop-name__container"}>
                        <Avatar src={order.image} icon={<UserOutlined/>} size={40} style={{marginRight: 8}}
                                className={"col-4"}/>
                        <div className={"shop-name__container__item col-8"}>
                            <div className={"col-12 line-1"}>{value}</div>

                        </div>
                    </div>
                ),
            width: 240,
            align: "left",
        },
        {
            title: "Phân loại",
            dataIndex: "productName",
            key: "productName",
            render: (value, order) =>
                (
                    <div className={"d-flex-start"}>
                        <Tag>{order?.productItemName}</Tag>
                    </div>
                ),
            width: 120,
            align: "flex-end",
        },
        {
            title: "Số lượng",
            dataIndex: "quantity",
            key: "quantity",
            width: 120,
            align: "left",
        },
        {
            title: "Đơn giá",
            dataIndex: "sold",
            key: "sold",
            width: 120,
            align: "right",
            render: (value, order) => (
                <>
                    {

                        (order?.discount > 0) ?
                            (
                                <>
                                    <div
                                        className={'price price-active price-active-main line-1 at'}>{formatCurrency(renderNewPrice(order.price, order.discount))}
                                    </div>
                                    <div className={"priceline-1"}>
                                        <del>{formatCurrency(order?.price.toString())}</del>
                                    </div>
                                </>
                            ) :
                            (
                                <div
                                    className={'price price-active price-active-main line-1 at'}>{formatCurrency(order?.price)}
                                </div>
                            )
                    }
                </>
            )
        },
        {
            title: "Tổng tiền",
            dataIndex: "total",
            key: "total",
            width: 130,
            align: "right",
            render: (value, order) => (
                <>
                    {

                        (order?.discount > 0) ?
                            (
                                <>

                                    <div
                                        className={'price price-active price-active-main line-1 at'}>{formatCurrency(renderNewPrice(order.price * (order?.quantity), order.discount))}
                                    </div>
                                    <div className={"priceline-1"}>
                                        <del>{formatCurrency((order?.price * order?.quantity).toString())}</del>
                                    </div>

                                </>
                            ) :
                            (
                                <>
                                    <div
                                        className={'price price-active price-active-main line-1 at'}>{formatCurrency(order?.price * order?.quantity)}
                                    </div>

                                </>
                            )
                    }
                </>
            )
        },
        {
            title: "Trạng thái",
            dataIndex: "status",
            key: "status",
            width: 100,
            align: "center",
            render: (value, order) => (
                <>
                    <div>
                        {renderStatusTitle(value)}
                    </div>
                </>
            )
        }

    ]
    return (
        <>
            <ListUserStyled>
                <div>
                    <SearchOrderHistory
                        onChangeKeyword={onChangeKeyword}
                        onFilter={onFilter}
                        pagination={pagination}
                    />
                    <Divider className="mv-16"/>
                    <div className="title-type-1 d-flex justify-content-space-between align-items-center pb-16 pt-0 mb-16">
                        <div className="fs-24">
                            Lịch sử đơn hàng đã mua
                        </div>

                    </div>
                </div>
                <Anchor
                    affix={false}
                    getContainer={() => document.getElementById("body-admin-scroll")}
                >
                    <Row gutter={[16, 16]}>
                        <Col style={{width: 0}} flex="auto">
                            <SpinCustom spinning={loading}>
                                <TableCustom
                                    isPrimary
                                    className="mb-6"
                                    dataSource={data}
                                    columns={columns}
                                    textEmpty="Không có đơn hàng"
                                    rowKey="orderId"
                                    sticky={{offsetHeader: -12}}
                                    scroll={{y: "100%", x: "800px"}}
                                    pagination={{
                                        defaultPage: 10,
                                        showSizeChanger: true,
                                        pageSizeOptions: ['10', '20', '30'],
                                        total: totalPage,
                                        onChange: (page, pageSize) => {
                                            handleChangePage(page, pageSize);
                                        }
                                    }}
                                />
                            </SpinCustom>
                        </Col>
                    </Row>
                </Anchor>

            </ListUserStyled>
        </>

    )
}
