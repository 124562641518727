import http from "../index"
import { 
    apiReadLastestMessage,
    apiSendMessage,
    apiGetMessageInRoom,
    apiGetListRoom,
    apiGetNumberUnreadMess
 } from "./urls"

const readLastestMessage = (roomId, userId) => http.post(`${apiReadLastestMessage}?roomId=${roomId || 0}&userId=${userId}`);
const sendMessage = body => http.post(apiSendMessage, body);
const getMessageInRoom = body => http.post(apiGetMessageInRoom, body);

const getListRoom = body => http.post(apiGetListRoom, body);
const getNumberUnreadMess = () => http.post(apiGetNumberUnreadMess);


const ChatBoxService = { 
    readLastestMessage,
    sendMessage,
    getMessageInRoom,
    getListRoom,
    getNumberUnreadMess
 }
export default ChatBoxService
