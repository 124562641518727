import http from "../index"
import { 
    apiGetAllForAdmin,
    apiDeleteProduct,
    apiInsertProduct,
    apiUpdateProduct,
    apiChangeStatusProduct,
    apiGetAll,
    apiGetProductDetailsByID,
    apiGetListRelatedProduct,
    apiGetProductReviewsByID,
    apiCheckPromoteCode,
    apiBuyProduct,
    apiGetListInventoryByProductItemID,
    apiInsertInventory,
    apiUpdateInventory,
    apiDeleteInventory,
    apiGetAllInventory,
    apiGetAllProductAndProductItem,
    apiImportInventoryByProductItem,
    apiInsertProductItem,
    apiDeleteProductItem
 } from "./urls"

 //ADMIN
const getAllForAdmin = body => http.post(apiGetAllForAdmin, body);
const deleteProduct = body => http.post(apiDeleteProduct, body);
const changeStatusProduct = body => http.post(apiChangeStatusProduct, body);
const insertProduct = body => http.post(apiInsertProduct, body);
const updateProduct = body => http.post(apiUpdateProduct, body);
const insertProductItem = body => http.post(apiInsertProductItem, body);
const deleteProductItem = body => http.post(apiDeleteProductItem, body);


//ANNONYMOUS
const getAll = body => http.post(apiGetAll, body);
const getProductDetailsByID = (productID, currentUserId) => 
    http.get(`${apiGetProductDetailsByID}?ProductID=${productID}${currentUserId ? `&currentUserId=${currentUserId}` : ''}`);
const getProductRelated = body => http.post(apiGetListRelatedProduct, body);
const getProductReviewsByID = body => http.post(apiGetProductReviewsByID, body);
const buyProduct = body => http.post(apiBuyProduct, body);


//PromoteCode
const checkPromoteCode = body => http.post(apiCheckPromoteCode, body);

//Inventory
const getAllInventory = body => http.post(apiGetAllInventory, body);
const getListInventoryByProductItemID = body => http.post(apiGetListInventoryByProductItemID, body);
const insertInventory = body => http.post(apiInsertInventory, body);
const updateInventory = body => http.post(apiUpdateInventory, body);
const deleteInventory = body => http.post(apiDeleteInventory, body);
const getAllProductAndProductItem = () => http.get(apiGetAllProductAndProductItem);
const importInventoryByProductItem = (id, file) => http.post(`${apiImportInventoryByProductItem}?ProductItemID=${id}`, file);


const Product = { 
    getAllForAdmin,
    deleteProduct,
    changeStatusProduct,
    insertProduct,
    updateProduct,
    getAll,
    getProductDetailsByID,
    getProductRelated,
    getProductReviewsByID,
    checkPromoteCode,
    buyProduct,
    getListInventoryByProductItemID,
    insertInventory,
    updateInventory,
    deleteInventory,
    getAllInventory,
    getAllProductAndProductItem,
    importInventoryByProductItem,
    insertProductItem,
    deleteProductItem,
 }
export default Product
