import { Anchor, Col, DatePicker, Descriptions, Divider, Form, Row, Space, TreeSelect, message } from "antd"
import { useState } from "react"
import { useSelector } from "react-redux"
import CustomModal from "src/components/Modal/CustomModal"
import SpinCustom from "src/components/Spin"
import Button from "src/components/MyButton/Button"
import { useEffect } from "react"
import MoneyService from "src/services/MoneyService"
import dayjs from "dayjs";
import Notice from "src/components/Notice"
import FlInput from "src/components/FloatingLabel/Input"
import "../style.css"
import TableCustom from "src/components/Table/CustomTable"
import { PAYMENT_TYPE } from "src/constants/constants"

const DetailWaller = ({ onOk, detailInfo, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [dataBody, setDataBody] = useState(null);
  const [listDataSource, setListDataSource] = useState([]);
  const [totalDataSource, setTotalDataSource] = useState(0);
  const labelStyle = {fontWeight: 'bold', color: 'black'};
  const columns = [
    {
      title: "STT",
      key: "index",
      width: 60,
      align: "center",
      render: (text, row, idx) => (
        <div className="text-center">
          {idx +
            1 +
            dataBody.pageSize * (dataBody.currentPage - 1)}
        </div>
      ),
    },
    {
      title: "transaction ID",
      dataIndex: "transactionID",
      key: "transactionID",
      width: 200,
      align: "left",
    },
    {
      title: "Tên tài khoản",
      dataIndex: "userName",
      key: "userName",
      width: 200,
      align: "left",
      render: value => <strong>{value}</strong>,
    },
    {
      title: "Số tiền",
      dataIndex: "money",
      key: "money",
      width: 150,
      align: "right",
      render: value => formatCurrency(value),
    },
    {
      title: "Nội dung",
      dataIndex: "transactionContent",
      key: "transactionContent",
      width: 320,
      align: "left",
    },
    {
      title: "Thời gian chuyển khoản",
      dataIndex: "transactionTime",
      key: "transactionTime",
      width: 150,
      align: "center",
      render: value => formatDate(value),
    },
    {
      title: "Thời gian nạp tiền",
      dataIndex: "createDate",
      key: "createDate",
      width: 150,
      align: "center",
      render: value => formatDate(value),
    },
    {
      title: "Hình thức nạp",
      dataIndex: "type",
      key: "type",
      width: 140,
      align: "center",
      render: (value) => (<strong>{value == PAYMENT_TYPE.VIETCOMBANK ? 'Vietcombank' : 'VNPay'}</strong>)
    },
  ]

  //#region useEffect

  useEffect(() => {
    if(detailInfo) {
      setDataBody({
        pageSize: 10,
        currentPage: 1,
        textSearch: "",
        dateFrom: "",
        dateTo: "",
        userId: detailInfo?.userID
      });
    }
  }, [detailInfo])

  useEffect(() => {
    if(dataBody) {
      getListCustomerWallet();
    }
  }, [dataBody])
  
  //#endregion
    
  //#region function

  /**
   * @description format date
   * @author HDDUNG
   */
  function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        time = d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-') + ` ${time}`;
  };

  /**
   * @description get list danh sách ví
   * @author hddung 
   */
  async function getListCustomerWallet() {
    const res = await MoneyService.getTransaction(dataBody);
    if(res?.isError) return
    setListDataSource(res?.object?.list);
    setTotalDataSource(res?.object?.count);
  }

  /**
   * @description Format số tiền
   * @author hddung 
   */
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: 'VND',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(value).replace('VND', 'đ');
  };

  const renderFooter = () => (
    <div className="d-flex justify-content-flex-end">
        <Button
            className="btn-hover-shadow"
            onClick={() => props?.onCancel()}
        >
            Đóng
        </Button>
    </div>
  )

  //#endregion
  
  return (
    <CustomModal
      title={`Thông tin chi tiết: ${detailInfo?.username}`}
      footer={renderFooter()}
      width="95%"
      {...props}
      style={{padding: '20px 20px 0px 10px'}}
      className="modal-detail-wallet"
    >
        <SpinCustom spinning={loading}>
          <Row gutter={[16]} style={{height: '100%'}}>
            <Col span={24}>
              <Row gutter={[16]}>
                <Col flex="auto">
                  <FlInput
                    onSearch={e => {
                      setDataBody((prevState) => ({
                        ...prevState,
                        textSearch: e || '',
                        currentPage: 1
                      }));
                    }}
                    search
                    allowClear
                    label="Nhập tên tài khoản, số điện thoại, email"
                  />
                </Col>
                <Col span={5}>
                  <DatePicker
                    onChange={(e) => {
                        const formattedDate = dayjs(e).format('YYYY-MM-DD');
                        if(new Date(formattedDate) <= new Date(dataBody?.dateTo) || !dataBody?.dateTo || !e) {
                          setDataBody((prevState) => ({
                            ...prevState,
                            dateFrom: formattedDate == 'Invalid Date' ? null : formattedDate,
                            currentPage: 1
                          }));
                        } else {
                          message.error('Từ ngày nhỏ hơn đến ngày');
                        }
                    }}
                    value={dataBody?.dateFrom ? dayjs(dataBody?.dateFrom) : null}
                    placeholder="Từ ngày"
                    format="DD/MM/YYYY"
                    allowClear
                  />
                </Col>
                <Col span={5}>
                  <DatePicker
                    onChange={(e) => {
                        const formattedDate = dayjs(e).format('YYYY-MM-DD');
                        if(new Date(formattedDate) >= new Date(dataBody?.dateFrom) || !dataBody?.dateFrom || !e) {
                          setDataBody((prevState) => ({
                            ...prevState,
                            dateTo: formattedDate == 'Invalid Date' ? null : formattedDate,
                            currentPage: 1
                          }));
                        } else {
                          message.error('Đến ngày lớn hơn từ ngày');
                        }
                    }}
                    value={dataBody?.dateTo ? dayjs(dataBody?.dateTo) : null}
                    placeholder="Đến ngày"
                    format="DD/MM/YYYY"
                    allowClear
                  />
                </Col>
              </Row>
              <Anchor
                affix={false}
                getContainer={() => document.getElementById("body-admin-scroll")}
              >
                <Row gutter={[16, 16]} style={{marginTop: 8}}>
                  <Col style={{ width: 0 }} flex="auto">
                    <TableCustom
                      isPrimary
                      onRow={record => {
                        return {
                          onClick: () => {
                            // setDetailInfo(record);
                            // setOpenDetailWaller(true);
                          },
                        }
                      }}
                      className="mb-6"
                      dataSource={listDataSource || []}
                      columns={columns}
                      textEmpty="Không có dữ liệu"
                      rowKey="id"
                      sticky={{ offsetHeader: -12 }}
                      scroll={{ y: "100%", x: "800px" }}
                      pagination={{ 
                        defaultPage: dataBody?.pageSize || 10, 
                        showSizeChanger: true, 
                        pageSizeOptions: ['10', '20', '30'], 
                        total: totalDataSource,
                        onChange: (page, pageSize) => {
                          setDataBody((prevState) => ({
                            ...prevState,
                            pageSize: pageSize || 10,
                            currentPage: page || 1
                          }));
                        },
                      }}
                    />
                  </Col>
                </Row>
              </Anchor>
            </Col>
          </Row>
        </SpinCustom>
    </CustomModal>
  )
}

export default DetailWaller