import Button from "../../../../components/MyButton/Button";
import React, {useState} from "react";
import CustomModal from "../../../../components/Modal/CustomModal";
import {Divider, Form, Input, Table} from "antd";
import TinyEditor from "../../../../components/TinyEditor";
import SpinCustom from "../../../../components/Spin";
import http from "src/services";
import Swal from "sweetalert2";

export const SendMailTemplate = ({open, cancel, refreshData, ids}) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const columns = [
        {
            title: "STT",
            key: "index",
            width: 60,

            render: (text, row, idx) => (
                <div className="text-center">
                    {idx + 1}
                </div>
            ),
        },
        {
            title: 'userID',
            dataIndex: 'userID',
            key: 'userID',
        },
        {
            title: 'email',
            dataIndex: 'email',
            key: 'email',
        },
    ];
    const handleSubmit = () => {
        form.validateFields().then(values => {
            setLoading(true)
            const model = {
                "subject": values.subject,
                "body": values.body,
                "toEmails": ids
            }
            http.post("ManageCustomer/SendEmails", model).then(resp => {
                if (!resp.isError) {
                    refreshData();
                    showSuccess("Bạn đã gửi email thành công đến các khách hàng");
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })
        }).catch(reason => {
            setLoading(false);
        }).finally(
            // setLoading(false)
        )
    }
    const showSuccess = (message) => {
        setLoading(false)
        Swal.fire({
            icon: 'success',
            title: 'Thành Công',
            text: message,
        }).then(() => {
            refreshData();
            cancel();
        });
    };
    const renderFooter = () => (
        <div className="d-flex justify-content-flex-end">
            <Button
                btnType="primary"
                className="btn-hover-shadow"
                onClick={() => handleSubmit()}
                disabled={loading}
            >
                Lưu
            </Button>
            <Button
                className="btn-hover-shadow"
                onClick={() => cancel()}
                disabled={loading}
            >
                Đóng
            </Button>
        </div>
    )
    return (
        <>
            <CustomModal
                title={"Thông tin email"}
                footer={renderFooter()}
                width={1600}
                open={!!open}
                onCancel={cancel}
            >
                <SpinCustom spinning={loading}>

                    <Form form={form}>
                        <Form.Item
                            label="Tiêu đề"
                            required
                            name="subject"
                            rules={[
                                {
                                    required: true,
                                    message: "Tiêu đề mail không được để trống",
                                },
                            ]}
                        >
                            <Input placeholder="Nhập tên"/>
                        </Form.Item>
                        <Form.Item
                            label="Gửi đến"
                            required
                            name="toEmails"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: "Tiêu đề mail không được để trống",
                            //     },
                            // ]}
                        >
                            <Table dataSource={ids} columns={columns}/>
                        </Form.Item>

                        <Divider className="mv-16"/>
                        <Form.Item className={"d-flex flex-column "}
                                   label="Nội dung"
                                   required={true}
                                   name="body"
                                   trigger="onEditorChange"
                                   validateTrigger={["onEditorChange"]}
                                   rules={[
                                       {
                                           required: true,
                                           message: "Nội dung mail không được để trống",
                                       },
                                   ]}
                        >
                            <TinyEditor setLoading={setLoading}/>
                        </Form.Item>

                    </Form>

                </SpinCustom>
            </CustomModal>
        </>
    )
}
