// list endpoints
import http from "src/services";

const getBanner = "BannerFooter/GetList"
const getBannerUser = "Guest/GetListBannerFooter?Type=1"
const FooterrUser = "Guest/GetListBannerFooter?Type=5"

const saveBanner = "BannerFooter/AddNew"

const updateBanner = "BannerFooter/Update"

const DELETEBANNER = "BannerFooter/ChangeStatusDelete"
const getPost  = "Post/GetList"


export const pagingBanner = (
    pageSize,
    currentPage,
    textSearch,
    type,
    isDelete
) =>
    http.post(getBanner, {
        pageSize: pageSize,
        currentPage: currentPage,
        textSearch: textSearch,
        type: type,
        isDelete: isDelete
    });

export const getListBannerUser = (

) => http.get(getBannerUser);
export const getListFooterUser = (

) => http.get(FooterrUser);

export const save = (data) => http.post(saveBanner, data)

export const getListPost = () => http.post(getPost,
    {
        textSearch: "",
        status: 0,
    }
)

export const update = (data) => http.post(updateBanner, data)


export const deleteBanner = (id, isDelete) => http.get(DELETEBANNER, {
    params: {
        ID: id,
        IsDelete: isDelete

    }

})

