import React from 'react'
import imgStar from "../../../../assets/images/page/product/rating-stars.png"


const ratingStyled = {
    form:{
        display:"flex"
    },
    productRate : {
        backgroundImage: `url(${imgStar})`,
        backgroundPosition: "0 -12px",
        backgroundRepeat:"repeat-x",
        height: "12px",
        width: "12px",
      },
      productRating : {
        height: "12px",
        width: "12px",
        backgroundRepeat: "repeat-x",
        backgroundImage: `url(${imgStar})`,
        backgroundPosition: "0 0",
      }
}


export default function rate({number,classname,max}) {
  return (
    <div className={classname} style={ratingStyled.form}>
        { Array.from({length : max},(_,index) => (
            <div key={index} style={(index + 1) <= number?ratingStyled.productRating:ratingStyled.productRate}>
            </div>
        ))}
    </div>
  )
}
