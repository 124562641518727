import React from "react"
import CustomModal from "src/components/Modal/CustomModal"
import Button from "src/components/MyButton/Button"
import './style.css'
import { useEffect, useState } from "react"
import SvgIcon from "src/components/SvgIcon"
import PaymentMethods from "src/components/PaymentMethods"
import UserService from "src/services/UserService"
import { Spin } from "antd"
import { useSelector } from "react-redux"
import Notice from "src/components/Notice"

const PopupConfirm = ({onOk, title, productSelected, priceSaleProductItem, quantityBuy, promoteCode, ...props}) => {
    const [priceOrigin, setPriceOrigin] = useState(0);
    const [priceSale, setPriceSale] = useState(0);
    const [openOptionPayment, setOpenOptionPayment] = useState(false);
    const { balance } = useSelector(state => state.appGlobal);
    const [isUnequal, setIsUnequal] = useState(false);
    const [loading, setLoading] = useState(true);

    //#region useEffect

    useEffect(() => {
        if(productSelected && priceSaleProductItem) {
            setPriceOrigin(productSelected?.price);
            setPriceSale(priceSaleProductItem);
            setLoading(false);
        }

    }, [productSelected, priceSaleProductItem])

    useEffect(() => {
        if(balance < (priceSale * quantityBuy)) {
            setIsUnequal(true);
        } else {
            setIsUnequal(false);
        }

        //Khi số dư >= số tiền cần trả thì đóng popup
        if(balance >= (priceSale * quantityBuy)) {
            setOpenOptionPayment(false);
        }
    }, [balance, priceSale, quantityBuy])

    //#endregion

    //#region function

    /**
     * @description Format số tiền
     * @author hddung 
     */
    const formatCurrency = (value) => {
        return new Intl.NumberFormat('vi-VN', {
            style: 'currency',
            currency: 'VND',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value).replace('VND', 'đ');
    };

    /**
     * @description Hàm xử lý khi click mua ngay
     * @author hddung 
     */
    function hanlderBuyProduct() {
        if(!isUnequal) {
            onOk && onOk(); 
            props?.onCancel()
        } else {
            Notice({
                isSuccess: false,
                msg: "Số dư ví không đủ, vui lòng nạp thêm."
            })
        }
    }

    const renderFooter = () => (
        <div className="d-flex justify-content-flex-end">
          <Button
            className="btn-hover-shadow"
            onClick={() => props?.onCancel()}
          >
            Đóng
          </Button>
          <Button
            btnType="primary"
            className="btn-hover-shadow"
            onClick={hanlderBuyProduct}
          >
            Mua hàng
          </Button>
        </div>
    )

    //#endregion

    return (
        <CustomModal
          title='Xác nhận đơn hàng'
          footer={renderFooter()}
          {...props}
          className="ctn-popupConfirm"
          style={{padding: '20px 20px 0px 10px'}}
        >
            <Spin spinning={loading}>
                <div className={"cart-contact-cf-product"}>
                    <div className={"content-pay"}>
                        
                        <div className={"item-payment__infor col-12"}>
                            <p>Đơn giá:</p>
                            
                            <div>
                                <b style={{marginRight: '5px'}}>{formatCurrency(priceSale || 0)}</b>
                                <del>
                                    {formatCurrency(priceOrigin || 0)}
                                </del>
                            </div>
                            
                        </div>
                        <div className={"item-payment__infor col-12"}>
                            <p>Số lượng:</p>

                            <p>
                                {quantityBuy || 0}
                            </p>
                        </div>
                        <div className={"item-payment__infor col-12"}>
                            <p>Tổng giá:</p>

                            <p>
                            <p>{formatCurrency(priceOrigin * quantityBuy || 0)}</p>
                            </p>
                        </div>
                        <div className={"item-payment__infor col-12"}>
                            <p>Khuyến mãi:</p>

                            <p>
                            <p>- {formatCurrency( (priceOrigin * quantityBuy) - (priceSale * quantityBuy) || 0)}</p>
                            </p>
                        </div>
                        <div className={"item-payment__infor col-12 total-border-cf-product"}>
                            <h6>Tổng giá trị phải thanh toán:</h6>
                            <p style={{color: 'red', fontWeight: 'bold', paddingLeft: '10px'}}>
                                {formatCurrency(priceSale * quantityBuy || 0)}
                            </p>
                        </div>
                        <div className={"item-payment__infor col-12 total-border-cf-product"}>
                            <p>Số dư hiện tại:</p>

                            <p>
                            <p>{formatCurrency(balance)}</p>
                            </p>
                        </div>
                        { isUnequal && (
                        <div className={"item-payment__infor col-12"} style={{margin: '5px 0px'}}>
                            <p>Số tiền cần nạp thêm:</p>

                            <p>
                            <p>{formatCurrency((priceSale * quantityBuy || 0) - balance)}</p>
                            </p>
                        </div>
                        )}
                    </div>
                    { isUnequal && (
                        <div style={{direction: 'rtl'}}>
                            <div className="payment-method" onClick={() => setOpenOptionPayment(true)}>
                                <SvgIcon name="ic-payment" className="txt-payment"/>
                                <span className="txt-payment">Nạp thêm</span>
                            </div>
                        </div>
                    )}
                </div>


                { !!openOptionPayment &&
                    <PaymentMethods 
                    onCancel={() => {
                        setOpenOptionPayment(false);
                    }}
                    onOk={() => {}}
                    open={openOptionPayment}
                    money={(priceSale * quantityBuy || 0) - balance}
                    />
                }
            </Spin>
        </CustomModal>
    )
}

export default PopupConfirm