import { Col, Row, Select } from "antd"
import { useSelector } from "react-redux"
import FlInput from "src/components/FloatingLabel/Input"
import FlSelect from "src/components/FloatingLabel/Select"
import { SYSTEM_KEY } from "src/constants/constants"
import { getListComboByKey } from "src/lib/utils"
import { SearchStyled } from "../styled"

const { Option } = Select
const Search = ({ setDataBody, dataBody }) => {
  const { listSystemKey } = useSelector(state => state.appGlobal)

  return (
    <SearchStyled>
      <Row gutter={[16, 16]}>
        <Col flex="auto">
          <FlInput
            onSearch={e => {
              setDataBody((prevState) => ({
                ...prevState,
                textSearch: e || '',
                currentPage: 1
              }));
            }}
            search
            allowClear
            label="Nhập tên tiêu đề, email, họ tên, số điện thoại, nội dung hỗ trợ, người phụ trách"
          />
        </Col>
        <Col lg={5} xs={24}>
          <FlSelect
            label="Trạng thái"
            onChange={Status => {
              setDataBody((prevState) => ({
                ...prevState,
                status: Status || 0,
                currentPage: 1
              }));
            }}
            value={dataBody?.status || 0}
          >
            {getListComboByKey(SYSTEM_KEY?.CONTACT_STATUS, listSystemKey)?.map(
              i => (
                <Option key={+i.codeValue} value={+i.codeValue}>
                  {i?.description}
                </Option>
              ),
            )}
          </FlSelect>
        </Col>
      </Row>
    </SearchStyled>
  )
}

export default Search
