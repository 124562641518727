// MainComponent.js
import React, {useEffect, useState} from 'react';

import CustomModal from "../../../../components/Modal/CustomModal";
import Button from "../../../../components/MyButton/Button";
import {Col, DatePicker, Form, Input, Row, Select} from "antd";
import Swal from "sweetalert2";
import SpinCustom from "../../../../components/Spin";
import {getRegexEmail, getRegexMobile, getRegexTax} from "../../../../lib/stringsUtils";
import RegionService from "../../../../services/RegionService";
import {Option} from "antd/es/mentions";
import {getRegexPassword} from "../../../../lib/utils";
import FlInput from "../../../../components/FloatingLabel/Input";
import http from "../../../../services";
import dayjs from "dayjs";
import moment from "moment";
import {useSelector} from "react-redux";
import {GUIDE_EMPTY} from "../../../../constants/constants";

export const CustomerEditModal = ({open, cancel,refreshData,userId,...props}) => {
    moment.locale('en');
    const [loading, setLoading] = useState(false);
    const [listWard, setListWard] = useState([]);
    const [listProvince, setListProvince] = useState([]);
    const [listDistrict, setListDistrict] = useState([]);
    const [userInfo,setUserInfo]=useState();
    const {userIF} = useSelector(state => state?.appGlobal)

    const getListProvinceVN = () => {
        setLoading(true)
        RegionService.getByRegionId({ regionId: 234 })
            .then(res => {
                if (res?.isError) return
                setListProvince(res?.object);
                loadListDistrict(userInfo?.provinceID);
                loadListWard(userInfo?.districtID);
            })
            .finally(() => setLoading(false))
    }
    const loadListDistrict=(newValue)=>{
        if(newValue&&newValue!==0){
            RegionService.getByRegionId({ regionId: newValue })
                .then(res => {
                    if (res?.isError) return
                    const lstDistrict = res?.object||[];
                    setListDistrict(lstDistrict)
                })
        }
    }
    const loadListWard=(newValue)=>{
        if(newValue&&newValue!==0){
            RegionService.getByRegionId({ regionId: newValue })
                .then(res => {
                    if (res?.isError) return
                    const listWard = res?.object||[];
                    setListWard(listWard)
                })
        }
    }
    const getCustomerDetail=()=>{
        http.post(`/ManageCustomer/GetCustomer?UserID=${userId}`).then(resp=>{
           if(!resp.isError){
               setUserInfo(resp?.object);
           }
        },[])
    }
    useEffect(() => {
        getCustomerDetail();
    },[])

    useEffect(() => {
        form.setFieldsValue({
            ...userInfo,
            dateOfBirth: userInfo?.dateOfBirth ? dayjs(userInfo.dateOfBirth) : null,
            provinceID: !!userInfo?.provinceID ? userInfo?.provinceID : undefined,
            districtID: !!userInfo?.districtID ? userInfo?.districtID : undefined,
            wardID: !!userInfo?.wardID ? userInfo?.wardID : undefined,
        });

        loadListDistrict(userInfo?.provinceID);
        loadListWard(userInfo?.districtID);

    }, [userInfo])
    useState(() => {
        getListProvinceVN();
    },[])

    const handleSubmit = () => {
        if(props?.isCompany){
            setLoading(true);
            form.validateFields().then(values => {
                const model={...values,userID:userId,accountBalance:userInfo?.accountBalance,avatar:userInfo?.avatar,
                    dateOfBirth: values.dateOfBirth ? dayjs(values.dateOfBirth).format('YYYY-MM-DD').toString() : undefined
                }
                http.post("ManageCustomer/EditBusiness",model).then(resp=>{
                    if(!resp?.isError){
                        setLoading(false);
                        showSuccess("Thay đổi thông tin khách hàng thành công");
                    }
                })
            }).catch(reason => {
                setLoading(false);
            }).finally(() => {
                setLoading(false);
            })
        }else{

        setLoading(true);
        form.validateFields().then(values => {
            const model={...values,userID:userId,accountBalance:userInfo?.accountBalance,avatar:userInfo?.avatar,
                dateOfBirth:values.dateOfBirth?dayjs(values.dateOfBirth).format('YYYY-MM-DD').toString():undefined,sex:values.sex==1}
            http.post("ManageCustomer/EditCustomer",model).then(resp=>{
                if(!resp?.isError){
                    setLoading(false);
                    showSuccess("Thay đổi thông tin khách hàng thành công");
                }
            })
        }).catch(reason => {
            setLoading(false);
        }).finally(() => {
            setLoading(false);
        })
        }


    }
    const showSuccess = (message) => {
        Swal.fire({
            icon: 'success',
            title: 'Thành Công',
            text: message,
        }).then(() => {
            // navigate(ROUTER.TEMPLATE_HOP_DONG);
            refreshData();
            cancel();
        });
    };

    useEffect(() => {

    }, [])
    const renderFooter = () => (
        <div className="d-flex justify-content-flex-end">
            <Button
                btnType="primary"
                className="btn-hover-shadow"
                onClick={() => handleSubmit()}
            >
                Lưu
            </Button>
            <Button
                className="btn-hover-shadow"
                onClick={() => cancel()}
            >
                Đóng
            </Button>
        </div>
    )
    const [form] = Form.useForm();
    const onChangeProvince = newValue => {
        form.resetFields([`districtID`]);
        form.resetFields([`wardID`]);
        if (!newValue) {
            setListDistrict([]);
            return;
        }
        setLoading(true);
        RegionService.getByRegionId({ regionId: newValue })
            .then(res => {

                if (res?.isError) return
                const lstDistrict = res?.object||[];
                setListDistrict(lstDistrict)
            })
            .finally(() => setLoading(false));
    };
    const onChangeDistrict = e => {
        form.resetFields([`wardID`])
        if (!e) return setListWard([])
        setLoading(true)
        RegionService.getByRegionId({ regionId: e })
            .then(res => {
                if (res?.isError) return
                const lstWard = res?.object||[];
                setListWard(lstWard);
            })
            .finally(() => setLoading(false))
    }
    return (
        <>
            <CustomModal
                title={ "Thông tin khách hàng"}
                footer={renderFooter()}
                width={1600}
                open={!!open}
                onCancel={cancel}
            >
                <SpinCustom spinning={loading} >
                    <div className={"d-flex flex-column container"}>
                        <Form
                            form={form}
                            layout="vertical"

                        >
                            <Row gutter={[16, 16]}>
                                {
                                    userIF?.accountID===GUIDE_EMPTY&&(
                                        <Col span={12}>
                                            <Form.Item
                                                name="username"
                                                label="Tên đăng nhập"
                                                required
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Thông tin không được để trống",
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Nhập" disabled={true} />
                                            </Form.Item>
                                        </Col>
                                    )
                                }
                                {/*<Col span={12}>*/}
                                {/*    <Form.Item*/}
                                {/*        name="password"*/}
                                {/*        label="Mật khẩu"*/}
                                {/*        required*/}
                                {/*        rules={[*/}
                                {/*            { required: true, message: "Vui lòng nhập mật khẩu" },*/}
                                {/*            {*/}
                                {/*                pattern: getRegexPassword(),*/}
                                {/*                message:*/}
                                {/*                    "Mật khẩu có chứa ít nhất 8 ký tự, trong đó có ít nhất một số và bao gồm cả chữ thường và chữ hoa và ký tự đặc biệt, ví dụ @, #, ?, !.",*/}
                                {/*            },*/}
                                {/*        ]}*/}
                                {/*    >*/}
                                {/*        <FlInput isPass  isRequired />*/}
                                {/*    </Form.Item>*/}
                                {/*</Col>*/}

                                <Col span={12}>
                                    {
                                        !props?.isCompany&&(
                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Bạn chưa nhập họ và tên!",
                                                    },
                                                    {
                                                        min:8,
                                                        message: "Độ dài của tên là tối thiểu là 8 kí tự!",
                                                    },
                                                    {
                                                        max: 50,
                                                        message: "Độ dài của tên là tối đa là 50 kí tự",
                                                    },
                                                ]}
                                                label="Họ và tên"
                                                name="fullName"
                                            >
                                                <Input placeholder="Nhập họ và tên" />
                                            </Form.Item>

                                        )
                                    }
                                    {
                                        props?.isCompany&&(
                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Bạn chưa nhập tên doanh nghiệp!",
                                                    },
                                                    {
                                                        min:8,
                                                        message: "Độ dài của tên là tối thiểu là 8 kí tự!",
                                                    },
                                                    {
                                                        max: 50,
                                                        message: "Độ dài của tên là tối đa là 50 kí tự",
                                                    },
                                                ]}
                                                label="Tên doanh nghiệp"
                                                name="fullName"
                                            >
                                                <Input placeholder="Nhập tên doanh nghiệp" />
                                            </Form.Item>

                                        )
                                    }
                                </Col>
                                {
                                    !props?.isCompany&&(
                                        <Col span={8}>
                                            <Form.Item label="Giới tính" name="sex" required>
                                                <Select placeholder="Chọn" allowClear>
                                                    <Option key="1241" value="1">
                                                        Nam
                                                    </Option>
                                                    <Option key="1230" value="0">
                                                        Nữ
                                                    </Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    )
                                }
                                <Col span={8}>
                                    <Form.Item
                                        name="phoneNumber"
                                        label="Số điện thoại"
                                        required
                                        rules={[
                                            { required: true, message: "Thông tin không được để trống" },
                                            {
                                                pattern: getRegexMobile(),
                                                message: "Vui lòng nhập đúng số điện thoại",
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Nhập" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        name="email"
                                        label="Email"
                                        required
                                        rules={[
                                            // { required: true, message: "Thông tin không được để trống" },
                                            {
                                                pattern: getRegexEmail(),
                                                message: "Email sai định dạng",
                                            },
                                            {
                                                max: 50,
                                                message: "Email không được quá 50 ký tự",
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Nhập" disabled={true}  />
                                    </Form.Item>
                                </Col>


                                {
                                    !props?.isCompany&&(
                                        <Col span={8}>
                                            <Form.Item label="Ngày sinh" name="dateOfBirth">
                                                <DatePicker
                                                    placeholder="Chọn"
                                                    format="DD/MM/YYYY"
                                                    allowClear
                                                />
                                            </Form.Item>
                                        </Col>

                                    )
                                }
                                {
                                    !props?.isCompany&&(
                                        <Col span={8}>
                                            <Form.Item
                                                name="taxNumber"
                                                label="Căn cước công dân"
                                                rules={[
                                                    // { required: true, message: "Thông tin không được để trống" },
                                                    {
                                                        pattern: getRegexTax(),
                                                        message: "Số căn cớc sai định dạng",
                                                    },
                                                    {
                                                        max: 12,
                                                        message: "Số căn cước không quá 12 ký tự",
                                                    },
                                                ]}
                                            >
                                                <Input  placeholder="Nhập"  />
                                            </Form.Item>
                                        </Col>

                                    )
                                }
                                {
                                props?.isCompany&&(
                                    <Col span={8}>
                                        <Form.Item
                                            name="taxNumber"
                                            label="Mã số thuế"
                                            rules={[
                                                // { required: true, message: "Thông tin không được để trống" },
                                                {
                                                    pattern: getRegexTax(),
                                                    message: "Mã số thuế sai định dạng",
                                                },
                                                // {
                                                //     max: 50,
                                                //     message: "Số căn cước không được quá 50 ký tự",
                                                // },
                                            ]}
                                        >
                                            <Input  placeholder="Nhập"  />
                                        </Form.Item>
                                    </Col>

                                )
                            }

                                {/*address */}
                                <Col span={8}>
                                    <Form.Item label="Tỉnh/Thành Phố" name="provinceID">
                                        <Select placeholder="Chọn" allowClear  onChange={onChangeProvince}>
                                            {
                                                listProvince.map((province)=>(
                                                    <Option key={province.regionID} value={province.regionID}>{province.regionName}</Option>
                                                ))
                                            }

                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item label="Quận/Huyện" name="districtID">
                                        <Select placeholder="Chọn" allowClear  onChange={onChangeDistrict}>
                                            {
                                                listDistrict.map((district)=>(
                                                    <Option key={district.regionID} value={district.regionID}>{district.regionName}</Option>
                                                ))
                                            }

                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Xã/Phường" name="wardID">
                                        <Select placeholder="Chọn" allowClear>
                                            {
                                                listWard.map((district)=>(
                                                    <Option key={district.regionID} value={district.regionID}>{district.regionName}</Option>
                                                ))
                                            }

                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="Số nhà/tổ/thôn/xóm... "
                                        name="address"
                                        rules={[
                                            {
                                                max: 100,
                                                message: "Địa chỉ không được quá 50 ký tự",
                                            },
                                        ]}

                                    >
                                        <Input placeholder="Số nhà/tổ/thôn/xóm... " />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </SpinCustom>
            </CustomModal>
        </>
    );
};

