import React, {useEffect, useState} from 'react';

import http from "../../../../services";
import {OrdersHistoryListTable} from "../List/OrdersHistoryListTable";
import Button from "../../../../components/MyButton/Button";
import CustomModal from 'src/components/Modal/CustomModal';
export const OrdersHistoryTab = ({userID,isShow,setIsShow})=>{
    const [data,setData]=useState([]);
    const [totalPage,setTotalPage]=useState(1)
    const [pagination, setPagination] = useState({
        currentPage: 1,
        keyword: "",
        status:0,
        pageSize:10

    })
    const getData = () => {
        http.post(`Order/GetListOrderByCustomerId?UserID=${userID}`,pagination)
            .then(res => {
                if (!res?.isError) {
                    setData(res.object.list)
                    setTotalPage(res.object.count);
                }else{
                    setData([]);
                }

            })
    }
    useEffect(() => {
        getData();
    }, [pagination]);
    const handleChangePage=(model) => {
        setPagination(model)
    }
    const handleFilter=(model)=>{
        setPagination(model);
    }
    const onChangeKeyword = (key) => {

        setData([]);
        const newPagi = {...pagination, search: key, currentPage: 1};
        setPagination(newPagi);
    }
    const renderFooter = () => (
        <div className="d-flex justify-content-flex-end">
            <Button
                className="btn-hover-shadow"
                onClick={() => setIsShow(false)}
            >
                Đóng
            </Button>
        </div>
    )
    return(
        <CustomModal
        title={"Lịch sử đơn hàng"}
        footer={renderFooter()}
        width={1800}
        open={!!isShow}
        onCancel={()=>setIsShow(false)}
    >
        <OrdersHistoryListTable
            data={data}
            totalPage={totalPage}
            pagination={pagination}
            onChangeKeyword={onChangeKeyword}
            changePage={handleChangePage}
            onFilter={handleFilter}
        />
        </CustomModal>
    )
}

