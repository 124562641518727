import './inforCard.css'
import React from "react";

export default function InforCard_PC() {
    return (
        <div id="SECTION159" className="ladi-section">
            <div className="ladi-section-background"></div>
            <div className="ladi-overlay"></div>
            <div className="ladi-container">
                <div id="LINE160" className="ladi-element">
                    <div className="ladi-line">
                        <div className="ladi-line-container"></div>
                    </div>
                </div>
                <div id="HEADLINE161" className="ladi-element"><h2 className="ladi-headline ladi-transition pri">LỢI ÍCH
                    KHÁCH HÀNG SỬ DỤNG BẢN QUYỀN</h2></div>
                <div id="PARAGRAPH162" className="ladi-element">
                    <div className="ladi-paragraph ladi-transition">Những điều này bạn sẽ cảm nhận được khi đã sử dụng
                        sản phẩm do chúng tôi cung cấp
                    </div>
                </div>
                <div id="IMAGE163" className="ladi-element">
                    <div className="ladi-image ladi-transition">
                        <div className="ladi-image-background"></div>
                    </div>
                </div>
                <div id="IMAGE164" className="ladi-element">
                    <div className="ladi-image ladi-transition">
                        <div className="ladi-image-background"></div>
                    </div>
                </div>
                <div id="GROUP165" className="ladi-element">
                    <div className="ladi-group">
                        <div id="PARAGRAPH166" className="ladi-element">
                            <div className="ladi-paragraph ladi-transition pri">Hoạt động ổn định</div>
                        </div>
                        <div id="PARAGRAPH167" className="ladi-element">
                            <div className="ladi-paragraph ladi-transition">Sẽ không còn những hiện tượng giật lag, đòi
                                bản quyền, đòi key, xanh màn hay tự khởi động lại nữa.
                            </div>
                        </div>
                        <div id="LINE168" className="ladi-element">
                            <div className="ladi-line">
                                <div className="ladi-line-container"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="GROUP169" className="ladi-element">
                    <div className="ladi-group">
                        <div id="PARAGRAPH170" className="ladi-element">
                            <div className="ladi-paragraph ladi-transition pri">Hỗ trợ tính năng mới nhất</div>
                        </div>
                        <div id="PARAGRAPH171" className="ladi-element">
                            <div className="ladi-paragraph ladi-transition">Liên lục cập nhật (với Windows bản quyền) và
                                thương thích ngược đối với các bản Office hiện tại và phiên bản trước
                            </div>
                        </div>
                        {/*<div id="LINE172" className="ladi-element">*/}
                        {/*    <div className="ladi-line">*/}
                        {/*        <div className="ladi-line-container"></div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div id="GROUP173" className="ladi-element">
                    <div className="ladi-group">
                        <div id="PARAGRAPH174" className="ladi-element">
                            <div className="ladi-paragraph ladi-transition pri">Hỗ trợ cài đặt</div>
                        </div>
                        <div id="PARAGRAPH175" className="ladi-element">
                            <div className="ladi-paragraph ladi-transition">Chúng tôi hỗ trợ kích hoạt bản quyền đối với
                                khách hàng thông qua phần mềm điều khiển màn hình từ xa (Teamviewer hoặc Ultraviewer)
                            </div>
                        </div>
                        {/*<div id="LINE176" className="ladi-element">*/}
                        {/*    <div className="ladi-line">*/}
                        {/*        <div className="ladi-line-container"></div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div id="GROUP177" className="ladi-element">
                    <div className="ladi-group">
                        <div id="PARAGRAPH178" className="ladi-element">
                            <div className="ladi-paragraph ladi-transition pri">Hiệu năng cao</div>
                        </div>
                        <div id="PARAGRAPH179" className="ladi-element">
                            <div className="ladi-paragraph ladi-transition">Phần mềm bản quyền không can thiệp hệ thống,
                                nên hoạt động ở mức độ tối đa theo thiết lập nhà sản xuất
                            </div>
                        </div>
                        <div id="LINE180" className="ladi-element">
                            <div className="ladi-line">
                                <div className="ladi-line-container"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="IMAGE181" className="ladi-element">
                    <div className="ladi-image ladi-transition">
                        <div className="ladi-image-background"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}
