import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "src/config/axiosConfig";
import STORAGE, {getStorage} from "../lib/storage";

const initialState = {
    cartItems: [],
}
export interface CartItemDTO{
    "productItemID": string,
    "userID": string,
    "quantity": number
}

export const getCart = createAsyncThunk('cart/getCart', async () => {
    try {
        const userInfo = JSON.parse(localStorage.getItem(STORAGE.USER_INFO))
        const userId = userInfo.userID;
        const response = await axios.post(`Cart/GetAllCartItems?userId=${userId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
});
export const addToCart = createAsyncThunk('cart/addToCart', async (model:CartItemDTO) => {
    try {
        const response = await axios.post(`Cart/InsertCartItem`,model);
        return response.data;
    } catch (error) {
        throw error;
    }
});
export const updateCart = createAsyncThunk('cart/updateCart', async (model:CartItemDTO) => {
    try {
        const response = await axios.post(`Cart/UpdateCartItem`,model);
        return response.data;
    } catch (error) {
        throw error;
    }
});
export const clearCart = createAsyncThunk('cart/deleteCart', async (userId) => {
    try {
        const response = await axios.post(`Cart/DeleteCart?userId=${userId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
});
export const deleteCartItem = createAsyncThunk('cart/deleteCartItem', async (model:CartItemDTO) => {
    try {
        const response = await axios.post(`Cart/DeleteCartItem`,model);
        return response.data;
    } catch (error) {
        throw error;
    }
});

export const cartSlice = createSlice({

    name: "cart",
    initialState,
    reducers: {
        // insertCart: (state, action) => {
        //
        //     state.cartItems = [...action.payload]
        // }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCart.fulfilled, (state, action) => {
                // console.log('get Cart',action.payload.object)
                state.cartItems = action.payload.object;
            })
            .addCase(getCart.rejected, (state, action) => {
                // Xử lý lỗi khi yêu cầu AJAX bị từ chối
                state.cartItems=[];
            })
            .addCase(addToCart.fulfilled, (state, action) => {

                // state.cartItems = action.payload.Object;
            })
            .addCase(addToCart.rejected, (state, action) => {
                // state.cartItems=[];
            })
            .addCase(updateCart.fulfilled, (state, action) => {

                // state.cartItems = action.payload.Object;
            })
            .addCase(updateCart.rejected, (state, action) => {

                // state.cartItems=[];
            })
        ;
    }
})

export const {insertCart} = cartSlice.actions

export default cartSlice.reducer
