import CustomModal from "../../../../components/Modal/CustomModal";
import SpinCustom from "../../../../components/Spin";
import {Col, DatePicker, Form, Input, InputNumber, Radio, RadioChangeEvent, Row} from "antd";
import Button from "../../../../components/MyButton/Button";
import Styled from "../../../../components/StatusLabel/Styled";
import React, {useEffect, useState} from "react";
import {EMPTY_GUID, GUIDE_EMPTY, PROMOTE_CODE_TYPE, USER_MODE_LIST} from "../../../../constants/constants";
import {ProductSearch} from "./ProductSearch";
import {UsersSelected} from "../../ListUser/modal/UserSelectedList";
import http from "../../../../services";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import moment from "moment";
const defaultObject = {
    "promoteCode": "123",
    "promoteValue": 0,
    "promoteType": "1",
    "expiredDateCode": moment(), // Change this line
    "isDelete": false,
    "createDate": dayjs(),
    "productID": "",
    "productName": "",
    "productItemID": "",
    "productItemName": "",
    "promoteUsers": []
};
const defaultUser={
    "userID": "00000000-0000-0000-0000-000000000000",
    "isDelete": false,
    "quantityPromoteCode": 1,
    "username": "All user"
}
export const PromoteDetail=({voucher, open, cancel,handleRefreshUpdate})=>{

    const [form] = Form.useForm();
    const categories: TabsProps['categoriesItem'] = [

        {
            value:PROMOTE_CODE_TYPE.VOUCHER ,
            label: 'Mã giảm giá',
        },
        {
            value: PROMOTE_CODE_TYPE.MA_GIA,
            label: 'Mã giá',
        }
    ];
    const [loading, setLoading] = useState(false);
    const [usersParam, setUsersParam] = useState([]);
    const [productParam, setProductParam] = useState([]);
    const [voucherModel, setVoucherModel] = useState(defaultObject);
    const [userQuantity, setUserQuantity] = useState(1);
    const [userMode, setUserMode] = useState(0);
    const [usersModel, setUsersModel] = useState(defaultUser);
    useEffect(() => {
        if (voucherModel && voucherModel.expiredDateCode) {
            const convertedDate = dayjs(voucherModel.expiredDateCode).format("YYYY-MM-DD HH:mm:ss");
            form.setFieldsValue({ ...voucherModel, expiredDateCode: dayjs(convertedDate) });

            setProductParam(voucherModel.productItemID);
        }
    }, [voucherModel, form]);

    useEffect(() => {
        const copiedVoucher = { ...voucher,expiredDateCode: moment(voucher?.expiredDateCode||moment()) };
        setVoucherModel(copiedVoucher);
        if(copiedVoucher?.promoteUsers[0]?.userID==EMPTY_GUID){
            setUserMode(0);
            setUserQuantity(copiedVoucher?.promoteUsers[0]?.quantityPromoteCode);
            setUsersModel([]);
        }else{
            setUserMode("1");
            setUsersModel(copiedVoucher.promoteUsers);
        }
    },[voucher]);
    const [price, setPrice] = useState(-1);
    const renderFooter = () => (
        <div className="d-flex justify-content-flex-end">
            <Button
                btnType="primary"
                className="btn-hover-shadow"
                onClick={()=>handleSubmit()}
            >
                Lưu
            </Button>
            <Button
                className="btn-hover-shadow"
                onClick={()=>cancel()}
            >
                Đóng
            </Button>
        </div>
    )
    const refreshUsers=(model)=>{
        setUsersParam(model);
    }

    const refreshProductParam = (model,price) => {
        setProductParam(model);
        setPrice(price);
    }

    const handleSubmit = () => {
        form.validateFields().then(values => {
            const expired=values.expiredDateCode?dayjs(values.expiredDateCode).format('YYYY-MM-DDTHH:mm:ss').toString():undefined;
            if(userMode!==0){
                const dateObject = moment(values.expiredDateCode).toDate();
                const model = {
                    promoteCode: values.promoteCode,
                    // productItemID: voucherModel.productItemID,
                    promoteValue: values?.promoteValue,
                    // productItemPrice: price,
                    // promoteType: PROMOTE_CODE_TYPE.VOUCHER,
                    expiredDateCode: expired,
                    addPromoteUserRequests: usersParam,
                }
                http.post('Promote/EditPromote', model).then(value => {
                    if(!value.isError){
                        showSuccess("Cập nhật mã giảm giá thành công");
                        handleRefreshUpdate();
                        cancel();
                    }
                }).finally(() => {

                });
            }else{
                const dateObject = moment(values.expiredDateCode).toDate();
                const model = {
                    promoteCode: values.promoteCode,
                    // productItemID: voucherModel.productItemID,
                    promoteValue: values?.promoteValue,
                    // productItemPrice: price,
                    // promoteType: PROMOTE_CODE_TYPE.VOUCHER,
                    expiredDateCode: expired,
                    addPromoteUserRequests: [
                        {
                            "userID": GUIDE_EMPTY,
                            "quantityPromoteCode": userQuantity
                        }
                    ]
                }
                http.post('Promote/EditPromote', model).then(value => {
                    if(!value.isError){
                        showSuccess("Cập nhật mã giá thành công");
                        handleRefreshUpdate();
                        cancel();
                    }
                }).finally(() => {

                });
            }

        })

    }
    const showSuccess = (message) => {
        Swal.fire({
            icon: 'success',
            title: 'Thành Công',
            text: message,
        })
    };
    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
        // Can not select days before today and today
        return current && current < dayjs().endOf('day');
    };

    const disabledDateTime = () => ({
        disabledHours: () => range(0, 24).splice(4, 20),
        disabledMinutes: () => range(30, 60),
        disabledSeconds: () => [55, 56],
    });
    const range = (start: number, end: number) => {
        const result = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    };
    const expiredDate = voucherModel?.expiredDateCode
        ? moment(voucherModel.expiredDateCode)
        : null;
    const onChangeUserMode = ({ target: { value } }: RadioChangeEvent) => {
        setUserMode(value);
    };
    return(
        <>
            <CustomModal
                title={"Cập nhật mã giá"}
                footer={renderFooter()}
                width={1600}
                open={!!open}
                onCancel={cancel}
            >
                <SpinCustom spinning={loading}>
                    <Styled>
                        <Form
                            form={form}
                            layout="vertical"
                        >

                            <Row gutter={[24]} style={{marginTop: '15px'}}>
                                <Col span={12}>
                                    <Form.Item
                                        name="promoteCode"
                                        label="Mã"
                                        required
                                        rules={[{ required: true, message: "Mã không được để trống" },
                                            {
                                                pattern: /^[^\s]+$/,
                                                message: "Mã không được chứa dấu cách",
                                            },]}

                                    >
                                        <Input placeholder="Nhập mã giá" disabled={true} />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        name="promoteValue"
                                        label="Giá trị mã"
                                        required
                                        rules={[
                                            { required: true, message: "Giá trị của mã phải lớn hơn 0đ" },
                                            { type: 'number', message: "Vui lòng nhập một số hợp lệ" },
                                            { validator: (_, value) => value < 1000000000000 ? Promise.resolve() : Promise.reject('Giá trị phải nhỏ hơn 1,000,000,000,000') },
                                        ]}
                                    >
                                        <InputNumber
                                            defaultValue={0}
                                            formatter={(value) =>
                                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " ₫"
                                            }
                                            parser={(value) => {
                                                const parsedValue = value.replace(/\s?₫|(,*)/g, "");
                                                return isNaN(parsedValue) ? 0 : parseInt(parsedValue, 10);
                                            }}
                                            min={0}
                                            step={1000}
                                            max={1000000000000}
                                        />
                                    </Form.Item>

                                </Col>
                            </Row>
                            <Row gutter={[24]} style={{marginTop: '15px'}}>
                                <Col span={12}>
                                    <Form.Item
                                        name="category"
                                        label="Chọn sản phẩm"
                                        required
                                        // rules={[
                                        //     {
                                        //         required: !productParam,
                                        //         message: 'Vui lòng chọn sản phẩm',
                                        //     },
                                        // ]}
                                    >
                                        <ProductSearch refreshProductParam={refreshProductParam}
                                                       productName={voucherModel.productName}
                                                       productItemID={voucherModel.productItemID}
                                                       type={-1}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="expiredDateCode"
                                        label="Ngày hết hạn"
                                        required
                                        rules={[{required: true, message: "Hãy chọn ngày hết hạn"}]}
                                    >
                                        <DatePicker
                                            placeholder="Chọn ngày hết hạn"
                                            format="DD-MM-YYYY HH:mm:ss"
                                            // disabledDate={disabledDate}
                                            showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }}
                                            value={form.getFieldValue("expiredDateCode")}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[24]} style={{marginTop: '15px'}}>
                                <Col span={12}>
                                    {
                                        userMode==1&&(
                                            <Form.Item
                                                name="userQuantity"
                                                label="Danh sách người dùng"
                                                required
                                                rules={[
                                                    {
                                                        required: usersParam&&usersParam.length < 1,
                                                        message: 'Vui lòng nhập danh sách người dùng',
                                                    },
                                                ]
                                                }

                                            >
                                                <div style={{display:"flex",justifyContent:"flex-start"}}>
                                                    <Radio.Group
                                                        className={"d-flex-start"}
                                                        options={USER_MODE_LIST}
                                                        onChange={onChangeUserMode}
                                                        value={userMode}
                                                        optionType="button"
                                                        buttonStyle="solid"
                                                    />
                                                </div>
                                                {
                                                    <UsersSelected  refreshUsers={refreshUsers} initUsers={usersModel}/>
                                                }
                                            </Form.Item>
                                        )
                                    }
                                    {
                                        userMode==0&&(
                                            <>
                                                <Form.Item
                                                    name="userQuantity"
                                                    label="Danh sách người dùng"
                                                    required
                                                    rules={[
                                                        {
                                                            required: userQuantity<1,
                                                            message: 'Vui lòng nhập số lượng lớn hơn 0'
                                                        },
                                                    ]
                                                    }

                                                >
                                                    <div className={"d-flex-start"} style={{marginBottom:16}}>
                                                        <Radio.Group
                                                            className={"d-flex-start"}
                                                            options={USER_MODE_LIST}
                                                            onChange={onChangeUserMode}
                                                            value={userMode}
                                                            optionType="button"
                                                            buttonStyle="solid"
                                                        />
                                                    </div>
                                                    <div className={"d-flex-start"}>
                                                        <label aria-required={true} style={{marginRight:10}}>Nhập số lượng</label>
                                                        <InputNumber min={1} value={userQuantity} onChange={(e)=>setUserQuantity(e)}/>
                                                    </div>

                                                </Form.Item>
                                            </>
                                        )
                                    }
                                </Col>
                            </Row>
                        </Form>
                    </Styled>
                </SpinCustom>

            </CustomModal>


        </>
    )
}
