import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    isReload: false,
    isReloadListChat: false,
}

export const chatRedux = createSlice({
  name: "chatRedux",
  initialState,
  reducers: {
    setReload: (state, action) => {
      state.isReload = action.payload
    },
    setReloadListChat: (state, action) => {
      state.isReloadListChat = action.payload
    },
  },
})

export const {
    setReload,
    setReloadListChat
} = chatRedux.actions

export default chatRedux.reducer
