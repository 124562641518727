import { Col, Form, Input, Row, Select, Spin } from "antd"
import React, { useEffect, useState } from "react"
import SelectAddress from "src/components/SelectAddress"
import { getRegexEmail, getRegexMobile } from "src/lib/stringsUtils"
import RegionService from "src/services/RegionService"

const StepModal2 = ({ form }) => {
  const [listAddress, setListAddress] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getListAddress()
  }, [])

  const format = items =>
    items.map(item => ({
      ...item,
      key: item.RegionID,
      value: item.RegionID,
      title: item.RegionName,
      isLeaf: item.RegionLevel === 4,
    }))

  const getListAddress = async () => {
    try {
      setLoading(true)
      const res = await RegionService.getByRegionId({ regionId: 234 })
      if (res.isOk) {
        const data = format(res.Object)
        setListAddress(data)
      }
    } finally {
      setLoading(false)
    }
  }
  return (
    <Spin spinning={loading}>
      {" "}
      <Form form={form} layout="vertical">
        <Row gutter={16}>
          <Col span={24}>
            <div className="step-title">
              <div className="step">BƯỚC 2 : THÔNG TIN CÔNG TY</div>
              <div className="note">
                Thông tin doanh nghiệp của Quý Công ty trên website
              </div>
            </div>
          </Col>
          <Col span={18}>
            <Form.Item
              label="TÊN CÔNG TY"
              name="AccountName"
              rules={[
                { required: true, message: "Thông tin không được để trống!" },
              ]}
            >
              <Input placeholder="Nhập" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="LOẠI HÌNH HOẠT ĐỘNG"
              name="ActivityType"
              rules={[
                { required: true, message: "Thông tin không được để trống!" },
              ]}
            >
              <Select>
                {listAddress?.map((item, idx) => (
                  <Select.Option value={item?.value} key={`${item?.key}${idx}`}>
                    {item?.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="CHỌN SỐ NHÂN VIÊN" name="NumberStaffID">
              <Select>
                {listAddress?.map((item, idx) => (
                  <Select.Option value={item?.value} key={`${item?.key}${idx}`}>
                    {item?.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="QUỐC GIA" name="Country">
              <Select>
                {listAddress?.map((item, idx) => (
                  <Select.Option value={item?.value} key={`${item?.key}${idx}`}>
                    {item?.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="TỈNH / TP" name="ProvinceID">
              <Select>
                {listAddress?.map((item, idx) => (
                  <Select.Option value={item?.value} key={`${item?.key}${idx}`}>
                    {item?.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="ĐỊA CHỈ CÔNG TY"
              rules={[
                {
                  required: true,
                  message: "Thông tin không được để trống!",
                },
              ]}
              name="Address"
            >
              <Input placeholder="Nhập" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="SƠ LƯỢC VỀ CÔNG TY"
              name="Introduce"
              rules={[
                {
                  required: true,
                  message: "Thông tin không được để trống!",
                },
              ]}
            >
              <Input.TextArea placeholder="Nhập" />
            </Form.Item>
          </Col>

          {/* <Col span={24}>
          <SelectAddress floating={false} form={form} required={true} />
        </Col> */}
          <Col span={24}>
            <Form.Item
              label="TÊN NGƯỜI LIÊN HỆ"
              name="FirstName"
              rules={[
                {
                  required: true,
                  message: "Thông tin không được để trống!",
                },
              ]}
            >
              <Input placeholder="Nhập " />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="ĐIỆN THOẠI"
              name="PhoneNumber"
              rules={[
                {
                  required: true,
                  message: "Thông tin không được để trống!",
                },
                {
                  pattern: getRegexMobile(),
                  message: "Vui lòng nhập đúng số điện thoại",
                },
              ]}
            >
              <Input placeholder="Nhập" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="MÃ SỐ THUẾ"
              rules={[
                {
                  required: true,
                  message: "Thông tin không được để trống!",
                },
              ]}
              name="TaxCode"
            >
              <Input placeholder="Nhập" />
            </Form.Item>
          </Col>
          {/* <Col span={24}>
            <Form.Item
              label="MÃ XÁC NHẬN"
              name="TaxCode"
              rules={[
                {
                  required: true,
                  message: "Thông tin không được để trống!",
                },
              ]}
            >
              <Input placeholder="Nhập" />
            </Form.Item>
          </Col> */}
        </Row>
      </Form>
    </Spin>
  )
}

export default StepModal2
