import {useEffect, useState} from "react";
import {Image, Modal} from "antd";

export const ImageViewer = ({ base64Image }) => {
    const [previewVisible, setPreviewVisible] = useState(false);
    const [content, setContent] = useState(false);

    const handlePreview = () => {
        setPreviewVisible(true);
    };

    const handleCancel = () => {
        setPreviewVisible(false);
    };
    useEffect(() => {
        const match = base64Image.match(/src="([^"]+)"/);

// Kiểm tra xem có trùng khớp không và lấy giá trị src nếu có
        const srcValue = match ? match[1] : '';
        setContent(srcValue);
    },[base64Image])

    return (
        <>
            <Image
                src={content}
                preview={{
                    mask: <div style={{ background: 'rgba(255,255,255,0.8)' }} />,
                }}
            />

        </>
    );
};

export default ImageViewer;
