import { Form, Input, Row } from "antd"
import { useContext, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import CustomModal from "src/components/Modal/CustomModal"
import Button from "src/components/MyButton/Button"
import STORAGE, { setStorage } from "src/lib/storage"
import { StoreContext } from "src/lib/store"
import { getRegexPassword } from "src/lib/stringsUtils"
import { hasPermission } from "src/lib/utils"
import { setListTabs, setUserInfo } from "src/redux/appGlobal"
import ROUTER from "src/router"
import AuthService from "src/services/AuthService"
import RoleService from "src/services/RoleService"
import MenuItemBreadcrumb from "../../MenuItems"
import { StyleLoginModal } from "../../styled"
import FormLoginSocial from "../FormLoginSocial"
import Notice from "src/components/Notice"

const LoginModal = ({
  openLoginModal,
  handleCancel,
  handleRegister,
  setOpenForgetPassModal,
  stopNavigate = false,
  idRouter,
}) => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { routerStore } = useContext(StoreContext)
  const [routerBeforeLogin, setRouterBeforeLogin] = routerStore
  const { typeMenu } = useSelector(state => state?.appGlobal)
  const comeStartPage = async () => {
    // const resp = await RoleService.getListTab()
    // if (resp.isError) return
    // dispatch(setListTabs(resp.Object))
    const responsiveTask = {
      Object: [],
    }
    const treeLabel = tree =>
      tree?.map(i => ({
        ...i,
        title: i?.label,
        children: treeLabel(i?.children),
      }))
    const items = treeLabel(MenuItemBreadcrumb(navigate, responsiveTask.Object))
    // const menuAdmin = items
    //   ?.filter(i => i?.showOnAdmin)
    //   ?.filter(x => hasPermission(x?.TabID, [...resp.Object]))
    //   ?.map(i => ({
    //     ...i,
    //     children: i?.children?.filter(x =>
    //       hasPermission(x?.TabID, [...resp.Object]),
    //     ),
    //   }))
    const menuAdmin = items
    let startPage = "/"
    if (idRouter) {
      startPage = ""
    } else if (!!menuAdmin && !!menuAdmin[0]?.children?.length) {
      startPage = menuAdmin[0]?.children[0]?.key
    } else if (!!(menuAdmin[0]?.key?.charAt(0) === "/")) {
      startPage = menuAdmin[0]?.key
    }
    navigate(startPage)
  }
  const onLogin = async () => {
    try {
      setLoading(true)
      const values = await form.validateFields()
      const res = await AuthService.login({ ...values })
      if (res?.isError) return
      if (res?.isOk) {
        setStorage(STORAGE.TOKEN, res?.object?.token)
        setStorage(STORAGE.USER_INFO, res?.object)
        dispatch(setUserInfo(res?.object))
        setRouterBeforeLogin(undefined)
        handleCancel()
        if (stopNavigate) return
        else {
          navigate(
            routerBeforeLogin
              ? routerBeforeLogin
              : res?.object?.roleID !== typeMenu
              ? comeStartPage()
              : ROUTER.HOME,
          )
        }
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <CustomModal
      title="Đăng nhập"
      width="600px"
      footer={null}
      open={openLoginModal}
      onCancel={handleCancel}
    >
      <StyleLoginModal>
        <Form form={form} layout="vertical">
          <Form.Item
            rules={[
              {
                required: true,
                message: "Bạn chưa nhập email!",
              },
              // {
              //   pattern: getRegexEmail(),
              //   message: "Email sai định dạng",
              // },
            ]}
            label="Tên tài khoản/Email đăng nhập"
            name="username"
          >
            <Input placeholder="Nhập tên tài khoản/email" />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Bạn chưa nhập mật khẩu!",
              },
              {
                pattern: getRegexPassword(),
                message:
                  "Mật khẩu có chứa ít nhất 8 ký tự, trong đó có ít nhất một số và bao gồm cả chữ thường và chữ hoa và ký tự đặc biệt, ví dụ @, #, ?, !.",
              },
            ]}
            label="Mật khẩu"
            name="password"
          >
            <Input.Password placeholder="Nhập mật khẩu" />
          </Form.Item>
          <Row className="space-between">
            {/* <Checkbox onChange={e => {}}>Nhớ thông tin</Checkbox> */}
            <div />
            <Link
              onClick={() => {
                setOpenForgetPassModal()
                handleCancel()
              }}
              className="forget-pass"
            >
              <i>Quên mật khẩu?</i>
            </Link>
          </Row>
          <Row>
            <Button
              loading={loading}
              btnType="primary"
              className="btn-login"
              type="submit"
              htmlType="submit"
              onClick={onLogin}
            >
              Đăng nhập
            </Button>
          </Row>
        </Form>

        <div className="center">Hoặc</div>
        <FormLoginSocial />

        <Row className="register">
          <span>
            Bạn chưa có tài khoản?{" "}
            <span
              className="link-regis"
              onClick={() => {
                handleCancel()
                handleRegister()
              }}
            >
              Đăng ký
            </span>{" "}
            ngay
          </span>
        </Row>
      </StyleLoginModal>
    </CustomModal>
  )
}

export default LoginModal
