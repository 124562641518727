//Authenticate
export const apiLogin = "Authservice/Login"
export const apiLogout = `Authservice/Logout`
export const apiRegister = `Authservice/Register`
export const apiTeacherRegister = `Authservice/TeacherRegister`
export const apiSchooleEnterpriseRegister = `Authservice/SchooleEnterpriseRegister`
export const apiForgotPassword = `Authservice/ForgotPassword`
export const apiChangePassword = `Authservice/ChangePassword`
export const apiVerifyCode = `Authservice/VerifyCode`
export const apiBusinessRegister = `Authservice/BusinessRegister`
export const apiCheckExistEmail = `Authservice/CheckExistEmail`
export const apiGetLinkedinLoginUrl = `Authservice/GetLinkedinLoginUrl`
export const apiEmailRegister = "Authservice/Register"

// GOOGLE
export const apiLoginGoole = "Authservice/GetGoogleLoginUrl"
export const apiCallBackLoginGoole = "Authservice/GetTokenInfo"
//FACEBOOK
export const apiLoginFB = "Authservice/GetFacebookLoginUrl"

//SMS
export const apiRegisterSMS = "RegistrationBussiness/RegisterSMS"
export const apiAuthenSMS = "RegistrationBussiness/AuthenSMS"

