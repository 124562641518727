import{
    apiHistoryPayment,
    apiInforPayment,
    apiCreatePaymentUrl,
    apiGetQRRechargeByMoney,
    apiGetAllTransactionForAdmin
} from './urls.js'
  import http from "../index"
  
  const historyPayment = body => http.post(apiHistoryPayment, body)

  const InforPayment = query => http.get(apiInforPayment,{query})

  const createPaymentUrl = body => http.post(`${apiCreatePaymentUrl}?Money=${body}`)

  const getQRRechargeByMoney = body => http.get(`${apiGetQRRechargeByMoney}?Money=${body}`)

  const getAllTransactionForAdmin = body => http.post(apiGetAllTransactionForAdmin, body)

  const RegionService = {
    historyPayment,
    InforPayment,
    createPaymentUrl,
    getQRRechargeByMoney,
    getAllTransactionForAdmin
  }
  export default RegionService
  