import {CameraOutlined, UserOutlined} from "@ant-design/icons"
import {Avatar, Button, Menu, Upload} from "antd"
import defaultBackground from "src/assets/images/background/bg-db.jpg"
import LayoutCommon from "src/components/Common/Layout"
import Notice from "src/components/Notice"
import STORAGE, {setStorage} from "src/lib/storage"
import FileService from "src/services/FileService"
import UserService from "src/services/UserService"
import {StyleDashboard} from "./styled"
import useWindowSize from "src/lib/useWindowSize"
import {HistoryPayment} from "../HistoryPayment"
import Wallet from "../Wallet"
import "src/components/Common/Layout/layout.css"
import {useEffect, useState} from "react"
import {useNavigate, useParams} from "react-router-dom"
import WalletVNPay from "../WalletVNPay"


export const FinanecDashBoard= () => {
    const userInfo = JSON.parse(localStorage.getItem(STORAGE.USER_INFO))
    const [current, setCurrent] = useState("1")
    const {id} = useParams()
    const [userDetail, setUserDetail] = useState({})
    const [loading, setLoading] = useState(false)
    const [backgroundUpload, setBackgroundUpload] = useState("")

    const items = [

        {
            label: "Nạp tiền",
            key: "1",
            icon: <UserOutlined style={{fontSize: 17}}/>,
            Node: (
                <LayoutCommon>
                    <Wallet/>
                </LayoutCommon>
            ),
        },
        {
            label: "Lịch sử giao dịch",
            key: "2",
            icon: <UserOutlined style={{fontSize: 17}}/>,
            Node: (
                <LayoutCommon>
                    <HistoryPayment/>
                </LayoutCommon>
            ),
        }
    ]
    const uploadImg = async file => {
        try {
            setLoading(true)
            const formData = new FormData()
            formData.append("file", file)
            const res = await FileService.uploadFile(formData)
            if (res.isError) return
            setBackgroundUpload(res.Object)
        } finally {
            setLoading(false)
        }
    }

    const changeBackground = async () => {
        try {
            setLoading(true)
            const res = await UserService.changeInfor({
                ...userInfo,
                Background: backgroundUpload,
            })
            if (res?.isError) return
            let obj = {
                ...userInfo,
                Background: backgroundUpload,
            }
            setStorage(STORAGE.USER_INFO, obj)
            // dispatch(setUserInfo(obj))
            getInfo()
            setBackgroundUpload("")

            Notice({
                msg: "Cập nhật ảnh bìa thành công.",
            })
        } finally {
            setLoading(false)
        }
    }

    const getInfo = () => {
        setLoading(true)
        UserService.getInforUser()
            .then(res => {
                if (res.isError) return
                setUserDetail(res.Object)
            })
            .finally(() => setLoading(false))
    }

    const navigate=useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
        getInfo();

        if(id=='nap-tien'){
            setCurrent("2");
        }else{
            // if (!!id) setCurrent(pre => id)
            // else setCurrent(pre => "1")
            // setCurrent(pre => "1");
            navigate("/nap-tien")
        }
    }, [id])
    useEffect(()=>{
        // if(current=="1"){
        //     navigate('/nap-tien');
        // }
        // if(current=="2"){
        //     navigate("/nap-tien")
        // }
    },[current])

    const onClick = e => {
        setCurrent(e.key)
    }
    return (
        <StyleDashboard>
            <div
                className="header-dashboard"
                style={{
                    backgroundImage: `url("${
                        backgroundUpload || userDetail?.Background || defaultBackground
                    }")`,
                    backgroundSize: "cover",
                }}
            >
                <LayoutCommon>
                    <div className="fw-600 fs-30 mt-25">
                        <div className="wrap-avatar justify-content-space-between d-flex div-coating">
                            <div
                                className="d-flex align-items-center justify-content-center user-avatar"
                                // style={{ padding: "0px 300px 0px 300px" }}
                            >
                                <Avatar
                                    icon={
                                        <UserOutlined style={{fontSize: "80px", color: "#fff"}}/>
                                    }
                                    size={useWindowSize.isMobile() ? 80 : 160}
                                    src={userDetail?.Avatar}
                                    className={`${useWindowSize.isMobile() ? "mt-65" : ""}`}
                                />
                                <div className="ml-20 pt-80 d-flex flex-column">
                                    <div
                                        style={useWindowSize.isMobile() ? {fontSize: "16px"} : {}}
                                    >
                                        {userInfo?.FullName}
                                    </div>
                                    <div className="fs-16">{userInfo?.Username}</div>
                                </div>
                            </div>
                            <div>
                                {/*<Upload*/}
                                {/*    beforeUpload={file => {*/}
                                {/*        uploadImg(file)*/}
                                {/*        return false*/}
                                {/*    }}*/}
                                {/*    accept="image/*"*/}
                                {/*    multiple={false}*/}
                                {/*    maxCount={1}*/}
                                {/*    fileList={[]}*/}
                                {/*>*/}
                                {/*    <div className="d-flex">*/}
                                {/*        {!backgroundUpload && (*/}
                                {/*            <Button className="btn-background btn-hide fw-600 d-flex-center">*/}
                                {/*                <CameraOutlined/> Đổi ảnh bìa*/}
                                {/*            </Button>*/}
                                {/*        )}*/}

                                {/*        {backgroundUpload && (*/}
                                {/*            <Button*/}
                                {/*                className="ml-16 btn-background"*/}
                                {/*                style={{width: 120}}*/}
                                {/*                onClick={e => {*/}
                                {/*                    e.stopPropagation()*/}
                                {/*                    changeBackground()*/}
                                {/*                }}*/}
                                {/*            >*/}
                                {/*                <div className="text-center fw-600 w-100">Lưu ảnh</div>*/}
                                {/*            </Button>*/}
                                {/*        )}*/}
                                {/*        {backgroundUpload && (*/}
                                {/*            <Button*/}
                                {/*                className="ml-16 btn-background "*/}
                                {/*                style={{width: 120}}*/}
                                {/*                onClick={e => {*/}
                                {/*                    e.stopPropagation()*/}
                                {/*                    setBackgroundUpload("")*/}
                                {/*                }}*/}
                                {/*            >*/}
                                {/*                <div className="text-center fw-600 w-100">Hủy</div>*/}
                                {/*            </Button>*/}
                                {/*        )}*/}
                                {/*    </div>*/}
                                {/*</Upload>*/}
                            </div>
                        </div>
                    </div>
                    <div className="menu-dash">
                        <Menu
                            onClick={onClick}
                            selectedKeys={[current]}
                            mode="horizontal"
                            items={items}
                        />
                    </div>
                </LayoutCommon>
            </div>
            <div className="my-user-style" style={{marginTop: "40px"}}>
                {items?.find(item => item?.key === current)?.Node}
            </div>
        </StyleDashboard>
    )
}

