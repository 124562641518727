import { Col, Empty, Pagination, Row, Tabs } from "antd"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import LayoutCommon from "src/components/Common/Layout"
import RelateToNews from "./component/RelateToNews"
import { NewsStyled } from "./styled"

import moment from "moment"
import { useEffect, useState } from "react"
import SpinCustom from "src/components/Spin"
import GuestServices from "src/services/GuestService"
import { TabsNewsStyled } from "./styled"
import PostService from "src/services/PostService"
import { GUIDE_EMPTY } from "src/constants/constants"
import { StyledHeaderSubmenu } from "../Regulation/components/HeaderSubmenu"
import { fomatTimeFromNow } from "src/lib/time"
import { NewItemImage } from "../../../components/News/NewItemImage"
import ROUTER from "src/router"

function News({ hideTitle = false, inLayout = true, title = "" }) {
  const url = window.location.href;
  const {state} = useLocation();
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [dataPost, setDataPost] = useState()
  const [pagination, setPagination] = useState()
  const [items, setItems] = useState([])
  const [itemsLevel3, setItemsLevel3] = useState([])
  const [act, setAct] = useState("")
  const [actLv3, setActLv3] = useState("")
  const [currentPost, setItemsCurrentPost] = useState("")
  const [currentCate, setCurrentCate] = useState([])

  const Element = inLayout ? LayoutCommon : Col
  const { id } = useParams()

  console.log("item", items)

  /**
   * @description set title tab web
   * @author hddung
   */
  useEffect(() => {
    if(url) {
        document.title = 'Tin tức'; // Thay đổi title thành 'New Title'
    
        return () => {
        document.title = 'TẠP HÓA SỐ'; // Đặt lại title khi component bị unmount
        };
      }
  }, [url]);

  useEffect(() => {
    if (id !== undefined){
      //Check get list theo tag hay theo post
      if(id?.startsWith('tags-')) {
        setPagination({
          CurrentPage: 1,
          PageSize: 20,
          tagsID: getGuiIdByParamURL(id),
        });
      } else {
        setPagination({
          CurrentPage: 1,
          PageSize: 20,
          categoryPostID: id,
          textSearch: state?.textSearch || ''
        });  
      }
    }
  }, [id, state])

  useEffect(() => {
    if (pagination) {
      //Check get list theo tag hay theo post
      if(id?.startsWith('tags-')) {
        getListPostByTags();
      } else {
        getListPost();
      }
    }
  }, [pagination])

  const getListPost = async () => {
    try {
      setLoading(true)
      const res = await GuestServices.getListPost({
        ...pagination,
      })
      if (res?.isError) return
      setDataPost(res?.object)
    } finally {
      setLoading(false)
    }
  }

  /**
   * @description Lấy danh sách bài viết theo tag
   * @author hddung
   */
  const getListPostByTags = async () => {
    try {
      setLoading(true)
      const res = await GuestServices.getListPostByTags({
        ...pagination,
      })
      if (res?.isError) return
      setDataPost(res?.object)
    } finally {
      setLoading(false)
    }
  }

  /**
   * @description thực hiện get guid từ chuỗi
   * @author hddung  
   * @returns 
   */
  function getGuiIdByParamURL(id) {
    if(id) {
      var regex = /([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/;
      var ketQua = id.match(regex);
      if(!ketQua) return '';
      return ketQua[ketQua.length - 1];  
    }
  }

  useEffect(() => {
    getListCategory()
    setAct(id)
  }, [id])
  useEffect(() => {
    setItems(
      !!currentCate && !!currentCate?.length
        ? [
            {
              label: currentCate?.find(item => item?.categoryPostID === id)
                ?.categoryPostName,
              key: id,
              children: <div />,
            },
            ...currentCate
              ?.filter(item => item?.parentID === id)
              ?.map((i, idx) => ({
                label: (
                  <div style={{ marginTop: "10px" }}>{i?.categoryPostName}</div>
                ),
                key: i?.categoryPostID,
                children: <div />,
              })),
          ]
        : [],
    )
  }, [currentCate])

  useEffect(() => {
    setItemsLevel3(
      !!(
        !!currentCate &&
        !!currentCate?.length &&
        !!currentCate?.find(item => item?.parentID === currentPost) &&
        !!(
          currentCate?.find(item => item?.categoryPostID === currentPost)
            ?.level !== 1
        )
      )
        ? [
            {
              label: "Tất cả",
              key: "-1",
              children: <div />,
            },
            ...currentCate
              ?.filter(item => item?.parentID === currentPost)
              ?.map(i => ({
                label: i?.categoryPostName,
                key: i?.categoryPostID,
                children: <div />,
              })),
          ]
        : [],
    )
  }, [currentPost])

  useEffect(() => {
    window?.scroll(0, 0)
  }, [])

  const getListCategory = async () => {
    try {
      const res = await GuestServices.guestGetListCate()
      if (res?.isError) return
      setCurrentCate(res?.object?.data || [])
    } finally {
    }
  }

  return (
    <NewsStyled>
      <Element>
        {/* {!hideTitle && (
          <div className="title-type-1">
            {title || LIST[id]?.categoryPostName}
          </div>
        )} */}
        <SpinCustom spinning={loading}>
          <div className="section-box">
            <div className="breadcrumbs-div" style={{marginBottom: '0px', padding: '16px 0px 16px 0px'}}>
                <div className="container">
                    <ul className="breadcrumb">
                        <li><a className="font-xs color-gray-500" onClick={() => {
                            navigate(ROUTER.HOME)
                        }}>Trang chủ</a></li>
                        <li><a className="font-xs color-gray-1000" onClick={() => {
                            navigate('')
                        }}>Tin tức</a></li>
                    </ul>
                </div>
            </div>
          </div>
          <Row gutter={[24, 0]}>
            <Col span={24}>
              {!!items && !!items?.length && (
                <StyledHeaderSubmenu>
                  <TabsNewsStyled>
                    <Tabs
                      activeKey={act}
                      items={items}
                      onChange={value => {
                        setAct(value)
                        setActLv3("-1")
                        setItemsCurrentPost(value)
                        setPagination(prev => ({
                          ...prev,
                          categoryPostID: value,
                        }))
                      }}
                    />
                  </TabsNewsStyled>
                </StyledHeaderSubmenu>
              )}
            </Col>
            <Col xs={24} md={16}>
              <Col span={24}>
                {!!(!!currentPost && !!itemsLevel3?.length) ? (
                  <TabsNewsStyled>
                    <Tabs
                      activeKey={actLv3}
                      items={itemsLevel3}
                      onChange={value => {
                        setActLv3(value)
                        setPagination(prev => ({
                          ...prev,
                          categoryPostID: value,
                        }))
                      }}
                    />
                  </TabsNewsStyled>
                ) : (
                  <div />
                )}
              </Col>
              {dataPost?.data?.length ? (
                dataPost?.data?.map((i, idx) => (
                  <NewItemImage
                    item={{
                      ...i,
                      image: i?.image,
                      title: i?.title,
                      time:
                        i?.publicationTime &&
                        fomatTimeFromNow(i?.publicationTime),
                      // moment(i?.PublicationTime).format(
                      //   "HH:mm:ss DD/MM/YYYY",
                      // ),
                      content: i?.summary,
                    }}
                    key={i?.postID}
                    onClick={() => {
                      window.scrollTo({ top: 0, left: 0 })
                      navigate(`/${i.slug}-${i.postID}`)
                    }}
                  />
                ))
              ) : (
                <Empty description="Không có bài viết" />
              )}
              {!!dataPost?.total && (
                <div className="d-flex justify-content-flex-end">
                  <Pagination
                    className="d-flex"
                    total={dataPost?.total}
                    hideOnSinglePage={dataPost?.total <= 10}
                    current={pagination?.CurrentPage}
                    pageSize={pagination?.PageSize || 1}
                    responsive
                    locale={{ items_per_page: "" }}
                    showSizeChanger={dataPost?.total > 10}
                    onChange={(CurrentPage, PageSize) =>
                      setPagination({
                        ...pagination,
                        CurrentPage,
                        PageSize,
                      })
                    }
                  />
                </div>
              )}
            </Col>
            <Col xs={24} md={8}>
              <RelateToNews />
            </Col>
          </Row>
        </SpinCustom>
      </Element>
    </NewsStyled>
  )
}
export default News
