import styled from "styled-components";

export const CardPreviewStyled = styled.div`
    display: flex;
    width: 243px;
    height: 96px;
    border: 1px solid #D5DFE4;
    border-radius: 4px;
    padding: 15px;
    &:hover{
        background-color: #F4F7FC;
        border: 1px solid #8C9EC5;
    }
    .name{
        font-family: "DM Sans", sans-serif;
        font-size: 20px;
        line-height: 28px;
        font-weight: bold;
    }
`