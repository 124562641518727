import http from "../index"
import {
  apiSchooleEnterpriseRegister,
  apiTeacherRegister,
  apiBusinessRegister,
  apiChangePassword,
  apiForgotPassword,
  apiLogin,
  apiLogout,
  apiRegister,
  apiVerifyCode,
  apiLoginGoole,
  apiCallBackLoginGoole,
  apiLoginFB,
  apiCheckExistEmail,
  apiGetLinkedinLoginUrl,
  apiEmailRegister,
  apiRegisterSMS,
  apiAuthenSMS,
} from "./urls"
import QueryString from "qs"

const login = body => http.post(apiLogin, body)
const register = body => http.post(apiRegister, body)
const forgotPass = body => http.post(apiForgotPassword, body)
const verifyCode = body => http.post(apiVerifyCode, body)
const changePassword = body => http.post(apiChangePassword, body)

const TeacherRegister = body => http.post(apiTeacherRegister, body)
const SchooleEnterpriseRegister = body =>
  http.post(apiSchooleEnterpriseRegister, body)

const logout = () => http.get(apiLogout)
const loginGG = () => http.get(apiLoginGoole)
const loginFB = () => http.get(apiLoginFB)
const loginLinked = () => http.get(apiGetLinkedinLoginUrl)

const callbackGG = params => http.get(apiCallBackLoginGoole, { params })

const businessRegister = body => http.post(apiBusinessRegister, body)
const checkExistEmail = body => {
  const params = QueryString.stringify(body)
  return http.get(`${apiCheckExistEmail}?${params}`)
}
const emailRegister = body => http.post(apiEmailRegister,body)

const registerSMS = body => http.post(apiRegisterSMS,body)
const authenSMS = body => http.post(apiAuthenSMS,body)


const AuthService = {
  loginGG,
  callbackGG,
  login,
  logout,
  register,
  TeacherRegister,
  SchooleEnterpriseRegister,
  forgotPass,
  verifyCode,
  businessRegister,
  changePassword,
  loginFB,
  checkExistEmail,
  loginLinked,
  emailRegister,
  registerSMS,
  authenSMS,
}
export default AuthService
