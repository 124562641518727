import {Button} from "antd";
import {ORDER_STATUS} from "../../../../constants/constants";
import "./orderStatus.css"
import {useState} from "react";
import {useNavigate} from "react-router-dom";
export const OrderStatusBox = ({title,showContract,showCancelModal,handleShowFeedBackModal,productId,slug}) => {
    const navigate=useNavigate();
    const renderButton = () => {
        switch (title) {
            case ORDER_STATUS.CHO_XAC_NHAN:
                return <Button className="box__item" onClick={()=>showCancelModal()}>Huỷ đơn hàng</Button>;
            case ORDER_STATUS.CHO_KY:
                return (
                    <div className="box-item__container">
                        <Button className="box__item sign-btn" onClick={()=>showContract()}>Ký hợp đồng</Button>
                        <Button className="box__item" onClick={()=>showCancelModal()}>Huỷ đơn hàng</Button>
                    </div>
                );
            case ORDER_STATUS.HOAN_THANH:
                return (
                    <div className="box-item__container">
                        <Button className="box__item" onClick={()=>navigate(`/${slug}-${productId}`)}>Mua Lại</Button>
                        <Button className="box__item rate-btn" onClick={()=>handleShowFeedBackModal()} >Đánh giá</Button>
                    </div>
                );
            default:
                return null;
        }
    };
    return (
        <div className={"box__container"}>
            {renderButton(title)}
        </div>
    )

}
