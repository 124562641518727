import { Spin,BackTop } from "antd"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import STORAGE, { getStorage } from "src/lib/storage"
import CommonService from "src/services/CommonService"
import "./App.css"
import ModalLoading from "./components/Modal/Loading"
import { getListSystemKey, setBalance, setListTabs, setUserInfo } from "./redux/appGlobal"
import AppRouter from "./router/AppRouter"
import RoleService from "./services/RoleService"
import GuestServices from "./services/GuestService"
import ChatBox from "./pages/ChatBox/chatBox"
import { setBanner, setEvent, setFooter } from "./redux/banner"
import { ACCOUNT_TYPE_ID } from "./constants/constants"
import 'react-toastify/dist/ReactToastify.css';
//import "../node_modules/font-awesome/css/font-awesome.min.css"
import { Analytics } from "@vercel/analytics/react"
import "./assets/fontawesome-5/css/fontawesome.css"
import "./assets/fontawesome-5/js/fontawesome"
import 'antd/dist/reset.css';
import SignalRClient from './signalR'
import UserService from "./services/UserService"



function App() {
  const { token } = useSelector(state => state.appGlobal);
  const isLogin = getStorage(STORAGE.TOKEN);
  const dispatch = useDispatch()
  const { modalLoading } = useSelector(state => state.common)
  const { chatUserInfor } = useSelector(state => state.appGlobal)
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // getBaner()
  }, [])
  const getBaner = async () => {
    const res = await GuestServices.getListBanner()
    if (res?.isError) return
    dispatch(setBanner(res?.object?.BannerFooter?.ListBanner))
    dispatch(setBanner(res?.object?.ListBanner))
    dispatch(
      setFooter({
        Footer: res?.object?.Footer,
        Map: res?.object?.Map,
      }),
    )
    const resEvent = await GuestServices.getListEvent()
    dispatch(setEvent(resEvent?.object))
  }
  useEffect(() => {
    if (!!isLogin || (!!token && !isLogin)) getData()
  }, [isLogin, token])

  const getSystemKey = async () => {
    const res = await CommonService.getSystemKey("All")
    if (res.isError) return
    dispatch(getListSystemKey(res.object))
  }
  const getBalanceUser = async () => {
    const res = await UserService.getInforUser();
    dispatch(setBalance(res?.object?.accountBalance || 0));
  }
  const getData = async () => {
    try {
      setLoading(true)
      dispatch(setUserInfo(getStorage(STORAGE.USER_INFO)))
      if (getStorage(STORAGE.USER_INFO)?.roleID !== ACCOUNT_TYPE_ID.HocVien) {
        const resp = await RoleService.getListTab()
        console.log('testttttttttt: ', resp.isOk)
        //if (resp.isOk) {
          console.log('testttttttttt: ', resp)
          console.log('testttttttttt: ', resp.object)
          dispatch(setListTabs(resp.object))
        //}
      }
      getSystemKey()
      getBalanceUser()
    } finally {
      setLoading(false)
    }
  }


  return (
    <>
      <div className="layout-center">
        <div className="layout-max-width">
          {loading ? (
            <div className="loading-center" style={{ height: "100vh" }}>
              <Spin />
            </div>
          ) : (
            <AppRouter />
          )}
        </div>
        {!!modalLoading && <ModalLoading />}

        {chatUserInfor?.isShow && <ChatBox user={chatUserInfor?.userData}/>}

        <SignalRClient />
      </div>
      <Analytics />
    </>
  )
}

export default App
