import { UserOutlined } from "@ant-design/icons"
import { Anchor, Avatar, Col, Divider, Row, Space, Table, Tooltip } from "antd"
import { useEffect, useState } from "react"
import { FloatActionWrapper } from "src/components/FloatAction/styles"
import CB1 from "src/components/Modal/CB1"
import Button from "src/components/MyButton/Button"
import ButtonCircle from "src/components/MyButton/ButtonCircle"
import Notice from "src/components/Notice"
import SpinCustom from "src/components/Spin"
import TableCustom from "src/components/Table/CustomTable"
import {
  MainTableData,
  MainTableHeader,
  SubTableData,
  SubTableHeader,
} from "src/components/Table/CustomTable/styled"
import UserService from "src/services/UserService"
import Search from "./components/Search"
import TreeAnchor from "./components/TreeAnchor"
import ImportUser from "./modal/ImportUser"
import ModalInsertUpdate from "./modal/InsertUpdate"
import UserDetail from "./modal/UserDetail"
import ChangePasswordAdmin from "src/components/ChangePasswordAdmin"
import { ListServiceStyled } from "./styled"
import Product from "src/services/ProductionService"

const DataDemo = [
  {
    Index: 1,
    image: null,
    AccountName: "nhânvien1",
    Name: "nhân viên quảng cáo",
    Email: "nhanvien1@gmail.com",
    Address: "67 Thanh xuân Hà Nội",
    Role: "Nhân viên",
    listButtonShow: {
      IsUpdate: true,
      IsDelete: true,
    },
  },
  {
    Index: 1,
    image: null,
    AccountName: "Quản lý phân khu hà nội",
    Name: "Nguyên Van A",
    Email: "A@gmail.com",
    Address: " Hà Nội",
    Role: "Nhân viên",
    listButtonShow: {
      IsUpdate: true,
      IsDelete: true,
    },
  },
  {
    Index: 1,
    image: null,
    AccountName: "nhânvien1",
    Name: "nhân viên quảng cáo",
    Email: "nhanvien1@gmail.com",
    Address: "67 Thanh xuân Hà Nội",
    Role: "Nhân viên",
    listButtonShow: {
      IsUpdate: true,
      IsDelete: true,
    },
  },
]

const ListService = () => {
  const [dataSource, setDataSource] = useState([])
  const [loading, setLoading] = useState(false)
  const [openInsertUpdate, setOpenInsertUpdate] = useState(false)
  const [openImportUser, setOpenImportUser] = useState(false)
  const [detailInfo, setDetailInfo] = useState()
  const [listButtonShow, setListButtonShow] = useState()
  const [selectedNode, setSelectedNote] = useState()
  const [openModalUserDetail, setOpenModalUserDetail] = useState(false)
  const [openModalChangPassword, setOpenModalChangPassword] = useState(false)
  const [textSearch, setTextSearch] = useState("")
  const [status, setStatus] = useState(1)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const columns = [
    {
      title: "STT",
      dataIndex: "Index",
      key: "Index",
      width: 60,
      align: "center",
    },
    {
      title: "Ảnh",
      dataIndex: "image",
      key: "image",
      align: "center",
      render: value => <Avatar src={value} icon={<UserOutlined />} size={40} />,
      width: 60,
      align: "center",
    },
    {
      title: "Tài khoản",
      dataIndex: "AccountName",
      key: "AccountName",
      width: 180,
      align: "center",
    },
    {
      title: "Tên nhân viên",
      dataIndex: "Name",
      key: "Name",
      width: 220,
      align: "center",
    },
    {
      title: (
        <>
          <MainTableHeader>Email</MainTableHeader>
          <SubTableHeader>Số điện thoại</SubTableHeader>
        </>
      ),
      dataIndex: "PhoneNumber",
      key: "PhoneNumber",
      align: "center",
      width: 220,
      render: (val, record) => (
        <>
          <MainTableData>{record?.Email}</MainTableData>
          <SubTableData>{val}</SubTableData>
        </>
      ),
    },
    {
      title: "Địa chỉ",
      dataIndex: "Address",
      key: "Address",
      align: "center",
    },
    {
      title: "Phân quyền",
      dataIndex: "Role",
      key: "Role",
      width: 160,
      render: (text, record) => (
        <div className="d-flex justify-content-center align-items-center mh-36">
          {console.log("record", record)}
          {console.log("val", text)}
          <div className=" text-center">{text}</div>
          <FloatActionWrapper size="small" className="float-action__wrapper">
            {renderListButton(record)}
          </FloatActionWrapper>
        </div>
      ),
    },
  ]
  useEffect(() => {
    getProduction()
  }, [status, textSearch])

  const renderListButton = record => (
    <Space>
      {!!listButtonShow?.IsUpdate && (
        <ButtonCircle
          title="Cập nhật"
          iconName="edit"
          onClick={() => {
            setOpenInsertUpdate(true)
            setDetailInfo(record)
          }}
        />
      )}
      {!!listButtonShow?.IsDelete && (
        <ButtonCircle
          title="Xóa"
          iconName="bin"
          onClick={() => {
            CB1({
              title: `Bạn có chắc chắn muốn xoá người dùng
              <strong> ${record?.UserName}</strong> không?`,
              icon: "warning-usb",
              okText: "Đồng ý",
              onOk: async close => {
                onDeleteUser(record?.UserID)
                close()
              },
            })
          }}
        />
      )}
      {!!listButtonShow?.IsUpdate && (
        <ButtonCircle
          title="đổi mật khẩu"
          iconName="reset-pass"
          style={{ background: "#fff" }}
          onClick={() => setOpenModalChangPassword(true)}
        />
      )}
    </Space>
  )

  const onDeleteUser = async UserID => {}
  const onReset = async UserID => {
    const res = await UserService.resetPassword({ UserID })
    if (res?.isError) return
    Notice({ msg: "Reset mật khẩu thàng công !" })
  }
  const getProduction = async () => {
    setDataSource(DataDemo)
    setListButtonShow({
      IsUpdate: true,
      IsDelete: true,
    })
  }
  const exportUser = async () => {
    try {
      const res = await UserService.exportUser({
        DepartmentID: selectedNode?.DepartmentID,
      })

      const href = URL.createObjectURL(res)

      const link = document.createElement("a")
      link.href = href
      link.setAttribute("download", "danh sách nhân viên.xlsx") //or any other extension
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    } finally {
    }
  }
  const onSelectChange = newSelectedRowKeys => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys)
    setSelectedRowKeys(newSelectedRowKeys)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  return (
    <ListServiceStyled>
      <div>
        <Search
          setTextSearch={setTextSearch}
          setStatus={setStatus}
          status={status}
        />
        <Divider className="mv-16" />
        <div className="title-type-1 d-flex justify-content-space-between align-items-center pb-16 pt-0 mb-16">
          <div className="fs-24">
            Danh sách dịch vụ
            {/* ({dataSource?.total || 0}) : */}
          </div>
          <Row guter={[16, 16]}>
            {listButtonShow?.IsInsert && (
              <Col>
                <Button
                  btnType="primary"
                  t
                  className="btn-hover-shadow"
                  onClick={() => setOpenInsertUpdate(true)}
                >
                  Thêm dịch vụ
                </Button>
              </Col>
            )}
            {listButtonShow?.IsExport && (
              <Col>
                <Button
                  onClick={exportUser}
                  className="btn-hover-shadow"
                  btnType="third"
                >
                  Xuất Excel
                </Button>
              </Col>
            )}
          </Row>
        </div>
      </div>
      <Anchor
        affix={false}
        getContainer={() => document.getElementById("body-admin-scroll")}
      >
        <Row gutter={[16, 16]}>
          <Col style={{ width: 300 }}>
            <TreeAnchor
              selectedNode={selectedNode}
              setSelectedNote={setSelectedNote}
              keyId={2}
              getAllUser={() => getProduction()}
            />
          </Col>
          <Col style={{ width: 0 }} flex="auto">
            <SpinCustom spinning={loading}>
              <TableCustom
                isPrimary
                onRow={record => {
                  return {
                    onClick: () => {
                      setOpenModalUserDetail(record)
                    },
                  }
                }}
                className="mb-6"
                dataSource={dataSource}
                columns={columns}
                textEmpty="Không có nhân viên"
                pagination={false}
                rowKey="UserID"
                sticky={{ offsetHeader: -12 }}
                scroll={{ y: "100%", x: "800px" }}
              />
            </SpinCustom>
          </Col>
        </Row>
      </Anchor>
      {!!openInsertUpdate && (
        <ModalInsertUpdate
          open={openInsertUpdate}
          detailInfo={detailInfo}
          onOk={getProduction}
          onCancel={() => {
            setDetailInfo(undefined)
            setOpenInsertUpdate(false)
          }}
        />
      )}

      {!!openImportUser && (
        <ImportUser
          open={openImportUser}
          onCancel={() => setOpenImportUser(false)}
          onOk={getProduction}
          department={selectedNode}
        />
      )}
      {!!openModalUserDetail && (
        <UserDetail
          open={openModalUserDetail}
          onCancel={() => setOpenModalUserDetail(false)}
          onOk={getProduction}
          department={selectedNode}
          listButtonShow={listButtonShow}
        />
      )}
      {!!openModalChangPassword && (
        <ChangePasswordAdmin
          visible={true}
          onCancel={() => setOpenModalChangPassword(false)}
        />
      )}
    </ListServiceStyled>
  )
}

export default ListService
