import React, {useEffect, useState} from "react";
import Button from "../../../../components/MyButton/Button";
import {Anchor, Col, Divider, Input, Row, Table,Tooltip} from "antd";
import CustomModal from "../../../../components/Modal/CustomModal";
import type {SearchProps} from '../Search';
import SpinCustom from "../../../../components/Spin";
import TableCustom from "../../../../components/Table/CustomTable";
import http from "../../../../services";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {DisplayId} from "../../../../lib/utils";

export const UserSelectedListModal = (props) => {
    const {users, open, onCancel, search, pagination, totalPage, submit} = props;
    const [loading, setLoading] = useState(false);
    const {Search} = Input;
    const [selectedUserNames, setSelectedUserNames] = useState([]);

    const renderFooter = () => (
        <div className="d-flex justify-content-flex-end">
            <Button btnType="primary" className="btn-hover-shadow" onClick={() => submit(selectedUserNames)}>
                Lưu
            </Button>
            <Button className="btn-hover-shadow" onClick={() => onCancel()}>
                Đóng
            </Button>
        </div>
    );

    const handleSearch = (keyword) => {

        const model = {...pagination, textSearch: keyword};
        search(model);
    };

    const handleChangePage = (page, pageSize) => {
        const model = {...pagination, pageSize: pageSize || 15, currentPage: page || 1};
        search(model);
    };
    const handleRowSelection = (record) => {
        const selectedNames = [...selectedUserNames];
        // const index = selectedNames.indexOf(record.userID);
        const index = selectedNames.findIndex(item => item.userID === record.userID);
        if (index === -1) {
            const element={
                userID:record.userID,
                username:record.userName
            }
            selectedNames.push(element);
        } else {
            selectedNames.splice(index, 1);
        }
        setSelectedUserNames(selectedNames);
    };

    const columns = [
        {
            title: `Chọn (${selectedUserNames?.length || 0})`,
            dataIndex: "select",
            key: "select",
            width: 40,
            render: (val, record) => (
                <div className="text-center">
                    <input
                        type="checkbox"
                        checked={selectedUserNames.map(i=>i.userID).includes(record.userID)}
                        onChange={() => handleRowSelection(record)}
                    />
                </div>
            ),
        },
        {
            title: "Mã số người dùng",
            dataIndex: "userID",
            key: "userID",
            width: 120,
            align: "left",
            render: (val, record) => (
                <div className="text-right">
                    <p style={{textDecoration: "none"}}>{val}</p>
                </div>
            ),
        },
        {
            title: "UserName",
            dataIndex: "userName",
            key: "userName",
            width: 120,
            align: "left",
            render: (val, record) => (
                <div className="text-right">
                    <p style={{textDecoration: "none"}}>{val}</p>
                </div>
            ),
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            align: "center",
            render: (val, record) => (
                <div className="text-right">
                    <p style={{textDecoration: "none"}}>{val}</p>
                </div>
            ),
            width: 60,
        },
    ];

    return (
        <>
            <CustomModal title={"Chọn Sản phẩm"} footer={renderFooter()} width={1000} open={!!open} onCancel={onCancel}>

                <Divider className="mv-16"/>
                <div>
                    <Search placeholder="Hãy nhập từ khoá" allowClear onSearch={(e) => handleSearch(e)} size={"large"}/>
                    <Divider className="mv-16"/>
                </div>
                <Anchor affix={false} getContainer={() => document.getElementById("body-admin-scroll")}>
                    <Row gutter={[16, 16]}>
                        <Col style={{width: 0}} flex="auto">
                            <SpinCustom spinning={loading}>
                                <TableCustom
                                    isPrimary
                                    className="mb-6"
                                    dataSource={Object.values(users)} // Sửa đổi ở đây để lấy giá trị của đối tượng users
                                    columns={columns}
                                    textEmpty="Không có người dùng"
                                    rowKey="userName"
                                    sticky={{offsetHeader: -12}}
                                    scroll={{y: "100%", x: "800px"}}
                                    pagination={{
                                        defaultPageSize: 15,
                                        pageSizeOptions: ['15'],
                                        defaultPage: 15,
                                        showSizeChanger: true,
                                        total: totalPage,
                                        onChange: (page, pageSize) => {
                                            handleChangePage(page, pageSize);
                                        },
                                    }}
                                />
                            </SpinCustom>
                        </Col>
                    </Row>
                </Anchor>
            </CustomModal>
        </>
    );
};

export const UsersSelected = ({refreshUsers,initUsers}) => {
    const [users, setUsers] = useState({});
    const [userSelected, setUserSelected] = useState([]);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [totalPage, setTotalPage] = useState(0);
    const [pagination, setPagination] = useState({
        pageSize: 10,
        currentPage: 1,
        textSearch: "",
    });

    useEffect(()=>{
        if(initUsers){

           const newModel= initUsers.map(item=>({
                userId:item.userID,
                username:item.username,
                quantity:item.quantityPromoteCode,
            }));

           setUserSelected(newModel)
        }
    },[initUsers])

    useEffect(() => {
        getUser();
    }, [pagination]);

    useEffect(() => {
        const newParam=userSelected.map(item=>({
            userID:item.userId,
            quantityPromoteCode:item.quantity
            // isDelete:true
        }));
        refreshUsers(newParam);
    }, [userSelected]);
    const handleSearch = (model) => {
        setPagination(model);
    };
    const handleSubmit = (model) => {
        const convertModel = model.map(x => ({userId: x.userID,username:x.username, quantity: 1}));

        // const convertModel = model.map(x => ({userId: x, quantity: 1}));
        // const mergedArray = [...new Set([...userSelected, ...model])]; //loại bỏ user trùng lặp
        const itemAdder = convertModel.filter(item2 => !userSelected.some(item1 => item1.userId === item2.userId));
        const newList = [...userSelected, ...itemAdder];

        setUserSelected(newList);
        setIsOpenModal(false);
    }

    const getUser = () => {
        http
            .get(`Promote/GetListUserForAdd?TextSearch=${pagination.textSearch}&CurrentPage=${pagination.currentPage}&RoleId=1`)
            .then((response) => {
                if (!response.isError) {

                    setUsers(response?.object?.list);
                    setTotalPage(response?.object?.count);
                } else {
                    setUsers({});
                }
            });
    };
    const handleRemoveRow = (userId) => {
        const newModel = userSelected.filter(s => s.userId !== userId);
        setUserSelected(newModel);
    }
    const handleChangeQuantity=(quantity,record)=>{

        const updatedList = userSelected.map(item => {
            if (item.userId === record.userId) {
                return { ...item, quantity: quantity };
            }
            return item;
        });
        setUserSelected(updatedList)
    }

    const columns = [
        {
            title: 'Số thứ tự',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
            width: 30,
        },
        {
            title: 'Mã người dùng',
            dataIndex: 'userId',
            key: 'userId',
            width: 200,
            render: (text, record, index) => {
                return (
                    <>
                        {DisplayId(text)}
                    </>
                )
            },
        },
        {
            title: 'Tài khoản',
            dataIndex: 'username',
            key: 'username',
            width: 300,
            render: (text, record, index) => {
                return (
                    <>
                        <Tooltip title={text}>
                            <p style={{maxWidth:"100%",wordBreak:"break-all",whiteSpace:"normal"}}>
                                {text}
                            </p>
                        </Tooltip>
                    </>
                )
            },
        },
        {
            title: 'Số lượng',
            dataIndex: 'quantity',
            key: 'quantity',
            width: 60,
            align:"center",
            render: (text, record, index) => {
                return (
                    <>
                        <input
                            style={{width:"100%"}}
                            id="number"
                            type="number"
                            min={1}
                            value={record.quantity}
                            onChange={(e) => {
                                const quantity = parseInt(e.target.value, 10);
                                handleChangeQuantity(quantity, record);
                            }}
                        />
                    </>
                )
            }
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            render: (val, record) => (
                <div className="text-right">
                    <FontAwesomeIcon icon={faTrash} style={{color: "#b71f1f", cursor: "pointer"}} onClick={() => {
                        handleRemoveRow(record.userId);
                    }}/>
                </div>
            ),
        },
    ];
    // const renderDataSource =()=>{
    //     return userSelected.map(item=>(
    //         {userName: item}
    //     ))
    // }
    return (
        <>
            <div className={"d-flex-end"}>
                <Button btnType="primary" onClick={() => setIsOpenModal(true)}>Thêm người dùng</Button>
            </div>

            <Table dataSource={userSelected} columns={columns}/>

            {isOpenModal && (
                <UserSelectedListModal
                    users={users}
                    open={isOpenModal}
                    onCancel={() => setIsOpenModal(false)}
                    search={handleSearch}
                    pagination={pagination}
                    totalPage={totalPage}
                    submit={handleSubmit}
                />
            )}
        </>
    );
};
