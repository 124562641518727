import { Col, Row } from "antd"
import CustomModal from "src/components/Modal/CustomModal"
import SvgIcon from "src/components/SvgIcon"
import { StyleSelectRegisterModal } from "../../styled"
import { useState } from "react"
import RegisterModal from "./components/RegisterModal"

const SelectRegisterModal = ({
  openSelectRegisterModal,
  handleCancel,
  handleLogin,
}) => {
  const [openRegisterModal, setOpenRegisterModal] = useState(false)
  const [openTeacherRegisterModal, setOpenTeacherRegisterModal] =
    useState(false)
  const [openSchooleEnterpriseRegister, setOpenSchooleEnterpriseRegister] =
    useState(false)
  const [isSchool, setIsSchool] = useState()
  return (
    <CustomModal
      title="Chọn dạng tài khoản"
      footer={null}
      open={openSelectRegisterModal}
      onCancel={handleCancel}
      width="1000px"
    >
      <StyleSelectRegisterModal>
        <Row gutter={25} className="div-modal-select">
          <Col xs={24} sm={12} md={6}>
            <div
              onClick={() => {
                setOpenRegisterModal(true)
              }}
              className="modal-select"
            >
              <SvgIcon name="person-register" />
              <div className="title">Ứng viên</div>
            </div>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <div
              onClick={() => {
                setOpenSchooleEnterpriseRegister(true)
                setIsSchool(false)
              }}
              className="modal-select"
            >
              <SvgIcon name="test-site-register" />
              <div className="title">Doanh nghiệp</div>
            </div>
          </Col>
        </Row>
        <Row>
          <span className="md-bottom-text">
            <span>
              Bạn đã có tài khoản?{" "}
              <span
                className="link-login"
                onClick={() => {
                  handleCancel()
                  handleLogin()
                }}
              >
                Đăng nhập
              </span>{" "}
              ngay
            </span>
          </span>
        </Row>

        {!!openRegisterModal && (
          <RegisterModal
            openRegisterModal={openRegisterModal}
            handleOk={() => {}}
            handleCancel={() => setOpenRegisterModal(false)}
            // handleLogin={() => setOpenLoginModal(true)}
          />
        )}
      </StyleSelectRegisterModal>
    </CustomModal>
  )
}

export default SelectRegisterModal
