// Region
export const apiHistoryPayment = `Transaction/GetAllTransaction`
export const apiInforPayment = `Transaction/GetInforPayment`

//VNPAY
export const apiCreatePaymentUrl = `Payment/CreatePaymentUrl`

//Vietcombank
export const apiGetQRRechargeByMoney = `/Transaction/GetQRRechargeByMoney`

//Transaction
export const apiGetAllTransactionForAdmin = `/Transaction/GetAllTransactionForAdmin`