 import {Anchor, Col, Divider, Image, Pagination, Row, Space} from "antd";
import {UserOutlined} from "@ant-design/icons";
import {MainTableHeader} from "../../../components/Table/CustomTable/styled";
import {FloatActionWrapper} from "../../../components/FloatAction/styles";
import Search from "./component/Search";
import Button from "../../../components/MyButton/Button";
import SpinCustom from "../../../components/Spin";
import TableCustom from "../../../components/Table/CustomTable";
import {ListUserStyled} from "../Company/styled";
import React, {useEffect, useState} from "react";
import {ImportBannerFooter} from "./component/ImportBannerFooter";
import {deleteBanner, pagingBanner} from "./component/api/api-banner";
import ButtonCircle from "../../../components/MyButton/ButtonCircle";
import CB1 from "../../../components/Modal/CB1";
import Notice from "../../../components/Notice";
import {useNavigate} from "react-router-dom";
import http from "../../../services";

const pageSizeOptions = ['10', '20', '50']; // Customize page size options


export const BannerManager = () => {
    const [dataBannerApi, setDataBannerApi] = useState([])
    const [page, setPage] = useState(1)
    const [isInsert, setIsInsert] = useState(false)
    const [size, setSize] = useState(10)
    const [keyword, setKeyword] = useState("")
    const [category, setCategory] = useState(1)
    const [status, setStatus] = useState(-1)
    const [detailInfo, setDetailInfo] = useState()
    const [isDeleted, setIsDeleted] = useState(-2)
    const [loading, setLoading] = useState(false)
    const [refest, setRefest] = useState(false)
    const [onInsertUpdate, setOpenInsertUpdate] = useState(false)
    const [totalPages, setTotalPages] = useState()
    const navigate = useNavigate();
    const renderStatus = (value) => {
        return (
            <div
                className="text-ellipsis fw-600 text-center"
                style={{color: value == 0 ? '#0960a0' : 'red'}}
            >
                {value == 0 ? 'Kích hoạt' : 'Không kích hoạt'}
            </div>
        )
    }
    const columns = [
        {
            title: "STT",
            dataIndex: "id",
            key: "id",
            width: '10%',
            align: "center",
            render: (val, record, idx) => (
                <div className="text-right">
                    {idx + 1 + size * (page - 1)}
                </div>
            ),
        },

        {
            title: "Hình ảnh",
            dataIndex: "title",
            key: "title",
            width: '12%',
            align: "center",
            render: value => <Image src={value} icon={<UserOutlined/>} size={40} style={{borderRadius: "8px"}}/>
        },
        {
            title: "Nội dung",
            dataIndex: "content",
            key: "content",
            width: '12%',
            align: "center",
            render: value => {
                return (<>
                    <p style={{textDecoration: "underline", color: "blue"}}
                       onClick={() => navigate(`/${value}`)}
                    >{value}</p>
                </>)
            }
        },

        // {
        //     title: "Icon",
        //     dataIndex: "icon",
        //     key: "icon",
        //     width: '10%',
        //     responsive: category == 2 ? ['lg'] : [],
        //     render: value => <Image src={value} icon={<UserOutlined/>} size={40} style={{borderRadius: "8px"}}/>,
        //     align: "center",
        // },

        {
            title: "Người tạo",
            dataIndex: "createUserName",
            key: "createUserName",
            width: '12%',
            align: "center",
        },
        {
            title: (
                <>
                    <MainTableHeader>Ngày tạo</MainTableHeader>
                    {/*<SubTableHeader>Số điện thoại</SubTableHeader>*/}
                </>
            ),
            dataIndex: "createDate",
            key: "createDate",
            align: "center",
            width: '12%',
            // render: (val, record) => (
            //     <>
            //         <MainTableData>{record?.Email}</MainTableData>
            //         {/*<SubTableData>{val}</SubTableData>*/}
            //     </>
            // ),
        },
        {
            title: "Trạng thái",
            dataIndex: "status",
            key: "status",
            width: '12%',
            render: (text, record) => (
                <div className="d-flex justify-content-center align-items-center mh-36">
                    <div className=" text-center">{renderStatus(text)}</div>
                    <FloatActionWrapper size="small" className="float-action__wrapper list-button-hover"
                                        style={{background: "#fff"}}>
                        {renderListButton(record)}
                    </FloatActionWrapper>
                </div>
            ),
        },
    ]

    const columnsFooter = [
        {
            title: "STT",
            dataIndex: "id",
            key: "id",
            width: '10%',
            align: "left",
            render: (val, record, idx) => (
                <div className="text-right">
                    {idx + 1 + size * (page - 1)}
                </div>
            ),
        },
        {
            title: "Tiêu đề",
            dataIndex: "title",
            key: "title",
            width: '12%',
            align: "left",
            render: value => {
                return (<>
                    <p className={"primary-color"} style={{fontWeight: 600}}>{value}</p>
                </>)
            }
        },

        {
            title: "Icon",
            dataIndex: "icon",
            key: "title",
            width: '12%',
            align: "center",
            render: value => <Image src={value} icon={<UserOutlined/>} size={30} style={{borderRadius: "8px"}}/>
        },
        {
            title: "Nội dung",
            dataIndex: "content",
            key: "content",
            width: '12%',
            align: "left",
            render: value => {
                return (<>
                    <p style={{textDecoration: "underline", color: "blue"}}
                       onClick={() => navigate(`/${value}`)}
                    >{value}</p>
                </>)
            }
        },

        // {
        //     title: "Icon",
        //     dataIndex: "icon",
        //     key: "icon",
        //     width: '10%',
        //     responsive: category == 2 ? ['lg'] : [],
        //     render: value => <Image src={value} icon={<UserOutlined/>} size={40} style={{borderRadius: "8px"}}/>,
        //     align: "center",
        // },

        {
            title: "Người tạo",
            dataIndex: "createUserName",
            key: "createUserName",
            width: '12%',
            align: "center",
        },
        {
            title: (
                <>
                    <MainTableHeader>Ngày tạo</MainTableHeader>
                    {/*<SubTableHeader>Số điện thoại</SubTableHeader>*/}
                </>
            ),
            dataIndex: "createDate",
            key: "createDate",
            align: "center",
            width: '12%',
            // render: (val, record) => (
            //     <>
            //         <MainTableData>{record?.Email}</MainTableData>
            //         {/*<SubTableData>{val}</SubTableData>*/}
            //     </>
            // ),
        },
        {
            title: "Trạng thái",
            dataIndex: "status",
            key: "status",
            width: '12%',
            render: (text, record) => (
                <div className="d-flex justify-content-center align-items-center mh-36">
                    <div className=" text-center">{renderStatus(text)}</div>
                    <FloatActionWrapper size="small" className="float-action__wrapper list-button-hover"
                                        style={{background: "#fff"}}>
                        {renderListButton(record)}
                    </FloatActionWrapper>
                </div>
            ),
        },
    ]
    const getBanner = () => {

        pagingBanner(size, page, keyword, category, status).then(res => {
                setLoading(true)
                if (!res?.isError) {
                    setDataBannerApi(res?.object.list)
                    setTotalPages(res?.object.count)
                    setIsInsert(res?.object?.isInsert)
                } else {
                    setTotalPages(1);
                    setDataBannerApi([]);
                }
                setLoading(false)

            }
        )
    }

    const onDeletebanners = (id, isDeleted) => {
        setLoading(true)
        deleteBanner(id, isDeleted).then(res => {

            // if (res.status === 200) {
            //     Notice({
            //         msg: "Hủy kích hoạt thành công",
            //         isSuccess: true,
            //     })
            // }
            // setRefest(!refest)
            Notice({
                msg: isDeleted == 1 ? "Kích hoạt thành công" : "Hủy kích hoạt thành công",
                isSuccess: true,
            })
            setRefest(!refest)
        })
        setLoading(false)
    }

    const handleChangePage = (page, pageSize) => {
        setSize(pageSize)
        setPage(page);

    }

    const handleChangeKeyword = (e) => {
        setKeyword(e)
    }

    const handleChangeCategory = (e) => {
        setCategory(String(e))

    }

    const handleStatus = (e) => {
        setStatus(String(e))
    }


    useEffect(() => {
        getBanner()
    }, [page, size, category, onInsertUpdate, keyword, status, isDeleted, refest, detailInfo])


    const renderListButton = record => (
        <Space>
            {
                record?.isUpdate&&(
                    <ButtonCircle
                        title="Cập nhật"
                        iconName="edit-green"
                        style={{background: "#DDFEF0"}}
                        onClick={() => {
                            setOpenInsertUpdate(true)
                            setDetailInfo(record)
                        }}
                    />
                )
            }
            {
                record?.isChangeStatus&&(
                    <ButtonCircle
                        title={record?.status == 1 ? "Kích hoạt" : "Hủy kích hoạt"}
                        iconName={record?.status == 1 ? "green-accept" : "cancel"}
                        style={{background: "#FFE9EC"}}
                        onClick={() => {
                            CB1({
                                title: `Bạn có chắc chắn muốn thay đối trạng thái không?`,
                                icon: record?.status == 1 ? "green-accept" : "cancel",
                                okText: "Đồng ý",
                                onOk: async close => {
                                    let isCheck = record?.status == 1 ? 0 : 1
                                    onDeletebanners(record?.id, isCheck)
                                    // Notice({
                                    //     msg: record?.status == 1 ? "Kích hoạt thành công" : "Hủy kích hoạt thành công",
                                    //     isSuccess: true,
                                    // })
                                    // setIsDeleted(isCheck);
                                    // const status=refest;
                                    // setRefest(!status)
                                },
                            })
                        }}
                    />

                )
            }
            {
                record?.isDelete  && (
                    <ButtonCircle
                        title={"Xóa"}
                        iconName={"bin"}
                        style={{background: "#FFE9EC"}}
                        onClick={
                            () => {
                                CB1({
                                    title: `Bạn có chắc chắn muốn xoá banner/footer này không?`,
                                    icon: "warning-usb",
                                    okText: "Đồng ý",
                                    onOk: async close => {
                                        setLoading(true)
                                        //PositionService.deletePos(record?.PositionID)
                                        http.delete(`BannerFooter/Delete?ID=${record?.id}`).then(res => {
                                            if (res.isOk) {
                                                Notice({
                                                    msg: "Xoá thành công.",
                                                    isSuccess: true,
                                                })
                                                setRefest(!refest)
                                            }
                                        })
                                            .finally(() => {
                                                setLoading(false)
                                            })
                                        close()
                                    },
                                })
                            }
                        }
                    />
                )
            }

        </Space>
    )

    return (
        <ListUserStyled>
            <div>
                <Search
                    handleChangeCategory={handleChangeCategory}
                    category={category}
                    handleStatus={handleStatus}
                    status={status}
                    handleChangeKeyword={handleChangeKeyword}
                    keyWord={keyword}
                />
                <Divider className="mv-16"/>
                <div className="title-type-1 d-flex justify-content-space-between align-items-center pb-16 pt-0 mb-16">
                    <div className="fs-24">
                        Quản Lý Banner & Footer
                        {/* ({dataSource?.total || 0}) : */}
                    </div>
                    <Row gutter={[16, 16]}>
                        {/*{listButtonShow?.IsInsert && (*/}
                        <Col>
                            {
                                isInsert && (
                                    <Button
                                        btnType="primary"
                                        className="btn-hover-shadow"
                                        onClick={() => {
                                            setOpenInsertUpdate(true);
                                            setDetailInfo();
                                        }}
                                    >
                                        Thêm Banner & Footer
                                    </Button>
                                )
                            }
                        </Col>
                        {onInsertUpdate && (
                            <Col>
                                <ImportBannerFooter visible={onInsertUpdate} dataInfo={detailInfo}
                                                    onCancel={() => setOpenInsertUpdate(false)}
                                                    onOk={() => setOpenInsertUpdate(false)}/>
                            </Col>
                        )}
                    </Row>
                </div>
            </div>
            <Anchor
                affix={false}
                getContainer={() => document.getElementById("body-admin-scroll")}
            >
                <Row gutter={[14, 14]} style={{paddingLeft: 20, paddingRight: 20}}>
                    {/*<Col style={{width: 300}}>*/}
                    {/*    <TreeAnchor*/}
                    {/*        // selectedNode={selectedNode}*/}
                    {/*        // setSelectedNote={setSelectedNote}*/}
                    {/*        keyId={2}*/}
                    {/*        // getAllUser={() => getProduction()}*/}
                    {/*    />*/}
                    {/*</Col>*/}
                    <Col style={{width: 0}} flex="auto">
                        <SpinCustom spinning={loading}>
                            <TableCustom
                                isPrimary
                                onRow={record => {
                                    return {
                                        onClick: () => {
                                            // setOpenModalUserDetail(record)
                                        },
                                    }
                                }}
                                className="mb-6"
                                dataSource={dataBannerApi}
                                columns={category == 1 ? columns : columnsFooter}
                                textEmpty="Không có"
                                pagination={false}
                                rowKey="id"
                                sticky={{offsetHeader: -12}}
                                scroll={{y: "100%", x: "800px"}}
                            />
                        </SpinCustom>
                        <div style={{display: "flex", justifyContent: "flex-end", marginTop: 30, marginBottom: 30}}>
                            <Pagination
                                current={page}
                                pageSize={size}
                                total={totalPages}
                                onChange={handleChangePage}
                                showSizeChanger
                            />

                        </div>

                    </Col>
                </Row>
            </Anchor>

        </ListUserStyled>

    )

}
