import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  listSystemKey: [],
  listCount: {},
  listTabs: [],
  isAuthenticated: false,
  userInfo: {},
  shopUserID: '',
  chatUserInfor: {},
  signalRConnection: {},
  balance: 0,
  token: null
}

export const appGlobalSlice = createSlice({
  name: "appGlobal",
  initialState,
  reducers: {
    getListSystemKey: (state, action) => {
      state.listSystemKey = action.payload
    },
    setListTabs: (state, action) => {
      state.listTabs = action.payload
    },
    changeAuthorization: (state, action) => {
      state.isAuthenticated = action.payload
    },
    setListCount: (state, action) => {
      state.listCount = action.payload
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
      // window.location.href('/');
    },
    setShopUserInfo: (state, action) => {
      state.shopUserID = action.payload
    },
    setChatUserInfor: (state, action) => {
      state.chatUserInfor = action.payload
    },
    setSignalRConnect: (state, action) => {
      state.signalRConnection = action.payload
    },
    setBalance: (state, action) => {
      state.balance = action.payload
    },
    setToken: (state, action) => {
      state.token = action.payload
    },
  },
})

export const {
  getListSystemKey,
  changeAuthorization,
  setListTabs,
  setListCount,
  setUserInfo,
  setShopUserInfo,
  setChatUserInfor,
  setSignalRConnect,
  setBalance,
  setToken
} = appGlobalSlice.actions

export default appGlobalSlice.reducer
