import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    isReloadList: false,
    isReloadCount: false,
}

export const notifyRedux = createSlice({
  name: "notifyRedux",
  initialState,
  reducers: {
    setReloadList: (state, action) => {
      state.isReloadList = action.payload
    },
    setReloadCount: (state, action) => {
      state.isReloadCount = action.payload
    },
  },
})

export const {
    setReloadList,
    setReloadCount
} = notifyRedux.actions

export default notifyRedux.reducer
