import { Col, DatePicker, Row, Select, TreeSelect, message } from "antd"
import { useSelector } from "react-redux"
import FlInput from "src/components/FloatingLabel/Input"
import FlSelect from "src/components/FloatingLabel/Select"
import { SYSTEM_KEY } from "src/constants/constants"
import { getListComboByKey } from "src/lib/utils"
import { SearchStyled } from "../styled"
import { FloatingLabel } from "react-bootstrap"
import FlTreeSelect from "src/components/FloatingLabel/TreeSelect"
import dayjs from "dayjs";
import { useState } from "react"
import ProductionService from 'src/services/ProductionService'
import { useEffect } from "react"

const { Option } = Select
const Search = ({ setDataBody, dataBody }) => {
  const { listSystemKey } = useSelector(state => state.appGlobal);
  const [listProduct, setListProduct] = useState([]);

  //#region useEffect
  
  useEffect(() => {
    getListProduct();
  }, [])

  //#endregion
    
  //#region function

  /**
   * @description lấy danh sách sản phẩm
   * @author hddung
   */
  async function getListProduct() {
    const res = await ProductionService.getAllProductAndProductItem();
    if(res?.isError) return
    const arrData = res?.object?.list || [];
    const productSource = [{
      value: '',
      title: 'Tất cả',
      children: arrData.filter(x => x.lv == 1).map(x => ({
        value: x.id, 
        title: x.objectName, 
        children: getChildrenArr(x.id, arrData.filter(x => x.lv != 1))
      }))
    }]
    setListProduct(productSource);
  }

  /**
   * @description đệ quy lấy ra chidrent của từng node
   * @author hddung
   */
  function getChildrenArr(parentID, data){
    const lstChildren = data.filter(x => x.parentID == parentID);
    
    return data?.length > 0 
    ? 
    //đệ quy tiếp tục với những node k thuộc parentID
    lstChildren.map(x => ({
        value: x.id, 
        title: x.objectName, 
        children: getChildrenArr(x.id, data.filter(x => x.parentID != parentID))
    })) 
    : []; 
  }

  //#endregion

  return (
    <SearchStyled>
      <Row gutter={[16, 16]}>
        <Col flex="auto">
          <FlInput
            onSearch={e => {
              setDataBody((prevState) => ({
                ...prevState,
                textSearch: e || '',
                currentPage: 1
              }));
            }}
            search
            allowClear
            label="Nhập mã tồn kho, tên sản phẩm, tên phân loại, nội dung"
          />
        </Col>
        <Col lg={7} xs={24}>
            {listProduct?.length > 0 &&
                <FlTreeSelect
                  label="Sản phẩm"
                  allowClear
                  showSearch
                  treeNodeFilterProp="title"
                  style={{
                      width: '100%',
                  }}
                  value={dataBody?.objectID || ''}
                  dropdownStyle={{
                      maxHeight: 400,
                      overflow: 'auto',
                  }}
                  placeholder="Chọn sản phẩm"
                  treeDefaultExpandAll
                  onChange={(record) => {
                    setDataBody((prevState) => ({
                      ...prevState,
                      objectID: record || '',
                      currentPage: 1
                    }));
                  }}
                  treeData={listProduct}
                />
            }
        </Col>
        <Col lg={3} xs={24}>
          <FlSelect
            label="Trạng thái"
            onChange={status => {
              setDataBody((prevState) => ({
                ...prevState,
                status: status || 0,
                currentPage: 1
              }));
            }}
            value={dataBody?.status || 0}
          >
            {getListComboByKey(SYSTEM_KEY?.INVENTORY_STATUS, listSystemKey)?.map(
              i => (
                <Option key={+i.codeValue} value={+i.codeValue}>
                  {i?.description}
                </Option>
              ),
            )}
          </FlSelect>
        </Col>
        <Col lg={3} xs={24}>
          <DatePicker
              onChange={(e) => {
                  const formattedDate = dayjs(e).format('YYYY-MM-DD');
                  if(new Date(formattedDate) <= new Date(dataBody?.toDate) || !dataBody?.toDate || !e) {
                    setDataBody((prevState) => ({
                      ...prevState,
                      fromDate: formattedDate == 'Invalid Date' ? null : formattedDate,
                      currentPage: 1
                    }));
                  } else {
                    message.error('Từ ngày nhỏ hơn đến ngày');
                  }
              }}
              value={dataBody?.fromDate ? dayjs(dataBody?.fromDate) : null}
              placeholder="Từ ngày"
              format="DD/MM/YYYY"
              allowClear
          />
      </Col>

      <Col lg={3} xs={24}>
          <DatePicker
              onChange={(e) => {
                  const formattedDate = dayjs(e).format('YYYY-MM-DD');
                  if(new Date(formattedDate) >= new Date(dataBody?.fromDate) || !dataBody?.fromDate || !e) {
                    setDataBody((prevState) => ({
                      ...prevState,
                      toDate: formattedDate == 'Invalid Date' ? null : formattedDate,
                      currentPage: 1
                    }));
                  } else {
                    message.error('Đến ngày lớn hơn từ ngày');
                  }
              }}
              value={dataBody?.toDate ? dayjs(dataBody?.toDate) : null}
              placeholder="Đến ngày"
              format="DD/MM/YYYY"
              allowClear
          />
      </Col>
      </Row>
    </SearchStyled>
  )
}

export default Search
