import "./cart-contact.css"
import {Button, Col, Form, Input, Row, Select, Tag} from "antd";
import React from "react";
import {useSelector} from "react-redux";

export const OrderContact = () => {
    const carts = useSelector(state => state?.cart?.cartItems)
    const layout = {
        labelCol: {span: 8},
        wrapperCol: {span: 16},
    };
    const tailLayout = {
        wrapperCol: {offset: 8, span: 16},
    };
    const [form] = Form.useForm();
    const onFinish = (values) => {
        console.log('Received values:', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const formatCurrency = (value) => {
        return new Intl.NumberFormat('vi-VN', {
            style: 'currency',
            currency: 'VND',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value).replace('VND', 'đ');
    };
    const renderNewPrice = (price, discount) => {
        return price * (100 - discount) / 100;
    }
    return (
        <>

            <div className={"container main-cart"}>
                <div className={"title__container"}>
                    <h4>Thông tin đơn hàng</h4>
                </div>
                <div className={"contact__container"}>
                    <div className={"post-dot"}></div>
                    <div className={"contact-information__container"}>
                        <h5 className={"title-infor"}>
                            Thông tin doanh nghiệp
                        </h5>
                        <div className={"information-form__container"}>
                            <Form
                                {...layout}
                                form={form}
                                name="businessForm"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                            >
                                <Row gutter={[24,32]}>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Tên doanh nghiệp"
                                            name="businessName"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Vui lòng nhập tên doanh nghiệp!',
                                                },
                                            ]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Mã số thuế"
                                            name="taxNumber"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Vui lòng nhập mã số thuế!',
                                                },
                                            ]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[24,32]}>
                                    <Col span={4} style={{ textAlign: "right", fontWeight: 'bold' }}>
                                        <label className="required-label" aria-required={true}>Địa chỉ doanh nghiệp :</label>
                                    </Col>
                                    <Col span={20} style={{paddingLeft:2}}>
                                        <Form.Item
                                            name="businessAddress"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Vui lòng nhập địa chỉ doanh nghiệp!',
                                                },
                                            ]}
                                        >
                                            <Input style={{ width: '150%' }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[24,32]}>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Tên người đại diện"
                                            name="representativeName"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Vui lòng nhập tên người đại diện!',
                                                },
                                            ]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </Col>

                                </Row>

                                <Row gutter={[24,32]}>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Số điện thoại"
                                            name="phoneNumber"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Vui lòng nhập số điện thoại!',
                                                },
                                            ]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Email"
                                            name="email"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Vui lòng nhập email!',
                                                },
                                                {
                                                    type: 'email',
                                                    message: 'Email không hợp lệ!',
                                                },
                                            ]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </Col>
                                </Row>


                                {/*<Form.Item >*/}
                                {/*</Form.Item>*/}
                            </Form>

                        </div>
                    </div>
                </div>
                <div className={"product__container"}>
                    <div className={"contact-information__container"}>
                        <h5 className={"title-infor"}>
                            Sản phẩm
                        </h5>
                        <div className={"table-container col-12"}>
                            <table>
                                <thead>
                                    <th className={"w-50"}>Tên sản phẩm</th>
                                    <th className={"w-5"}>Số lượng</th>
                                    <th className={"w-20"}>Đơn giá</th>
                                    <th className={"w-20"}>Thành tiền</th>
                                </thead>
                                {
                                    carts.map((product,index)=>(
                                        <tr>
                                            <td>
                                                <div className={"item-name__container"}>
                                                    <div className={"image__container col-2"}>
                                                        <img src={product.image}/>
                                                    </div>
                                                    <div className={"name-item col-6"}>
                                                        <p className={"name-item max-line1"}>{product.productName}</p>
                                                    </div>
                                                    <div className={"attr-item col-4"}>
                                                        <p>Phân loại</p>
                                                        <Tag
                                                            // key={product?.productId}
                                                            closable={false}
                                                            style={{userSelect: 'none'}}
                                                        >
                                                            <span>{product?.productItemName}</span>
                                                        </Tag>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                x{product.quantity}
                                            </td>
                                            <td>
                                                {
                                                    (product?.discount>0)?(
                                                        <div
                                                            className={'price'}>{formatCurrency(renderNewPrice(product.price,product.discount))}<del>{formatCurrency(product.price.toString())}</del>
                                                        </div>
                                                    ):(
                                                        <div
                                                            className={'price'}>{formatCurrency(product.price.toString()||0)}
                                                        </div>
                                                    )
                                                }
                                            </td>
                                        </tr>
                                    ))
                                }
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
