import CustomModal from "../CustomModal";
import {Button, Col, Form, Row} from "antd";
import SpinCustom from "../../Spin";
import FlInput from "../../FloatingLabel/Input";
import {useState} from "react";
import axios from "axios";
import Swal from "sweetalert2";
import {useNavigate} from "react-router-dom";
import http from "../../../services";

export default function CancelModal({visible, onCancel, refreshData, order_id}) {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = () => {
        form.validateFields().then(async form => {
        setLoading(true);
            const model = {
                "orderId": order_id,
                "note": form.reason
            }
            await http.post('Order/CancelOrder', model).then(resp => {
                if(!resp?.isError){
                    onCancel();
                    showSuccess();
                }
            }).catch(reason => {
                 showError("Đã có lỗi xảy ra")
            })
        }).catch(reason => {
            setLoading(false);
        })
            .finally(() => {
                setLoading(false);
            })
    }
    const showSuccess = () => {
        setLoading(true)
        Swal.fire({
            icon: 'success',
            title: 'Thành Công',
            text: 'Bạn đã huỷ đơn hàng thành công!',
        }).then(() => {
            navigate('/orders');
            refreshData();
        });
    };
    const showError = (message) => {
        setLoading(true)
        Swal.fire({
            icon: 'error',
            title: 'Thất bại',
            text: message,
        }).then(() => {

        });
    };

    return (
        <>
            <CustomModal
                title={" Huỷ đơn hàng"}
                visible={visible}
                footer={
                    <div style={{display: "flex", justifyContent: "flex-end"}}>
                        <Button  disabled={loading} btnType="third" onClick={onCancel}>
                            Huỷ
                        </Button>
                        <Button  disabled={loading} btnType="primary" onClick={() => handleSubmit()}>
                            Đồng ý
                        </Button>
                    </div>
                }
                width={970}
                onCancel={onCancel}
            >

                <SpinCustom spinning={loading}>
                    <Form form={form}>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    name="reason"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Lý do không được để trống!",
                                        },
                                    ]}
                                >
                                    <FlInput label=" Nhập Lý do huỷ" isRequired/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </SpinCustom>
            </CustomModal>
        </>
    );
}
