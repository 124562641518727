import {
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Row,
  Select,
  TreeSelect,
  Upload,
  message
} from "antd"
import moment from "moment"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import CustomModal from "src/components/Modal/CustomModal"
import Button from "src/components/MyButton/Button"
import ButtonCircle from "src/components/MyButton/ButtonCircle"
import Notice from "src/components/Notice"
import SpinCustom from "src/components/Spin"
import SvgIcon from "src/components/SvgIcon"
import { GUIDE_EMPTY, SEX_TYPE, SYSTEM_KEY } from "src/constants/constants"
import {
  getRegexEmail,
  getRegexMobile,
  getRegexPassword,
  getRegexPhoneNumber,
  getRegexUsername,
} from "src/lib/stringsUtils"
import { getListComboByKey, nest, normFile } from "src/lib/utils"
import DepartmentSevice from "src/services/DepartmentSevice"
import FileService from "src/services/FileService"
import Product from "src/services/ProductionService"
import RoleService from "src/services/RoleService"
import UserService from "src/services/UserService"
import styled from "styled-components"
import dayjs from "dayjs";
import { ButtonUploadStyle } from "../styled"
import RegionService from "src/services/RegionService"
import EmployeeService from "src/services/EmployeeService"
const { Option } = Select
const Styled = styled.div`
  .ant-upload.ant-upload-select-picture-card {
    width: unset;
    height: unset;
    background-color: unset;
    border: unset;
  }
  .ant-upload-list {
    align-items: center;
    display: flex;
  }
`
const ModalInsertUpdate = ({ onOk, detailInfo, reloadTable, ...props }) => {
  const { listSystemKey } = useSelector(state => state.appGlobal);
  const [form] = Form.useForm();

  const [listDept, setListDept] = useState([]);
  const [listRole, setListRole] = useState([]);

  const [listProvince, setListProvince] = useState([]);
  const [listDistrict, setListDistrict] = useState([]);
  const [listWard, setListWard] = useState([]);

  const [loading, setLoading] = useState(false);
  const [fileUrl, setfileUrl] = useState('');

  //#region useEffect

  useEffect(() => {
    getAllDepartment();
    getAllForCombobox();
    getListProvinceVN();
  }, [])

  useEffect(() => {
    if(detailInfo) {
      setfileUrl(detailInfo?.avatar);
      loadListDistrict(detailInfo?.provinceID);
      loadListWard(detailInfo?.districtID);
      form.setFieldsValue({
        ...detailInfo,
        dateOfBirth: detailInfo?.dateOfBirth && dayjs(detailInfo?.dateOfBirth),
        provinceID: detailInfo?.provinceID || null,
        districtID: detailInfo?.districtID || null,
        wardID: detailInfo?.wardID || null
      });
    }
  }, [detailInfo])

  //#endregion

  //#region function

  /**
   * @description thực hiện upload ảnh
   * @author hddung 
   */
  const uploadFile = async file => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("InsertFileList", file);
      const res = await FileService.uploadFileList(formData);
      if (res?.isError) return;
      setfileUrl(res?.object[0]?.fileUrl);
    } finally {
      setLoading(false);
    }
  }

  const uploadProps = {
    accept: "image/*",
    multiple: false,
    fileList: [],
    beforeUpload: file => {
      if(handleBeforeUpload(file)) {
        uploadFile(file)
        return false
      }
    },
  }

  /**
   * @description lấy ra danh sách role
   * @author hddung 
   */
  async function getAllForCombobox() {
    try{
      setLoading(true);
      const res = await RoleService.getAllForCombobox();
      if(res?.isError) return
      
      setListRole(res?.object?.map(x => ({
        label: x.roleName,
        value: x.roleID
      })));

    } finally {
      setLoading(false);
    }

  }
  
  async function getAllDepartment() {
    try {
      setLoading(true);
      const res = await DepartmentSevice.getAllDepartment();
      if(res?.isError) return
      const arrData =  res?.object?.list;
      setListDept(
        arrData.filter(x => x.departmentParentID == '00000000-0000-0000-0000-000000000000').map(x => ({
          title: x.departmentName,
          value: x.departmentID,
          children: getChildrenArr(x.departmentID, arrData.filter(x => x.departmentParentID != '00000000-0000-0000-0000-000000000000'))
        }))
        )
      } finally {
        setLoading(false);
      }
    }

  const getListProvinceVN = () => {
    setLoading(true)
    RegionService.getByRegionId({ regionId: 234 })
      .then(res => {
        if (res?.isError) return
        setListProvince(res?.object);
        loadListDistrict(detailInfo.provinceID);
        loadListWard(detailInfo.districtID);
      })
      .finally(() => setLoading(false))
  }

  // useState(() => {

  //   getListProvinceVN();
  // },[])
  const loadListDistrict=(newValue)=>{
    if(newValue&&newValue!==0){
      setLoading(true)
      RegionService.getByRegionId({ regionId: newValue })
      .then(res => {
        if (res?.isError) return
        const lstDistrict = res?.object||[];
        setListDistrict(lstDistrict)
      })
      .finally(() => setLoading(false))
    }
  }
  const loadListWard=(newValue)=>{
    if(newValue&&newValue!==0){
      setLoading(true)
      RegionService.getByRegionId({ regionId: newValue })
      .then(res => {
        if (res?.isError) return
        const listWard = res?.object||[];
        setListWard(listWard)
      })
      .finally(() => setLoading(false))
    }
  }
  const onChangeProvince = newValue => {
    form.resetFields([`districtID`]);
    form.resetFields([`wardID`]);
    if (!newValue) {
      setListDistrict([]);
      return;
    }
    setLoading(true);
    RegionService.getByRegionId({ regionId: newValue })
        .then(res => {

          if (res?.isError) return
          const lstDistrict = res?.object||[];
          setListDistrict(lstDistrict)
        })
        .finally(() => setLoading(false));
  };

  const onChangeDistrict = e => {
    form.resetFields([`wardID`])
    if (!e) return setListWard([])
    setLoading(true)
    RegionService.getByRegionId({ regionId: e })
      .then(res => {
        if (res?.isError) return
        const lstWard = res?.object||[];
        setListWard(lstWard);
      })
      .finally(() => setLoading(false))
  }
  const onChangeWard = e => {
    // setWardSelected(e);
  }

  /**
   * @description đệ quy lấy ra chidrent của từng node
   * @author hddung
   */
  function getChildrenArr(parentID, data){
    const lstChildren = data.filter(x => x.departmentParentID == parentID);

    return data?.length > 0 
    ? 
    //đệ quy tiếp tục với những node k thuộc parentID
    lstChildren.map(x => ({
        title: x.departmentName, 
        value: x.departmentID,
        children: getChildrenArr(x.departmentID, data.filter(x => x.departmentParentID != parentID))
    })) 
    : []; 
  }

  /**
   * @description Thực hiện luồng save
   * @author hddung
   */
  async function onSubmitData() {
    try {
      setLoading(true);
      await form.validateFields().then(values => {
        if(detailInfo) {
          updateEmployee(values);
        } else {
          insertEmployee(values);
        }
      })
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  /**
   * @description insert nhân viên
   * @author hddung
   */
  async function insertEmployee(value) {
    const res = await EmployeeService.insertUser({
      departmentID: value?.departmentID,
      avatar: fileUrl,
      username: value?.username,
      password: value?.password,
      email: value?.email,
      fullName: value?.fullName,
      phoneNumber: value?.phoneNumber,
      sex: value?.sex == SEX_TYPE.MALE,
      address: value?.address,
      dateOfBirth: value?.dateOfBirth ? dayjs(value?.dateOfBirth).format('YYYY-MM-DD') : null,
      wardID: value?.wardID || 0,
      districtID: value?.districtID || 0,
      provinceID: value?.provinceID || 0,
      roleID: value?.roleID
    });
    if(res?.isError) return
    Notice({ msg: `Thêm nhân viên thành công !` });
    onOk && onOk();
    props?.onCancel();
    reloadTable();
  }

  /**
   * @description update nhân viên
   * @author hddung
   */
  async function updateEmployee(value) {
    const res = await EmployeeService.updateUser({
      userID: detailInfo?.userID,
      departmentID: value?.departmentID,
      avatar: fileUrl,
      username: value?.username,
      email: value?.email,
      fullName: value?.fullName,
      phoneNumber: value?.phoneNumber,
      sex: value?.sex == SEX_TYPE.MALE,
      address: value?.address,
      dateOfBirth: value?.dateOfBirth ? dayjs(value?.dateOfBirth).format('YYYY-MM-DD') : null,
      wardID: value?.wardID || 0,
      districtID: value?.districtID || 0,
      provinceID: value?.provinceID || 0,
      roleID: value?.roleID
    });
    if(res?.isError) return
    Notice({ msg: `Cập nhật nhân viên thành công !` });
    onOk && onOk();
    props?.onCancel();
    reloadTable();
  }

  /**
   * @description validate file upload
   * @author hddung
   */
  function handleBeforeUpload(file) {
    const fileSize = file.size / 1024 / 1024; // Chuyển đổi dung lượng thành MB
    const isImage = file.type.startsWith('image/');

    if (fileSize > 5) {
      message.error('Dung lượng ảnh không được vượt quá 5MB!');
      return false; // Ngăn không cho tải lên
    }
    if (!isImage) {
      message.error('Chỉ được tải lên file ảnh!');
      return false; // Ngăn không cho tải lên
    }

    return true; // Cho phép tải lên
  };

  //#endregion
  
  const renderFooter = () => (
    <div className="d-flex justify-content-flex-end">
      <Button
        className="btn-hover-shadow"
        onClick={() => props?.onCancel()}
      >
        Đóng
      </Button>
      <Button
        btnType="primary"
        className="btn-hover-shadow"
        onClick={onSubmitData}
      >
        {detailInfo ? 'Cập nhật' : 'Lưu'}
      </Button>
    </div>
  )
  return (
    <CustomModal
      title={!!detailInfo ? "Cập nhật nhân viên" : "Thêm nhân viên"}
      footer={renderFooter()}
      width={1024}
      {...props}
    >
      <SpinCustom spinning={loading}>
        <Styled>
          <Form
            form={form}
            layout="vertical"
            // initialValues={{
            //   Password: defaultPass?.description,
            //   ListUserManager: [
            //     { PositionID: undefined, DepartmentID: undefined },
            //   ],
            // }}
          >
            <Row gutter={[16]}>
              <Col span={fileUrl ? 10 : 24}>
                <Form.Item
                  label="Hình đại diện"
                  name="avatar"
                  rules={[
                    {
                      required: true,
                      message: "Thông tin không được để trống",
                    },
                  ]}
                >
                  <Upload {...uploadProps}>
                    <Row className="align-items-center">
                      <ButtonUploadStyle>
                        <Button className="account-button-upload ">
                          <Row className="account-background-upload d-flex align-items-center">
                            <SvgIcon name="add-media-video" />
                            <div className="account-text-upload ml-16">
                              Chọn ảnh
                            </div>
                          </Row>
                        </Button>
                      </ButtonUploadStyle>
                      <div className="sub-color fs-12 ml-16">
                        Dung lượng file tối đa 5MB, định dạng: .JPG, .JPEG,
                        .PNG, .SVG
                      </div>
                    </Row>
                  </Upload>
                </Form.Item>
              </Col>
              { fileUrl &&
                <Col span={7}>
                  <Image
                    src={fileUrl}
                    alt="image"
                    loading="lazy"
                    width={100}
                  />
                </Col>
              }
              <Col md={12} xs={24}>
                <Form.Item
                  label="Họ và tên"
                  required
                  name="fullName"
                  rules={[
                    {
                      required: true,
                      message: "Thông tin không được để trống",
                    },
                  ]}
                >
                  <Input placeholder="Nhập tên" />
                </Form.Item>
              </Col>

              <Col md={detailInfo?.UserID ? 6 : 12} xs={24}>
                <Form.Item
                  label="Tên tài khoản"
                  required
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Thông tin không được để trống",
                    },
                    {
                      pattern: getRegexUsername(),
                      message:
                        "Tài khoản phải nhiều hơn 6 kí tự, bao gồm chữ số hoặc chữ cái hoặc kí tự _ và không chứa khoảng trắng",
                    },
                  ]}
                >
                  <Input placeholder="Nhập tên" />
                </Form.Item>
              </Col>
              {!!detailInfo?.UserID && (
                <Col md={6} xs={24}>
                  <Form.Item
                    label="Trạng thái"
                    name="status"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Thông tin không được để trống",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Chọn trạng thái"
                      options={[]}
                    />
                  </Form.Item>
                </Col>
              )}
              {!detailInfo && (
                <>
                  <Col md={12} xs={24}>
                    <Form.Item
                      label="Mật khẩu"
                      required
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Thông tin không được để trống",
                        },
                        {
                          pattern: getRegexPassword(),
                          message:
                            "Mật khẩu có chứa ít nhất 8 ký tự, trong đó có ít nhất một số và bao gồm cả chữ thường và chữ hoa và ký tự đặc biệt, ví dụ @, #, ?, !.",
                        },
                      ]}
                    >
                      <Input.Password
                        placeholder="Nhập"
                        autoComplete="new-password"
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} xs={24}>
                    <Form.Item
                      label="Nhập lại mật khẩu"
                      required
                      name="PasswordConfirm"
                      rules={[
                        {
                          required: true,
                          message: "Thông tin không được để trống",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve()
                            }
                            return Promise.reject(
                              new Error("Mật khẩu xác nhận không đúng!"),
                            )
                          },
                        }),
                      ]}
                    >
                      <Input.Password placeholder="Nhập" />
                    </Form.Item>
                  </Col>
                </>
              )}

              <Col md={12} xs={24}>
                <Form.Item
                  label="Số điện thoại"
                  name="phoneNumber"
                  rules={[
                    {
                      pattern: getRegexPhoneNumber(),
                      message:
                        "Vui lòng nhập đúng số điện thoại",
                    },
                  ]}
                >
                  <Input placeholder="Nhập" />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Thông tin không được để trống",
                    },
                    {
                      pattern: getRegexEmail(),
                      message: "Email sai định dạng",
                    },
                  ]}
                >
                  <Input placeholder="Nhập email" />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item 
                  label="Giới tính" 
                  name="sex"
                >
                  <Select placeholder="Chọn" allowClear defaultValue={detailInfo?.sex || SEX_TYPE.MALE}>
                    {getListComboByKey(
                      SYSTEM_KEY?.SEX_TYPE,
                      listSystemKey,
                    )?.map(i => (
                      <Option key={+i?.codeValue} value={+i?.codeValue}>
                        {i?.description}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item 
                  label="Ngày sinh" 
                  name="dateOfBirth"
                >
                  <DatePicker
                    placeholder="Chọn"
                    format="DD/MM/YYYY"
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item 
                  label="Tỉnh/Thành phố"
                  name="provinceID"
                >
                  <Select placeholder="-- Chọn Tỉnh/Thành phố --" allowClear onChange={onChangeProvince}>
                      {
                          listProvince.map((province)=>(
                          <Option key={province.regionID} value={province.regionID}>{province.regionName}</Option>
                              ))
                          }
                  </Select>
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item 
                  label="Quận/Huyện"
                  name="districtID"
                >
                  <Select placeholder="-- Chọn Quận/Huyện --" allowClear onChange={onChangeDistrict}>
                      {
                          listDistrict.map((district)=>(
                              <Option key={district.regionID} value={district.regionID}>{district.regionName}</Option>
                          ))
                      }
                  </Select>
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item 
                  label="Phường/Xã" 
                  name="wardID"
                >
                  <Select placeholder="-- Chọn Phường/Xã --" allowClear onChange={onChangeWard}>
                      {
                          listWard.map((district)=>(
                              <Option key={district.regionID} value={district.regionID}>{district.regionName}</Option>
                          ))
                      }
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label="Địa chỉ" name="address">
                  <Input placeholder="Nhập" />
                </Form.Item>
              </Col>

              <Col md={12} xs={24}>
                <Form.Item
                  label="Phòng ban"
                  required
                  name="departmentID"
                  rules={[
                    {
                      required: true,
                      message: "Thông tin không được để trống",
                    },
                  ]}
                >
                  <TreeSelect
                    allowClear
                    showSearch
                    treeNodeFilterProp="title"
                    style={{
                        width: '100%',
                    }}
                    dropdownStyle={{
                        maxHeight: 400,
                        overflow: 'auto',
                    }}
                    placeholder="Chọn phòng ban"
                    treeDefaultExpandAll
                    treeData={listDept}
                  />
                </Form.Item>
              </Col>

              <Col md={12} xs={24}>
                <Form.Item
                  label="Nhóm quyền"
                  required
                  name="roleID"
                  rules={[
                    {
                      required: true,
                      message: "Thông tin không được để trống",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Chọn"
                    optionFilterProp="label"
                    // mode="multiple"
                    maxTagCount="responsive"
                    options={listRole}
                  />
                </Form.Item>
              </Col>

            </Row>
          </Form>
        </Styled>
      </SpinCustom>
    </CustomModal>
  )
}

export default ModalInsertUpdate
