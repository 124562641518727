import { Col, Divider, Row, Spin } from "antd"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import SvgIcon from "src/components/SvgIcon"
import { formatDateAndTime } from "src/lib/dateFormatters"
import GuestServices from "src/services/GuestService"
import { RelateNewsStyled } from "../styled"
import { ROUTER_KEY } from "src/constants/constants"

const RelateToNews = () => {
  const [loading, setLoading] = useState(false)
  const [listData, setListData] = useState([])
  const navigate = useNavigate()

  const getCategoryPost = () => {
    setLoading(true)
    GuestServices.getCategoryPost()
      .then(res => {
        if (res.isOk) setListData(res.object)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    getCategoryPost()
  }, [])

  return (
    <RelateNewsStyled>
      <Spin spinning={loading}>
        <div className="relate-news-header">
          <div className="relate-news-header-text">Danh mục bài viết</div>
          <div className="relate-news-header-space" />
        </div>
        <div className="relate-news-content">
          <div className="relate-news-text-care">Nhiều người quan tâm</div>
          {listData?.listMostInterested?.map((i, idx) => (
            <div
              key={`RelateToNews${idx}`}
              className="relate-news-box-care pointer"
              onClick={() => {
                window.scrollTo(0, 0)
                navigate(`/${i?.slug || `bai-viet${ROUTER_KEY.NOI_DUNG}`}-${i?.postID}`)
              }}
            >
              <div className="relate-news-box-care-title">{i?.title}</div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="relate-news-box-care-time">
                  {formatDateAndTime(i?.publicationTime)}
                </div>
                <div className="d-flex">
                  <SvgIcon
                    name="mode-comment"
                    style={{ margin: "0 6px 0 10px" }}
                  />
                  <div className="number-comment">{i?.numberComment}</div>
                  <div className="d-flex">
                    <SvgIcon name="view" style={{ margin: "0 6px 0 10px" }} />
                    <div className="number-comment">
                      {/* {!!item?.NumberOfViewers ? item?.NumberOfViewers : 0} */}
                      {i?.numberOfViewers}
                    </div>
                  </div>
                </div>
              </div>

              <Divider style={{ borderTopWidth: idx === 2 ? 4 : 1 }} />
            </div>
          ))}
          <div className="relate-news-text-care">Tags phổ biến</div>
          <Row gutter={[12, 24]} className="mt-24">
            {listData?.listPopularTags?.map((i, idx) => (
              <Col
                key={`tag123123${idx}`}
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0 })
                  navigate(`/tin-tuc/tags-${i?.tagsID}`, {
                    state: i,
                  })
                }}
              >
                <div className="relate-news-tag pointer"> {i?.tagsName}</div>
              </Col>
            ))}
          </Row>
          <Divider style={{ borderTopWidth: 4 }} />
        </div>
      </Spin>
    </RelateNewsStyled>
  )
}

export default RelateToNews
