import {Col, DatePicker, Form, Input, Row, Select} from "antd";
import {getRegexPassword} from "../../../../lib/utils";
import FlInput from "../../../../components/FloatingLabel/Input";
import {Option} from "antd/es/mentions";
import {
    getRegexEmail,
    getRegexMobile,
    getRegexMobileVN,
    getRegexPhoneNumber,
    getRegexTax
} from "../../../../lib/stringsUtils";
import React, {useEffect, useState} from "react";
import moment from "moment/moment";
import RegionService from "../../../../services/RegionService";
import dayjs from "dayjs";
import http from "../../../../services";
import Swal from "sweetalert2";
import Button from "../../../../components/MyButton/Button";
import CustomModal from "../../../../components/Modal/CustomModal";
import Notice from "../../../../components/Notice";
import SpinCustom from "../../../../components/Spin";
import {GUIDE_EMPTY} from "../../../../constants/constants";
import {useSelector} from "react-redux";

export const ManualCustomerCompanyCreate = ({open, cancel, refreshData,...props}) => {
    moment.locale('en');
    const [loading, setLoading] = useState(false);
    const [listWard, setListWard] = useState([]);
    const [listProvince, setListProvince] = useState([]);
    const [listDistrict, setListDistrict] = useState([]);
    const [provinceSelected, setProvinceSelected] = useState();
    const [disctricteSelected, setDisctricteSelected] = useState();
    const {userInfo} = useSelector(state => state?.appGlobal)

    const getListProvinceVN = () => {
        setLoading(true)
        RegionService.getByRegionId({regionId: 234})
            .then(res => {
                if (res?.isError) return
                setListProvince(res?.object);
                loadListDistrict(provinceSelected);
                loadListWard(disctricteSelected);
            })
            .finally(() => setLoading(false))
    }
    const loadListDistrict = (newValue) => {
        if (newValue && newValue !== 0) {
            RegionService.getByRegionId({regionId: newValue})
                .then(res => {
                    if (res?.isError) return
                    const lstDistrict = res?.object || [];
                    setListDistrict(lstDistrict)
                })
        }
    }
    const loadListWard = (newValue) => {
        if (newValue && newValue !== 0) {
            RegionService.getByRegionId({regionId: newValue})
                .then(res => {
                    if (res?.isError) return
                    const listWard = res?.object || [];
                    setListWard(listWard)
                })
        }
    }
    useState(() => {
        getListProvinceVN();
    }, [])

    const handleSubmit = () => {
        setLoading(true);
        form.validateFields().then(values => {
            let model={...values};
            if(props?.isCompany){
                model = {
                    ...values}
            }else{
                model = {
                    ...values,
                    dateOfBirth: values.dateOfBirth ? dayjs(values.dateOfBirth).format('YYYY-MM-DDTHH:mm:ss').toString() : undefined,
                    sex: values.sex == 1
                }
            }
            http.post("ManageCustomer/AddBusiness", model).then(resp => {
                if (!resp?.isError) {
                    Notice({msg: `Tạo khách hàng thành công`});
                    refreshData();
                    setLoading(false);
                    cancel();
                }
                setLoading(false);
            })
        }).catch(reason => {
            setLoading(false);
        }).finally(() => {
        })

    }
    // const showSuccess = (message) => {
    //     Swal.fire({
    //         icon: 'success',
    //         title: 'Thành Công',
    //         text: message,
    //     }).then(() => {
    //         // navigate(ROUTER.TEMPLATE_HOP_DONG);
    //         refreshData();
    //         cancel();
    //     });
    // };

    useEffect(() => {

    }, [])
    const renderFooter = () => (
        <div className="d-flex justify-content-flex-end">
            <Button
                btnType="primary"
                className="btn-hover-shadow"
                onClick={() => handleSubmit()}
                disabled={loading}
            >
                Lưu
            </Button>
            <Button
                className="btn-hover-shadow"
                onClick={() => cancel()}
            >
                Đóng
            </Button>
        </div>
    )
    const [form] = Form.useForm();
    const onChangeProvince = newValue => {
        form.resetFields([`districtID`]);
        form.resetFields([`wardID`]);
        if (!newValue) {
            setListDistrict([]);
            return;
        }
        setLoading(true);
        RegionService.getByRegionId({regionId: newValue})
            .then(res => {

                if (res?.isError) return
                const lstDistrict = res?.object || [];
                setListDistrict(lstDistrict)
            })
            .finally(() => setLoading(false));
    };
    const onChangeDistrict = e => {
        form.resetFields([`wardID`])
        if (!e) return setListWard([])
        setLoading(true)
        RegionService.getByRegionId({regionId: e})
            .then(res => {
                if (res?.isError) return
                const lstWard = res?.object || [];
                setListWard(lstWard);
            })
            .finally(() => setLoading(false))
    }

    return (
        <>
            <CustomModal
                title={"Thêm mới khách hàng doanh nghiệp"}
                footer={renderFooter()}
                width={1600}
                open={!!open}
                onCancel={cancel}
            >
                <SpinCustom spinning={loading}>
                    <div className={"d-flex flex-column container"}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <Row gutter={[16, 16]}>
                                {
                                    userInfo?.accountID===GUIDE_EMPTY&&(
                                        <Col span={12}>
                                            <Form.Item
                                                name="username"
                                                label="Tên đăng nhập"
                                                required
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Thông tin không được để trống",
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Nhập"/>
                                            </Form.Item>
                                        </Col>
                                    )
                                }
                                {/*<Col span={12}>*/}
                                {/*    <Form.Item*/}
                                {/*        name="password"*/}
                                {/*        label="Mật khẩu"*/}
                                {/*        required*/}
                                {/*        rules={[*/}
                                {/*            { required: true, message: "Vui lòng nhập mật khẩu" },*/}
                                {/*            {*/}
                                {/*                pattern: getRegexPassword(),*/}
                                {/*                message:*/}
                                {/*                    "Mật khẩu có chứa ít nhất 8 ký tự, trong đó có ít nhất một số và bao gồm cả chữ thường và chữ hoa và ký tự đặc biệt, ví dụ @, #, ?, !.",*/}
                                {/*            },*/}
                                {/*        ]}*/}
                                {/*    >*/}
                                {/*        <FlInput isPass  isRequired />*/}
                                {/*    </Form.Item>*/}
                                {/*</Col>*/}

                                <Col span={12}>
                                    {!props?.isCompany
                                        &&(
                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Bạn chưa nhập họ và tên!",
                                                    },
                                                    {
                                                        min: 8,
                                                        message: "Độ dài của tên là tối thiểu là 8 kí tự!",
                                                    },
                                                    {
                                                        max: 50,
                                                        message: "Độ dài của tên là tối đa là 50 kí tự",
                                                    },
                                                ]}
                                                label="Họ và tên"
                                                name="fullName"
                                            >
                                                <Input placeholder="Nhập họ và tên"/>
                                            </Form.Item>
                                        )

                                    }
                                    {props?.isCompany
                                        &&(
                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Bạn chưa nhập tên doanh nghiệp!",
                                                    },
                                                    {
                                                        min: 8,
                                                        message: "Độ dài của tên là tối thiểu là 8 kí tự!",
                                                    },
                                                    {
                                                        max: 50,
                                                        message: "Độ dài của tên là tối đa là 50 kí tự",
                                                    },
                                                ]}
                                                label="Tên doanh nghiệp"
                                                name="fullName"
                                            >
                                                <Input placeholder="Nhập tên doanh nghiệp"/>
                                            </Form.Item>
                                        )

                                    }
                                </Col>
                                {!props?.isCompany&&
                                    (
                                        <Col span={8}>
                                            <Form.Item label="Giới tính" name="sex" required>
                                                <Select placeholder="Chọn" allowClear>
                                                    <Option key="1241" value="1">
                                                        Nam
                                                    </Option>
                                                    <Option key="1230" value="0">
                                                        Nữ
                                                    </Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>)
                                }
                                <Col span={8}>
                                    <Form.Item
                                        name="phoneNumber"
                                        label="Số điện thoại"
                                        required
                                        rules={[
                                            {required: true, message: "Thông tin không được để trống"},
                                            {
                                                pattern: getRegexPhoneNumber(),
                                                message: "Vui lòng nhập đúng số điện thoại",
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Nhập"/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        name="email"
                                        label="Email"
                                        required
                                        rules={[
                                            // { required: true, message: "Thông tin không được để trống" },
                                            {
                                                pattern: getRegexEmail(),
                                                message: "Email sai định dạng",
                                            },
                                            {
                                                max: 50,
                                                message: "Email thuế không được quá 50 ký tự",
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Nhập"/>
                                    </Form.Item>
                                </Col>
                                {!props?.isCompany&&(

                                    <Col span={8}>
                                        <Form.Item label="Ngày sinh" name="dateOfBirth">
                                            <DatePicker
                                                placeholder="Chọn"
                                                format="DD/MM/YYYY"
                                                allowClear
                                            />
                                        </Form.Item>
                                    </Col>
                                )
                                }
                                {
                                    props?.isCompany?(
                                        <Col span={8}>
                                            <Form.Item
                                                name="taxNumber"
                                                label="Mã số thuế"
                                                rules={[
                                                    { required: true, message: "Thông tin không được để trống" },
                                                    {
                                                        pattern: getRegexTax(),
                                                        message: "Mã số thuế sai định dạng",
                                                    },
                                                    {
                                                        max: 50,
                                                        message: "Mã số thuế không được quá 50 ký tự",
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Nhập"/>
                                            </Form.Item>
                                        </Col>

                                    ):(
                                        <Col span={8}>
                                            <Form.Item
                                                name="taxNumber"
                                                label="Căn cước công dân"
                                                rules={[
                                                    { required: true, message: "Thông tin không được để trống" },
                                                    {
                                                        pattern: getRegexTax(),
                                                        message: "Căn cước sai định dạng",
                                                    }
                                                ]}
                                            >
                                                <Input placeholder="Nhập"/>
                                            </Form.Item>
                                        </Col>
                                    )
                                }

                                {/*address */}
                                <Col span={8}>
                                    <Form.Item label="Tỉnh/Thành Phố" name="provinceID"
                                               required
                                               rules={[
                                                   { required: true, message: "Thông tin không được để trống" }
                                               ]}
                                    >
                                        <Select placeholder="Chọn" allowClear onChange={onChangeProvince}>
                                            {
                                                listProvince.map((province) => (
                                                    <Option key={province.regionID}
                                                            value={province.regionID}>{province.regionName}</Option>
                                                ))
                                            }

                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item label="Quận/Huyện" name="districtID"
                                               required
                                               rules={[
                                                   { required: true, message: "Thông tin không được để trống" }
                                               ]}
                                    >
                                        <Select placeholder="Chọn" allowClear onChange={onChangeDistrict}
                                        >
                                            {
                                                listDistrict.map((district) => (
                                                    <Option key={district.regionID}
                                                            value={district.regionID}>{district.regionName}</Option>
                                                ))
                                            }

                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Xã/Phường" name="wardID"

                                               required
                                               rules={[
                                                   { required: true, message: "Thông tin không được để trống" }
                                               ]}>
                                        <Select placeholder="Chọn" allowClear
                                        >
                                            {
                                                listWard.map((district) => (
                                                    <Option key={district.regionID}
                                                            value={district.regionID}>{district.regionName}</Option>
                                                ))
                                            }

                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="Số nhà/tổ/thôn/xóm... "
                                        name="address"
                                        required
                                        rules={[
                                            { required: true, message: "Thông tin không được để trống" }
                                        ]}

                                    >
                                        <Input placeholder="Số nhà/tổ/thôn/xóm... "/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>

                    </div>
                </SpinCustom>
            </CustomModal>
        </>
    )

}
