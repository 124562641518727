import React, {useEffect, useState} from "react";

import http from "../../../../../services";
import {ContractListTable} from "./ContractListTable";

export const ContractManagement=()=>{

    const [contracts, setContracts] = useState([])
    const [totalPage, setTotalPage] = useState([])

    const [statusSlected, setStatusselected] = useState(0);

    const [pagination, setPagination] = useState({
        "pageSize": 10,
        "currentPage": 1,
        "textSearch": "",
        "fromDate": null,
        "toDate": null,
        "type": 0
    })
    const getListContract = () => {
        http.post('/Contract/GetAllContract',{...pagination})
            .then(res => {
                if (!res?.isError) {
                    setContracts([]);
                    setContracts(res.object.list)
                    setTotalPage(res.object.count);
                }else{
                    setContracts([]);
                }

            })
    }
    const handleFilter =(model)=>{
        setPagination(model)
    }
    const updateContract = (orderId, updatedOrder) => {
        setContracts((prevOrder) =>
            prevOrder.map((order) =>
                (order.orderId === orderId ? updatedOrder : order)
            )
        );
    };

    useEffect(() => {
        getListContract();
        // console.log('pagi',pagination);
    }, [pagination]);




    const onChangeKeyword = (key: string) => {

        setContracts([]);
        const newPagi = {...pagination, keyword: key, currentPage: 1};
        setPagination(newPagi);
    }


    const handleChangePage=(model) => {
        setPagination(model)
    }
    return (
        <>
            <div className={" page-container"}>
                <div className={"main-nav__content"}>

                </div>

                <div className={"content-contracts"}>
                    <ContractListTable contracts={contracts}  refreshData={updateContract} totalPage={totalPage}
                                    pagination={pagination} getOrder={handleChangePage}
                                    onChangeKeyword={onChangeKeyword}
                                    handleFilter={handleFilter}
                    />
                </div>

            </div>
        </>


    )

}
