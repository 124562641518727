import {useEffect, useState} from "react"
import {useNavigate} from "react-router-dom"
import FlInput from "src/components/FloatingLabel/Input"
import UseWindowSize from "../../../lib/useWindowSize";

const SearchBar = ({navigatePath, txtLabel}) => {
  const navigate = useNavigate()
  const [textSearch, settextSearch] = useState("")
  const [datetimeCurrent, setDatetimeCurrent] = useState(false)
  useEffect(() => {
    const interval = setInterval(() => {
      setDatetimeCurrent(prev => !prev)
    }, 2000);

    const queryParams = new URLSearchParams(window.location.search);
    settextSearch(queryParams.get('search'));

    return () => clearInterval(interval);

  }, [])
  return (
    <>
      {/* <Dropdown
        overlay={<SearchRecommend textSearch={textSearch} />}
        trigger={["click"]}
      ></Dropdown> */}
        <FlInput
          style={{ width: "100%" ,margin:"5px 0px"}}
          search="true"
          // isanimate={true}
          value={textSearch}
          onChange={value => {
            settextSearch(value.target.value)
          }}
          onSearch={value => {
            navigate(navigatePath || `/danh-sach-san-pham${value ? `?search=${value}` : '' }`, {
              state: { textSearch: value },
            });
          }}
          label={
            txtLabel || "Tìm kiếm sản phẩm"
            // <Texty type="swing-y" mode="smooth">
            //   {!datetimeCurrent && "Tìm kiếm tin tức"}
            // </Texty>
          }
        />
        {/* </Dropdown> */}
    </>
  )
}

export default SearchBar
