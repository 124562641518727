import {Col, Form, Image, InputNumber, QRCode, Row, Typography} from "antd"
import React, {useEffect, useState} from "react"
import LayoutCommon from "src/components/Common/Layout"
import styled from "styled-components"
import PaymentService from "src/services/PaymentService"
import { PAYMENT_TYPE } from "src/constants/constants"
import Button from "src/components/MyButton/Button"

const {Paragraph} = Typography
const StyleWallet = styled.div`
  background: #fff;

  .bank-img {
    height: 100px;
    border-radius: 10px;
    margin-top: 16px;
  }

  .wallet-left {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .wallet-right {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
`
const Wallet = () => {
    const [loading, setLoading] = useState()
    const [detail, setDetail] = useState()
    const [modePayment, setModePayment] = useState(PAYMENT_TYPE.VIETCOMBANK);
    const url = window.location.href;
    const [form] = Form.useForm();
    const [priceValue, setPriceValue] = useState('');
    const getDetail = async () => {
        try {
            setLoading(true)
            const res = await PaymentService.InforPayment()
            if (res?.isError) return
            setDetail(res?.object)
        } finally {
            setLoading(false)
        }
    }

    /**
     * @description set title tab web
     * @author hddung
     */
    useEffect(() => {
        if(url) {
            document.title = 'Nạp tiền'; // Thay đổi title thành 'New Title'
        
            return () => {
            document.title = 'TẠP HÓA SỐ'; // Đặt lại title khi component bị unmount
            };
        }
    }, [url]);


    /**
     * @description Chặn sự kiện nhập chữ
     * @author hddung 
     */
    function handleKeyDown(e) {
        // Lấy mã phím từ sự kiện
        const keyCode = e.keyCode || e.which;

        // Kiểm tra nếu mã phím không phải là số
        if (keyCode >=65 && keyCode <= 90) {
            e.preventDefault(); // Ngăn chặn sự kiện mặc định của phím
        }
    };

    function validateForm(_, value) {
        if (value && (value < 5000 || value > 1000000000)) {
            return Promise.reject('Số tiền hợp lệ từ 5,000 đến dưới 1 tỷ đồng');
        }
        return Promise.resolve();
    }

    /**
     * @description Thực hiện hiển thị tab của vnpay để thanh toán
     * @author hddung
     */
    async function openVNPay() {
        try {
            await form.validateFields().then(async values => {
                const res = await PaymentService.createPaymentUrl(priceValue);
                if(res?.isError) return
                
                const width = 1000; // Chiều rộng của cửa sổ
                const height = 600; // Chiều cao của cửa sổ
                const left = (window.innerWidth - width) / 2; // Vị trí căn giữa theo chiều ngang
                const top = (window.innerHeight - height) / 2; // Vị trí căn giữa theo chiều dọc
                
                const popupWindow = window.open(res?.object, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
            })
        } catch{}
    }

    const currencyFormatter = (value) => {
        const formattedValue = new Intl.NumberFormat('vi-VN', {
            style: 'currency',
            currency: 'VND'
        }).format(value);
        return formattedValue.replace(/₫|,/g, '');
    };

    const currencyParser = (value) => {
        if (typeof value === 'string') {
            const parsedValue = value.replace(/[^\d]/g, '');
            return parsedValue ? parseInt(parsedValue, 10) : '';
        }
        return value;
    };


    useEffect(() => {
        getDetail()
    }, [])
    return (
        <StyleWallet>
            <LayoutCommon style={{minWidth: 700}}>
                <Row gutter={[16, 16]} className="p-24 flex-wrap">
                    <Col xs={24} md={12} style={{maxWidth: "none"}} className={"d-flex-center"}>
                        <p style={{fontWeight:600,fontSize:22,maxWidth:"240px",whiteSpace:"normal",overflowWrap:"break-word"}}>Phương thức nạp tiền</p>
                    </Col>
                </Row>
                <div style={{minHeight:650}}>
                    <div className="p-24 flex-wrap d-flex-center" style={{width:'100%',marginBottom:50}}>
                        <Button
                            btnType={modePayment == PAYMENT_TYPE.VIETCOMBANK ? 'primary' : ''}
                            className="btn-hover-shadow mr-10"
                            onClick={() => {
                                setModePayment(PAYMENT_TYPE.VIETCOMBANK);
                            }}
                        >
                            Vietcombank
                        </Button>
                        {/* <Button
                            btnType={modePayment == PAYMENT_TYPE.VNPAY ? 'primary' : ''}
                            className="btn-hover-shadow"
                            onClick={() => {
                                setModePayment(PAYMENT_TYPE.VNPAY);
                            }}
                        >
                            VNPay
                        </Button> */}
                    </div>
                    {
                        modePayment == PAYMENT_TYPE.VIETCOMBANK ? 
                        (
                            <Row gutter={[16, 16]} className="p-24 flex-wrap">
                                <Col xs={24} md={12} style={{maxWidth: "none"}}>
                                    <div className="wallet-left">
                                        <div>
                                            <img className="bank-img" src={detail?.imgBanking} alt="" style={{marginLeft:50}}/>
                                            <ul className="mt-16" style={{marginLeft: "20px"}}>
                                                <li className="mt-8 d-flex">
                                                    STK: 
                                                    <Paragraph copyable className="fw-600 mb-0" style={{marginLeft: 4}}>
                                                        {detail?.stkBanking}
                                                    </Paragraph>
                                                </li>
                                                <li className="mt-8">
                                                    Người nhận: <b>{detail?.reciverBanking}</b>
                                                </li>
                                                <li className="mt-8">
                                                    <div
                                                        classname="transfer-content"
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "flex-start",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <span>Nội dung chuyển khoản: </span>
                                                        <div>
                                                            <Paragraph copyable className="fw-600 mb-0">
                                                                {detail?.rechargeContent}
                                                            </Paragraph>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={24} md={12} style={{maxWidth: "none"}}>
                                    <div className="fw-600 text-center mt-16 d-flex-center">
                                        <p style={{maxWidth:"240px",whiteSpace:"normal",overflowWrap:"break-word"}}>Quét mã QRCode để nội dung chuyển khoản chính xác</p>
                                    </div>
                                    <div className="wallet-right">
                                        <div>
                                            <Image width="300px" src={detail?.qr}/>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        )
                        :
                        (
                            <Row gutter={[16, 16]} className="p-24 flex-wrap">
                                <Col xs={24} md={12} style={{maxWidth: "none"}} className={"d-flex-center"} >
                                    <div className="logo-vnpay-recharge" style={{maxWidth:250,width:"80%"}}>
                                        <Image width="100%" style={{objectFit:"contain",boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                        borderRadius:'20px'

                                        }} src="http://res.cloudinary.com/dmcqrnfke/image/upload/v1710828783/xqucdgqzmx6prfkqij4n.png"/>
                                    </div>
                                </Col>
                                <Form
                                    form={form}
                                    layout="vertical"
                                    style={{margin: 'auto'}}
                                >
                                    <Col xs={24} md={12} style={{maxWidth: "none"}}>
                                        <Form.Item
                                            label="Nhập số tiền cần nạp :"
                                            name="price"
                                            rules={[
                                            {
                                                required: true,
                                                message: "Thông tin không được để trống",
                                            },
                                            {
                                                validator: validateForm
                                            }
                                            ]}
                                        >
                                            <InputNumber
                                                placeholder="Nhập giá tiền VNĐ"
                                                formatter={currencyFormatter}
                                                parser={currencyParser}
                                                controls={false}
                                                onChange={values => {setPriceValue(values)}}
                                                onKeyDown={handleKeyDown}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12} style={{maxWidth: "none"}}>
                                        <div className="d-flex justify-content-flex-end">
                                            <Button
                                                btnType="primary"
                                                className="btn-hover-shadow"
                                                onClick={openVNPay}
                                            >
                                                Tạo mã nạp tiền
                                            </Button>
                                        </div>
                                    </Col>
                                </Form>
                            </Row>
                        )
                    }
                </div>
            </LayoutCommon>
        </StyleWallet>
    )
}



export default Wallet
