import { Spin, Tree } from "antd"
import _ from "lodash"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import CB1 from "src/components/Modal/CB1"
import ButtonCircle from "src/components/MyButton/ButtonCircle"
import Notice from "src/components/Notice"
import { GUIDE_EMPTY } from "src/constants/constants"
import PostService from "src/services/PostService"
import { TreeAnchorStyled } from "../../ListUser/styled"
import InsertCategory from "../modal/InsertCategory"
export const sort = items => {
  return _.orderBy(items, ["level", "numericalOrder"], ["asc", "asc"])
}
const TreeCategory = ({ selectedNode, setSelectedNote }) => {
  const { state } = useLocation()

  const [loading, setLoading] = useState(false)
  const [lstCate, setLstCate] = useState([])
  const [open, setOpen] = useState()

  const [treeData, setTreeData] = useState([])
  const ACTION = [
    {
      icon: "add-box",
      title: "Thêm danh mục",
      onClick: nodeData => setOpen(nodeData),
      static: true,
    },
    {
      icon: "edit-black",
      title: "Sửa thông tin",
      onClick: nodeData => setOpen({ ...nodeData, isEdit: true }),
      static: false,
    },
    {
      icon: "delete-black",
      title: "Xóa danh mục",
      static: false,
      onClick: nodeData => {
        CB1({
          title: `Bạn có chắc chắn muốn xoá danh mục
          <strong> ${nodeData?.categoryPostName}</strong> không?`,
          icon: "trashRed",
          okText: "Đồng ý",
          onOk: async close => {
            onDeleteCategory(nodeData?.key)
            close()
          },
        })
      },
    },
  ]

  useEffect(() => {
    getListCategory()
  }, [])

  const onDeleteCategory = async id => {
    try {
      const res = await PostService.deleteCategory(id)
      if (res?.isError) return
      Notice({ msg: "Xóa danh mục thành công!" })
      getListCategory()
    } finally {
    }
  }

  const nest = (items, id, link) =>
    items
      ?.filter(item => item[link] === id)
      .map(item => ({
        ...item,
        title: item.categoryPostName,
        key: item.categoryPostID,
        isStatic: item.isStatic,
        children: nest(items, item?.categoryPostID, link),
      }))

  const getListCategory = async () => {
    try {
      setLoading(true)
      const res = await PostService.getListCategoryPost({
        categoryPostID: GUIDE_EMPTY,
      })
      if (res?.isError) return
      console.log("res-cate", res)
      setLstCate(res?.object?.data)
      // sort(res?.object?.data)
      const tree = nest(
        sort([
          {
            categoryCode: "TAT_CA",
            categoryPostID: "00000000-0000-0000-0000-000000000000",
            categoryPostName: "Tất cả",
            description: null,
            isStatic: true,
            level: 0,
            numericalOrder: 2,
            parentID: "-1",
          },
          ...res?.object?.data,
        ]),
        GUIDE_EMPTY,
        // "-1",
        "parentID",
      )
      console.log("1", res?.object?.data)
      setTreeData(tree)

      setSelectedNote(e =>
        !e
          ? state?.selectedNode
            ? state?.selectedNode
            : tree[0]?.children[0]
          : e,
      )
    } finally {
      setLoading(false)
    }
  }

  console.log("tree", treeData)
  const reSort = async (nodeData, i) => {
    try {
      setLoading(true)
      const res = await PostService.sortCategories([
        {
          categoryPostID: nodeData?.categoryPostID,

          numericalOrder: nodeData?.numericalOrder + i,
        },
        {
          categoryPostID: lstCate
            ?.filter(i => i?.parentID === nodeData?.parentID)
            ?.find(
              item => item?.numericalOrder === nodeData?.numericalOrder + i,
            )?.categoryPostID,
          numericalOrder: lstCate
            ?.filter(i => i?.parentID === nodeData?.parentID)
            ?.find(
              item => item?.numericalOrder === nodeData?.numericalOrder + i,
            )?.categoryPostID
            ? nodeData?.numericalOrder
            : undefined,
        },
      ])
      if (res?.isError) return
      getListCategory()

      Notice({ msg: "Cập nhật danh mục thành công !" })
    } finally {
      setLoading(false)
    }
  }
  return (
    <TreeAnchorStyled>
      <Spin spinning={loading}>
        {
          !!treeData?.length && (
            <Tree
              defaultExpandAll
              selectedKeys={[selectedNode?.key]}
              treeData={treeData}
              onSelect={(_, e) =>
                // !!(e?.node?.level > 1) &&
                setSelectedNote(e?.node)
              }
              titleRender={(nodeData, idx) => {
                console.log("note", nodeData)
                return (
                  <div
                    key={nodeData?.key}
                    className="d-flex justify-content-space-between align-items-center mh-36"
                  >
                    <div className="text-ellipsis">{nodeData?.title}</div>
                    <div className="list-button-tree-hover">
                      {!nodeData?.isStatic && ACTION
                        // ?.filter(
                        //   i =>
                        //     (nodeData?.isStatic && i?.static) ||
                        //     !nodeData?.isStatic,
                        // )
                        ?.map(item =>
                          !!(
                            !!(
                              nodeData?.level === 3 && item?.icon === "add-box"
                            )
                            // ||
                            // !!(
                            //   nodeData?.level === 1 &&
                            //   item?.icon === "delete-black"
                            // )
                          ) ? (
                            ""
                          ) : (
                            <ButtonCircle
                              key={item?.icon}
                              title={item?.title}
                              iconName={item?.icon}
                              onClick={() => {
                                item.onClick(nodeData)
                              }}
                            />
                          ),
                        )}
                      {!!(nodeData?.numericalOrder > 1) && (
                        <ButtonCircle
                          key={`${nodeData?.categoryPostID}arrow-up`}
                          title={"Lên"}
                          iconName={"arrow-up"}
                          onClick={() => {
                            reSort(nodeData, -1)
                          }}
                        />
                      )}
                      {lstCate?.filter(
                        item => item?.parentID === nodeData?.parentID,
                      )?.length > nodeData?.numericalOrder && (
                        <ButtonCircle
                          key={`${nodeData?.categoryPostID}arrow-up`}
                          title={"Xuống"}
                          iconName={"arrow-down"}
                          onClick={() => {
                            reSort(nodeData, 1)
                          }}
                        />
                      )}
                    </div>
                  </div>
                )
              }}
            />
          )
          // : (
          //   <Button
          //     btnType="primary"
          //     key={"addstart"}
          //     onClick={() => setOpen([])}
          //   >
          //     Thêm danh mục
          //   </Button>
          // )
        }
      </Spin>
      {!!open && (
        <InsertCategory
          open={open}
          onCancel={() => setOpen(undefined)}
          onOk={getListCategory}
        />
      )}
    </TreeAnchorStyled>
  )
}

export default TreeCategory
