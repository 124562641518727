import { Col, DatePicker, Row, Select, message } from "antd"
import { useSelector } from "react-redux"
import FlInput from "src/components/FloatingLabel/Input"
import FlSelect from "src/components/FloatingLabel/Select"
import { SYSTEM_KEY } from "src/constants/constants"
import { getListComboByKey } from "src/lib/utils"
import { SearchStyled } from "../styled"
import dayjs from "dayjs";
import Notice from "src/components/Notice"
import CommonService from "src/services/CommonService"
import { useState } from "react"
import { useEffect } from "react"

const { Option } = Select
const Search = ({ setDataBody, dataBody }) => {
  const { listSystemKey } = useSelector(state => state.appGlobal)
  const [listFunction, setListFunction] = useState([]);

  useEffect(() => {
    GetListComboboxSearch();
  }, [])

  /**
   * @description Thực hiện lấy danh sách hành động
   * @author hddung 
   */
  async function GetListComboboxSearch() {
    const res = await CommonService.getListComboboxSearch();
    if(res?.isError) return
    setListFunction(res?.object?.map(x => ({
      value:  x?.value,
      label: x?.functionName,
    })));
  }

  return (
    <SearchStyled>
      <Row gutter={[16, 16]}>
        <Col flex="auto">
          <FlInput
            onSearch={e => {
              setDataBody((prevState) => ({
                ...prevState,
                textSearch: e || '',
                currentPage: 1
              }));
            }}
            search
            allowClear
            label="Nhập mã người dùng, tên tài khoản, email, số điện thoại"
          />
        </Col>
        <Col lg={2} xs={24}>
          <FlSelect
            label="Giới tính"
            onChange={gender => {
              setDataBody((prevState) => ({
                ...prevState,
                gender: gender || 0,
                currentPage: 1
              }));
            }}
            value={dataBody?.gender || 0}
          >
            {getListComboByKey(SYSTEM_KEY?.SEX_TYPE_SELECT, listSystemKey)?.map(
              i => (
                <Option key={+i.codeValue} value={+i.codeValue}>
                  {i?.description}
                </Option>
              ),
            )}
          </FlSelect>
        </Col>
        <Col lg={5} xs={24}>
          <FlSelect
            label="Mối quan hệ"
            onChange={relationshipStatus => {
              setDataBody((prevState) => ({
                ...prevState,
                relationshipStatus: relationshipStatus || 0,
                currentPage: 1
              }));
            }}
            value={dataBody?.relationshipStatus || 0}
          >
            {getListComboByKey(SYSTEM_KEY?.RELATIONSHIP_STATUS, listSystemKey)?.map(
              i => (
                <Option key={+i.codeValue} value={+i.codeValue}>
                  {i?.description}
                </Option>
              ),
            )}
          </FlSelect>
        </Col>
        <Col lg={5} xs={24}>
          <FlSelect
            label="Khoảng tiền"
            onChange={filterAccBalance => {
              setDataBody((prevState) => ({
                ...prevState,
                filterAccBalance: filterAccBalance || 1,
                currentPage: 1
              }));
            }}
            value={dataBody?.filterAccBalance || 1}
          >
            {getListComboByKey(SYSTEM_KEY?.FILTER_ACC_BALANCE_USER_WALLET, listSystemKey)?.map(
              i => (
                <Option key={+i.codeValue} value={+i.codeValue}>
                  {i?.description}
                </Option>
              ),
            )}
          </FlSelect>
        </Col>
        <Col lg={4} xs={24}>
          <FlSelect
            label="Sắp xếp"
            onChange={sortBy => {
              setDataBody((prevState) => ({
                ...prevState,
                sortBy: sortBy || 0,
                currentPage: 1
              }));
            }}
            value={dataBody?.sortBy || 0}
          >
            {getListComboByKey(SYSTEM_KEY?.SORT_USER_WALLET, listSystemKey)?.map(
              i => (
                <Option key={+i.codeValue} value={+i.codeValue}>
                  {i?.description}
                </Option>
              ),
            )}
          </FlSelect>
        </Col>
        
      </Row>
    </SearchStyled>
  )
}

export default Search
