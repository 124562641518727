const ROUTER = {
  LOGIN_GOOGLE: "/dang-nhap-tu-mang-xa-hoi",
  HOME: "/",
  HOME2: "/1",
  SVG_VIEWER: "/svg-viewer",

  //ANONYMOUS
  TIN_TUC: "tin-tuc/:id",
  NOI_DUNG: "noi-dung/:id",
  LIEN_HE: "lien-he",

  // ADMIN
  TONG_QUAN: "/tong-quan",
  QUAN_LY_LIEN_HE: "/quan-ly-lien-he",
  XAC_NHAN_DON_HANG: "/xac-nhan-don-hang",
  ORDER_DETAIL_ADMIN: "/admin/order-detail",
  DANH_SACH_DON_HANG: "/danh-sach-don-hang",
  DANH_SACH_DON_HANG_CHO_XAC_NHAN: "/danh-sach-don-hang-cho-xac-nhan",
  DON_HANG: "/don-hang",
  TRA_CUU_HOP_DONG: "/tra-cuu-hop-dong",
  TEMPLATE_HOP_DONG: "/template-hop-dong",
  SAN_PHAM: "/san-pham",
  DICH_VU: "/dich-vu",
  GIAM_GIA: "/giam-gia",
  MA_GIA: "/ma-gia",
  BAI_VIET: "/bai-viet",
  DANG_BAI: "/dang-bai",
  LICH_SU_HOAT_DONG: "/lich-su-hoat-dong",
  DASHBOARD: "/dashboard",
  CHI_TIET: "/chi-tiet",
  DOANH_NGHIEP: "/doanh-nghiep",
  DANH_SACH_DOANH_NGHIEP: "/danh-sach-doanh-nghiep",
  CA_NHAN: "/ca-nhan",
  PHONG_BAN: "/phong-ban",
  DANH_SACH_THE: "/danh-sach-the",
  NHAN_VIEN: "/nhan-vien",
  NHAN_VIEN_ORACLE: "/nhan-vien-oracle",
  PHAN_LOAI: "/phan-loai",
  TON_KHO: "/quan-ly-ton-kho",
  PHAN_QUYEN: "phan-quyen",
  THEM_TEMPLATE_HOP_DONG: "them-template-hop-dong",
  QUAN_LY_BANNER_FOOTER: "banner-footer",
  LICH_SU_NAP_TIEN: "lich-su-nap-tien",
  QUAN_LY_VI: "quan-ly-vi",
  TAO_CHU_KY: "tao-chu-ky",
  DANH_SACH_CHU_KY: "danh-sach-chu-ky",

  // User
  USER_VIEW: "/trang-nguoi-dung",
  USER_INFOR: "/thong-tin-tai-khoan",
  NAP_TIEN: "/nap-tien",
  HOME_PAGE: "/home-page",
  USER_ORDERS: "/orders",
  PRODUCT_LIST: "/danh-sach-san-pham",
  PRODUCT_DETAIL: "/chi-tiet-san-pham",
  PRODUCT_SHOP: "/chi-tiet-cua-hang",
  CART_DETAIL: "/cart-detail",
  ORDER_DETAIL: "/order-detail",
  CHAT_CENTER: "/chatcenter",
  ORDER_CONTACT: "/order-contact",
}
export default ROUTER
