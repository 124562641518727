import {useNavigate, useParams} from 'react-router-dom';
import React, {useEffect, useState} from "react";
import "./orderDetail.css"
import {StatusList} from "../StatusBox/statusList";
import {OrderDetailStatusBox} from "../StatusBox/orderDetailStatus";
import {ORDER_STATUS} from "../../../../constants/constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faEye, faEyeSlash, faFilePdf} from "@fortawesome/free-solid-svg-icons";
import {Button, Col, Tag} from "antd";
import {DownloadOutlined, MenuFoldOutlined} from "@ant-design/icons";
import {StatusListNotSign} from "../StatusBox/statusListNotSign";
import {ProductResultViewer} from "../../../Product/modal/ProductResultViewer";
import {StatusListAutomatic} from "../StatusBox/statusListAutomatic";
import SpinCustom from "../../../../components/Spin";
import {StatusListAutomationSign} from "../StatusBox/statusListAutomationSign";
import ConTractViewPDF from "../../../Contract/ContractPreview/ConTractViewPDF";
import http from "../../../../services";
import SvgIcon from 'src/components/SvgIcon';
import {renderStatusTitle} from "../../../../lib/utils";
import UseWindowSize from "../../../../lib/useWindowSize";

export const OrderDetail = () => {
    const {id} = useParams();
    const [order, setOrder] = useState([]);
    const [listStatus, setListStatus] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isShowContract, setIsShowContract] = useState(false);
    const [isShowProduct, setIsShowProduct] = useState(false);
    const url = window.location.href;
    useEffect(() => {
        setLoading(true);
        getOrderDetail().then(resp => {
            if(!resp.isError){
                setOrder(resp?.object)
                document.title = `Chi tiết đơn hàng: ${resp?.object?.productName}` || 'TẠP HÓA SỐ'; // Thay đổi title thành 'New Title'
            }else {
                navigate('/orders')
            }
        });
        getOrderStatus().then(resp => {
            const inputArray = resp?.object;
            // Chuyển đổi mảng đối tượng thành mảng mới chỉ chứa orderId và createDate
            const newArray = inputArray.map(({statusID, createDate}) => ({statusID, createDate}));
            setListStatus(newArray);
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    /**
     * @description set title tab web
     * @author hddung
     */
    useEffect(() => {
        if(url) {
            return () => {
            document.title = 'TẠP HÓA SỐ'; // Đặt lại title khi component bị unmount
            };
        }
    }, [url]);

    const navigate = useNavigate();
    const getOrderDetail = async () => {
        return await http.post(`Order/GetOrderDetail?orderId=${id}`)
    }
    const getOrderStatus = async () => {
        return await http.post(`Order/GetOrderStatus?orderId=${id}`)
    }
    const formatCurrency = (value) => {
        return new Intl.NumberFormat('vi-VN', {
            style: 'currency',
            currency: 'VND',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value).replace('VND', 'đ');
    };
    const renderNewPrice = (price, discount) => {
        return price * (100 - discount) / 100;
    }

    const downloadFile = () => {
        // Tạo một đối tượng Blob chứa nội dung file HTML
        const htmlContent = order?.htmlContent;
        const blob = new Blob([htmlContent], {type: 'text/html'});

        // Tạo một URL cho Blob
        const url = window.URL.createObjectURL(blob);

        // Tạo một thẻ <a> để tạo và kích hoạt tải xuống
        const a = document.createElement('a');
        a.href = url;
        a.download = order?.contractFileName;

        // Thêm thẻ <a> vào DOM và kích hoạt tải xuống
        document.body.appendChild(a);
        a.click();

        // Xóa thẻ <a> sau khi tải xuống hoàn thành
        window.URL.revokeObjectURL(url);
    }
    const handleViewContract = () => {
        setIsShowContract(true);
    }
    const handleShowProduct = () => {
        setIsShowProduct(true);
    }
    const handleSetLoading=(value)=>{
        console.log('load',value);
        setLoading(value);
    }
    return (
        <>
            <SpinCustom spinning={loading}>
            <div className={"container main-container mcts"}>
                <div className={"back-container"}>
                    <div className={"back-item col-3"}>
                        <div style={{marginBottom:8,marginRight:4}} onClick={() => {
                            navigate("/orders")
                            // window.location.href="/orders";
                        }}>
                            <FontAwesomeIcon icon={faChevronLeft} color={'#212529'} style={{marginBottom:1}} />
                        </div>

                        <h6 onClick={() => {
                            navigate("/orders")
                            // window.location.href="/orders";
                        }}> Trở về</h6>
                    </div>
                    <div className={"id-title__container col-9"}>
                        {
                            !UseWindowSize.isMobile() ? (  <p>
                                ID đơn hàng {order?.orderId} |
                            </p>) : ""
                        }
                        <p >
                            {renderStatusTitle(order?.status)}
                        </p>
                    </div>
                </div>
                <div className={"status-tracking"}>
                    <div className={"status-container"}>
                        {
                            order?.isSignContract && !order?.isBuyAutomatic &&(

                                <StatusList status={order?.status} listStatus={listStatus}/>
                            )
                        }
                        {
                            !order?.isSignContract && !order?.isBuyAutomatic&&(

                                <StatusListNotSign status={order?.status} listStatus={listStatus}/>
                            )
                        }
                        {
                            order?.isBuyAutomatic&&!order?.isSignContract&&
                            (
                                <StatusListAutomatic status={order?.status} listStatus={listStatus}/>
                            )
                        }
                        {
                            order?.isBuyAutomatic&&order?.isSignContract&&
                            (
                                <StatusListAutomationSign status={order?.status} listStatus={listStatus}/>
                            )
                        }
                    </div>
                    {
                        (order?.status !== ORDER_STATUS.DA_HUY&&order?.status!==ORDER_STATUS.DA_DANH_GIA) ?
                            (<div className={"status-container button--container"}>
                                <OrderDetailStatusBox title={order?.status} contract={order?.htmlContent}
                                                      order_id={order?.orderId} productId={order?.productId} slug={order?.slug}
                                                      setLoading={handleSetLoading}
                                />
                            </div>) : ""
                    }
                        {
                            (order?.status === ORDER_STATUS.DA_HUY) ? (
                                <>
                    <div className={"document-container"}>
                                    <div className={"header__it"}>
                                        <p>Lý do hủy đơn</p>
                                    </div>
                                    <div className={"body__it"}>
                                        <p>{order?.note}</p>
                                    </div>
                    </div>
                                </>
                            ) : ""
                        }
                        {
                            ((order?.status === ORDER_STATUS.HOAN_THANH || order?.status === ORDER_STATUS.DA_DANH_GIA) && order?.isSignContract) ? (
                                <>
                                <div className={"document-container"}>
                                    <div style={{display: "flex", flexDirection: "column"}}>
                                        <div>
                                            <div className={"header__it"}>
                                                <p>Hợp đồng đã ký</p>
                                            </div>
                                            <div className={"body__it"}>
                                                <div style={{cursor: 'pointer', margin: 6,display:"flex"}} className={"col-4"}>
                                                    <FontAwesomeIcon className={"icon-item item-files"}
                                                                     onClick={() => handleViewContract()}
                                                                     style={{
                                                                         height: '28px',
                                                                         width: '28px',
                                                                     }}
                                                                     icon={faFilePdf}/>
                                                    <span onClick={() => handleViewContract()}
                                                          className={"max-line1"}>{order?.contractFileName}</span>
                                                </div>
                                                {/*<div style={{display: "flex"}}>*/}

                                                    {/*<div style={{marginRight: 4, alignSelf: 'end'}}>*/}
                                                    {/*    <Button style={{width: 142}}*/}

                                                    {/*            btnType="primary"*/}
                                                    {/*            className="btn-hover-shadow"*/}
                                                    {/*            onClick={handleViewContract}>Xem hợp đồng*/}
                                                    {/*    </Button>*/}
                                                    {/*</div>*/}
                                                    {/*<div style={{marginRight: 4, alignSelf: 'end'}}>*/}
                                                    {/*    <Button style={{width: 142}}*/}
                                                    {/*            icon={<DownloadOutlined/>}*/}
                                                    {/*            onClick={() => downloadFile()}*/}
                                                    {/*            btnType="primary"*/}
                                                    {/*            className="btn-hover-shadow">Tải hợp đồng*/}
                                                    {/*    </Button>*/}
                                                    {/*</div>*/}
                                                {/*</div>*/}
                                            </div>
                                        </div>

                                        {/*/!*xem sp *!/*/}
                                        {/*<div>*/}
                                        {/*    <FontAwesomeIcon icon={faEye} style={{cursor: "pointer"}}*/}
                                        {/*                     onClick={() => handleShowProduct()}/>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                                </>
                            ) : ""
                        }


                </div>
                <div className={"product-detail"}>
                    <div>
                        <h6>Sản phẩm</h6>
                    </div>
                    <div className={"order-container__main"}>

                        <div className={"im__p__container col-12 col-md-7 "}>
                            <div className={"image-order__container"}  style={{cursor:"pointer"}}
                                 onClick={()=>navigate(`/${order.slug}-${order.productId}`)}>
                                <img src={order?.image}/>
                            </div>
                            <div className={"name_cts col-md-8"} >
                                <div className={"d-flex-column"} style={{alignContent:"center"}}>
                                <p className={"name-attr max-line1"} style={{cursor:"pointer"}}
                                   onClick={()=>navigate(`/${order.slug}-${order.productId}`)}>
                                    {order?.productName}
                                </p>
                                <span>
                                            Phân loại:<Tag>{order?.productItemName}</Tag>
                               </span>
                                <p className={"max-line1"}>
                                    x{order?.quantity}
                                </p>
                                </div>
                               {/* <div style={{marginTop: 2}} className='d-flex'>*/}
                               {/*     {order?.isSignContract && <Tag className='d-flex align-items-center'><SvgIcon className='ic-tag-order' name='sign-contract-ic'/>Ký hợp đồng</Tag>} */}
                               {/*     {!order?.isCheckStock && <Tag className='d-flex align-items-center'><SvgIcon className='ic-tag-order' name='stock-ic'/>Mua quá tồn kho</Tag>} */}
                               {/*     {order?.isBuyAutomatic && <Tag className='d-flex align-items-center'><SvgIcon className='ic-tag-order' name='automatic-ic'/>Mua bán tự động</Tag>}*/}
                               {/*</div>*/}
                            </div>
                        </div>
                                        <div className={"attribute__container col-12 col-md-1"}>
                            {
                                ((order?.status === ORDER_STATUS.HOAN_THANH || order?.status === ORDER_STATUS.DA_DANH_GIA))
                                && (
                                    <>
                                            {isShowProduct ? (

                                                    <FontAwesomeIcon style={{marginLeft: 10, cursor: "pointer"}} size={16}
                                                                     icon={faEye}
                                                                     onClick={() => handleShowProduct()}/>
                                                ) :
                                                (
                                                    <FontAwesomeIcon style={{marginLeft: 10, cursor: "pointer"}} size={16}
                                                                     icon={faEyeSlash}
                                                                     onClick={() => handleShowProduct()}/>
                                                )

                                            }
                                    </>
                                )
                            }
                                        </div>
                        <div className={"attribute__container col-12 col-md-4 "}>
                            <div className={"col-12"}>
                                <div className={"quantity-price__container"}>
                                    {/*<p className={"name-attr max-line1 col-6"}>*/}
                                    {/*    {order?.productName}*/}
                                    {/*</p>*/}
                                    <p className={"col-4"}>
                                        Tạm tính
                                    </p>
                                    <div className={" price-dis__container "}>
                                        {
                                            (order?.discount && order?.discount > 0) ?
                                                (
                                                    <div className={"price"}>
                                                        <div
                                                            className={'price-total-container sale-price'}>{formatCurrency(renderNewPrice(order?.price, order?.discount))}
                                                        </div>
                                                        <del>{formatCurrency((order?.price*order?.quantity)?.toString() || 0)}</del>
                                                    </div>
                                                ) :
                                                (
                                                    <div
                                                        className={'price-total-container'}>{formatCurrency((order?.price*order?.quantity)?.toString() || 0)}</div>
                                                )
                                        }
                                    </div>
                                </div>
                                {/* <span>*/}
                                {/*             Phân loại:<Tag>{order?.productItemName}</Tag>*/}
                                {/*</span>*/}
                                <div className={"quantity-price__container"}>
                                    {/*<p className={"col-6 max-line1"}>*/}
                                    {/*    x{order?.quantity}*/}
                                    {/*</p>*/}
                                    <p className={"col-md-5"}>
                                        Tổng cộng
                                    </p>
                                    <div className={"price-dis__container "}>
                                        {
                                            (order?.discount && order?.discount > 0) ?
                                                (
                                                    <div className={"price-total-container main-p"}>
                                                        <div
                                                            className={'price-acitve'}>{formatCurrency(renderNewPrice(order?.price*order?.quantity, order?.discount))}
                                                        </div>
                                                    </div>
                                                ) :
                                                (
                                                    <div
                                                        className={'price-total-container main-p'}>{formatCurrency((order?.price*order?.quantity).toString() || 0)}
                                                    </div>
                                                )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                isShowContract && (
                    <ConTractViewPDF
                        onCancel={() => setIsShowContract(false)}
                        open={isShowContract}
                        contractPDF={{
                            titleContract: order?.contractName,
                            contractUrlPDF: order?.contractUrlPDF
                        }}
                    />
                )
            }
            {
                isShowProduct && (
                    <ProductResultViewer onCancel={() => setIsShowProduct(false)} open={isShowProduct}
                                         contract_html={order?.orderDetails}/>
                )
            }
            </SpinCustom>
        </>
    )
}
