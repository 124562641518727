import styled from "styled-components"

export const ManageWalletStyle = styled.div``

export const SearchStyled = styled.div`
  background: #fff;
  margin-bottom: 10px;
`

export const DetailWalletStyled = styled.div`
  
`