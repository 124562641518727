import { Col, Divider, Row } from "antd"
import { NewsItemStyled } from "../../pages/ANONYMOUS/News/styled"

import SvgIcon from "src/components/SvgIcon"
export const NewItemImage = ({
  item,
  onClick = () => {},
  showDivider = true,
}) => {
  return (
    <NewsItemStyled onClick={onClick}>
      <Row gutter={24} className="flex-wrap">
        <Col span={10} className="news-image">
          <img
            src={item?.image}
            width="100%"
            loading="lazy"
            className="image"
            style={{
              height: 230,
              objectFit: item?.image ? "cover" : "contain",
            }}
            alt="news"
          />
        </Col>
        <Col span={14}>
          <div className="main-header max-line2">{item?.title}</div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "6px 0px 20px",
            }}
          >
            <div className="time"> {item?.time}</div>
            {!(item?.TopicID === 13) && (
              <>
                <div className="d-flex">
                  <SvgIcon
                    name="mode-comment"
                    style={{ margin: "0 6px 0 10px" }}
                  />
                  <div className="number-comment">{item?.NumberComment}</div>
                </div>
                <div className="d-flex">
                  <SvgIcon name="view" style={{ margin: "0 6px 0 10px" }} />
                  <div className="number-comment">
                    {/* {!!item?.NumberOfViewers ? item?.NumberOfViewers : 0} */}
                    {item?.NumberOfViewers}
                  </div>
                </div>
              </>
            )}
          </div>
          {!!item?.content && <div className="content">{item?.content}</div>}
        </Col>
      </Row>
      {!!showDivider && <Divider />}
    </NewsItemStyled>
  )
}
