import styled from "styled-components"

export const NewsStyled = styled.div``

export const RelateNewsStyled = styled.div`
  .subHeader {
  }
  .relate-news-header {
    background: #0960a0;
    padding: 30px;
  }
  .relate-news-header-text {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
  }
  .relate-news-header-space {
    margin-top: 20px;
    border: 2px solid #7f96c1;
  }
  .relate-news-content {
    padding: 40px 30px;
    background: #f7f7f7;
  }
  .relate-news-text-care {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }
  .relate-news-box-care {
    margin-top: 30px;
  }
  .relate-news-box-care-title {
    font-weight: 600;
    color: #0960a0;
  }
  .relate-news-box-care-time {
    color: #838383;
    font-size: 12px;
    margin: 15px 0px;
  }
  .relate-news-tag {
    border: 1px solid #dddddd;
    border-radius: 4px;
    padding: 10px 8px;
    font-size: 10px;
    color: #000000;
  }
`
export const TabsNewsStyled = styled.div`
  .hover-red {
    :hover {
      color: #f0383e;
    }
  }
  .ant-tabs-content-holder {
    padding: 0px 0px;
  }
  .bread-crumb-tab-news {
    margin-top: 0px;
    margin-bottom: 15px;
    .ant-breadcrumb-link,
    .ant-breadcrumb-separator {
      color: #212529;
      font-weight: 400;
      opacity: 1;
      font-size: 14px;
    }
  }
  .see-more-2 {
    position: absolute;
    top: -50px;
    right: 0px;
    cursor: pointer;
  }

  .see-more-3 {
    position: absolute;
    top: 0px;
    right: 0px;
    cursor: pointer;
  }
  .see-more {
    position: absolute;
    top: 20px;
    right: 0px;
    cursor: pointer;
  }
  .ant-tabs-tab-active {
    background: #f8f8f8;
  }
  .ant-tabs-tab {
    padding: 15px 25px;
    margin: 0px;
  }
  .ant-tabs-tab-btn {
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
    text-align: center;
    text-shadow: unset !important;
    color: #0960a0;
    @media only screen and (min-width: 600px) {
      font-size: 22px;
    }
    @media only screen and (min-width: 550px) {
      font-size: 18px;
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #ee1d23;
  }
  .ant-tabs-ink-bar {
    height: 3px !important;
    background: linear-gradient(90deg, #154297 0%, #ed1e24 100%);
  }
`
export const NewsItemStyled = styled.div`
  cursor: pointer;
  .div-sca {
    overflow: hidden;
  }
  .sca {
    transition: 1s all ease-out;
    :hover {
      transform: scale(1.2);
    }
  }
  .hover-red {
    font-size: 16px !important;
    :hover {
      color: #e4353a;
    }
  }

  .flex-wrap {
    flex-wrap: nowrap;
  }

  .div-logo-product2 {
    max-width: 60vh !important;
    height: 30vh !important;
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border-radius: 100%; */
    overflow-y: hidden;
    position: relative;

    :hover {
      img {
        transform: scale(1.2);
      }
    }
    img {
      /* height: 90%; */
      width: 100%;
      object-fit: contain;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      transition: all 3s ease-in-out;

      /* animation: mymove 2s linear 0s alternate; */
    }
    @keyframes mymove {
      from {
        top: 0;
      }
      to {
        top: unset;
        bottom: 0;
      }
    }
  }
  .div-logo-product {
    max-width: 60vh !important;
    min-width: 60vh !important;
    height: 30vh !important;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border-radius: 100%; */
    overflow-y: hidden;
    position: relative;

    :hover {
      img {
        transform: translateY(calc((-100% + 30vh)));
      }
      .div-hover {
        display: flex;
      }
    }
    .div-hover {
      display: none;
      justify-content: center;
      align-items: center;
      transition: all 2s ease-in-out;
      background: linear-gradient(to top, #00577f8f, rgba(255, 255, 255, 0));
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      .ant-btn {
        margin-left: 8px;
        margin-right: 8px;
        font-weight: 600;
        min-width: 150px;
        padding: 10px 20px;
        border-radius: 20px !important;
      }
      .btn-see {
        :hover {
          background: #0960a0 !important;
          div div span {
            color: #fff !important;
          }
        }
      }
      .btn-detail {
        :hover {
          background: #e50500 !important;
          div div span {
            color: #fff !important;
          }
        }
      }
    }
    img {
      /* height: 90%; */
      width: 100%;
      object-fit: contain;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      transition: all 3s ease-in-out;

      /* animation: mymove 2s linear 0s alternate; */
    }
    @keyframes mymove {
      from {
        top: 0;
      }
      to {
        top: unset;
        bottom: 0;
      }
    }
  }
  .ispreview {
    cursor: pointer;
    color: #1890ff;
    font-style: italic;
    :hover {
      text-decoration: underline;
      font-weight: 600;
    }
  }
  .news-image {
    overflow: hidden;
  }
  .box-shadow-maintoring {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  .news-image:hover {
    .image {
      transform: scale(1.2);
    }
  }
  .image {
    transition: all 1s;
    transform: scale(1);
  }
  .main-header {
    font-size: 20px;
    font-weight: 600;
    overflow: hidden;
    :hover {
      color: #e4353a;
    }
  }
  .ellipsis-5 {
    -webkit-line-clamp: 5 !important;
  }
  .time {
    font-size: 12px;
    color: #838383;
  }
  .mv-17 {
    margin: 17px 0px !important;
  }
  .content {
    color: #838383;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .type-new {
    font-weight: 600;
    color: #f0383e;
  }
  .header-small {
    color: #0960a0;
    font-weight: 600;
    font-size: 16px;
    margin: 8px 0px;
  }

  @media (max-width: 768px) {
    .flex-wrap {
      flex-wrap: wrap;
    }
    .ant-col-10 {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .ant-col-14 {
      margin-top: 10px;
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
`
