// ADMIN
export const apiGetAllForAdmin = "Product/GetAllProductForAdmin"
export const apiDeleteProduct = "Product/DeleteProduct"
export const apiChangeStatusProduct = "Product/ChangeStatusProduct"
export const apiInsertProduct = "Product/InsertProduct"
export const apiUpdateProduct = "Product/UpdateProduct"
export const apiInsertProductItem = "ProductItem/InsertProductItem"
export const apiDeleteProductItem = "ProductItem/DeleteProductItem"

//ANONYMOUS
export const apiGetAll = "Guest/GetListProduct"
export const apiGetProductDetailsByID = "Guest/GetProductDetailsByID"
export const apiGetListRelatedProduct = "guest/GetListRelatedProduct"
export const apiGetProductReviewsByID = "guest/GetProductReviewsByID"
export const apiBuyProduct = "Product/BuyProduct"

//PromoteCode
export const apiCheckPromoteCode = "Product/CheckPromoteCode"

//Inventory
export const apiGetAllInventory = "Inventory/GetAllInventory"
export const apiGetListInventoryByProductItemID = "Inventory/GetListInventoryByProductItemID"
export const apiInsertInventory = "Inventory/InsertInventory"
export const apiUpdateInventory = "Inventory/UpdateInventory"
export const apiDeleteInventory = "Inventory/DeleteInventory"
export const apiGetAllProductAndProductItem = "Inventory/GetAllProductAndProductItem"
export const apiImportInventoryByProductItem = "Product/ImportInventoryByProductItem"