import {Col, DatePicker, Row, Select} from "antd"
import FlInput from "src/components/FloatingLabel/Input"
import FlSelect from "src/components/FloatingLabel/Select"
import {ORDER_STATUS, PROMOTE_CODE_TYPE} from "src/constants/constants"
import {SearchStyled} from "../../Production/styled";
import dayjs from "dayjs";

const { Option } = Select
const Search = ({onChangeKeyword,pagination,handleFilter}) => {

    const items: TabsProps['categoriesItem'] = [
        {
            key: -1,
            label: 'Tất cả',
        },
        {
            key: 1,
            label: 'Kích hoạt',
        },
        {
            key:2,
            label: 'Không kích hoạt',
        }
    ];

    return (
        <SearchStyled>
            <Row gutter={[16, 16]}>
                <Col flex="auto">
                    <FlInput
                        search
                        allowClear
                        label="Nhập mã giảm giá "
                        onSearch={e => onChangeKeyword(e)}
                    />
                </Col>
                <Col lg={5} xs={24}>
                    <FlSelect
                        label="Trạng thái"
                        onChange={(e) => {
                            handleFilter({...pagination,status:e})
                        }}
                        value={pagination.status}
                    >
                        {
                            items?.map(i=>(
                                        <Option key={i.key} value={i.key}>
                                    {i?.label}
                                </Option>
                            ))
                        }
                    </FlSelect>
                </Col>



            </Row>
        </SearchStyled>
    )
}

export default Search
