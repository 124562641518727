import styled from "styled-components"

export const Preview = styled.div`
  .preview{
  display: flex;
  width: 78%;
  margin: auto;
  padding-top: 30px;
  }
  .services{
    width: 78%;
    margin: auto;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
  }
  .title-product{
    display: flex;
    justify-content: center;
    margin-top: 70px;
    h3{
      font-weight: 500;
      line-height: 1.2;
      font-family: "DM Sans", sans-serif;
    } 
  }
  .sub-title-product{
    display: flex;
    margin-top: 30px;
    justify-content: center;
    h5{
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: #8C9EC5;
      font-family: "DM Sans", sans-serif;
    } 
  }
  .product{
    width: 78%;
    margin: auto;
    display: flex;
  }
`
