import {Col, DatePicker, Row, Select} from "antd"
import {useSelector} from "react-redux"
import FlInput from "src/components/FloatingLabel/Input"
import FlSelect from "src/components/FloatingLabel/Select"
import {ORDER_STATUS} from "src/constants/constants"
import {SearchStyled} from "../../Production/styled";
import dayjs from "dayjs";
import http from"src/services"
import {useEffect, useState} from "react";

export const Search = ({onChangeKeyword,pagination,handleFilter}) => {

    const [typeOption,setTypeOption]=useState([]);
    const loadOption=()=>{
        http.get("Common/GetSystemKey?key=SEARCH_CONTRACT_STATUS").then(resp => {
            if(!resp.isError){
                setTypeOption(resp?.object)
            }
        })
    }
    useEffect(()=>{
        loadOption();
    },[])
    const { Option } = Select
    const items: TabsProps['items'] = [
        {
            key: 0,
            label: 'Tất cả',
        },
        {
            key: 1,
            label: 'Chờ ký'
        },
        {
            key: 2,
            label: 'Đã kí',
        }
    ];
    const sortItems: TabsProps['sortitems'] = [

        {
            key: 0,
            label: 'Sắp xếp',
        },
        {
            key: 1,
            label: 'Giá tăng dần',
        },
        {
            key: -1,
            label: 'Giá giảm dần',
        }
    ];

    return (
        <SearchStyled>
            <Row gutter={[16, 16]}>
                <Col flex="auto">
                    <FlInput
                        search
                        allowClear
                        label="Tìm kiếm theo Mã hợp đồng, tên hợp đồng, tên file,tên khách hàng ... "
                        onSearch={e => onChangeKeyword(e)}
                    />
                </Col>
                <Col lg={5} xs={24}>
                    <FlSelect
                        label="Trạng thái"
                        onChange={(e) => {
                            handleFilter({...pagination,type:e})
                        }}
                        value={pagination.type}
                    >
                        {
                            typeOption?.map(i=>(
                                <Option key={i.id} value={i.codeValue}>
                                    {i?.description}
                                </Option>
                            ))
                        }
                    </FlSelect>
                </Col>

                {/*<Col lg={3} xs={16}>*/}
                {/*    <FlSelect*/}
                {/*        label="Sắp xếp"*/}
                {/*        onChange={(e) => {*/}
                {/*            handleFilter({...pagination,sortByPrice:e})*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        {*/}
                {/*            sortItems?.map(i=>(*/}
                {/*                <Option key={i.key} value={i.key}>*/}
                {/*                    {i?.label}*/}
                {/*                </Option>*/}
                {/*            ))*/}
                {/*        }*/}
                {/*    </FlSelect>*/}
                {/*</Col>*/}

                <Col lg={3} xs={24}>

                    <DatePicker
                        onChange={(e) => {
                            const formattedDate = dayjs(e).format('YYYY-MM-DD');
                            handleFilter({...pagination, fromDate: formattedDate == 'Invalid Date' ? null : formattedDate});
                        }}
                        placeholder="Từ ngày"
                        format="DD/MM/YYYY"
                        allowClear
                    />
                </Col>

                <Col lg={3} xs={24}>
                    <DatePicker
                        onChange={(e) => {
                            const formattedDate = dayjs(e).format('YYYY-MM-DD');
                            handleFilter({...pagination, toDate: formattedDate == 'Invalid Date' ? null : formattedDate});
                        }}
                        placeholder="Đến ngày"
                        format="DD/MM/YYYY"
                        allowClear
                    />
                </Col>

            </Row>
        </SearchStyled>
    )
}
