import { Col, Row, Spin, Typography } from "antd"
import CustomModal from "../Modal/CustomModal"
import Button from "../MyButton/Button"
import SvgIcon from "../SvgIcon"
import "./style.css"
import { useState } from "react"
import SpinCustom from "../Spin"
import VietcomBankPopup from "./VietcomBankPopup"
import PaymentService from "src/services/PaymentService"

function PaymentMethods({onOk, money, ...props}) {
    const [loading, setLoading] = useState(false);
    const [openVietcomBank, setOpenVietcomBank] = useState(false)

    //#region function

    /**
     * @description Thực hiện hiển thị tab của vnpay để thanh toán
     * @author hddung
     */
    async function openVNPay() {
        const res = await PaymentService.createPaymentUrl(money);
        if(res?.isError) return
        
        const width = 1000; // Chiều rộng của cửa sổ
        const height = 600; // Chiều cao của cửa sổ
        const left = (window.innerWidth - width) / 2; // Vị trí căn giữa theo chiều ngang
        const top = (window.innerHeight - height) / 2; // Vị trí căn giữa theo chiều dọc
        
        const popupWindow = window.open(res?.object, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
    }

    const renderFooter = () => (
        <div className="d-flex justify-content-flex-end">
          <Button
            className="btn-hover-shadow"
            onClick={() => props?.onCancel()}
          >
            Đóng
          </Button>
        </div>
    )

    //#endregion

    return (
    <CustomModal
        title='Phương thức nạp'
        footer={renderFooter()}
        {...props}
        width={350}
        style={{padding: '80px 20px 0px 10px'}}
    >
        <Spin spinning={loading}>
            <div className="d-flex justify-content-around">
                <div>
                    <Typography.Text strong className="d-flex justify-content-center mb-1">Ngân hàng</Typography.Text>
                    <div className="op-payment-img" onClick={() => setOpenVietcomBank(true)}>
                        <img src="http://res.cloudinary.com/dmcqrnfke/image/upload/v1728582082/yefhn7nwzjrczbmpaicb.png" width={80} height={80} />
                    </div>
                </div>
                {/* <div>
                    <Typography.Text strong className="d-flex justify-content-center mb-1">VNPay</Typography.Text>
                    <div className="op-payment-img" onClick={openVNPay}>
                        <img src="http://res.cloudinary.com/dmcqrnfke/image/upload/v1710828783/xqucdgqzmx6prfkqij4n.png" width={80} height={80} />
                    </div>
                </div> */}
            </div>
        </Spin>

        { !!openVietcomBank &&
            <VietcomBankPopup 
            onCancel={() => {
                setOpenVietcomBank(false);
            }}
            onOk={() => {}}
            open={openVietcomBank}
            money={money}
            />
        }
    </CustomModal>
    )
}

export default PaymentMethods