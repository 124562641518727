import CustomModal from "../../../../components/Modal/CustomModal";
import Button from "../../../../components/MyButton/Button";
import {Avatar, Col, Form, Input, List, Row} from "antd";
import FlInput from "../../../../components/FloatingLabel/Input";

export const CustomerCareDetailModal=({open, cancel,item})=>{
    const renderFooter = () => (
        <div className="d-flex justify-content-flex-end">
            <Button
                className="btn-hover-shadow"
                onClick={() => cancel()}
            >
                Đóng
            </Button>

        </div>
    )
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            time = d.getHours() + ':' + (d.getMinutes() < 9 ? '0' : '') + d.getMinutes();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('-') + ` ${time}`;
    };
    return(
        <>
            <CustomModal
                title={"Chi tiết hoạt động"}
                width="800px"
                footer={renderFooter()}
                open={open}
                onCancel={()=>cancel()}
            >
                <Row gutter={[16, 16]} className="justify-content-center" style={{marginBottom:20}}>
                    <Col span={24}>
                        <div className={"d-flex flex-column"}>
                            <div style={{display: 'flex',justifyContent:"space-between"}}>
                                <p style={{fontSize:24,marginBottom:0,fontWeight:600}}>{item?.title}</p>

                            </div>
                            <div style={{display: 'flex'}}>
                                <Avatar src={item?.createUserAvatar} size={50} style={{marginRight:10}}/>
                                <div>
                                <h5 style={{marginRight: '20px',alignSelf:"end",marginBottom:0}}>{item?.createUserName}</h5>
                                <p style={{fontSize:14, color: 'rgba(0, 0, 0, 0.45)',alignSelf:"center"}}>{formatDate(item?.createDate)}</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} className="justify-content-center">
                    <Col span={24}>
                        <div className={"d-flex flex-column"}>
                           <div>
                               <p style={{fontSize:20,marginBottom:0,fontWeight:600}}>Nội dung</p>
                           </div>

                        </div>
                    </Col>
                    <Col span={24}>
                        <div style={{padding:"4px 16px"}}>
                            <div dangerouslySetInnerHTML={{ __html: item?.content }} />
                        </div>
                    </Col>
                </Row>
            </CustomModal>
        </>
    )
}
