import { Dropdown, Select } from "antd"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import FlInput from "src/components/FloatingLabel/Input"
import SearchRecommend from "src/pages/Search/component/SearchRecommend"
import UseWindowSize from "../../../lib/useWindowSize";
import ROUTER from "src/router"
import { TEXT_HEADER_SEARCH } from "src/constants/constants"
import '../../../assets/css/Input/InputSearch.css'

const SearchMenu = ({}) => {
  const navigate = useNavigate();
  const [textSearch, settextSearch] = useState("");
  const [datetimeCurrent, setDatetimeCurrent] = useState(false);
  const [isSearchPost, setIsSearchPost] = useState(1);
  const queryParams = new URLSearchParams(window.location.search);
  const url = window.location.href;
  const comboData = [
    {
      value: 1,
      label: 'Sản phẩm'
    },
    {
      value: 2,
      label: 'Tin tức'
    },
  ];
  
  useEffect(() => {
    if(url.toLowerCase().includes(TEXT_HEADER_SEARCH.POST)) {
      setIsSearchPost(2);
    } else {
      setIsSearchPost(1);
    }


    //Tránh bị giữ search value khi sang màn khác 2 màn danh sách sản phẩm, tin tức
    if(url && (url.toLowerCase().includes(TEXT_HEADER_SEARCH.PRODUCT) || url.toLowerCase().includes(TEXT_HEADER_SEARCH.POST))) {
      settextSearch(queryParams.get('search'));
      return
    }

    settextSearch('');
  }, [url])

  /**
   * @description Lọc theo danh sách sản phẩm
   * @author hddung
   */
  function searchProduct() {
    navigate(`/danh-sach-san-pham${textSearch ? `?search=${textSearch}` : '' }`, {
      state: { textSearch: textSearch },
    });
  }

  /**
   * @description Lọc theo tin tức
   * @author hddung
   */
  function searchPost() {
    const arrURL = url.split('/');
    if(arrURL?.length > 0) {
      // Lấy ra id bài post
      const id = arrURL[4]?.split('?')[0] || 'b9c02d5c-2197-46c2-9ed2-ab3e2cd2946a';
      navigate(`/tin-tuc/${id}${textSearch ? `?search=${textSearch}` : '' }`, {
        state: { textSearch: textSearch },
      });
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setDatetimeCurrent(prev => !prev)
    }, 2000);
    

    return () => clearInterval(interval)
  }, [])
  return (
    <div style={{display: 'flex', alignItems: 'center'}}>
      {/* <Dropdown
        overlay={<SearchRecommend textSearch={textSearch} />}
        trigger={["click"]}
      ></Dropdown> */}
        <Select
          options={comboData}
          style={{
            width: 110,
          }}
          className="op-search-header"
          defaultValue={isSearchPost}
          value={isSearchPost}
          onChange={value => {
            if(value == 1) {
              setIsSearchPost(1);
            } else {
              setIsSearchPost(2);
            }
          }}
        />
          {/* <Option key={item?.value} value={item?.value}>

          </Option>
        </Select> */}
        <FlInput
          style={{ width:  UseWindowSize.isMobile() ? "100%" : "340px" ,margin:"5px 0px"}}
          className="ip-search-header"
          search="true"
          // isanimate={true}
          value={textSearch}
          onChange={value => {
            settextSearch(value.target.value)
          }}
          onSearch={() => {
            if(isSearchPost == 2) {
              searchPost();
            } else {
              searchProduct();
            }
          }}
          label={UseWindowSize.isMobile() ? "" : "Tìm kiếm"

            // <Texty type="swing-y" mode="smooth">
            //   {!datetimeCurrent && "Tìm kiếm tin tức"}
            // </Texty>
          }
        />
        {/* </Dropdown> */}
    </div>
  )
}

export default SearchMenu
