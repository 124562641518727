import {Button, Col, Form, Row, Tag} from "antd";
import React, {useEffect, useState} from "react";
import "./cart-item.css";
import {DeleteOutlined} from "@ant-design/icons";
import FlInput from "../../../components/FloatingLabel/Input";
import {faFontAwesome} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {VoucherForm, voucherForm} from "./voucher-form";
import {color} from "chart.js/helpers";
import {useNavigate} from "react-router-dom";
import ROUTER from "../../../router";
//  interface Item{
//     productId:number,
//     productName:string,
//     image: string,
//     attribute: string,
//     oldPrice: number,
//     newPrice: number,
//     quantity: number
// }
//  interface ICartItem {
//     shopName: string,
//     items:Array<Item>
// }

interface Props {
    cartItem: any,
    handleSelectedProduct: any,
    productSelected: any,
    handleUpdateCart: void,
    handleChangePromoteItem: void
}

export const CartItem = (props: Props) => {
useEffect(() => {
    console.log('data',[props.cartItem]);
},[])
    const formatCurrency = (value) => {
        return new Intl.NumberFormat('vi-VN', {
            style: 'currency',
            currency: 'VND',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value).replace('₫', '');
    };
    const renderInputChange = (e) => {
        return e > 0 ? e : 1;
    }
    const [isShowVoucher, setIsShowVoucher] = useState(false)
    const [voucherFormStates, setVoucherFormStates] = useState({});
    const navigate=useNavigate();
    const toggleVoucherForm = (productItemID) => {

        setVoucherFormStates(prevStates => ({
            ...prevStates,
            [productItemID]: !prevStates[productItemID]
        }));
    };
    const handleNavigate=(id,slug)=>{
        navigate(`/${slug}-${id}`)
    }
    useEffect(() => {
        console.log('product',props.cartItem);
    },[props.cartItem])
    return (
        <>
            {
                props.cartItem?.items?.map((product, index) => (
                    <React.Fragment key={product.productItemID}>
                        {/*<tr className={"blank"}>*/}
                        {/*    <td></td>*/}
                        {/*    <td></td>*/}
                        {/*    <td></td>*/}
                        {/*    <td></td>*/}
                        {/*    <td></td>*/}
                        {/*    <td></td>*/}
                        {/*</tr>*/}
                        <tr className={"item-detail--container"}>
                            <td st>
                                <input
                                    checked={props.productSelected.includes(product.productId)}
                                    type="checkbox"
                                    className={"checkbox-prod"}
                                    onChange={() => props.handleSelectedProduct(product.productId)
                                    }
                                />
                            </td>
                            <td>
                                <div className="item-container">
                                    <div className="image--container" onClick={()=>handleNavigate(product.pId,product.slug)}>
                                        <img src={product?.image} alt={product?.productName}/>
                                    </div>
                                    <div className="item-content-container">
                                        <p onClick={()=>handleNavigate(product.pId,product.slug)} className={"txt-product-name stcs"}>{product?.productName}</p>
                                        <Tag
                                            // key={product?.productId}
                                            closable={false}
                                            style={{userSelect: 'none',width:"fit-content"}}
                                        >
                                            {product?.attribute}
                                        </Tag>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className={"main-prdt"}>

                                    {
                                        product.oldPrice != -1 &&
                                        (
                                            (product.newPrice != product.oldPrice) ?
                                                (
                                                    <div
                                                        className={'price main-prdt'}>
                                                        <p className={"col-12"} style={{marginRight:"4px "}}>{formatCurrency(product.newPrice.toString())}₫</p>
                                                        <del>{formatCurrency(product.oldPrice.toString())}₫</del>
                                                    </div>
                                                ) :
                                                (
                                                    <div
                                                        className={'price'}>{formatCurrency(product.oldPrice.toString())} ₫
                                                    </div>
                                                )
                                        )
                                    }
                                    {
                                        (product.oldPrice == -1&&product.newPrice==-1) &&

                                        (
                                            <div
                                                className={'price'}>Liên hệ
                                            </div>
                                        )
                                    }
                                    {
                                        (product.oldPrice == -1&&product.newPrice!=-1) &&

                                        (
                                            <div
                                                className={'price'}>{formatCurrency(product.newPrice.toString())}₫
                                            </div>
                                        )
                                    }

                                </div>
                            </td>
                            <td>
                                <div className={'calculator-container'}>
                                    <button className={'btn-s'} type='button' id={'decrease'}
                                            onClick={() => props?.handleUpdateCart(product?.productItemID, parseInt(product?.quantity) - 1)}>-
                                    </button>
                                    <input className={'btn-s input-s'} type='text' id={'raw'} value={product.quantity}
                                           onInput={(event) => props.handleUpdateCart(product.productItemID, renderInputChange(parseInt(event.target.value)))}/>
                                    <button className={'btn-s'} type='button' id={'increase'}
                                            onClick={() => props?.handleUpdateCart(product?.productItemID, parseInt(product?.quantity) + 1)}>+
                                    </button>
                                </div>
                            </td>
                            <td>
                                <div className={'price price-active'}>
                                    {
                                        (product.oldPrice == -1&&product.newPrice==-1) ?
                                            (
                                                <div
                                                    className={'price'}>Liên hệ
                                                </div>
                                            ) : (
                                                <div>
                                                    {formatCurrency(product.newPrice * product.quantity)}₫
                                                </div>
                                            )

                                    }
                                    {/*{formatCurrency(product.newPrice * product.quantity)}₫*/}
                                </div>
                            </td>
                            <td>
                                <DeleteOutlined style={{cursor: 'pointer'}}
                                                onClick={() => props?.handleUpdateCart(product?.productItemID, 0)}/>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>
                                <div className={"vc_ctn"}>

                                <div className={"icon-voucher__container col-md-5"}>
                                    <FontAwesomeIcon icon={faFontAwesome} className={"p-icon"} beat
                                                     style={{color: "#3965b1",}}/>
                                    {
                                        !product.promoteCode&&product.oldPrice != -1 && (
                                            <p onClick={() => toggleVoucherForm(product.productItemID)}>Nhập mã giảm giá</p>
                                        )
                                    }
                                    {
                                        !product.promoteCode&&product.oldPrice == -1 && (
                                            <p onClick={() => toggleVoucherForm(product.productItemID)}>Nhập mã giá</p>
                                        )
                                    }
                                    {
                                        product.promoteCode&&product.oldPrice != -1 && (
                                            <p  onClick={() => toggleVoucherForm(product.productItemID)}>Nhập mã giảm giá khác</p>
                                        )
                                    }
                                    {
                                        product.promoteCode&&product.oldPrice == -1 && (
                                            <p onClick={() => toggleVoucherForm(product.productItemID)}>Nhập mã giá khác</p>
                                        )
                                    }
                                </div>
                                <div className={"voucher-dt-container"}>
                                    {
                                        product.promoteCode&&product.oldPrice!= -1&&(
                                            <Tag color="red">-{formatCurrency((product.oldPrice-product.newPrice)*product?.quantity)}₫</Tag>
                                        )
                                    }
                                    {
                                        product.promoteCode&&product.oldPrice== -1&&(
                                            <Tag color="blue">{formatCurrency(product.newPrice)}₫ /sản phẩm</Tag>
                                        )
                                    }
                                </div>
                                </div>
                            </td>
                            <td>
                                {
                                    voucherFormStates[product.productItemID] && (
                                        <VoucherForm key={product.productItemID} productItemID={product.productItemID} quantity={product.quantity} isShow={voucherFormStates[product.productItemID]}
                                                     handleHide={() => toggleVoucherForm(product.productItemID)}

                                                     handleChangePromoteItem={props.handleChangePromoteItem}
                                        />
                                    )
                                }
                            </td>
                            <td>

                            </td>
                            <td>
                                {/*{*/}
                                {/*    product.promoteCode&&product.oldPrice!= -1&&(*/}
                                {/*        <Tag color="red">-{formatCurrency((product.oldPrice-product.newPrice)*product?.quantity)}₫</Tag>*/}
                                {/*    )*/}
                                {/*}*/}
                                {/*{*/}
                                {/*    product.promoteCode&&product.oldPrice== -1&&(*/}
                                {/*        <Tag color="blue">{formatCurrency(product.newPrice)}₫ /sản phẩm</Tag>*/}
                                {/*    )*/}
                                {/*}*/}
                            </td>
                            <td>

                            </td>

                        </tr>

                    </React.Fragment>
                ))
            }

        </>
    )
}
