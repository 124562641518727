import http from "../index"
import { 
    apiAddContact,
    apiManagerContactList,
    apiConfirmHandleContact,
    apiAddHandlerContact,
    apiConfirmDoneContact,
    apiReplyContact,
    apiAddContactByAdmin
 } from "./urls"

const addContact = body => http.post(apiAddContact, body);
const addContactByAdmin = body => http.post(apiAddContactByAdmin, body);

const managerContactList = body => http.post(apiManagerContactList, body);
const confirmHandleContact = (contactId, note) => http.post(`${apiConfirmHandleContact}?ContactId=${contactId}&Note=${note}`);
const addHandlerContact = (contactId, userID, note) => http.post(`${apiAddHandlerContact}?ContactId=${contactId}&UserID=${userID}&Note=${note}`);
const confirmDoneContact = (contactId, response) => http.post(`${apiConfirmDoneContact}?ContactId=${contactId}&Response=${response}`);

const replyContact = (contactId, body) => http.post(`${apiReplyContact}?ContactId=${contactId}`, body);

const ContactService = { 
    addContact,
    addContactByAdmin,
    managerContactList,
    confirmHandleContact,
    addHandlerContact,
    confirmDoneContact,
    replyContact
 }
export default ContactService
