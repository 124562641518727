
import axios from 'axios';
import STORAGE, {getStorage} from "../lib/storage";

axios.defaults.baseURL =  `${process.env.REACT_APP_API_ROOT}`; // Thay thế URL bằng URL của API của bạn

axios.interceptors.request.use(
    (config) => {
        const token = getStorage(STORAGE.TOKEN);
        if (token) {
            config.headers.Authorization = `${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axios;
