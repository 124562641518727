import {Avatar, Col, Divider, Row, Select} from "antd";
import React, {useEffect, useState} from "react";
import {DownOutlined, EyeInvisibleOutlined, EyeOutlined, UserOutlined} from "@ant-design/icons";
import ButtonCircle from "../../../../components/MyButton/ButtonCircle";
import {CustomerEditModal} from "../modal/CustomerEditModal";
import {GUIDE_EMPTY, relationOption, RELATTION_STATUS} from "../../../../constants/constants";
import "../style/customer-edit.css"
import CB1 from "../../../../components/Modal/CB1";
import http from "../../../../services";
import {toast} from "react-toastify";
import Notice from "../../../../components/Notice";
import {formatMoney} from "../../../../lib/utils";
import {DATE_FORMAT_DD, DATE_TIME_FORMAT_DD, formatDate} from "../../../../lib/dateFormatters";
import Button from "../../../../components/MyButton/Button";
import {useSelector} from "react-redux";

export const CustomerInfor = ({userDetail, refreshData, refreshCareTab, ...props}) => {
    const [isShowEditModal, setIsShowEditModal] = useState(false);
    const [hidenAccBalance, setHidenAccBalance] = useState(true)
    const {userIF} = useSelector(state => state?.appGlobal)


    const renderRelationShip = (value) => {
        switch (value) {
            case RELATTION_STATUS.KHACH_HANG_MOI:
                return (<div>khách hàng mới</div>);
            case RELATTION_STATUS.KHACH_HANG_TIEP_CAN:
                return (<div>khách hàng đã tiếp cận sản phẩm</div>);
            case RELATTION_STATUS.KHACH_HANG_TIEM_NANG:
                return (<div>khách hàng tiềm năng</div>);
            case RELATTION_STATUS.KHACH_HANG_THAN_THIET:
                return (<div>khách hàng thân thiết</div>);
            default:
                return (<div>khách hàng mới</div>);
        }
    }
    const handleChangeStatus = (id, status) => {
        CB1({
            title: `Bạn có chắc chắn muốn chuyển trạng thái khách hàng không?`,
            icon: "warning-usb",
            okText: "Đồng ý",
            onOk: async close => {
                http.post(`ManageCustomer/ChangeCustomerRelationship?UserID=${id}&RelationshipStatus=${status}`).then(resp => {
                    if (!resp.isError) {
                        // toast.success("Thay đổi trạng thái thành công !");
                        Notice({msg: "Thay đổi trạng thái thành công !"})
                        refreshData();
                        refreshCareTab();
                    }
                }).finally(() => {
                    close()
                })
            },
        })
    }
    const handleHiden = () => {
        setHidenAccBalance(!hidenAccBalance)
    }
    return (
        <>
            <div className={"flex-column customer__infor-ctn col-4"}>
                <Row gutter={5} className="pointer" style={{marginBottom: 20}}>
                    <Col>
                        <div className="account-infor-avatar" style={{marginBottom: 20}}>
                            <Avatar
                                src={userDetail?.avatar}
                                size={100}
                                className="style-avt"
                                style={{border: "1px solid #ddd"}}
                                icon={<UserOutlined className="fs-24"/>}
                            />
                        </div>
                        <div style={{marginBottom: 20}}>
                            <span style={{fontSize: 14}}>
                                {/*{renderRelationShip(userDetail?.relationshipStatus)}*/}
                                <Select
                                    value={userDetail?.relationshipStatus}
                                    onChange={(e) => {
                                        handleChangeStatus(userDetail.userID, e);
                                    }}
                                >
                                    {relationOption.map((option) => (
                                        <option key={option.key} value={option.key}>
                                            {option.label}
                                        </option>
                                    ))}
                                </Select>
                            </span>
                        </div>
                        <div className={"d-flex"}>
                            <p style={{fontSize: 20, fontWeight: 600, marginRight: 6}}>{userDetail?.fullName}</p>
                            {/*<ButtonCircle*/}
                            {/*    title="Chỉnh sửa thông tin khách hàng"*/}
                            {/*    iconName="edit"*/}
                            {/*    style={{background: "rgba(255,255,255,0.8)"}}*/}
                            {/*    onClick={() => {*/}
                            {/*        setIsShowEditModal(true);*/}
                            {/*    }}*/}
                            {/*/>*/}
                        </div>
                    </Col>
                </Row>
                <Row>
                    {userIF?.accountID === GUIDE_EMPTY &&
                        (
                            <>
                                <Col xs={24} sm={8} className="field-title">
                                    Tên tài khoản:
                                </Col>
                                <Col xs={24} sm={16}>{userDetail?.username}</Col>
                            </>
                        )
                    }

                    <Col xs={24} sm={8} className="field-title">
                        Số tiền trong tài khoản:
                    </Col>
                    <Col xs={24} sm={16}>
                          <span className="money-now">
                                        {hidenAccBalance
                                            ? '******'
                                            : userDetail?.accountBalance
                                                ? formatMoney(userDetail?.accountBalance)
                                                : 0}
                              đ
                                      </span>
                        <span className="ml-12 fs-17">
                                        {hidenAccBalance ? (
                                            <EyeOutlined onClick={handleHiden}/>
                                        ) : (
                                            <EyeInvisibleOutlined onClick={handleHiden}/>
                                        )}
                                      </span>
                    </Col>
                    {!props?.isCompany && (
                        <Col xs={24} sm={8} className="field-title">
                            Tên doanh nghiệp:
                        </Col>)
                    }
                    {props?.isCompany && (
                        <Col xs={24} sm={8} className="field-title">
                            Họ và tên:
                        </Col>)
                    }
                    <Col xs={24} sm={16}>{userDetail?.fullName}</Col>
                    <Col xs={24} sm={8} className="field-title">
                        Số điện thoại:
                    </Col>
                    <Col xs={24} sm={16}>{userDetail?.phoneNumber}</Col>
                    {!props?.isCompany && (
                        <>
                            <Col xs={24} sm={8} className="field-title">
                                Giới tính:
                            </Col>


                            <Col xs={24} sm={16}>
                                {userDetail?.sex === '1' ? 'Nam' : 'Nữ'}
                            </Col>
                        </>
                    )
                    }
                    {
                        !props?.isCompany && (
                            <>
                                <Col xs={24} sm={8} className="field-title">
                                    Ngày sinh:
                                </Col>
                                <Col xs={24} sm={16}>
                                    {formatDate(userDetail?.dateOfBirth, DATE_FORMAT_DD)}

                                </Col>
                            </>
                        )
                    }
                    <Col xs={24} sm={8} className="field-title">
                        Email:
                    </Col>
                    <Col xs={24} sm={16}>{userDetail?.email}</Col>

                    <Col xs={24} sm={8} className="field-title">
                        Địa chỉ chi tiết:
                    </Col>
                    <Col xs={24} sm={16}>
                        <p style={{maxWidth: "100%", wordBreak: "break-all", whiteSpace: "normal"}}>
                            {userDetail?.address}
                        </p>
                    </Col>

                    {
                        !props?.isCompany && (
                            <Col xs={24} sm={8} className="field-title">
                                Căn cước công dân:
                            </Col>
                        )
                    }
                    {
                        props?.isCompany && (
                            <Col xs={24} sm={8} className="field-title">
                                Mã số thuế:
                            </Col>
                        )
                    }
                    <Col xs={24} sm={16}>
                        <p style={{wordBreak: "break-word"}}>
                            {userDetail?.taxNumber}
                        </p>
                    </Col>
                </Row>

            </div>

            {
                isShowEditModal && (
                    <CustomerEditModal
                        cancel={() => setIsShowEditModal(false)}
                        open={isShowEditModal}
                        refreshData={refreshData}
                        userId={userDetail?.userID}
                        isCompany={props?.isCompany}
                    />
                )
            }
        </>
    )
}
