import { 
    apiGetListByDepartmentID,
    apiGetDetailsByID,
    apiInsertEmployee,
    apiUpdateEmployee,
    apiDeleteEmployee
} from "./urls"
import http2 from "../index2"

const getListUserByDepartmentID = body => http2.post(apiGetListByDepartmentID, body);
const getDetailsByID = body => http2.post(apiGetDetailsByID, body);
const insertEmployee = body => http2.post(apiInsertEmployee, body);
const updateEmployee = body => http2.post(apiUpdateEmployee, body);
//const deleteEmployee = id => http2.post(apiDeleteEmployee, body);
const deleteEmployee = id => http2.post(`${apiDeleteEmployee}?Emplid=${id}`)

const EmployeeOracleService = { 
    getListUserByDepartmentID,
    getDetailsByID,
    insertEmployee,
    updateEmployee,
    deleteEmployee
}
export default EmployeeOracleService
