import {Col, Row, Select} from "antd"
import FlInput from "src/components/FloatingLabel/Input"
import FlSelect from "src/components/FloatingLabel/Select"
import {SearchStyled} from "../../Production/styled";
import {TabsProps} from "react-bootstrap";
import {SearchOutlined} from "@ant-design/icons";


const {Option} = Select

export const items: TabsProps['items'] = [

    {
        key: -1,
        label: 'Tất cả',
    },
    {
        key: 1,
        label: 'Không kích hoạt',
    },
    {
        key: 0,
        label: 'Kích họat',
    },

];
export const type: TabsProps['type'] = [

    {
        key: 1,
        label: 'Banner',
    },
    {
        key: 5,
        label: 'Footer',
    }
];
const Search = ({handleChangeKeyword, status, handleStatus, category, pagination, handleChangeCategory}) => {

    return (
        <SearchStyled>
            <Row gutter={[16, 16]}>

                <Col flex="auto">

                    <div style={{display: "flex"}}>
                        <Col flex="auto">
                            <FlInput
                                search
                                allowClear
                                label="Tìm kiếm"
                                onSearch={e => handleChangeKeyword(e)}
                            />
                        </Col>

                        <Col lg={5} xs={24}>
                            <FlSelect
                                label="Thể loại"
                                onChange={handleChangeCategory}
                                value={category && (category == 1 ? "Banner"
                                    :  "Footer")}
                            >
                                {
                                    type?.map(i => (
                                        <Option key={i?.key} value={i?.key}>
                                            {i?.label}
                                        </Option>
                                    ))
                                }
                            </FlSelect>
                        </Col>
                    </div>

                </Col>


                <Col lg={5} xs={24}>
                    <FlSelect
                        label="Trạng thái"
                        onChange={handleStatus}
                        value={status && (status == -1 ? " Tất cả"
                            : (status == 1 ? "Không kích hoạt"
                                : status == 0 && "Kích hoạt"))}
                    >
                        {
                            items?.map(i => (
                                <Option key={i.key} value={i.key}>
                                    {i?.label}
                                </Option>
                            ))
                        }
                    </FlSelect>
                </Col>

                <div style={{paddingLeft: "30px", paddingTop: 4, paddingRight: 4}}>
                    <SearchOutlined style={{fontSize: "30px"}}/>
                </div>
            </Row>
        </SearchStyled>
    )
}


export default Search



