import React from 'react'
import { CardPreviewStyled} from './style'
import { Col } from 'antd'


export default function CardPreview({logo,name}) {
  return (
    <CardPreviewStyled>
        <Col className='logo'>
            {logo}
        </Col>
        <Col className='name'>
            {name}
        </Col>
    </CardPreviewStyled>
  )
}
