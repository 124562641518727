import React from "react"
import { ProductStyled } from "./styled"
import { Row } from "antd"
import Rate from "./Rate"

export default function Product({ Data, DirectDetailProduct }) {
  const formatCurrency = value => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
    }).format(value)
  }
  const handleClickProduct = ProductID => {
    DirectDetailProduct(ProductID)
  }
  return (
    <>
      {Data?.length > 0 &&
        Data.map(x => (
          <ProductStyled
            key={x.productID}
            onClick={() => handleClickProduct(x.productID)}
          >
            <Row className="logo">
              <div className="logo-img">
                <img alt="file have some error" src={x.image} />
              </div>
            </Row>
            <Row className="review">
              <Row className="website">
                <p>{x.username}</p>
              </Row>
              <Row className="name">
                <p>{x.productName}</p>
              </Row>
              <Row className="rate">
                <Rate number={x.averageReview} max={6} />
                <p>
                  ({x.quantityReview} reviews) | Sold {x.sold}
                </p>
              </Row>
              <Row className="price">
                <p className="price-sale">
                  {formatCurrency((x.price * (100 - x.discount)) / 100)} đ
                </p>
                <p className="price-amount">{formatCurrency(x.price)} đ</p>
                <div className="btn-view">
                  <p>xem chi tiết</p>
                </div>
              </Row>
            </Row>
            <div className="discount">-{x.discount}%</div>
          </ProductStyled>
        ))}
    </>
  )
}
