import { Col, Descriptions, Divider, Image, Row } from "antd"
import moment from "moment"
import { useEffect, useState } from "react"
import CB1 from "src/components/Modal/CB1"
import CustomModal from "src/components/Modal/CustomModal"
import Button from "src/components/MyButton/Button"
import Notice from "src/components/Notice"
import UserService from "src/services/UserService"
import styled from "styled-components"
import ModalInsertUpdate from "./InsertUpdate"
import { FAILBACK, USER_STATUS_ENUM } from "src/constants/constants"
import { fomatTimeFromNow, formatDate } from "src/lib/time"

const StyledUserDetail = styled.div`
  .img-user {
    width: 100%;
    min-height: 220px;
    max-height: 300px;
    object-fit: cover;
  }
  .account-name {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  }
  .position {
    text-align: center;
  }
  .div-divider {
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-divider-horizontal {
      width: 50%;
      min-width: 50%;
    }
  }
`
const UserDetail = ({ onOk, detailInfo, updateUser, ...props }) => {
  const labelStyle = {fontWeight: 'bold', color: 'black'};

  const renderFooter = () => (
    <div className="d-flex justify-content-flex-end">
      <Button
        className="btn-hover-shadow"
        onClick={() => props?.onCancel()}
      >
        Đóng
      </Button>
      {detailInfo?.isEdit &&
      <Button
        btnType="primary"
        className="btn-hover-shadow"
        onClick={updateUser}
      >
        {detailInfo ? 'Cập nhật' : 'Lưu'}
      </Button>
      }
    </div>
  )

  return (
    <CustomModal
    title="Chi tiết nhân viên"
    footer={renderFooter()}
    width={1200}
    {...props}
    >
      <StyledUserDetail>
        <Row gutter={[20, 8]}>
          <Col span={5}>
            <Image
              src={detailInfo?.avatar}
              fallback={FAILBACK}
              alt={"ảnh tài khoản"}
              className="img-user"
            />
          </Col>
          <Col span={19}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                  <Descriptions title="Thông tin chi tiết:">
                      <Descriptions.Item label="Họ và tên" labelStyle={labelStyle}>{detailInfo?.fullName}</Descriptions.Item>
                      <Descriptions.Item label="Tên tài khoản" labelStyle={labelStyle}>{detailInfo?.username}</Descriptions.Item>
                      <Descriptions.Item label="Số điện thoại" labelStyle={labelStyle}>{detailInfo?.phoneNumber}</Descriptions.Item>
                      <Descriptions.Item label="Email" labelStyle={labelStyle}>{detailInfo?.email}</Descriptions.Item>
                      <Descriptions.Item label="Giới tính" labelStyle={labelStyle}>{detailInfo?.sex ? 'Nam' : 'Nữ'}</Descriptions.Item>
                      <Descriptions.Item label="Ngày sinh" labelStyle={labelStyle}>{formatDate(detailInfo?.dateOfBirth)}</Descriptions.Item>
                      <Descriptions.Item label="Địa chỉ" labelStyle={labelStyle} span={3}>{detailInfo?.fullAddress}</Descriptions.Item>
                      <Descriptions.Item label="Phòng ban" labelStyle={labelStyle}>{detailInfo?.departmentName}</Descriptions.Item>
                      <Descriptions.Item label="Nhóm quyền" labelStyle={labelStyle}>{detailInfo?.roleName}</Descriptions.Item>
                      <Descriptions.Item label="Trạng thái" labelStyle={labelStyle}>{detailInfo?.status == USER_STATUS_ENUM.DANG_HOAT_DONG ? 'Đang hoạt dộng' : 'Ngưng hoạt động'}</Descriptions.Item>
                  </Descriptions>
              </Col>
            </Row>
          </Col>
        </Row>
      </StyledUserDetail>
    </CustomModal>
  )
}

export default UserDetail
