import {useEffect, useState} from "react";
import './news-slider.scss'
import {useNavigate} from "react-router-dom";
import GuestService from "src/services/GuestService"
const slideWidth = 28;

let _items = [
    // {
    //     player: {
    //         title: 'Efren Reyes',
    //         desc: 'Known as "The Magician", Efren Reyes is well regarded by many professionals as the greatest all around player of all time.',
    //         image: 'https://i.postimg.cc/RhYnBf5m/er-slider.jpg',
    //         id: "1"
    //     },
    // },
    // {
    //     player: {
    //         title: "Ronnie O'Sullivan",
    //         desc: "Ronald Antonio O'Sullivan is a six-time world champion and is the most successful player in the history of snooker.",
    //         image: 'https://i.postimg.cc/qBGQNc37/ro-slider.jpg',
    //         id: "1"
    //     },
    // },
    // {
    //     player: {
    //         title: 'Shane Van Boening',
    //         desc: 'The "South Dakota Kid" is hearing-impaired and uses a hearing aid, but it has not limited his ability.',
    //         image: 'https://i.postimg.cc/cHdMJQKG/svb-slider.jpg',
    //         id: "1"
    //     },
    // },
    // {
    //     player: {
    //         title: 'Mike Sigel',
    //         desc: 'Mike Sigel or "Captain Hook" as many like to call him is an American professional pool player with over 108 tournament wins.',
    //         image: 'https://i.postimg.cc/C12h7nZn/ms-1.jpg',
    //         id: "1"
    //     },
    // },
    // {
    //     player: {
    //         title: 'Willie Mosconi',
    //         desc: 'Nicknamed "Mr. Pocket Billiards," Willie Mosconi was among the first Billiard Congress of America Hall of Fame inductees.',
    //         image: 'https://i.postimg.cc/NfzMDVHP/willie-mosconi-slider.jpg',
    //         id: "1"
    //
    //     },
    // },
];

let length = 5;
_items.push(..._items);

const sleep = (ms = 0) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

const CarouselSlideItem = ({pos, idx, activeIdx}) => {
    const navigate = useNavigate();
    const createItem = (position, idx) => {
        const item = {
            styles: {
                transform: `translateX(${position * slideWidth}rem)`,
                cursor: "pointer"
            },
            player: _items[idx].player,
        };

        switch (position) {
            case length - 1:
            case length + 1:
                item.styles = {...item.styles, filter: 'grayscale(1)'};
                break;
            case length:
                break;
            default:
                item.styles = {...item.styles, opacity: 0};
                break;
        }

        return item;
    };

    const item = createItem(pos, idx, activeIdx);

    return (
        <li className="carousel__slide-item" style={item.styles} onClick={() => {
            navigate(`/${item.player.slug}-${item.player.id}`);
            // navigate(`/${item?.slug || 'bai-viet-n'}-${item?.postID}`)
        }}>
            <div className="carousel__slide-item-img-link">
                <img src={item.player.image} alt={item.player.title}/>
            </div>
            <div className="carousel-slide-item__body">
                <h4 className={"limit-text"}>{item.player.title}</h4>
                <p className={"limit-text-2"}>{item.player.desc}</p>
            </div>
        </li>
    );
};

const keys = Array.from(Array(10).keys());

export const NewsSlider = () => {
    const [items, setItems] = useState(keys);
    const [isTicking, setIsTicking] = useState(false);
    const [activeIdx, setActiveIdx] = useState(0);
    const bigLength = items.length;

    const prevClick = (jump = 1) => {
        if (!isTicking) {
            setIsTicking(true);
            setItems((prev) => {
                return prev.map((_, i) => prev[(i + jump) % bigLength]);
            });
        }
    };

    const nextClick = (jump = 1) => {
        if (!isTicking) {
            setIsTicking(true);
            setItems((prev) => {
                return prev.map(
                    (_, i) => prev[(i - jump + bigLength) % bigLength],
                );
            });
        }
    };

    const handleDotClick = (idx) => {
        if (idx < activeIdx) prevClick(activeIdx - idx);
        if (idx > activeIdx) nextClick(idx - activeIdx);
    };

    useEffect(() => {
        if (isTicking) sleep(300).then(() => setIsTicking(false));
    }, [isTicking]);

    useEffect(() => {
        setActiveIdx((length - (items[0] % length)) % length) // prettier-ignore
    }, [items]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const model = {
                    "pageSize": 5,
                    "currentPage": 1,
                    "categoryPostID": "d21a60d0-e3f8-49e0-96eb-7b2f6947fd89"
                }

                const resp = await GuestService.getListPost(model);
                const originalData = resp?.object?.data;
                const convertedData = originalData.map(item => {
                    return {
                        player: {
                            title: item.title,
                            desc: item.summary,
                            image: item.image,
                            id: item.postID,
                            slug:item.slug
                        }
                    };
                });
                // const s = [
                //     {
                //         player: {
                //             title: 'Shane Van Boening',
                //             desc: 'The "South Dakota Kid" is hearing-impaired and uses a hearing aid, but it has not limited his ability.',
                //             image: 'https://i.postimg.cc/cHdMJQKG/svb-slider.jpg',
                //         },
                //     },
                //     {
                //         player: {
                //             title: 'Mike Sigel',
                //             desc: 'Mike Sigel or "Captain Hook" as many like to call him is an American professional pool player with over 108 tournament wins.',
                //             image: 'https://i.postimg.cc/C12h7nZn/ms-1.jpg',
                //         },
                //     },
                //     {
                //         player: {
                //             title: 'Willie Mosconi',
                //             desc: 'Nicknamed "Mr. Pocket Billiards," Willie Mosconi was among the first Billiard Congress of America Hall of Fame inductees.',
                //             image: 'https://i.postimg.cc/NfzMDVHP/willie-mosconi-slider.jpg',
                //         },
                //     },
                // ];

                _items = [...convertedData];
                _items.push(..._items);
                // console.log('convert: ', _items);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    return (
        _items && _items.length > 0 && (
            <div className="carousel__wrap">
                <div className="carousel__inner">
                    <button className="carousel__btn carousel__btn--prev" onClick={() => prevClick()}>
                        <i className="carousel__btn-arrow carousel__btn-arrow--left"/>
                    </button>
                    <div className="carousel__container">
                        <ul className="carousel__slide-list">
                            {items.map((pos, i) => (
                                <CarouselSlideItem
                                    key={i}
                                    idx={i}
                                    pos={pos}
                                    activeIdx={activeIdx}
                                />
                            ))}
                        </ul>
                    </div>
                    <button className="carousel__btn carousel__btn--next" onClick={() => nextClick()}>
                        <i className="carousel__btn-arrow carousel__btn-arrow--right"/>
                    </button>
                    <div className="carousel__dots">
                        {items.slice(0, length).map((pos, i) => (
                            <button
                                key={i}
                                onClick={() => handleDotClick(i)}
                                className={i === activeIdx ? 'dot active' : 'dot'}
                            />
                        ))}
                    </div>
                </div>
            </div>
        )
    );
};


