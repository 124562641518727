import {Anchor, Col, Divider, Row, Tag, Tooltip} from "antd";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import SpinCustom from "../../../../../components/Spin";
import {FloatActionWrapper} from "../../../../../components/FloatAction/styles";
import {ListUserStyled} from "../../../Company/styled";
import TableCustom from "../../../../../components/Table/CustomTable";
import {Search} from "../../components/Search"
import {format, parseISO} from "date-fns";
import ConTractViewPDF from "../../../../Contract/ContractPreview/ConTractViewPDF";
import ButtonCircle from "../../../../../components/MyButton/ButtonCircle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";
import {DisplayId} from "../../../../../lib/utils";
import {SearchContract} from "../../components/SearchContract";
import Button from "../../../../../components/MyButton/Button";
import {ContractPanel} from "../../panel/ContractPanel";
import ROUTER from "../../../../../router";
import {ContractViewer} from "../../../../Contract/ContractPreview/ContractViewer";
import http from "../../../../../services";
import CB1 from "../../../../../components/Modal/CB1";
import Swal from "sweetalert2";
import {TemplateEditModal} from "../../modal/TemplateEditModal";
import Notice from "../../../../../components/Notice";

export const TemplateListTable = ({
                                      contracts,
                                      refreshData,
                                      totalPage,
                                      pagination,
                                      getOrder,
                                      onChangeKeyword,
                                      handleFilter,
                                      isInsert
                                  }) => {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [contractSelected, setContractSelected] = useState(false);
    const [isShowEditModal, setIsShowEditModal] = useState(false);
    const [isShowCreateContract, setisShowCreateContract] = useState(false);


    const [dataBodySource, setDataBodySource] = useState({
        pageSize: 10,
        currentPage: 1,
    });

    const handleChangePage = (page, pageSize) => {
        const model = {...pagination, pageSize: pageSize || 10, currentPage: page || 1}
        getOrder(model);
    }
    const [isShowContract, setIsShowContract] = useState(false);
    const RenderDeleteButton = (id) => {

        return  (
            <ButtonCircle
                title="Xóa template"
                iconName="cancel"
                style={{background: "#FFE9EC"}}
                onClick={() => {
                    CB1({
                        title: `Bạn có chắc chắn muốn xóa tempalate này không?`,
                        icon: "warning-usb",
                        okText: "Đồng ý",
                        onOk: async close => {
                            http.post(`Contract/DeleteContractTemplate`).then(resp => {
                                if(!resp?.isError){
                                    // handleRefresh();
                                    refreshData();
                                    Notice({
                                        isSuccess: true,
                                        msg: "Xóa template thành công!",
                                    })
                                }
                            }).finally(() => {
                                close();
                            })
                        },
                    })
                }}
            />
        )

    };



    const RenderViewButton = (contract) => {
        return (
            <ButtonCircle
                title="Xem hợp đồng"
                iconName="view"
                style={{background: "rgba(255,255,255,0.8)"}}
                onClick={() => {
                   handleViewerContract(contract);
                }}
            />
        )

    };
    const handleViewerContract=async (contract) => {
        const html = await getHtml(contract?.contractUrl);
        setContractSelected({...contract,html:html});
        setTimeout(()=>{
        setIsShowContract(true);
        },[500])
    }
    const getHtml=async (url) => {
        const resp = await http.get(`Contract/GetHTMLContentByURL?url=${url}`)
        if(!resp?.isError){
            return resp?.object?.htmlContent||'';
        }else{
            return '';
        }
    }
    const renderDate = (dateString) => {
        if (dateString) {
            const dateObject = parseISO(dateString);
            const formattedDate = format(dateObject, 'dd-MM-yyyy HH:mm:ss');
            return formattedDate;
        }
    }
    const RenderInactiveButton = (id) => {

        return  (
            <ButtonCircle
                title="Xóa template"
                iconName="bin"
                style={{background: "#FFE9EC"}}
                onClick={() => {
                    CB1({
                        title: `Bạn có chắc chắn muốn xóa template này không?`,
                        icon: "warning-usb",
                        okText: "Đồng ý",
                        onOk: async close => {
                            const model={
                                "contractID": id}
                            http.post(`Contract/DeleteContractTemplate`,model).then(resp => {
                                if(!resp?.isError){
                                     refreshData();
                                    Notice({
                                        isSuccess: true,
                                        msg: "Xóa template thành công!",
                                    })
                                }
                            }).finally(() => {
                                close();
                            })
                        },
                    })
                }}
            />
        )

    };
    const showSuccess = (message) => {
        Swal.fire({
            icon: 'success',
            title: 'Thành Công',
            text: message,
        })
    };

    const renderEditButton=(contract)=>{

        return(
            <ButtonCircle
                title="Cập nhật"
                iconName="edit"
                onClick={() => {
                    handleEditButton(contract)
                    // setIsShowPromoteDetail(true);
                    // setVoucherDetail(voucher);
                }}
            />
        )
    }
    const handleEditButton=async (contract) => {
        const html = await getHtml(contract?.contractUrl);
        setContractSelected({...contract, html: html});
        setTimeout(()=>{
        setIsShowEditModal(true);

        },500);
    }
    const renderButton=(contract)=>{
        if(!contract?.isDelete){
            return (
                <>
                    {renderEditButton(contract)}
                </>
            )
        }else{
            return (
                <>
                    {RenderInactiveButton( contract?.contractID)}
                    {renderEditButton(contract)}
                </>
            )
        }
    }
    const renderButtonViewer = (contract) => {
        return (<>
            {RenderViewButton(contract)}
        </>)
    }

    const [totalDataSource, setTotalDataSource] = useState(0);
    const columns = [
        {
            title: "Mã hợp đồng",
            dataIndex: "contractID",
            key: "contractID",
            width: 120,
            align: "center",
            render:(value,contract)=>(
                <div>
                    {DisplayId(contract?.contractID)}
                </div>
            )
        },
        {
            title: "Tên hợp đồng",
            dataIndex: "contractName",
            key: "contractName",
            render: (value, contract) =>
                (
                    <div>
                        <div className={"max-line1 col-11"}>
                            <Tooltip title={value} className={"d-flex"}>
                                <p className="multiline-cell">
                                    {value}
                                </p>
                            </Tooltip>
                        </div>

                    </div>
                ),
            width: 200,
            align: "left",
        },
        {
            title: "Hợp đồng",
            dataIndex: "contractFileName",
            key: "contractName",
            render: (value, contract) =>
                (
                    <div>
                        <div className={"max-line1 col-11"} >
                            <Tooltip title={value} className={"d-flex"}>
                                <div>
                                    <FontAwesomeIcon icon={faFilePdf}
                                                     style={{color: "#1f2123",fontSize:"18px",marginRight:4}}
                                                     onClick={()=>handleViewerContract(contract)}
                                    />
                                </div>
                                <p className="multiline-cell" onClick={()=>handleViewerContract(contract)}>
                                    {value}
                                </p>
                            </Tooltip>
                        </div>
                        <div className={"col-1"}>
                            <FloatActionWrapper size="small" className="float-action__wrapper" style={{background: "transparent"}}>
                                {/*{renderButtonViewer(contract)}*/}
                                {renderButton(contract)}
                            </FloatActionWrapper>
                        </div>
                    </div>
                ),
            width: 200,
            align: "left",
        },

        // {
        //     title: "Trạng thái",
        //     dataIndex: "isDelete",
        //     key: "isDelete",
        //     width: 100,
        //     render: (value, contract) => (
        //         <div className="d-flex-end justify-content-center align-items-center mh-36">
        //             <FloatActionWrapper size="small" className="float-action__wrapper" style={{background: "#fff"}}>
        //                 {renderButton(contract)}
        //             </FloatActionWrapper>
        //             {
        //                 value?"Không kích hoạt":"Kích hoạt"
        //             }
        //         </div>
        //     ),
        //
        // },
    ]
    const handleRefreshUpdate=()=>{
        setIsShowEditModal(false);
        setisShowCreateContract(false);
        refreshData();
    }
    return (
        <>
            <ListUserStyled>
                <div>
                    <SearchContract
                        onChangeKeyword={onChangeKeyword}
                        pagination={pagination}
                        handleFilter={handleFilter}
                    />
                    <Divider className="mv-16"/>
                    <div className="title-type-1 d-flex justify-content-space-between align-items-center pb-16 pt-0 mb-16">
                        <div className="fs-24">
                            Danh sách template
                            {/* ({dataSource?.total || 0}) : */}
                        </div>
                        <Row guter={[16, 16]}>
                            {isInsert&&(
                                <Col>
                                    <Button
                                        btnType="primary"
                                        className="btn-hover-shadow"
                                        onClick={() => {
                                         setisShowCreateContract(true);
                                        }}
                                    >
                                        Thêm template
                                    </Button>
                                </Col>
                            )}
                            {/* {listButtonShow?.IsExport && (
              <Col>
                <Button
                  onClick={exportUser}
                  className="btn-hover-shadow"
                  btnType="third"
                >
                  Xuất Excel
                </Button>
              </Col>
            )} */}
                        </Row>
                    </div>
                </div>

                <Anchor
                    affix={false}
                    getContainer={() => document.getElementById("body-admin-scroll")}
                >
                    <Row gutter={[16, 16]}>
                        <Col style={{width: 0}} flex="auto">
                            <SpinCustom spinning={loading}>
                                <TableCustom
                                    isPrimary
                                    // onRow={record => {
                                    //     return {
                                    //         onClick: () => {
                                    //             setOpenModalUserDetail(record)
                                    //         },
                                    //     }
                                    // }}
                                    className="mb-6"
                                    dataSource={contracts}
                                    columns={columns}

                                    textEmpty="Không có hợp đồng"
                                    rowKey="contractID"

                                    sticky={{offsetHeader: -12}}
                                    scroll={{y: "100%", x: "800px"}}
                                    pagination={{
                                        defaultPage: dataBodySource.pageSize,
                                        showSizeChanger: true,
                                        pageSizeOptions: ['10', '20', '30'],
                                        total: totalPage,
                                        // onChange: (page, pageSize) => {
                                        //     setDataBodySource((prevState) => ({
                                        //         ...prevState,
                                        //         pageSize: pageSize || 10,
                                        //         currentPage: page || 1
                                        //     }));
                                        // },
                                        onChange: (page, pageSize) => {
                                            handleChangePage(page, pageSize);
                                        }
                                    }}
                                />
                            </SpinCustom>
                        </Col>
                    </Row>
                </Anchor>

            </ListUserStyled>
            {
                isShowContract && (

                    <ConTractViewPDF
                        onCancel={() => setIsShowContract(false)}
                        open={isShowContract}
                        contractPDF={{
                            titleContract: contractSelected?.contractName,
                            contractUrlPDF: contractSelected?.contractUrlPDF
                        }}
                    />
                )
            }
            {
                isShowEditModal&&(
                    <TemplateEditModal
                        contract={contractSelected}
                         open={isShowEditModal}
                        cancel={() => setIsShowEditModal(false)}
                        refreshData={handleRefreshUpdate}
                    />
                )
            }
            {
                isShowCreateContract&&(
                    <ContractPanel
                        open={isShowCreateContract}
                        cancel={() => setisShowCreateContract(false)}
                        refreshData={handleRefreshUpdate}
                    />
                )
            }

        </>

    )
}
