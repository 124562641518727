import { Col, Divider, Row, Spin } from "antd"
import { useEffect, useState } from "react"
import SvgIcon from "src/components/SvgIcon"
import { NewsDetailStyled } from "../styled"
import moment from "moment"
import { useNavigate, useParams } from "react-router-dom"
import GuestServices from "src/services/GuestService"
import Comments from "./Comments"
import ROUTER from "src/router"
import { linkYoutube } from "src/components/News/linkYoutube"
import { ROUTER_KEY } from "src/constants/constants"

const Detail = ({ postID }) => {
  const url = window.location.href;
  const id = useParams();
  const [postDataID, setPostDataID] = useState(null);
  // console.log(PostID)
  const [loading, setLoading] = useState(false)
  const [detailNews, setDetailNews] = useState()
  const [listPostRelate, setListPostRelate] = useState([])
  const navigate = useNavigate()

  /**
   * @description set title tab web
   * @author hddung
   */
  useEffect(() => {
    if(url) {
        return () => {
        document.title = 'TẠP HÓA SỐ'; // Đặt lại title khi component bị unmount
        };
    }
  }, [url]);

  useEffect(() => {
    if(Object.keys(id).length > 0) {
      getDetailPost();
    }
  }, [id])

  const getDetailPost = async () => {
    try {
      setLoading(true)
      const PostID = getGuiIdByParamURL(id?.id) || postID;
      const res = await GuestServices.getDetail({ PostID });
      if (res?.isOk) {
        setDetailNews(res?.object);
        document.title = res?.object?.title || 'TẠP HÓA SỐ';
      }
      else setDetailNews(undefined);
      window.scrollTo(0, 0)
    } finally {
      setLoading(false);
    }
  }

  const getListPostRelate = async () => {
    try {
      setLoading(true)
      const res = await GuestServices.getListPostRelate({
        categoryPostID: detailNews?.categoryPostID,
      })
      if (res?.isError) return
      setListPostRelate(res?.object)
    } finally {
      setLoading(false)
    }
  }

  // useEffect(() => {
  //   // if (PostID) 
  //   getDetailPost()
  //   window.scrollTo(0, 0)
  // }, [])

  useEffect(() => {
    if (detailNews) getListPostRelate()
  }, [detailNews])

  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, [PostID])


  /**
   * @description get URL lấy ra Id product
   * @author hddung 
   */
  function getGuiIdByParamURL(id) {
    var regex = /([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/;
    var ketQua = id.match(regex);
    return ketQua[ketQua.length - 1];
  }

  return (
    <Spin spinning={loading}>
      <div className="section-box">
        <div className="breadcrumbs-div" style={{marginBottom: '0px', padding: 'unset'}}>
            <div className="container" style={{padding: 'unset'}}>
                <ul className="breadcrumb">
                    <li><a className="font-xs color-gray-500" onClick={() => {
                        navigate(ROUTER.HOME)
                    }}>Trang chủ</a></li>
                    <li><a className="font-xs color-gray-500" onClick={() => {
                        navigate('/tin-tuc/b9c02d5c-2197-46c2-9ed2-ab3e2cd2946a')
                    }}>Tin tức</a></li>
                    <li><a className="font-xs color-gray-1000">{detailNews?.title}</a></li>
                </ul>
            </div>
        </div>
      </div>
      {!detailNews && !loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          Không tồn tại bài đăng
        </div>
      ) : (
        <NewsDetailStyled>
          <div className="new-detail-header">{detailNews?.title}</div>
          <Row gutter={10} className="align-items-center new-detail-sub-header">
            <Col>
              <SvgIcon name="access-time" />
            </Col>
            <Col className="new-detail-sub-text">
              {detailNews?.publicationTime &&
                moment(detailNews?.publicationTime).format(
                  "DD/MM/YYYY HH:mm:ss",
                )}
            </Col>
            <Col className="">
              <SvgIcon name="author" />
            </Col>
            <Col className="new-detail-sub-text">
              {detailNews?.categoryPostName}
            </Col>

            <Col>
              <div className="d-flex">
                <SvgIcon name="view" style={{ margin: "0 6px 0 10px" }} />
                <div className="number-comment">
                  {/* {!!item?.NumberOfViewers ? item?.NumberOfViewers : 0} */}
                  {detailNews?.numberOfViewers}
                </div>
              </div>
            </Col>
          </Row>
          <div className="new-detail-content mb-24">{detailNews?.summary}</div>

          {!!detailNews?.YoutubeLink && (
            <iframe
              width="100%"
              height="500"
              src={linkYoutube(detailNews?.youtubeLink)}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            />
          )}
          {!!detailNews?.listFile?.length && (
            <div className="mb-16">
              <div className="mb-16 fw-600 fs-16">Tài liệu đính kèm</div>
              {detailNews?.listFile?.map(i => (
                <a rel="noreferrer" target="_blank" href={i?.fileUrl}>
                  <Row gutter={10}>
                    <Col>
                      <SvgIcon name="link" />
                    </Col>
                    <Col>{i?.fileName}</Col>
                  </Row>
                </a>
              ))}
            </div>
          )}
          <Col span={24}>
            <div dangerouslySetInnerHTML={{ __html: detailNews?.content }} />
          </Col>

          <Divider />
          <Row gutter={20} style={{ marginBottom: "10px" }}>
            {detailNews?.listTags?.map((i, idx) => (
              <Col key={`tag${idx}`}
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0 })
                  navigate(`/tin-tuc/tags-${i?.tagsID}`, {
                    state: i,
                  })
                }}
              >
                <div className="news-detail-box-tags pointer">{i.tagsName}</div>
              </Col>
            ))}
          </Row>
          <div
            className="ctn-button-back"
          >
            <div 
              className="button-back pointer"
              onClick={() => {
                window.scrollTo(0, 0)
                window.history.back()
              }}
            >
              <SvgIcon name="arrow-back-red" style={{ marginRight: "5px" }} />
              Trở về
            </div>
          </div>
          {!!detailNews?.IsComment && <Comments postID={postID} />}

          <div className="title-type-1">Bài viết liên quan</div>
          {listPostRelate?.length > 0 &&
            listPostRelate?.map(item => (
              <div
                style={{ cursor: "pointer" }}
                key={`news-relate-to${item?.postID}`}
                className="primary-color text-ellipsis mb-12 fw-600 ls-ctn-news-relate"
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" })
                  navigate(`/${item?.slug || `bai-viet${ROUTER_KEY.NOI_DUNG}`}-${item?.postID}`)
                }}
              >
              -  {item?.title}
              </div>
            ))}
        </NewsDetailStyled>
      )}
    </Spin>
  )
}

export default Detail
