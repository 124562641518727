import antd, {Col, DatePicker, Row, Select} from "antd"
import {useSelector} from "react-redux"
import FlInput from "src/components/FloatingLabel/Input"
import FlSelect from "src/components/FloatingLabel/Select"
import {ORDER_STATUS} from "src/constants/constants"
import {SearchStyled} from "../../Production/styled";
import dayjs from "dayjs";
import {CustomDropdown} from "../../../../components/Layouts/component/DropDown/DropDownCustom";

const { Option } = Select
const Search = ({onChangeKeyword,pagination,handleFilter,mode}) => {
    // const {
    //     Select
    // } = antd;

    const items: TabsProps['items'] = [
        {
            key: 0,
            label: 'Tất cả',
        },
        {
            key: ORDER_STATUS.CHO_XAC_NHAN,
            label: 'Chờ xác nhận',
        },
        {
            key: ORDER_STATUS.CHO_KY,
            label: 'Chờ ký'
        },
        {
            key: ORDER_STATUS.HOAN_THANH,
            label: 'Hoàn thành',
        },
        {
            key: ORDER_STATUS.DA_HUY,
            label: 'Đã Huỷ',
        }
        // {
        //     key: ORDER_STATUS.DA_DANH_GIA,
        //     label: 'Đã đánh giá',
        // }
    ];
    const sortItems: TabsProps['sortitems'] = [

        {
            key: 0,
            label: 'Tất cả',
        },
        {
            key: 1,
            label: 'Giá tăng dần',
        },
        {
            key: -1,
            label: 'Giá giảm dần',
        }
    ];

    return (
        <SearchStyled>
            <Row gutter={[16, 16]}>
                <Col flex="auto">
                    <FlInput
                        search
                        allowClear
                        label="Nhập ID đơn hàng hoặc tên sản phẩm "
                        onSearch={e => onChangeKeyword(e)}
                    />
                </Col>
                {
                    mode&&mode===1&&(  //order
                        <Col lg={5} xs={24}>
                            <FlSelect
                                label="Trạng thái"
                                onChange={(e) => {
                                    handleFilter({...pagination,status:e})
                                }}
                                value={pagination.status}
                            >
                                {
                                    items?.map(i=>(
                                                <Option key={i.key} value={i.key}>
                                            {i?.label}
                                        </Option>
                                    ))
                                }
                            </FlSelect>
                        </Col>
                    )
                }


                <Col lg={3} xs={16}>
                    <FlSelect
                        label="Sắp xếp"
                        onChange={(e) => {
                            handleFilter({...pagination,sortByPrice:e})
                        }}
                        value={pagination?.sortByPrice}
                    >
                        {
                            sortItems?.map(i=>(
                                <Option key={i.key} value={i.key}>
                                    {i?.label}
                                </Option>
                            ))
                        }
                    </FlSelect>
                    {/*<CustomDropdown options={sortItems} onSelect={(e)=>{*/}
                    {/*    handleFilter({...pagination,sortByPrice:e.value})*/}
                    {/*}}*/}
                    {/*    placeholder={"Sắp xếp"}*/}

                    {/*/>*/}
                </Col>

                <Col lg={3} xs={24}>

                    <DatePicker
                        label={"Từ ngày"}
                        onChange={(e) => {
                            const formattedDate = dayjs(e).format('DD/MM/YYYY');
                            handleFilter({...pagination, fromDate: formattedDate});
                        }}
                        placeholder="Từ ngày"
                        format="DD/MM/YYYY"
                        allowClear
                    />
                </Col>

                <Col lg={3} xs={24}>
                    <DatePicker
                        lable={"Đến ngày"}
                        onChange={(e) => {
                            const toDate = dayjs(e).format('DD/MM/YYYY');
                            handleFilter({...pagination, fromDate: toDate});
                        }}
                        placeholder="Đến ngày"
                        format="DD/MM/YYYY"
                        allowClear
                    />
                </Col>

            </Row>
        </SearchStyled>
    )
}

export default Search
