import { UserOutlined } from "@ant-design/icons"
import { Anchor, Avatar, Col, Divider, Row, Space, Table, Tooltip } from "antd"
import { useEffect, useState } from "react"
import { FloatActionWrapper } from "src/components/FloatAction/styles"
import CB1 from "src/components/Modal/CB1"
import Button from "src/components/MyButton/Button"
import ButtonCircle from "src/components/MyButton/ButtonCircle"
import Notice from "src/components/Notice"
import SpinCustom from "src/components/Spin"
import TableCustom from "src/components/Table/CustomTable"
import {
  MainTableData,
  MainTableHeader,
  SubTableData,
  SubTableHeader,
} from "src/components/Table/CustomTable/styled"
import UserService from "src/services/UserService"
import Search from "./components/Search"
import TreeAnchor from "./components/TreeAnchor"
import ImportUser from "./modal/ImportUser"
import ModalInsertUpdate from "./modal/InsertUpdate"
import UserDetail from "./modal/UserDetail"
import ChangePasswordAdmin from "src/components/ChangePasswordAdmin"
import { ListUserStyled } from "./styled"
import ContactService from "src/services/ContactService"
import { STATUS_OPTION } from "src/constants/constants"
import AddContent from "./modal/AddContent"
import ContactHandlers from "./modal/ContactHandlers"
import SendMail from "./modal/SendMail"
import { useLocation } from "react-router-dom"

const ListUserContact = () => {
  const [loading, setLoading] = useState(false)

  //hddung
  const [dataBody, setDataBody] = useState({});
  const {state} = useLocation();
  const [listContactData, setListContactData] = useState([]);
  const [totalDataSource, setTotalDataSource] = useState(0);

  const [openContentPopup, setOpenContentPopup] = useState(false);
  const [openContactHandlers, setOpenContactHandlers] = useState(false);
  const [openInsertUpdate, setOpenInsertUpdate] = useState(false);
  const [detailInfo, setDetailInfo] = useState(null);
  const [modeShowPopup, setModeShowPopup] = useState(0);
  const [openSendMail, setOpenSendMail] = useState(false);
  const [isInsert, setIsInsert] = useState(false);
  //hddung
  const columns = [
    {
      title: "STT",
      key: "index",
      width: 60,
      align: "center",
      render: (text, row, idx) => (
        <div className="text-center">
          {idx +
            1 +
            dataBody.pageSize * (dataBody.currentPage - 1)}
        </div>
      ),
    },
    {
      title: "Tiêu đề",
      dataIndex: "title",
      key: "title",
      width: 200,
      align: "left",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 220,
      align: "left",
    },
    {
      title: "Họ và tên",
      dataIndex: "name",
      key: "name",
      width: 220,
      align: "left",
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone",
      key: "phone",
      width: 140,
      align: "right",
    },
    {
      title: "Nội dung hỗ trợ",
      dataIndex: "content",
      key: "content",
      width: 300,
      align: "left",
    },
    {
      title: "Người phụ trách",
      dataIndex: "assignUserName",
      key: "assignUserName",
      width: 220,
      align: "left",
    },
    {
      title: "",
      dataIndex: "status",
      key: "status",
      width: 160,
      render: (type, record) => (
        <div className="d-flex justify-content-center align-items-center mh-36">
          {renderTextStatus(type)}
          {type != STATUS_OPTION.HOAN_THANH && (
            <FloatActionWrapper size="small" className="float-action__wrapper" style={{ background: "#fff"}}>
              {renderListButton(record)}
            </FloatActionWrapper>
            )
          }
        </div>
      ),
    },
  ]


  const renderListButton = record => (
    <Space>
      { record?.isConfirm &&
      <ButtonCircle
        title="Xác nhận xử lý"
        iconName="confirm-manager-contact"
        onClick={() => {
          setOpenContentPopup(true)
          setDetailInfo(record)
          setModeShowPopup(STATUS_OPTION.CHO_XAC_NHAN);
        }}
      />}
      { record?.isAddSupporter &&
      <ButtonCircle
        title="Thêm phụ trách"
        iconName="add-user-manager-contact"
        onClick={() => {
          setOpenContentPopup(true)
          setDetailInfo(record)
          setModeShowPopup(STATUS_OPTION.DANG_XU_LY);
        }}
      />}
      { record?.isDone &&
      <ButtonCircle
        title="Hoàn thành"
        iconName="done-manager-contact"
        style={{ background: "#fff" }}
        onClick={() => {
          setOpenContentPopup(true)
          setDetailInfo(record)
          setModeShowPopup(STATUS_OPTION.HOAN_THANH);
        }}
      />}
      { record?.isSendMail &&
      <ButtonCircle
        title="Gửi mail"
        iconName="send-mail"
        style={{ background: "#fff" }}
        onClick={() => {
          setOpenSendMail(true);
          setDetailInfo(record)
        }}
      />}
    </Space>
  )
  
  //#region useEffect

  useEffect(() => {
    if(Object.keys(dataBody).length > 0) {
      managerContactList();
    }
  }, [dataBody])

  useEffect(() => {
    if(state?.isOverView) {
      setDataBody({
        pageSize: 10,
        currentPage: 1,
        status: 1,
        textSearch: ''
      });
    } else {
      setDataBody({
        pageSize: 10,
        currentPage: 1,
        status: 0,
        textSearch: ''
      });
    }
  },[state])

  //#endregion

  //#region function

  /**
   * @description thực hiện return trạng thái tương ứng status
   * @author hddung 
   */
  function renderTextStatus(type) {
    var str = 'Chờ xử lý',
        color = 'red';
    switch(type) {
      case STATUS_OPTION.CHO_XAC_NHAN:
        str = 'Chờ xử lý';
        color = 'red';
        break;
      case STATUS_OPTION.DANG_XU_LY:
        str = 'Đang xử lý';
        color = '#2dc258';
        break;
      case STATUS_OPTION.HOAN_THANH:
        str = 'Hoàn thành';
        color = '#2160bd';
        break;
    }

    return (
      <div className="text-center text-ellipsis fw-600" style={{color: color}}>
        {str}
      </div>
    )

  }

  /**
   * @description get list danh sách contact
   * @author hddung 
   */
  async function managerContactList() {
    try {
      setLoading(true);
      const res = await ContactService.managerContactList(dataBody);
      if(res?.isError) return
      var data = res?.object;
      
      if(!data) return

      setListContactData(data?.list);
      setTotalDataSource(data?.total);
      setIsInsert(data?.isInsert)
    } finally {
      setLoading(false);
    }
  }

  //#endregion

  return (
    <ListUserStyled>
      <div>
        <Search
          setDataBody={setDataBody}
          dataBody={dataBody}
        />
        <Divider className="mv-16" />
        <div className="title-type-1 d-flex justify-content-space-between align-items-center pb-16 pt-0 mb-16">
          <div className="fs-24">
            Yêu cầu hỗ trợ
          </div>
          {isInsert &&
            <Col>
              <Button
                btnType="primary"
                className="btn-hover-shadow"
                onClick={() => setOpenInsertUpdate(true)}
                >
                Thêm yêu cầu hỗ trợ
              </Button>
            </Col>
          }
        </div>
      </div>
      <Anchor
        affix={false}
        getContainer={() => document.getElementById("body-admin-scroll")}
      >
        <Row gutter={[16, 16]}>
          <Col style={{ width: 0 }} flex="auto">
            <SpinCustom spinning={loading}>
              <TableCustom
                isPrimary
                onRow={record => {
                  return {
                    onClick: () => {
                      setDetailInfo(record);
                      setOpenContactHandlers(true);
                    },
                  }
                }}
                className="mb-6"
                dataSource={listContactData || []}
                columns={columns}
                textEmpty="Không có bản ghi nào!"
                rowKey="id"
                sticky={{ offsetHeader: -12 }}
                scroll={{ y: "100%", x: "800px" }}
                pagination={{ 
                  defaultPage: dataBody.pageSize, 
                  showSizeChanger: true, 
                  pageSizeOptions: ['10', '20', '30'], 
                  total: totalDataSource,
                  onChange: (page, pageSize) => {
                    setDataBody((prevState) => ({
                      ...prevState,
                      pageSize: pageSize || 10,
                      currentPage: page || 1
                    }));
                  },
                }}
              />
            </SpinCustom>
          </Col>
        </Row>
      </Anchor>

      { !!openContentPopup &&
        <AddContent 
          detailInfo={detailInfo} 
          onCancel={() => {
            setDetailInfo(undefined);
            setOpenContentPopup(false);
          }}
          reloadTable={managerContactList}
          onOk={() => {}}
          open={openContentPopup}
          modeShowPopup={modeShowPopup}
        />
      }

      { !!openContactHandlers &&
        <ContactHandlers 
          detailInfo={detailInfo} 
          onCancel={() => {
            setDetailInfo(undefined);
            setOpenContactHandlers(false);
          }}
          onOk={() => {}}
          open={openContactHandlers}
        />
      }

      { !!openSendMail &&
        <SendMail 
          detailInfo={detailInfo} 
          onCancel={() => {
            setDetailInfo(undefined);
            setOpenSendMail(false);
          }}
          reloadTable={managerContactList}
          onOk={() => {}}
          open={openSendMail}
        />
      }

      { !!openInsertUpdate &&
        <ModalInsertUpdate 
          onCancel={() => {
            setOpenInsertUpdate(false);
          }}
          reloadTable={managerContactList}
          onOk={() => {}}
          open={openInsertUpdate}
        />
      }

    </ListUserStyled>
  )
}

export default ListUserContact
