import React, {useEffect} from 'react';
import "./contractPreview.css"
import Button from "../../../components/MyButton/Button";
import CustomModal from 'src/components/Modal/CustomModal';

export const ConTractViewPDF = ({contractPDF, ...props}) => {
    useEffect(() => {
        console.log('pdf:',contractPDF);
    }, []);
    const renderFooter = () => (
        <div className="d-flex justify-content-flex-end">
            <Button
                className="btn-hover-shadow"
                onClick={() => props?.onCancel()}
            >
                Đóng
            </Button>
        </div>
    )

    return (
        <CustomModal
            title={contractPDF?.titleContract || 'Chi tiết hợp đồng'}
            footer={renderFooter()}
            width={1400}
            {...props}
        >
            <div className='ctn-content-contract-pdf'>
                <iframe src={contractPDF?.contractUrlPDF} style={{zIndex: '99999', width: '100%', height: '82vh'}}></iframe>
            </div>
        </CustomModal>
    );
};

export default ConTractViewPDF
