import {
  Anchor,
  Avatar,
  Col,
  Divider,
  Image,
  Row,
  Space,
  Table,
  Tooltip,
} from "antd"
import { useEffect, useState } from "react"
import { FloatActionWrapper } from "src/components/FloatAction/styles"
import CB1 from "src/components/Modal/CB1"
import Button from "src/components/MyButton/Button"
import ButtonCircle from "src/components/MyButton/ButtonCircle"
import Notice from "src/components/Notice"
import SpinCustom from "src/components/Spin"
import TableCustom from "src/components/Table/CustomTable"
import Search from "./components/Search"
import TreeAnchor from "./components/TreeAnchor"
import ImportUser from "./modal/ImportUser"
import ModalInsertUpdate from "./modal/InsertUpdate"
import UserDetail from "./modal/UserDetail"
import ChangePasswordAdmin from "src/components/ChangePasswordAdmin"
import { ListUserStyled } from "./styled"
import { ContainerWidth } from "./styled"
import EmployeeService from "src/services/EmployeeOracleService"
import { SEX_TYPE, USER_STATUS_ENUM } from "src/constants/constants"

const ListUser = () => {
  const [loading, setLoading] = useState(false)
  const [openInsertUpdate, setOpenInsertUpdate] = useState(false)
  const [openImportUser, setOpenImportUser] = useState(false)
  const [detailInfo, setDetailInfo] = useState()
  const [listButtonShow, setListButtonShow] = useState()
  const [selectedNode, setSelectedNote] = useState()
  const [openModalUserDetail, setOpenModalUserDetail] = useState(false)
  const [openModalChangPassword, setOpenModalChangPassword] = useState(false)
  const [textSearch, setTextSearch] = useState("")
  const [status, setStatus] = useState(1)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const [dataBody, setDataBody] = useState({ pageSize: 10, currentPage: 1 })
  const [dataSource, setDataSource] = useState([])
  const [roleAction, setRoleAction] = useState(null)
  const [totalDataSource, setTotalDataSource] = useState(0)

  const columns = [
    {
      title: "STT",
      key: "index",
      width: 60,
      align: "center",
      render: (text, row, idx) => (
        <div className="text-center">
          {idx + 1 + dataBody.pageSize * (dataBody.currentPage - 1)}
        </div>
      ),
    },
    // {
    //   title: "Ảnh",
    //   dataIndex: "avatar",
    //   key: "avatar",
    //   align: "center",
    //   render: value => (
    //       <div onClick={(event)=>{
    //         event.stopPropagation();
    //       }}>
    //       <Image src={value} size={50} width={100} height={100}/>
    //       </div>
    //   ),
    //   width: 130
    // },
    {
      title: "Tài khoản",
      dataIndex: "useR_ID",
      key: "useR_ID",
      width: 230,
      render: value => <strong>{value}</strong>,
      align: "left",
    },
    {
      title: "Tên nhân viên",
      dataIndex: "name",
      key: "name",
      width: 220,
      align: "left",
    },
    {
      // title: (
      //   <>
      //     <MainTableHeader>Email</MainTableHeader>
      //     <SubTableHeader>Số điện thoại</SubTableHeader>
      //   </>
      // ),
      title: "Email",
      dataIndex: "emaiL_ADDR",
      key: "emaiL_ADDR",
      align: "left",
      width: 220,
    },
    // {
    //   title: "Phân quyền",
    //   dataIndex: "roleName",
    //   key: "roleName",
    //   align: "left",
    //   width: 180,
    // },
    {
      title: "",
      dataIndex: "status",
      key: "status",
      width: 50,
      render: (type, record) => (
        <div className="d-flex justify-content-center align-items-center mh-36">
          {/* {renderTextStatus(type)} */}
          <FloatActionWrapper size="small" className="float-action__wrapper">
            {renderListButton(record)}
          </FloatActionWrapper>
        </div>
      ),
    },
  ]

  const renderListButton = record => (
    <Space>
      {/* {!!record?.isEdit && ( */}
      <ButtonCircle
        title="Cập nhật"
        iconName="edit"
        style={{ background: "#fff" }}
        onClick={() => {
          setDetailInfo(record)
          setOpenInsertUpdate(true)
        }}
      />
      {/* )} */}
      {/* {!!record?.isDelete && ( */}
      <ButtonCircle
        title="Xóa"
        iconName="bin"
        style={{ background: "#fff" }}
        onClick={() => {
          CB1({
            title: `Bạn có chắc chắn muốn xoá người dùng
              <strong> ${record?.useR_ID}</strong> không?`,
            icon: "warning-usb",
            okText: "Đồng ý",
            onOk: async close => {
              deleteEmployee(record?.emplid)
              close()
            },
          })
        }}
      />
      {/* )} */}
      {/* {!!record?.isBlock && (
        <ButtonCircle
          title={record?.status == 1 ? 'Ngưng hoạt đông' : 'Hoạt đông'}
          iconName={record?.status == 1 ? 'cancel-post' : 're-post'}
          style={{ background: "#fff" }}
          onClick={() => {
            changeStatusUser(record?.userID, record?.status == USER_STATUS_ENUM.DANG_HOAT_DONG ? USER_STATUS_ENUM.NGUNG_HOAT_DONG : USER_STATUS_ENUM.DANG_HOAT_DONG);
          }}
        />
      )} */}
      {/* {!!record?.isBlock && (
        <ButtonCircle
          title='Ngưng hoạt đông'
          iconName='cancel-post'
          style={{ background: "#fff" }}
          onClick={() => {
            changeStatusUser(record?.userID, USER_STATUS_ENUM.NGUNG_HOAT_DONG);
          }}
        />
      )}
      {!!record?.isActive && (
        <ButtonCircle
          title='Hoạt đông'
          iconName='re-post'
          style={{ background: "#fff" }}
          onClick={() => {
            changeStatusUser(record?.userID,  USER_STATUS_ENUM.DANG_HOAT_DONG);
          }}
        />
      )} */}
      {!!record?.isResetPassword && (
        <ButtonCircle
          title="đổi mật khẩu"
          iconName="reset-pass"
          style={{ background: "#fff" }}
          onClick={() => {
            setDetailInfo(record)
            setOpenModalChangPassword(true)
          }}
        />
      )}
    </Space>
  )

  //#region useEffect

  useEffect(() => {
    if (Object.keys(dataBody).length > 0) {
      getListUserByDepartmentID()
    }
  }, [dataBody])

  //#endregion

  //#region function

  /**
   * @description lấy ra toàn bộ nhân viên theo phòng ban
   * @author hddung
   */
  async function getListUserByDepartmentID() {
    try {
      setLoading(true)
      const res = await EmployeeService.getListUserByDepartmentID(dataBody)
      if (res?.isError) return
      //format lại 1 số giá trị để binding vào form update nhân viên
      // res?.object?.listUserGetListByDepartmentID.forEach(x => {
      //   x.sex = x.sex ? SEX_TYPE.MALE : SEX_TYPE.FEMALE
      // });

      setDataSource(res?.object?.listEmployee)
      setTotalDataSource(res?.object?.total)
      setRoleAction({
        isImport: res?.object?.isImport,
        isInsert: res?.object?.isInsert,
      })
    } finally {
      setLoading(false)
    }
  }

  /**
   * @description thực hiện return trạng thái tương ứng status
   * @author hddung
   */
  function renderTextStatus(type) {
    var str = "Ngưng hoạt động",
      color = "red"
    switch (type) {
      case USER_STATUS_ENUM.NGUNG_HOAT_DONG:
        str = "Ngưng hoạt động"
        color = "red"
        break
      case USER_STATUS_ENUM.DANG_HOAT_DONG:
        str = "Đang hoạt động"
        color = "#0960a0"
        break
    }

    return (
      <div
        className="text-center text-ellipsis fw-600"
        style={{ color: color }}
      >
        {str}
      </div>
    )
  }

  /**
   * @description delete nhân viên
   * @author hddung
   */
  async function deleteEmployee(userID) {
    const res = await EmployeeService.deleteEmployee(userID)
    if (res?.isError) return
    getListUserByDepartmentID()
    Notice({ msg: `Xóa nhân viên thành công !` })
  }

  /**
   * @description changestatus nhân viên
   * @author hddung
   */
  async function changeStatusUser(userID, status) {
    const res = await EmployeeService.changeStatusUser({
      userID: userID,
      status: status,
    })
    if (res?.isError) return
    getListUserByDepartmentID()
    Notice({ msg: `Cập nhật trạng thái thành công !` })
  }

  //#endregion

  return (
    <ContainerWidth>
      <ListUserStyled>
        <div>
          <Search setDataBody={setDataBody} dataBody={dataBody} />
          <Divider className="mv-16" />
          <div className="title-type-1 d-flex justify-content-space-between align-items-center pb-16 pt-0 mb-16">
            <div className="fs-24">
              Danh sách nhân viên
              {/* ({dataSource?.total || 0}) : */}
            </div>
            <Row guter={[16, 16]}>
              {/* {roleAction?.isImport &&
              <Col style={{marginRight: '5px'}}>
                <Button
                  onClick={() => {
                    // setOpenImportPopup(true);
                  }}
                  className="btn-hover-shadow"
                  btnType="third"
                >
                  Nhập Excel
                </Button>
              </Col>
            } */}
              {/* {roleAction?.isInsert && */}
              <Col>
                <Button
                  btnType="primary"
                  className="btn-hover-shadow"
                  onClick={() => setOpenInsertUpdate(true)}
                >
                  Thêm nhân viên
                </Button>
              </Col>
              {/* } */}
            </Row>
          </div>
        </div>
        <Anchor
          affix={false}
          getContainer={() => document.getElementById("body-admin-scroll")}
        >
          <Row gutter={[16, 16]}>
            <Col style={{ width: 300 }}>
              <TreeAnchor setDataBody={setDataBody} dataBody={dataBody} />
            </Col>
            <Col style={{ width: 0 }} flex="auto">
              <SpinCustom spinning={loading}>
                <TableCustom
                  isPrimary
                  onRow={record => {
                    return {
                      onClick: () => {
                        setDetailInfo(record)
                        setOpenModalUserDetail(true)
                      },
                    }
                  }}
                  className="mb-6"
                  dataSource={dataSource || []}
                  columns={columns}
                  textEmpty="Không có bản ghi nào!"
                  rowKey="id"
                  sticky={{ offsetHeader: -12 }}
                  scroll={{ y: "100%", x: "800px" }}
                  pagination={{
                    defaultPage: dataBody.pageSize,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "30"],
                    total: totalDataSource,
                    onChange: (page, pageSize) => {
                      setDataBody(prevState => ({
                        ...prevState,
                        pageSize: pageSize || 10,
                        currentPage: page || 1,
                      }))
                    },
                  }}
                />
              </SpinCustom>
            </Col>
          </Row>
        </Anchor>
        {!!openInsertUpdate && (
          <ModalInsertUpdate
            open={openInsertUpdate}
            detailInfo={detailInfo}
            onOk={() => {}}
            onCancel={() => {
              setDetailInfo(undefined)
              setOpenInsertUpdate(false)
            }}
            reloadTable={getListUserByDepartmentID}
          />
        )}
        {!!openImportUser && (
          <ImportUser
            open={openImportUser}
            onCancel={() => setOpenImportUser(false)}
            onOk={() => {}}
            department={selectedNode}
          />
        )}
        {!!openModalUserDetail && (
          <UserDetail
            open={openModalUserDetail}
            detailInfo={detailInfo}
            onOk={() => {}}
            onCancel={() => {
              setDetailInfo(undefined)
              setOpenModalUserDetail(false)
            }}
            updateUser={() => {
              setOpenInsertUpdate(true)
              setOpenModalUserDetail(false)
            }}
          />
        )}
        {!!openModalChangPassword && (
          <ChangePasswordAdmin
            open={openModalChangPassword}
            detailInfo={detailInfo}
            onOk={() => {}}
            onCancel={() => {
              setDetailInfo(undefined)
              setOpenModalChangPassword(false)
            }}
            reloadTable={getListUserByDepartmentID}
          />
        )}
      </ListUserStyled>
    </ContainerWidth>
  )
}

export default ListUser
