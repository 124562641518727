import { UserOutlined } from "@ant-design/icons"
import { Anchor, Avatar, Col, Divider, Row, Space, Table, Tooltip } from "antd"
import { useEffect, useState } from "react"
import { FloatActionWrapper } from "src/components/FloatAction/styles"
import CB1 from "src/components/Modal/CB1"
import Button from "src/components/MyButton/Button"
import ButtonCircle from "src/components/MyButton/ButtonCircle"
import Notice from "src/components/Notice"
import SpinCustom from "src/components/Spin"
import TableCustom from "src/components/Table/CustomTable"
import Search from "./components/Search"
import { LogHistoryStyle } from "./styles"
import ContactService from "src/services/ContactService"
import { STATUS_OPTION } from "src/constants/constants"
import CommonService from "src/services/CommonService"
import dayjs from "dayjs";
import { formatDateTime } from "src/lib/time"

const LogHistory = () => {
  const [loading, setLoading] = useState(false)

  //hddung
  const [dataBody, setDataBody] = useState({
    pageSize: 10,
    currentPage: 1,
    textSearch: "",
    functionName: "",
    userName: "",
    fromDate: dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
    toDate: dayjs().format('YYYY-MM-DD')
  });
  const [listDataSource, setListDataSource] = useState([]);
  const [totalDataSource, setTotalDataSource] = useState(0);

  //hddung
  const columns = [
    {
      title: "STT",
      key: "index",
      width: 60,
      render: (text, row, idx) => (
        <div className="text-center">
          {idx +
            1 +
            dataBody.pageSize * (dataBody.currentPage - 1)}
        </div>
      ),
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 60,
      align: "right",
    },
    {
      title: "Người dùng",
      dataIndex: "userName",
      key: "userName",
      width: 110,
      align: "left",
      render: (value) => (<strong>{value}</strong>)
    },
    {
      title: "Thời gian",
      dataIndex: "createDate",
      key: "createDate",
      width: 120,
      align: "center",
      render: (value) => (<p>{formatDateTime(value)}</p>)
    },
    {
      title: "Hành động",
      dataIndex: "funcName",
      key: "funcName",
      width: 140,
      align: "left",
    },
    {
      title: "Địa chỉ IP",
      dataIndex: "ip",
      key: "ip",
      width: 120,
      align: "left",
    },
    {
      title: "Mã thao tác",
      dataIndex: "objectGUID",
      key: "objectGUID",
      width: 180,
      align: "left",
    },
    {
      title: "userAgent",
      dataIndex: "userAgent",
      key: "userAgent",
      width: 300,
      align: "left",
    },
    {
      title: "Mô tả hành động",
      dataIndex: "logContent",
      key: "logContent",
      width: 280,
      align: "left",
    },
  ]

  //#region useEffect

  useEffect(() => {
    if(Object.keys(dataBody).length > 0) {
      getListHistory();
    }
  }, [dataBody])

  //#endregion

  //#region function

  /**
   * @description get list danh sách history
   * @author hddung 
   */
  async function getListHistory() {
    try {
      setLoading(true);
      const res = await CommonService.getListHistory(dataBody);
      if(res?.isError) return
      setListDataSource(res?.object?.list);
      setTotalDataSource(res?.object?.count);
    } finally {
      setLoading(false);
    }
  }

  //#endregion

  return (
    <LogHistoryStyle>
      <div>
        <Search
          setDataBody={setDataBody}
          dataBody={dataBody}
        />
        <Divider className="mv-16" />
        <div className="title-type-1 d-flex justify-content-space-between align-items-center pb-16 pt-0 mb-16">
          <div className="fs-24">
            Lịch sử hoạt động
          </div>
        </div>
      </div>
      <Anchor
        affix={false}
        getContainer={() => document.getElementById("body-admin-scroll")}
      >
        <Row gutter={[16, 16]}>
          <Col style={{ width: 0 }} flex="auto">
            <SpinCustom spinning={loading}>
              <TableCustom
                isPrimary
                className="mb-6"
                dataSource={listDataSource || []}
                columns={columns}
                textEmpty="Không có bản ghi nào!"
                rowKey="id"
                sticky={{ offsetHeader: -12 }}
                scroll={{ y: "100%", x: "800px" }}
                pagination={{ 
                  defaultPage: dataBody.pageSize, 
                  showSizeChanger: true, 
                  pageSizeOptions: ['10', '20', '30'], 
                  total: totalDataSource,
                  onChange: (page, pageSize) => {
                    setDataBody((prevState) => ({
                      ...prevState,
                      pageSize: pageSize || 10,
                      currentPage: page || 1
                    }));
                  },
                }}
              />
            </SpinCustom>
          </Col>
        </Row>
      </Anchor>
    </LogHistoryStyle>
  )
}

export default LogHistory
