import { Tabs } from "antd"
import { useLocation, useNavigate } from "react-router-dom"
import LayoutCommon from "src/components/Common/Layout"
import MenuItem from "src/components/Layouts/MenuItems"
import { treeToList } from "src/lib/utils"
import ROUTER from "src/router"
import styled from "styled-components"
// import { TabsNewsStyled } from "../../News/styled"

export const StyledHeaderSubmenu = styled.div`
  .ant-tabs-tab-btn {
    color: #000;
    font-size: 24px;
  }
  .ant-tabs-tab-active {
    background: #fff !important;
  }
`
const HeaderSubmenu = ({ value }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const LIST = MenuItem(navigate)?.filter(
    i => i?.key === value && !!i?.NotHeaderSub,
  )
  const list = treeToList(LIST, "key")?.filter(
    i => i?.showOnBread || (!i?.type && !i?.hideOnMenu),
  )
  const arr = [list[list?.length - 1], ...list?.slice(0, list?.length - 1)]
  return (
    <LayoutCommon>
      {/* <StyledHeaderSubmenu>
        <TabsNewsStyled>
          <Tabs
            items={arr?.map((i, idx) => ({
              label:
                idx === 0 ? (
                  i?.label
                ) : (
                  <div style={{ marginTop: "10px" }}>{i?.label}</div>
                ),
              key: i?.key,
              onTabClick: () =>
                i?.onClick
                  ? i?.onClick()
                  : navigate(i?.key?.replace("submenu", "")),
              children: <div />,
            }))}
            onChange={value => {
              var i = arr?.find(item => item?.key === value)
              i?.onClick
                ? i?.onClick()
                : navigate(i?.key?.replace("submenu", ""))
            }}
            activeKey={location?.pathname}
          />
        </TabsNewsStyled>
      </StyledHeaderSubmenu> */}
    </LayoutCommon>
  )
}

export default HeaderSubmenu
