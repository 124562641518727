import { configureStore,getDefaultMiddleware } from "@reduxjs/toolkit"
import appGlobalReducer from "./appGlobal"
import commonReducer from "./common"
import roleReducer from "./role"
import courseReducer from "./course"
import bannerReducer from "./banner"
import cartReducer from "./cart"
import chatReducer from "./chat"
import notifyReducer from "./notify"
import {apiLoggerMiddleware} from "./apiLoggerMiddleware";

export default configureStore({
  reducer: {
    appGlobal: appGlobalReducer,
    role: roleReducer,
    common: commonReducer,
    course: courseReducer,
    banner: bannerReducer,
    cart:cartReducer,
    chat: chatReducer,
    notify: notifyReducer
  },
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
      }).concat(
          apiLoggerMiddleware
      ),
})
