import { 
    apiGetListUserByDepartmentID,
    apiInsertUser,
    apiUpdateUser,
    apiDeleteUser,
    apiResetPassword,
    apiChangeStatusUser,
} from "./urls"
import http from "../index"

const getListUserByDepartmentID = body => http.post(apiGetListUserByDepartmentID, body);
const insertUser = body => http.post(apiInsertUser, body);
const updateUser = body => http.post(apiUpdateUser, body);
const deleteUser = body => http.post(apiDeleteUser, body);
const resetPassword = body => http.post(apiResetPassword, body);
const changeStatusUser = body => http.post(apiChangeStatusUser, body);

const EmployeeService = { 
    getListUserByDepartmentID,
    insertUser,
    updateUser,
    deleteUser,
    resetPassword,
    changeStatusUser
}
export default EmployeeService
