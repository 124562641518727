import { Tree } from "antd"
import { useEffect, useState } from "react"
import SpinCustom from "src/components/Spin"
import DepartmentOracleService from "src/services/DepartmentOracleSerivce"
import ButtonCircle from "src/components/MyButton/ButtonCircle"
//module "e:/FPTU/Fall2023/SEP490/FE/src/pages/ADMIN/Production/modal/InsertCategoryProduct"
import { TreeAnchorStyled } from "../../ListUser/styled"
import CB1 from "src/components/Modal/CB1"
import Notice from "src/components/Notice"
import InsertCategoryProduct from "../modal/InsertCategoryProduct"

const TreeAnchor = ({ setDataBody, dataBody}) => {
  const [loading, setLoading] = useState(false)
  const [treeData, settreeData] = useState([])
  const [open, setOpen] = useState()
  const ACTION = [
    {
      icon: "edit-black",
      title: "Sửa thể loại",
      onClick: nodeData => setOpen({ ...nodeData, isEdit: true }),
      static: false,
    },
    {
      icon: "delete-black",
      title: "Xóa Thể loại",
      static: false,
      onClick: nodeData => {
        CB1({
          title: `Bạn có chắc chắn muốn xoá thể loại
          <strong> ${nodeData?.title}</strong> không?`,
          icon: "trashRed",
          okText: "Đồng ý",
          onOk: async close => {
            onDeleteCategory(nodeData?.key)
            close()
          },
        })
      },
    },
  ]

  //#region useEffect

  useEffect(() => {
    fetchData()
  }, [])

  //#endregion

  //#region function

  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await DepartmentOracleService.getAllDepartment()
      console.log("response: " + response)
      const data = response?.object
      console.log("data: " + data)

      if (!data) return

      const parentNode = data[0]
      const lstChildrenNode = data?.filter(x => x.deptid != "000000000")

      const tree = [
        {
          key: parentNode?.deptid,
          title: parentNode?.deptname,
          isDelete: true,
          isInsert: true,
          isUpdate: true,
          children:
            lstChildrenNode?.map(x => ({
              key: x?.deptid,
              title: x.deptname,
              level: 2,
              parentID: parentNode?.deptid,
              children: [],
              isDelete: true,
              isInsert: true,
              isUpdate: true,
            })) || [],
        },
      ]

      settreeData(tree)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const onDeleteCategory = async id => {
    try {
      const res = await DepartmentOracleService.deleteDepartment(id)
      if (res?.isError) return
      Notice({ msg: "Xóa danh mục thành công!" })
      fetchData()
    } finally {
    }
  }

  //#endregion

  return (
    <TreeAnchorStyled>
      <SpinCustom spinning={loading}>
        {!!treeData?.length && (
          <Tree
            defaultExpandAll
            defaultSelectedKeys={["000000000"]}
            value={dataBody?.deptid}
            placeholder="Please select"
            onSelect={(_, e) => {
              setDataBody(prevState => ({
                ...prevState,
                deptid: e.node.key || "000000000",
              }))
            }}
            treeData={treeData}
            titleRender={(nodeData, idx) => {
              return (
                <div
                  key={nodeData?.key}
                  className="d-flex justify-content-space-between align-items-center mh-36"
                >
                  <div className="text-ellipsis">{nodeData?.title}</div>
                  <div className="list-button-tree-hover">
                    {nodeData.key == "000000000" && nodeData.isInsert ? (
                      <ButtonCircle
                        key="add-box"
                        title="Thêm mới phân loại"
                        iconName="add-box"
                        onClick={() => {
                          setOpen({ nodeData })
                        }}
                      />
                    ) : (
                      // ACTION?.map(item =>
                      //     <ButtonCircle
                      //       key={item?.icon}
                      //       title={item?.title}
                      //       iconName={item?.icon}
                      //       onClick={() => {
                      //         item.onClick(nodeData)
                      //       }}
                      //     />
                      // )
                      <>
                        {nodeData?.isUpdate && (
                          <ButtonCircle
                            key="edit-black"
                            title="Sửa thể loại"
                            iconName="edit-black"
                            onClick={() => {
                              setOpen({ ...nodeData, isEdit: true })
                            }}
                          />
                        )}
                        {nodeData?.isDelete && (
                          <ButtonCircle
                            key="delete-black"
                            title="Xóa Thể loại"
                            iconName="delete-black"
                            onClick={() => {
                              CB1({
                                title: `Bạn có chắc chắn muốn xoá thể loại
                              <strong> ${nodeData?.title}</strong> không?`,
                                icon: "trashRed",
                                okText: "Đồng ý",
                                onOk: async close => {
                                  onDeleteCategory(nodeData?.key)
                                  close()
                                },
                              })
                            }}
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              )
            }}
          />
        )}
      </SpinCustom>

      {!!open && (
        <InsertCategoryProduct
          open={open}
          onCancel={() => setOpen(false)}
          listCategories={treeData}
          onOk={fetchData}
        />
      )}
    </TreeAnchorStyled>
  )
}

export default TreeAnchor
