import React, {useCallback, useEffect, useState} from "react";
import {DeleteOutlined, HomeOutlined, RightOutlined} from "@ant-design/icons";
import {CartItem} from "./item";
import {Button} from "antd";
import {useDispatch, useSelector} from 'react-redux';
import {clearCart, deleteCartItem, getCart, updateCart} from "../../../redux/cart";
import STORAGE from "../../../lib/storage";
import Swal from "sweetalert2";
import "./cart-detail.css"
import {useNavigate} from "react-router-dom";
import ROUTER from "../../../router";
import {useMediaQuery} from "react-responsive";
import SpinCustom from "../../../components/Spin";
import {debounce} from "lodash";
import http from "../../../services";
import PaymentMethods from "src/components/PaymentMethods";
import SvgIcon from "src/components/SvgIcon";

export const CartDetail = () => {
    const url = window.location.href;
    const cartItems = useSelector(state => state?.cart?.cartItems)
    const [activeTab, setActiveTab] = useState('tab-description');
    const [balance, setBalance] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isValidate, setIsValidate] = useState(false);
    const [filterItem, setFilterItem] = useState(false);
    const [isOpenQR, setIsOpenQR] = useState(false);
    const [qrContent, setQrContent] = useState(false);
    const [openOptionPayment, setOpenOptionPayment] = useState(false);
    const handleTabChange = (tabId) => {
        setActiveTab(tabId);
    };
    const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'});
    const isTabletOrMobile = useMediaQuery({query: '(max-width: 1224px)'});
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(getCart());
    }, [dispatch]);

    /**
     * @description set title tab web
     * @author hddung
     */
    useEffect(() => {
        if(url) {
            document.title = 'Chi tiết giỏ hàng'; // Thay đổi title thành 'New Title'
        
            return () => {
            document.title = 'TẠP HÓA SỐ'; // Đặt lại title khi component bị unmount
            };
        }
    }, [url]);

    const getAccountBalance = async () => {
        return await http.get("User/GetUserByID");
    }
    const debounceUpdateCart = useCallback(debounce((id, quantity) => handleUpdateQuantity(id, quantity), 1000), [])
    const handleUpdateQuantity = async (id, quantity) => {

        try {
            const userInfo = JSON.parse(localStorage.getItem(STORAGE.USER_INFO))
            const userId = userInfo.userID;
            const model = {
                "productItemID": id,
                "userID": userId,
                "quantity": quantity
            }
            if (quantity < 1) {

                await dispatch(deleteCartItem(model)).then(value => {
                    dispatch(getCart());
                })
            } else {
                await dispatch(updateCart(model)).then(value => {
                    dispatch(getCart());
                })
            }
        } catch (e) {

        }
    }
    const confirmDelete = (id) => {
        Swal.fire({
            title: "Xóa khỏi giỏ hàng?",
            text: "Bạn có chắc chắn muốn sản phẩm trong giỏ hàng không!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: 'Đồng ý',
            cancelButtonText: 'Huỷ bỏ',
            dangerMode: true,
        }).then((result) => {
            if (result.isConfirmed) {
                handleUpdateQuantity(id, 0); //remove
            } else {

            }
        });
    }
    const formatCurrency = (value) => {
        return new Intl.NumberFormat('vi-VN', {
            style: 'currency',
            currency: 'VND',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value).replace('VND', 'đ');
    };
    const [cart, setCart] = useState([]);
    const [productSelected, setProductSelected] = useState([]);
    const [totalPrice, setTotalPrice] = useState({
        totalNewPrice: 0,
        totalOldPrice: 0,
    });
    const [totalPriceSubmit, setTotalPriceSubmit] = useState({
        totalNewPrice: 0,
        totalOldPrice: 0,
    });

    useEffect(() => {
        setLoading(true);
    }, [])

    useEffect(() => {
        // setLoading(true);
        getAccountBalance().then(resp => {
            if (!resp?.isError) {
                setBalance(resp?.object?.accountBalance)
            } else {
                setBalance(0)
            }
        }).catch(reason => {
            setBalance(0);
        }).finally(
            () => {
                groupedProducts();
            }
        )
    }, [cartItems])
    useEffect(() => {
        loadCartData();
        const filteredProducts = [];
        if (productSelected && productSelected.length > 0 && cartItems && cartItems.length > 0) {
            cart?.forEach(shop => {

                shop?.items.forEach(product => {
                    if (productSelected.includes(product.productItemID)) {
                        filteredProducts.push(product)
                    }
                });
            })
            setFilterItem(filteredProducts);
        }
        handleCheckValidate(filteredProducts)

    }, [productSelected, cart]);
    const loadCartData = () => {
        calculateTotalPrice();
    }
    const calculateTotalPrice = () => {
        let total = {
            totalNewPrice: 0,
            totalOldPrice: 0,
        }
        for (const shop of cart) {
            for (const item of shop.items) {
                if (productSelected.includes(item.productId) && !(item.oldPrice == -1 && item.newPrice == -1)) {
                    total.totalNewPrice += item.newPrice * item.quantity;
                    if (item.oldPrice > 0) {
                        total.totalOldPrice += item.oldPrice * item.quantity;
                    } else if (item.newPrice > 0) { // giá liên hệ
                        total.totalOldPrice += item.newPrice * item.quantity;
                    }
                }
            }
        }
        setTotalPrice(total);
    };
    const handleSelectedProduct = (id) => {
        if (productSelected.includes(id)) {
            const updatedList = productSelected.filter((item) => item !== id);
            setProductSelected(updatedList);
        } else {
            const updatedList = [...productSelected, id];
            setProductSelected(updatedList);
        }
    };
    const handleSelectedShop = (shop, event) => {
        const productIds = shop.items.map(item => item.productId);
        const isChecked = event.target.checked; // Kiểm tra xem checkbox đã được check hay không
        const arr = productSelected;
        if (isChecked) {
            const newArray = [...new Set([...arr, ...productIds])];
            setProductSelected(newArray);

        } else {
            const newArray = arr.filter(item => !productIds.includes(item));
            setProductSelected(newArray);
        }
    }
    const checkShopSelected = (shop) => {
        const productIds = shop.items.map(item => item.productId);
        const arr = productSelected;
        return productIds.every(item => arr.includes(item));
    }
    const selectAllCart = (event) => {
        const isChecked = event.target.checked; // Kiểm tra xem checkbox đã được check hay không
        const newArray = [];
        if (isChecked) {
            for (const shop of cart) {
                for (const item of shop.items) {
                    newArray.push(item.productId);
                }
            }
            setProductSelected(newArray);
        } else {
            setProductSelected([]);
        }
    }
    const checkTotal = () => {
        if (!cart || cart.length == 0) {
            return false;
        }
        const products = []
        for (const shop of cart) {
            for (const item of shop.items) {
                products.push(item.productId);
            }
        }

        return products.every(item => productSelected.includes(item));
    }
    const renderPrice = (product) => {

        return (product?.discount !== 0) ? (product.price * (100 - product.discount)) / 100 : product.price;
    }
    const groupedProducts = () => {
        const r = cartItems.reduce((result, product) => {

            const existingShop = result.find(shop => shop.shopName === product.username);
            if (existingShop) {
                // Nếu shop đã tồn tại, thêm sản phẩm vào danh sách sản phẩm của shop
                existingShop.items.push({
                    productId: product.productItemID,
                    productItemID: product.productItemID,
                    pId:product.productID,
                    shopID:product.shopID,
                    productName: product.productName,
                    image: product.image,
                    attribute: product.productItemName,
                    oldPrice: product.price,
                    newPrice: renderPrice(product),
                    discount: product.discount,
                    quantity: product.quantity,
                    slug: product.slug,
                    promoteCode: ""
                });
            } else {
                // Nếu shop chưa tồn tại, tạo một shop mới
                result.push({
                    shopName: product.username,
                    items: [
                        {
                            productId: product.productItemID,
                            productItemID: product.productItemID,
                            shopID:product.shopID,
                            pId:product.productID,
                            productName: product.productName,
                            image: product.image,
                            attribute: product.productItemName,
                            oldPrice: product.price,
                            newPrice: renderPrice(product),
                            discount: product.discount,
                            quantity: product.quantity,
                            slug: product.slug,
                            promoteCode: "",

                        }
                    ]
                });
            }
            return result;
        }, []);
        //handle cart
        const array = [];
        for (const shop of r) {
            for (const item of shop.items) {
                array.push(item.productId);
            }
        }

        setProductSelected(array);
        // setTimeout(200);
        setCart(r)
        setLoading(false);
    }

    const handleUpdate = async (id, quantity) => {
        let checkRemove = false;
        const updatedDataCopy = cart.map((shop) => {
            // Tìm kiếm sản phẩm trong mảng items của từng cửa hàng
            const updatedItems = shop.items.map((item) => {
                if (item.productItemID === id) {
                    // Cập nhật giá trị quantity nếu sản phẩm được tìm thấy
                    if (quantity == 0) {
                        checkRemove = true;
                        return item;//pending item when quantity=0
                    }
                    if (quantity < 0) {
                        return item;
                    }
                    return {...item, quantity: quantity};
                }
                return item;
            });
            // Trả về một bản sao của cửa hàng với mảng items đã được cập nhật
            return {...shop, items: updatedItems};
        });
        if (checkRemove) {
            confirmDelete(id);
        } else {
            // Cập nhật state với dữ liệu mới
            setCart(updatedDataCopy);
            await debounceUpdateCart(id, quantity);

        }

    }
    const ClearCart = async () => {
        try {
            setLoading(true);
            const userInfo = JSON.parse(localStorage.getItem(STORAGE.USER_INFO))
            const userId = userInfo.userID;
            await dispatch(clearCart(userId)).then(value => {
                dispatch(getCart());
            })
        } catch (e) {

        } finally {
            setLoading(false);
        }
    }
    const handleClearCart = () => {
        Swal.fire({
            title: "Xóa khỏi giỏ hàng?",
            text: "Bạn có chắc chắn muốn xoá tất cả sản phẩm trong giỏ hàng không!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: 'Đồng ý',
            cancelButtonText: 'Huỷ bỏ',
            dangerMode: true,
        }).then((result) => {
            if (result.isConfirmed) {
                ClearCart();
            } else {

            }
        });
    }
    const handleCheckout = () => {

        if (filterItem && filterItem.length > 0) {
            const model = filterItem.map((item) => ({
                "productItemID": item.productItemID,
                "promoteCode": item.promoteCode || null, // Nếu bạn có một giá trị promoteCode nào đó thì sử dụng giá trị này ở đây.
                "quantity": parseInt(item.quantity),
            }));
            checkout(model).then(resp => {
                if (!resp?.isError) {
                    Swal.fire({
                        title: "Thành công",
                        text: "Chúc mừng bạn đã mua hàng thành công",
                        icon: "success",

                    }).then((result) => {
                        // ClearCart();
                        navigate(ROUTER.USER_ORDERS);
                    });
                }
                // if (resp?.data?.statusCode == 200) {
                //     Swal.fire({
                //         title: "Thành công",
                //         text: "Chúc mừng bạn đã mua hàng thành công",
                //         icon: "success",
                //
                //     }).then((result) => {
                //         ClearCart();
                //         navigate(ROUTER.USER_ORDERS);
                //     });
                // } else {
                //     Swal.fire({
                //         icon: 'error',
                //         title: 'Mua hàng Thất bại',
                //         text: 'Mua hàng thất bại! Kiểm tra lại thông tin giỏ hàng và thử lại',
                //     }).then(() => {
                //         // navigate('/orders');
                //         // clearCart();
                //     });
                // }

            }).catch(reason => {
                Swal.fire({
                    icon: 'error',
                    title: 'Mua hàng Thất bại',
                    text: reason,
                }).then(() => {
                    // navigate('/orders');
                    // clearCart();
                });
            })
        }
    }
    const checkout = async (model) => {
        try {
            setLoading(true);
            return await http.post('/Product/Purchase', model);
        } finally {
            setLoading(false);
        }
    }
    const handleChangePromoteItem = (productItemID, accuratePrice, promoteCode, type) => {
        const updatedState = [...cart];
        let salePrice = "";

        // Tìm sản phẩm cần cập nhật
        const itemToUpdate = updatedState.find(shop => {
            return shop.items.some(item => item.productItemID === productItemID);
        });
        // Nếu tìm thấy sản phẩm, cập nhật giá
        if (itemToUpdate) {
            itemToUpdate.items.forEach(item => {
                if (item.productItemID === productItemID) {
                    item.promoteCode = promoteCode;
                    if (item.oldPrice == -1) {
                        item.newPrice = accuratePrice;
                        // item.oldPrice = accuratePrice;
                    } else {
                        // const saleprice=item.oldPrice*(100-accuratePrice)/100;
                        item.newPrice = accuratePrice;
                    }
                }
            });

            const c = updatedState;

            setCart(updatedState);
            // Cập nhật trạng thái
            // setCart(...cart,{ s: updatedState });
        }
    }

    function hasInvalidOldPrice(data) {
        for (const product of data) {
            if (product.oldPrice == -1)
                return true;
        }

        return false;
    }

    const handleCheckValidate = (data) => {

        const s = checkValidate(data);
        setIsValidate(s);
    }
    const checkValidate = (data) => {
        if (balance < totalPrice.totalNewPrice) {
            return false;
        }
        if (!data && data.length <= 0) {

            return false;
        }
        if (hasInvalidOldPrice(data)) {

            return false
        }
        return true;
    }
    const handleAddMoney = () => {

        const pr=totalPrice.totalNewPrice-balance;
        if(pr>0){
            http.get(`/Transaction/GetQRRechargeByMoney?Money=${pr}`).then(response => {
                setIsOpenQR(true);
                setQrContent(response.object);
                // response.data;
            })
        }
    }


    return (
        <SpinCustom spinning={loading}>
            <main className="main main-primary">
                <div className="section-box">
                    <div className="breadcrumbs-div">
                        <div className="container">
                            <ul className="breadcrumb">
                                <li><a className="font-xs color-gray-500" href="./">Trang chủ</a></li>
                                <li><a className="font-xs color-gray-500" href="./danh-sach-san-pham">Danh Sách Sản
                                    Phẩm</a>
                                </li>
                                <li><a className="font-xs color-gray-1000" href="">Chi tiết giỏ hàng</a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                {
                    isDesktopOrLaptop && (
                        <section className="section-box shop-template">
                            <div className="container main-ck">
                                {/*cart*/}
                                <div className={"cart-ct col-md-8 col-12"} style={{marginBottom:12}}>
                                    <div className="header" style={{background:"none",border:"none",marginBottom:12}}>
                                        <div className={"head-tit"} >
                                            <h5>Giỏ Hàng</h5>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <div className="table--container">
                                            <table className="table">
                                                <thead >
                                                <tr>
                                                    <th scope="col-1">
                                                        {
                                                            (cart && cart.length > 0) ? (
                                                                <input style={{marginLeft:4}}
                                                                    type="checkbox" checked={checkTotal()}
                                                                       onChange={(event) => selectAllCart(event)}/>
                                                            ) : ""
                                                        }
                                                        {/*<input type="checkbox" checked={checkTotal()}*/}
                                                        {/*       onChange={(event) => selectAllCart(event)}/>*/}
                                                    </th>
                                                    <th scope="col-3">Tất cả
                                                        ({productSelected ? productSelected.length : 0} sản
                                                        phẩm)
                                                    </th>
                                                    <th scope="col-2">Đơn giá</th>
                                                    <th scope="col-3">Số lượng</th>
                                                    <th scope="col-2">Thành tiền</th>
                                                    <th scope="col-1">
                                                        {
                                                            (cart && cart.length > 0) ? (
                                                                <DeleteOutlined onClick={() => handleClearCart()}/>) : ""
                                                        }
                                                        {/*<DeleteOutlined onClick={()=>handleClearCart()}/>*/}
                                                    </th>
                                                </tr>
                                                <tr className={"blank"}>
                                                    <td style={{border:"none"}}></td>
                                                    <td style={{border:"none"}}></td>
                                                    <td style={{border:"none"}}></td>
                                                    <td style={{border:"none"}}></td>
                                                    <td style={{border:"none"}}></td>
                                                    <td style={{border:"none"}}></td>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    cart.map((item, index) => (
                                                        <React.Fragment key={item.productItemID}>
                                                            <tr>
                                                                <td>
                                                                    <input className={"checkbox-shop"}
                                                                           type="checkbox" checked={checkShopSelected(item)}
                                                                           onChange={(event) => {
                                                                               handleSelectedShop(item, event)
                                                                           }}/>
                                                                </td>
                                                                <th>
                                                                    <div className={"shop-name--container"}>
                                                                        <HomeOutlined/>
                                                                        <p
                                                                           className={"shop-name--title"}
                                                                           style={{cursor:"pointer"}}
                                                                            onClick={()=>{
                                                                                navigate(ROUTER.PRODUCT_SHOP+`?shopID=${item.items[0].shopID}`)
                                                                            }}
                                                                        >{item.shopName}
                                                                        </p>
                                                                        <RightOutlined className={'right-icon'}/>
                                                                    </div>
                                                                </th>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>

                                                            <CartItem cartItem={item}
                                                                      handleSelectedProduct={handleSelectedProduct}
                                                                      productSelected={productSelected}
                                                                      handleUpdateCart={handleUpdate}
                                                                      handleChangePromoteItem={handleChangePromoteItem}
                                                            />
                                                        </React.Fragment>
                                                    ))
                                                }
                                                <tr>

                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {/*total */}
                                    <div className="total-container">
                                        <div className={"col-4"}></div>
                                        <div className="col-8 container price-total-container">
                                            <div className={"contai_t_T"}>
                                                <p className={"total-title "}>Tổng tiền</p>
                                                <div className={"price-total-container prtt"}>
                                                    {
                                                        (totalPrice.totalOldPrice == totalPrice.totalNewPrice) ? (
                                                                <div
                                                                    className={'price price-active price-active-main'}>{formatCurrency(totalPrice.totalOldPrice)}
                                                                </div>
                                                            ) :
                                                            (
                                                                <>
                                                                    <div
                                                                        className={'price price-active price-active-main'}>{formatCurrency(totalPrice.totalNewPrice)}
                                                                    </div>
                                                                    {
                                                                        totalPrice.totalOldPrice > 0 && (
                                                                            <div className={"price"}>
                                                                                <del>{formatCurrency(totalPrice.totalOldPrice)}</del>
                                                                            </div>
                                                                        )
                                                                    }
                                                                </>
                                                            )
                                                    }
                                                </div>
                                            </div>
                                            <Button type="primary button-submit"
                                                    style={{height: "46px", width: "150px",fontSize:15,background:'#DD5656'}}
                                                    danger
                                                    // disabled={!isValidate}
                                                    onClick={() => {
                                                handleCheckout()
                                            }}>
                                                Mua hàng{productSelected&&productSelected.length>0? ` (${productSelected.length})` : ""}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                {/*checkout*/}
                                <div className={"cart-contact col-md-4 col-12"} style={{marginTop:64}}>
                                    <div className={"header t__t"}>
                                        <h5>
                                            Thanh toán
                                        </h5>
                                    </div>
                                    <div className={"content-pay"}>
                                        <div className={"item-payment__infor col-12 total-border"}>
                                            <h6>Tổng giá trị sản phẩm</h6>
                                            <div
                                                className={'price price-active price-active-main'}>{formatCurrency(totalPrice.totalOldPrice)}
                                            </div>
                                            {/*<p>{totalPrice.totalOldPrice}</p>*/}
                                        </div>
                                        <div className={"item-payment__infor col-12"}>
                                            <p>Khuyến mãi</p>

                                            <p>{formatCurrency(totalPrice.totalOldPrice - totalPrice.totalNewPrice)}</p>
                                        </div>
                                        <div className={"item-payment__infor col-12"}>
                                            <p>Tổng giá trị phải thanh toán</p>
                                            <p style={{color: 'red'}}>{formatCurrency(totalPrice.totalNewPrice)}</p>
                                        </div>
                                        <div className={"item-payment__infor col-12"}>
                                            <p>Số dư hiện tại</p>
                                            <p>{formatCurrency(balance)}</p>
                                        </div>
                                        {
                                            balance - totalPrice.totalNewPrice < 0 &&
                                            (
                                                <>
                                                    <div className={"item-payment__infor col-12"}>
                                                        <p>Số tiền cần nạp thêm</p>
                                                        <p>{formatCurrency(Math.abs(balance - totalPrice.totalNewPrice))}</p>
                                                    </div>
                                                    {/* <div className={"item-payment__infor col-12"}>
                                                        <Button type="primary" primary onClick={() => {
                                                            // handleAddMoney()
                                                            setOpenOptionPayment(true)
                                                        }}>
                                                            Nạp thêm tiền
                                                        </Button>
                                                    </div> */}
                                                    <div style={{direction: 'rtl'}}>
                                                        <div className="payment-method" onClick={() => setOpenOptionPayment(true)}>
                                                            <SvgIcon name="ic-payment" className="txt-payment"/>
                                                            <span className="txt-payment">Nạp thêm</span>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>

                        </section>
                    )

                }
                {
                    isTabletOrMobile && (
                        <section className="section-box shop-template">
                            <div className="container main-ck main-ck__mobile">
                                {/*cart*/}
                                <div className={"cart-ct col-12"}>
                                    <div className="header">
                                        <h5>Giỏ hàng</h5>
                                    </div>
                                    <div className="content">
                                        <div className="table--container">
                                            <table className="table">
                                                <thead>
                                                <tr>
                                                    <th scope="col-1">
                                                        {
                                                            (cart && cart.length > 0) ? (
                                                                <input style={{marginLeft:4}} type="checkbox" checked={checkTotal()}
                                                                       onChange={(event) => selectAllCart(event)}/>
                                                            ) : ""
                                                        }
                                                        {/*<input type="checkbox" checked={checkTotal()}*/}
                                                        {/*       onChange={(event) => selectAllCart(event)}/>*/}
                                                    </th>
                                                    <th scope="col-3">Tất cả
                                                        ({productSelected ? productSelected.length : 0} sản
                                                        phẩm)
                                                    </th>
                                                    <th scope="col-2">Đơn giá</th>
                                                    <th scope="col-3">Số lượng</th>
                                                    <th scope="col-2">Thành tiền</th>
                                                    <th scope="col-1">
                                                        {
                                                            (cart && cart.length > 0) ? (
                                                                <DeleteOutlined onClick={() => handleClearCart()}/>) : ""
                                                        }
                                                        {/*<DeleteOutlined onClick={()=>handleClearCart()}/>*/}
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    cart.map((item, index) => (
                                                        <React.Fragment key={item.pro}>
                                                            <tr>
                                                                <td>
                                                                    <input className={"checkbox-shop"}
                                                                           type="checkbox" checked={checkShopSelected(item)}
                                                                           onChange={(event) => {
                                                                               handleSelectedShop(item, event)
                                                                           }}/>
                                                                </td>
                                                                <th>
                                                                    <div className={"shop-name--container"}>
                                                                        <HomeOutlined/>
                                                                        <p className={"shop-name--title"}
                                                                           style={{cursor:"pointer"}}
                                                                           onClick={()=>navigate(ROUTER.PRODUCT_SHOP+`?shopID=${item.items[0].shopID}`)}
                                                                        >{item.shopName}</p>
                                                                        <RightOutlined className={'right-icon'}/>
                                                                    </div>
                                                                </th>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>

                                                            <CartItem cartItem={item}
                                                                      handleSelectedProduct={handleSelectedProduct}
                                                                      productSelected={productSelected}
                                                                      handleUpdateCart={handleUpdate}
                                                                      handleChangePromoteItem={handleChangePromoteItem}
                                                            />
                                                        </React.Fragment>
                                                    ))
                                                }
                                                <tr>

                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {/*total */}
                                    <div className="total-container">
                                        <div className={"col-4"}></div>
                                        <div className="col-8 container price-total-container">
                                            <div>
                                                <p className={"total-title"}>Tổng tiền</p>
                                            </div>
                                            <div className={"price-total-container"}>
                                                {
                                                    (totalPrice.totalOldPrice == totalPrice.totalNewPrice) ? (
                                                            <div
                                                                className={'price price-active price-active-main'}>{formatCurrency(totalPrice.totalOldPrice)}
                                                            </div>
                                                        ) :
                                                        (
                                                            <>
                                                                <div
                                                                    className={'price price-active price-active-main'}>{formatCurrency(totalPrice.totalNewPrice)}
                                                                </div>
                                                                {
                                                                    totalPrice.totalOldPrice > 0 && (
                                                                        <div className={"price"}>
                                                                            <del>{formatCurrency(totalPrice.totalOldPrice)}</del>
                                                                        </div>
                                                                    )
                                                                }
                                                            </>
                                                        )
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/*checkout*/}
                                <div className={"cart-contact col-12"}>
                                    <div style={{marginBottom: "15px"}} className={"header"}>
                                        <h5>
                                            Thanh toán
                                        </h5>
                                    </div>
                                    <div className={"content-pay"}>
                                        <div className={"item-payment__infor col-12 total-border"}>
                                            <h6>Tổng giá trị sản phẩm</h6>
                                            <div
                                                className={'price price-active price-active-main'}>{formatCurrency(totalPrice.totalOldPrice)}
                                            </div>
                                            {/*<p>{totalPrice.totalOldPrice}</p>*/}
                                        </div>
                                        <div className={"item-payment__infor col-12"}>
                                            <p>Khuyến mãi</p>

                                            <p>{formatCurrency(totalPrice.totalOldPrice - totalPrice.totalNewPrice)}</p>
                                        </div>
                                        <div className={"item-payment__infor col-12"}>
                                            <p>Tổng giá trị phải thanh toán</p>
                                            <p style={{color: 'red'}}>{formatCurrency(totalPrice.totalNewPrice)}</p>
                                        </div>
                                        <div className={"item-payment__infor col-12"}>
                                            <p>Số dư hiện tại</p>
                                            <p>{formatCurrency(balance)}</p>
                                        </div>
                                        {
                                            balance - totalPrice.totalNewPrice < 0 &&
                                            (
                                                <>
                                                    <div className={"item-payment__infor col-12"}>
                                                        <p>Số tiền cần nạp thêm</p>
                                                        <p>{formatCurrency(Math.abs(balance - totalPrice.totalNewPrice))}</p>
                                                    </div>
                                                    {/* <div className={"item-payment__infor col-12"}>
                                                        <Button type="primary" primary onClick={() => {
                                                            // handleAddMoney()
                                                            setOpenOptionPayment(true)
                                                        }}>
                                                            Nạp thêm tiền
                                                        </Button>
                                                    </div> */}
                                                    <div style={{direction: 'rtl', paddingBottom: '8px'}}>
                                                        <div className="payment-method" onClick={() => setOpenOptionPayment(true)}>
                                                            <SvgIcon name="ic-payment" className="txt-payment"/>
                                                            <span className="txt-payment">Nạp thêm</span>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                        <div className={"price-total-container"}>
                                            <Button type="primary button-submit" danger
                                                    onClick={() => {
                                                        handleCheckout()
                                                    }}>
                                                Mua hàng
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </section>
                    )

                }
                {/* {
                    isOpenQR && (
                        <PaymentModal onCancel={() => setIsOpenQR(false)}
                                      open={isOpenQR}
                                      qr_html={qrContent}
                        />
                    )
                } */}
                { !!openOptionPayment &&
                    <PaymentMethods
                    onCancel={() => {
                        setOpenOptionPayment(false);
                    }}
                    onOk={() => {}}
                    open={openOptionPayment}
                    money={totalPrice.totalNewPrice - balance}
                    />
                }
            </main>
        </SpinCustom>
    )
}

