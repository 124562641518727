import {Col, DatePicker, Form, Input, Row, Select, Tooltip} from "antd"
import {useSelector} from "react-redux"
import FlInput from "src/components/FloatingLabel/Input"
import FlSelect from "src/components/FloatingLabel/Select"
import {MODE_ACCESS_COMPANY_USER, MODE_SHOWPOPUP_COMPANY, ORDER_STATUS, RELATTION_STATUS} from "src/constants/constants"
import {SearchStyled} from "../../Production/styled";
import dayjs from "dayjs";
import SvgIcon from "../../../../components/SvgIcon";
import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCakeCandles} from "@fortawesome/free-solid-svg-icons";
import http from "../../../../services";
import RegionService from "../../../../services/RegionService";
import Button from "../../../../components/MyButton/Button";

export const SearchCompany = ({onChangeKeyword, pagination, handleFilter}) => {
    const {Option} = Select
    const [listDistrict, setListDistrict] = useState([]);
    const [listWard, setListWard] = useState([]);

    const sortItems: TabsProps['sortitems'] = [
        {
            key: 0,
            label: 'Không',
        },
        {
            key: 1,
            label: 'Ngày tạo tăng dần',
        },
        {
            key: 2,
            label: 'Ngày tạo giảm dần',
        }
    ];
    const sortStatus: TabsProps['sortitems'] = [
        // {
        //     key: 0,
        //     label: 'Tất cả',
        // },
        {
            key: 1,
            label: 'Đang hoạt động',
        },
        {
            key: 2,
            label: 'Ngừng hoạt động',
        }
    ];
    const relationOption: TabsProps['sortitems'] = [
        {
            key: 0,
            label: 'Tất cả',
        },
        {
            key: RELATTION_STATUS.KHACH_HANG_MOI,
            label: 'Khách hàng mới',
        },
        {
            key: RELATTION_STATUS.KHACH_HANG_TIEP_CAN,
            label: 'Khách hàng đã tiếp cận sản phẩm',
        },
        {
            key: RELATTION_STATUS.KHACH_HANG_TIEM_NANG,
            label: 'Khách hàng tiềm năng',
        },
        {
            key: RELATTION_STATUS.KHACH_HANG_THAN_THIET,
            label: 'khách hàng thân thiết',
        },
    ];
    const [isBirthDay, setIsBirthDay] = useState(false);
    const [numbirthDay, setNumbirthDay] = useState(0);
    const [listProvince, setListProvince] = useState([]);

    useEffect(() => {
        setupBirthDayInfor();
    }, []);
    const setupBirthDayInfor=()=>{
        const model={
            "pageSize": 1000,
            "currentPage": 1,
            "textSearch": "",
            "gender": 0,
            "sortBy": 0,
            "isBirthdayToday": 1,
            "relationshipStatus":0
        }
        http.post('/ManageCustomer/GetListCustomer',model)
            .then(res => {
                if (!res?.isError) {
                    setNumbirthDay(res?.object?.list?.length||0);
                }

            })
    }

    const [form] = Form.useForm();
    const getListProvinceVN = () => {
        RegionService.getByRegionId({ regionId: 234 })
            .then(res => {
                if (!res?.isError){
                    setListProvince(res?.object);
                }
            })
            .finally()
    }

    const loadListDistrict=(newValue)=>{
        if(newValue&&newValue!==0){
            RegionService.getByRegionId({ regionId: newValue })
                .then(res => {
                    if (res?.isError) return
                    const lstDistrict = res?.object||[];
                    setListDistrict(lstDistrict)
                })
        }
    }
    const onChangeProvince = newValue => {
        handleFilter({...pagination, provinceID: newValue})
        form.resetFields([`districtID`]);
        form.resetFields([`wardID`]);
        if (!newValue) {
            setListDistrict([]);
            return;
        }

        RegionService.getByRegionId({ regionId: newValue })
            .then(res => {

                if (res?.isError) return
                const lstDistrict = res?.object||[];
                setListDistrict(lstDistrict)
            })

    };
    const onChangeDistrict = e => {
        handleFilter({...pagination, districtID: e})
        form.resetFields([`wardID`])
        if (!e) return setListWard([])
        RegionService.getByRegionId({ regionId: e })
            .then(res => {
                if (res?.isError) return
                const lstWard = res?.object||[];
                setListWard(lstWard);
            })
    }
    const onChangeWard = e => {
        handleFilter({...pagination, wardID: e})
        // setWardSelected(e);
    }
    useState(() => {
        getListProvinceVN();
    },[]);

    return (
        <SearchStyled>
            <Form form={form} layout="vertical" style={{width: "100%"}}>
            <Row gutter={[16, 16]} style={{marginBottom:32}}>

                <Col lg={5} xs={24}>
                    <FlInput
                        search
                        allowClear
                        label="Tìm kiếm"
                        onSearch={e => onChangeKeyword(e)}
                    />
                </Col>
                <Col lg={3} xs={24} >
                    <FlSelect
                        label="Mối quan hệ"
                        onChange={(e) => {
                            handleFilter({...pagination, relationshipStatus: e})
                        }}
                        value={pagination.relationshipStatus}
                    >
                        {
                            relationOption?.map(i => (
                                <Option key={i.key} value={i.key}>
                                    {i?.label}
                                </Option>
                            ))
                        }
                    </FlSelect>
                </Col>
                <Col lg={4} xs={16}>
                    <FlSelect
                        value={pagination.sttatus}
                        label="Trạng thái"
                        onChange={(e) => {
                            handleFilter({...pagination, sttatus: e})
                        }}
                    >
                        {
                            sortStatus?.map(i => (
                                <Option key={i.key} value={i.key}>
                                    {i?.label}
                                </Option>
                            ))
                        }
                    </FlSelect>
                </Col>
                <Col lg={3} xs={16}>
                    <FlSelect
                        value={pagination.sortBy}
                        label="Sắp xếp"
                        onChange={(e) => {
                            handleFilter({...pagination, sortBy: e})
                        }}
                    >
                        {
                            sortItems?.map(i => (
                                <Option key={i.key} value={i.key}>
                                    {i?.label}
                                </Option>
                            ))
                        }
                    </FlSelect>
                </Col>
                <Col lg={3} xs={8}>
                    <Form.Item
                        // label="Tỉnh/Thành phố"
                        name="provinceID"
                    >
                        <FlSelect
                            // placeholder="-- Chọn Tỉnh/Thành phố --"
                            allowClear
                            onChange={onChangeProvince}
                            label="Tỉnh/Thành phố"
                        >
                            {
                                listProvince.map((province)=>(
                                    <Option key={province.regionID} value={province.regionID}>{province.regionName}</Option>
                                ))
                            }
                        </FlSelect>
                    </Form.Item>
                </Col>
                <Col  lg={3} xs={8}>
                    <Form.Item
                        // label="Quận/Huyện"
                        name="districtID"
                    >
                        <FlSelect
                            label="Quận/Huyện"
                            // placeholder="-- Chọn Quận/Huyện --"
                            allowClear
                            onChange={onChangeDistrict}
                            notFoundContent="Bạn cần chọn Tỉnh/Thành phố"

                        >
                            {
                                listDistrict.map((district)=>(
                                    <Option key={district.regionID} value={district.regionID}>{district.regionName}</Option>
                                ))
                            }
                        </FlSelect>
                    </Form.Item>
                </Col>
                <Col  lg={3} xs={8}>
                    <Form.Item
                        // label="Phường/Xã"
                        name="wardID"
                    >
                        <FlSelect
                            label="Phường/Xã"
                            // placeholder="-- Chọn Phường/Xã --"
                            allowClear
                            onChange={onChangeWard}
                            notFoundContent="Bạn cần chọn Quận/Huyện"
                        >
                            {
                                listWard.map((district)=>(
                                    <Option key={district.regionID} value={district.regionID}>{district.regionName}</Option>
                                ))
                            }
                        </FlSelect>
                    </Form.Item>
                </Col>
            </Row>
                {/*<Row gutter={[16]}>*/}
                {/*    <Col lg={8} xs={8}>*/}
                {/*        <Form.Item*/}
                {/*            // label="Tỉnh/Thành phố"*/}
                {/*            name="provinceID"*/}
                {/*        >*/}
                {/*            <FlSelect*/}
                {/*                // placeholder="-- Chọn Tỉnh/Thành phố --"*/}
                {/*                allowClear*/}
                {/*                onChange={onChangeProvince}*/}
                {/*                label="Tỉnh/Thành phố"*/}
                {/*            >*/}
                {/*                {*/}
                {/*                    listProvince.map((province)=>(*/}
                {/*                        <Option key={province.regionID} value={province.regionID}>{province.regionName}</Option>*/}
                {/*                    ))*/}
                {/*                }*/}
                {/*            </FlSelect>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*    <Col  lg={9} xs={8}>*/}
                {/*        <Form.Item*/}
                {/*            // label="Quận/Huyện"*/}
                {/*            name="districtID"*/}
                {/*        >*/}
                {/*            <FlSelect*/}
                {/*                label="Quận/Huyện"*/}
                {/*                // placeholder="-- Chọn Quận/Huyện --"*/}
                {/*                allowClear*/}
                {/*                onChange={onChangeDistrict}*/}
                {/*                notFoundContent="Bạn cần chọn Tỉnh/Thành phố"*/}

                {/*            >*/}
                {/*                {*/}
                {/*                    listDistrict.map((district)=>(*/}
                {/*                        <Option key={district.regionID} value={district.regionID}>{district.regionName}</Option>*/}
                {/*                    ))*/}
                {/*                }*/}
                {/*            </FlSelect>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*    <Col  lg={7} xs={8}>*/}
                {/*        <Form.Item*/}
                {/*            // label="Phường/Xã"*/}
                {/*            name="wardID"*/}
                {/*        >*/}
                {/*            <FlSelect*/}
                {/*                label="Phường/Xã"*/}
                {/*                // placeholder="-- Chọn Phường/Xã --"*/}
                {/*                allowClear*/}
                {/*                onChange={onChangeWard}*/}
                {/*                notFoundContent="Bạn cần chọn Quận/Huyện"*/}
                {/*            >*/}
                {/*                {*/}
                {/*                    listWard.map((district)=>(*/}
                {/*                        <Option key={district.regionID} value={district.regionID}>{district.regionName}</Option>*/}
                {/*                    ))*/}
                {/*                }*/}
                {/*            </FlSelect>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*</Row>*/}

            </Form>
        </SearchStyled>
    )
}
