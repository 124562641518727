import React, {useEffect, useRef, useState} from 'react'
import OrdersService from '../../../../services/OrdersService'
import {Button, Input, Space, Tabs} from 'antd';
import './orderList.css'
import {OrderPreview} from "./orderPreview";
import {ORDER_STATUS} from "../../../../constants/constants";
import {AudioOutlined} from "@ant-design/icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBan} from "@fortawesome/free-solid-svg-icons";
import SpinCustom from "../../../../components/Spin";

export const OrdersList = () => {
    const [orders, setOrders] = useState([])
    const url = window.location.href;
    const [statusSlected, setStatusselected] = useState(0);
    const [loading, setLoading] = useState(0);
    const totalOrder = useRef(0);

    const [pagination, setPagination] = useState({
        pageSize: 5,
        currentPage: 1,
        status: 0,
        keyword: ""
    })
    const getListOrders = () => {
        setLoading(true);
        OrdersService.listOrders({
            ...pagination,
        })
            .then(res => {
                if (res?.isError) {
                    setLoading(false)
                    return
                }
                totalOrder.current = res?.object.count
                setOrders([...orders, ...res?.object?.list])

            }).finally(() => {
            setLoading(false);
        })
    }
    const updateOrder = (orderId, updatedOrder) => {
        // console.log('od');
        // setLoading(false);
        // setOrders((prevOrder) =>
        //     prevOrder.map((order) =>
        //         (order.orderId === orderId ? updatedOrder : order)
        //     )
        // );
       getListOrders();
    };

    /**
     * @description set title tab web
     * @author hddung
     */
    useEffect(() => {
        if(url) {
            document.title = 'Đơn hàng đã mua'; // Thay đổi title thành 'New Title'
        
            return () => {
            document.title = 'TẠP HÓA SỐ'; // Đặt lại title khi component bị unmount
            };
        }
    }, [url]);

    useEffect(() => {
        getListOrders();
    }, [pagination]);

    useEffect(() => {
       setLoading(false);
    }, [orders]);

    // useEffect(() => {
    //     window.addEventListener('scroll', handleScroll);
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);


    const onChangeTab = (key: string) => {
        setOrders([]);
        const newPagi = {...pagination, status: key, currentPage: 1};
        setPagination(newPagi);
    };

    const onChangeKeyword = (key: string) => {
        console.log(key);
        setOrders([]);
        const newPagi = {...pagination, keyword: key, currentPage: 1};
        setPagination(newPagi);
    }

    const items: TabsProps['items'] = [
        {
            key: 0,
            label: 'Tất cả',
        },
        {
            key: ORDER_STATUS.CHO_XAC_NHAN,
            label: 'Chờ xác nhận',
        },
        {
            key: ORDER_STATUS.CHO_KY,
            label: 'Chờ ký'
        },
        {
            key: ORDER_STATUS.HOAN_THANH,
            label: 'Hoàn thành',
        },
        {
            key: ORDER_STATUS.DA_HUY,
            label: 'Đã Huỷ',
        }
        // {
        //     key: ORDER_STATUS.DA_DANH_GIA,
        //     label: 'Đã đánh giá',
        // }
    ];
    const {Search} = Input;

    //scoll
    const loadMoreOrders = () => {
        const newPage = pagination.currentPage + 1;
        setPagination({...pagination, currentPage: newPage});
    };
    // const handleScroll = () => {
    //     // Tính toán khoảng cách cuối trang và vị trí hiện tại của trình duyệt
    //     const scrollY = window.scrollY;
    //     const windowHeight = window.innerHeight;
    //     const bodyHeight = document.body.offsetHeight;
    //
    //     if (scrollY + windowHeight >= bodyHeight - 200) {
    //         // Nếu cuộn xuống gần cuối trang, tải thêm đơn hàng
    //         loadMoreOrders();
    //     }
    // };
    const isLoadMore = () => {

        return totalOrder.current > orders.length;
    }
    const suffix = (
        <AudioOutlined
            style={{
                fontSize: 16,
                color: '#1890ff',
            }}
        />
    );
    return (
        <>
            <SpinCustom spinning={loading}>
                <div className={"container page-container"}>
                    <div className={"main-nav__content"}>
                        <div className={"tablayout-container"}>
                            <Tabs defaultActiveKey={statusSlected} items={items} onChange={onChangeTab}/>
                        </div>
                        <div>
                            {/*<Space direction="vertical">*/}
                            {/*<Search*/}
                            {/*    // className={"search-bar__container"}*/}
                            {/*    // style={{marginBottom: 8}}*/}
                            {/*    placeholder="Search"*/}
                            {/*    onSearch={onChangeKeyword}*/}
                            {/*/>*/}
                            <Search

                                placeholder="Nhập tên đơn hàng"
                                // enterButton="Search"
                                size="large"
                                allowClear
                                className={"col-md-10 col-12"}
                                // suffix={suffix}
                                onSearch={onChangeKeyword}
                            />
                            {/*</Space>*/}

                        </div>
                    </div>

                    <div className={"content-orders "}>
                        {(orders || orders.length > 0) &&
                            orders.map((item, index) => (
                                <OrderPreview order={item} key={item?.orderId} refreshData={updateOrder}
                                              setLoading={setLoading}/>
                            ))
                        }
                        {(!orders || orders.length < 1) &&
                            (
                                <div className={"d-flex-center"}>
                                    <h5 style={{textAlign: "center", marginBottom: 0}}>Không có đơn hàng</h5>
                                </div>
                            )
                        }
                    </div>
                    {
                        isLoadMore() ? (
                            <div className={"load-more__container"}>
                                <Button onClick={() => loadMoreOrders()}>
                                    Xem thêm
                                </Button>
                            </div>

                        ) : ""
                    }

                </div>

            </SpinCustom>
        </>


    )


}
