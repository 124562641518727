// import '../../../assets/css/product/ProductShopList.css';
import '../ProductShopList/ProductShopList.css'
import React, {PureComponent} from 'react';
import {useState} from 'react';
import {useEffect, componentDidMount} from 'react';
import {Button} from 'antd';
import {WechatOutlined} from '@ant-design/icons';
import SearchMenu from '../../../components/Layouts/component/SearchMenu';
import {ProductList} from '../ProductList';
import {Spin} from "antd";
import {useSelector, useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import ROUTER from "src/router"
import {setChatUserInfor} from "../../../redux/appGlobal";
import GuestService from "src/services/GuestService"
import SearchBar from "./SearchBar";
import {useMediaQuery} from "react-responsive";

function ProductShopList() {
    const url = window.location.href;
    const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'});
    const isTabletOrMobile = useMediaQuery({query: '(max-width: 1224px)'});
    const [product, setProduct] = useState([]);
    const [shopInfor, setShopInfor] = useState(null);
    const [requestBody, setRequestBody] = useState({
        pageSize: 10,
        currentPage: 1,
        textSearch: '',
        shopID: '',
        categoryID: '',
        sortBy: 0,
        priceType: 0,
        reviewType: 0,
    });
    const [showLoad, setShowLoad] = useState(true);
    const {shopUserID} = useSelector(state => state?.appGlobal);
    const navigate = useNavigate();
    const apiUrl = `Guest/GetListShopProducts`;
    const {userInfo} = useSelector(state => state?.appGlobal);
    const dispatch = useDispatch();

    /**
     * @description set title tab web
     * @author hddung
     */
    useEffect(() => {
        if(url) {
            return () => {
            document.title = 'TẠP HÓA SỐ'; // Đặt lại title khi component bị unmount
            };
        }
    }, [url]);

    /**
     * @description thực hiện việc show chat box
     * @author hddung
     */
    function showChatBox() {
        //Set biến redux global để hiển thị chatbox
        dispatch(setChatUserInfor(
            {
                userData: {
                    toUserId: shopInfor.shopUserID,
                    toUserName: shopInfor?.shopUsername,
                    roomId: shopInfor?.roomId,
                    avatar: shopInfor?.avatarShop
                },
                isShow: true
            }));
    }

    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                const queryParams = new URLSearchParams(window.location.search);
                requestBody.shopID = shopUserID || queryParams.get('shopID');
                const response = await GuestService.getListShopProducts(requestBody);
                if (response?.isError) return

                if (response?.object?.list?.length > 0 && response?.object?.shopinfor) {
                    setProduct(response?.object?.list); 
                    setShopInfor(response?.object?.shopinfor);
                    document.title = response?.object?.shopinfor?.shopUsername || 'TẠP HÓA SỐ'; // Thay đổi title thành 'New Title'
                    setShowLoad(false);
                }

            } catch (error) {
                console.error('Error fetching product details:', error);
            }
        };
        fetchProductDetails();
    }, [requestBody]);

    return (
        <main className="main">
            <div className="section-box">
                <div className="breadcrumbs-div">
                    <div className="container">
                        <ul className="breadcrumb">
                            <li><a className="font-xs color-gray-500" onClick={() => {
                                navigate(ROUTER.HOME)
                            }}>Trang chủ</a></li>
                            <li><a className="font-xs color-gray-1000" href="">Cửa hàng</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            {showLoad && product.length == 0 && !shopInfor
                ? <div className="ctn-spin-shop-infor"><Spin/></div>
                : (
                    <div className="ctn-product-shop-list">
                        <div className="ctn-product-infor-shop cpts container">
                            {
                                isDesktopOrLaptop && (
                                    <div className="ctn-layout">
                                        <div className="infor-right">
                                            <div className='shop-infor'>
                                                <img className='shop-img' src={shopInfor.avatarShop}/>
                                                <div style={{display: "flex", flexWrap: "nowrap"}}>

                                                    <div className='shop-name'>
                                                        <h5>{shopInfor.shopUsername}</h5>
                                                        <img
                                                            src="https://salt.tikicdn.com/ts/upload/5d/4c/f7/0261315e75127c2ff73efd7a1f1ffdf2.png"/>
                                                    </div>
                                                    <div className="btn-chat ">
                                                        <Button icon={<WechatOutlined/>} onClick={showChatBox}
                                                                disabled={userInfo?.userID && userInfo?.userID == shopInfor?.shopUserID}>Nhắn
                                                            tin</Button>
                                                    </div>
                                                </div>
                                                <div className='review-rate rvr'>
                                                    <p className={"p-tag ptg"}>{shopInfor.totalAverageReview}/5 <span
                                                        style={{color: '#fdc040'}}>★</span> <br></br> <span
                                                        style={{fontSize: '11px'}}>{shopInfor.totalQuantityReview} Đánh giá</span>
                                                    </p>
                                                    <p className={"p-tag ptg"}> {shopInfor.totalSold}<br></br><span
                                                        style={{fontSize: '11px'}}> Đã bán</span></p>
                                                </div>
                                            </div>


                                        </div>
                                        <div className="line"></div>
                                        <div className="infor-left">
                                            <SearchBar navigatePath={`/chi-tiet-cua-hang?shopID=${shopInfor.shopUserID}`}
                                                       txtLabel={'Tìm kiếm sản phẩm shop'}/>
                                        </div>
                                    </div>

                                )
                            }
                            {
                                isTabletOrMobile && (
                                    <div className="ctn-layout ctdre">
                                        <div className="infor-right col-12">
                                            <div className='shop-infor col-12 ctnsdf'>
                                                <div className={"d-flex col-12 col-md-7"}>

                                                    <img className='shop-img' src={shopInfor.avatarShop}/>
                                                    <div style={{display: "flex", flexWrap: "wrap"}}>

                                                        <div className='shop-name'>
                                                            <h5>{shopInfor.shopUsername}</h5>
                                                            <img
                                                                src="https://salt.tikicdn.com/ts/upload/5d/4c/f7/0261315e75127c2ff73efd7a1f1ffdf2.png"/>
                                                        </div>
                                                        <div className="btn-chat btn-ctsf">
                                                            <Button icon={<WechatOutlined/>} onClick={showChatBox}
                                                                    disabled={userInfo?.userID && userInfo?.userID == shopInfor?.shopUserID}>Nhắn
                                                                tin</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='review-rate col-12 col-md-5'>
                                                    <p className={"p-tag"}>{shopInfor.totalAverageReview}/5 <span
                                                        style={{color: '#fdc040'}}>★</span> <br></br> <span
                                                        style={{fontSize: '11px'}}>{shopInfor.totalQuantityReview} Đánh giá</span>
                                                    </p>
                                                    <p className={"p-tag"}> {shopInfor.totalSold}<br></br><span
                                                        style={{fontSize: '11px'}}> Đã bán</span></p>
                                                </div>
                                            </div>


                                        </div>
                                        <div className="infor-left col-12">
                                            <SearchBar navigatePath={`/chi-tiet-cua-hang?shopID=${shopInfor.shopUserID}`}
                                                       txtLabel={'Tìm kiếm sản phẩm shop'}/>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <div className="ctn-product-content-list">
                            {!showLoad && <ProductList urlAPI={apiUrl} requestDataBody={requestBody} titleTab={shopInfor?.shopUsername}/>}
                        </div>
                    </div>
                )
            }

        </main>
    );

}

export default ProductShopList
