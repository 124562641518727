import styled from "styled-components";

export const Category = styled.div`
    width: 100%;
    border: 2px solid #dbd6d6;
    .header{
        padding: 0px 30px;
        height: 50px;
        line-height: 50px;
        font-size: 18px;
        font-family: sans-serif;
        font-weight: 500;
        color: #605e5ec7;
    }
    .item{
         height: 50px;
        display: flex;
        align-items: center;
        padding-left: 60px;
        cursor: pointer;
        .item-name{
                    font-family: sans-serif;
        font-weight: 500;
        }
        .item-number{
            aspect-ratio: 1 / 1;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: background-color .75s ease-out 100ms;

            
        }
        &:hover{
            color: orange;
            .item-name{
                font-size: 18px;
                padding-left: 3px;
            }
            .item-number{
                
                background-color: #ff7b00b5;
                color: white;
            }
        }
    }
`