import { Col, Form, Row } from "antd"
import { useState } from "react"
import CustomModal from "src/components/Modal/CustomModal"
import Button from "src/components/MyButton/Button"
import Notice from "src/components/Notice"
import SpinCustom from "src/components/Spin"
import AuthService from "src/services/AuthService"
import styled from "styled-components"
import StepModal1 from "./StepModal1"
import StepModal2 from "./StepModal2"
const CustomModalRegisterStyled = styled.div`
  .step-title {
    .step {
      text-transform: uppercase;
      font-weight: normal;
      font-size: 16px;
      color: #333;
      font-weight: 600;
    }
    .note {
      color: #4d4d4d;
      margin-bottom: 16px;
    }
  }
`
const RegisterEnterpriseModal = ({ open, handleCancel, roleID }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [activeKey, setActiveKey] = useState(1)

  const handlRegister = async () => {
    try {
      setLoading(true)
      const values = await form.validateFields()

      const res = await AuthService.businessRegister({
        ...values,
        ...activeKey,
        RequestEmail: activeKey?.Email,
      })
      if (res?.isError) return
      Notice({
        isSuccess: true,
        msg: "Đăng ký thành công!",
      })
      handleCancel()
    } finally {
      setLoading(false)
    }
  }
  const rederFooter = () => {
    return (
      <div className="d-flex-sb">
        <div>
          {activeKey !== 1 && (
            <Button
              loading={loading}
              className=""
              type="submit"
              htmlType="submit"
              onClick={() => setActiveKey(1)}
              btnType="third"
            >
              Quay lại
            </Button>
          )}
        </div>
        <div>
          <Button
            loading={loading}
            className=""
            type="submit"
            htmlType="submit"
            onClick={() => {
              if (activeKey === 1) {
                checkEmail()
              } else {
                handlRegister()
              }
            }}
            btnType="primary"
          >
            {"Tiếp" || "Đăng ký"}
          </Button>
        </div>
      </div>
    )
  }

  const checkEmail = async () => {
    try {
      setLoading(true)
      const value = await form.validateFields()
      const res = await AuthService.checkExistEmail({ email: value?.email })
      if (res?.isError) return
      // Notice({ msg: `Email chính xác !` })

      setActiveKey(value)
    } finally {
      setLoading(false)
    }
  }
  return (
    <CustomModal
      title="Đăng ký nhà tuyển dụng"
      width="1024px"
      footer={rederFooter()}
      open={open}
      onCancel={handleCancel}
      bodyStyle={{ height: "1000px" }}
    >
      <CustomModalRegisterStyled>
        <SpinCustom spinning={loading}>
          <Row gutter={[16, 0]} style={{ width: "100%" }}>
            <Col span={24}>
              {activeKey === 1 && (
                <StepModal1 setLoading={setLoading} form={form} />
              )}
              {!!activeKey && activeKey !== 1 && (
                <StepModal2 setLoading={setLoading} form={form} />
              )}
            </Col>
          </Row>
        </SpinCustom>
      </CustomModalRegisterStyled>
    </CustomModal>
  )
}

export default RegisterEnterpriseModal
