import {Anchor, Col, Divider, Row} from "antd";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import SpinCustom from "../../../../../components/Spin";
import {ListUserStyled} from "../../../Company/styled";
import TableCustom from "../../../../../components/Table/CustomTable";
import {format, parseISO} from "date-fns";
import {SearchSignature} from "../../components/SearchSignature";
import Button from "../../../../../components/MyButton/Button";
import {CreateSignatureModal} from "../../modal/CreateSignature";
import ButtonCircle from "../../../../../components/MyButton/ButtonCircle";
import CB1 from "../../../../../components/Modal/CB1";
import http from "../../../../../services";
import {FloatActionWrapper} from "../../../../../components/FloatAction/styles";
import Notice from "../../../../../components/Notice";
import {EditSignatureModal} from "../../modal/EditSignatureModal";
import ImageViewer from "../../modal/PreviewSig";


export const SignatureListTable = ({
                                       data,
                                       refreshData,
                                       totalPage,
                                       pagination,
                                       getOrder,
                                       onChangeKeyword,
                                       handleFilter
                                   }) => {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [itemSelected, setItemSelected] = useState();
    const handleShowCreate = () => {
        setShowCreateModal(true);
    }
    const handleCancelCreate = () => {
        setShowCreateModal(false);
    }
    const [dataBodySource, setDataBodySource] = useState({
        pageSize: 10,
        currentPage: 1,
    });



    const columns = [
        {
            title: "STT",
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
            width: 30,
        },
        {
            title: "Tên chữ ký",
            dataIndex: "signatureName",
            key: "signatureID",
            render: (value) =>
                (
                    <div>
                        {value}
                    </div>
                ),
            width: 200,
            align: "left",
        },
        {
            title: "Chữ ký",
            dataIndex: "signatureImage",
            key: "signatureImage",
            render: (value,signature) =>
                (
                    <>
                    {/*<div dangerouslySetInnerHTML={{ __html: `${value}` }} />*/}
                        <ImageViewer base64Image={value} />
                        <FloatActionWrapper size="small" className="float-action__wrapper" style={{background: "transparent"}}>
                            {/*{renderButtonViewer(contract)}*/}
                            {renderButton(signature)}
                        </FloatActionWrapper>
                    </>
                ),
            width: 200,
            align: "left",
        },

    ]
    const renderDeleteButton = (signature) => {
        if(signature?.isDelete){
        return  (
            <ButtonCircle
                title="Xóa mẫu chữ ký"
                iconName="cancel"
                style={{background: "#FFE9EC"}}
                onClick={() => {
                    CB1({
                        title: `Bạn có chắc chắn muốn xóa chữ ký này không?`,
                        icon: "warning-usb",
                        okText: "Đồng ý",
                        onOk: async close => {
                            const model={
                                "signatureID": signature.signatureID
                            }
                            http.post(`Contract/DeleteContractSignature`,model).then(resp => {
                                if(!resp?.isError){
                                    Notice({ msg: `Xóa chữ ký thành công!` });
                                    refreshData();
                                }
                            }).finally(() => {
                                close();
                            })
                        },
                    })
                }}
            />
        )
        }

    };
    const renderEditButton=(model)=>{
        if(model?.isUpdate){
        return(
            <ButtonCircle
                title="Cập nhật"
                iconName="edit"
                onClick={() => {
                        setItemSelected(model);
                        setTimeout(() => {
                        setShowEditModal(true);
                        },[500])
                }}
            />
        )
        }
    }
    const renderButton=(model)=>{
        return(
            <>
                {renderEditButton(model)}
                {renderDeleteButton(model)}
            </>
        )
    }
    return (
        <>
            <ListUserStyled>
                <div>
                    <SearchSignature
                        onChangeKeyword={onChangeKeyword}
                        pagination={pagination}
                        handleFilter={handleFilter}
                    />
                    <Divider className="mv-16"/>
                    <div
                        className="title-type-1 d-flex justify-content-space-between align-items-center pb-16 pt-0 mb-16">
                        <div className="fs-24">
                            Danh sách chữ kí
                            {/* ({dataSource?.total || 0}) : */}
                        </div>
                        <Row guter={[16, 16]}>
                            {(
                                <Col>
                                    {
                                        data?.isInsert&&(

                                        <Button
                                            btnType="primary"
                                            className="btn-hover-shadow"
                                            onClick={() => {
                                                handleShowCreate();
                                            }}
                                        >
                                            Thêm Chữ ký
                                        </Button>
                                        )
                                    }
                                </Col>
                            )}

                        </Row>
                    </div>
                </div>
                <Anchor
                    affix={false}
                    getContainer={() => document.getElementById("body-admin-scroll")}
                >
                    <Row gutter={[16, 16]}>
                        <Col style={{width: 0}} flex="auto">
                            <SpinCustom spinning={loading}>
                                <TableCustom
                                    isPrimary
                                    // onRow={record => {
                                    //     return {
                                    //         onClick: () => {
                                    //             setOpenModalUserDetail(record)
                                    //         },
                                    //     }
                                    // }}
                                    className="mb-6"
                                    dataSource={data?.listSignature}
                                    columns={columns}

                                    textEmpty="Không có kết quả"
                                    rowKey="signatureID"

                                    sticky={{offsetHeader: -12}}
                                    scroll={{y: "100%", x: "800px"}}
                                    pagination={{
                                        defaultPage: dataBodySource.pageSize,
                                        showSizeChanger: true,
                                        pageSizeOptions: ['10', '20', '30'],
                                        total: totalPage,
                                        // onChange: (page, pageSize) => {
                                        //     setDataBodySource((prevState) => ({
                                        //         ...prevState,
                                        //         pageSize: pageSize || 10,
                                        //         currentPage: page || 1
                                        //     }));
                                        // },
                                        // onChange: (page, pageSize) => {
                                        //     handleChangePage(page, pageSize);
                                        // }
                                    }}
                                />
                            </SpinCustom>
                        </Col>
                    </Row>
                </Anchor>

            </ListUserStyled>
            {

                showCreateModal && (
                    <CreateSignatureModal onCancel={() => handleCancelCreate()}
                                          open={() => handleShowCreate()}
                                          refreshData={refreshData}
                    />
                )
            }
            {

                showEditModal && (
                    <EditSignatureModal onCancel={() => setShowEditModal(false)}
                                          open={() => setShowEditModal(true)}
                                          refreshData={refreshData}
                                            data={itemSelected}
                    />
                )
            }
        </>

    )
}
