import { Col, Row, Select } from "antd"
import React from "react"
import FlInput from "src/components/FloatingLabel/Input"
import FlSelect from "src/components/FloatingLabel/Select"
import Button from "src/components/MyButton/Button"
import { SearchStyled } from "../styled"
import { SYSTEM_KEY } from "src/constants/constants"
import { getListComboByKey } from "src/lib/utils"
import { useSelector } from "react-redux"
import CreatePost from "src/pages/ADMIN/CreatePost/index"
import { useState } from "react"

const { Option } = Select

const Search = ({ onAdd, setPagination, setTextSearch, reloadTable, isInsert }) => {
  const { listSystemKey } = useSelector(state => state.appGlobal)
  const [openPopupPost, setOpenPopupPost] = useState(false);

  return (
    <SearchStyled>
      <Row gutter={[16, 16]}>
        <Col flex="auto">
          <FlInput
            search
            allowClear
            label="Nhập tiêu đề bài viết"
            onChange={e => setTextSearch(e?.target?.value)}
            onSearch={() => setPagination(pre => ({ ...pre, CurrentPage: 1 }))}
          />
        </Col>
        <Col lg={5} xs={24}>
          <FlSelect
            label="Trạng thái"
            allowClear
            onChange={Status =>
              setPagination(pre => ({ ...pre, CurrentPage: 1, Status }))
            }
          >
            <Option key={"allStarusTopic"} value={0}>
              Tất cả
            </Option>
            {getListComboByKey(SYSTEM_KEY?.POST_STATUS, listSystemKey)?.map(
              i => (
                <Option key={+i.codeValue} value={+i.codeValue}>
                  {i?.description}
                </Option>
              ),
            )}
          </FlSelect>
        </Col>

        {isInsert && (
          <Col>
            <Button
              btnType="primary"
              className="btn-hover-shadow"
              onClick={() => setOpenPopupPost(true)}
            >
              Thêm bài viết
            </Button>
          </Col>
        )}
      </Row>

      {
        openPopupPost && (
          <CreatePost
            open={openPopupPost}
            onOk={() => {}}
            reloadTable={reloadTable}
            onCancel={() => {
              setOpenPopupPost(false);
            }}
          />
        )
      }
    </SearchStyled>
  )
}

export default Search
