import { Carousel } from 'antd'
import React from 'react'

const slideStyle = {
    height: '470px',
}


export default function Slide({Data}) {
  return (
    <Carousel autoplay>
        {Data?.length > 0 && Data.map(x => 
                <div>
                    <div style={{
                        height:"570px",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundImage:"url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-k5GG-klIB-VbieUUrmWbpLy1MAsM7532xA&usqp=CAU)"
                    }}></div>  
                </div>  
        )}
    
    </Carousel>
  )
}
