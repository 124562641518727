import CustomModal from "../../../../components/Modal/CustomModal";
import SpinCustom from "../../../../components/Spin";
import {Col, Form, Input, Row, TreeSelect} from "antd";
import {STATUS_OPTION} from "../../../../constants/constants";
import Button from "../../../../components/MyButton/Button";

import TinyEditor from "../../../../components/TinyEditor";
import Notice from "../../../../components/Notice";
import http from "src/services"
import {useState} from "react";
export const AddNewCustomerResponseModal =({cancel,refreshData,open,userInfo})=>{
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();


    const renderFooter = () => (
        <div className="d-flex justify-content-flex-end">
            <Button
                className="btn-hover-shadow"
                onClick={() => cancel()}
            >
                Đóng
            </Button>
            <Button
                btnType="primary"
                className="btn-hover-shadow"
                onClick={handleSubmit}
            >
                Lưu
            </Button>
        </div>
    )
    const handleSubmit=()=>{
        form.validateFields().then(values => {
            const model={
                userID: userInfo?.userID,
                title: values.title,
                content: values.content,
                email: userInfo?.email
            }
           http.post("ManageCustomer/AddCustomerCare",model).then(resp=>{
               if(!resp?.isError){
                   Notice({ msg: "Thêm hoạt động khách hàng thành công !" });
                   refreshData();
                   cancel();
               }
           })
        }).catch(reason => {
            console.log(reason);
        });
        // refreshData();
    }

    return (
        <>
            <CustomModal
                title={"Thêm hoạt động khách hàng"}
                footer={renderFooter()}
                width={1000}
                open={open}
                style={{padding: '20px 20px 0px 10px'}}
            >
                <SpinCustom spinning={loading}>
                    <Form
                        form={form}
                        layout="vertical"
                    >
                        <Row gutter={[16, 16]} className="justify-content-center">
                            <Col span={24}>
                                <Form.Item
                                    label="Tiêu đề"
                                    name="title"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Thông tin không được để trống",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Nhập tiêu đề" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]} className="justify-content-center">
                            <Col span={24}>
                                <Form.Item className={"d-flex flex-column"}
                                           label="Nội dung"
                                           required={true}
                                           name="content"
                                           trigger="onEditorChange"
                                           validateTrigger={["onEditorChange"]}
                                           rules={[
                                               {
                                                   required: true,
                                                   message: "Nội dung không được để trống",
                                               },
                                           ]}

                                >
                                    <TinyEditor setLoading={setLoading} height={'200px'}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </SpinCustom>
            </CustomModal>
        </>
    )
}
