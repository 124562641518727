import http from "../index"
import { 
    apiGetAllForAdmin,
    apiGetAllCategory,
    apiGetAllCategoryForAdmin,
    apiInsertCategory,
    apiUpdateCategory,
    apiDeleteCategory
 } from "./urls"

const getAllforAdmin = body => http.post(apiGetAllForAdmin, body);
const getAllCategory = () => http.get(apiGetAllCategory);
const getAllCategoryForAdmin = () => http.post(apiGetAllCategoryForAdmin);

const insertCategory = body => http.post(apiInsertCategory, body);
const updateCategory = body => http.post(apiUpdateCategory, body);
const deleteCategory = body => http.post(apiDeleteCategory, body);

const Category = { 
    getAllforAdmin,
    getAllCategory,
    getAllCategoryForAdmin,
    insertCategory,
    updateCategory,
    deleteCategory
 }
export default Category
