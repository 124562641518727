import { Col, Row, Spin, Image, Typography } from "antd"
import CustomModal from "../Modal/CustomModal"
import Button from "../MyButton/Button"
import LayoutCommon from "src/components/Common/Layout"
import "./style.css"
import { useEffect, useState } from "react"
import styled from "styled-components"
import PaymentService from "src/services/PaymentService"

const {Paragraph, Text} = Typography
const StyleWallet = styled.div`
  background: #fff;

  .bank-img {
    height: 100px;
    border-radius: 10px;
    margin-top: 16px;
  }

  .wallet-left {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .wallet-right {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
`

function VietcomBankPopup({onOk, money, ...props}) {
    const [loading, setLoading] = useState(false);
    const [qrCode, setQrCode] = useState(null);
    const [detail, setDetail] = useState()

    //#region useEffect

    useEffect(() => {
      getDetail()
    }, [])
    
    useEffect(() => {
      if(money) {
        getQRcodeByMoney();
      }
    }, [money])

    //#endregion

    //#region function

    /**
     * @description Format số tiền
     * @author hddung 
     */
    const formatCurrency = (value) => {
      return new Intl.NumberFormat('vi-VN', {
          style: 'currency',
          currency: 'VND',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
      }).format(value).replace('VND', 'đ');
  };

    const getDetail = async () => {
      try {
          setLoading(true)
          const res = await PaymentService.InforPayment()
          if (res?.isError) return
          setDetail(res?.object)
      } finally {
          setLoading(false)
      }
    }

    /**
     * @description Thực hiện call API trả về mã qr tương ứng với số tiền
     * @author hddung 
     */
    async function getQRcodeByMoney() {
      try {
        setLoading(true);
        const res = await PaymentService.getQRRechargeByMoney(money);
        if(res?.isError) return
        setQrCode(res?.object)  
      } 
      finally {
        setLoading(false);
      }
      
    }

    const renderFooter = () => (
        <div className="d-flex justify-content-flex-end">
          <Button
            className="btn-hover-shadow"
            onClick={() => props?.onCancel()}
          >
            Đóng
          </Button>
        </div>
    )

    //#endregion

    return (
    <CustomModal
        title='Vietcombank'
        footer={renderFooter()}
        {...props}
        width={1200}
        style={{padding: '80px 20px 0px 10px'}}
    >
        <Spin spinning={loading}>
            {/* <Wallet qrCode={qrCode}></Wallet> */}
            <StyleWallet>
              <LayoutCommon>
                <Row gutter={[16, 16]} className="p-24 flex-wrap">
                  <Col span={12} style={{maxWidth:"none"}}>
                    <div className="wallet-left">
                      <div>
                        <img className="bank-img" src={detail?.imgBanking} alt="" />
                        <ul className="mt-16" style={{ marginLeft: "20px" }}>
                          <li className="mt-8 d-flex">
                            STK: 
                            <Paragraph copyable className="fw-600 mb-0" style={{marginLeft: 4}}>
                              {detail?.stkBanking}
                            </Paragraph>
                          </li>
                          <li className="mt-8">
                            Người nhận: <b>{detail?.reciverBanking}</b>
                          </li>
                          <li className="mt-8 d-flex">
                            Số tiền: 
                            <Paragraph copyable={{ text: money}} className="fw-600 mb-0" style={{ marginLeft: 4 }}>
                              {formatCurrency(money)}
                            </Paragraph>
                          </li>
                          <li className="mt-8">
                            <div
                              classname="transfer-content"
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                              }}
                            >
                              <span>Nội dung chuyển khoản: </span>
                              <div>
                                <Paragraph copyable className="fw-600 mb-0"  style={{marginLeft: 4}}>
                                  {detail?.rechargeContent}
                                </Paragraph>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                  <Col span={12} style={{maxWidth:"none"}}>
                    <div className="fw-600 text-center mt-16" >
                      Quét mã QRCode để nội dung chuyển khoản chính xác
                    </div>

                    <div className="wallet-right">
                      <div>
                        <Image width="300px" src={qrCode} />
                      </div>
                    </div>
                  </Col>
                </Row>
              </LayoutCommon>
            </StyleWallet>
        </Spin>
    </CustomModal>
    )
}

export default VietcomBankPopup