import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import http from "../../../../services";
import Notice from "../../../../components/Notice";
import {Col, Form, Input, Row} from "antd";
import SpinCustom from "../../../../components/Spin";
import Modal from "react-bootstrap/Modal";
import SignatureCanvas from "react-signature-canvas";
import Button from "../../../../components/MyButton/Button";

export const EditSignatureModal = ({onCancel, open,refreshData,data}) => {
    const [loading, setLoading] = useState(false);
    const sigRef = useRef(null);
    const [signature, setSignature] = useState(null);
    const [content, setContent] = useState(null);
    const handleSignatureEnd = () => {
        setSignature(sigRef.current.toDataURL());
    };
    useEffect(() =>{
        setContent(data?.signatureImage);
        form.setFieldsValue({ name: data.signatureName });
    },[data]);

    const {userInfo} = useSelector(state => state?.appGlobal)
    const handleSubmit = async () => {
        form.validateFields().then(values => {
            const model={
                "signatureID": data?.signatureID,
                "signatureName": values?.name,
                "signatureImage": content,
                "updateUser": userInfo?.userID
            }
            setLoading(true);
            http.post("Contract/UpdateContractSignature",model).then(resp => {
                if(!resp.isError){
                    Notice({
                        isSuccess: true,
                        msg: 'Cập nhật chữ kí thành công!'
                    });
                    refreshData();
                    onCancel();
                }
                setLoading(false);
            }).finally(() => {
                setLoading(false);
            })
        })
    }

    const handleSave = () => {
        if (signature) {
            const htmlString = `
                    <img class="sign-item" src="${signature}" style="height: 100px; width: auto;" alt="Chữ kí" />`;
            setContent(htmlString);
        }
    }

    const clearSignature = () => {
        sigRef.current.clear();
        setSignature(null);
    };
    const [form] = Form.useForm();

    return (
        <SpinCustom spinning={loading}>
            <div>
                <Modal show={open} onHide={onCancel} closable={false}
                       dialogClassName="cus-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>Chi tiết hợp đồng</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={"cus-modal-body"}>
                        <div className={" container contract-container"}>
                            <div className={"form-ctn__sign"}>
                                <Form
                                    form={form}
                                    layout="vertical">
                                    <Row gutter={[16, 16]}>
                                        <Col span={24}>
                                            <Form.Item
                                                name="name"
                                                label="Nhập tên người ký"
                                                required
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Thông tin không được để trống",
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Nhập tên người ký"/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label={"Chữ kí tay"} required={true} name={"signature"}
                                                       rules={[
                                                           {
                                                               required: content==null,
                                                               message: "Thông tin không được để trống",
                                                           },
                                                       ]}
                                            >
                                                <div className={"contract-container"}>
                                                    <div className={"contract-c"}
                                                         dangerouslySetInnerHTML={{__html: content || '<div></div>'}}>

                                                    </div>
                                                </div>

                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label={"Thực hiện kí tay"} className={"d-flex-end"}>
                                                <div className={"conten-sign"}>
                                                    <SignatureCanvas
                                                            penColor="black"
                                                        canvasProps={{className: 'signature'}}
                                                        ref={sigRef}
                                                        onEnd={handleSignatureEnd}
                                                        width={"400px"}
                                                    />
                                                    <div style={{display: 'flex',justifyContent:"center"}}>
                                                        <Button disabled={loading} style={{marginRight: 6}}
                                                                onClick={clearSignature}>Làm mới</Button>
                                                        <Button disabled={loading} onClick={handleSave}>Lưu chữ
                                                            kí</Button>
                                                    </div>
                                                </div>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                </Form>
                            </div>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={onCancel} disabled={loading}>
                            Đóng
                        </Button>
                        <Button btnType="primary" variant="primary" disabled={loading} onClick={handleSubmit}>
                            Xác nhận
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </SpinCustom>

    )
}
