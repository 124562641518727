import { Col, DatePicker, Row, Select, message } from "antd"
import { useSelector } from "react-redux"
import FlInput from "src/components/FloatingLabel/Input"
import FlSelect from "src/components/FloatingLabel/Select"
import { SYSTEM_KEY } from "src/constants/constants"
import { getListComboByKey } from "src/lib/utils"
import { SearchStyled } from "../styled"
import { useEffect, useState } from "react"
import RegionService from "src/services/RegionService"
import dayjs from "dayjs";

const { Option } = Select
const Search = ({ setDataBody, dataBody }) => {
  const [loading, setLoading] = useState(false);
  const { listSystemKey } = useSelector(state => state.appGlobal);
  const [listProvince, setListProvince] = useState([]);
  const [listDistrict, setListDistrict] = useState([]);
  const [listWard, setListWard] = useState([]);

  //#region useEffect

  useEffect(() => {
    getListProvinceVN();
  }, [])

  //#endregion

  //#region function

  const getListProvinceVN = () => {
    setLoading(true)
    RegionService.getByRegionId({ regionId: 234 })
      .then(res => {
        if (res?.isError) return
        setListProvince(res?.object);
        loadListDistrict(dataBody.provinceID);
        loadListWard(dataBody.districtID);
      })
      .finally(() => setLoading(false))
  }

  useState(() => {

    getListProvinceVN();
  },[])
  const loadListDistrict=(newValue)=>{
    if(newValue&&newValue!==0){
      setLoading(true)
      RegionService.getByRegionId({ regionId: newValue })
      .then(res => {
        if (res?.isError) return
        const lstDistrict = res?.object||[];
        setListDistrict(lstDistrict)
      })
      .finally(() => setLoading(false))
    }
  }
  const loadListWard=(newValue)=>{
    if(newValue&&newValue!==0){
      setLoading(true)
      RegionService.getByRegionId({ regionId: newValue })
      .then(res => {
        if (res?.isError) return
        const listWard = res?.object||[];
        setListWard(listWard)
      })
      .finally(() => setLoading(false))
    }
  }

  const onChangeProvince = newValue => {
    setDataBody((prevState) => ({
      ...prevState,
      districtID: 0,
      wardID: 0,
      provinceID: newValue || 0,
      currentPage: 1
    }));
    if (!newValue) {
      setListDistrict([]);
      return;
    }
    setLoading(true);
    RegionService.getByRegionId({ regionId: newValue })
        .then(res => {

          if (res?.isError) return
          const lstDistrict = res?.object||[];
          setListDistrict(lstDistrict)
        })
        .finally(() => setLoading(false));
  };

  const onChangeDistrict = e => {
    // form.resetFields([`wardID`])
    setDataBody((prevState) => ({
      ...prevState,
      districtID: e || 0,
      wardID: 0,
      currentPage: 1
    }));
    if (!e) return setListWard([])
    setLoading(true)
    RegionService.getByRegionId({ regionId: e })
      .then(res => {
        if (res?.isError) return
        const lstWard = res?.object||[];
        setListWard(lstWard);
      })
      .finally(() => setLoading(false))
  }
  const onChangeWard = e => {
    // setWardSelected(e);
    setDataBody((prevState) => ({
      ...prevState,
      wardID: e || 0,
      currentPage: 1
    }));
  }

  //#endregion

  return (
    <SearchStyled>
      <Row gutter={[16, 16]}>
        <Col flex="auto">
          <FlInput
            onSearch={e => {
              setDataBody((prevState) => ({
                ...prevState,
                textSearch: e || '',
                currentPage: 1
              }));
            }}
            search
            allowClear
            label="Nhập mã số thuế, tên doanh nghiệp, số điện thoại, email, địa chỉ, người phụ trách"
          />
        </Col>
        <Col lg={3} xs={24}>
          <FlSelect
            label="Tỉnh/Thành phố"
            allowClear
            onChange={onChangeProvince}
            value={dataBody?.provinceID || null}
          >
            {listProvince?.map(
              i => (
                <Option key={+i.regionID} value={+i.regionID}>
                  {i?.regionName}
                </Option>
              ),
            )}
          </FlSelect>
        </Col>
        <Col lg={3} xs={24}>
          <FlSelect
            label="Quận/Huyện"
            allowClear
            onChange={onChangeDistrict}
            value={dataBody?.districtID || null}
          >
            {listDistrict?.map(
              i => (
                <Option key={+i.regionID} value={+i.regionID}>
                  {i?.regionName}
                </Option>
              ),
            )}
          </FlSelect>
        </Col>
        <Col lg={4} xs={24}>
          <FlSelect
            label="Phường/Xã"
            allowClear
            onChange={onChangeWard}
            value={dataBody?.wardID || null}
          >
            {listWard?.map(
              i => (
                <Option key={+i.regionID} value={+i.regionID}>
                  {i?.regionName}
                </Option>
              ),
            )}
          </FlSelect>
        </Col>
        <Col lg={3} xs={24}>
          <DatePicker
              onChange={(e) => {
                  const formattedDate = dayjs(e).format('YYYY-MM-DD');
                  if(new Date(formattedDate) <= new Date(dataBody?.dateTo) || !dataBody?.dateTo || !e) {
                    setDataBody((prevState) => ({
                      ...prevState,
                      dateFrom: formattedDate == 'Invalid Date' ? null : formattedDate,
                      currentPage: 1
                    }));
                  } else {
                    message.error('Từ ngày nhỏ hơn đến ngày');
                  }
              }}
              value={dataBody?.dateFrom ? dayjs(dataBody?.dateFrom) : null}
              placeholder="Từ ngày"
              format="DD/MM/YYYY"
              allowClear
          />
        </Col>

        <Col lg={3} xs={24}>
          <DatePicker
              onChange={(e) => {
                  const formattedDate = dayjs(e).format('YYYY-MM-DD');
                  if(new Date(formattedDate) >= new Date(dataBody?.dateFrom) || !dataBody?.dateFrom || !e) {
                    setDataBody((prevState) => ({
                      ...prevState,
                      dateTo: formattedDate == 'Invalid Date' ? null : formattedDate,
                      currentPage: 1
                    }));
                  } else {
                    message.error('Đến ngày lớn hơn từ ngày');
                  }
              }}
              value={dataBody?.dateTo ? dayjs(dataBody?.dateTo) : null}
              placeholder="Đến ngày"
              format="DD/MM/YYYY"
              allowClear
          />
        </Col>
      </Row>
    </SearchStyled>
  )
}

export default Search
