import {useNavigate, useParams} from 'react-router-dom';
import React, {useEffect, useState} from "react";
import "./style/orderAdmin.css"

import {ORDER_STATUS} from "../../../../constants/constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faEye, faEyeSlash, faFile, faFilePdf} from "@fortawesome/free-solid-svg-icons";
import {OrderStatusAdminList} from "./OrderStatusAdminList";
import { Tag,Button} from "antd"

import Swal from "sweetalert2";
import CancelOrderAdminModal from "../List/CancelOrderAdminModal";
import CB1 from "../../../../components/Modal/CB1";
import ROUTER from "../../../../router";
import http from "../../../../services";
import {OrderStatusAdminListNotSign} from "./OrderStatusAdminListNotSign";
import {DownloadOutlined} from "@ant-design/icons";
import {ContractViewer} from "../../../Contract/ContractPreview/ContractViewer";
import {ProductResultViewer} from "../../../Product/modal/ProductResultViewer";
import {StatusListAutomatic} from "../../../USER/Orders/StatusBox/statusListAutomatic";
import {OrderStatusAdminAutomatic} from "./OrderStatusAdminAutomatic";
import SpinCustom from "../../../../components/Spin";
import {StatusListAutomationSign} from "../../../USER/Orders/StatusBox/statusListAutomationSign";
import ConTractViewPDF from "../../../Contract/ContractPreview/ConTractViewPDF";
import {renderStatusTitle} from "../../../../lib/utils";

export const OrderAdminDetail = () => {
    const {id} = useParams();
    const [order, setOrder] = useState([]);
    const [listStatus, setListStatus] = useState([]);
    const [showCancel, setShowCancel] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isRefresh, setIsrefresh] = useState(false);
    const [isShowContract, setIsShowContract] = useState(false);
    const [isShowProduct, setIsShowProduct] = useState(false);
    useEffect(() => {
        setLoading(true);
        getOrderDetail().then(resp => {
            if (!resp.isError) {
                setOrder(resp?.object)
            }
        })
            .finally(() => {
                setLoading(false);
            })

    }, [isRefresh])

    useEffect(() => {
        setStatus(order?.status);
        setLoading(true)
        getOrderStatus().then(resp => {
            if (!resp.isError) {
                const inputArray = resp?.object;
                // Chuyển đổi mảng đối tượng thành mảng mới chỉ chứa orderId và createDate
                const newArray = inputArray.map(({statusID, createDate}) => ({statusID, createDate}));
                setListStatus(newArray);

            } else {
                setListStatus([]);
            }
        }).finally(() => {
            setLoading(false);
        })
    }, [order])
    const handleShowCancelModal = () => {
        setShowCancel(true);
    }
    const handleHideCancelModal = () => {
        setShowCancel(false);
    }
    const navigate = useNavigate();
    const getOrderDetail = async () => {
        return await http.post(`Order/GetOrderDetail?orderId=${id}`)
    }
    const getOrderStatus = async () => {
        return await http.post(`Order/GetOrderStatus?orderId=${id}`)
    }
    const formatCurrency = (value) => {
        return new Intl.NumberFormat('vi-VN', {
            style: 'currency',
            currency: 'VND',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value).replace('VND', 'đ');
    };
    const renderNewPrice = (price, discount) => {
        return price * (100 - discount) / 100;
    }

    const handleRefreshCancelChange = () => {
        navigate(`${ROUTER.DANH_SACH_DON_HANG}`)
    }

    const [status, setStatus] = useState(ORDER_STATUS.CHO_XAC_NHAN);
    const downloadFile = () => {
        // Tạo một đối tượng Blob chứa nội dung file HTML
        const htmlContent = order?.htmlContent;
        const blob = new Blob([htmlContent], {type: 'text/html'});

        // Tạo một URL cho Blob
        const url = window.URL.createObjectURL(blob);

        // Tạo một thẻ <a> để tạo và kích hoạt tải xuống
        const a = document.createElement('a');
        a.href = url;
        a.download = order?.contractFileName;

        // Thêm thẻ <a> vào DOM và kích hoạt tải xuống
        document.body.appendChild(a);
        a.click();

        // Xóa thẻ <a> sau khi tải xuống hoàn thành
        window.URL.revokeObjectURL(url);
    }

    const showCancelModal = () => {
        setShowCancel(true);
    }
    const handleConfirmStatus = (orderId) => {
        setLoading(true)
        http.post(`/Order/ConfirmOrder?OrderId=${orderId}`).then(resp => {
            // console.log('s',resp.isError);
            if (!resp?.isError) {
                showSuccessUpdate("Bạn đã xác nhận đơn hàng thành công");
                setLoading(false)
            }
        }).finally(() => {
            setLoading(false);
        })
    }
    const showSuccessUpdate = (message) => {
        setLoading(true)
        Swal.fire({
            icon: 'success',
            title: 'Thành Công',
            text: message,
        }).then(() => {
            navigate(`${ROUTER.DANH_SACH_DON_HANG}`)
        });
    };
    const renderButton = (title) => {
        switch (title) {
            case ORDER_STATUS.CHO_KY:
                return <Button className="box__item" onClick={() => showCancelModal()}>Huỷ đơn hàng</Button>;
            case ORDER_STATUS.CHO_XAC_NHAN:
                return (
                    <div className="box-item__container">
                        <Button className="box__item" onClick={() => showCancelModal()}>Huỷ đơn hàng</Button>
                        <Button className="box__item sign-btn" btnType={"primary"} onClick={() => {
                            CB1({
                                title: `Bạn có chắc chắn muốn xác nhận đơn hàng này ?`,
                                icon: "warning-usb",
                                okText: "Đồng ý",
                                onOk: async close => {
                                    handleConfirmStatus(id);
                                    close();
                                },
                            })
                        }}>Xác nhận</Button>
                    </div>
                );
            default:
                return null;
        }
    };
    const handleViewContract = () => {
        setIsShowContract(true);
    }
    const handleShowProduct = () => {
        setIsShowProduct(true);
    }
    return (
        <>
            <SpinCustom spinning={loading}>
                <div className={"container main-container"}>
                    <div className={"back-container"}>
                        <div className={"back-item col-3"}>
                            <div style={{marginBottom:8,marginRight:4}} onClick={() => {
                                navigate(`${ROUTER.DANH_SACH_DON_HANG}`)
                                // window.location.href="/orders";
                            }}>
                                <FontAwesomeIcon icon={faChevronLeft} color={'#212529'} style={{marginBottom:1}} />
                            </div>
                            <h6 onClick={() => {
                                navigate(`${ROUTER.DANH_SACH_DON_HANG}`)
                                // window.location.href="/orders";
                            }}> Trở về</h6>
                        </div>
                        <div className={"id-title__container col-9"}>
                            <p>
                                ID đơn hàng {order?.orderId} |
                            </p>
                            <p >
                                {renderStatusTitle(order?.status)}
                            </p>
                        </div>
                    </div>
                    <div className={"status-tracking"}>
                        <div className={"status-container"}>
                            {
                                order?.isSignContract && !order?.isBuyAutomatic && (
                                    <OrderStatusAdminList status={order?.status} listStatus={listStatus}/>
                                )
                            }
                            {
                                !order?.isSignContract && !order?.isBuyAutomatic && (
                                    <OrderStatusAdminListNotSign status={order?.status} listStatus={listStatus}/>
                                )
                            }
                            {
                                order?.isBuyAutomatic &&!order?.isSignContract&&
                                (
                                    <OrderStatusAdminAutomatic status={order?.status} listStatus={listStatus}/>
                                )
                            }
                            {
                                order?.isBuyAutomatic&&order?.isSignContract&&
                                (
                                    <StatusListAutomationSign status={order?.status} listStatus={listStatus}/>
                                )
                            }
                        </div>

                        <div className={"status-container button--container"}>
                            {renderButton(order?.status)}
                        </div>
                            {
                                (order?.status === ORDER_STATUS.DA_HUY) ? (
                                    <>
                                    <div className={"document-container"}>
                                        <div className={"header__it"}>
                                            <h5>Lý do hủy đơn</h5>
                                        </div>
                                        <div className={"body__it"}>
                                            <p>{order?.note}</p>
                                        </div>
                                    </div>
                                    </>
                                ) : ""
                            }
                            {
                                ((order?.status === ORDER_STATUS.HOAN_THANH || order?.status === ORDER_STATUS.DA_DANH_GIA) && order?.isSignContract) ? (
                                    <>
                                    <div className={"document-container"}>
                                        <div style={{display: "flex", flexDirection: "column"}}>
                                            <div>
                                                <div className={"header__it"}>
                                                    <h6>Hợp đồng đã ký</h6>
                                                </div>
                                                <div className={"body__it"}>
                                                    <div style={{cursor: 'pointer', margin: 6,display:"flex"}} className={"col-4"}>
                                                        <FontAwesomeIcon className={"icon-item item-files"}
                                                                         onClick={() => handleViewContract()}
                                                                         style={{
                                                                             height: '28px',
                                                                             width: '28px',
                                                                             color: '#4278d7'
                                                                         }}
                                                                         icon={faFilePdf}/>
                                                        <span onClick={() => handleViewContract()}
                                                              className={"max-line1"}>{order?.contractFileName}</span>
                                                    </div>
                                                    {/*<div style={{display: "flex"}}>*/}

                                                    {/*    <div style={{marginRight: 4, alignSelf: 'end'}}>*/}
                                                    {/*        <Button style={{width: 142}}*/}

                                                    {/*                btnType="primary"*/}
                                                    {/*                className="btn-hover-shadow"*/}
                                                    {/*                onClick={handleViewContract}>Xem*/}
                                                    {/*            hợp*/}
                                                    {/*            đồng*/}
                                                    {/*        </Button>*/}
                                                    {/*    </div>*/}
                                                    {/*    <div style={{marginRight: 4, alignSelf: 'end'}}>*/}
                                                    {/*        <Button style={{width: 142}}*/}
                                                    {/*                icon={<DownloadOutlined/>}*/}
                                                    {/*                onClick={() => downloadFile()}*/}
                                                    {/*                btnType="primary"*/}
                                                    {/*                className="btn-hover-shadow">Tải hợp đồng*/}
                                                    {/*        </Button>*/}
                                                    {/*    </div>*/}
                                                    {/*</div>*/}
                                                </div>
                                            </div>

                                            {/*/!*xem sp *!/*/}
                                            {/*<div>*/}
                                            {/*    <FontAwesomeIcon icon={faEye} style={{cursor: "pointer"}}*/}
                                            {/*                     onClick={() => handleShowProduct()}/>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                    </>
                                ) : ""
                            }

                    </div>
                    <div className={"product-detail"}>
                        <div>
                            <h6>Sản phẩm</h6>
                        </div>
                        <div className={"order-container__main"}>
                            <div className={"im__p__container col-12 col-md-7 "}>
                                <div className={"image-order__container"}  style={{cursor:"pointer"}}
                                     onClick={()=>navigate(`/${order.slug}-${order.productId}`)}>
                                    <img src={order?.image}/>
                                </div>
                                <div className={"name_cts col-md-8"} >
                                    <p className={"name-attr max-line1"} style={{cursor:"pointer"}}
                                       onClick={()=>navigate(`/${order.slug}-${order.productId}`)}>
                                        {order?.productName}
                                    </p>
                                    <span>
                                            Phân loại:<Tag>{order?.productItemName}</Tag>
                               </span>
                                    <p className={"max-line1"}>
                                        x{order?.quantity}
                                    </p>
                                    {/* <div style={{marginTop: 2}} className='d-flex'>*/}
                                    {/*     {order?.isSignContract && <Tag className='d-flex align-items-center'><SvgIcon className='ic-tag-order' name='sign-contract-ic'/>Ký hợp đồng</Tag>} */}
                                    {/*     {!order?.isCheckStock && <Tag className='d-flex align-items-center'><SvgIcon className='ic-tag-order' name='stock-ic'/>Mua quá tồn kho</Tag>} */}
                                    {/*     {order?.isBuyAutomatic && <Tag className='d-flex align-items-center'><SvgIcon className='ic-tag-order' name='automatic-ic'/>Mua bán tự động</Tag>}*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                            <div className={"attribute__container col-12 col-md-1"}>
                                {
                                    ((order?.status === ORDER_STATUS.HOAN_THANH || order?.status === ORDER_STATUS.DA_DANH_GIA))
                                    && (
                                        <>
                                            {isShowProduct ? (

                                                    <FontAwesomeIcon style={{marginLeft: 10, cursor: "pointer"}} size={16}
                                                                     icon={faEye}
                                                                     onClick={() => handleShowProduct()}/>
                                                ) :
                                                (
                                                    <FontAwesomeIcon style={{marginLeft: 10, cursor: "pointer"}} size={16}
                                                                     icon={faEyeSlash}
                                                                     onClick={() => handleShowProduct()}/>
                                                )

                                            }
                                        </>
                                    )
                                }
                            </div>
                            <div className={"attribute__container col-12 col-md-4 "}>
                                <div className={"col-12"}>
                                    <div className={"quantity-price__container"}>
                                        {/*<p className={"name-attr max-line1 col-6"}>*/}
                                        {/*    {order?.productName}*/}
                                        {/*</p>*/}
                                        <p className={"col-4"}>
                                            Tạm tính
                                        </p>
                                        <div className={" price-dis__container "}>
                                            {
                                                (order?.discount && order?.discount > 0) ?
                                                    (
                                                        <div className={"price"}>
                                                            <div
                                                                className={'price-total-container sale-price'}>{formatCurrency(renderNewPrice(order?.price, order?.discount))}
                                                            </div>
                                                            <del>{formatCurrency((order?.price*order?.quantity)?.toString() || 0)}</del>
                                                        </div>
                                                    ) :
                                                    (
                                                        <div
                                                            className={'price-total-container'}>{formatCurrency((order?.price*order?.quantity)?.toString() || 0)}</div>
                                                    )
                                            }
                                        </div>
                                    </div>
                                    {/* <span>*/}
                                    {/*             Phân loại:<Tag>{order?.productItemName}</Tag>*/}
                                    {/*</span>*/}
                                    <div className={"quantity-price__container"}>
                                        {/*<p className={"col-6 max-line1"}>*/}
                                        {/*    x{order?.quantity}*/}
                                        {/*</p>*/}
                                        <p className={"col-md-5"}>
                                            Tổng cộng
                                        </p>
                                        <div className={"price-dis__container "}>
                                            {
                                                (order?.discount && order?.discount > 0) ?
                                                    (
                                                        <div className={"price-total-container main-p"}>
                                                            <div
                                                                className={'price-acitve'}>{formatCurrency(renderNewPrice(order?.price*order?.quantity, order?.discount))}
                                                            </div>
                                                        </div>
                                                    ) :
                                                    (
                                                        <div
                                                            className={'price-total-container main-p'}>{formatCurrency((order?.price*order?.quantity).toString() || 0)}
                                                        </div>
                                                    )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showCancel && (
                    <CancelOrderAdminModal
                        onCancel={() => handleHideCancelModal()}
                        visible={showCancel}
                        order_id={order?.orderId}
                        refreshData={() => handleRefreshCancelChange()}
                    />
                )}
                {
                    isShowContract && (
                        <ConTractViewPDF
                            onCancel={() => setIsShowContract(false)}
                            open={isShowContract}
                            contractPDF={{
                                titleContract: order?.contractName,
                                contractUrlPDF: order?.contractUrlPDF
                            }}
                        />
                    )
                }
                {
                    isShowProduct && (
                        <ProductResultViewer onCancel={() => setIsShowProduct(false)} open={isShowProduct}
                                             contract_html={order?.orderDetails}/>
                    )
                }
            </SpinCustom>
        </>
    )
}
