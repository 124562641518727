import {CustomerCareListTable} from "../List/CustomerCareListTable";
import {useEffect, useState} from "react";
import http from "../../../../services";
import {Avatar, Col, List, Pagination, Row, Typography} from "antd";
import ButtonCircle from "../../../../components/MyButton/ButtonCircle";
import {STATUS_OPTION} from "../../../../constants/constants";
import AddContent from "../../EmployeeContact/modal/AddContent";
import {AddCustomerCareModal} from "../modal/AddCustomerCareModal";
import {CustomerCareDetailModal} from "./CustomerCareDetail";
import {AddNewCustomerResponseModal} from "../modal/AddNewCustomerResponseModal";

export const TakeCareTab =({userInfo,isRefresh,customerSelected,refreshTable})=>{
    const [data,setData]=useState([]);
    const [totalPage,setTotalPage]=useState(1)
    const [pagination, setPagination] = useState({
        "currentPage": 1,
        "search": "",
        "UserID": userInfo?.userID|| undefined,
    })
    const [itemSelected, setItemSelected] = useState({});
    const [isShowDetail, setIsShowDetail] = useState(false);

    const getData = () => {
        http.post(`ManageCustomer/GetListCustomerCaresByUserId?UserID=${userInfo?.userID}&CurrentPage=${pagination?.currentPage}&search=${pagination?.search}`)
            .then(res => {
                if (!res?.isError) {
                    setData(res.object.list)
                    setTotalPage(res.object.count);
                }else{
                    setData([]);
                }

            })
    }
    useEffect(() => {
        if(userInfo?.userID){
           getData();
        }
    }, [pagination,isRefresh,userInfo]);
    // useEffect(()=>{
    //     getData();
    // },[isRefresh])

    const handleRefreshData=()=>{
        const model={
            "currentPage": 1,
            "search": "",
            "UserID": userInfo?.userID|| undefined,
        }
        refreshTable();
        setPagination(model);
    }
    const handleChangePage=(model) => {
        setPagination(model)
    }
    const onChangeKeyword = (key: string) => {

        setData([]);
        const newPagi = {...pagination, search: key, currentPage: 1};
        setPagination(newPagi);
    }
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            time = d.getHours() + ':' + (d.getMinutes() < 9 ? '0' : '') + d.getMinutes();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('-') + ` ${time}`;
    };
    const [openContentPopup, setOpenContentPopup] = useState(false);
    const [isShowAddResponse, setIsShowAddResponse] = useState(false);


    return(

        <>
            <Row gutter={[16, 16]} style={{marginTop: '30px', marginBottom: '15px'}}>
                <Col span={10} >
                    <Typography.Text style={{fontSize: '20px'}} strong>Lịch sử tương tác:</Typography.Text>
                </Col>
                <Col span={14} >
                    <div className={"d-flex"}>
                        {
                            customerSelected?.isAssignStaff&&(
                                <ButtonCircle
                                    title="Thêm phụ trách"
                                    iconName="add-user-manager-contact"
                                    onClick={() => {
                                        setOpenContentPopup(true)
                                    }}
                                />
                            )
                        }
                        {
                            customerSelected?.isInsertCustomerCare&&(
                                    <ButtonCircle
                                        title="Thêm hoạt động"
                                         iconName="add-blue"
                                        onClick={() => {

                                            setIsShowAddResponse(true)
                                        }}
                                    />
                            )
                        }
                    </div>
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="justify-content-center">
                <Col span={24}>
                    <List
                        itemLayout="horizontal"
                        dataSource={data}
                        renderItem={(item, index) => (
                            <List.Item>
                                <List.Item.Meta onClick={()=>{
                                    setItemSelected(item);
                                    setIsShowDetail(true)
                                }}
                                    avatar={<Avatar src={item?.createUserAvatar} size={50}/>}
                                    title={
                                        <div style={{display: 'flex'}}>
                                            <h6 style={{marginRight: '20px'}}>{item?.createUserName}</h6>
                                            <p style={{lineHeight: '30px', color: 'rgba(0, 0, 0, 0.45)'}}>{formatDate(item?.createDate)}</p>
                                        </div>
                                    }
                                    description={item?.title}
                                />
                            </List.Item>
                        )}
                    />
                </Col>
                <div className={"d-flex-end"} style={{width:'80%'}}>

                <Pagination

                    total={totalPage}
                    // showTotal={(total) => ` ${total} kết quả`}
                    onChange={(current, size) => {
                        setPagination({...pagination,currentPage:current})
                    }}
                    // hideOnSinglePage={true}
                    current={pagination.currentPage}
                    showSizeChanger={false} // Ẩn dropdown chọn page size

                />
                </div>
            </Row>
            {openContentPopup &&
                <AddCustomerCareModal cancel={()=>setOpenContentPopup(false)}
                                      open={openContentPopup}
                                      refreshData={()=>handleRefreshData()}
                                      customerId={userInfo.userID}
                />
            }
            {isShowAddResponse &&
                <AddNewCustomerResponseModal cancel={()=>setIsShowAddResponse(false)}
                                      open={isShowAddResponse}
                                      refreshData={()=>handleRefreshData()} userInfo={userInfo}/>
            }
            {
                isShowDetail&&(
                    <CustomerCareDetailModal open={isShowDetail} cancel={()=>setIsShowDetail(false)} item={itemSelected}/>
                )
            }
        </>
    )
}
