import React from "react";

export const DraggablePanel = ({ name,value }) => {
    // const handleDragStart = (event) => {
    //     console.log("Drag start:", name);
    //     event.dataTransfer.setData("text/plain", name);
    // };
    const handleDragStart = (event) => {
        // Chuyển đối tượng chứa thông tin thành chuỗi JSON
        const dataTransferString = renderInputHtml(name,value);
        // Gán chuỗi JSON cho dataTransfer
        event.dataTransfer.setData("text/html", dataTransferString);
    };
    const handleDragEnd = () => {
    };
    const renderInputHtml = (name,value) => {
        return `<input type='text' id='${value}'  value='${name}' placeholder="${name}" disabled="true" style="height: 34px;  width: fit-content; min-width: 130px; "/>`;
    };


    return (
        <div
            draggable
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            style={{
                opacity: 0.8,
                cursor: "move",
                border: "1px dashed #ccc",
                padding: "8px",
                margin: "8px",
            }}
        >
            {name}
        </div>
    );
};
