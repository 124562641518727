import {Button, Tag} from "antd";
import {ShopOutlined, WechatOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import './orderPreview.css'
import {IMAGES} from "../../../../constants/imageDir";
import {OrderStatusBox} from "../StatusBox/orderStatus";
import {ORDER_STATUS} from "../../../../constants/constants";
import {useNavigate} from "react-router-dom";
import {ContractPreview} from "../../../Contract/ContractPreview/ContractPreview";
import axios from "axios";
import SpinCustom from "../../../../components/Spin";
import CancelModal from "../../../../components/Modal/CancelOrderModal/CancelModal";
import FeedBackProductModal from "../../../../components/Modal/FeedBackModal/FeedBackProductModal";
import ROUTER from "../../../../router";
import { useDispatch } from "react-redux";
import { setChatUserInfor } from "src/redux/appGlobal";

export const OrderPreview = ({order, refreshData,setLoading}) => {
    useEffect(() => {

    }, [order])

    const [contract_html, setContract_html] = useState('');
    const navigate = useNavigate();
    const [showCancel, setShowCancel] = useState(false);
    const [showFeedBack, setShowFeedBack] = useState(false);
    const dispatch = useDispatch();

    const handleShowCancelModal = () => {
        setShowCancel(true);
    }
    const handleHideCancelModal = () => {
        setShowCancel(false);
    }
    const handleRefreshSignChange = () => {
        const o = {...order, status: ORDER_STATUS.HOAN_THANH};
        refreshData(order?.orderId, o);
    }
    const handleRefreshCancelChange = () => {
        const o = {...order, status: ORDER_STATUS.DA_HUY};
        refreshData(order?.orderId, o);
    }

    const handleShowFeedBackModal = () => {
        setShowFeedBack(true);
    }
    const handleHideFeedBackModal = () => {
        setShowFeedBack(false);
    }
    const handleRefreshFeedBackChange = () => {
        const o = {...order, status: ORDER_STATUS.DA_DANH_GIA};
        setLoading(false);
        refreshData(order?.orderId, o);
    }

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('vi-VN', {
            style: 'currency',
            currency: 'VND',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value).replace('VND', 'đ');
    };
    const renderNewPrice = (price, discount) => {
        return price * (100 - discount) / 100;
    }
    const renderStatusTitle = (title) => {
        switch (title) {
            case ORDER_STATUS.DA_HUY:
                return <p style={{color:"red",fontWeight:600}}>Đã huỷ</p>;
            case ORDER_STATUS.CHO_XAC_NHAN:
                return <p  className={"primary-color"} style={{color:"var(--bs-primary) !important",fontWeight:600}}>Chờ xác nhận</p>;
            case ORDER_STATUS.HOAN_THANH:
                return <p className={"success-color"} style={{color:"var(--bs-bs-success) !important",fontWeight:600}}>Hoàn thành</p>;
            case ORDER_STATUS.CHO_KY:
                return <p className={"primary-color"} style={{color:"var(--bs-primary) !important",fontWeight:600}}>Chờ ký</p>;
            case ORDER_STATUS.DA_DANH_GIA:
                return <p className={"success-color"} style={{color:"var(--bs-success) !important",fontWeight:600}}>Đã đánh giá</p>;
            default:
                return ""
        }
    }
    const [showContract, setShowContract] = useState(false)

    const handleShowContract = () => {
        getOrderDetail().then(resp => {
            setContract_html((resp?.data?.object?.htmlContent));
        }).catch(reason => {
            // console.log(reason);
        }).finally(() => {
            setLoading(false);
            setShowContract(true);
        })
    }
    const handleCancelContract = () => {
        console.log('cancel')
        setShowContract(false);
    }

    /**
     * @description thực hiện việc show chat box
     * @author hddung
     */
    function showChatBox() {
        //Set biến redux global để hiển thị chatbox
        dispatch(setChatUserInfor(
        {
            userData: {
                toUserId: order?.shopId,
                toUserName: order?.shopName,
                roomId: order?.roomId,
                avatar: order?.avatarShop
            },
            isShow: true
        }));
    }

    const getOrderDetail = async () => {

        return await axios.post(`Order/GetOrderDetail?orderId=${order?.orderId}`)
    }
    return (
        <>
                <div className={"car-item-main__container"}>
                    <div className={"card-item__container"}>
                        <div className={"shop-container"}>
                            <div className={"col-8 shop-container-item shop-container-item__cus"}>
                                <h5 className={"item__name"}>{order?.shopName}</h5>
                                <div>
                                <Button className={"item__name"} icon={<WechatOutlined/>} onClick={showChatBox}>Nhắn tin</Button>
                                <Button onClick={()=>{
                                    navigate(ROUTER.PRODUCT_SHOP+`?shopID=${order.shopId}`)
                                }}  icon={<ShopOutlined/>}>Xem Shop</Button>
                                </div>
                            </div>
                            <div className={"col-4 status-title"}>
                                <div style={{color:'red'}}>
                                    {renderStatusTitle(order?.status)}
                                </div>
                            </div>
                        </div>
                        {/*separate*/}
                        <div className={"content-order__container"}>
                            <ul>
                                <li className={"it"} onClick={() => navigate(`/order-detail/${order?.orderId}`)}>
                                    <div className={"order-container__main order-container__main_l"}>
                                        <div className={"image-order__container"}>
                                            <img src={order?.image}/>
                                        </div>
                                        <div className={"attribute__container"}>
                                            <div className={"col-12"}>
                                                <p className={"name-attr max-line1"}>

                                                    {order?.productName}
                                                </p>
                                                <span>
                                                    Phân loại: <Tag>{order?.productItemName}</Tag>
                                                </span>
                                                <div className={"quantity-price__container"}>
                                                    <p className={"col-3 max-line1"}>
                                                        x{order?.quantity}
                                                    </p>
                                                    <div className={"d-flex-end"} style={{width:"100%"}}>
                                                    {
                                                        (order?.discount && order?.discount > 0) ?
                                                            (
                                                                <div className={"price-total-container"}>

                                                                    <del>{formatCurrency(order?.price.toString())}</del>
                                                                    <div style={{color:"#ED1117",marginLeft:8}} className={'price '}>{formatCurrency(renderNewPrice(order.price, order.discount))}
                                                                    </div>
                                                                </div>
                                                            ) :
                                                            (
                                                                <div style={{color:"#ED1117"}} className={'price'}>{formatCurrency(order?.price.toString())}</div>
                                                            )
                                                    }
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={"total--bill__container l-bi-c"}>
                        <div className={"button__container"}>
                            <OrderStatusBox title={order?.status} showContract={handleShowContract}
                                            showCancelModal={handleShowCancelModal}
                                            handleShowFeedBackModal={handleShowFeedBackModal}
                                            productId={order?.productId}
                                            slug={order?.slug}
                            />
                        </div>
                        <div className={"total-price__container"}>
                            <div style={{alignSelf:"center"}}>
                                <p className={"total-title"}>Tổng tiền</p>
                            </div>
                            <div className={"price-total-container"}>
                                {
                                    <>
                                        {
                                            (order?.discount > 0) ?
                                                (
                                                    <>
                                                        <div
                                                            className={'price price-active price-active-main'}>{formatCurrency(renderNewPrice(order?.price*order?.quantity, order?.discount))}
                                                        </div>
                                                        <div className={"price"}>
                                                            <del>{formatCurrency(order?.price*order?.quantity.toString())}</del>
                                                        </div>
                                                    </>
                                                ) :
                                                (
                                                    <div
                                                        className={'price price-active price-active-main'}>{formatCurrency(order?.price*order?.quantity)}
                                                    </div>
                                                )
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {showContract && (
                    <ContractPreview
                        open={() => handleShowContract()}
                        onCancel={() => handleCancelContract()}
                        contract_html={contract_html}
                        order_id={order?.orderId}
                        refreshData={() => handleRefreshSignChange()}
                        onLoading={setLoading}
                    />
                )}
                {showCancel && (
                    <CancelModal
                        onCancel={() => handleHideCancelModal()}
                        visible={showCancel}
                        order_id={order?.orderId}
                        refreshData={() => handleRefreshCancelChange()}
                    />
                )}
                {
                    showFeedBack && (
                        <FeedBackProductModal
                            onCancel={() => handleHideFeedBackModal()}
                            visible={showFeedBack}
                            product_id={order?.productId}
                            refreshData={() => handleRefreshFeedBackChange()}
                            orderId={order?.orderId}
                            onLoading={setLoading}
                        />
                    )
                }
        </>
    )

}
