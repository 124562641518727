import http from "../index"
import { 
    apiGetSystemKey, 
    apiGetListHistory,
    apiGetListComboboxSearch
} from "./urls"

const getSystemKey = key => http.get(apiGetSystemKey, { params: { key } })
const getListHistory = body => http.post(apiGetListHistory, body)
const getListComboboxSearch = () => http.post(apiGetListComboboxSearch)

const CommonService = { 
    getSystemKey,
    getListHistory,
    getListComboboxSearch
}
export default CommonService
