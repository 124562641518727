import "./statuslist.css"
import {ORDER_STATUS} from "../../../../constants/constants";

export const StatusList = ({status, listStatus}) => {
    const convertStatus = () => {
        switch (status) {
            case ORDER_STATUS.CHO_XAC_NHAN:
                return 0;
            case ORDER_STATUS.CHO_KY:
                return 1;
            case ORDER_STATUS.HOAN_THANH:
                return 2;
            case ORDER_STATUS.DA_DANH_GIA:
                return 3;
            default:
                return 0;
        }
    }

    return (
        <>
            {
                status !== ORDER_STATUS.DA_HUY ? (
                        <div className="stepper">
                            <input hidden id="order_id" name="order_id" value="1"/>

                            {/*chờ xác nhận */}
                            <div className="stepper__step stepper__step-icon--finish">
                                <div className={`stepper__step-icon ${status > 0 ? " stepper__step-icon--finish" : ""} `}>
                                    <svg enableBackground="new 0 0 32 32" viewBox="0 0 32 32" x="0" y="0"
                                         className="shopee-svg-icon icon-order-order">
                                        <g>
                                            <path
                                                d="m5 3.4v23.7c0 .4.3.7.7.7.2 0 .3 0 .3-.2.5-.4 1-.5 1.7-.5.9 0 1.7.4 2.2 1.1.2.2.3.4.5.4s.3-.2.5-.4c.5-.7 1.4-1.1 2.2-1.1s1.7.4 2.2 1.1c.2.2.3.4.5.4s.3-.2.5-.4c.5-.7 1.4-1.1 2.2-1.1.9 0 1.7.4 2.2 1.1.2.2.3.4.5.4s.3-.2.5-.4c.5-.7 1.4-1.1 2.2-1.1.7 0 1.2.2 1.7.5.2.2.3.2.3.2.3 0 .7-.4.7-.7v-23.7z"
                                                fill="none" strokeLinecap="round" strokeLinejoin="round"
                                                strokeMiterlimit="10"
                                                strokeWidth="3">

                                            </path>
                                            <g>
                                                <line fill="none" strokeLinecap="round" strokeMiterlimit="10"
                                                      strokeWidth="3"
                                                      x1="10"
                                                      x2="22" y1="11.5" y2="11.5">

                                                </line>
                                                <line fill="none" strokeLinecap="round" strokeMiterlimit="10"
                                                      strokeWidth="3"
                                                      x1="10"
                                                      x2="22" y1="18.5" y2="18.5">

                                                </line>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div className="stepper__step-text">
                                    Chờ xác nhận
                                </div>
                                <div className="stepper__step-date">
                                    {
                                        status >= 1 ? (<p>{listStatus[0]?.createDate}</p>): ""
                                    }
                                </div>
                            </div>

                            {/*chờ ký*/}

                            <div className="stepper__step stepper__step--pending">
                                <div className={`stepper__step-icon ${status >= 2 ? " stepper__step-icon--finish" : ""} `}>
                                    <svg enableBackground="new 0 0 32 32" viewBox="0 0 32 32" x="0" y="0"
                                         className="shopee-svg-icon icon-order-received">
                                        <g>
                                            <polygon fill="none"
                                                     points="2 28 2 19.2 10.6 19.2 11.7 21.5 19.8 21.5 20.9 19.2 30 19.1 30 28"
                                                     strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="3">

                                            </polygon>
                                            <polyline fill="none" points="21 8 27 8 30 19.1" strokeLinecap="round"
                                                      strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="3">

                                            </polyline>
                                            <polyline fill="none" points="2 19.2 5 8 11 8" strokeLinecap="round"
                                                      strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="3">

                                            </polyline>
                                            <line fill="none" strokeLinecap="round" strokeLinejoin="round"
                                                  strokeMiterlimit="10"
                                                  strokeWidth="3" x1="16" x2="16" y1="4" y2="14">

                                            </line>
                                            <path
                                                d="m20.1 13.4-3.6 3.6c-.3.3-.7.3-.9 0l-3.6-3.6c-.4-.4-.1-1.1.5-1.1h7.2c.5 0 .8.7.4 1.1z"
                                                stroke="none">

                                            </path>
                                        </g>
                                    </svg>
                                </div>
                                <div className="stepper__step-text">
                                    Chờ ký
                                </div>
                                <div className="stepper__step-date">
                                    {
                                        status >= 2 ? (<p>{listStatus[1]?.createDate}</p>): ""
                                    }
                                </div>
                            </div>

                            {/*hoàn thành */}
                            <div className="stepper__step stepper__step--finish  ">
                                <div className={`stepper__step-icon ${status >= 4 ? " stepper__step-icon--finish" : ""} `}>
                                    <svg enableBackground="new 0 0 32 32" viewBox="0 0 32 32" x="0" y="0"
                                         className="shopee-svg-icon icon-order-shipping">
                                        <g>
                                            <line fill="none" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="3"
                                                  x1="18.1"
                                                  x2="9.6" y1="20.5" y2="20.5">

                                            </line>
                                            <circle cx="7.5" cy="23.5" fill="none" r="4" strokeMiterlimit="10"
                                                    strokeWidth="3">

                                            </circle>
                                            <circle cx="20.5" cy="23.5" fill="none" r="4" strokeMiterlimit="10"
                                                    strokeWidth="3">

                                            </circle>
                                            <line fill="none" strokeMiterlimit="10" strokeWidth="3" x1="19.7" x2="30"
                                                  y1="15.5"
                                                  y2="15.5">

                                            </line>
                                            <polyline fill="none" points="4.6 20.5 1.5 20.5 1.5 4.5 20.5 4.5 20.5 18.4"
                                                      strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="3">

                                            </polyline>
                                            <polyline fill="none" points="20.5 9 29.5 9 30.5 22 24.7 22"
                                                      strokeLinejoin="round"
                                                      strokeMiterlimit="10" strokeWidth="3">

                                            </polyline>
                                        </g>
                                    </svg>
                                </div>
                                <div className="stepper__step-text">
                                    Hoàn thành
                                </div>
                                <div className="stepper__step-date">
                                    {
                                        status >= 4 ? (<p>{listStatus[2]?.createDate}</p>): ""
                                    }
                                </div>
                            </div>
                            <div className="stepper__step stepper__step--finish">
                                <div className={`stepper__step-icon ${status >=5 ? " stepper__step-icon--finish" : ""} `}>
                                    <svg enableBackground="new 0 0 32 32" viewBox="0 0 32 32" x="0" y="0"
                                         className="shopee-svg-icon icon-order-rating">
                                        <polygon fill="none"
                                                 points="16 3.2 20.2 11.9 29.5 13 22.2 19 24.3 28.8 16 23.8 7.7 28.8 9.8 19 2.5 13 11.8 11.9"
                                                 strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10"
                                                 strokeWidth="3">

                                        </polygon>
                                    </svg>
                                </div>
                                <div className="stepper__step-text">
                                    Đánh giá
                                </div>
                                <div className="stepper__step-date">
                                    {
                                        status >= 5 ? (<p>{listStatus[3]?.createDate}</p>): ""
                                    }
                                </div>
                            </div>
                            <div className="stepper__line">
                                <div className="stepper__line-background" style={{backgroundColor: "rgb(224, 224, 224)"}}>
                                </div>

                                <div className="stepper__line-foreground"
                                     style={{
                                         width: `calc((100% - 140px) * ${convertStatus(status) / 3}`,
                                         backgroundColor: "rgb(45, 194, 88)"
                                     }}>
                                </div>

                                {/*<div className="stepper__line-foreground"*/}
                                {/*     style="width: calc((100% - 140px) * 3/4; background: rgb(45, 194, 88);">*/}
                                {/*</div>*/}

                            </div>
                        </div>
                    ) :
                    (<div className="stepper">
                            <input hidden id="order_id" name="order_id" value="1"/>


                            <div className="stepper__step stepper__step-icon--finish cancel-st"
                                 style={{color: 'red !important', borderColor: '#ed3c3c !important'}}>
                                <div
                                    className={`stepper__step-icon cancel-st ${status > 0 ? " stepper__step-icon--finish" : ""} `}
                                    style={{color: 'red !important', borderColor: '#ed3c3c !important'}}>
                                    <svg enableBackground="new 0 0 32 32" viewBox="0 0 32 32" x="0" y="0"
                                         className="shopee-svg-icon icon-order-problem" style={{color: 'red'}}>
                                        <g>
                                            <g>
                                                <path
                                                    d="m5 3.4v23.7c0 .4.3.7.7.7.2 0 .3 0 .3-.2.5-.4 1-.5 1.7-.5.9 0 1.7.4 2.2 1.1.2.2.3.4.5.4s.3-.2.5-.4c.5-.7 1.4-1.1 2.2-1.1s1.7.4 2.2 1.1c.2.2.3.4.5.4s.3-.2.5-.4c.5-.7 1.4-1.1 2.2-1.1.9 0 1.7.4 2.2 1.1.2.2.3.4.5.4s.3-.2.5-.4c.5-.7 1.4-1.1 2.2-1.1.7 0 1.2.2 1.7.5.2.2.3.2.3.2.3 0 .7-.4.7-.7v-23.7z"
                                                    fill="none" strokeLinecap="round" strokeLinejoin="round"
                                                    strokeMiterlimit="10" strokeWidth="3">

                                                </path>
                                            </g>
                                            <line fill="none" strokeLinecap="round" strokeLinejoin="round"
                                                  strokeMiterlimit="10" strokeWidth="3" x1="16" x2="16" y1="9" y2="15">

                                            </line>
                                            <circle cx="16" cy="20.5" r="2" stroke="none">

                                            </circle>
                                        </g>
                                    </svg>
                                </div>
                                <div className="stepper__step-text">
                                    Đã hủy
                                </div>
                                <div className="stepper__step-date">
                                    {(<p>{listStatus[listStatus.length-1]?.createDate}</p>)}
                                </div>
                            </div>


                        </div>
                    )
            }

        </>
    );
}
