import { Form} from "antd";
import UploadSingle from "../../../../components/UploadSingle";
import http from "../../../../services";
import Template from 'src/assets/template/Nhap_danh_sach_khach_hang_new.xlsx'
import React, {useState} from "react";
import Notice from "../../../../components/Notice";
import CustomModal from "../../../../components/Modal/CustomModal";
import SpinCustom from "../../../../components/Spin";
import Button from "src/components/MyButton/Button"
export const ImportFileCustomerCreate = ({open, cancel, refreshData}) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false);

    const handleFileListChange = (fileList) => {

    };

    const onFinish = () => {

        form.validateFields().then(values=>{
            setLoading(true)
            const formData = new FormData();
            formData.append('file', values.file.fileList[0].originFileObj);
            http.post("ManageCustomer/ImportUser", formData).then(resp => {
                if (!resp.isError) {
                    Notice({msg: `Bạn đã tải file thành công`});
                    cancel();
                    refreshData();
                }
                     setLoading(false);
            })
        }).catch(reason => {
            setLoading(false);
        })
    };
    const renderFooter = () => (
        <div className="d-flex justify-content-flex-end">
            <Button
                btnType="primary"
                className="btn-hover-shadow"
                onClick={() => onFinish()}
                disabled={loading}
            >
                Lưu
            </Button>
            <Button
                className="btn-hover-shadow"
                onClick={() => cancel()}
            >
                Đóng
            </Button>
        </div>
    )
    return (
        <>
            <CustomModal
                title={"Thêm mới khách hàng"}
                footer={renderFooter()}
                width={1600}
                open={!!open}
                onCancel={cancel}
            >
                <SpinCustom spinning={loading}>
                    <div className={"import-cover__ctn"}>
                        <div style={{marginBottom: 20}}>
                            <DownloadButton/>
                        </div>
                        <div className={"update"}>
                            <Form form={form}>


                                {/* Component UploadSingle */}
                                <Form.Item name="file" label="Upload File" style={{marginBottom: 20}}
                                           rules={[
                                               {
                                                   required: true,
                                                   message: 'Xin hãy upload file của bạn',
                                               },
                                           ]}
                                >
                                    <UploadSingle getFileList={handleFileListChange} maxCount={1}/>
                                </Form.Item>


                            </Form>
                        </div>
                    </div>
                </SpinCustom>
            </CustomModal>
        </>
    )

}


export const DownloadButton = () => {
    return (
        <>
            <div className={"d-flex"}>
                <p style={{fontSize: 14, marginRight: 8}}>Tải template</p>
                <a
                    href={Template}
                    download="TemplateImportUser"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    tại đây
                </a>
            </div>
        </>
    );
};
