import {Button} from "antd";
import {ORDER_STATUS} from "../../../../constants/constants";
import "./orderDetailStatus.css"
import React, {lazy, useState} from "react";
import {ContractPreview} from "../../../Contract/ContractPreview/ContractPreview";
import CustomModal from "../../../../components/Modal/CustomModal";
import CancelModal from "../../../../components/Modal/CancelOrderModal/CancelModal";
import FeedBackProductModal from "../../../../components/Modal/FeedBackModal/FeedBackProductModal";
import {useNavigate} from "react-router-dom";
import ROUTER from "../../../../router";
// import {ContractPreview} from "../../../Contract/ContractPreview/ContractPreview";
const Hello = lazy(() => import("../../../Contract/ContractPreview/ContractPreview"));
export const OrderDetailStatusBox = ({title,contract,order_id,productId,slug,setLoading}) => {

    const [showContract, setShowContract] = useState(false);
    const [showCancel, setShowCancel] = useState(false);
    const [showFeedBack, setShowFeedBack] = useState(false);
        const refreshData=()=>{
            setLoading(false);
        }
        const navigate=useNavigate();

    const handleShowContract = () => {
        setShowContract(true);
    }
    const handleCancelContract = () => {
        setShowContract(false);
    }
    const handleShowCancelModal = () => {
        setShowCancel(true);
    }
    const handleHideCancelModal = () => {
        setShowCancel(false);
    }
    const handleShowFeedBackModal = () => {
        setShowFeedBack(true);


    }
    const handleHideFeedBackModal = () => {
        setShowFeedBack(false);

    }
    const handleRefreshFeedBackChange = () => {
        setShowFeedBack(false)
    }

    const renderButton = () => {
        switch (title) {
            case ORDER_STATUS.CHO_XAC_NHAN:
                return <Button className="box__item" onClick={()=>handleShowCancelModal()}>Huỷ đơn hàng</Button>;
            case ORDER_STATUS.CHO_KY:
                return (
                    <div className="box-item__container">
                        <Button className="box__item sign-btn" onClick={handleShowContract}>Ký hợp đồng</Button>
                        <Button className="box__item" onClick={()=>handleShowCancelModal()}>Huỷ đơn hàng</Button>
                    </div>
                );
            case ORDER_STATUS.HOAN_THANH:
                return (
                    <div className="box-item__container">
                        <Button className="box__item" onClick={()=>navigate(`/${slug}-${productId}`)}>Mua Lại</Button>
                        <Button className="box__item rate-btn" onClick={()=>handleShowFeedBackModal()}>Đánh giá</Button>
                    </div>
                );
            default:
                return null;
        }
    };
    return (
        <>
            <div className={"box__container"}>
                {renderButton(title)}
            </div>
            {showContract && (
                <ContractPreview
                    open={()=>handleShowContract()}
                    onCancel={() => handleCancelContract()}
                    contract_html={contract}
                    order_id={order_id}
                    refreshData={refreshData}
                    onLoading={setLoading}
                />
            )}

            {showCancel && (
                <CancelModal
                    onCancel={() => handleHideCancelModal()}
                    visible={showCancel}
                    order_id={order_id}
                    refreshData={refreshData}
                />
            )}
            {
                showFeedBack && (
                    <FeedBackProductModal
                        onCancel={() => handleHideFeedBackModal()}
                        visible={showFeedBack}
                        product_id={productId}
                        refreshData={() => handleRefreshFeedBackChange()}
                        orderId={order_id}
                        onLoading={setLoading}
                    />
                )
            }
        </>
    )
}
