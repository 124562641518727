 import React, {useEffect, useRef, useState} from 'react';
import SignatureCanvas from 'react-signature-canvas';
import "./contractPreview.css"
import Button from "../../../components/MyButton/Button";
import Modal from 'react-bootstrap/Modal';
import http from "../../../services";
import Swal from "sweetalert2";
import {useNavigate} from "react-router-dom";
import SpinCustom from "../../../components/Spin";


export const ContractPreview = ({ onCancel, open, contract_html,order_id,refreshData,onLoading}) => {
    // let contract = useRef();
    const [contract, setContract] = useState(null);
    const sigRef = useRef();
    const [signature, setSignature] = useState(null);
    const navigate=useNavigate();
    const [loading, setLoading] = useState(false)
    const handleSignatureEnd = () => {
        setSignature(sigRef.current.toDataURL());
    };

    const clearSignature = () => {
        sigRef.current.clear();
        setSignature(null);
    };
    const showSuccess = () => {
        onLoading(false)
        Swal.fire({
            icon: 'success',
            title: 'Thành Công',
            text: 'Bạn đã kí hợp đồng thành công!',
        }).then(() => {
            navigate('/orders');
            refreshData();
        });
    };
    const showError = (message) => {
        onLoading(false)
        Swal.fire({
            icon: 'error',
            title: 'Thất bại',
            text: message,
        }).then(() => {
            // navigate('/orders');
        });
    };


    const convertSignature = (sign) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(contract, 'text/html');
        const body = doc.querySelector('body');

        // Lấy danh sách các thẻ có class "sign-item"
        const signItems = body.querySelectorAll('.sign-item');
        // Lặp qua danh sách và xóa chúng
        signItems.forEach(item => {
            item.remove();
        });

        const signPlace = body.querySelector('#sign-place');
        if(signPlace){
            const parentElement = signPlace.parentElement;
            parentElement.removeChild(signPlace);
            parentElement.innerHTML+=sign
        }
        // body.innerHTML += sign;
        const modifiedHtml = new XMLSerializer().serializeToString(doc);
        setContract(modifiedHtml)
    }
    const clearSignItem = () => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(contract, 'text/html');
        const body = doc.querySelector('body');
        const signItems = body.querySelectorAll('.sign-item');
        signItems.forEach(item => {
            item.innerHTML = '';
        });
        const modifiedHtml = new XMLSerializer().serializeToString(doc);
        setContract(modifiedHtml)
    }
    const handleSave = () => {
        if(signature){
            // const htmlString = `
            //       <div class="sign-item" style="display: flex; justify-content: end; height: 120px">
            //         <img src="${signature}" style="height: 100%; width: auto; margin-right: 138px" alt="Chữ kí" />
            //       </div>`;

            const htmlString = `
<!--                  <div class="sign-item" style="height: 120px">-->
                    <div id="sign-place" style="display: flex; justify-content: center"><img class="sign-item" src="${signature}" style="height: 60px; width: auto;" alt="Chữ kí" /></div>`;
            convertSignature(htmlString)
        }
    }

    // useEffect(() => {
    //
    // }, [signature]);

    useEffect(() => {
        const new_html=handleInitContract();
        setContract(new_html)
        // setContract(contract_html);
    }, [contract_html]);
    const handleInitContract=()=>{
        const sign=`<input id="sign-place" disabled="disabled" type="hidden" value="Chữ ký khách hàng" placeholder="Chữ ký khách hàng">`;
        const parser = new DOMParser();
        const doc = parser.parseFromString(contract_html, 'text/html');
        const body=doc.querySelector("body");
        const signPlace = body.querySelector('#sign-place');
        if(signPlace){
            const parentElement = signPlace.parentElement;
            parentElement.removeChild(signPlace);
            parentElement.innerHTML+=sign
        }
        const modifiedHtml = new XMLSerializer().serializeToString(doc);
        return modifiedHtml;
    }

    const handleSubmit = async () => {
        const check=checkValid();
        if(check===false){
            showError('Bạn phải thêm chữ ký để hoàn thành hợp đồng');
            return;
        }
            onLoading(true);
            onCancel(true);
        // Tạo một tệp HTML từ chuỗi HTML
        const blob = new Blob([contract], {type: 'text/html'});
        const formData = new FormData();
        formData.append('file', blob, `contract_order${order_id}.html`);
        await http.post(`Contract/SignContract?orderId=${order_id}`, formData).then(resp=>{
            if(!resp.isError){
                showSuccess();
            }
        }).catch(reason => {
           showError('đã có lỗi xảy ra')
        }).finally(() => {
            onLoading(false);
            // onCancel();
        });
    }
    const checkValid=()=>{
        const parser = new DOMParser();
        const doc = parser.parseFromString(contract, 'text/html');
        const body = doc.querySelector('body');
        // Lấy danh sách các thẻ có class "sign-item"
        const signItems = body.querySelectorAll('.sign-item');
        if(signItems&&signItems.length>0){
            return true;
        }
        return false;
    }
    return (
        <SpinCustom spinning={loading}>
        <div>
            <Modal show={open} onHide={onCancel}  closable={false}
                   dialogClassName="cus-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Chi tiết hợp đồng</Modal.Title>
                </Modal.Header>
                <Modal.Body className={"cus-modal-body"}>
                    <div className={"contract-container"}>
                        <div className={"contract-c"}
                             dangerouslySetInnerHTML={{__html: contract || '<div></div>'}}></div>
                        <div className={"sign-contract__container"}>
                            <div className={"conten-sign"} style={{marginRight:40}}>
                                <SignatureCanvas
                                    penColor="black"
                                    canvasProps={{className: 'signature'}}
                                    ref={sigRef}
                                    onEnd={handleSignatureEnd}
                                    width={"400px"}
                                />
                                <div style={{display: 'flex',justifyContent:"center"}}>
                                    <Button disabled={loading} style={{marginRight: 6}} onClick={clearSignature}>Làm mới</Button>
                                    <Button disabled={loading} onClick={handleSave}>Lưu chữ kí</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onCancel}  disabled={loading}>
                        Đóng
                    </Button>
                    <Button btnType="primary" variant="primary"  disabled={loading} onClick={handleSubmit}>
                        Ký hợp đồng
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
        </SpinCustom>

    );
};


