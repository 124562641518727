import { Anchor, Avatar, Col, Descriptions, Divider, Form, Input, Row, Select, Space, Table, Tooltip, TreeSelect } from "antd"
import TextArea from "antd/es/input/TextArea"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import CustomModal from "src/components/Modal/CustomModal"
import Button from "src/components/MyButton/Button"
import Notice from "src/components/Notice"
import SpinCustom from "src/components/Spin"
import { STATUS_OPTION } from "src/constants/constants"
import ContactService from "src/services/ContactService"
import UserService from "src/services/UserService"
import ProductionService from "src/services/ProductionService"

const DetailInventory = ({ detailInfo, onOk, ...props }) => {
    const [loading, setLoading] = useState(false);
    const labelStyle = {fontWeight: 'bold', color: 'black'};

    //#region useEffect
    //#endregion
    
    //#region function
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
    
        return [day, month, year].join('-');
      };
    //#endregion

    const renderFooter = () => (
        <div className="d-flex justify-content-flex-end">
            <Button
                className="btn-hover-shadow"
                onClick={() => props?.onCancel()}
            >
                Đóng
            </Button>
        </div>
    )

    return (
        <CustomModal
            title={"Chi tiết tồn kho"}
            footer={renderFooter()}
            width={800}
            {...props}
        >
        <SpinCustom spinning={loading}>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Descriptions title="">
                        <Descriptions.Item label="Mã tồn kho" labelStyle={labelStyle} span={3}>{detailInfo?.inventoryID}</Descriptions.Item>
                        <Descriptions.Item label="Tên sản phẩm" labelStyle={labelStyle} span={3}>{detailInfo?.productName}</Descriptions.Item>
                        <Descriptions.Item label="Tên phân loại" labelStyle={labelStyle} span={3}>{detailInfo?.productItemName}</Descriptions.Item>
                        <Descriptions.Item label="Ngày tạo" labelStyle={labelStyle} span={2}>{formatDate(detailInfo?.createDate)}</Descriptions.Item>
                        <Descriptions.Item label="Trạng thái" labelStyle={labelStyle}>{detailInfo?.isSold ? 'Đã bán' : 'Chưa bán'}</Descriptions.Item>
                        <Descriptions.Item label="Nội dung" labelStyle={labelStyle}>{detailInfo?.content}</Descriptions.Item>
                    </Descriptions>
                </Col>        
            </Row>
        </SpinCustom>
        </CustomModal>
    )
}

export default DetailInventory