import {ModalWrapper} from "../../../../components/Modal/CustomModal/styled";
import cn from "../../../../lib/classnames";
import styles from "../../../../components/Modal/CustomModal/styles.module.scss";
import PropTypes from "prop-types";

export const CustomerModal = (props) => {

    const { children, className, tilteStart, hiddenScroll } = props

    return (
        <ModalWrapper
            style={{ top: 10 ,
            }}
            {...props}
            className={cn(className, { [styles.titleFlexStart]: tilteStart })}
            hiddenScroll={hiddenScroll}
            maskTransitionName=""
            closable={false}
        >
            <div style={{width:"100%"}}>
                {children}
            </div>

        </ModalWrapper>
    )
}
CustomerModal.propTypes = {
    tilteStart: PropTypes.bool,
    className: PropTypes.string,
    hiddenScroll: PropTypes.bool,
}

CustomerModal.defaultProps = {
    tilteStart: true,
    hiddenScroll: false,
    className: "",
}


