import { Col, Row } from "antd"
import { useEffect } from "react"
import LayoutCommon from "src/components/Common/Layout"
import RelateToNews from "../News/component/RelateToNews"
import Detail from "./component/Detail"
import { NewsDetailStyled } from "./styled"
const NewDetail = () => {
  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  return (
    <NewsDetailStyled>
      <LayoutCommon>
        <Row gutter={[24, 24]} className="mt-24">
          <Col xs={24} md={16}>
            <Detail />
          </Col>
          <Col xs={24} md={8}>
            <RelateToNews />
          </Col>
        </Row>
      </LayoutCommon>
    </NewsDetailStyled>
  )
}

export default NewDetail
