import React, {useEffect, useState} from "react";

import http from "../../../../../services";
import {SignatureListTable} from "./SignatureListTable";


export const SingatureManagement=()=>{

    const [signatures, setSignatures] = useState([])
    const [totalPage, setTotalPage] = useState([])


    const [pagination, setPagination] = useState({
        "textSearch": "",
    })
    const getListData = () => {
        http.post('/Contract/GetContractSignature',{...pagination})
            .then(res => {
                if (!res?.isError) {
                    setSignatures(res.object)
                    setTotalPage(res.object.listSignature.size());
                }else{
                    setSignatures([]);
                }

            })
    }
    const handleFilter =(model)=>{
        setPagination(model)
    }
    const updateContract = (signatureID, updatedObject) => {
        setSignatures((prevOrder) =>
            prevOrder.map((s) =>
                (s.signatureID === signatureID ? updatedObject : s)
            )
        );
    };


    useEffect(() => {
        getListData();
        // console.log('pagi',pagination);
    }, [pagination]);


    const onChangeKeyword = (key: string) => {
        setSignatures([]);
        const newPagi = {...pagination, textSearch: key};
        setPagination(newPagi);
    }


    const handleChangePage=(model) => {
        setPagination(model)
    }
    return (
        <>
            <div className={" page-container"}>
                <div className={"main-nav__content"}>

                </div>

                <div className={"content-contracts"}>
                    <SignatureListTable data={signatures}  refreshData={()=>getListData()} totalPage={totalPage}
                                       pagination={pagination} getOrder={handleChangePage}
                                       onChangeKeyword={onChangeKeyword}
                                       handleFilter={handleFilter}
                    />
                </div>

            </div>
        </>


    )

}
