import styled from "styled-components"

export const StyleSearchList = styled.div`
  padding: 0px 190px 60px 190px;

  .ant-btn-primary {
    background: #fff;
    border-radius: 8px;
  }

  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    border-radius: 8px;
  }
  .ant-input-group-wrapper {
    border: 1px solid #dddddd;
    border-radius: 8px;
    margin-bottom: 30px;
  }

  .ant-input-group-addon,
  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button:not(.ant-btn-primary) {
    border-radius: 8px;
    --antd-wave-shadow-color: none;
  }
  .ant-btn {
    height: 56px;
    border: none;
    box-shadow: none;
    outline: none;
    border-radius: 8px;
  }
  .search-post-input {
    .ant-input {
      height: 56px;
      border: none;

      box-shadow: none;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 120%;
      color: #212529;
    }
  }
`

export const StyleSearchList2 = styled.div`
  padding: 50px 190px 60px 190px;
  background-color: #fff;
  box-shadow: 0px 4px 40px rgba(0, 81, 139, 0.15);
  border-radius: 10px;
  margin-top: 10px;
  .ant-btn-primary {
    background: #fff;
    border-radius: 8px;
  }

  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    border-radius: 8px;
  }
  .ant-input-group-wrapper {
    border: 1px solid #dddddd;
    border-radius: 8px;
    margin-bottom: 30px;
  }

  .ant-input-group-addon,
  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button:not(.ant-btn-primary) {
    border-radius: 8px;
    --antd-wave-shadow-color: none;
  }
  .ant-btn {
    height: 56px;
    border: none;
    box-shadow: none;
    outline: none;
    border-radius: 8px;
  }
  .search-post-input {
    .ant-input {
      height: 56px;
      border: none;

      box-shadow: none;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 120%;
      color: #212529;
    }
  }
`
export const StyleSearchTagHeader = styled.div`
  margin: 30px auto;
  .name-for {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #777777;
  }
  .name-tag {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 120%;
    color: #ed1117;
    margin-top: 20px;
  }
`

export const SearchRecommendStyle = styled.div`
  background-color: #fff;
  width: 500px;
  max-height: 500px;
  overflow: hidden auto;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0px 4px 40px rgba(0, 81, 139, 0.15);
`

export const ResultItemStyle = styled.div`
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  .img-poster {
    width: 100%;
    height: 60px;
    object-fit: cover;
    border: 1px solid #f0f0f0;
  }
  .title {
    font-size: 16px;
    font-weight: 600;
  }
  .time-post {
    color: #999;
    & > * {
      font-size: 11px;
    }
    svg {
      width: 12px;
      margin-left: 6px;
    }
  }
`
