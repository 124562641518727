// MainComponent.js
import React, {useEffect, useState} from 'react';

import CustomModal from "../../../../components/Modal/CustomModal";
import Button from "../../../../components/MyButton/Button";
import {Tabs} from "antd";
import Swal from "sweetalert2";
import SpinCustom from "../../../../components/Spin";
import RegionService from "../../../../services/RegionService";
import http from "../../../../services";
import moment from "moment";
import {CustomerInfor} from "../component/CustomerInfor";
import {TakeCareTab} from "../component/TakeCareTab";
import  styles from'../style/customer-detail-modal.css'
import {CustomerCareListTable} from "../List/CustomerCareListTable";
import {OrderHistoryButton} from"../component/OrderHistoryButton";


export const CustomerDetailModal = ({open, cancel, refreshData, userId,...props}) => {
    moment.locale('en');
    const [loading, setLoading] = useState(false);
    const [listWard, setListWard] = useState([]);
    const [listProvince, setListProvince] = useState([]);
    const [listDistrict, setListDistrict] = useState([]);
    const [isRefresh, setIsRefresh] = useState(true);
    const [isRefreshCareTab, setIsRefreshCareTab] = useState(true);
    const [userInfo, setUserInfo] = useState();
    const getListProvinceVN = () => {
        setLoading(true)
        RegionService.getByRegionId({regionId: 234})
            .then(res => {
                if (res?.isError) return
                setListProvince(res?.object);
                loadListDistrict(userInfo?.provinceID);
                loadListWard(userInfo?.districtID);
            })
            .finally(() => setLoading(false))
    }
    const handleRefreshDate=()=>{
        refreshData();
        setIsRefresh(!isRefresh);
    }
    const loadListDistrict = (newValue) => {
        if (newValue && newValue !== 0) {
            RegionService.getByRegionId({regionId: newValue})
                .then(res => {
                    if (res?.isError) return
                    const lstDistrict = res?.object || [];
                    setListDistrict(lstDistrict)
                })
        }
    }
    const loadListWard = (newValue) => {
        if (newValue && newValue !== 0) {
            RegionService.getByRegionId({regionId: newValue})
                .then(res => {
                    if (res?.isError) return
                    const listWard = res?.object || [];
                    setListWard(listWard)
                })
        }
    }
    const getCustomerDetail = () => {
        http.post(`/ManageCustomer/GetCustomer?UserID=${userId}`).then(resp => {
            if (!resp.isError) {

                setUserInfo(resp?.object);
            }
        })
    }
    useEffect(() => {
        getCustomerDetail();
    }, [isRefresh])

    useEffect(() => {
        loadListDistrict(userInfo?.provinceID);
        loadListWard(userInfo?.districtID);
    }, [userInfo])
    useState(() => {
        getListProvinceVN();
    }, [])

    const handleSubmit = () => {
    }
    const showSuccess = (message) => {
        Swal.fire({
            icon: 'success',
            title: 'Thành Công',
            text: message,
        }).then(() => {
            // navigate(ROUTER.TEMPLATE_HOP_DONG);
            refreshData();
            cancel();
        });
    };

    const renderFooter = () => (
        <div className="d-flex justify-content-flex-end">
            <Button
                className="btn-hover-shadow"
                onClick={() => cancel()}
            >
                Đóng
            </Button>
        </div>
    )
    const onChange = (key) => {
    };
    const tabs = [
        {
            id: 2,
            label: "Lịch sử chăm sóc khách hàng ",
            content: <TakeCareTab userInfo={userInfo} isRefresh={isRefreshCareTab}
                                  customerSelected={props?.customerSelected}
                                  refreshTable={refreshData}
            />
        },
        // {
        //     id: 3,
        //     label: "Lịch sử đơn hàng đã mua ",
        //     content: <OrderHistoryButton userID={userInfo?.userID}/>
        // },
    ]

    return (
        <>
            <CustomModal
                title={`Thông tin khách hàng ${props?.isCompany?"doanh nghiệp":"cá nhân"}`}
                footer={renderFooter()}
                width={1800}
                open={!!open}
                onCancel={cancel}
            >
                <SpinCustom spinning={loading}>
                    <div className={"d-flex"}>
                        <CustomerInfor userDetail={userInfo} refreshData={handleRefreshDate}
                                       refreshCareTab={()=>setIsRefreshCareTab(!isRefreshCareTab)} isCompany={props?.isCompany?true:false} />
                        <div className={"col-8"}>
                        <Tabs
                            className={styles.tabActiveCustom}
                            onChange={onChange}
                            type="card"
                            items={tabs.map((tab, i) => {
                                return {
                                    label: tab?.label,
                                    key: tab?.id,
                                    children: tab?.content,
                                };
                            })}
                        />
                        </div>
                    </div>
                </SpinCustom>
            </CustomModal>
        </>
    );
};

