import {Col, DatePicker, Row, Select} from "antd"
import {useSelector} from "react-redux"
import FlInput from "src/components/FloatingLabel/Input"
import FlSelect from "src/components/FloatingLabel/Select"
import {ORDER_STATUS} from "src/constants/constants"
import {SearchStyled} from "../../Production/styled";
import dayjs from "dayjs";
import http from"src/services"
import {useEffect, useState} from "react";

export const SearchSignature = ({onChangeKeyword}) => {


    return (
        <SearchStyled>
            <Row gutter={[16, 16]}>
                <Col flex="auto">
                    <FlInput
                        search
                        allowClear
                        label="Tìm kiếm theo tên chữ ký "
                        onSearch={e => onChangeKeyword(e)}
                    />
                </Col>

            </Row>
        </SearchStyled>
    )
}
