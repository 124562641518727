import SpinCustom from "../../../../components/Spin";
import {Col, Form, Image, Input, Row, Select, TreeSelect, Upload} from "antd";
import FlInput from "../../../../components/FloatingLabel/Input";
import Button from "../../../../components/MyButton/Button";
import React, {useEffect, useState} from "react";
import FlSelect from "../../../../components/FloatingLabel/Select";
import {CustomerModal} from "../modal/CustomerModal";
import {ButtonUploadStyle} from "../../Production/styled";
import SvgIcon from "../../../../components/SvgIcon";
import {getListPost, save, update} from "./api/api-banner";
import Notice from "../../../../components/Notice";
import FileService from "../../../../services/FileService";
import {TabsProps} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import "../style/banner-footer.css"

const type: TabsProps['type'] = [
    {
        key: 1,
        label: 'Banner',
    },
    {
        key: 2,
        label: 'Footer',
    }
];

export const items: TabsProps['items'] = [
    {
        key: 1,
        label: 'Không kích hoạt',
    },
    {
        key: 0,
        label: 'Kích hoạt',
    },

];
export const ImportBannerFooter = ({visible, onOk, onCancel, dataInfo}) => {
    const Option = Select.Option;
    const history = useNavigate();

    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [fileUrl, setfileUrl] = useState(dataInfo?.icon);

    const [category, setCategory] = useState(Number(dataInfo?.type || 1));
    const [TextSearch, setTextSearch] = useState("")
    const [selectedNode, setSelectedNote] = useState()
    const [listPost, setListPost] = useState([])
    const [isLink, setIsLink] = useState()
    const [selectedValue, setSelectedValue] = useState(dataInfo?.content);


    const uploadFile = async (file) => {
        try {
            if (file.size > 5 * 1024 * 1024 || !isImageFile(file)) {
                Notice({
                    msg: "Hãy chọn file ảnh có kích thước nhỏ hơn 5MB",
                    isError: true,
                })
                return;
            }

            setLoading(true);
            const formData = new FormData();
            formData.append('InsertFileList', file);

            const res = await FileService.uploadFileList(formData);

            if (res?.isError) {
                // Xử lý lỗi từ phía máy chủ (nếu cần)
                return;
            }

            setfileUrl(res?.object[0]?.fileUrl);
        } finally {
            setLoading(false);
        }
    };

    const isImageFile = (file) => {
        const acceptedImageTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/svg+xml'];

        return acceptedImageTypes.includes(file.type);
    };

    const uploadProps = {
        accept: "image/*",
        multiple: false,
        fileList: [],
        beforeUpload: file => {
            uploadFile(file)
            return false
        },
    }


    useEffect(() => {
        let model = dataInfo;
        if (dataInfo) {
            if (parseInt(dataInfo.type) >= 2) {
                model = {...dataInfo, type: "2", typeFooter: parseInt(dataInfo.type)}
            }
        }
        form.setFieldsValue(model)
    }, [])

    const handleSubmit = () => {
        form.validateFields().then(values => {
            values.icon = fileUrl
            values.content = selectedValue
            setLoading(true)
            if (dataInfo) {
                let model = {}
                if (category == 1) {
                    model = {
                        "type": parseInt(values.type),
                        "title": fileUrl,
                        "content": values.content,
                        "icon": null,
                        "id": dataInfo.id,
                        "isDelete": 1
                    }
                } else {
                    model = {
                        "type": parseInt(values.typeFooter),
                        "title": values.title,
                        "content": values.content,
                        "icon": fileUrl,
                        "id": dataInfo.id,
                        "isDelete": 1
                    }
                }
                update(model)
                    .then(res => {
                        if (!res?.isError) {
                            Notice({
                                msg: "Cập nhật thành công",
                                isSuccess: true,
                            })
                            onOk()
                        }
                    })
                    .finally(() => {
                        setLoading(false)
                    })


            } else {
                setLoading(false);
                let model = {}
                if (category == 1) {
                    model = {
                        "type": parseInt(values.type),
                        "title": fileUrl,
                        "content": values.content,
                        "icon": null
                    }
                } else {
                    model = {
                        "type": parseInt(values.typeFooter),
                        "title": values.title,
                        "content": values.content,
                        "icon": fileUrl
                    }
                }

                save(model)
                    .then(res => {
                        if (!res?.isError) {

                            Notice({
                                msg: category == 1 ? "Thêm mới bài viết thành công" : "Thêm footer thành công",
                                isSuccess: true,
                            })
                            onOk();
                        }
                    })
                    .finally(() => {
                        setLoading(false)

                    })
            }
        })
    }


    const getPost = () => {
        setLoading(true)
        getListPost().then(res => {
            if (!res?.isError) {
                if (res?.object?.Data.length > 0) {
                    setListPost(res?.object?.Data)

                }
                // setTotal(result?.object?.Total)
            }
        })
        setLoading(false)
    }

    useEffect(() => {
        getPost()
    }, [])


    return (
        <CustomerModal
            title={dataInfo ? "Sửa thông tin" : "Thêm Banner&Footer"}
            visible={visible}
            style={{
                maxWidth: "1200px",
                minHeight: 800

            }}
            className={"cus-m-title"}
            width={"auto"}
            onCancel={onCancel}
            footer={
                <div style={{display: "flex", justifyContent: "flex-end"}}>
                    <Button btnType="third" onClick={onCancel}>
                        Đóng
                    </Button>
                    <Button btnType="primary" onClick={() => handleSubmit()}>
                        Xác nhận
                    </Button>
                </div>
            }
        >
            <SpinCustom spinning={loading}>
                <Form form={form} style={{padding: "20px 10px"}}>
                    <Row gutter={[16, 16]}>

                        <Col span={24}>
                            <Form.Item
                                style={{width: "100% !important"}}
                                name="type"
                                label={"Thể loại"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Thông tin không được để trống!",
                                    },
                                ]}
                                initialValue={1}
                            >
                                <FlSelect
                                    disabled={!!dataInfo?.type}
                                    name="type"
                                    isRequired
                                    onChange={(e) => {
                                        setCategory(e)
                                    }}

                                >
                                    {dataInfo?.type ?
                                        <> <Option value="1">Banner</Option>
                                            <Option value="2">Footer</Option>
                                        </>
                                        :
                                        <>
                                            {
                                                type?.map(i => (
                                                    <Option key={i.key} value={i.key}>
                                                        {i?.label}
                                                    </Option>
                                                ))
                                            }
                                        </>

                                    }


                                </FlSelect>
                            </Form.Item>
                        </Col>


                        <Col span={24}>

                            {category >= 2 &&
                                <>
                                    <Form.Item
                                        style={{width: "100% !important"}}
                                        name="typeFooter"
                                        label="Kiểu footer"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Thông tin không được để trống!",
                                            },
                                        ]}
                                    >
                                        <FlSelect

                                            name="typeFooter"
                                            isRequired
                                            onChange={(e) => {
                                            }}
                                        >
                                            <Option value={2}>Thông tin liên hệ</Option>
                                            <Option value={3}>Chính sách Chung</Option>
                                            <Option value={4}>Fanpage</Option>

                                        </FlSelect>
                                    </Form.Item>

                                    <Form.Item
                                        style={{width: "100% !important"}}
                                        name="title"
                                        label="Tiêu đề"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Thông tin không được để trống!",
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Nhập tiêu đề"/>
                                    </Form.Item>


                                    <Col span={24} style={{padding: 0}}>

                                        <Form.Item
                                            label="Chọn icon"
                                            name="icon"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Thông tin không được để trống",
                                                },
                                            ]}
                                        >
                                            <Upload {...uploadProps}
                                            >
                                                <Row className="align-items-center">
                                                    <ButtonUploadStyle>
                                                        <Button className="account-button-upload ">
                                                            <Row
                                                                className="account-background-upload d-flex align-items-center">
                                                                <SvgIcon name="add-media-video"/>
                                                                <div className="account-text-upload ml-16">
                                                                    Chọn ảnh
                                                                </div>
                                                            </Row>
                                                        </Button>
                                                    </ButtonUploadStyle>
                                                    <div className="sub-color fs-12 ml-16">
                                                        Dung lượng file tối đa 5MB, định dạng: .JPG, .JPEG,
                                                        .PNG, .SVG
                                                    </div>
                                                </Row>
                                            </Upload>
                                        </Form.Item>
                                    </Col>
                                    {/*{ fileUrl &&*/}

                                    <Col span={5}>
                                        {fileUrl ? <Image
                                            src={fileUrl}
                                            alt="image"
                                            loading="lazy"
                                            width={200}
                                            style={{marginBottom: "33px"}}
                                        /> : <Image src={dataInfo?.icon}></Image>}

                                    </Col>
                                </>
                            }

                        </Col>

                        <Col span={24}>
                            {listPost && listPost?.length > 0 &&category==1&&
                                <Form.Item
                                    style={{width: "100% !important"}}
                                    name="content"
                                    label={"Nội dung"}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Thông tin không được để trống!",
                                        },
                                    ]}
                                >
                                    <TreeSelect
                                        showSearch
                                        isRequired
                                        style={{width: '80%'}}
                                        dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                        placeholder="Chọn bài viết"
                                        allowClear
                                        treeDefaultExpandAll
                                        name="content"
                                        value={dataInfo?.content || selectedValue}
                                        onChange={(value, label, extra) => {
                                            setSelectedValue(value)
                                        }}
                                        treeData={listPost.map(node => ({
                                            label: node.title,
                                            value: node.slug + "-" + node.postID,
                                        }))}
                                    />
                                </Form.Item>
                            }
                            {listPost && listPost?.length > 0 &&category!=1&&
                                <Form.Item
                                    style={{width: "100% !important"}}
                                    name="content"
                                    label={"Nội dung"}
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: "Thông tin không được để trống!",
                                    //     },
                                    // ]}
                                >
                                    <TreeSelect
                                        showSearch
                                        // isRequired
                                        style={{width: '80%'}}
                                        dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                        placeholder="Chọn bài viết"
                                        allowClear
                                        treeDefaultExpandAll
                                        name="content"
                                        value={dataInfo?.content || selectedValue}
                                        onChange={(value, label, extra) => {
                                            setSelectedValue(value)
                                        }}
                                        treeData={listPost.map(node => ({
                                            label: node.title,
                                            value: node.slug + "-" + node.postID,
                                        }))}
                                    />
                                </Form.Item>
                            }
                        </Col>
                        {category == 1 &&
                            <>
                                <Col span={10}>

                                    <Form.Item
                                        label="Chọn ảnh"
                                        name="title"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Thông tin không được để trống",
                                            },
                                        ]}
                                    >
                                        <Upload {...uploadProps}
                                        >
                                            <Row className="align-items-center">
                                                <ButtonUploadStyle>
                                                    <Button className="account-button-upload ">
                                                        <Row
                                                            className="account-background-upload d-flex align-items-center">
                                                            <SvgIcon name="add-media-video"/>
                                                            <div className="account-text-upload ml-16">
                                                                Chọn ảnh
                                                            </div>
                                                        </Row>
                                                    </Button>
                                                </ButtonUploadStyle>
                                                <div className="sub-color fs-12 ml-16">
                                                    Dung lượng file tối đa 5MB, định dạng: .JPG, .JPEG,
                                                    .PNG, .SVG
                                                </div>
                                            </Row>
                                        </Upload>
                                    </Form.Item>
                                </Col>
                                {/*{ fileUrl &&*/}

                                <Col span={5}>
                                    {fileUrl ? <Image
                                        src={fileUrl}
                                        alt="image"
                                        loading="lazy"
                                        width={200}
                                        style={{marginBottom: "33px"}}
                                    /> : <Image src={dataInfo?.title}></Image>}

                                </Col>
                            </>
                        }

                        {/*{dataInfo?.isDelete &&*/}
                        {/*    <Col span={24}>*/}
                        {/*        <Form.Item*/}
                        {/*            style={{width: "100% !important"}}*/}
                        {/*            name="isDelete"*/}
                        {/*            rules={[*/}
                        {/*                {*/}
                        {/*                    required: true,*/}
                        {/*                    message: "Thông tin không được để trống!",*/}
                        {/*                },*/}
                        {/*            ]}*/}
                        {/*        >*/}
                        {/*            <FlSelect*/}
                        {/*                label="Trạng thái"*/}
                        {/*                name="isDelete"*/}
                        {/*                isRequired*/}
                        {/*                onChange={(e) => {*/}
                        {/*                    // handleFilter({...pagination,status:e})*/}
                        {/*                }}*/}
                        {/*                // value={pagination.status}*/}
                        {/*            >*/}
                        {/*                <Option value="1">Không kích hoạt</Option>*/}
                        {/*                <Option value="0">Kích hoạt</Option>*/}


                        {/*                {*/}

                        {/*                    // items?.map(i => (*/}
                        {/*                    //     <Option key={i?.key} value={ i.key}>*/}
                        {/*                    //         {i?.label}*/}
                        {/*                    //     </Option>*/}
                        {/*                    // ))*/}
                        {/*                }*/}
                        {/*            </FlSelect>*/}
                        {/*        </Form.Item>*/}
                        {/*    </Col>*/}
                        {/*}*/}
                    </Row>
                </Form>
            </SpinCustom>
        </CustomerModal>
    )
}
