import { Form, Input } from "antd"
import { useEffect, useState } from "react"
import CustomModal from "src/components/Modal/CustomModal"
import Button from "src/components/MyButton/Button"
import Notice from "src/components/Notice"
import DepartmentOracleService from "src/services/DepartmentOracleSerivce"

const InsertCategoryProduct = ({ open, onCancel, onOk, listCategories }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const isEdit = open?.isEdit;

  //#region useEffect

  useEffect(() => {
    if (isEdit)
      form.setFieldsValue({ categoryName: open?.title })
  }, [open]);

  //#endregion

  //#region function

  /**
   * @description thực hiện action thêm, sửa thể loại
   * @author hddung
   */
  const onSubmit = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();
      let res
      if (isEdit) {
        res = await DepartmentOracleService.updateDepartment({
          deptid: open?.key,
          deptname: values?.categoryName,
        })
      } else {
        if(listCategories[0].children.find(x => x.title.toLowerCase() == values.categoryName.toLowerCase())) {
          Notice({
            msg: 'Tên phân loại đã tồn tại',
            isSuccess: false,
          });
          return
        } else {
          res = await DepartmentOracleService.insertDepartment({
            deptname: values?.categoryName
            //...values
          })
        }
      }
      if (res?.isError) return;
      Notice({ msg: `${isEdit ? "Cập nhật" : "Thêm"} danh mục thành công !` });
      onOk && onOk();
      onCancel();
    } finally {
      setLoading(false);
    }
  }

  /**
   * @description Thực hiện render button
   * @author hddung
   */
  const renderFooter = () => (
    <div className="d-flex justify-content-flex-end">
      <Button btnType="primary" onClick={onSubmit} loading={loading}>
        {isEdit ? "Cập nhật" : "Thêm"}
      </Button>
    </div>
  )

   //#endregion

  return (
    <CustomModal
      title={isEdit ? "Cập nhật thể loại" : "Thêm thể loại"}
      footer={renderFooter()}
      width={1024}
      open={!!open}
      onCancel={onCancel}
    >
      <div className="mb-16" />
      <Form form={form} layout="vertical">
        <Form.Item
          name="categoryName"
          label="Tên danh mục"
          required
          rules={[{ required: true, message: "Thông tin không được để trống" }]}
        >
          <Input placeholder="Nhập tên danh mục" />
        </Form.Item>
      </Form>
    </CustomModal>
  )
}

export default InsertCategoryProduct
