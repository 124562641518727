import {
  apiListOrder, apiOrderDetail
} from './urls.js'
  import http from "../index"

     const listOrders = body => http.post(apiListOrder, body)
     const getOrderDetail=param=>http.post(apiOrderDetail+`orderId=${param}`)
  const RegionService = {
    listOrders,
    getOrderDetail
  }
  export default RegionService
