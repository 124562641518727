import React from 'react'
import {useState} from 'react'
import TableCustom from 'src/components/Table/CustomTable'
import "../../../assets/css/style.css";
import {useEffect} from 'react'
import "../ProductDetail/style.css"
import {useParams} from 'react-router-dom';
import STORAGE, {getStorage} from "src/lib/storage"
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Notice from "src/components/Notice"
import {Badge, Button, Spin, Tabs, Tag} from "antd";
import {AndroidOutlined, AppleOutlined, ShoppingCartOutlined, ShopOutlined, WechatOutlined} from "@ant-design/icons";
import CheckableTag from "antd/es/tag/CheckableTag";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux"
import {setShopUserInfo, setChatUserInfor, setBalance} from "src/redux/appGlobal"
import {addToCart, getCart} from "../../../redux/cart";
import $ from "jquery";
import SweetAlert2, {withSwal} from 'react-sweetalert2';
import Swal from 'sweetalert2';
import {Col, Image, QRCode, Row, Typography} from "antd";
import FlInput from "src/components/FloatingLabel/Input"
import LoginModal from "../../../components/Layouts/component/Login/LoginModal";
import {ProductRelate, ProductRelated} from "../ProductRelate/ProductRelate";
import ROUTER from 'src/router';
import PopupConfirm from 'src/pages/Product/ProductDetail/PopupConfirm'
import {PRICE_TYPE, SATUS_PRODUCT} from 'src/constants/constants';
import {useMediaQuery} from "react-responsive";
import ProductionService from "src/services/ProductionService"
import UserService from 'src/services/UserService';
import RegisterModal from 'src/components/Layouts/component/Register/components/RegisterModal';
import IconHoverProduct from '../ProductList/iconHoverProduct';
import ForgetModal from 'src/components/Layouts/component/Forget/ForgetModal';
import VerifyForgetModal from 'src/components/Layouts/component/Forget/components/VerifyForgotModal';
import RePasswordModal from 'src/components/Layouts/component/Forget/components/RePasswordModal';

export const ProductDetail = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [loadingReview, setLoadingReview] = useState(false);
    const [activeTab, setActiveTab] = useState('tab-description');
    const handleTabChange = (tabId) => {
        // console.log('handleTabChange ', tabId);
        setActiveTab(tabId == 1 ? 'tab-description' : 'tab-reviews');
    };
    const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'});
    const isTablet = useMediaQuery({query: '(max-width: 1224px) and (min-width: 800px)'});
    const isMobile = useMediaQuery({query: '(max-width: 800px)'});
    const [openForgetPassModal, setOpenForgetPassModal] = useState(false)
    const [openSelectRegisterModal, setOpenSelectRegisterModal] = useState(false)
    const [openLoginModal, setOpenLoginModal] = useState(false)
    const [openVerifyModal, setOpenVerifyModal] = useState(false)
    const [rePasswordModal, setRePasswordModal] = useState(false)
    const [email, setEmail] = useState(false)
    const [codeVerify, setCodeVerify] = useState()
    const [product, setProduct] = useState([]);
    const [productRelated, setProductRelated] = useState([]);
    const url = window.location.href;
    const [productReview, setProductReview] = useState({
        pageSize: 10,
        currentPage: 1,
        productID: "",
        starRating: 0
    });
    const [dataProductReview, setDataProductReview] = useState([]);
    const [totalReview, setTotalReview] = useState([]);
    const dispatch = useDispatch()
    const [swalProps, setSwalProps] = useState({});
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [isShowConfirmBuyProduct, setIsShowConfirmBuyProduct] = useState(false);

    const [productSelected, setProductSelected] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [selectedTags, setSelectedTags] = useState([]);
    const [tagsData, setTagsData] = useState([]);
    const [txtDiscount, setTxtDiscount] = useState('');
    const {userInfo} = useSelector(state => state?.appGlobal);
    const [priceSaleProductItem, setPriceSaleProductItem] = useState(0);
    const {id} = useParams();


    /**
     * @description set title tab web
     * @author hddung
     */
    useEffect(() => {
        if (url) {
            return () => {
                document.title = 'TẠP HÓA SỐ'; // Đặt lại title khi component bị unmount
            };
        }
    }, [url]);

    const showSuccess = (message) => {
        Swal.fire({
            icon: 'success',
            title: 'Thành Công',
            text: message,
        }).then(() => {
            setShowSuccessAlert(true);
        });
    };
    useEffect(() => {
        if (txtDiscount && productSelected.price == PRICE_TYPE.GIA_LIEN_HE) {
            CheckPromoteCode();
        }
    }, [txtDiscount, productSelected])
    const showBuySuccess = (orderDetailId) => {
        Swal.fire({
            icon: 'success',
            title: 'Thành Công',
            text: 'Mua hàng thành công',
        }).then(() => {
            if (orderDetailId) {
                navigate(`/order-detail/${orderDetailId}`);
            }
        });
    };
    const showError = (message) => {
        Swal.fire({
            icon: 'error',
            title: 'Mua hàng thất bại',
            text: message,
        }).then(() => {
            setShowSuccessAlert(true);
        });
    };
    const addCart = async () => {
        try {
            // const userInfo = JSON.parse(localStorage.getItem(STORAGE.USER_INFO))
            const isLogin = getStorage(STORAGE.TOKEN);
            const userId = userInfo?.userID;
            if (userId && isLogin != null) {
                const model = {
                    "productItemID": productSelected.productItemID,
                    "userID": userId,
                    "quantity": quantity
                }
                await dispatch(addToCart(model)).then(resp => {
                    if (!resp.isError) {
                        dispatch(getCart());
                        showSuccess('Thêm sản phẩm vào giỏ hàng thành công!');
                    } else {
                        showError(resp.object);
                    }
                }).catch(reason => {
                    showError("Thêm vào giỏ hàng thất bại")
                });
            } else {
                Notice({
                    msg: "Bạn Phải đăng nhập mới có thể mua được", isSuccess: false
                })
                // navigate('/login');
                setOpenLoginModal(true);
            }


        } catch (e) {

        }
    }
    const fetchProductDetails = async () => {
        try {
            setLoading(true);
            const userInfo = JSON.parse(localStorage.getItem(STORAGE.USER_INFO))//để tạm sau để làm biến chung
            const response = await ProductionService.getProductDetailsByID(getGuiIdByParamURL(id), userInfo?.userID);
            setProduct(response?.object);
            setSelectedTags([response?.object?.productItems[0]?.productItemName] || null);
            setProductSelected(response?.object?.productItems[0] || null) //set default product
            document.title = response?.object?.productName || 'TẠP HÓA SỐ';
            return response;
        } catch (error) {
            console.error('Error fetching product details:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        if (id) {
            fetchProductDetails().then(resp => {
                const model = {
                    "shopID": resp?.data?.object?.shopUserID,
                    "categoryID": resp?.data?.object?.categoryID
                }
                featchProductRelated(model).then(value => {
                    setProductRelated(value?.object);
                })
            });
        }
    }, [id]);
    const featchProductRelated = async (model) => {
        return await ProductionService.getProductRelated(model);
    }


    /**
     * @description get URL lấy ra Id product
     * @author hddung
     */
    function getGuiIdByParamURL(id) {
        var regex = /([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/;
        var ketQua = id.match(regex);
        return ketQua[ketQua.length - 1];
    }

    /**
     * @description set biến global cho shopID
     * @author HDDUNG
     * @param {*} ShopUserId Id shop của sản phẩm
     */
    function showProductShopClick(ShopUserId) {
        dispatch(setShopUserInfo(ShopUserId));
        navigate(`/chi-tiet-cua-hang?shopID=${ShopUserId}`);
    };

    /**
     * @description format date
     * @author HDDUNG
     */
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            time = d.getHours() + ':' + d.getMinutes();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-') + ` ${time}`;
    };

    /**
     * @description lấy dữ liệu review sản phẩm
     * @author HDDUNG
     */
    useEffect(() => {
        if (activeTab == 'tab-reviews') {
            async function getReviewProduct() {
                try {
                    setLoadingReview(true);
                    productReview.productID = getGuiIdByParamURL(id);
                    const response = await ProductionService.getProductReviewsByID(productReview);
                    setDataProductReview(response?.object);
                    setTotalReview(response?.object?.count);
                } catch (error) {
                    console.error('Error fetching product details:', error);
                } finally {
                    setLoadingReview(false);
                }
            };
            getReviewProduct();
        } else {
            //Set active về all star khi chuyển lại tab về mô tả tránh cache lại tab trước đó đã bấm
            $('.rating-classification.active').removeClass('active');
            $('.rating-classification.auto-active').addClass('active');
            productReview.starRating = 0
        }
    }, [productReview, activeTab]);

    /**
     * @description bắt sự kiện lọc review theo sao
     * @author HDDUNG
     * @param {*} e
     */
    function onClickStarTab(e) {
        $('.rating-classification.active').removeClass('active');
        e.target.classList.add('active');
        setProductReview((prevState) => ({
            ...prevState,
            starRating: e.target.getAttribute('value') || 0
        }));

    };

    /**
     * @description sự kiện render page number theo tổng số comment review
     * @author HDDUNG
     * @returns
     */
    const renderPagination = () => {
        const totalPages = Math.ceil(totalReview / 10);
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(
                <li
                    key={i}
                    className={`page-item ${productReview.currentPage === i ? 'active' : ''}`}
                >
                    <a
                        className="page-link"
                        href="#"
                        onClick={() => handlePageChange(i)}
                    >
                        {i}
                    </a>
                </li>
            );
        }
        return pages;
    };

    /**
     * @description sự kiện change page
     * @author HDDUNG
     * @param {*} page page được chọn
     */
    const handlePageChange = (page) => {
        const totalPages = Math.ceil(totalReview / 10);
        page = page > totalPages ? totalPages : page;
        setProductReview((prevState) => ({
            ...prevState,
            currentPage: page || 1
        }));
    };

    useEffect(() => {

        // const list = product?.productItems?.map(item => item.productItemName);
        setTagsData(product?.productItems);
    }, [product])
    useEffect(() => {
        const p = product?.productItems?.find(item => item.productItemName === selectedTags[0]);
        setProductSelected(p);
        setQuantity(1);
    }, [selectedTags])



    const formatCurrency = (value) => {
        return new Intl.NumberFormat('vi-VN', {
            style: 'currency',
            currency: 'VND',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value).replace('₫', '');
    };
    const handleChange = (tag: any, checked: boolean) => {
        const nextSelectedTags = checked
            ? [tag]
            : [tag]
        setSelectedTags(nextSelectedTags);
    };
    const handleMinusClick = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    const handlePlusClick = () => {
        setQuantity(quantity + 1);
    };

    const handleChangeQuantity = (value) => {
        if ((isNaN(parseInt(value)) || parseInt(value) < 1) && value != '') {
            setQuantity(1);
        } else {
            setQuantity(parseInt(value || 0));
        }
    };

    const handleBlurQuantity = (value) => {
        if (isNaN(parseInt(value)) || parseInt(value) < 1) {
            setQuantity(1);
        }
    };

    /**
     * @description Thực hiện cập nhật lại số tiền của người dùng vào redux
     * @author hddung
     */
    const getBalanceUser = async () => {
        const res = await UserService.getInforUser();
        if (res.isError) return
        dispatch(setBalance(res?.object?.accountBalance || 0));
    }

    const handleBuyNowClick = () => {
        const isLogin = getStorage(STORAGE.TOKEN);
        if (isLogin == null) {
            Notice({
                msg: "Bạn Phải đăng nhập mới có thể mua được", isSuccess: false
            })
            // navigate('/login');
            setOpenLoginModal(true);
        } else {
            console.log('name', productSelected);
            const model = {
                "productItemID": productSelected.productItemID,
                "promoteCode": txtDiscount || null, // Nếu bạn có một giá trị promoteCode nào đó thì sử dụng giá trị này ở đây.
                "quantity": quantity,
            }
            buyNow(model).then(resp => {
                if (resp?.statusCode == 200) {
                    showBuySuccess(resp?.object);
                    getBalanceUser();
                }

            }).catch(reason => {
                console.log('error:  ' + reason);
                showError("");
            })
        }
        
    };

    const buyNow = async (model) => {
        try {
            setLoading(true);
            return await ProductionService.buyProduct(model);
        } finally {
            setLoading(false);
        }
    }

    /**
     * @description thực hiện việc show chat box
     * @author hddung
     */
    function showChatBox() {
        //Set biến redux global để hiển thị chatbox
        dispatch(setChatUserInfor(
            {
                userData: {
                    toUserId: product?.shopUserID,
                    toUserName: product?.shopUsername,
                    roomId: product?.roomId,
                    avatar: product?.avatarShop
                },
                isShow: true
            }));
    }

    /**
     * @description thực hiện check code giảm giá
     * @author hddung
     * @returns
     */
    async function CheckPromoteCode() {
        const isLogin = getStorage(STORAGE.TOKEN);
        if (isLogin == null) {
            Notice({
                msg: "Bạn Phải đăng nhập mới có thể mua được", isSuccess: false
            })
            // navigate('/login');
            setOpenLoginModal(true);
        } else {
            try {
                //Check code khi txtDiscount có giá trị sẽ gọi API, nếu không thì show popup xác nhận mua hàng như thường 
                if (txtDiscount && productSelected.priceType != PRICE_TYPE.GIA_LIEN_HE) {
                    const response = await ProductionService.checkPromoteCode({
                        productItemID: productSelected?.productItemID,
                        promoteCode: txtDiscount,
                        quantity: quantity
                    });

                    if (response?.isError) return

                    //Giảm giá nếu có
                    setPriceSaleProductItem(response?.object?.accuratePrice || (productSelected?.price || 0) * ((100 - (productSelected?.discount || 0)) / 100 || 1));

                    //Nếu là giá liên hệ thì sẽ set lại giá trị để đi luồng bình thường
                    if (productSelected?.price == PRICE_TYPE.GIA_LIEN_HE) {
                        setProductSelected((prevState) => ({
                            ...prevState,
                            price: response?.object?.accuratePrice,
                            priceType: PRICE_TYPE.GIA_LIEN_HE // Biến để xác định sp trước khi cập nhập giá mới là sp 'liên hệ'
                        }));
                    } else { //Luồng bình thường sẽ hiển thị popup xác nhận mua hàng
                        setIsShowConfirmBuyProduct(true);
                    }
                } else {
                    //Luồng bình thường sẽ hiển thị popup xác nhận mua hàng
                    setPriceSaleProductItem((productSelected?.price || 0) * ((100 - (productSelected?.discount || 0)) / 100 || 1));
                    setIsShowConfirmBuyProduct(true);
                }


            } catch (error) {
                console.error('Error fetching product details:', error);
            }
        }
    }


    return (
        <Spin spinning={loading}>
            <main className="main">
                <div className="section-box">
                    <div className="breadcrumbs-div" style={{marginBottom: '0px', padding: '16px 0px 0px 0px'}}>
                        <div className="container">
                            <ul className="breadcrumb">
                                <li><a className="font-xs color-gray-500" onClick={() => {
                                    navigate(ROUTER.HOME)
                                }}>Trang chủ</a></li>
                                <li><a className="font-xs color-gray-500" onClick={() => {
                                    navigate(ROUTER.PRODUCT_LIST)
                                }}>Danh Sách Sản Phẩm</a></li>
                                <li>
                                    <a className="font-xs color-gray-1000"
                                       href=""
                                    >{product.productName}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <section className="section-box shop-template">
                    <div className="container partern">
                        <div className="product-information-top">
                            <div className="product-information-content">
                                {
                                    isDesktopOrLaptop && (
                                        <div className="row">
                                            <div className="col-lg-4" style={{display: 'flex', alignItems: 'center'}}>
                                                <Badge.Ribbon
                                                    style={{
                                                        display: productSelected?.isSignContract ? 'block' : 'none',
                                                        minWidth: '56px'
                                                    }}
                                                    text={<IconHoverProduct iconName={'sign-contract-ic'}
                                                                            title={'Ký hợp đồng'}/>}
                                                    color="#fd9636"
                                                >
                                                    <Badge.Ribbon
                                                        style={{
                                                            display: !productSelected?.isCheckStock ? 'block' : 'none',
                                                            minWidth: '56px',
                                                            top: productSelected?.isSignContract ? '36px' : '8px'
                                                        }}
                                                        text={<IconHoverProduct iconName={'stock-ic'}
                                                                                title={'Mua quá tồn kho'}/>}
                                                        color="#fd9636"
                                                    >
                                                        <Badge.Ribbon
                                                            style={{
                                                                display: productSelected?.isBuyAutomatic ? 'block' : 'none',
                                                                minWidth: '56px',
                                                                top: productSelected?.isSignContract && !productSelected?.isCheckStock ? '66px'
                                                                    : ((!productSelected?.isSignContract && !productSelected?.isCheckStock) || productSelected?.isSignContract) ? '36px' : '8px'
                                                            }}
                                                            text={<IconHoverProduct iconName={'automatic-ic'}
                                                                                    title={'Mua bán tự động'}/>}
                                                            color="#fd9636"
                                                        >
                                                            <div className="images-container first-line">
                                                                <Image className='img-product-detail' src={product.image}/>
                                                            </div>
                                                        </Badge.Ribbon>
                                                    </Badge.Ribbon>
                                                </Badge.Ribbon>
                                            </div>
                                            <div className="col-lg-4 container-attr">
                                                <div className={"title-rate-container"}>
                                                    <h1 className="color-brand-3 title-content"
                                                        style={{fontSize: '1.8rem'}}>{product.productName}</h1>
                                                    <div className="">
                                                        <div className="rating ">
                                                            <div className="product-rate d-inline-block">
                                                                <div className="product-rating"
                                                                     style={{width: product.averageReview * 20 + "%"}}></div>
                                                            </div>
                                                            <span
                                                                className="rating-text color-gray-500 font-medium"> ({product.quantityReview} đánh giá) | Đã bán {product.sold} | Tồn kho: {!productSelected?.isCheckStock ? '999+' : productSelected.quantityInventory}</span>
                                                        </div>
                                                    </div>

                                                </div>

                                                {productSelected?.price !== -1 && (<div
                                                    className="mb-40 product-price color-brand-3 price-item-detail__container">
                                                    <h4 style={{color: 'red'}} className={"price-item-detail__container"}>
                                                        <p>{formatCurrency(productSelected?.price * ((100 - productSelected?.discount) / 100 || 1))}</p>
                                                        <span>₫</span>
                                                    </h4>
                                                    {productSelected?.discount > 0 &&
                                                        (
                                                            <div className={"price-item-detail__container"}>
                                                                <del
                                                                    style={{
                                                                        fontSize: '18px',
                                                                        color: '#8c9ec5'
                                                                    }} className={"del-container"}>
                                                                    <p>{formatCurrency(productSelected?.price)} </p>
                                                                    <span className={"d-icon"}>₫</span>
                                                                </del>

                                                                <span
                                                                    style={{fontSize: '18px'}}>-{productSelected?.discount}%</span>
                                                            </div>
                                                        )}

                                                </div>)
                                                }
                                                {productSelected?.price == -1 && (<div
                                                    className="mb-40 product-price color-brand-3 price-item-detail__container">
                                                    <h4 style={{color: 'red'}} className={"price-item-detail__container"}>
                                                        <p>Liên Hệ</p>
                                                    </h4>

                                                </div>)
                                                }
                                                <div className="buy-product">
                                                    <div>
                                                        <div className={"tag-container"}>
                                                            <p style={{marginRight: 8}}>Phân loại:</p>
                                                            <b>{selectedTags}</b>
                                                        </div>
                                                        {tagsData?.map((tag) => (
                                                            <CheckableTag
                                                                style={{
                                                                    fontSize: '14px',
                                                                    minWidth: '48px',
                                                                    padding: '4px 10px 4px 10px',
                                                                    margin: '3px 4px',
                                                                    background: selectedTags.includes(tag?.productItemName) ? '#e6f4ff' : '#DFDFDF',
                                                                    color: selectedTags.includes(tag?.productItemName) ? '#0958d9' : '',
                                                                    border: selectedTags.includes(tag?.productItemName) ? ' 1px solid #91caff' : ''
                                                                }}
                                                                key={tag?.productItemName}
                                                                checked={selectedTags.includes(tag?.productItemName)}
                                                                onChange={(checked) => handleChange(tag?.productItemName, checked)}
                                                            >
                                                                {tag?.productItemName}
                                                            </CheckableTag>
                                                        ))}

                                                    </div>
                                                    <div>

                                                    </div>
                                                </div>
                                                {/* <div className='d-flex'>
                                                
                                            </div> */}
                                                <div className="quantity-container mt-10 d-flex ">
                                                    <div className="font-sm text-quantity">Số lượng:</div>
                                                    <div className={'calculator-container'}>
                                                        <div style={{
                                                            marginRight: '5%',
                                                            display: "flex",
                                                            flexWrap: "nowrap"
                                                        }}>
                                                            <button className={'btn-s'} type='button' id={'decrease'}
                                                                    onClick={handleMinusClick}>-
                                                            </button>
                                                            <input className={'btn-s input-s'} type='text' id={'raw'}
                                                                   value={quantity}
                                                                   onChange={value => handleChangeQuantity(value.target.value)}
                                                                   onBlur={value => handleBlurQuantity(value.target.value)}
                                                            />
                                                            <button className={'btn-s'} type='button' id={'increase'}
                                                                    onClick={handlePlusClick}>+
                                                            </button>
                                                        </div>
                                                        <FlInput
                                                            disabled={productSelected?.priceType == PRICE_TYPE.GIA_LIEN_HE}
                                                            onChange={values => {
                                                                if (productSelected?.price != PRICE_TYPE.GIA_LIEN_HE) {
                                                                    setTxtDiscount(values.target.value)
                                                                }
                                                            }}
                                                            onSearch={values => {
                                                                setTxtDiscount(values)
                                                            }}
                                                            allowClear
                                                            label={productSelected?.price == PRICE_TYPE.GIA_LIEN_HE ? 'Nhập mã giá' : "Nhập mã giảm giá"}
                                                            search={productSelected?.price == PRICE_TYPE.GIA_LIEN_HE}
                                                            style={{height: "32px", width: '188px'}}
                                                        />
                                                    </div>

                                                </div>


                                                <div className="buy-product ">
                                                    <div className="box-quantity">
                                                        <div className="button-buy btbs">
                                                            <Button className={"add-to-cart__btn"} danger onClick={addCart}
                                                                    disabled={product?.status == SATUS_PRODUCT.NGUNG_BAN}>
                                                                <ShoppingCartOutlined style={{fontSize: '20px'}}/>
                                                                Thêm vào giỏ hàng
                                                            </Button>
                                                            <Button className={"buy-now__btn"} type="primary" danger
                                                                    onClick={CheckPromoteCode}
                                                                    disabled={productSelected?.price == PRICE_TYPE.GIA_LIEN_HE
                                                                        || product?.status == SATUS_PRODUCT.NGUNG_BAN
                                                                        || (productSelected?.isBuyAutomatic && productSelected?.quantityInventory < quantity)
                                                                    }
                                                            >
                                                                Mua ngay
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 shop-information d-flex">
                                                <div className='shop-infor'>
                                                    <img className='shop-img' src={product.avatarShop}/>
                                                    <div className='shop-name'>
                                                        <h5>{product.shopUsername}</h5>
                                                        <img
                                                            src="https://salt.tikicdn.com/ts/upload/5d/4c/f7/0261315e75127c2ff73efd7a1f1ffdf2.png"/>
                                                    </div>
                                                    <div className='review-rate'>
                                                        <p className={"p-tag"}>{product.totalAverageReview}/5 <span
                                                            style={{color: '#fdc040'}}>★</span> <br></br> <span
                                                            style={{fontSize: '11px'}}>{product.totalQuantityReview} Đánh giá</span>
                                                        </p>
                                                        <p className={"p-tag"}> {product.totalSold}<br></br><span
                                                            style={{fontSize: '11px'}}> Đã bán</span></p>
                                                    </div>
                                                    <div className='shop-button'>
                                                        <div>
                                                            {/* <img style={{ width: '20px', height: '20px' }} src="https://salt.tikicdn.com/ts/upload/49/27/ff/d735c33edfdc6cf6aeb6e183bec28869.png" alt="view-store" />
                                <span onClick={() => {navigate(`/chi-tiet-cua-hang/`)} }>Xem Shop</span> */}
                                                            <Button icon={<ShopOutlined/>} onClick={() => {
                                                                showProductShopClick(product.shopUserID)
                                                            }}>Xem shop</Button>
                                                        </div>
                                                        <div>
                                                            {/* <img style={{ width: '20px', height: '20px' }} src="https://scontent.fhan18-1.fna.fbcdn.net/v/t1.15752-9/363068912_1332535057664356_8795616996644429061_n.png?_nc_cat=108&ccb=1-7&_nc_sid=ae9488&_nc_ohc=-zBy33AZBgwAX_AyMC4&_nc_ht=scontent.fhan18-1.fna&oh=03_AdT0itHwq6HrIlhhnxxEojHFESWLgOcgT2ynE5fxW02zPg&oe=64E59CBA" alt="follow-store" />
                                <span> Nhắn tin</span> */}
                                                            <Button icon={<WechatOutlined/>} onClick={showChatBox}
                                                                    disabled={!userInfo?.userID || (userInfo?.userID && userInfo?.userID == product?.shopUserID)}>Nhắn
                                                                tin</Button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    )

                                }

                                {
                                    isTablet && (
                                        <div className="row">
                                            <div className={"col-12 d-flex"}>

                                                <div className="col-5" style={{display: 'flex', alignItems: 'center',marginLeft:14}}>
                                                    <Badge.Ribbon
                                                        style={{
                                                            display: productSelected?.isSignContract ? 'block' : 'none',
                                                            minWidth: '56px'
                                                        }}
                                                        text={<IconHoverProduct iconName={'sign-contract-ic'}
                                                                                title={'Ký hợp đồng'}/>}
                                                        color="#fd9636"
                                                    >
                                                        <Badge.Ribbon
                                                            style={{
                                                                display: !productSelected?.isCheckStock ? 'block' : 'none',
                                                                minWidth: '56px',
                                                                top: productSelected?.isSignContract ? '36px' : '8px'
                                                            }}
                                                            text={<IconHoverProduct iconName={'stock-ic'}
                                                                                    title={'Mua quá tồn kho'}/>}
                                                            color="#fd9636"
                                                        >
                                                            <Badge.Ribbon
                                                                style={{
                                                                    display: productSelected?.isBuyAutomatic ? 'block' : 'none',
                                                                    minWidth: '56px',
                                                                    top: productSelected?.isSignContract && !productSelected?.isCheckStock ? '66px'
                                                                        : ((!productSelected?.isSignContract && !productSelected?.isCheckStock) || productSelected?.isSignContract) ? '36px' : '8px'
                                                                }}
                                                                text={<IconHoverProduct iconName={'automatic-ic'}
                                                                                        title={'Mua bán tự động'}/>}
                                                                color="#fd9636"
                                                            >
                                                                <div className="images-container first-line first-line-mbs"
                                                                     style={{marginLeft: 4}}>
                                                                    <Image className='img-product-detail'
                                                                           src={product.image}/>
                                                                </div>
                                                            </Badge.Ribbon>
                                                        </Badge.Ribbon>
                                                    </Badge.Ribbon>
                                                </div>
                                                <div className={"col-1"}></div>
                                                <div className="col-6 container-attr">
                                                    <div className={"title-rate-container"}>
                                                        <h1 className="color-brand-3 title-content"
                                                            style={{fontSize: '1.8rem'}}>{product.productName}</h1>
                                                        <div className="">
                                                            <div className="rating ">
                                                                <div className="product-rate d-inline-block">
                                                                    <div className="product-rating"
                                                                         style={{width: product.averageReview * 20 + "%"}}></div>
                                                                </div>
                                                                <span
                                                                    className="rating-text color-gray-500 font-medium"> ({product.quantityReview} đánh giá) | Đã bán {product.sold} | Tồn kho: {!productSelected?.isCheckStock ? '999+' : productSelected.quantityInventory}</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {productSelected?.price !== -1 && (<div
                                                        className="mb-40 product-price color-brand-3 price-item-detail__container">
                                                        <h4 style={{color: 'red'}}
                                                            className={"price-item-detail__container"}>
                                                            <p>{formatCurrency(productSelected?.price * ((100 - productSelected?.discount) / 100 || 1))}</p>
                                                            <span>₫</span>
                                                        </h4>
                                                        {productSelected?.discount > 0 &&
                                                            (
                                                                <div className={"price-item-detail__container"}>
                                                                    <del
                                                                        style={{
                                                                            fontSize: '18px',
                                                                            color: '#8c9ec5'
                                                                        }} className={"del-container"}>
                                                                        <p>{formatCurrency(productSelected?.price)} </p>
                                                                        <span className={"d-icon"}>₫</span>
                                                                    </del>

                                                                    <span
                                                                        style={{fontSize: '18px'}}>-{productSelected?.discount}%</span>
                                                                </div>
                                                            )}

                                                    </div>)
                                                    }
                                                    {productSelected?.price == -1 && (<div
                                                        className="mb-40 product-price color-brand-3 price-item-detail__container">
                                                        <h4 style={{color: 'red'}}
                                                            className={"price-item-detail__container"}>
                                                            <p>Liên Hệ</p>
                                                        </h4>

                                                    </div>)
                                                    }
                                                    <div className="buy-product">
                                                        <div>
                                                            <div className={"tag-container"}>
                                                                <p style={{marginRight: 8}}>Phân loại:</p>
                                                                <b>{selectedTags}</b>
                                                            </div>
                                                            {tagsData?.map((tag) => (
                                                                <CheckableTag
                                                                    style={{
                                                                        fontSize: '14px',
                                                                        minWidth: '48px',
                                                                        padding: '4px 10px 4px 10px',
                                                                        margin: '3px 4px',
                                                                        background: selectedTags.includes(tag?.productItemName) ? '#e6f4ff' : '#DFDFDF',
                                                                        color: selectedTags.includes(tag?.productItemName) ? '#0958d9' : '',
                                                                        border: selectedTags.includes(tag?.productItemName) ? ' 1px solid #91caff' : ''
                                                                    }}
                                                                    key={tag?.productItemName}
                                                                    checked={selectedTags.includes(tag?.productItemName)}
                                                                    onChange={(checked) => handleChange(tag?.productItemName, checked)}
                                                                >
                                                                    {tag?.productItemName}
                                                                </CheckableTag>
                                                            ))}

                                                        </div>
                                                        <div>

                                                        </div>
                                                    </div>
                                                    <div className="quantity-container mt-10 d-flex ">
                                                        <div className="font-sm text-quantity">Số lượng:</div>
                                                        <div className={'calculator-container ccbts'}>
                                                            <div style={{
                                                                marginRight: '5%',
                                                                display: "flex",
                                                                flexWrap: "nowrap",
                                                                marginBottom: "10px"
                                                            }}>
                                                                <button className={'btn-s'} type='button' id={'decrease'}
                                                                        onClick={handleMinusClick}>-
                                                                </button>
                                                                <input className={'btn-s input-s'} type='text' id={'raw'}
                                                                       value={quantity}
                                                                       onChange={value => handleChangeQuantity(value.target.value)}
                                                                       onBlur={value => handleBlurQuantity(value.target.value)}
                                                                />
                                                                <button className={'btn-s'} type='button' id={'increase'}
                                                                        onClick={handlePlusClick}>+
                                                                </button>
                                                            </div>
                                                            <FlInput
                                                                disabled={productSelected?.priceType == PRICE_TYPE.GIA_LIEN_HE}
                                                                onChange={values => {
                                                                    if (productSelected?.price != PRICE_TYPE.GIA_LIEN_HE) {
                                                                        setTxtDiscount(values.target.value)
                                                                    }
                                                                }}
                                                                onSearch={values => {
                                                                    setTxtDiscount(values)
                                                                }}
                                                                allowClear
                                                                label={productSelected?.price == PRICE_TYPE.GIA_LIEN_HE ? 'Nhập mã giá' : "Nhập mã giảm giá"}
                                                                search={productSelected?.price == PRICE_TYPE.GIA_LIEN_HE}
                                                                style={{height: "32px"}}
                                                            />
                                                        </div>

                                                    </div>


                                                    <div className="buy-product ">
                                                        <div className="box-quantity">
                                                            <div className="button-buy btbs">
                                                                <Button className={"add-to-cart__btn"} danger
                                                                        onClick={addCart}>
                                                                    <ShoppingCartOutlined style={{fontSize: '20px'}}/>
                                                                    Thêm vào giỏ hàng
                                                                </Button>
                                                                <Button className={"buy-now__btn"} type="primary" danger
                                                                        onClick={CheckPromoteCode}
                                                                        disabled={productSelected?.price == PRICE_TYPE.GIA_LIEN_HE
                                                                            || product?.status == SATUS_PRODUCT.NGUNG_BAN
                                                                            || (productSelected?.isBuyAutomatic && productSelected?.quantityInventory < quantity)
                                                                        }
                                                                >
                                                                    Mua ngay
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"col-12"} onClick={"border-bt"}></div>
                                            <div className="col-lg-12 shop-information d-flex">
                                                <div className='shop-infor shop-infor-mb'>
                                                    <div className={"col-4 rvs-ctn"}>
                                                        <img className='shop-img' src={product.avatarShop}/>
                                                        <div className='shop-name'>
                                                            <h5>{product.shopUsername}</h5>
                                                            <img
                                                                src="https://salt.tikicdn.com/ts/upload/5d/4c/f7/0261315e75127c2ff73efd7a1f1ffdf2.png"/>
                                                        </div>
                                                    </div>
                                                    <div className='shop-button col-8 rvs-ctn'>
                                                        <div className={"d-flex shctn col-6"}>
                                                            <div>
                                                                <Button icon={<ShopOutlined/>} onClick={() => {
                                                                    showProductShopClick(product.shopUserID)
                                                                }}>Xem shop</Button>
                                                            </div>
                                                            <div>
                                                                <Button icon={<WechatOutlined/>} onClick={showChatBox}
                                                                        disabled={userInfo?.userID && userInfo?.userID == product?.shopUserID}>Nhắn
                                                                    tin</Button>
                                                            </div>
                                                        </div>
                                                        <div className='review-rate rvs-ctn col-6'>
                                                            <p className={"p-tag-c"}>{product.totalAverageReview}/5 <span
                                                                style={{color: '#fdc040'}}>★</span> <br></br> <span
                                                                style={{fontSize: '11px'}}>{product.totalQuantityReview} Đánh giá</span>
                                                            </p>
                                                            <p className={"p-tag-c"}> {product.totalSold}<br></br><span
                                                                style={{fontSize: '11px'}}> Đã bán</span></p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    )

                                }

                                {
                                    isMobile && (
                                        <div className="row" style={{justifyContent: "center"}}>
                                            {/*<div className="col-6" style={{display: 'flex', alignItems: 'center'}}>*/}
                                            {/*    <div className="images-container first-line">*/}
                                            {/*        <Image className='img-product-detail' src={product.image}/>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            <div className="col-10 container-attr">
                                                <div className={"title-rate-container"}>
                                                    <h1 className="color-brand-3 title-content"
                                                        style={{fontSize: '1.8rem'}}>{product.productName}</h1>
                                                    <div className="">
                                                        <div className="rating" style={{display:"flex",flexDirection:"column"}}>
                                                            <div className="product-rate d-inline-block" style={{marginBottom:4}}>
                                                                <div className="product-rating"
                                                                     style={{width: product.averageReview * 20 + "%"}}></div>
                                                            </div>
                                                            <span className="rating-text color-gray-500 font-medium col-xs-12"> ({product.quantityReview} đánh giá) | Đã bán {product.sold} | Tồn kho: {!productSelected?.isCheckStock ? '999+' : productSelected.quantityInventory}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/*image*/}
                                                <div className="title-rate-container"
                                                     style={{display: 'flex', alignItems: 'center'}}>
                                                    <Badge.Ribbon
                                                        style={{
                                                            display: productSelected?.isSignContract ? 'block' : 'none',
                                                            minWidth: '56px'
                                                        }}
                                                        text={<IconHoverProduct iconName={'sign-contract-ic'}
                                                                                title={'Ký hợp đồng'}/>}
                                                        color="#fd9636"
                                                    >
                                                        <Badge.Ribbon
                                                            style={{
                                                                display: !productSelected?.isCheckStock ? 'block' : 'none',
                                                                minWidth: '56px',
                                                                top: productSelected?.isSignContract ? '36px' : '8px'
                                                            }}
                                                            text={<IconHoverProduct iconName={'stock-ic'}
                                                                                    title={'Mua quá tồn kho'}/>}
                                                            color="#fd9636"
                                                        >
                                                            <Badge.Ribbon
                                                                style={{
                                                                    display: productSelected?.isBuyAutomatic ? 'block' : 'none',
                                                                    minWidth: '56px',
                                                                    top: productSelected?.isSignContract && !productSelected?.isCheckStock ? '66px'
                                                                        : ((!productSelected?.isSignContract && !productSelected?.isCheckStock) || productSelected?.isSignContract) ? '36px' : '8px'
                                                                }}
                                                                text={<IconHoverProduct iconName={'automatic-ic'}
                                                                                        title={'Mua bán tự động'}/>}
                                                                color="#fd9636"
                                                            >
                                                                <div className="images-container first-line">
                                                                    <Image className='img-product-detail'
                                                                           src={product.image}/>
                                                                </div>
                                                            </Badge.Ribbon>
                                                        </Badge.Ribbon>
                                                    </Badge.Ribbon>
                                                </div>

                                                {productSelected?.price !== -1 && (<div
                                                    className="mb-40 product-price color-brand-3 price-item-detail__container">
                                                    <h4 style={{color: 'red'}} className={"price-item-detail__container"}>
                                                        <p>{formatCurrency(productSelected?.price * ((100 - productSelected?.discount) / 100 || 1))}</p>
                                                        <span>₫</span>
                                                    </h4>
                                                    {productSelected?.discount > 0 &&
                                                        (
                                                            <div className={"price-item-detail__container"}>
                                                                <del
                                                                    style={{
                                                                        fontSize: '18px',
                                                                        color: '#8c9ec5'
                                                                    }} className={"del-container"}>
                                                                    <p>{formatCurrency(productSelected?.price)} </p>
                                                                    <span className={"d-icon"}>₫</span>
                                                                </del>

                                                                <span
                                                                    style={{fontSize: '18px'}}>-{productSelected?.discount}%</span>
                                                            </div>
                                                        )}

                                                </div>)
                                                }
                                                {productSelected?.price == -1 && (<div
                                                    className="mb-40 product-price color-brand-3 price-item-detail__container">
                                                    <h4 style={{color: 'red'}} className={"price-item-detail__container"}>
                                                        <p>Liên Hệ</p>
                                                    </h4>

                                                </div>)
                                                }
                                                <div className="buy-product">
                                                    <div>
                                                        <div className={"tag-container"}>
                                                            <p style={{marginRight: 8}}>Phân loại:</p>
                                                            <b>{selectedTags}</b>
                                                        </div>
                                                        {tagsData?.map((tag) => (
                                                            <CheckableTag
                                                                style={{
                                                                    fontSize: '14px',
                                                                    minWidth: '48px',
                                                                    padding: '4px 10px 4px 10px',
                                                                    margin: '3px 4px',
                                                                    background: selectedTags.includes(tag?.productItemName) ? '#e6f4ff' : '#DFDFDF',
                                                                    color: selectedTags.includes(tag?.productItemName) ? '#0958d9' : '',
                                                                    border: selectedTags.includes(tag?.productItemName) ? ' 1px solid #91caff' : ''
                                                                }}
                                                                key={tag?.productItemName}
                                                                checked={selectedTags.includes(tag?.productItemName)}
                                                                onChange={(checked) => handleChange(tag?.productItemName, checked)}
                                                            >
                                                                {tag?.productItemName}
                                                            </CheckableTag>
                                                        ))}

                                                    </div>
                                                    <div>

                                                    </div>
                                                </div>
                                                <div className="quantity-container mt-10 d-flex ">
                                                    <div className="font-sm text-quantity">Số lượng:</div>
                                                    <div className={'calculator-container '}>
                                                        <div style={{
                                                            marginRight: '5%',
                                                            display: "flex",
                                                            flexWrap: "nowrap",
                                                            marginBottom: "10px"
                                                        }}>
                                                            <button className={'btn-s'} type='button' id={'decrease'}
                                                                    onClick={handleMinusClick}>-
                                                            </button>
                                                            <input className={'btn-s input-s'} type='text' id={'raw'}
                                                                   value={quantity}
                                                                   onChange={value => handleChangeQuantity(value.target.value)}
                                                                   onBlur={value => handleBlurQuantity(value.target.value)}
                                                            />
                                                            <button className={'btn-s'} type='button' id={'increase'}
                                                                    onClick={handlePlusClick}>+
                                                            </button>
                                                        </div>
                                                        <FlInput
                                                            disabled={productSelected?.priceType == PRICE_TYPE.GIA_LIEN_HE}
                                                            onChange={values => {
                                                                if (productSelected?.price != PRICE_TYPE.GIA_LIEN_HE) {
                                                                    setTxtDiscount(values.target.value)
                                                                }
                                                            }}
                                                            onSearch={values => {
                                                                setTxtDiscount(values)
                                                            }}
                                                            allowClear
                                                            label={productSelected?.price == PRICE_TYPE.GIA_LIEN_HE ? 'Nhập mã giá' : "Nhập mã giảm giá"}
                                                            search={productSelected?.price == PRICE_TYPE.GIA_LIEN_HE}
                                                            style={{height: "32px"}}
                                                        />
                                                    </div>

                                                </div>


                                                <div className="buy-product ">
                                                    <div className="box-quantity">
                                                        <div className="button-buy ">
                                                            <Button className={"add-to-cart__btn"} danger onClick={addCart}>
                                                                <ShoppingCartOutlined style={{fontSize: '20px'}}/>
                                                                Thêm vào giỏ hàng
                                                            </Button>
                                                            <Button className={"buy-now__btn"} type="primary" danger
                                                                    onClick={CheckPromoteCode}
                                                                    disabled={productSelected?.price == PRICE_TYPE.GIA_LIEN_HE
                                                                        || product?.status == SATUS_PRODUCT.NGUNG_BAN
                                                                        || (productSelected?.isBuyAutomatic && productSelected?.quantityInventory < quantity)
                                                                    }
                                                            >
                                                                Mua ngay
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"col-12"} onClick={"border-bt"}></div>
                                            <div className="col-lg-12 shop-information d-flex">
                                                <div className='shop-infor shop-infor-mb' style={{flexWrap: "wrap"}}>
                                                    <div className={"col-md-5 rvs-ctn"} style={{
                                                        display: "flex",
                                                        flexWrap: "nowrap",
                                                        marginBottom: "14px",
                                                        marginRight: "10px"
                                                    }}>
                                                        <img className='shop-img' src={product.avatarShop}/>
                                                        <div className='shop-name'>
                                                            <h5>{product.shopUsername}</h5>
                                                            <img
                                                                src="https://salt.tikicdn.com/ts/upload/5d/4c/f7/0261315e75127c2ff73efd7a1f1ffdf2.png"/>
                                                        </div>
                                                    </div>
                                                    <div className='shop-button  rvs-ctn cstd' style={{
                                                        justifyContent: "flex-start",
                                                        marginBottom: "14px",
                                                        flex: 1
                                                    }}>
                                                        <div className={"d-flex shctn col-6"}>
                                                            <div>
                                                                <Button icon={<ShopOutlined/>} onClick={() => {
                                                                    showProductShopClick(product.shopUserID)
                                                                }}>Xem shop</Button>
                                                            </div>
                                                            <div>
                                                                <Button icon={<WechatOutlined/>} onClick={showChatBox}
                                                                        disabled={userInfo?.userID && userInfo?.userID == product?.shopUserID}>Nhắn
                                                                    tin</Button>
                                                            </div>
                                                        </div>
                                                        <div className='review-rate rvs-ctn col-6'>
                                                            <p className={"p-tag-c"}>{product.totalAverageReview}/5 <span
                                                                style={{color: '#fdc040'}}>★</span> <br></br> <span
                                                                style={{fontSize: '11px'}}>{product.totalQuantityReview} Đánh giá</span>
                                                            </p>
                                                            <p className={"p-tag-c"}> {product.totalSold}<br></br><span
                                                                style={{fontSize: '11px'}}> Đã bán</span></p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    )

                                }
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-box shop-template">
                    <div className="container partern">
                        <div className=" mb-10 first-line">
                            <Tabs
                                onChange={handleTabChange}
                                defaultActiveKey="1"
                                items={[AppleOutlined, AndroidOutlined].map((Icon, i) => {
                                    const id = String(i + 1);
                                    return {
                                        label: (
                                            <p className={"des-title"}>{id == 1 ? 'Mô tả sản phẩm' : `Đánh giá (${product.averageReview}/5)`}</p>
                                        ),
                                        key: id,
                                        // children: `Tab ${id}`,
                                    };
                                })}
                            />

                            <div className="tab-content">
                                <div
                                    className={`tab-pane fade ${activeTab === 'tab-description' ? 'active show' : ''}`}
                                    id="tab-description"
                                    role="tabpanel"
                                    aria-labelledby="tab-description"
                                >
                                    <div
                                        className="display-text-short height-ctn-500"
                                        dangerouslySetInnerHTML={{__html: product.description}}
                                    />
                                </div>
                                <Spin spinning={loadingReview}>
                                    <div
                                        className={`tab-pane fade ${activeTab === 'tab-reviews' ? 'active show' : ''}`}
                                        id="tab-reviews"
                                        role="tabpanel"
                                        aria-labelledby="tab-reviews"
                                    >
                                        <div className="comments-area height-ctn-500"
                                             style={{display: activeTab === 'tab-reviews' ? 'block' : 'none'}}>
                                            <div className='container'>
                                                {/* Đánh giá sản phẩm (sao) */}
                                                <div className="row">
                                                    <div className='col-lg-2 col-rating-left'>
                                                        <div className='rate-point'>
                                                            <b class="fs-2">{product.averageReview}</b><span
                                                            class="fs-4">/5</span>
                                                        </div>
                                                        <div className="rating ">
                                                            <div className="product-rate d-inline-block">
                                                                <div className="product-rating"
                                                                     style={{width: product.averageReview * 20 + "%"}}></div>
                                                            </div>
                                                        </div>
                                                        <div className='number-review'>
                                                            <span class="fs-6">{product.quantityReview} đánh giá</span>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg col-rating-right'>
                                                        <div className='rate-star'>
                                                            <div className="product-rate d-inline-block">
                                                                <div className="product-rating"
                                                                     style={{width: 5 * 20 + "%"}}></div>
                                                            </div>
                                                            <div className="progress progress-star">
                                                                <div className="progress-bar" role="progressbar"
                                                                     style={{width: (product?.productReview?.quantiy5Star / product?.quantityReview) * 100 + "%"}}
                                                                     aria-valuenow="100" aria-valuemin="0"
                                                                     aria-valuemax="100">
                                                                </div>
                                                            </div>
                                                            <p className='txtnumber-rate'>{product?.productReview?.quantiy5Star}</p>
                                                        </div>
                                                        <div className='rate-star'>
                                                            <div className="product-rate d-inline-block">
                                                                <div className="product-rating"
                                                                     style={{width: 4 * 20 + "%"}}></div>
                                                            </div>
                                                            <div className="progress progress-star">
                                                                <div className="progress-bar" role="progressbar"
                                                                     style={{width: (product?.productReview?.quantiy4Star / product?.quantityReview) * 100 + "%"}}
                                                                     aria-valuenow="100" aria-valuemin="0"
                                                                     aria-valuemax="100">
                                                                </div>
                                                            </div>
                                                            <p className='txtnumber-rate'>{product?.productReview?.quantiy4Star}</p>
                                                        </div>
                                                        <div className='rate-star'>
                                                            <div className="product-rate d-inline-block">
                                                                <div className="product-rating"
                                                                     style={{width: 3 * 20 + "%"}}></div>
                                                            </div>
                                                            <div className="progress progress-star">
                                                                <div className="progress-bar" role="progressbar"
                                                                     style={{width: (product?.productReview?.quantiy3Star / product?.quantityReview) * 100 + "%"}}
                                                                     aria-valuenow="100" aria-valuemin="0"
                                                                     aria-valuemax="100">
                                                                </div>
                                                            </div>
                                                            <p className='txtnumber-rate'>{product?.productReview?.quantiy3Star}</p>
                                                        </div>
                                                        <div className='rate-star'>
                                                            <div className="product-rate d-inline-block">
                                                                <div className="product-rating"
                                                                     style={{width: 2 * 20 + "%"}}></div>
                                                            </div>
                                                            <div className="progress progress-star">
                                                                <div className="progress-bar" role="progressbar"
                                                                     style={{width: (product?.productReview?.quantiy2Star / product?.quantityReview) * 100 + "%"}}
                                                                     aria-valuenow="100" aria-valuemin="0"
                                                                     aria-valuemax="100">
                                                                </div>
                                                            </div>
                                                            <p className='txtnumber-rate'>{product?.productReview?.quantiy2Star}</p>
                                                        </div>
                                                        <div className='rate-star'>
                                                            <div className="product-rate d-inline-block">
                                                                <div className="product-rating"
                                                                     style={{width: 1 * 20 + "%"}}></div>
                                                            </div>
                                                            <div className="progress progress-star">
                                                                <div className="progress-bar" role="progressbar"
                                                                     style={{width: (product?.productReview?.quantiy1Star / product?.quantityReview) * 100 + "%"}}
                                                                     aria-valuenow="100" aria-valuemin="0"
                                                                     aria-valuemax="100">
                                                                </div>
                                                            </div>
                                                            <p className='txtnumber-rate'>{product?.productReview?.quantiy1Star}</p>
                                                        </div>

                                                    </div>
                                                </div>
                                                {/* Phân loại sao */}
                                                <div className="row rate-option">
                                                    <div className='col-2 txt-title-rate'>Đánh giá sản phẩm</div>
                                                    <div
                                                        className='col-lg-1 text-center rating-classification auto-active active'
                                                        value="0" onClick={onClickStarTab}>Tất cả
                                                    </div>
                                                    <div className='col-lg-1 text-center rating-classification'
                                                         value="5"
                                                         onClick={onClickStarTab}>5 sao
                                                    </div>
                                                    <div className='col-lg-1 text-center rating-classification'
                                                         value="4"
                                                         onClick={onClickStarTab}>4 sao
                                                    </div>
                                                    <div className='col-lg-1 text-center rating-classification'
                                                         value="3"
                                                         onClick={onClickStarTab}>3 sao
                                                    </div>
                                                    <div className='col-lg-1 text-center rating-classification'
                                                         value="2"
                                                         onClick={onClickStarTab}>2 sao
                                                    </div>
                                                    <div className='col-lg-1 text-center rating-classification'
                                                         value="1"
                                                         onClick={onClickStarTab}>1 sao
                                                    </div>
                                                </div>
                                                {/* Đánh giá sản phẩm (cmt) */}
                                                <div className="row">
                                                    <div className="comment-list">
                                                        {/* map here */}
                                                        {dataProductReview?.list?.map(data => (
                                                            <div className='row cmt-product'>
                                                                <div className='col-lg-2 d-flex user-infor-review'>
                                                                    <div className="thumb text-center"><img
                                                                        src={data.avatar}
                                                                        alt="Ecom"
                                                                        style={{maxHeight: 70, maxWidth: 70, objectFit: 'contain'}}/>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg'>
                                                                    <div>
                                                                        <p className='user-name-review font-heading'>{data.username}</p>
                                                                        <div className="rating ">
                                                                            <div
                                                                                className="product-rate d-inline-block">
                                                                                <div className="product-rating"
                                                                                     style={{width: data.starRating * 20 + "%"}}></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="d-flex align-items-center time-send">
                                                                <span
                                                                    className="font-xs color-gray-700">{formatDate(data.createDate)}</span>
                                                                    </div>
                                                                    <div className='content-review-product'>
                                                                        <p className="mb-10 font-sm color-gray-900">
                                                                            {data.contentRating}
                                                                        </p>
                                                                        {   data.image &&
                                                                            (<div className='img-cmt-product'>
                                                                                <Image className='img-review'
                                                                                    src={data.image}/>
                                                                            </div>)
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            style={{display: activeTab === 'tab-reviews' && Math.ceil(totalReview / 10) > 0 ? 'block' : 'none'}}>
                                            <ul className="pagination justify-content-end">
                                                <li className="page-item">
                                                    <a className="page-link page-prev" onClick={() => {
                                                        handlePageChange((productReview.currentPage - 1))
                                                    }}></a>
                                                </li>
                                                {renderPagination()}
                                                <li className="page-item">
                                                    <a className="page-link page-next" onClick={() => {
                                                        handlePageChange((productReview.currentPage + 1))
                                                    }}></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Spin>
                                <div className="border-bottom pt-30 mb-50"></div>

                            </div>
                        </div>
                    </div>
                    <SweetAlert2 {...swalProps} />
                    {!!openLoginModal && (
                        <LoginModal
                            stopNavigate={true}
                            openLoginModal={openLoginModal}
                            handleCancel={() => setOpenLoginModal(false)}
                            handleRegister={() => setOpenSelectRegisterModal(true)}
                            setOpenForgetPassModal={() => setOpenForgetPassModal(true)}
                        />
                    )}
                    {!!openForgetPassModal && (
                        <ForgetModal
                            openForgetPassModal={openForgetPassModal}
                            handleOk={() => {
                            }}
                            handleCancel={() => setOpenForgetPassModal(false)}
                            handleLogin={() => setOpenLoginModal(true)}
                            setOpenVerifyModal={() => setOpenVerifyModal(true)}
                            setEmail={setEmail}
                        />
                    )}
                    {!!openVerifyModal && (
                        <VerifyForgetModal
                            openVerifyModal={openVerifyModal}
                            handleOk={() => {
                            }}
                            handleCancel={() => setOpenVerifyModal(false)}
                            handleLogin={() => setOpenLoginModal(true)}
                            setRePasswordModal={() => setRePasswordModal(true)}
                            email={email}
                            setCodeVerify={setCodeVerify}
                        />
                    )}
                    {!!rePasswordModal && (
                        <RePasswordModal
                            rePasswordModal={rePasswordModal}
                            handleOk={() => {
                            }}
                            handleCancel={() => setRePasswordModal(false)}
                            handleLogin={() => setOpenLoginModal(true)}
                            email={email}
                            codeVerify={codeVerify}
                        />
                    )}
                    {!!openSelectRegisterModal && (
                        <RegisterModal
                            openRegisterModal={openSelectRegisterModal}
                            handleOk={() => {
                            }}
                            handleCancel={() => setOpenSelectRegisterModal(false)}
                            handleLogin={() => setOpenLoginModal(true)}
                        />
                    )}
                </section>
                <section className="section-box shop-template mt-50 mb-36">
                    <ProductRelated products={productRelated}/>
                </section>

                {isShowConfirmBuyProduct &&
                    <PopupConfirm
                        open={isShowConfirmBuyProduct}
                        productSelected={productSelected}
                        priceSaleProductItem={priceSaleProductItem}
                        quantityBuy={quantity}
                        promoteCode={txtDiscount}
                        onOk={handleBuyNowClick}
                        onCancel={() => {
                            setIsShowConfirmBuyProduct(false);
                        }}
                    />
                }
            </main>
        </Spin>
    )
}
