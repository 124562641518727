import {Anchor, Avatar, Button, Col, Divider, Row, Space, Tag} from "antd";
import {UserOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import './style/preview.css'
import {ORDER_STATUS} from "../../../../constants/constants";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import SpinCustom from "../../../../components/Spin";
import Swal from "sweetalert2";
import {FloatActionWrapper} from "../../../../components/FloatAction/styles";
import {ListUserStyled} from "../../Company/styled";
import TableCustom from "../../../../components/Table/CustomTable";
import ButtonCircle from "../../../../components/MyButton/ButtonCircle";
import CB1 from "../../../../components/Modal/CB1";
import CancelModal from "../../../../components/Modal/CancelOrderModal/CancelModal";
import CancelAdminModal from "../../../../components/Modal/CancelOrderModal/CancelAdminModal";
import ROUTER from "../../../../router";
import http from "../../../../services";
import Search from "../components/Search";
import {renderStatusTitle} from "../../../../lib/utils";

export const OrderListTable = ({
                                   order,
                                   refreshData,
                                   totalPage,
                                   pagination,
                                   getOrder,
                                   onChangeKeyword,
                                   handleFilter,
                                    refresh,
                                   ...props
                               }) => {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [showCancel, setShowCancel] = useState(false);
    const [orderId, setOrderId] = useState();
    const [dataBodySource, setDataBodySource] = useState({
        pageSize: 10,
        currentPage: 1,
    });

    const handleChangePage = (page, pageSize) => {
        const model = {...pagination, pageSize: pageSize || 10, currentPage: page || 1}
        getOrder(model);
    }

    const handleShowCancelModal = () => {
        setShowCancel(true);
    }
    const handleHideCancelModal = () => {
        setShowCancel(false);
    }

    const handleRefreshCancelChange = () => {
        // const od = order.find(x => x.orderId === orderId);
        // const o = {...od, status: ORDER_STATUS.DA_HUY};
        // refreshData(od?.orderId, o);
        refresh();
    }


    const formatCurrency = (value) => {
        return new Intl.NumberFormat('vi-VN', {
            style: 'currency',
            currency: 'VND',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value).replace('VND', 'đ');
    };
    const renderNewPrice = (price, discount) => {
        return price * (100 - discount) / 100;
    }



    const getOrderDetail = async () => {
        return await axios.post(`Order/GetOrderDetail?orderId=${order?.orderId}`)
    }
    const showCancelModal = (id) => {
        setOrderId(id);
        setShowCancel(true);
    }
    const renderButton = (order) => {
        switch (order.status) {
            case ORDER_STATUS.CHO_KY:
                return (
                    <div className="box-item__container__ad">
                        {renderCancelButton(order)}
                    </div>
                )
            case ORDER_STATUS.CHO_XAC_NHAN:
                return (
                    <div className="box-item__container__ad">
                        {
                            renderConfirmButton(order)
                        }
                        {
                            renderCancelButton(order)
                        }
                        {/*<ButtonCircle className="box__item" onClick={() => showCancelModal(order.id)}>Xác nhận đơn*/}
                        {/*    hàng</ButtonCircle>*/}
                    </div>
                );
            default:
                return null;
        }

    };

    const renderCancelButton = (order) => {
        if(order?.isCancel){
                return (
                    <ButtonCircle
                        title="Hủy đơn hàng"
                        iconName="cancel"
                        style={{background: "#FFE9EC"}}
                        onClick={() => {
                            CB1({
                                title: `Bạn có chắc chắn muốn hủy đơn hàng này không?`,
                                icon: "warning-usb",
                                okText: "Đồng ý",
                                onOk: async close => {
                                    showCancelModal(order?.orderId);
                                    close();
                                },
                            })
                        }}
                    />
                )
        }else{
            return "";
        }
    }
    const renderConfirmButton = (order) => {
        if(order?.isConfirm){
            return (
                <ButtonCircle
                    title="Xác nhận đơn hàng"
                    iconName="green-accept"
                    style={{background: "#fff"}}
                    onClick={() =>
                        CB1({
                            title: `Bạn có chắc chắn muốn xác nhận đơn hàng này ?`,
                            icon: "warning-usb",
                            okText: "Đồng ý",
                            onOk: async close => {
                                handleConfirmStatus(order?.orderId);
                                close();
                            },
                        })
                    }
                />
            )

        }else{
            return "";
        }
    }
    const handleConfirmStatus = (orderId) => {
        setLoading(true)
        http.post(`/Order/ConfirmOrder?OrderId=${orderId}`).then(resp => {
            if (!resp?.isError) {
                showSuccessUpdate("Bạn đã xác nhận đơn hàng thành công", orderId);
                setLoading(false);
            }
        }).finally(() => {
            setLoading(false);
        })
    }
    const showSuccessUpdate = (message, orderId) => {
        setLoading(true)
        Swal.fire({
            icon: 'success',
            title: 'Thành Công',
            text: message,
        }).then(() => {
            // const od = order.find(x => x.orderId === orderId);
            // const o = {...od, status: ORDER_STATUS.CHO_KY};
            // refreshData(orderId, o);
            refresh();
        });
    };
    const [totalDataSource, setTotalDataSource] = useState(0);
    const columns = [
        {
            title: "Tên cửa hàng",
            dataIndex: "shopName",
            key: "shopName",
            width: 100,
            align: "left",
        },
        {
            title: "sản phẩm",
            dataIndex: "productName",
            key: "productName",
            render: (value, order) =>
                (
                    <div className={"shop-name__container"} onClick={() => {
                        navigate(`${ROUTER.ORDER_DETAIL_ADMIN}/${order.orderId}`)
                    }}>
                        <Avatar src={order.image} icon={<UserOutlined/>} size={40} style={{marginRight: 8}}
                                className={"col-4"}/>
                        <div className={"shop-name__container__item col-8"}>
                            <div className={"col-12 line-1"}>{value}</div>
                            {/*<div className={"col-12"} style={{display: "flex"}}>*/}
                            {/*    <div style={{marginRight: 6}}>Phân loại:</div>*/}
                            {/*    <Tag>{order?.productItemName}</Tag>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                ),
            width: 240,
            align: "left",
        },
        {
            title: "phân loại",
            dataIndex: "productName",
            key: "productName",
            render: (value, order) =>
                (
                    <div className={"d-flex-start"}>
                        <Tag>{order?.productItemName}</Tag>
                    </div>
                ),
            width: 120,
            align: "flex-end",
        },
        {
            title: "Số lượng",
            dataIndex: "quantity",
            key: "quantity",
            width: 60,
            align: "center",
        },
        {
            title: "Đơn giá",
            dataIndex: "sold",
            key: "sold",
            width: 120,
            align: "right",
            render: (value, order) => (
                <>
                    {

                        (order?.discount > 0) ?
                            (
                                <>
                                    <div
                                        className={'price price-active price-active-main line-1 at'}>{formatCurrency(renderNewPrice(order.price, order.discount))}
                                    </div>
                                    <div className={"priceline-1"}>
                                        <del>{formatCurrency(order?.price.toString())}</del>
                                    </div>
                                </>
                            ) :
                            (
                                <div
                                    className={'price price-active price-active-main line-1 at'}>{formatCurrency(order?.price)}
                                </div>
                            )
                    }
                </>
            )
        },
        {
            title: "Tổng tiền",
            dataIndex: "total",
            key: "total",
            width: 130,
            align: "right",
            render: (value, order) => (
                <>
                    {

                        (order?.discount > 0) ?
                            (
                                <>

                                    <div
                                        className={'price price-active price-active-main line-1 at'}>{formatCurrency(renderNewPrice(order.price * (order?.quantity), order.discount))}
                                    </div>
                                    <div className={"priceline-1"}>
                                        <del>{formatCurrency((order?.price * order?.quantity).toString())}</del>
                                    </div>

                                </>
                            ) :
                            (
                                <>
                                    <div
                                        className={'price price-active price-active-main line-1 at'}>{formatCurrency(order?.price * order?.quantity)}
                                    </div>

                                </>
                            )
                    }
                </>
            )
        },
        {
            title: "Trạng thái",
            dataIndex: "status",
            key: "status",
            width: 100,
            align: "center",
            render: (value, order) => (
                <>
                    <FloatActionWrapper size="small" className="float-action__wrapper" style={{background: "#fff"}}>
                        {renderButton(order)}
                    </FloatActionWrapper>
                    <div>
                        {renderStatusTitle(value)}
                    </div>
                </>
            )
        }
        // {
        //     title: "",
        //     dataIndex: "action",
        //     key: "action",
        //     width: 100,
        //     render: (text, record) => (
        //         <div className="d-flex-end justify-content-center align-items-center mh-36">
        //             <FloatActionWrapper size="small" className="float-action__wrapper" style={{background: "#fff"}}>
        //                 {renderButton(record)}
        //             </FloatActionWrapper>
        //         </div>
        //     ),
        //
        // },
    ]
    return (
        <>
            <ListUserStyled>
                <div>
                    <Search

                        onChangeKeyword={onChangeKeyword}
                        pagination={pagination}
                        handleFilter={handleFilter}
                        mode={props.mode}
                    />
                    <Divider className="mv-16"/>
                </div>
                <Anchor
                    affix={false}
                    getContainer={() => document.getElementById("body-admin-scroll")}
                >
                    <Row gutter={[16, 16]} style={{marginBottom:10}}>
                        <Col style={{width: 0}} flex="auto">
                            <div className={"d-flex"}>
                            <p style={{fontWeight:600,fontSize:16,marginRight:4}}>Doanh thu:</p>
                            <p style={{fontWeight:600,fontSize:16,color:"red"}}>{formatCurrency(order?.totalValue)}</p>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col style={{width: 0}} flex="auto">
                            <SpinCustom spinning={loading}>
                                <TableCustom
                                    isPrimary
                                    // onRow={record => {
                                    //     return {
                                    //         onClick: () => {
                                    //             setOpenModalUserDetail(record)
                                    //         },
                                    //     }
                                    // }}
                                    className="mb-6"
                                    dataSource={order?.list}
                                    columns={columns}
                                    textEmpty="Không có đơn hàng"
                                    rowKey="orderId"
                                    sticky={{offsetHeader: -12}}
                                    scroll={{y: "100%", x: "800px"}}
                                    pagination={{
                                        defaultPage: dataBodySource.pageSize,
                                        showSizeChanger: true,
                                        pageSizeOptions: ['10', '20', '30'],
                                        total: totalPage,
                                        // onChange: (page, pageSize) => {
                                        //     setDataBodySource((prevState) => ({
                                        //         ...prevState,
                                        //         pageSize: pageSize || 10,
                                        //         currentPage: page || 1
                                        //     }));
                                        // },
                                        onChange: (page, pageSize) => {
                                            handleChangePage(page, pageSize);
                                        }
                                    }}
                                />
                            </SpinCustom>
                        </Col>
                    </Row>
                </Anchor>
                {showCancel && (
                    <CancelAdminModal
                        onCancel={() => handleHideCancelModal()}
                        visible={showCancel}
                        order_id={orderId}
                        refreshData={() => handleRefreshCancelChange()}
                        loading={loading}
                        setLoading={setLoading}
                    />
                )}
            </ListUserStyled>
        </>

    )
}
