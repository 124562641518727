import {
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  TreeSelect,
  Upload,
} from "antd"
import moment from "moment"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import CustomModal from "src/components/Modal/CustomModal"
import Button from "src/components/MyButton/Button"
import ButtonCircle from "src/components/MyButton/ButtonCircle"
import Notice from "src/components/Notice"
import SpinCustom from "src/components/Spin"
import SvgIcon from "src/components/SvgIcon"
import { GUIDE_EMPTY, SYSTEM_KEY } from "src/constants/constants"
import {
  getRegexEmail,
  getRegexMobile,
  getRegexPassword,
  getRegexPhoneNumber,
  getRegexUsername,
} from "src/lib/stringsUtils"
import { getListComboByKey, nest, normFile } from "src/lib/utils"
import Category from "src/services/CategoryService"
import FileService from "src/services/FileService"
import Product from "src/services/ProductionService"
import RoleService from "src/services/RoleService"
import UserService from "src/services/UserService"
import styled from "styled-components"
import { ButtonUploadStyle } from "../styled"
import RegionService from "src/services/RegionService"
import ContactService from "src/services/ContactService"
const { Option } = Select
const Styled = styled.div`
  .ant-upload.ant-upload-select-picture-card {
    width: unset;
    height: unset;
    background-color: unset;
    border: unset;
  }
  .ant-upload-list {
    align-items: center;
    display: flex;
  }
`
const ModalInsertUpdate = ({ onOk, reloadTable, ...props }) => {
  const { listSystemKey } = useSelector(state => state.appGlobal);
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [email, setEmail] = useState('');
  const [name, setFullName] = useState('');
  const [phone, setPhoneNumber] = useState('');
  const [provinceID, setProvince] = useState(0);
  const [districtID, setDistrict] = useState(0);
  const [wardID, setWard] = useState(0);
  const [address, setAddress] = useState('');
  const [content, setContent] = useState('');
  const [listProvince, setListProvince] = useState([]);
  const [listDistrict, setListDistrict] = useState([]);
  const [listWard, setListWard] = useState([]);

  //#region useEffect

  const getListProvinceVN = () => {
    setLoading(true)
    RegionService.getByRegionId({ regionId: 234 })
        .then(res => {
        if (res?.isError) return
        setListProvince(res?.object);
        // loadListDistrict(userInfo.provinceID);
        // loadListWard(userInfo.districtID);
        })
        .finally(() => setLoading(false));
  }

  useState(() => {
      getListProvinceVN();
  },[]);

  //#endregion
    
  //#region function

  const loadListDistrict=(newValue)=>{
    if(newValue&&newValue!==0){
        RegionService.getByRegionId({ regionId: newValue })
            .then(res => {
            if (res?.isError) return
            const lstDistrict = res?.object||[];
            setListDistrict(lstDistrict)
            })
    }
  }

  const loadListWard=(newValue)=>{
    if(newValue&&newValue!==0){
        RegionService.getByRegionId({ regionId: newValue })
            .then(res => {
            if (res?.isError) return
            const listWard = res?.object||[];
            setListWard(listWard)
            })
    }
  }

  const onChangeProvince = newValue => {
    form.resetFields([`districtID`]);
    form.resetFields([`wardID`]);

    setDistrict('');
    setWard('');

    if (!newValue) {
        setListDistrict([]);
        return;
    }

    setLoading(true);
    
    setProvince(newValue);

    RegionService.getByRegionId({ regionId: newValue })
        .then(res => {

            if (res?.isError) return
            const lstDistrict = res?.object||[];
            setListDistrict(lstDistrict)
        })
    .finally(() => setLoading(false));
  };

  const onChangeDistrict = newValue => {
    form.resetFields([`wardID`]);

    setWard('');

    if (!newValue) return setListWard([]);
    setLoading(true);

    setDistrict(newValue);

    RegionService.getByRegionId({ regionId: newValue })
        .then(res => {
        if (res?.isError) return
        const lstWard = res?.object||[];
        setListWard(lstWard);
        })
        .finally(() => setLoading(false))
  }

  const onChangeWard = newValue => {
      setWard(newValue);
  }

  /**
   * @description Thực hiện luồng lưu
   * @author hddung 
   */
  const onContinue = async () => {
    
    setLoading(true);
    try {
        const values = await form.validateFields();
        if(values) {
            const res = await ContactService.addContactByAdmin({
              title: values?.title,
              email: values?.email,
              name: values?.name,
              phone: values?.phone,
              provinceID: values?.provinceID,
              districtID: values?.districtID,
              wardID: values?.wardID,
              address: values?.address,
              content: values?.content,
            });
            if(res?.isError) return
            
            Notice({
                isSuccess: true,
                msg: "Tạo liên hệ thành công",
            });

            onOk && onOk();
            props?.onCancel();
            reloadTable();
        }
        
    } finally {
        setLoading(false);
    }
  }

  const renderFooter = () => (
    <div className="d-flex justify-content-flex-end">
      <Button
        className="btn-hover-shadow"
        onClick={() => props?.onCancel()}
      >
          Đóng
      </Button>
      <Button
        btnType="primary"
        className="btn-hover-shadow"
        onClick={onContinue}
      >
        Lưu
      </Button>
    </div>
  )

  //#endregion

  return (
    <CustomModal
      title={"Tạo yêu cầu hỗ trợ"}
      footer={renderFooter()}
      width={1024}
      {...props}
    >
      <SpinCustom spinning={loading}>
        <Styled>
        <Form
          form={form}
          layout="vertical"
          width="100%"
          >
            <Row gutter={[16, 16]}>
              <Col span={12}>
                  <Form.Item
                      rules={[
                      {
                          required: true,
                          message: "Thông tin không được bỏ trống",
                      },
                      ]}
                      required
                      label="Tiêu đề"
                      name="title"
                  >
                      <Input placeholder="Nhập tiêu đề"/>
                  </Form.Item>
              </Col>
              <Col span={12}>
                  <Form.Item
                      name="email"
                      label="Email"
                      required
                      rules={[
                      { required: true, message: "Thông tin không được để trống" },
                      {
                          pattern: getRegexEmail(),
                          message: "Email sai định dạng",
                      },
                      ]}
                  >
                      <Input placeholder="Nhập" onChange={val => setEmail(val.target.value)}/>
                  </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col  span={12}>
                  <Form.Item
                      rules={[
                      {
                          required: true,
                          message: "Thông tin không được bỏ trống",
                      },
                      ]}
                      label="Họ tên"
                      name="name"
                  >
                      <Input placeholder="Nhập họ tên" onChange={val => setFullName(val.target.value)}/>
                  </Form.Item>
              </Col>
              <Col  span={12}>
                  <Form.Item
                      name="phone"
                      label="Số điện thoại"
                      rules={[
                      {
                          pattern: getRegexPhoneNumber(),
                          message: "Vui lòng nhập đúng số điện thoại",
                      },
                      ]}
                  >
                      <Input placeholder="Nhập" onChange={val => setPhoneNumber(val.target.value)}/>
                  </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col  span={12}>
                  <Form.Item
                      label="Tỉnh/Thành phố"
                      name="provinceID"
                  >
                      <Select placeholder="-- Chọn Tỉnh/Thành phố --" allowClear  onChange={onChangeProvince}>
                          {
                              listProvince.map((province)=>(
                              <Option key={province.regionID} value={province.regionID}>{province.regionName}</Option>
                                  ))
                              }
                      </Select>
                  </Form.Item>
              </Col>
              <Col  span={12}>
                  <Form.Item
                      label="Quận/Huyện"
                      name="districtID"
                  >
                      <Select placeholder="-- Chọn Quận/Huyện --" allowClear  onChange={onChangeDistrict} notFoundContent="Bạn cần chọn Tỉnh/Thành phố">
                          {
                              listDistrict.map((district)=>(
                                  <Option key={district.regionID} value={district.regionID}>{district.regionName}</Option>
                              ))
                          }
                      </Select>
                  </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col  span={12}>
                  <Form.Item
                      label="Phường/Xã"
                      name="wardID"
                  >
                      <Select placeholder="-- Chọn Phường/Xã --" allowClear  onChange={onChangeWard} notFoundContent="Bạn cần chọn Quận/Huyện">
                          {
                              listWard.map((district)=>(
                                  <Option key={district.regionID} value={district.regionID}>{district.regionName}</Option>
                              ))
                          }
                      </Select>
                  </Form.Item>
              </Col>
              <Col  span={12}>
                  <Form.Item
                      label="Địa chỉ liên hệ"
                      name="address"
                  >
                      <Input placeholder="Vui lòng ghi đầy đủ số nhà, đường phố, thôn/tổ dân phố" onChange={val => setAddress(val.target.value)}/>
                  </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                  <Form.Item
                      rules={[
                      {
                          required: true,
                          message: "Thông tin không được bỏ trống",
                      },
                      ]}
                      label="Nội dung"
                      name="content"
                  >
                      <Input.TextArea style={{height: '150px'}} onChange={val => setContent(val.target.value)}/>
                  </Form.Item>
              </Col>
            </Row>
        </Form>
        </Styled>
      </SpinCustom>
    </CustomModal>
  )
}

export default ModalInsertUpdate
