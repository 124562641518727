import { Row, Col, Form, Input, Space, Spin, Statistic } from "antd"
import styled from "styled-components"
import { useState } from "react"
import CustomModal from "src/components/Modal/CustomModal"
import Button from "src/components/MyButton/Button"
import AuthService from "src/services/AuthService"
import Notice from "src/components/Notice"

const { Countdown } = Statistic
const StyleModalVerify = styled.div`
  .ant-statistic .ant-statistic-content {
    font-size: 20px !important;
  }
`

const ModalVerify = ({
  open,
  onOk,
  onCancel,
  formData,
  handleCancel,
  Role,
  roleID,
  dataRegister
}) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [code, setCode] = useState('')
  const [timeOut, setTimeOut] = useState(false)
  const [countDown, setCountDown] = useState(Date.now())

  const handleSubmit = async () => {
    try {
      setLoading(true)
  
      const verifyCode = await AuthService.verifyCode({ Email: dataRegister.email, codeVerify: code })
      if(verifyCode.isError) return
  
      Notice({
        isSuccess: true,
        msg: "Đăng ký thành công",
      });
      handleCancel();
    } finally {
      setLoading(false);
    }

  }

  const handleReCode = async () => {
    try {
      setLoading(true)
      const res = await AuthService.emailRegister({ Email: dataRegister.email, username: dataRegister.username, password: dataRegister.password })
      if (res?.isError) return
      
      Notice({
        isSuccess: true,
        msg: "Mã đã được gửi lại vào địa chỉ email của bạn",
      });

    } finally {
      setTimeOut(false);
      setCountDown(Date.now)
      setLoading(false)
    }
   
  }

  return (
    <CustomModal
      style={{}}
      title="Mã xác nhận"
      width="350px"
      maskClosable={false}
      footer={false}
      open={open}
      onCancel={onCancel}
    >
      <StyleModalVerify>
        <Spin spinning={loading}>
          <Form form={form}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Bạn chưa nhập mã xác nhận!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value.length == 6) {
                      return Promise.resolve()
                    } else
                      return Promise.reject(new Error("Mã xác nhận có 6 ký tự"))
                  },
                }),
              ]}
              name="CodeVerify"
            >
              <Input
                placeholder="Nhập mã xác nhận"
                onChange={val => setCode(val.target.value)}
                value={code}
                addonAfter={
                  !timeOut ? (
                    <Countdown
                      className="countdown"
                      value={countDown + 120 * 1000}
                      format="sss"
                      onFinish={() => {
                        setTimeOut(true)
                        Notice({
                          isSuccess: false,
                          msg: "Mã của bạn đã hết hạn. <br> Vui lòng nhấn 'Gửi lại mã'  để nhận mã mới",
                        })
                      }}
                    />
                  ) : (
                    <div>Mã đã hết hạn</div>
                  )
                }
              />
            </Form.Item>
            <Space
              size={16}
              style={{ width: "100%" }}
              className="justify-content-flex-end"
            >
              <Button btnType="" onClick={onCancel}>
                Đóng
              </Button>

              <Button btnType="third" onClick={handleReCode}>
                Gửi lại mã
              </Button>

              <Button
                btnType="primary"
                onClick={handleSubmit}
                disabled={timeOut}
              >
                Xác nhận
              </Button>
            </Space>
          </Form>
        </Spin>
      </StyleModalVerify>
    </CustomModal>
  )
}

export default ModalVerify
