import { Col, Button, Form, Input, Row, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import {getRegexEmail, getRegexMobileVN, getRegexPhoneNumber} from "src/lib/stringsUtils";
import RegionService from "src/services/RegionService";
import ContactService from "src/services/ContactService";
import ReCAPTCHA from "react-google-recaptcha";
import Notice from "src/components/Notice";
import "./style.css"

const { Option } = Select;

function Contact() {
    const url = window.location.href;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [email, setEmail] = useState('');
    const [name, setFullName] = useState('');
    const [phone, setPhoneNumber] = useState('');
    const [provinceID, setProvince] = useState(0);
    const [districtID, setDistrict] = useState(0);
    const [wardID, setWard] = useState(0);
    const [address, setAddress] = useState('');
    const [content, setContent] = useState('');
    const [response, setResponse] = useState('');
    const [listProvince, setListProvince] = useState([]);
    const [listDistrict, setListDistrict] = useState([]);
    const [listWard, setListWard] = useState([]);

    const getListProvinceVN = () => {
        setLoading(true)
        RegionService.getByRegionId({ regionId: 234 })
            .then(res => {
            if (res?.isError) return
            setListProvince(res?.object);
            // loadListDistrict(userInfo.provinceID);
            // loadListWard(userInfo.districtID);
            })
            .finally(() => setLoading(false));
    }

    useState(() => {
        getListProvinceVN();
    },[]);

    /**
     * @description set title tab web
     * @author hddung
     */
    useEffect(() => {
        if(url) {
            document.title = 'Liên hệ'; // Thay đổi title thành 'New Title'
        
            return () => {
            document.title = 'TẠP HÓA SỐ'; // Đặt lại title khi component bị unmount
            };
        }
    }, [url]);

    const loadListDistrict=(newValue)=>{
        if(newValue&&newValue!==0){
            RegionService.getByRegionId({ regionId: newValue })
                .then(res => {
                if (res?.isError) return
                const lstDistrict = res?.object||[];
                setListDistrict(lstDistrict)
                })
        }
    }

    const loadListWard=(newValue)=>{
        if(newValue&&newValue!==0){
            RegionService.getByRegionId({ regionId: newValue })
                .then(res => {
                if (res?.isError) return
                const listWard = res?.object||[];
                setListWard(listWard)
                })
        }
    }

    const onChangeProvince = newValue => {
        form.resetFields([`districtID`]);
        form.resetFields([`wardID`]);

        setDistrict('');
        setWard('');

        if (!newValue) {
            setListDistrict([]);
            return;
        }

        setLoading(true);
        
        setProvince(newValue);

        RegionService.getByRegionId({ regionId: newValue })
            .then(res => {

                if (res?.isError) return
                const lstDistrict = res?.object||[];
                setListDistrict(lstDistrict)
            })
        .finally(() => setLoading(false));
    };

    const onChangeDistrict = newValue => {
        form.resetFields([`wardID`]);

        setWard('');

        if (!newValue) return setListWard([]);
        setLoading(true);

        setDistrict(newValue);

        RegionService.getByRegionId({ regionId: newValue })
            .then(res => {
            if (res?.isError) return
            const lstWard = res?.object||[];
            setListWard(lstWard);
            })
            .finally(() => setLoading(false))
    }

    const onChangeWard = newValue => {
        setWard(newValue);
    }

    async function submitInforContact() {
        const userInfor = {
            title,
            email,
            name,
            phone,
            provinceID,
            districtID,
            wardID,
            address,
            content,
            response
        };
        
        setLoading(true);
        try {
            const values = await form.validateFields();
            if(values && response) {
                const res = await ContactService.addContact(userInfor);
                if(res?.isError) return
                
                Notice({
                    isSuccess: true,
                    msg: "Tạo liên hệ thành công",
                });

                window.grecaptcha.reset();
            }
            else if(!response) {
                Notice({
                    isSuccess: false,
                    msg: "Xác nhận bạn không là phải người máy",
                });
            }
            
        } finally {
            setLoading(false);
        }
    }

    return(
        <div style={{height: "fit-content"}}>
            <div className="section-box">
                <div className="breadcrumbs-div" style={{padding: '16px 0px 0px 0px'}}>
                    <div className="container">
                        <ul className="breadcrumb">
                            <li><a className="font-xs color-gray-500" href="./">Trang chủ</a></li>
                            <li><a className="font-xs color-gray-1000" href="">Liên hệ</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="section-box" style={{marginBottom: '16px'}}>
                <div className="contain-contact container">
                    <div>
                        <div className="title-type-1 d-flex justify-content-space-between align-items-center pb-16 pt-0 mb-16">
                            <div className="fs-24">
                                Liên hệ với chúng tôi
                            </div>
                        </div>
                        <div>
                            <Spin spinning={loading}>
                                <Form
                                form={form}
                                layout="vertical"
                                width="100%"
                                >
                                    <Row gutter={[16, 16]}>
                                        <Col span={12}>
                                            <Form.Item
                                                rules={[
                                                {
                                                    required: true,
                                                    message: "Thông tin không được bỏ trống",
                                                },
                                                ]}
                                                required
                                                label="Tiêu đề"
                                                name="Title"
                                            >
                                                <Input placeholder="Nhập tiêu đề" onChange={val => setTitle(val.target.value)}/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                name="email"
                                                label="Email"
                                                required
                                                rules={[
                                                { required: true, message: "Thông tin không được để trống" },
                                                {
                                                    pattern: getRegexEmail(),
                                                    message: "Email sai định dạng",
                                                },
                                                ]}
                                            >
                                                <Input placeholder="Nhập" onChange={val => setEmail(val.target.value)}/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 16]}>
                                        <Col  span={12}>
                                            <Form.Item
                                                rules={[
                                                {
                                                    required: true,
                                                    message: "Thông tin không được bỏ trống",
                                                },
                                                ]}
                                                label="Họ tên"
                                                name="FullName"
                                            >
                                                <Input placeholder="Nhập họ tên" onChange={val => setFullName(val.target.value)}/>
                                            </Form.Item>
                                        </Col>
                                        <Col  span={12}>
                                            <Form.Item
                                                name="phoneNumber"
                                                label="Số điện thoại"
                                                rules={[
                                                {
                                                    pattern: getRegexPhoneNumber(),
                                                    message: "Vui lòng nhập đúng số điện thoại",
                                                },
                                                ]}
                                            >
                                                <Input placeholder="Nhập" onChange={val => setPhoneNumber(val.target.value)}/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 16]}>
                                        <Col  span={12}>
                                            <Form.Item
                                                label="Tỉnh/Thành phố"
                                                name="provinceID"
                                            >
                                                <Select placeholder="-- Chọn Tỉnh/Thành phố --" allowClear  onChange={onChangeProvince}>
                                                    {
                                                        listProvince.map((province)=>(
                                                        <Option key={province.regionID} value={province.regionID}>{province.regionName}</Option>
                                                            ))
                                                        }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col  span={12}>
                                            <Form.Item
                                                label="Quận/Huyện"
                                                name="districtID"
                                            >
                                                <Select placeholder="-- Chọn Quận/Huyện --" allowClear  onChange={onChangeDistrict} notFoundContent="Bạn cần chọn Tỉnh/Thành phố">
                                                    {
                                                        listDistrict.map((district)=>(
                                                            <Option key={district.regionID} value={district.regionID}>{district.regionName}</Option>
                                                        ))
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 16]}>
                                        <Col  span={12}>
                                            <Form.Item
                                                label="Phường/Xã"
                                                name="wardID"
                                            >
                                                <Select placeholder="-- Chọn Phường/Xã --" allowClear  onChange={onChangeWard} notFoundContent="Bạn cần chọn Quận/Huyện">
                                                    {
                                                        listWard.map((district)=>(
                                                            <Option key={district.regionID} value={district.regionID}>{district.regionName}</Option>
                                                        ))
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col  span={12}>
                                            <Form.Item
                                                label="Địa chỉ liên hệ"
                                                name="Address"
                                            >
                                                <Input placeholder="Vui lòng ghi đầy đủ số nhà, đường phố, thôn/tổ dân phố" onChange={val => setAddress(val.target.value)}/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item
                                                rules={[
                                                {
                                                    required: true,
                                                    message: "Thông tin không được bỏ trống",
                                                },
                                                ]}
                                                label="Nội dung"
                                                name="Content"
                                            >
                                                <Input.TextArea style={{height: '150px'}} onChange={val => setContent(val.target.value)}/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <ReCAPTCHA
                                        sitekey={process.env.REACT_APP_SITE_KEY_RECAPTCHA}
                                        onChange={value => setResponse(value)}
                                        />
                                    </Row>
                                    <Row style={{marginTop: "20px", paddingBottom: "20px"}}>
                                        <Button className="btn-submit-contact" size="large" style={{ width: '150px' }} type="primary" htmlType="submit" onClick={submitInforContact}>
                                            Đăng ký
                                        </Button>
                                    </Row>
                                </Form>
                            </Spin>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact