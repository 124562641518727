import { Anchor, Avatar, Col, Divider, Form, Input, Row, Select, Space, Table, Tooltip, TreeSelect } from "antd"
import TextArea from "antd/es/input/TextArea"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import CustomModal from "src/components/Modal/CustomModal"
import Button from "src/components/MyButton/Button"
import Notice from "src/components/Notice"
import SpinCustom from "src/components/Spin"
import { STATUS_OPTION } from "src/constants/constants"
import ContactService from "src/services/ContactService"
import UserService from "src/services/UserService"

const AddContent = ({ detailInfo, modeShowPopup, onOk, reloadTable, ...props }) => {
    const [loading, setLoading] = useState(false);
    const [listUser, setListUser] = useState([]);
    const [listAllDataUser, setListAllDataUser] = useState([]); //phục vụ cho việc check là phòng ban hay user để prevent click chọn
    const [selectedValue, setSelectedValue] = useState(null);
    const [form] = Form.useForm();

    //#region useEffect

    useEffect(() => {
        if(modeShowPopup == STATUS_OPTION.DANG_XU_LY) {
            getListUserInDept();
        }
    }, [modeShowPopup]);

    //#endregion
    
    //#region function

    /**
     * @description lấy danh sách nhân viên theo phòng
     * @author hddung
     */
    async function getListUserInDept() {
        const res = await UserService.getListUserInDept();
        if(res?.isError) return

        setListUser(
            //Láy ra các node đầu tiên và truyền vào đệ quy các node k thuộc node đầu tiên 
            res?.object?.filter(x => x.deptLevel == 1).map(x => ({
                value: x.deptID, 
                title: x.deptName, 
                children: getChildrenArr(x.deptID, res?.object.filter(x => x.deptLevel != 1))
            }))
        );

        setListAllDataUser(res?.object);

    }

    /**
     * @description đệ quy lấy ra chidrent của từng node
     * @author hddung
     */
    function getChildrenArr(parentID, data){
        const lstChildren = data.filter(x => x.deptIDParent == parentID);
        
        return data?.length > 0 
        ? 
        //đệ quy tiếp tục với những node k thuộc parentID
        lstChildren.map(x => ({
            value: x.userID || x.deptID, 
            title: x.deptName, 
            isUser: x.userID != null,
            children: getChildrenArr(x.deptID, data.filter(x => x.deptIDParent != parentID))
        })) 
        : []; 
    }

    /**
     * @description Xác nhận xử lý
     * @author hddung
     */
    async function confirmHandleContact() {
        try{
            setLoading(true);
            await form.validateFields().then(async (values) => {
                const res = await ContactService.confirmHandleContact(detailInfo?.id, values?.note);
                if(res?.isError) return
                Notice({
                    isSuccess: true,
                    msg: "Xác nhận thành công" 
                });
        
                onOk && onOk();
                props?.onCancel();
                reloadTable();
            })
        }
        finally{
            setLoading(false);
        }
    }

    /**
     * @description Thêm phụ trách
     * @author hddung
     */
    async function addHandlerContact() {
        try{
            setLoading(true);
            form.setFieldsValue({userId: selectedValue});

            await form.validateFields().then(async (values) => {
                const res = await ContactService.addHandlerContact(detailInfo?.id, values?.userId, values?.note);
                if(res?.isError) return
                Notice({
                    isSuccess: true,
                    msg: "Xác nhận thành công" 
                });
        
                onOk && onOk();
                props?.onCancel();
                reloadTable();
            })
        }
        finally{
            setLoading(false);
        }
    }

    /**
     * @description Hoàn thành
     * @author hddung
     */
    async function confirmDoneContact() {
        try{
            setLoading(true);
            await form.validateFields().then(async (values) => {
                //TODO respone có để chung note đc k
                const res = await ContactService.confirmDoneContact(detailInfo?.id, values?.note);
                if(res?.isError) return
                Notice({
                    isSuccess: true,
                    msg: "Xác nhận thành công" 
                });
        
                onOk && onOk();
                props?.onCancel();
                reloadTable();
            })
        }
        finally{
            setLoading(false);
        }
    }

    /**
     * @description thực hiện check mode để case function tương ứng
     * @author hddung
     */
    function saveModeContent() {
        switch(modeShowPopup) {
            case STATUS_OPTION.CHO_XAC_NHAN:
                confirmHandleContact();
                break;
            case STATUS_OPTION.DANG_XU_LY:
                addHandlerContact();
                break;
            case STATUS_OPTION.HOAN_THANH:
                confirmDoneContact();
                break;
        }
    }

    /**
     * @description thực hiện render cho title
     * @author hddung
     */
    function renderTitle() {
        switch(modeShowPopup) {
            case STATUS_OPTION.CHO_XAC_NHAN:
                return 'Xác nhận xử lý';
            case STATUS_OPTION.DANG_XU_LY:
                return 'Thêm phụ trách';
            case STATUS_OPTION.HOAN_THANH:
                return 'Hoàn thành';
        }
    }

    //#endregion

    const renderFooter = () => (
        <div className="d-flex justify-content-flex-end">
            <Button
                className="btn-hover-shadow"
                onClick={() => props?.onCancel()}
            >
                Đóng
            </Button>
            <Button
                btnType="primary"
                className="btn-hover-shadow"
                onClick={saveModeContent}
            >
                Lưu
            </Button>
        </div>
    )

    return (
        <CustomModal
            title={renderTitle()}
            footer={renderFooter()}
            width={600}
            {...props}
            style={{padding: '20px 20px 0px 10px'}}
        >
        <SpinCustom spinning={loading}>
            <Form
                form={form}
                layout="vertical"
            >
                {modeShowPopup == STATUS_OPTION.DANG_XU_LY && <Row gutter={[16, 16]} className="justify-content-center">
                    <Col span={24}>
                        <Form.Item
                            label="Nhân viên"
                            name="userId"
                            rules={[
                            {
                                required: true,
                                message: "Thông tin không được để trống",
                            },
                            ]}
                            valuePropName="select"
                            initialValue={selectedValue}
                        >
                            {listUser?.length > 0 &&
                                <TreeSelect
                                    showSearch
                                    treeNodeFilterProp="title"
                                    style={{
                                        width: '100%',
                                    }}
                                    value={selectedValue}
                                    dropdownStyle={{
                                        maxHeight: 400,
                                        overflow: 'auto',
                                    }}
                                    placeholder="Chọn nhân viên"
                                    allowClear
                                    treeDefaultExpandAll
                                    onChange={(record) => {
                                        if(listAllDataUser.find(x => x.deptID == record)) {
                                            setSelectedValue(null);
                                            return
                                        }
                                        setSelectedValue(record);
                                    }}
                                    treeData={listUser}
                                />
                            }
                        </Form.Item>
                    </Col>
                </Row>}
                <Row gutter={[16, 16]} className="justify-content-center">
                    <Col span={24}>
                        <Form.Item
                            label={ modeShowPopup == STATUS_OPTION.HOAN_THANH ? "phản hồi cuối cùng của người dùng" : "Note"}
                            name="note"
                            rules={[
                            {
                                required: true,
                                message: "Thông tin không được để trống",
                            },
                            ]}
                        >
                            <Input.TextArea style={{height: '130px'}} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </SpinCustom>
        </CustomModal>
    )
}

export default AddContent