import React, {useEffect, useState} from 'react';
import "./productResult.css"
import Button from "../../../components/MyButton/Button";
import Modal from 'react-bootstrap/Modal';
import SpinCustom from "../../../components/Spin";

export const ProductResultViewer = ({ onCancel, open, contract_html}) => {
    const [contract, setContract] = useState(null);
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        // var replacedHtml = contract_html.replace('\n', '<br />');
        setContract(contract_html);
    }, [contract_html]);

    return (
        <SpinCustom spinning={loading}>
            <div>
                <Modal show={open} onHide={onCancel}  dialogClassName="cus-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>Danh sách sản phẩm</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={"cus-modal-body"}>
                        <div className={"contract-container"}>
                            <div className={"contract-c"}
                                 dangerouslySetInnerHTML={{__html: contract || '<div></div>'}}></div>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={onCancel}>
                            Đóng
                        </Button>
                        {/*<Button btnType="primary" variant="primary" onClick={}>*/}
                        {/*    Ký hợp đồng*/}
                        {/*</Button>*/}
                    </Modal.Footer>
                </Modal>
            </div>
        </SpinCustom>
    );
};


