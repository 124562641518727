import banner1 from 'src/assets/images/banner/banner_1.png'
import banner2 from 'src/assets/images/banner/banner_2.jpg'
import banner3 from 'src/assets/images/banner/banner_3.png'
import emptyCart1 from '../assets/images/background/nocart.png'
import emptyCart2 from '../assets/images/background/cart-empty.png'

export const IMAGES =
    {
        banner: {
            banner1: banner1,
            banner2: banner2,
            banner3: banner3,
        },
        background:{
            emptyCart1:emptyCart1,
            emptyCart2:emptyCart2,
        }
    }
