import { Col, Form, Input, Row } from "antd"
import React from "react"
import Notice from "src/components/Notice"
import { getRegexEmail } from "src/lib/stringsUtils"
import { getRegexPassword } from "src/lib/utils"
import AuthService from "src/services/AuthService"

const StepModal1 = ({ form }) => {
  return (
    <Form form={form} layout="vertical">
      <Row gutter={16}>
        <Col span={24}>
          <div className="step-title">
            <div className="step">BƯỚC 1 : THÔNG TIN ĐĂNG NHẬP</div>
            <div className="note">
              Quý khách sử dụng thông tin tài khoản này để đăng nhập vào trang
              web
            </div>
          </div>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Email"
            name="Email"
            rules={[
              {
                required: true,
                message: "Thông tin không được để trống!",
              },
              {
                pattern: getRegexEmail(),
                message: "Email sai định dạng",
              },
            ]}
          >
            <Input placeholder="Nhập email" />
          </Form.Item>
        </Col>
        {/* <Col span={4}>
        <div
          onClick={() => {
            checkEmail()
          }}
          className="mt-37 pointer"
        >
          Kiểm tra email
        </div>
      </Col> */}
        <Col span={24}>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Bạn chưa nhập mật khẩu!",
              },
              {
                pattern: getRegexPassword(),
                message:
                  "Mật khẩu có chứa ít nhất 8 ký tự, trong đó có ít nhất một số và bao gồm cả chữ thường và chữ hoa và ký tự đặc biệt, ví dụ @, #, ?, !.",
              },
            ]}
            label="Mật khẩu"
            name="Password"
          >
            <Input.Password placeholder="Nhập mật khẩu" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Bạn phải nhập lại mật khẩu một lần nữa",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("Password") === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error("Mật khẩu không khớp!"))
                },
              }),
            ]}
            label="Nhập lại mật khẩu"
            name="Repassword"
          >
            <Input.Password placeholder="Nhập lại mật khẩu" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default StepModal1
