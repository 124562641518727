import ROUTER from "src/router"
import SvgIcon from "../SvgIcon"
const MenuItemBreadcrumb = () => {
  return [
    {
      key: ROUTER.HOME,
      icon: <SvgIcon name="home" />,
    },
  ]
}

export default MenuItemBreadcrumb

export const MenuItemAdmin = () => {
  return [
    {
      label: "Tổng quan",
      key: ROUTER.TONG_QUAN,
      hideOnMenu: true,
      showOnAdmin: true,
      icon: <SvgIcon name="dashboardCRM" />,
      TabID: [1],
    },
    {
      label: "Quản lý đơn hàng",
      key: "subkey2",
      hideOnMenu: true,
      showOnAdmin: true,
      icon: <SvgIcon name="OrderManagementCRM" />,
      TabID: [2],
      children: [
        {
          key: ROUTER.DANH_SACH_DON_HANG_CHO_XAC_NHAN,
          label: "Chờ xác nhận",
          TabID: [2],
        },
        {
          key: ROUTER.DANH_SACH_DON_HANG,
          label: "Danh sách đơn hàng",
          TabID: [2],
        },
      ],
    },
    {
      label: "Yêu cầu hỗ trợ",
      key: ROUTER.QUAN_LY_LIEN_HE,
      hideOnMenu: true,
      showOnAdmin: true,
      icon: <SvgIcon name="supportedCRM" />,
      TabID: [3],
    },
    {
      label: "Quản lý hợp đồng",
      key: "subkey4",
      hideOnMenu: true,
      showOnAdmin: true,
      icon: <SvgIcon name="ContractManagementCRM" />,
      TabID: [4, 5, 20],
      children: [
        {
          key: ROUTER.TRA_CUU_HOP_DONG,
          label: "Tra cứu hợp đồng",
          TabID: [4],
        },
        {
          key: ROUTER.TEMPLATE_HOP_DONG,
          label: "Template hợp đồng",
          TabID: [5],
        }, {
          key: ROUTER.DANH_SACH_CHU_KY,
          label: "Danh sách chữ ký",
          TabID: [20],
        },
        // {
        //   key: ROUTER.THEM_TEMPLATE_HOP_DONG,
        //   label: "Thêm template hợp đồng",
        //   TabID: [1],
        // },
      ],
    },
    {
      label: "Sản phẩm - Dịch vụ",
      key: "subkey5",
      hideOnMenu: true,
      showOnAdmin: true,
      icon: <SvgIcon name="Products-ServicesCRM" />,
      TabID: [7, 8, 9, 10],
      children: [
        {
          key: ROUTER.SAN_PHAM,
          label: "Danh sách sản phẩm",
          TabID: [7],
        },
        {
          key: ROUTER.TON_KHO,
          label: "Danh sách tồn kho",
          TabID: [8],
        },
        // {
        //   key: ROUTER.DICH_VU,
        //   label: "Danh sách dịch vụ",
        //   TabID: [1],
        // },
        // {
        //   key: ROUTER.PHAN_LOAI,
        //   label: "Danh sách thể loại",
        //   TabID: [1],
        // },
        {
          key: ROUTER.GIAM_GIA,
          label: "Mã giảm giá",
          TabID: [9],
        },
        {
          key: ROUTER.MA_GIA,
          label: "Mã giá",
          TabID: [10],
        },
      ],
    },
    {
      label: "Quản lý bài đăng",
      key: "subkey6",
      hideOnMenu: true,
      showOnAdmin: true,
      icon: <SvgIcon name="ManagePostsCRM" />,
      TabID: [11, 12],
      children: [
        {
          key: ROUTER.BAI_VIET,
          label: "Danh mục - bài viết",
          TabID: [11],
        },
        {
          key: ROUTER.DANH_SACH_THE,
          label: "Danh sách thẻ",
          TabID: [12],
        },
      ],
    },
    {
      label: "Quản lý khách hàng",
      key: "subkey7",
      hideOnMenu: true,
      showOnAdmin: true,
      icon: <SvgIcon name="CustomerManagementCRM" />,
      TabID: [24, 13, 25],
      children: [
        {
          key: ROUTER.DOANH_NGHIEP,
          label: "Đăng ký doanh nghiệp",
          TabID: [24],
        },
        {
          key: ROUTER.CA_NHAN,
          label: "Khách hàng cá nhân",
          TabID: [13],
        },
        {
          key: ROUTER. DANH_SACH_DOANH_NGHIEP,
          label: "Khách hàng doanh nghiệp",
          TabID: [25],
        },
        // {
        //   key: ROUTER.CHI_TIET,
        //   label: "Cấu hình Email",
        //   TabID: [1],
        // },
        // {
        //   key: ROUTER.CHI_TIET,
        //   label: "Cấu hình SMS",
        //   TabID: [1],
        // },
      ],
    },
    {
      label: "Quản trị hệ thống",
      key: "subkey8",
      hideOnMenu: true,
      showOnAdmin: true,
      icon: <SvgIcon name="SystemManagementCRM" />,
      TabID: [16, 17, 18, 20, 26],
      children: [
        {
          key: ROUTER.NHAN_VIEN,
          label: "Danh bạ nhân viên",
          TabID: [16],
        },
        
        {
          key: ROUTER.NHAN_VIEN_ORACLE,
          label: "Danh bạ nhân viên ORACLE",
          TabID: [99],
        }

        ,
        {
          key: ROUTER.LICH_SU_HOAT_DONG,
          label: "Lịch sử hoạt động",
          TabID: [17],
        },
        {
          key: ROUTER.PHAN_QUYEN,
          label: "Phân quyền",
          TabID: [18],
        },
        {
          key: ROUTER.QUAN_LY_VI,
          label: "Quản lý ví",
          TabID: [20],
        },
        {
          key: ROUTER.LICH_SU_NAP_TIEN,
          label: "Lịch sử nạp tiền",
          TabID: [26]
        },
      ],
    },
    // {
    //   label: "Thống kê",
    //   key: "subkey9",
    //   hideOnMenu: true,
    //   showOnAdmin: true,
    //   icon: <SvgIcon name="StatisticalCRM" />,
    //   TabID: [1, 7],
    //   children: [
    //     {
    //       key: ROUTER.DASHBOARD,
    //       label: "Dashboard",
    //       TabID: [1],
    //     },
    //     {
    //       key: ROUTER.CHI_TIET,
    //       label: "Chi tiết",
    //       TabID: [1],
    //     },
    //   ],
    // },
    {
      label: "Banner & Footer",
      key: ROUTER.QUAN_LY_BANNER_FOOTER,
      hideOnMenu: true,
      showOnAdmin: true,
      icon: <SvgIcon name="Banner-FooterCRM" />,
      TabID: [21],
    },
  ]
}

export const MenuItemUser = () => {
  return []
}
