import React, {useEffect, useState} from "react";

import http from "../../../../../services";
import {ContractListTable} from "../contract/ContractListTable";
import {TemplateListTable} from "./TemplateListTable";

export const TemplateManagement = () => {

    const [contracts, setContracts] = useState([])
    const [totalPage, setTotalPage] = useState([])
    const [isInsert, setIsInsert] = useState(false)

    const [pagination, setPagination] = useState({
        "pageSize": 10,
        "currentPage": 1,
        "textSearch": "",
        "fromDate": null,
        "toDate": null,
        // "statusID": 0,
        // "isDelete":-1
    })
    const getListContract = () => {
        http.post('/Contract/GetAllContractTemplate', {...pagination})
            .then(res => {
                if (!res?.isError) {
                    setContracts(res.object.listContractGetAllTemplateResponse);
                    setTotalPage(res.object.countList);
                    setIsInsert(res?.object?.isInsert);
                } else {
                    setContracts([]);
                }

            })
    }
    const handleFilter = (model) => {
        setPagination(model)
    }
    const updateContract = (orderId, updatedOrder) => {
        getListContract();
        // setContracts((prevOrder) =>
        //     prevOrder.map((order) =>
        //         (order.orderId === orderId ? updatedOrder : order)
        //     )
        // );
    };

    useEffect(() => {
        getListContract();
        // console.log('pagi',pagination);
    }, [pagination]);

    useEffect(() => {

    }, [contracts]);


    const onChangeTab = (key: string) => {
        setContracts([]);
        const newPagi = {...pagination, status: key, currentPage: 1};
        setPagination(newPagi);
    };

    const onChangeKeyword = (key: string) => {
        setContracts([]);
        const newPagi = {...pagination, textSearch: key, currentPage: 1};
        setPagination(newPagi);
    }


    const handleChangePage = (model) => {
        setPagination(model)
    }
    return (
        <>
            <div className={" page-container"}>
                <div className={"main-nav__content"}>

                </div>

                <div className={"content-contracts"}>
                    <TemplateListTable contracts={contracts} refreshData={updateContract} totalPage={totalPage}
                                       pagination={pagination} getOrder={handleChangePage}
                                       onChangeKeyword={onChangeKeyword}
                                       handleFilter={handleFilter}
                                       isInsert={isInsert}

                    />
                </div>

            </div>
        </>


    )

}
