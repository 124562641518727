import {Anchor, Checkbox, Col, Divider, Row, Select, Switch, Tooltip} from "antd";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import SpinCustom from "../../../../components/Spin";
import {FloatActionWrapper} from "../../../../components/FloatAction/styles";
import {ListUserStyled} from "../../Company/styled";
import TableCustom from "../../../../components/Table/CustomTable";
import 'react-toastify/dist/ReactToastify.css';
import {format, isToday, parseISO} from "date-fns";
import ButtonCircle from "../../../../components/MyButton/ButtonCircle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {DisplayId} from "../../../../lib/utils";
import {GUIDE_EMPTY, RELATTION_STATUS} from "../../../../constants/constants";
import {Search} from "../component/Search";
import Button from "../../../../components/MyButton/Button";
import {CreateCustomerModal} from "../modal/CreateCustomerModal";
import {CustomerEditModal} from "../modal/CustomerEditModal";
import {CustomerDetailModal} from "../modal/CustomerDetailModal";
import {SendMailTemplate} from "../modal/SendMailTemplate";
import CB1 from "../../../../components/Modal/CB1";
import {TabsProps} from "react-bootstrap";
import {toast} from "react-toastify";
import http from "src/services"
import SvgIcon from "../../../../components/SvgIcon";
import Notice from "../../../../components/Notice";
import {ManualCustomerCreate} from "../Create/ManualCustomerCreate";
import {ImportFileCustomerCreate} from "../Create/ImportFileCustomerCreate";
import {AddCustomerCareModal} from "../modal/AddCustomerCareModal";
import {AddNewCustomerResponseModal} from "../modal/AddNewCustomerResponseModal";
import {OrdersHistoryTab} from "../component/OrdersHistoryTab";
import {useSelector} from "react-redux";

export const CustomerListTable = ({
                                      customers,
                                      refreshData,
                                      totalPage,
                                      pagination,
                                      changePage,
                                      onChangeKeyword,
                                      handleFilter,
                                      refreshStatus,
                                      roles
                                  }) => {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [isShowCreateModal, setIsShowCreateModal] = useState(false);
    const [isShowImportModal, setIsShowImportModal] = useState(false);
    const [isShowEditModal, setIsShowEditModal] = useState(false);
    const [isShowViewModal, setIsShowViewModal] = useState();
    const [isShowSendMail, setIsShowSendMail] = useState();
    const [customerSelected, setCustomerSelected] = useState();
    const [chooseAll, setChooseAll] = useState(false);
    const [openContentPopup, setOpenContentPopup] = useState(false);
    const [isShowAddResponse, setIsShowAddResponse] = useState(false);
    const [isShowOrders, setIsOrders] = useState(false);
    const {userInfo} = useSelector(state => state?.appGlobal)

    const relationOption: TabsProps['sortitems'] = [
        // {
        //     key: 0,
        //     label: 'Tất cả',
        // },
        {
            key: RELATTION_STATUS.KHACH_HANG_MOI,
            label: 'Khách hàng mới',
        },
        {
            key: RELATTION_STATUS.KHACH_HANG_TIEP_CAN,
            label: 'Khách hàng đã tiếp cận sản phẩm',
        },
        {
            key: RELATTION_STATUS.KHACH_HANG_TIEM_NANG,
            label: 'Khách hàng tiềm năng',
        },
        {
            key: RELATTION_STATUS.KHACH_HANG_THAN_THIET,
            label: 'khách hàng thân thiết',
        },
    ];
    const statusOption: TabsProps['sortitems'] = [

        {
            key: 1,
            label: 'Đang hoạt động',
        },
        {
            key: 0,
            label: 'Ngưng hoạt động',
        }
    ];
    const [dataBodySource, setDataBodySource] = useState({
        pageSize: 10,
        currentPage: 1,
    });

    const handleChangePage = (page, pageSize) => {
        const model = {...pagination, pageSize: pageSize || 10, currentPage: page || 1}
        changePage(model);
    }
    const RenderEditButton = (customer) => {
        if (customer?.isUpdate) {
            return (
                <ButtonCircle
                    title="Chỉnh sửa thông tin khách hàng"
                    iconName="edit"
                    style={{background: "rgba(255,255,255,0.8)"}}
                    onClick={() => {
                        setCustomerSelected(customer);
                        setIsShowEditModal(true);
                    }}
                />
            )
        }

    };
    const RenderDeleteButton = (customer) => {
        if (customer?.isDelete) {
            return (
                <ButtonCircle
                    title="Xoá khách hàng"
                    iconName="delete-black"
                    style={{background: "rgba(255,255,255,0.8)"}}
                    onClick={() => {
                        CB1({
                            title: `Bạn có chắc chắn muốn xóa khách hàng này không?`,
                            icon: "trashRed",
                            okText: "Đồng ý",
                            onOk: async close => {

                                http.post(`ManageCustomer/DeleteCustomer?UserID=${customer?.userID}`).then(resp => {
                                    if (!resp?.isError) {
                                        refreshData();
                                        Notice({msg: `Xoá khách hang thành công !`});
                                    }
                                }).finally(() => {
                                    close();
                                })
                            },
                        })
                    }}
                />
            )
        }

    };

    const renderDate = (dateString) => {
        if (dateString) {
            const dateObject = parseISO(dateString);
            const formattedDate = format(dateObject, 'dd-MM-yyyy');
            return formattedDate;
        }
    }
    const RenderAddStaffButton = (customer) => {
        if (customer?.isAssignStaff) {
            return (
                <ButtonCircle
                    title="Thêm phụ trách"
                    iconName="add-user-manager-contact"
                    onClick={() => {
                        setCustomerSelected(customer);
                        setOpenContentPopup(true)
                    }}
                />
            )
        }

    };
    const RenderAddnewResponse = (customer) => {
        if (customer?.isUpdate) {
            return (
                <ButtonCircle
                    title="Thêm hoạt động"
                    iconName="add-blue"
                    onClick={() => {
                        setCustomerSelected(customer);
                        setIsShowAddResponse(true)
                    }}
                />
            )
        }

    };
    const renderButton = (customer) => {
        // console.log("customer" ,customer)
        return (<>
            <div className={"d-flex"}>
                {RenderEditButton(customer)}
                {RenderSendMailButton(customer)}
                {RenderAddStaffButton(customer)}
                {RenderAddnewResponse(customer)}
                {RenderOrderList(customer)}
                {RenderDeleteButton(customer)}
            </div>
        </>)
    }
    const RenderOrderList = (customer) => {
        if (customer?.isViewOrdersList) {
            return (
                <ButtonCircle
                    title="Xem danh sách đơn hàng"
                    iconName="menu-book"
                    style={{background: "rgba(255,255,255,0.8)"}}
                    onClick={() => {
                        setCustomerSelected(customer);
                        setIsOrders(true);
                    }}
                />
            )
        }

    };
    const RenderSendMailButton = (customer) => {
        if (customer?.isSendMail) {
            return (
                <ButtonCircle
                    title="Gửi email"
                    iconName="send-mail"
                    style={{background: "#fff"}}
                    onClick={() => {
                        setCustomerSelected(customer);
                        handleSendMailSingle(customer)
                    }}
                />
            )
        }
    }
    const renderSendMailMultilple=()=>{
        return (
            <ButtonCircle
                title="Gửi email"
                iconName="send-mail"
                style={{background: "#fff"}}
                onClick={() => {
                    setIsShowSendMail(true);
                }}
            />
        )
    }


    const renderRelationShip = (value) => {
        switch (value) {
            case RELATTION_STATUS.KHACH_HANG_MOI:
                return (<div>khách hàng mới</div>);
            case RELATTION_STATUS.KHACH_HANG_TIEP_CAN:
                return (<div>khách hàng đã tiếp cận sản phẩm</div>);
            case RELATTION_STATUS.KHACH_HANG_TIEM_NANG:
                return (<div>khách hàng tiềm năng</div>);
            case RELATTION_STATUS.KHACH_HANG_THAN_THIET:
                return (<div>khách hàng thân thiết</div>);
            default:
                return (<div>khách hàng mới</div>);
        }
    }

    const [userSelected, setUserSelected] = useState([]);


    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    useEffect(() => {

        const selectedUsers = selectedRowKeys.map(userID => {
            const user = customers.find(customer => customer.userID === userID&&customer?.isSendMail);
            return {userID, email: user ? user.email : 'N/A'};
        });
        setUserSelected(selectedUsers);
    }, [selectedRowKeys])
    const handleRowSelection = (record) => {
        const selectedItem = [...selectedRowKeys];
        const index = selectedItem.indexOf(record.userID);

        if (index === -1) {
            selectedItem.push(record.userID);
        } else {
            selectedItem.splice(index, 1);
        }
        setSelectedRowKeys(selectedItem);
    };
    const handleSelectAll = () => {
        setChooseAll(!chooseAll)
    }
    useEffect(() => {
        if (chooseAll) {
            setSelectedRowKeys(customers.map(item => item.userID));
        } else {
            setSelectedRowKeys([])
        }

    }, [chooseAll])
    const renderSelectAllCheckbox = () => {
        return (
            <>
                <div>

                    <div>
                        <Checkbox
                            indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < customers.length}
                            checked={selectedRowKeys.length === customers.length}
                            onChange={handleSelectAll}
                        />
                        ({selectedRowKeys?.length || 0})
                    </div>
                    {/*<div className={"d-lfex"}>*/}
                    {/*    <p>Tất cả</p>*/}
                    {/*    <Checkbox*/}
                    {/*        indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < customers.length}*/}
                    {/*        checked={selectedRowKeys.length === customers.length}*/}
                    {/*        onChange={handleSelectAll}*/}
                    {/*    />*/}
                    {/*</div>*/}

                </div>
            </>
        );
    }
    const handleSendMailSingle = (customer) => {
        const id = customer?.userID;
        const array = [];
        array.push(id);
        setSelectedRowKeys(array);
        setIsShowSendMail(true);

        // CB1({
        //     title: `Bạn có chắc chắn muốn gửi mail đến tài khoản này không?`,
        //     icon: "warning-usb",
        //     okText: "Đồng ý",
        //     onOk: async close => {
        //         const id = customer?.userID;
        //         const array = [];
        //         array.push(id);
        //         setSelectedRowKeys(array);
        //         setIsShowSendMail(true);
        //         close()
        //     },
        // })
    }
    const handleChangeRelation = (customer, status) => {
        CB1({
            title: `Bạn có chắc chắn muốn chuyển mối quan hệ khách hàng không?`,
            icon: "warning-usb",
            okText: "Đồng ý",
            onOk: async close => {
                http.post(`ManageCustomer/ChangeCustomerRelationship?UserID=${customer.userID}&RelationshipStatus=${status}`).then(resp => {
                    if (!resp.isError) {
                        Notice({msg: "Thay đổi mối quan hệ thành công !"})
                        const updateData = {...customer, relationshipStatus: status}
                        refreshStatus(updateData);
                    }
                }).finally(() => {
                    close()
                })
            },
        })
    }
    const handleChangeStatus = (customer, status) => {
        const s = status ? 1 : 2;
        CB1({
            title: `Bạn có chắc chắn muốn chuyển trạng thái khách hàng thành ${status ? 'hoạt động' : 'không hoạt động'} không?`,
            icon: "warning-usb",
            okText: "Đồng ý",
            onOk: async close => {
                http.post(`ManageCustomer/ChangeUserStatus?UserID=${customer.userID}&Status=${s}`).then(resp => {
                    if (!resp.isError) {
                        Notice({msg: "Thay đổi trạng thái thành công !"})
                        const updateData = {...customer, sttatus: s}
                        refreshStatus(updateData);
                    }
                }).finally(() => {
                    close()
                })
            },
        })
    }
    const columns = [
        {
            // title: `Chọn (${selectedRowKeys?.length || 0})`,
            title: () => renderSelectAllCheckbox(),
            dataIndex: "select",
            key: "select",
            width: 60,
            align: "center",
            render: (val, record) => (
                <div className="text-center" onClick={(event) => {
                    event.stopPropagation();
                }}
                >
                    <input
                        type="checkbox"
                        checked={selectedRowKeys?.includes(record.userID)}
                        onChange={
                            (event) => {
                                handleRowSelection(record)
                            }}

                    />
                </div>
            ),
        },
        // {
        //     title: "Mã khách hàng",
        //     dataIndex: "userID",
        //     key: "userID",
        //     width: 120,
        //     align: "left",
        //     render: (value, customer) => (
        //         <div>
        //             {DisplayId(customer?.userID)}
        //         </div>
        //     )
        // },
        ...(userInfo?.accountID===GUIDE_EMPTY?[{
            title: "Tên đăng nhập",
            dataIndex: "username",
            key: "username",
            render: (value, customer) =>
                (
                    <div>
                        <div className={"max-line1 col-11"}>
                            {value}
                        </div>
                        {/*<FloatActionWrapper size="small" className="float-action__wrapper" style={{background: "#fff"}}>*/}
                        {/*    {renderViewButton(customer)}*/}
                        {/*</FloatActionWrapper>*/}
                    </div>
                ),
            width: 120,
            align: "left",
        }]:[]),
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            render: (value, customer) =>
                (
                    <div>
                        <div className={"max-line1 col-11"}>
                            <Tooltip title={value} className={"d-flex"}>
                                <div>
                                    <FontAwesomeIcon icon={faEnvelope}
                                                     style={{color: "#2b3340", fontSize: "18px", marginRight: 4}}/>
                                    {/*<FontAwesomeIcon icon={faFilePdf}  style={{color: "#1f2123",fontSize:"18px",marginRight:4}} />*/}
                                </div>
                                <p className="multiline-cell">
                                    {value}
                                </p>
                            </Tooltip>
                        </div>

                    </div>
                ),
            width: 200,
            align: "left",
        },
        {
            title: "Tên khách hàng",
            dataIndex: "fullName",
            key: "fullName",
            render: (value, customer) =>
                (
                    <div>
                        {value}
                    </div>
                ),
            width: 160,
            align: "left",
        },


        {
            title: "Số điện thoại",
            dataIndex: "phoneNumber",
            key: "phoneNumber",
            render: (value, customer) => (
                <div>
                    {value}
                </div>
            ),
            width: 130,
            align: "left",
        },
        {
            title: "Quan hệ khách hàng",
            dataIndex: "relationshipStatus",
            key: "relationshipStatus",
            align: "left",
            width: 300,
            onCell: record => {
                return {
                    onClick: event => {
                        event.stopPropagation(); // this will avoid onRow being called
                        // go to placeUrl(routes.platformDetails, {})
                    }
                }
            },

            render: (value, record) => (
                <div className="">
                    {/*<div>{renderRelationShip(value)}</div>*/}
                    <Select
                        value={{value}}
                        onChange={(e) => {
                            handleChangeRelation(record, e);
                        }}
                        disabled={!record?.isChangeCustomerRelationship}
                    >
                        {relationOption.map((option) => (
                            <option key={option.key} value={option.key}>
                                {option.label}
                            </option>
                        ))}
                    </Select>

                </div>
            ),

        },
        {
            title: "Trạng thái",
            dataIndex: "status",
            key: "status",
            align: "left",
            width: 120,
            onCell: record => {
                return {
                    onClick: event => {
                        event.stopPropagation(); // this will avoid onRow being called
                        // go to placeUrl(routes.platformDetails, {})
                    }
                }
            },

            render: (value, record) => (
                <>
                    <div className="">

                        <Switch disabled={!record?.isChangeStatus} checked={value == 1}
                                onChange={(e) => {
                                    handleChangeStatus(record, e);
                                }}
                        />
                    </div>
                </>
            ),

        }, {
            title: "Người phụ trách",
            dataIndex: "assignUserName",
            key: "assignUserName",
            onCell: record => {
                return {
                    onClick: event => {
                        event.stopPropagation(); // this will avoid onRow being called
                        // go to placeUrl(routes.platformDetails, {})
                    }
                }
            },
            render: (value, customer) => (
                <>
                    <div>
                        {value}
                    </div>
                    <FloatActionWrapper size="small" className="float-action__wrapper" style={{background: "#fff"}}>
                        {
                            selectedRowKeys.length <= 1?renderButton(customer):renderSendMailMultilple()
                        }
                        {/*{renderButton(customer)}*/}
                    </FloatActionWrapper>
                </>
            ),
            width: 180,
            align: "left",
        },


    ]
    const handleRowClick = (record, dataIndex) => {
        setCustomerSelected(record);
        setIsShowViewModal(true);
    };
    return (
        <>
            <ListUserStyled>
                <div>
                    <Search

                        onChangeKeyword={onChangeKeyword}
                        pagination={pagination}
                        handleFilter={handleFilter}
                    />
                    <Divider className="mv-16"/>
                    <div
                        className="title-type-1 d-flex justify-content-space-between align-items-center pb-16 pt-0 mb-16">
                        <div className="fs-24">
                            Danh sách khách hàng cá nhân
                            {/* ({dataSource?.total || 0}) : */}
                        </div>
                        <Row guter={[16, 16]}>
                            {roles.isSendMultiMails && selectedRowKeys.length > 0 && (
                                <Col style={{marginRight: 10}}>
                                    <Button
                                        btnType="danger"
                                        className="btn-hover-shadow"
                                        onClick={() => {
                                            setIsShowSendMail(true);
                                        }}

                                    >
                                        Gửi Mail
                                    </Button>

                                </Col>
                            )}
                            {
                                roles.isInsert &&
                                (
                                    <Col style={{marginRight: 10}}>
                                        <Button
                                            className="btn-hover-shadow"
                                            btnType="third"
                                            onClick={() => {
                                                setIsShowImportModal(true);
                                            }}
                                        >
                                            Tải lên khách hàng
                                        </Button>

                                    </Col>
                                )}
                            {
                                roles.isImport &&
                                (
                                    <Col>
                                        <Button
                                            btnType="primary"
                                            className="btn-hover-shadow"
                                            onClick={() => {
                                                setIsShowCreateModal(true);
                                            }}
                                        >
                                            Thêm khách hàng
                                        </Button>

                                    </Col>
                                )}

                        </Row>
                    </div>
                </div>
                <Anchor
                    affix={false}
                    getContainer={() => document.getElementById("body-admin-scroll")}
                >
                    <Row gutter={[16, 16]}>
                        <Col style={{width: 0}} flex="auto">
                            <SpinCustom spinning={loading}>
                                <TableCustom
                                    isPrimary
                                    onRow={(record, rowIndex, colIndex) => ({
                                        onClick: (event) => {


                                            handleRowClick(record, "");
                                        },
                                    })}
                                    // rowSelection={rowSelection}
                                    className="mb-6"
                                    dataSource={customers}
                                    columns={columns}

                                    textEmpty="Không có hợp đồng"
                                    rowKey="userID"

                                    sticky={{offsetHeader: -12}}
                                    scroll={{y: "100%", x: "800px"}}
                                    pagination={{
                                        defaultPage: dataBodySource.pageSize,
                                        showSizeChanger: true,
                                        pageSizeOptions: ['10', '20', '30'],
                                        total: totalPage,
                                        // onChange: (page, pageSize) => {
                                        //     setDataBodySource((prevState) => ({
                                        //         ...prevState,
                                        //         pageSize: pageSize || 10,
                                        //         currentPage: page || 1
                                        //     }));
                                        // },
                                        onChange: (page, pageSize) => {
                                            handleChangePage(page, pageSize);
                                        }
                                    }}
                                />
                            </SpinCustom>
                        </Col>
                    </Row>
                </Anchor>

            </ListUserStyled>

            {
                isShowCreateModal && (
                    <ManualCustomerCreate
                        cancel={() => setIsShowCreateModal(false)}
                        open={isShowCreateModal}
                        refreshData={refreshData}
                    />
                )
            }
            {
                isShowImportModal && (
                    <ImportFileCustomerCreate
                        cancel={() => setIsShowImportModal(false)}
                        open={isShowImportModal}
                        refreshData={refreshData}
                    />
                )
            }
            {
                isShowEditModal && (
                    <CustomerEditModal
                        cancel={() => setIsShowEditModal(false)}
                        open={isShowEditModal}
                        refreshData={refreshData}
                        userId={customerSelected?.userID}
                    />
                )
            }
            {
                isShowViewModal && (
                    <CustomerDetailModal
                        cancel={() => setIsShowViewModal(false)}
                        open={isShowViewModal}
                        refreshData={refreshData}
                        userId={customerSelected?.userID}
                        customerSelected={customerSelected}
                    />
                )
            }
            {
                isShowSendMail && (
                    <SendMailTemplate
                        cancel={() => setIsShowSendMail(false)}
                        open={isShowSendMail}
                        refreshData={refreshData}
                        ids={userSelected}
                    />
                )
            }
            {openContentPopup &&
                <AddCustomerCareModal cancel={() => setOpenContentPopup(false)}
                                      open={openContentPopup}
                                      refreshData={() => refreshData()}
                                      customerId={customerSelected.userID}
                />
            }
            {isShowAddResponse &&
                <AddNewCustomerResponseModal cancel={() => setIsShowAddResponse(false)}
                                             open={isShowAddResponse}
                                             refreshData={() => refreshData()} userInfo={customerSelected}/>
            }
            {

                isShowOrders && (
                    <OrdersHistoryTab userID={customerSelected?.userID} isShow={isShowOrders} setIsShow={setIsOrders}/>
                )
            }
        </>

    )
}
