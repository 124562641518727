import { UserOutlined } from "@ant-design/icons"
import { Anchor, Avatar, Col, Divider, Row, Space, Table, Tooltip } from "antd"
import { useEffect, useState } from "react"
import { FloatActionWrapper } from "src/components/FloatAction/styles"
import CB1 from "src/components/Modal/CB1"
import Button from "src/components/MyButton/Button"
import ButtonCircle from "src/components/MyButton/ButtonCircle"
import Notice from "src/components/Notice"
import SpinCustom from "src/components/Spin"
import TableCustom from "src/components/Table/CustomTable"
import Search from "./components/Search"
import { ListUserStyled, TdTableStyled } from "./styled"
import ProductionService from "src/services/ProductionService"
import { SATUS_INVENTORY, STATUS_OPTION } from "src/constants/constants"
import InsertUpateInventory from "./modal/InsertUpate"
import ImportInventory from "./modal/ImportInventory"
import { useLocation } from "react-router-dom"
import DetailInventory from "./modal/DetailInventory"
import { formatDate } from "src/lib/time"

const ListInventory = () => {
  const [loading, setLoading] = useState(false)

  //hddung
  const [dataBody, setDataBody] = useState({});
  const [listDataSource, setListDataSource] = useState([]);
  const [totalDataSource, setTotalDataSource] = useState(0);

  const [openInventoryPopup, setOpenInventoryPopup] = useState(false);
  const [openImportPopup, setOpenImportPopup] = useState(false);
  const [openDetailInventory, setOpenDetailInventory] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [isImportExcel, setIsImportExcel] = useState(false);

  const {state} = useLocation();
  const [detailInfo, setDetailInfo] = useState(null);
  //hddung
  const columns = [
    {
      title: "STT",
      key: "index",
      width: 60,
      align: "center",
      render: (text, row, idx) => (
        <div className="text-center">
          {idx +
            1 +
            dataBody.pageSize * (dataBody.currentPage - 1)}
        </div>
      ),
    },
    {
      title: "Mã tồn kho",
      dataIndex: "inventoryID",
      key: "inventoryID",
      width: 150,
      align: "left",
    },
    {
      title: "Tên sản phẩm",
      dataIndex: "productName",
      key: "productName",
      width: 220,
      align: "left",
      render: value => <strong>{value}</strong>,
    },
    {
      title: "Tên phân loại",
      dataIndex: "productItemName",
      key: "productItemName",
      width: 200,
      align: "left",
    },
    {
      title: "Nội dung",
      dataIndex: "content",
      key: "content",
      width: 240,
      align: "left",
      maxHeight: 100,
      render: value => <TdTableStyled><Tooltip placement="leftTop" title={value}>{value}</Tooltip></TdTableStyled>,
    },
    {
      title: "Ngày tạo",
      dataIndex: "createDate",
      key: "createDate",
      width: 100,
      align: "center",
      render: value => formatDate(value),
    },
    {
      title: "Trạng thái",
      dataIndex: "isSold",
      key: "isSold",
      width: 100,
      render: (type, record) => (
        <div className="d-flex justify-content-center align-items-center mh-36">
          {renderTextStatus(type)}
          {type != STATUS_OPTION.HOAN_THANH && (
            <FloatActionWrapper size="small" className="float-action__wrapper" style={{ background: "#fff"}}>
              {renderListButton(record)}
            </FloatActionWrapper>
            )
          }
        </div>
      ),
    },
  ]


  const renderListButton = record => {
    return (
      <>
        {(record?.isUpdate || record?.isDelete) &&
          <Space>
            {record?.isUpdate &&
            <ButtonCircle
              title="Cập nhật"
              iconName="edit"
              onClick={() => {
                setDetailInfo(record);
                setOpenInventoryPopup(true);
              }}
            />}
            {record?.isDelete &&
            <ButtonCircle
              title="Xóa"
              iconName="bin"
              onClick={() => {
                CB1({
                  title: `Bạn có chắc chắn muốn xoá sản phẩm
                  <strong> ${record?.productName}</strong> không?`,
                  icon: "warning-usb",
                  okText: "Đồng ý",
                  onOk: async close => {
                    onDeleteInventory(record?.inventoryID)
                    close()
                  },
                })
              }}
            />}
          </Space>
        }
      </>
    )
  }
  
  //#region useEffect

  useEffect(() => {
    if(Object.keys(dataBody).length > 0) {
      getAllInventory();
    }
  }, [dataBody])

  useEffect(() => {
    if(state?.isOverView) {
      setDataBody({
        pageSize: 10,
        currentPage: 1,
        textSearch: "",
        objectID: "",
        status: 2,
        fromDate: null,
        toDate: null
      });
    } else {
      setDataBody({
        pageSize: 10,
        currentPage: 1,
        textSearch: "",
        objectID: "",
        status: 0,
        fromDate: null,
        toDate: null
      });
    }
  },[state])

  //#endregion

  //#region function

  /**
   * @description thực hiện return trạng thái tương ứng status
   * @author hddung 
   */
  function renderTextStatus(type) {
    var str = 'Đã bán',
        color = 'red';

    if(!type) {
      str = 'Chưa bán';
      color = '#0960a0';
    }

    return (
      <div className="text-center text-ellipsis fw-600" style={{color: color}}>
        {str}
      </div>
    )

  }

  /**
   * @description get list danh sách contact
   * @author hddung 
   */
  async function getAllInventory() {
    try {
      setLoading(true);
      const res = await ProductionService.getAllInventory(dataBody);
      if(res?.isError) return
      var data = res?.object;
      
      if(!data) return
  
      setListDataSource(data?.listInventoryGetAll);
      setTotalDataSource(data?.countList);
      setIsCreate(data?.isCreate);
      setIsImportExcel(data?.isImportExcel);
    } finally {
      setLoading(false);
    }
    
  }

  /**
   * @description thực hiện xóa sản phẩm 
   * @author hddung
   */
  async function onDeleteInventory(inventoryID) {
    setLoading(true);
    try {
      const res = await ProductionService.deleteInventory([inventoryID]);
      if(res?.isError) return
      getAllInventory();
    

    } catch (error) {
    }
    finally {
      setLoading(false);
    }
  }

  //#endregion

  return (
    <ListUserStyled>
      <div>
        <Search
          setDataBody={setDataBody}
          dataBody={dataBody}
        />
        <Divider className="mv-16" />
        <div className="title-type-1 d-flex justify-content-space-between align-items-center pb-16 pt-0 mb-16">
          <div className="fs-24">
            Quản lý tồn kho
          </div>
          <Row guter={[16, 16]}>
            { isCreate && (
              <Col style={{marginRight: '5px'}}>
                <Button
                  onClick={() => {
                    setOpenImportPopup(true);
                  }}
                  className="btn-hover-shadow"
                  btnType="third"
                >
                  Nhập Excel
                </Button>
              </Col>
            )}
            { isImportExcel && (
              <Col>
                <Button
                  btnType="primary"
                  className="btn-hover-shadow"
                  onClick={() => {
                    setOpenInventoryPopup(true);
                  }}
                  >
                  Thêm tồn kho
                </Button>
             </Col>
            )}
          </Row>
        </div>
      </div>
      <Anchor
        affix={false}
        getContainer={() => document.getElementById("body-admin-scroll")}
      >
        <Row gutter={[16, 16]}>
          <Col style={{ width: 0 }} flex="auto">
            <SpinCustom spinning={loading}>
              <TableCustom
                isPrimary
                onRow={record => {
                  return {
                    onClick: () => {
                      setDetailInfo(record);
                      // setOpenContactHandlers(true);
                      setOpenDetailInventory(true);
                    },
                  }
                }}
                className="mb-6"
                dataSource={listDataSource || []}
                columns={columns}
                textEmpty="Không có bản ghi nào!"
                rowKey="id"
                sticky={{ offsetHeader: -12 }}
                scroll={{ y: "100%", x: "800px" }}
                pagination={{ 
                  defaultPage: dataBody.pageSize, 
                  showSizeChanger: true, 
                  pageSizeOptions: ['10', '20', '30'], 
                  total: totalDataSource,
                  onChange: (page, pageSize) => {
                    setDataBody((prevState) => ({
                      ...prevState,
                      pageSize: pageSize || 10,
                      currentPage: page || 1
                    }));
                  },
                }}
              />
            </SpinCustom>
          </Col>
        </Row>
      </Anchor>

      { !!openInventoryPopup &&
        <InsertUpateInventory 
          detailInfo={detailInfo} 
          onCancel={() => {
            setDetailInfo(undefined);
            setOpenInventoryPopup(false);
          }}
          reloadTable={getAllInventory}
          onOk={() => {}}
          open={openInventoryPopup}
        />
      }

      { !!openImportPopup &&
        <ImportInventory 
          detailInfo={detailInfo} 
          onCancel={() => {
            setDetailInfo(undefined);
            setOpenImportPopup(false);
          }}
          reloadTable={getAllInventory}
          onOk={() => {}}
          open={openImportPopup}
        />
      }

      { !!openDetailInventory &&
        <DetailInventory 
          detailInfo={detailInfo} 
          onCancel={() => {
            setOpenDetailInventory(false);
          }}
          onOk={() => {}}
          open={openDetailInventory}
        />
      }

    </ListUserStyled>
  )
}

export default ListInventory