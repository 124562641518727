import React, {useEffect} from 'react';
import {Carousel} from "antd";
import  "./slider.css"
import {IMAGES} from "../../../constants/imageDir";
import {useNavigate} from "react-router-dom";
import ROUTER from "../../../router";
// const images = [
//     'https://cdn.divineshop.vn/image/catalog/Anh/Banner%2014%20thang%2011/Banner%20main-1280x632-27477.png?hash=1689069871',
//     'https://cdn.divineshop.vn/image/catalog/Anh-SP/Kh%C3%A1c/chatGPT-70878.jpg?hash=1681283148',
//     'https://cdn.divineshop.vn/image/catalog/Banner/banner%20Google%20One%20(3)-10567.png?hash=1658937523',
// ];
const images = Object.values(IMAGES.banner);

export const Slideshow = ({banners}) => {
    useEffect(() =>{

    })
    const navigate=useNavigate();
    return (
        <Carousel className={"slide-container-detail"}
                 autoplay={true}
                  infiniteLoop={true}
                  showStatus={false}
                  arrows={true}
                  showThumbs={false}
                  interval={5000}
                  autoplaySpeed={5000}
        >
            {banners.map(i => (
                <div key={i.id} className={"slide-container-detail slide-detail"} onClick={()=>{
                    navigate(`/${i.content}`)
                }}>
                    <img src={i?.title}  />
                </div>
            ))}
        </Carousel>
    );
}
