import { Anchor, Avatar, Col, Descriptions, Divider, Form, Input, List, Row, Select, Space, Table, Tooltip, Typography } from "antd"
import TextArea from "antd/es/input/TextArea"
import { useEffect } from "react"
import { useState } from "react"
import { useSelector } from "react-redux"
import CustomModal from "src/components/Modal/CustomModal"
import Button from "src/components/MyButton/Button"
import Notice from "src/components/Notice"
import SpinCustom from "src/components/Spin"
import { STATUS_OPTION } from "src/constants/constants"
import ContactService from "src/services/ContactService"

const ContactHandlers = ({ detailInfo, onOk, ...props }) => {
    const labelStyle = {fontWeight: 'bold', color: 'black'};

    //#region function

    /**
     * @description format date
     * @author HDDUNG
     */
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            time = d.getHours() + ':' + (d.getMinutes() < 9 ? '0' : '') + d.getMinutes();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('-') + ` ${time}`;
    };

    //#endregion

    const renderFooter = () => (
        <div className="d-flex justify-content-flex-end">
            <Button
                className="btn-hover-shadow"
                onClick={() => props?.onCancel()}
            >
                Đóng
            </Button>
        </div>
    )

    return (
        <CustomModal
            title={'Thông tin xử lý'}
            footer={renderFooter()}
            width={1000}
            {...props}
            style={{padding: '20px 20px 0px 10px'}}
        >
            <div style={{ maxHeight: '450px', overflowY: 'auto', overflowX: 'hidden' }}>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Descriptions title="Thông tin chi tiết:">
                        <Descriptions.Item label="Tiêu đề" labelStyle={labelStyle}>{detailInfo?.title}</Descriptions.Item>
                        <Descriptions.Item label="Email" labelStyle={labelStyle}>{detailInfo?.email}</Descriptions.Item>
                        <Descriptions.Item label="Họ và tên" labelStyle={labelStyle}>{detailInfo?.name}</Descriptions.Item>
                        <Descriptions.Item label="SĐT" labelStyle={labelStyle}>{detailInfo?.phone}</Descriptions.Item>
                        <Descriptions.Item label="Địa chỉ liên hệ" labelStyle={labelStyle} span={2}>{detailInfo?.address}</Descriptions.Item>
                        <Descriptions.Item label="Người phụ trách" labelStyle={labelStyle} span={3}>{detailInfo?.assignUserName}</Descriptions.Item>
                        <Descriptions.Item label="Nội dung hỗ trợ" labelStyle={labelStyle} span={3}>{detailInfo?.content}</Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Row gutter={[16, 16]} style={{marginTop: '30px', marginBottom: '15px'}}>
                <Col span={24} >
                    <Typography.Text style={{fontSize: '15px'}} strong>Lịch sử tương tác:</Typography.Text>
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="justify-content-center">
                <Col span={24}>
                    <List
                        itemLayout="horizontal"
                        dataSource={detailInfo?.contactHandlers}
                        renderItem={(item, index) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={<Avatar src={item?.avatar} size={50}/>}
                                title={
                                        <div style={{display: 'flex'}}>
                                            <h5 style={{marginRight: '20px'}}>{item?.userName}</h5>
                                            <p style={{lineHeight: '30px', color: 'rgba(0, 0, 0, 0.45)'}}>{formatDate(item?.createDate)}</p>
                                        </div>
                                }
                                description={item?.note}
                            />
                        </List.Item>
                        )}
                    />
                </Col>
            </Row>
            </div>
        </CustomModal>
    )
}

export default ContactHandlers