// User
export const apiInsertUser = "User/Insert"
export const apiDeleteUser = "User/Delete"
export const apiDetailUser = "User/GetDetail"
export const apiUpdateUser = "User/Update"
export const apiGetListUser = "User/GetList"
export const apiImportUser = "User/ImportUser"
export const apiExportUser = "User/ExportUser"
export const apiGetTemplateFileImportUser = "User/GetTemplateFileImportUser"
export const apiGetListGuest = "User/GetListGuest"
export const apiGetAccount = "User/GetAccount"
export const apiExportGuest = "User/ExportGuest"
export const apiImportGuest = "User/ImportGuest"
export const apiGetTemplateFileImportGuest = "User/GetTemplateFileImportGuest"
export const apiUpdateAccount = "User/UpdateAccount"
export const apiReplacePassword = "User/ChangePassword"
export const apiGetInforUser = "User/GetUserByID"
export const apiUploadAvatar = "User/UploadAvatar"
export const apiChangeInfor = "User/UpdateUser"
export const apiChangeProfile = "User/UpdateProfile"
export const apiChangeImgUser = "User/ChangeImgUser"
export const apiInsertRecruiter = "User/InsertRecruiter"
export const apiGetListRecruterByUserID = "User/GetListRecruterByUserID"

export const apiGetAllAccountUser = "User/GetAllAccountUser"
export const apiInsertAccountUser = "User/InsertAccountUser"
export const apiUpdateAccountUser = "User/UpdateAccountUser"
export const apiDeletaAccountUser = "User/DeleteAccountUser"

// Candidate
export const apiGetHomeJobApplication = "User/GetHomeJobApplication"
export const apiInsertAvatarCandidate = "User/InsertAvtar"
export const apiDeleteAvatarCandidate = "User/DeleteAvtar"
export const apiGetCandiateProfile = "User/GetCandidateProfile"
export const apiUpdateTitleProfile = "User/UpdateTitle"
export const apiUpdateCareerGoals = "User/UpdateCareerGoals"
export const apiGetComboBoxNation = "User/GetComboBoxNation"
export const apiGetComboBoxNationality = "User/GetComboBoxNationality"
export const apiupdateInfoCandidate = "User/UpdateInfor"
export const apiUpdateJobInformation = "User/UpdateJobInformation"
export const apiNationOrther = "User/GetComboBoxNationOrther"
export const apiEditWorkExperience = "User/EditWorkExperience"
export const apiInsertWorkExperience = "User/InsertWorkExperience"
export const apiUpdatetWorkExperience = "User/UpdatetWorkExperience"
export const apiDeleteWorkExp = "User/DeleteWorkexp"
export const apiUpdateCurrentDegreeUser = "User/UpdateCurrentDegreeUser"
export const apiInsertEducationUser = "User/InsertEducationUser"
export const apiDeleteEducationUser = "User/DeleteEducationUser"
export const apiUpdatetEducationUser = "User/UpdatetEducationUser"
export const apiInsertCertificate = "User/InsertCertificate"
export const apiUpdateCertificate = "User/UpdateCertificate"
export const apiDeleteCertificate = "User/DeleteCertificate"
export const apiInsertLanguage = "User/InserLanguage"
export const apiUpdateLanguage = "User/UpdateLanguage"
export const apiDeleteLanguage = "User/DeleteLanguage"
export const apiInsertAdvanced = "User/InsertAdvanced"
export const apiUpdateAdvanced = "User/UpdateAdvanced"
export const apiDeleteAdvanced = "User/DeleteAdvanced"
export const apiInserOrUpdateOutstandingAchievement =
  "User/InserOrUpdateOutstandingAchievement"
export const apiEXportCV = "User/PrintCV"
export const apiViewTemplate = "User/ViewTemplate"

//Admin
//Ứng viên tiềm năng
export const apiListOfPotentialPersonnel = "Account/ListOfPotentialPersonnel"

//Chi tiết công ty
export const apiDetailCompany = "Account/AccountDetails"

//Lấy danh sách người dùng theo phòng ban
export const apiGetListUserInDept = "User/GetListUserInDept"
