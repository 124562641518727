import React from 'react'
import { Category } from './style'
import { Col, Divider, Row } from 'antd'
export default function index({Data}) {
    console.log(Data)
  return (
    <Category>
        <Row className='header'>Thể loại</Row>
        <Divider style={{margin:"5px"}}/>
        {Data?.length > 0 && Data.map(x => 
            <Row className='item' key={x.categoryID}>
                <Col className='item-name'span={18}>{x.categoryName}</Col>
                <Col className='item-number' span={2}>{x.quantityProduct}</Col>
            </Row>
        )}
    </Category>
  )
}
