import { Col, Form, Input, Row, Spin } from "antd"
import { useState } from "react"
import { StyleRegisterModal } from "src/components/Layouts/styled"
import CustomModal from "src/components/Modal/CustomModal"
import Button from "src/components/MyButton/Button"
import Notice from "src/components/Notice"
import {
  getRegexEmail,
  getRegexMobile,
  getRegexPassword,
} from "src/lib/stringsUtils"
import AuthService from "src/services/AuthService"
import ModalVerify from "./ModalVerifyEmail"

const RegisterModal = ({
  openRegisterModal,
  handleOk,
  handleCancel,
  handleLogin,
}) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [openModalVerify, setOpenModalVerify] = useState(false)

  const email = Form.useWatch("Email", form)
  const username = Form.useWatch("Username", form)
  const password = Form.useWatch("Password", form)
  const handleSubmit = async () => {
    setLoading(true);
    form
      .validateFields()
      .then( async () => {
        await AuthService.emailRegister({ 
          username,
          password,
          email }).then(res => {
          if (res?.isError) return
          
          Notice({
            isSuccess: true,
            msg: "Đã gửi mã thành công, vui lòng kiểm tra email",
          });

          setOpenModalVerify(email);
        })
      })
      .finally(() => {setLoading(false)});
  }

  return (
    <CustomModal
      style={{}}
      title="Đăng ký thành viên"
      width="600px"
      footer={null}
      open={openRegisterModal}
      onOk={handleOk}
      onCancel={handleCancel}
    >
        <StyleRegisterModal>
          <Spin spinning={loading}>
            <Form layout="vertical" form={form}>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    required
                    rules={[
                      {
                        required: true,
                        message: "Bạn chưa nhập tên tài khoản!",
                      },
                    ]}
                    label="Tên tài khoản"
                    name="Username"
                  >
                    <Input placeholder="Nhập tên đăng nhập " />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Email"
                    name="Email"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Email không được để trống!",
                      },
                      {
                        pattern: getRegexEmail(),
                        message: "Email sai định dạng",
                      },
                    ]}
                  >
                    <Input placeholder="Nhập email" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    required
                    rules={[
                      {
                        required: true,
                        message: "Bạn chưa nhập mật khẩu!",
                      },
                      {
                        pattern: getRegexPassword(),
                        message:
                          "Mật khẩu có chứa ít nhất 8 ký tự, trong đó có ít nhất một số và bao gồm cả chữ thường và chữ hoa và ký tự đặc biệt, ví dụ @, #, ?, !.",
                      },
                    ]}
                    label="Mật khẩu"
                    name="Password"
                  >
                    <Input.Password placeholder="Nhập mật khẩu" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    required
                    rules={[
                      {
                        required: true,
                        message: "Bạn phải nhập lại mật khẩu một lần nữa",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("Password") === value) {
                            return Promise.resolve()
                          } else
                            return Promise.reject(new Error("Mật khẩu không khớp!"))
                        },
                      }),
                    ]}
                    label="Nhập lại mật khẩu"
                    name="RePassword"
                  >
                    <Input.Password placeholder="Nhập lại mật khẩu" />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="mt-12">
                <Button
                  btnType="primary"
                  className="btn-register"
                  type="submit"
                  htmlType="submit"
                  onClick={handleSubmit}
                >
                  Đăng ký
                </Button>
              </Row>
              <Row className="register">
                <span>
                  Bạn đã có tài khoản?
                  <span
                    className="link-login ml-4 mr-4"
                    onClick={() => {
                      handleCancel()
                      handleLogin()
                    }}
                  >
                    Đăng nhập
                  </span>
                  ngay
                </span>
              </Row>
            </Form>
          </Spin>
        </StyleRegisterModal>
      {!!openModalVerify && (
        <ModalVerify
        onCancel={() => setOpenModalVerify(false)}
        onOk={() => {}}
        open={openModalVerify}
        formData={form}
        handleCancel={() => {
          handleCancel();
          handleLogin()
        }}
        Role="register"
        dataRegister={
          {
            email: email, 
            username: username, 
            password: password
          }
        }
        />
        )}
    </CustomModal>
  )
}

export default RegisterModal
