import {Col, Form, Row} from "antd";
import FlInput from "../../../components/FloatingLabel/Input";
import React, {useEffect, useState} from "react";
import SpinCustom from "../../../components/Spin";
import Button from "../../../components/MyButton/Button";
import axios from "axios";

export const VoucherForm = ({isShow, productItemID,quantity,handleHide,handleChangePromoteItem}) => {
    const [form] = Form.useForm();
    const [error, setError] = useState(null);
    // useEffect(() => {
    //     console.log("productItemID : "+productItemID);
    //     console.log("isshow : "+isShow);
    //     console.log("----------");
    // },[])

    const handleSubmit = () => {
        form.validateFields().then(values => {
            setLoading(true);

            const model = {
                "productItemID": productItemID,
                "promoteCode": values.code,
                "quantity":quantity
            }
            CallApiGetCode(model).then(value => {
                if(value.data.statusCode==200){
                    setLoading(false);
                    handleHide();
                    setError(null);
                   handleChangePromoteItem(value.data.object.productItemID,value.data.object.accuratePrice,values.code,value.data.object.type)
                }else{
                    setError(value.data.object?value.data.object:value.data.Object);
                }
            }).catch(reason => {
            }).finally(() => {
                setLoading(false);
            })

        })
    }
    useEffect(() => {
        setError(null);
    },[isShow])
    const CallApiGetCode=async (model) => {
        return await axios.post("/Product/CheckPromoteCode", model);
    }
    const [loading, setLoading] = useState(false)
    return (
        <SpinCustom spinning={loading}>
            <Form form={form} style={{marginBottom: 0,minHeight:"34px",height:"fit-content"}}>
                <Row style={{marginBottom: 0,minHeight:"34px",height:"fit-content"}}>
                    <Col span={18} style={{marginBottom: 0,marginRight:10,minHeight:"30px",height:"fit-content"}}>
                        <Form.Item
                            name="code"
                            rules={[
                                {
                                    required: true,
                                    message: "Bạn chưa nhập mã voucher",
                                },
                            ]}
                            help={error}
                            style={{ color: 'red',minHeight: '34px',height:"fit-content" }}
                        >
                            <FlInput label="" isRequired style={{marginBottom: 0,minHeight:"30px",height:"fit-content"}}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Button btnType="primary" className={"btn-snm"} onClick={() => handleSubmit()}>
                            Xác nhận
                        </Button>
                    </Col>

                </Row>
            </Form>
        </SpinCustom>
    )
}
