import { Col, DatePicker, Row, Select, message } from "antd"
import { useSelector } from "react-redux"
import FlInput from "src/components/FloatingLabel/Input"
import FlSelect from "src/components/FloatingLabel/Select"
import { SYSTEM_KEY } from "src/constants/constants"
import { getListComboByKey } from "src/lib/utils"
import { SearchStyled } from "../styles"
import dayjs from "dayjs";
import Notice from "src/components/Notice"
import CommonService from "src/services/CommonService"
import { useState } from "react"
import { useEffect } from "react"

const { Option } = Select
const Search = ({ setDataBody, dataBody }) => {
  const { listSystemKey } = useSelector(state => state.appGlobal)
  const [listFunction, setListFunction] = useState([]);

  useEffect(() => {
    GetListComboboxSearch();
  }, [])

  /**
   * @description Thực hiện lấy danh sách hành động
   * @author hddung 
   */
  async function GetListComboboxSearch() {
    const res = await CommonService.getListComboboxSearch();
    if(res?.isError) return
    setListFunction(res?.object?.map(x => ({
      value:  x?.value,
      label: x?.functionName,
    })));
  }

  return (
    <SearchStyled>
      <Row gutter={[16, 16]}>
        <Col flex="auto">
          <FlInput
            onSearch={e => {
              setDataBody((prevState) => ({
                ...prevState,
                textSearch: e || '',
                currentPage: 1
              }));
            }}
            search
            allowClear
            label="Nhập tên tài khoản, số điện thoại của người dùng, lọc theo hành động"
          />
        </Col>
        <Col lg={4} xs={24}>
          <FlInput
            onSearch={e => {
              setDataBody((prevState) => ({
                ...prevState,
                userName: e || '',
                currentPage: 1
              }));
            }}
            search
            allowClear
            label="Nhập tên người dùng"
          />
        </Col>
        <Col lg={5} xs={24}>
          <FlSelect
            // showSearch
            optionFilterProp="label"
            label="Hành động"
            options={listFunction}
            onChange={funcName => {
              setDataBody((prevState) => ({
                ...prevState,
                functionName: funcName || "",
                currentPage: 1
              }));
            }}
            value={dataBody?.functionName || ""}
          >
            {/* {listFunction?.map(
              i => (
                <Option key={i.value} value={i.value}>
                  {i?.functionName}
                </Option>
              ),
            )} */}
          </FlSelect>
        </Col>
        <Col lg={3} xs={24}>
          <DatePicker
              onChange={(e) => {
                  const formattedDate = dayjs(e).format('YYYY-MM-DD');
                  if(new Date(formattedDate) <= new Date(dataBody?.toDate) || !dataBody?.toDate || !e) {
                    setDataBody((prevState) => ({
                      ...prevState,
                      fromDate: formattedDate == 'Invalid Date' ? null : formattedDate,
                      currentPage: 1
                    }));
                  } else {
                    message.error('Từ ngày nhỏ hơn đến ngày');
                  }
              }}
              value={dataBody?.fromDate ? dayjs(dataBody?.fromDate) : null}
              placeholder="Từ ngày"
              format="DD/MM/YYYY"
              allowClear
          />
      </Col>

      <Col lg={3} xs={24}>
          <DatePicker
              onChange={(e) => {
                  const formattedDate = dayjs(e).format('YYYY-MM-DD');
                  if(new Date(formattedDate) >= new Date(dataBody?.fromDate) || !dataBody?.fromDate || !e) {
                    setDataBody((prevState) => ({
                      ...prevState,
                      toDate: formattedDate == 'Invalid Date' ? null : formattedDate,
                      currentPage: 1
                    }));
                  } else {
                    message.error('Đến ngày lớn hơn từ ngày');
                  }
              }}
              value={dataBody?.toDate ? dayjs(dataBody?.toDate) : null}
              placeholder="Đến ngày"
              format="DD/MM/YYYY"
              allowClear
          />
      </Col>
      </Row>
    </SearchStyled>
  )
}

export default Search
