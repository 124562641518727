import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Form,
  Image,
  Input,
  Menu,
  Row,
  Tag,
  TreeSelect,
  Upload,
} from "antd"
import moment from "moment"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Notice from "src/components/Notice"
import SpinCustom from "src/components/Spin"
import SvgIcon from "src/components/SvgIcon"
import TinyEditor from "src/components/TinyEditor"
import { GUIDE_EMPTY } from "src/constants/constants"
import { normFile } from "src/lib/utils"
import ROUTER from "src/router"
import FileService from "src/services/FileService"
import PostService from "src/services/PostService"
import TagsService from "src/services/TagsService"

import { ButtonUploadStyle, CreatePostStyled } from "./styled"
import CustomModal from "src/components/Modal/CustomModal"

const CreatePost = ({onOk, PostID, reloadTable, ...props}) => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const [listTag, setListTag] = useState([])
  const [tag, setTag] = useState("")
  const [loading, setLoading] = useState(true)
  const [image, setimage] = useState()
  const [allTags, setAllTags] = useState([])
  const [form] = Form.useForm()
  const [treeData, setTreeData] = useState([])
  const [fileDelete, setFileDelete] = useState([])
  const [guidPost, setGuidPost] = useState()

  useEffect(() => {
    getListCategory()
  }, [])
  useEffect(() => {
    if (PostID) getDetailPost()
  }, [PostID])

  const getDetailPost = async () => {
    try {
      setLoading(true)
      const res = await PostService.getPost({ PostID })
      if (res?.isError) return
      form.setFieldsValue({
        ...res?.object,
        publicationTime:
          res?.object?.publicationTime && moment(res?.object?.publicationTime),
        file: res?.object?.listFile?.map(i => ({
          ...i,
          url: i?.fileUrl,
          name: i?.fileName,
        })),
      })
      if (res?.object?.image)
        setimage({
          objectFileID: res?.object?.logoFileID,
          fileUrl: res?.object?.image,
        })
      setListTag(res?.object?.listTags)
    } finally {
    }
  }

  const nest = (items, id, link) =>
    items
      ?.filter(item => item[link] === id)
      .map(item => ({
        ...item,
        title: item.categoryPostName,
        value: item.categoryPostID,
        children: nest(items, item?.categoryPostID, link),
      }))

  const getListCategory = async () => {
    try {
      setLoading(true)
      const resTags = await TagsService.getAllTags()
      if (!resTags?.isError) setAllTags(resTags?.object)
      const res = await PostService.getListCategoryPost()
      if (res?.isError) return
      nest(res?.object?.data, GUIDE_EMPTY, "parentID")
      setTreeData(res?.object?.data)
    } finally {
      setLoading(false)
    }
  }

  const onSubmit = async () => {
    try {
      setLoading(true)

      const values = await form.validateFields()
      let PostType = 1
      const category = treeData?.find(
        i => i?.categoryPostID === values?.categoryPostID,
      )
      if (category?.categoryCode?.includes("MEDIA")) PostType = 2
      if (category?.categoryCode?.includes("TAI_LIEU")) PostType = 3
      if (category?.categoryCode?.includes("DOI_TAC")) PostType = 4

      const res = await PostService[PostID ? "updatePost" : "insertPost"]({
        ...values,
        PublicationTime:
          values?.PublicationTime && moment(values?.PublicationTime)?.format(),
        logoFileID: image?.objectFileID,
        ListTagsInsert: listTag
          ?.filter(i => !i?.tagsID)
          ?.map(tag => ({ tagsName: tag?.tagsName })),
        ListtagsID: listTag?.filter(i => !!i?.tagsID)?.map(tag => tag?.tagsID),
        PostID: PostID || guidPost,
        PostType,
        file: undefined,
      })
      if (res?.isError) return
      let resUpload
      setGuidPost(res?.object)
      const fileNew = values?.file?.filter(i => !i?.objectFileID)
      if (fileNew?.length || fileDelete?.length) {
        const formData = new FormData()
        formData.append("GuidID", res?.object || PostID || guidPost)
        if (fileNew?.length)
          fileNew?.map(img =>
            formData.append("InsertFileList", img?.originFileObj),
          )
        if (fileDelete?.length)
          fileDelete?.map(i => formData.append("DeleteFileList", i))
        resUpload = await FileService.uploadFileList(formData)
      }
      if (resUpload?.isError) return
      Notice({ msg: `${PostID ? "Cập nhật" : "Thêm"} bài viết thành công !` })
      // navigate(ROUTER.DANH_SACH_BAI_VIET, { state: { selectedNode } })
      props?.onCancel();
      reloadTable();
    } finally {
      setLoading(false)
    }
  }
  const renderTag = () => {
    return (
      <Row gutter={[10, 10]} className="align-items-center">
        {listTag?.map((i, idx) => (
          <Col key={`create_post_tag${idx}`}>
            <Tag
              closable
              closeIcon={
                <SvgIcon
                  onClick={() =>
                    setListTag(prev =>
                      prev?.filter((_, index) => index !== idx),
                    )
                  }
                  name="close-tag"
                />
              }
            >
              {i?.tagsName}
            </Tag>
          </Col>
        ))}
        <Col flex="auto">
          <Input
            placeholder="Thêm thẻ"
            value={tag}
            className="input-tag-create-post"
            onChange={e => setTag(e?.target?.value)}
            onPressEnter={e => {
              if (!e?.target?.value) return
              setListTag(pre => [...pre, { tagsName: e?.target?.value }])
              setTag("")
            }}
          />
        </Col>
      </Row>
    )
  }

  const uploadFile = async file => {
    try {
      setLoading(true)
      const formData = new FormData()
      formData.append("InsertFileList", file)
      const res = await FileService.uploadFileList(formData)
      if (res?.isError) return
      setimage(res?.object[0])
    } finally {
      setLoading(false)
    }
  }
  const uploadProps = {
    accept: "image/*",
    multiple: false,
    fileList: [],
    beforeUpload: file => {
      uploadFile(file)
      return false
    },
  }

  const menu = (
    <Menu
      items={allTags?.map(i => ({
        key: i?.tagsID,
        label: i?.tagsName,
        disabled: listTag?.find(j => i?.tagsID === j?.tagsID),
        onClick: () => setListTag(pre => [...pre, i]),
      }))}
    />
  )

  const TITLE_NOT_REQUIRE = ["DOI_TAC"]
  const IMAGE_REQUIRE = ["DOI_TAC", "NANG_LUC_VA_THANH_TUU"]
  const CONTENT_NOT_REQUIRED = [
    "DOI_TAC",
    "NANG_LUC_VA_THANH_TUU",
    "MEDIA",
    "TAI_LIEU",
  ]

  const renderFooter = () => (
    <div className="d-flex justify-content-flex-end">
        <Button
            className="btn-hover-shadow"
            onClick={() => props?.onCancel()}
        >
            Đóng
        </Button>
        <Button
            btnType="primary"
            className="btn-hover-shadow"
            onClick={onSubmit}
        >
            Lưu
        </Button>
    </div>
  )

  return (
    <CreatePostStyled>
      <CustomModal
        title={!!PostID ? "Cập nhật sản phẩm" : "Thêm sản phẩm"}
        footer={renderFooter()}
        width={1600}
        {...props}
      >
        <SpinCustom spinning={loading}>
          <div className="mt-16">
            <Form
              layout="vertical"
              form={form}
              initialValues={{ IsComment: true }}
            >
              <Form.Item
                label="Danh mục"
                name="categoryPostID"
                required
                rules={[
                  {
                    required: true,
                    message: "Thông tin không được để trống",
                  },
                ]}
              >
                <TreeSelect
                  disabled={state?.type === "TUYEN_DUNG"}
                  placeholder="Chọn danh mục"
                  style={{ width: "100%" }}
                  treeDefaultExpandAll={true}
                  treeData={nest(treeData, GUIDE_EMPTY, "parentID")}
                />
              </Form.Item>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  const category = treeData?.find(
                    i => i?.categoryPostID === getFieldValue("categoryPostID"),
                  )
                  if (category?.categoryCode === "MEDIA")
                    return (
                      <Form.Item
                        label="Link Media"
                        name="youtubeLink"
                        required
                        rules={[
                          {
                            required: true,
                            message: "Thông tin không được để trống",
                          },
                        ]}
                      >
                        <Input placeholder="Thêm link media" />
                      </Form.Item>
                    )
                  return null
                }}
              </Form.Item>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  const category = treeData?.find(
                    i => i?.categoryPostID === getFieldValue("categoryPostID"),
                  )
                  if (category?.categoryCode?.includes("TAI_LIEU"))
                    return (
                      <Form.Item
                        valuePropName="fileList"
                        name="file"
                        label="Thêm file tài liệu"
                        getValueFromEvent={normFile}
                        // required
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Thông tin không được để trống",
                        //   },
                        // ]}
                      >
                        <Upload.Dragger
                          multiple={true}
                          beforeUpload={() => false}
                          className="pointer"
                          onRemove={e => {
                            if (e?.objectFileID)
                              setFileDelete(pre => [...pre, e?.objectFileID])
                          }}
                        >
                          <Row gutter={16} className="justify-content-center">
                            <Col>
                              <SvgIcon name="cloud" />
                            </Col>
                            <Col>
                              <span>
                                Kéo thả file đính kèm hoặc{" "}
                                <span style={{ color: "#154398" }}>
                                  Chọn File
                                </span>
                              </span>
                            </Col>
                          </Row>
                        </Upload.Dragger>
                      </Form.Item>
                    )
                  return null
                }}
              </Form.Item>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  const category = treeData?.find(
                    i => i?.categoryPostID === getFieldValue("categoryPostID"),
                  )
                  return (
                    <Form.Item
                      label="Tiêu đề"
                      name="title"
                      required={
                        !TITLE_NOT_REQUIRE?.includes(category?.categoryCode)
                      }
                      rules={[
                        {
                          required: !TITLE_NOT_REQUIRE?.includes(
                            category?.categoryCode,
                          ),
                          message: "Thông tin không được để trống",
                        },
                      ]}
                    >
                      <Input placeholder="Nhập tiêu đề" />
                    </Form.Item>
                  )
                }}
              </Form.Item>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  const category = treeData?.find(
                    i => i?.categoryPostID === getFieldValue("categoryPostID"),
                  )
                  const required = !!IMAGE_REQUIRE?.includes(
                    category?.categoryCode,
                  )
                  return (
                    <Form.Item
                      label={
                        <div>
                          <span>
                            {!!required && (
                              <span style={{ color: "#ff4d4f" }}>* </span>
                            )}
                            Hình thu nhỏ
                          </span>
                          <br />
                          <span className="sub-title">
                            Dung lượng file tối đa 1 MB, định dạng:.JPEG, .PNG
                          </span>
                        </div>
                      }
                      name="logoFileID"
                      required={false}
                      rules={[
                        {
                          required,
                          message: "Thông tin không được để trống",
                        },
                      ]}
                    >
                      <Row gutter={20} className="mt-16">
                        <Col>
                          <Upload {...uploadProps}>
                            <ButtonUploadStyle>
                              <Button className="account-button-upload ">
                                <Row className="account-background-upload d-flex align-items-center">
                                  <SvgIcon name="add-media-video" />
                                  <div className="account-text-upload ml-16">
                                    Chọn ảnh
                                  </div>
                                </Row>
                              </Button>
                            </ButtonUploadStyle>
                          </Upload>
                        </Col>
                        {!!image && (
                          <Col>
                            <Image
                              src={image?.fileUrl}
                              alt="image"
                              loading="lazy"
                              width="200px"
                            />
                          </Col>
                        )}
                      </Row>
                    </Form.Item>
                  )
                }}
              </Form.Item>

              <Form.Item label="Tóm tắt" name="summary">
                <Input.TextArea
                  placeholder="Nhập nội dung"
                  style={{ height: 120, overflow: "hidden auto" }}
                />
              </Form.Item>

              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  const category = treeData?.find(
                    i => i?.categoryPostID === getFieldValue("categoryPostID"),
                  )
                  const required = !CONTENT_NOT_REQUIRED?.includes(
                    category?.categoryCode,
                  )
                  return (
                    <Form.Item
                      label="Nội dung bài viết"
                      required={required}
                      name="content"
                      trigger="onEditorChange"
                      validateTrigger={["onEditorChange"]}
                      rules={[
                        {
                          required,
                          message: "Thông tin không được để trống",
                        },
                      ]}
                    >
                      <TinyEditor setLoading={setLoading} />
                    </Form.Item>
                  )
                }}
              </Form.Item>
              <Dropdown overlay={menu} trigger={["click"]}>
                <Form.Item label="Tags phổ biến" className="create-post-add-tag">
                  <Input prefix={renderTag()} disabled />
                </Form.Item>
              </Dropdown>
              {/* Ẩn tạm check box cho phép bình luận
              <Form.Item name="isComment" valuePropName="checked" >
                <Checkbox>Cho phép bình luận</Checkbox>
              </Form.Item> */}

              
              {/* <Button
                disabled={loading}
                className="form-contact-submit"
                onClick={onSubmit}
              >
                Đăng bài
              </Button> */}
            </Form>
          </div>
        </SpinCustom>
        </CustomModal>
    </CreatePostStyled>
  )
}

export default CreatePost
