import React, {useEffect, useRef, useState} from "react";
import {Input, Tabs} from "antd";
import Search from "../../Company/components/Search";
import Button from "../../../../components/MyButton/Button";
import OrdersService from "../../../../services/OrdersService";
import {ORDER_STATUS} from "../../../../constants/constants";
import axios from "axios";
import {OrderListTable} from "./OrderListTable";
import http from "../../../../services";
import Router from "../../../../router";

export const OrderManagementConfirm=()=>{

    const [orders, setOrders] = useState([])
    const [totalPage, setTotalPage] = useState([])

    const [statusSlected, setStatusselected] = useState(0);


    const [pagination, setPagination] = useState({
        pageSize: 10,
        currentPage: 1,
        status: ORDER_STATUS.CHO_XAC_NHAN,
        keyword: "",
        fromDate:null,
        toDate:null,
        sortByPrice:0
    })
    const getListOrders = () => {
        http.post('/Order/ManagerOrderList',{...pagination})
            .then(res => {
                if (!res?.isError) {
                    setOrders(res.object)
                    setTotalPage(res.object.count);

                }else{
                    setOrders([]);
                }

            })
    }
    const handleFilter =(model)=>{
        setPagination(model)
    }
    const updateOrder = (orderId, updatedOrder) => {
        setOrders((prevOrder) =>
            prevOrder.map((order) =>
                (order.orderId === orderId ? updatedOrder : order)
            )
        );
    };

    useEffect(() => {
        getListOrders();
        // console.log('pagi',pagination);
    }, [pagination]);

    useEffect(() => {

    }, [orders]);



    const onChangeTab = (key: string) => {
        setOrders([]);
        const newPagi = {...pagination, status: key, currentPage: 1};
        setPagination(newPagi);
    };
    const refresh=()=>{
        getListOrders();
    }
    const onChangeKeyword = (key: string) => {


        setOrders([]);
        const newPagi = {...pagination, keyword: key, currentPage: 1};
        setPagination(newPagi);
    }

    const items: TabsProps['items'] = [
        {
            key: 0,
            label: 'Tất cả',
        },
        {
            key: ORDER_STATUS.CHO_XAC_NHAN,
            label: 'Chờ xác nhận',
        },
        {
            key: ORDER_STATUS.CHO_KY,
            label: 'Chờ ký'
        },
        {
            key: ORDER_STATUS.HOAN_THANH,
            label: 'Hoàn thành',
        },
        {
            key: ORDER_STATUS.DA_HUY,
            label: 'Đã Huỷ',
        },
        {
            key: ORDER_STATUS.DA_DANH_GIA,
            label: 'Đã đánh giá',
        }
    ];
    const handleChangePage=(model) => {
        setPagination(model)
    }
    return (
        <>
            <div className={"page-container"}>
                <div className={"main-nav__content"}>
                    {/*<div className={"tablayout-container"}>*/}
                    {/*    <Tabs defaultActiveKey={statusSlected} items={items} onChange={onChangeTab}/>*/}
                    {/*</div>*/}
                    {/*<div>*/}
                    {/*    <Search*/}
                    {/*        className={"search-bar__container"}*/}
                    {/*        // style={{marginBottom: 8}}*/}
                    {/*        placeholder="Search"*/}
                    {/*        onSearch={onChangeKeyword}*/}
                    {/*    />*/}
                    {/*</div>*/}
                </div>

                <div className={"content-orders"}>
                    <OrderListTable order={orders}  refreshData={updateOrder} totalPage={totalPage}
                                    pagination={pagination} getOrder={handleChangePage}
                                    onChangeKeyword={onChangeKeyword}
                                    handleFilter={handleFilter}
                                    refresh={refresh}
                    />
                </div>

            </div>
        </>


    )

}
