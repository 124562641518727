import CustomModal from "../../../../components/Modal/CustomModal";
import SpinCustom from "../../../../components/Spin";
import {Col, Form, Input, Row, TreeSelect} from "antd";
import {STATUS_OPTION} from "../../../../constants/constants";
import Button from "../../../../components/MyButton/Button";
import {useEffect, useState} from "react";
import UserService from "../../../../services/UserService";
import http from "src/services"
import Notice from "../../../../components/Notice";
export const AddCustomerCareModal =({cancel,refreshData,open,customerId})=>{
    const [listUser, setListUser] = useState([]);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [listAllDataUser, setListAllDataUser] = useState([]); //phục vụ cho việc check là phòng ban hay user để prevent click chọn
    const [selectedValue, setSelectedValue] = useState(null);

    const renderFooter = () => (
        <div className="d-flex justify-content-flex-end">
            <Button
                className="btn-hover-shadow"
                onClick={() => cancel()}
            >
                Đóng
            </Button>
            <Button
                btnType="primary"
                className="btn-hover-shadow"
                onClick={handleSubmit}
            >
                Lưu
            </Button>
        </div>
    )
    const handleSubmit=()=>{
        form.validateFields().then(resp => {
            setLoading(true)
            http.post(`ManageCustomer/AssignStaff?CustomerID=${customerId}&StaffID=${resp.userId}`).then(resp=>{
                if(!resp?.isError){
                    Notice({ msg: `Thêm phụ trách thành công !` });
                    refreshData();
                    cancel();
                }
            }).finally(() => {
                setLoading(false);
            })
        })
    }
    function getChildrenArr(parentID, data){
        const lstChildren = data.filter(x => x.deptIDParent == parentID);

        return data?.length > 0
            ?
            //đệ quy tiếp tục với những node k thuộc parentID
            lstChildren.map(x => ({
                value: x.userID || x.deptID,
                title: x.deptName,
                isUser: x.userID != null,
                children: getChildrenArr(x.deptID, data.filter(x => x.deptIDParent != parentID))
            }))
            : [];
    }

    async function getListUserInDept() {
        const res = await UserService.getListUserInDept();
        if(res?.isError) return

        setListUser(
            //Láy ra các node đầu tiên và truyền vào đệ quy các node k thuộc node đầu tiên
            res?.object?.filter(x => x.deptLevel == 1).map(x => ({
                value: x.deptID,
                title: x.deptName,
                children: getChildrenArr(x.deptID, res?.object.filter(x => x.deptLevel != 1))
            }))
        );

        setListAllDataUser(res?.object);

    }
    useEffect(() => {
            getListUserInDept();
    },[] );
    return (
        <>
            <CustomModal
                title={"Thêm hỗ trợ"}
                footer={renderFooter()}
                width={600}
                open={open}
                style={{padding: '20px 20px 0px 10px'}}
            >
                <SpinCustom spinning={loading}>
                    <Form
                        form={form}
                        layout="vertical"
                    >
                        <Row gutter={[16, 16]} className="justify-content-center">
                            <Col span={24}>
                                <Form.Item
                                    label="Nhân viên"
                                    name="userId"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Thông tin không được để trống",
                                        },
                                    ]}
                                    valuePropName="select"
                                    initialValue={selectedValue}
                                >
                                    {listUser?.length > 0 &&
                                        <TreeSelect
                                            showSearch
                                            treeNodeFilterProp="title"
                                            style={{
                                                width: '100%',
                                            }}
                                            value={selectedValue}
                                            dropdownStyle={{
                                                maxHeight: 400,
                                                overflow: 'auto',
                                            }}
                                            placeholder="Chọn nhân viên"
                                            allowClear
                                            treeDefaultExpandAll
                                            onChange={(record) => {
                                                if(listAllDataUser.find(x => x.deptID == record)) {
                                                    setSelectedValue(null);
                                                    return
                                                }
                                                setSelectedValue(record);
                                            }}
                                            treeData={listUser}
                                        />
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                        {/*<Row gutter={[16, 16]} className="justify-content-center">*/}
                        {/*    <Col span={24}>*/}
                        {/*        <Form.Item*/}
                        {/*            label={ "Ghi chú"}*/}
                        {/*            name="note"*/}
                        {/*            rules={[*/}
                        {/*                {*/}
                        {/*                    required: true,*/}
                        {/*                    message: "Thông tin không được để trống",*/}
                        {/*                },*/}
                        {/*            ]}*/}
                        {/*        >*/}
                        {/*            <Input.TextArea style={{height: '130px'}} />*/}
                        {/*        </Form.Item>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                    </Form>
                </SpinCustom>
            </CustomModal>
        </>
    )
}
