import {Col, DatePicker, Row, Select} from "antd"
import {useSelector} from "react-redux"
import FlInput from "src/components/FloatingLabel/Input"
import FlSelect from "src/components/FloatingLabel/Select"
import {ORDER_STATUS} from "src/constants/constants"
import {SearchStyled} from "../../Production/styled";
import dayjs from "dayjs";

export const SearchContract = ({onChangeKeyword,pagination,handleFilter}) => {
    const { Option } = Select
    const items: TabsProps['items'] = [
        {
            key: -1,
            label: 'Tất cả',
        },
        {
            key: 0,
            label: 'Kích hoạt',
        },
        {
            key:1,
            label: 'Không kích hoạt',
        }
    ];
    const sortItems: TabsProps['sortitems'] = [

        {
            key: 0,
            label: 'Sắp xếp',
        },
        {
            key: 1,
            label: 'Giá tăng dần',
        },
        {
            key: -1,
            label: 'Giá giảm dần',
        }
    ];

    return (
        <SearchStyled>
            <Row gutter={[16, 16]}>
                <Col flex="auto">
                    <FlInput
                        search
                        allowClear
                        label="Tìm kiếm theo Mã hợp đồng, tên hợp đồng, tên file"
                        onSearch={e => onChangeKeyword(e)}
                    />
                </Col>
                {/*<Col lg={5} xs={24}>*/}
                {/*    <FlSelect*/}
                {/*        label="Trạng thái"*/}
                {/*        onChange={(e) => {*/}
                {/*            handleFilter({...pagination,isDelete:e})*/}
                {/*        }}*/}
                {/*        value={pagination.isDelete}*/}
                {/*    >*/}
                {/*        {*/}
                {/*            items?.map(i=>(*/}
                {/*                <Option key={i.key} value={i.key}>*/}
                {/*                    {i?.label}*/}
                {/*                </Option>*/}
                {/*            ))*/}
                {/*        }*/}
                {/*    </FlSelect>*/}
                {/*</Col>*/}
                {/*<Col lg={5} xs={24}>*/}
                {/*    <FlSelect*/}
                {/*        label="Trạng thái"*/}
                {/*        onChange={(e) => {*/}
                {/*            handleFilter({...pagination,status:e})*/}
                {/*        }}*/}
                {/*        value={pagination.status}*/}
                {/*    >*/}
                {/*        {*/}
                {/*            items?.map(i=>(*/}
                {/*                <Option key={i.key} value={i.key}>*/}
                {/*                    {i?.label}*/}
                {/*                </Option>*/}
                {/*            ))*/}
                {/*        }*/}
                {/*    </FlSelect>*/}
                {/*</Col>*/}

                {/*<Col lg={3} xs={16}>*/}
                {/*    <FlSelect*/}
                {/*        label="Sắp xếp"*/}
                {/*        onChange={(e) => {*/}
                {/*            handleFilter({...pagination,sortByPrice:e})*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        {*/}
                {/*            sortItems?.map(i=>(*/}
                {/*                <Option key={i.key} value={i.key}>*/}
                {/*                    {i?.label}*/}
                {/*                </Option>*/}
                {/*            ))*/}
                {/*        }*/}
                {/*    </FlSelect>*/}
                {/*</Col>*/}

                <Col lg={3} xs={24}>

                    <DatePicker
                        onChange={(e) => {
                            const formattedDate = dayjs(e).format('YYYY-MM-DD');
                            handleFilter({...pagination, fromDate: formattedDate == 'Invalid Date' ? null : formattedDate});
                        }}
                        placeholder="Từ ngày"
                        format="DD/MM/YYYY"
                        allowClear
                    />
                </Col>

                <Col lg={3} xs={24}>
                    <DatePicker
                        onChange={(e) => {
                            const formattedDate = dayjs(e).format('YYYY-MM-DD');
                            const toDate = formattedDate == 'Invalid Date' ? null : formattedDate
                            handleFilter({...pagination, toDate: toDate});
                        }}
                        placeholder="Đến ngày"
                        format="DD/MM/YYYY"
                        allowClear
                    />
                </Col>

            </Row>
        </SearchStyled>
    )
}
