import { useEffect, useRef } from "react";
import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as signalR from "@microsoft/signalr"; 
import { setMessage, setReload, setReloadListChat } from "../redux/chat";
import { setReloadList, setReloadCount } from "../redux/notify";
import Notice from "src/components/Notice";
import UserService from "src/services/UserService";
import { setBalance, setSignalRConnect, setUserInfo } from "src/redux/appGlobal";
import { NotifyType, RoleType } from "src/constants/constants";
import STORAGE, { setStorage } from "src/lib/storage";

const SignalRClient = () => {
  const { userInfo } = useSelector(state => state?.appGlobal)
  const { chatUserInfor } = useSelector(state => state?.appGlobal)
  const connection = useRef(null);
  const dispatch = useDispatch();

  /**
   * @description check login user
   * @author hddung
   */
  useEffect(() => {
      if(Object.keys(userInfo).length !== 0) {
          signalRConnection();
      } else {
        if(connection.current) connection.current.stop();
        connection.current = null
      }
  }, [userInfo]);

  /**
   * @description Thực hiện cập nhật lại số tiền của người dùng vào redux
   * @author hddung 
   */
  const getBalanceUser = async () => {
    const res = await UserService.getInforUser();
    dispatch(setBalance(res?.object?.accountBalance || 0));
  }

  /**
   * @description thực hiện show thông báo khi được duyệt hoặc từ chối đk doanh nghiệp
   * @author hddung
   */
  function sendSuccessRegistCompany(value) {
    Notice({
      isSuccess: value?.type == NotifyType.XacNhanDoanhNghiep,
      msg: `${value?.content}`,
    })

    if(value?.type == NotifyType.XacNhanDoanhNghiep) {
      const dataInfor = {...userInfo, roleID: RoleType.Business};
      setStorage(STORAGE.USER_INFO, dataInfor)
      dispatch(setUserInfo(dataInfor))
    }
  }

  /**
   * @description xử lý khi nhận event thông báo
   * @author hddung 
   */
  function eventNotify(value){
    //Load lại danh sách notify
    dispatch(setReloadList(true));
    //Load lại số lượng
    dispatch(setReloadCount(true));
    
    if(value?.type == NotifyType.XacNhanDoanhNghiep || value?.type == NotifyType.HuyDoanhNghiep){
      sendSuccessRegistCompany(value);
    }
  }

  /**
   * @description đăng ký signalR
   * @author hddung
   */
    function signalRConnection() {
        if(!connection.current) {
          connection.current = new signalR.HubConnectionBuilder()
          .withUrl(`${process.env.REACT_APP_API_WS}/signalRHub?Authorization=${userInfo.token}`)
            .build();
            
            /**
             * @description event chat
             * @author hddung
             */
            connection.current.on("SendMessageSuccess", (message) => {
              dispatch(setReload(true));
              dispatch(setReloadListChat(true));
            });

            /**
             * @description event notify
             * @author hddung
             */
            connection.current.on("SendNotificationSuccess", (value) => {
              if(value) {
                eventNotify(value);
              }
            });

            connection.current.on("InsertTransaction", message => {
                if (message) {
                      //cập nhật lại số tiền trong ví
                      getBalanceUser();
                      //Hiển thị thông báo
                      Notice({
                        isSuccess: true,
                        msg: `${message}`,
                      })
                    //}
                  }
            });
    
            connection.current.start()
            .then(() => {
              console.log('SignalR Connected!'+connection.current.connectionId);
              dispatch(setSignalRConnect(connection.current));
            })
            // .catch(err => console.log('Error connecting to SignalR: ' + err));
        }
  }

  return <div></div>

}

export default SignalRClient;