//Account
export const apiGetAccountByToken = `Account/GetAccount`
export const apiUpdateByAccountID = `Account/UpdateByAccountID`

export const apiGetUnitInformation = `Account/GetUnitInformation`
export const apiUpdateUnitInformation = `Account/UpdateUnitInformation`

//Authenticate
export const apiLogin = `Authservice/Login`
export const apiLogout = `Authservice/Logout`
export const apiRegister = `Authservice/Register`
export const apiForgotPassword = `Authservice/ForgotPassword`
export const apiChangePassword = `Authservice/ChangePassword`
export const apiVerifyCode = `Authservice/VerifyCode`
export const apiBusinessRegister = `Authservice/BusinessRegister`

// User
export const apiInsertUser = "User/Insert"
export const apiDeleteUser = "User/Delete"
export const apiDetailUser = "User/GetDetail"
export const apiUpdateUser = "User/Update"
export const apiGetListUser = "User/GetList"
export const apiImportUser = "User/ImportUser"
export const apiExportUser = "User/ExportUser"
export const apiGetTemplateFileImportUser = "User/GetTemplateFileImportUser"
export const apiGetListGuest = "User/GetListGuest"
export const apiGetAccount = "User/GetAccount"
export const apiExportGuest = "User/ExportGuest"
export const apiImportGuest = "User/ImportGuest"
export const apiGetTemplateFileImportGuest = "User/GetTemplateFileImportGuest"
export const apiUpdateAccount = "User/UpdateAccount"
export const apiReplacePassword = "User/ReplacePassword"
export const apiGetInforUser = "User/GetInforUser"
export const apiChangeInfor = "User/ChangeInfor"
export const apiChangeImgUser = "User/ChangeImgUser"
export const apiGetProfileUser = "User/GetProfileUser"
export const apiUpdateUserProfile = "User/UpdateUserProfile"
export const apiGetListUserInDept = "User/GetListUserInDept"

// Department
export const apiGetList = `Department/GetList`
export const apiGetListUserByDept = `Department/ListUserByDept`
export const apiDeleteDept = `Department/DeleteDept`
export const apiInsertUpdate = `Department/InsertUpdate`
export const apiGetAllDept = "Department/GetAllForCombobox"
// Common
export const apiGetSystemKey = "Common/GetSystemKey"

//POSITION
export const apiGetAllPosition = "Position/GetListPositionForCombobox"
export const apiGetListPosition = "Position/GetList"
export const apiCreate = "Position/Create"
export const apiDelete = "Position/Delete"
export const apiUpdate = "Position/Update"
export const apiGetAllTitle = "Position/GetListTitleForCombobox"

// CATEFORY_POST
export const apiGetListCategoryPost = "CategoryPost/GetList"
export const apiGetCategoryPostCate = "CategoryPost/GetCategoryPost"
export const apiSortCategories = "CategoryPost/SortCategories"
export const apiInsertCategory = "CategoryPost/Insert"
export const apiUpdateCategory = "CategoryPost/Update"
export const apiDeleteCategory = "CategoryPost/Delete"

// POST
export const apiGetListPost = "Post/GetList"
export const apiInsertPost = "Post/Insert"
export const apiGetDetailPost = "Guest/GetPostDetail"
export const apiUpdatePost = "Post/Update"
export const apiDeletePost = "Post/Delete"
export const apiRePost = "Post/RePost"
export const apiCancelPost = "Post/CancelPost"

//PRODUCT
export const apiGetAllProduct = "Product/GetAllProduct"
export const apiGetProductDetail = "Product/GetProductDetails"
export const apiGetProductReview = "Product/GetReviewByProductID"
export const apiInsertProduct = "Product/InsertProduct"
export const apiUpdateProduct = "Product/UpdateProduct"
export const apiDeleteProduct = "Product/DeleteProduct"

//RequestOrder
export const apiGetListWaterTesting = `RequestOrder/GetListWaterTesting`
export const apiGetDetailWaterTesting = `RequestOrder/GetDetailWaterTesting`
export const apiViewStepFinalWaterTesting = `RequestOrder/ViewStepFinal`
export const apiInsertWaterTesting = `RequestOrder/InsertWaterTesting`
export const apiUpdateWaterTesting = `RequestOrder/UpdateWaterTesting`
export const apiDeleteWaterTesting = `RequestOrder/Delete`
export const apiChangeStatusRequestOrder = `RequestOrder/ChangeStatusRequestOrder`
export const apiAcceptPayment = "RequestOrder/AcceptPayment"
export const apiGetExamResultForAdmin = "RequestOrder/GetExamResultForAdmin"
export const apiRedirectRequestOrder = "RequestOrder/RedirectRequestOrder"

// Role
export const apiGetByRoleId = `Role/GetByRoleId`
export const apiCreateOrUpdateRole = `Role/CreateOrUpdateRole`
export const apiGetListRole = `Role/GetList`
export const apiGetAllForCombobox = `Role/GetAllForCombobox`
export const apiDeleteRole = `Role/DeleteRole`
export const apiGetListTab = `Role/GetListTab`
export const apiGetListTask = `Role/GetListTask`

// Region
export const apiGetAllChidrenByRegionId = `Region/getAllChidrenByRegionId`
export const apiInsertRegion = `Region/Insert`
export const apiUpdateRegion = `Region/Update`
export const apiDeleteRegion = `Region/Delete`
export const apigetLocationVN = `Region/GetLocationVN`
export const apiGetByRegionId = `Guest/GetByRegionId`

// TAGS

export const apiGetListTags = "Tags/GetList"
export const apiInsertTags = "Tags/Insert"
export const apiUpdateTags = "Tags/Update"
export const apiDeleteTags = "Tags/Delete"
export const apiGetAllTags = "Tags/GetAllForCombobox"

// WATER PRICE
export const apiGetListWaterPrice = "WaterPriceConfig/GetList"
export const apiInsertWaterPrice = "WaterPriceConfig/Insert"
export const apiUpdateWaterPrice = "WaterPriceConfig/Update"
export const apiDeleteWaterPrice = "WaterPriceConfig/Delete"
export const apiUpdateGeneralWaterPrice = "WaterPriceConfig/UpdateGeneral"

// UPLOAD
export const apiUploadFile = "File/UploadFile"
export const apiUploadFileList = "File/UploadListFileSeaWeed"
export const apiUploadFileListUserProfile = "File/UploadListUserProfile"

//WATER BILL
export const apiGetListWB = "WaterBill/GetList"
export const apiUpdateWB = "WaterBill/Update"
export const apiDeleteWB = "WaterBill/Delete"

// TICKET
export const apiGetListTicket = "TicketList/GetList"
export const apiUpdateTicket = "TicketList/Update"

//ORDER_LIST
export const apiGetListOrder = "OrderList/GetList"
export const apiUpdateCancellation = "OrderList/UpdateCancellation"
export const apiUpdateOrder = "OrderList/Update"

//Examination
export const apiGetAllExaminationType = "Examination/GetAllExaminationType"
export const apiInsertExaminationType = "Examination/InsertExaminationType"
export const apiUpdateExaminationType = "Examination/UpdateExaminationType"
export const apiDeleteExaminationType = "Examination/DeleteExaminationType"
export const apiImportExaminationByType = "Examination/ImportExaminationByType"
export const apiGetTemplateImportExamination =
  "Examination/GetTemplateImportExamination"

export const apiGetAllExamination = "Examination/GetAllExamination"
export const apiGetAllExaminationByType = "Examination/GetAllExaminationByType"
export const apiInsertExamination = "Examination/InsertExamination"
export const apiUpdateExamination = "Examination/UpdateExamination"
export const apiDeleteExamination = "Examination/DeleteExamination"
export const apiGetAllForComboboxExamination = "Examination/GetAllForCombobox"

export const apiGetAllExaminationByEnvirMonitID =
  "Examination/GetAllExaminationByEnvirMonitID"
export const apiInsertExaminationPackage =
  "Examination/InsertExaminationPackage"
export const apiUpdateExaminationPackage =
  "Examination/UpdateExaminationPackage"
export const apiDeleteExaminationPackage =
  "Examination/DeleteExaminationPackage"

// GUEST
export const apiGuestGetListCate = "Guest/GetList"
export const apiGetDetailPackage = "Guest/GetDetailPackage"
export const apiGetListPostBycategoryPostID =
  "Guest/GetListPostBycategoryPostID"
export const apiGetListStaticNavbar = "Guest/GetListStaticNavbar"
export const apiGetDetailByG = "Guest/GetPostDetail"
export const apiGuestSendContact = "Guest/SendContact"
export const apiGetAllTopicGuest = "Guest/GetAllTopic"
export const apiGetPostHome = "Guest/GetPostHome"
export const apiGetRegionId = "Guest/GetByRegionId"
export const apiGetCategoryPost = "Guest/GetCategoryPost"
export const apiGetListPostRelate = "Guest/GetListPostRelate"
export const apiGetListPostByTags = "Guest/GetListPostByTags"
export const apiGetAllListPost = "Guest/GetAllListPost"
export const apiGetAllPackageGuest = "Guest/GetAllPackage"
export const apiGetPackageDetailsByIDForGuest = "Guest/GetPackageDetailsByID"
export const apiGetReviewByPackageIDForGuest = "Guest/GetReviewByPackageID"
export const apiGetAllCourseByG = "Guest/GetAllCourse"
export const apiAllPostCommnetByType = "Guest/GetAllPostCommnetByType"
export const apiGetAllCourseForGuest = "Guest/GetAllCourseForGuest"
export const apiGetDetailCourseForGuest = "Guest/GetDetailCourse"
export const apiInsertDiplomaG = "Guest/InsertDiploma"
export const apiGetAllSeminarG = "Guest/GetAllSeminar"
export const apiGetDetailSeminar = "Guest/GetDetailSeminar"
export const apiSeminarRegister = "Guest/SeminarRegister"
export const apiGetListTopicG = "Guest/GetListTopicName"
export const apiGetTopicByTypeGuest = "Guest/GetTopicByType"
export const apiGetAllOrBySearchGuest = "Guest/GetAllOrBySearch"
export const apiGuestGetAllProcurementBidding = "Guest/GetAllProcurementBidding"
export const apiGuestGetDetailsProcurementBidding =
  "Guest/GetDetailsProcurementBidding"
export const apiGetListDocument = "Guest/GetListDocument"
export const apiGuestInsertBiddingCompany = "Guest/InsertBiddingCompany"
export const apiRegisterRecruit = "Guest/InsertRecruitUser"
export const apiSeminarUserInsertGuest = "Guest/SeminarUserInsert"
export const apiGetAllRecruitForUser = "Guest/GetAllRecruitForUser"
export const apiLookUpExamResult = "Guest/LookUpExamResult"
export const apiGetAllRecruitStandardForUser =
  "Guest/GetAllRecruitStandardForUser"
export const apiGetDetailRecruitStandardForUser =
  "Guest/GetDetailRecruitStandardForUser"
export const apiGuestGetAllTopicPackage = "Guest/GetAllTopicPackage"
export const apiGetAllGuestPackageCommnetByType =
  "Guest/GetAllPackageCommnetByType"

//ProcurementBidding
export const apiUpdateBiddingCompanyStatus =
  "ProcurementBidding/UpdateBiddingCompanyStatus"
export const apiGetAllBiddingCompany = "ProcurementBidding/GetAllBiddingCompany"
export const apiInsertBiddingCompany = "ProcurementBidding/InsertBiddingCompany"
export const apiUpdateBiddingCompany = "ProcurementBidding/UpdateBiddingCompany"
export const apiDeleteBiddingCompany = "ProcurementBidding/DeleteBiddingCompany"

export const apiGetDetailsProcurementBidding =
  "ProcurementBidding/GetDetailsProcurementBidding"
export const apiGetAllProcurementBidding =
  "ProcurementBidding/GetAllProcurementBidding"
export const apiInsertProcurementBidding =
  "ProcurementBidding/InsertProcurementBidding"
export const apiUpdateProcurementBidding =
  "ProcurementBidding/UpdateProcurementBidding"
export const apiDeleteProcurementBidding =
  "ProcurementBidding/DeleteProcurementBidding"
// COURSE
export const apiGetAllCourse = "Course/GetAllCourse"
export const apiGetDetailCourse = "Course/GetDetailCourse"
export const apiInsertCourse = "Course/InsertCourse"
export const apiUpdateCourse = "Course/UpdateCourse"
export const apiInsertTopic = "Course/InsertTopic"
export const apiUpdateTopic = "Course/UpdateTopic"
export const apiDeleteTopic = "Course/DeleteTopic"
export const apiDeleteCourse = "Course/DeleteCourse"
export const apiGetAllCourseByUser = "Course/GetAllCourseByUser"
export const apiEvaluateForCourse = "Course/EvaluateForCourse"
export const apiGetStudentList = "Course/GetStudentList"

// ENVIRONMENT
export const apiGetMonitoringResult =
  "EnvironmentalMonitoring/GetMonitoringResult"
export const apiGetDetailsMonitoringResultVM =
  "EnvironmentalMonitoring/GetDetailsMonitoringResultVM"
export const apiUpdateResult = "EnvironmentalMonitoring/UpdateResult"
export const apiUpdateSample = "EnvironmentalMonitoring/UpdateSample"

//DIPLOMA
export const apiGetAllDiploma = "Diploma/GetAllDiploma"
export const apiInsertDiploma = "Diploma/InsertDiploma"
export const apiUpdateDiploma = "Diploma/UpdateDiploma"
export const apiUpdateDiplomaStatus = "Diploma/UpdateDiplomaStatus"
export const apiDeleteDiploma = "Diploma/DeleteDiploma"
export const apiGetListTopicName = "Diploma/GetListTopicName"
export const apiGetListCourseName = "Diploma/GetListCourseName"
export const apiGetDetailDiploma = "Diploma/GetDetailDiploma"
export const apiUpdatePay = "Diploma/UpdatePay"

// WATER TESTING
export const apiGetAllWaterTesting = "WaterTesting/GetAllWaterTesting"
export const apiChangeStatusWaterTesting =
  "WaterTesting/ChangeStatusWaterTesting"
export const apiGetWaterTestingDetails = "WaterTesting/GetWaterTestingDetails"
export const apiImportResultWaterTesting =
  "WaterTesting/ImportResultWaterTesting"
export const apiCreateSpecimen = "WaterTesting/CreateSpecimen"

//CART
export const apiGetAllProductInCart = "Cart/GetAllCart"
export const apiAddProductToCart = "Cart/AddToCart"
export const apiUpdateProductInCart = "Cart/UpdateQuantityCart"
export const apiDeleteProductInCart = "Cart/DeletePackageByCart"

// ORDER
export const apiGetAllOrder = "Order/GetAllOrder"
export const apiCancelOrder = "Order/CancelOrder"
export const apiGetDetailOrder = "Order/GetOrderDetails"
export const apiCreateOrder = "Order/CreateOrder"
export const apiUpdateOrders = "Order/UpdateOrder"

//Directory
export const apiGetAllUserDirectory = "Directory/GetAllUserDirectory"
export const apiInsertUserDirectory = "Directory/InsertUserDirectory"
export const apiUpdateUserDirectory = "Directory/UpdateUserDirectory"
export const apiDeleteUserDirectory = "Directory/DeleteUserDirectory"
export const apiBlockUserDirectory = "Directory/BlockUserDirectory"
export const apiResetPasswordByUserID = "Directory/ResetPasswordByUserID"

//CheckRegister
export const apiApproveRegister = "CheckRegister/ApproveRegister"
export const apiGetAllCheckRegister = "CheckRegister/GetAllCheckRegister"
export const apiGetDetailCheclRegister = "CheckRegister/GetDetailCheclRegister"

//SEMINAR
export const apiGetAllSeminar = "Seminar/GetAllSeminar"
export const apiSeminarInsert = "Seminar/SeminarInsert"
export const apiGetDetailSeminarAdmin = "Seminar/GetDetailSeminar"
export const apiSeminarUpdate = "Seminar/SeminarUpdate"
export const apiGetAllSeminarUser = "Seminar/GetAllSeminarUser"
export const apiDeleteSeminar = "Seminar/DeleteSeminar"
export const apiDeleteSeminarUser = "Seminar/DeleteSeminarUser"
export const apiUpdateSeminarUserStatus = "Seminar/UpdateSeminarUserStatus"
export const apiSeminarUserInsert = "Seminar/SeminarUserInsert"
export const apiSeminarUserUpdate = "Seminar/SeminarUserUpdate"
export const apiGetDetailSeminarUser = "Seminar/GetDetailSeminarUser"

// Overview
export const apiGetAllOverviewService = "Overview/GetAllOverviewService"
export const apiGetDetailsOverviewGuest = "Overview/GetDetailsOverviewGuest"
export const apiGetOverdueForResults = "Overview/GetOverdueForResults"
export const apiGetDetailsOverviewContact = "Overview/GetDetailsOverviewContact"
export const apiUpdateStatusContact = "Overview/UpdateStatusContact"
export const apiGetDetailsOverviewRecruit = "Overview/GetDetailsOverviewRecruit"
export const apiUpdateStatusRecrui = "Overview/UpdateStatusRecruit"
export const apiGetRequestSeminar = "Overview/GetRequestSeminar"
export const apiUpdateStatusRequest = "Overview/UpdateStatusRequest"
export const apiGetDetailsOverviewRequestorder =
  "Overview/GetDetailsOverviewRequestorder"
export const apiUpdateStatusProcurementBidding =
  "Overview/UpdateStatusProcurementBidding"
export const apiGetDetailsOverviewprocurement_bidding =
  "Overview/GetDetailsOverviewBiddingCompany"
export const apiGetCheckNewRegister = "Overview/GetCheckNewRegister"

//Business
export const apiGetAllBussiness = "Directory/GetAllBusinessDirectory"
export const apiInsertBussiness = "Directory/InsertBusinessDirectory"
export const apiUpdateBussiness = "Directory/UpdateBusinessDirectory"
export const apiHistoryBussiness = "Directory/IsViewHistory"

//Contact
export const apiSearchGetSupportListRequet =
  "Contact/SearchGetSupportListRequet"
export const apiConfirmSupport = "Contact/ConfirmSupport"
export const apiForwardSupportRequest = "Contact/ForwardSupportRequest"
export const apiGetDetailContact = "Contact/GetDetailContact"
export const apiEviction = "Contact/Eviction"
export const apiReceive = "Contact/Receive"
export const apiDoNotAccept = "Contact/DoNotAccept"

// GOOGLE
export const apiLoginGoole = "Authservice/GetGoogleLoginUrl"
export const apiCallBackLoginGoole = "Authservice/GetTokenInfo"
//FACEBOOK
export const apiLoginFB = "Authservice/GetFacebookLoginUrl"
// Topic
export const apiGetListTopic = "Topic/GetList"
export const apiDeleteTopicCategory = "Topic/DeleteTopicCategory"
export const apiCreateTopicCategory = "Topic/CreateTopicCategory"
export const apiUpdateTopicCategory = "Topic/UpdateTopicCategory"

// TopicPost
export const apiGetAllOrBySearch = "TopicPost/GetAllOrBySearch"
export const apiGetTopicByType = "TopicPost/GetTopicByType"

//Recruit
export const apiGetAllRecruit = "Recruit/GetAllRecruit"
export const apiGetDetailsRecruit = "Recruit/GetDetailsRecruit"
export const apiInsertRecruit = "Recruit/InsertRecruit"
export const apiUpdateRecruit = "Recruit/UpdateRecruit"
export const apiDeleteRecruit = "Recruit/DeleteRecruit"
export const apiUpdateStatusRecruit = "Recruit/UpdateStatusRecruit"
export const apiInsertTopicPost = "TopicPost/InsertTopicPost"
export const apiUpdateTopicPost = "TopicPost/UpdateTopicPost"
export const apiDeleteTopicPostById = "TopicPost/DeleteTopicPostById"
export const apiUpdateNote = "Recruit/UpdateStatusRecruitUser"
export const apiGetAllCareertitlesName = "Recruit/GetAllCareertitlesName"
export const apiGetAllRecruitUserByPosition =
  "Recruit/GetAllRecruitUserByPosition"
export const apiGetDetailsRecruitUser = "Recruit/GetDetailsRecruitUser"
export const apiGetAllRecruitPositionNameByRecruit =
  "Recruit/GetAllRecruitPositionNameByRecruit"
export const apiGetAllRecruitPositionName = "Recruit/GetAllRecruitPositionName"
export const apiDeleteRecruitUser = "Recruit/DeleteRecruitUser"
export const apiUpdateRecruitUser = "Recruit/UpdateRecruitUser"
export const apiInsertRecruitUser = "Recruit/InsertRecruitUser"

export const apiInsertRecruitPosition = "Recruit/InsertRecruitPosition"
export const apiUpdatetRecruitPosition = "Recruit/UpdateRecruitPosition"
export const apiDeleteRecruitPositionName = "Recruit/DeleteRecruitPositionName"
export const apiDeleteRecruitStandard = "Recruit/DeleteRecruitStandard"
export const apiGetDetailRecruitPositionNameByRecruit =
  "Recruit/GetDetailRecruitPositionNameByRecruit"
export const apiGetStandardNameForRecruitPosition =
  "Recruit/GetStandardNameForRecruitPosition"
export const apiGetAllRecruitUserByStandard =
  "Recruit/GetAllRecruitUserByStandard"
export const apiUpdateIsViewForUser = "Recruit/UpdateIsViewForUser"
//PostComment
export const apiPostCommentInsert = "PostComment/PostCommentInsert"
export const apiPostCommentUserLike = "PostComment/PostCommentUserLike"
export const apiPostCommentDelete = "PostComment/PostCommentDelete"
export const apiPostCommentUpdate = "PostComment/PostCommentUpdate"
export const apiGetAllPostCommnetByType = "PostComment/GetAllPostCommnetByType"

//Statistic
export const apiGetDashboard = "Statistic/GetDashboard"
export const apiGetDashboardDetailsByYear =
  "Statistic/GetDashboardDetailsByYear"
export const apiGetDetailStatistics = "Statistic/GetDetailStatistics"
export const apiUserDetailedStatistics = "Statistic/UserDetailedStatistics"
export const apiGetAllSpecimen = "Statistic/GetAllSpecimen"
export const apiCourseDetails = "Statistic/CourseDetails"
export const apiGetAllTestScore = "Statistic/GetAllTestScore"
export const apiGetAllOrderForTestScore = "Statistic/GetAllOrderForTestScore"
export const apiTestScoreDetails = "Statistic/TestScoreDetails"

//Notify
export const apiGetListNotify = "Notify/GetList"
export const apiGetNewNotification = "Notify/GetNewNotification"
export const apiMarkAsRead = "Notify/MarkAsRead?NotifyId="
export const apiMarkAsSeen = "Notify/MarkAsSeen?NotifyId="
export const apiDeleteNotifyForUser = "Notify/DeleteNotifyForUser?NotifyId="
export const apiGetListByReferenceId = "Notify/GetListByReferenceId"

//Package
export const apiGetAllPackage = "Package/GetAllPackage"
export const apiInsertPackage = "Package/InsertPackage"
export const apiUpdatePackage = "Package/UpdatePackage"
export const apiDeletePackage = "Package/DeletePackage"
export const apiGetAllTopicPackage = "Package/GetAllTopicPackage"
export const apiPackageInsertTopic = `Package/InsertTopic`
export const apiPackageUpdateTopic = `Package/UpdateTopic`
export const apiPackageDeletePackage = `Package/DeleteTopic`

export const apiGetAllRequestPackage = `Package/GetAllRequestPackage`
export const apiDeleteRequestPackage = `Package/DeleteRequestPackage`
export const apiInsertRequestPackage = `Package/InsertRequestPackage`
export const apiGetAllRequestPackageForUser = `Package/GetAllRequestPackageForUser`
export const apiUpdateStatusRequestPackage = `Package/UpdateStatusRequestPackage`
export const apiCreateRequestPackage = `Package/CreateRequestPackage`
export const apiEvaluateForPackage = `Package/EvaluateForPackage`

export const apiPackageCommentInsert = `Package/PackageCommentInsert`
export const apiPackageCommentUpdate = `Package/PackageCommentUpdate`
export const apiPackageCommentDelete = `Package/PackageCommentDelete`
export const apiPackageCommentUserLike = `Package/PackageCommentUserLike`
export const apiGetAllPackageCommnetByType = `Package/GetAllPackageCommnetByType`
export const apiGetDefaultPackageComment = `Package/GetDefaultPackageComment`
export const apiSortRequestService = `Package/SortRequestService`

//boxchat
export const apiGetByObjectId = "CommunicationContact/GetByObjectId"
export const apiInsertOrUpdateContact = "CommunicationContact/InsertOrUpdate"
export const apiDeleteContact = "CommunicationContact/Delete"
export const apiGetListContactByPackage =
  "CommunicationContact/GetListContactByPackage"
export const apiGetContactDetailsByAccount =
  "CommunicationContact/GetContactDetailsByAccount"

//Salary
export const apiGetSalaryConfig = "Salary/GetSalaryConfig"
export const apiUpdateSalaryConfig = "Salary/UpdateSalaryConfig"
export const apiGetWorktimeUserOverview = "Salary/GetWorktimeUserOverview"
export const apiUpdateWorktime = "Salary/UpdateWorktime"
export const apiUpdateWorktimeUser = "Salary/UpdateWorktimeUser"
export const apiChangeStatusWorktimeUser = "Salary/ChangeStatusWorktimeUser"
export const apiScheduleInsertWorktime = "Salary/ScheduleInsertWorktime"
export const apiScheduleInsertWorktimeUser = "Salary/ScheduleInsertWorktimeUser"
export const apiUpdateMonthlySalary = "Salary/UpdateMonthlySalary"
export const apiTakeDayOff = "Salary/TakeDayOff"
export const apiTakeManyDayOff = "Salary/TakeManyDayOff"
export const apiBroswerTakeDayOff = "Salary/Broswer"
export const apiRefreshWorktimeUser = "Salary/RefreshWorktimeUser"
export const apiGetWorktimeHistory = "Salary/GetWorktimeHistory"
export const apiGetWageByUser = "Salary/GetWageByUser"
export const apiGetWage = "Salary/GetWage"
export const apiUpdateSalary = "Salary/UpdateSalary"
//Folder
export const apiGetListFolder = "Folder/GetList"
export const apiGetListFileByFolderID = "Folder/GetListFileByFolderID"
export const apiInsertFolder = "Folder/Insert"
export const apiUpdateFolder = "Folder/Update"
export const apiDeleteFolder = "Folder/Delete"

//Infrastructure
export const apiGetListCategoryInfrastructure =
  "Infrastructure/GetListCategoryInfrastructure"
export const apiCreateCategoryInfrastructure =
  "Infrastructure/CreateCategoryInfrastructure"
export const apiUpdateCategoryInfrastructure =
  "Infrastructure/UpdateCategoryInfrastructure"
export const apiGetCategoryByID = "Infrastructure/GetCategoryByID"
export const apiDeleteCategoryInfrastructure =
  "Infrastructure/DeleteCategoryInfrastructure"
export const apiSortCategoriesInfrastructure = "Infrastructure/SortCategories"
export const apiGetListInfrastructure = "Infrastructure/GetListInfrastructure"
export const apiCreateInfrastructure = "Infrastructure/CreateInfrastructure"
export const apiUpdateInfrastructure = "Infrastructure/UpdateInfrastructure"
export const apiDeleteInfrastructure = "Infrastructure/DeleteInfrastructure"
export const apiExportInfrastructure = "Infrastructure/ExportInfrastructure"
export const apiGetListHistory = "Infrastructure/GetListHistory"
export const apiMoveDevice = "Infrastructure/MoveDevice"
export const apiDeviceConfirmation = "Infrastructure/DeviceConfirmation"
export const apiRefusingDevice = "Infrastructure/RefusingDevice"
export const apiReCallDevice = "Infrastructure/ReCallDevice"
export const apiReturnDevice = "Infrastructure/ReturnDevice"
export const apiImportInfrastructure = "Infrastructure/ImportInfrastructure"
