const { Tooltip } = require("antd");
const { useState } = require("react");
const { default: SvgIcon } = require("src/components/SvgIcon");

function IconHoverProduct({iconName, title}) {
    const [hover, setHover] = useState(false);
    return (
        <Tooltip 
            placement='leftTop'
            mouseLeaveDelay={0}
            title={title}
            overlayStyle={{ maxWidth: 500 }}
        >
            <div style={{width: '100%', height: '100%'}}>
                <SvgIcon
                    name={iconName}
                    style={{width: '100%', padding: '0px 8px'}}
                />
            </div>
        </Tooltip>
    )
}

export default IconHoverProduct