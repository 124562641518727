import {Anchor, Col, Divider, Row, Tag, Tooltip} from "antd";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import SpinCustom from "../../../../../components/Spin";
import {FloatActionWrapper} from "../../../../../components/FloatAction/styles";
import {ListUserStyled} from "../../../Company/styled";
import TableCustom from "../../../../../components/Table/CustomTable";
import {Search} from "../../components/Search"
import {format, parseISO} from "date-fns";
import ConTractViewPDF from "../../../../Contract/ContractPreview/ConTractViewPDF";
import ButtonCircle from "../../../../../components/MyButton/ButtonCircle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";
import {DisplayId} from "../../../../../lib/utils";

export const ContractListTable = ({
                                      contracts,
                                      refreshData,
                                      totalPage,
                                      pagination,
                                      getOrder,
                                      onChangeKeyword,
                                      handleFilter
                                  }) => {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [showCancel, setShowCancel] = useState(false);
    const [contractSelected, setContractSelected] = useState(false);

    const [dataBodySource, setDataBodySource] = useState({
        pageSize: 10,
        currentPage: 1,
    });

    const handleChangePage = (page, pageSize) => {
        const model = {...pagination, pageSize: pageSize || 10, currentPage: page || 1}
        getOrder(model);
    }
    const [isShowContract, setIsShowContract] = useState(false);


    const formatCurrency = (value) => {
        return new Intl.NumberFormat('vi-VN', {
            style: 'currency',
            currency: 'VND',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value).replace('VND', 'đ');
    };
    const RenderViewButton = (contract) => {
        return (
            <ButtonCircle
                title="Xem hợp đồng"
                iconName="view"
                style={{background: "rgba(255,255,255,0.8)"}}
                onClick={() => {
                    setContractSelected(contract);
                    setIsShowContract(true);
                }}
            />
        )

    };
    const renderDate = (dateString) => {
        if (dateString) {
            const dateObject = parseISO(dateString);
            const formattedDate = format(dateObject, 'dd-MM-yyyy HH:mm:ss');
            return formattedDate;
        }
    }
    const renderButton = (contract) => {

    }
    const renderButtonViewer = (contract) => {
        return (<>
            {RenderViewButton(contract)}
        </>)
    }

    const [totalDataSource, setTotalDataSource] = useState(0);
    const columns = [
        {
            title: "STT",
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
            width: 60,
        },
        {
            title: "Tên Hợp đồng",
            dataIndex: "contractName",
            key: "contractName",
            render: (value, contract) =>
                (
                    <div>
                        <div className={"max-line1 col-11"}>
                            <Tooltip title={value} className={"d-flex"}>
                                <p className="multiline-cell">
                                    {value}
                                </p>
                            </Tooltip>
                        </div>
                    </div>
                ),
            width: 200,
            align: "left",
        },
        {
            title: "Tên file",
            dataIndex: "contractFileName",
            key: "contractFileName",
            render: (value, contract) =>
                (
                    <div>
                        <div className={"max-line1 col-11"}>
                            <Tooltip title={"Xem và tải"} className={"d-flex"}>
                                <div onClick={()=>{
                                        setContractSelected(contract);
                                        setIsShowContract(true);
                                }}>
                                    <FontAwesomeIcon icon={faFilePdf}  style={{color: "#1f2123",fontSize:"18px",marginRight:4}} />
                                </div>
                                <p className="multiline-cell" onClick={()=>{
                                    setContractSelected(contract);
                                    setIsShowContract(true);
                                }} >
                                    {value}
                                </p>
                            </Tooltip>
                        </div>
                        {/*<div className={"col-1"}>*/}
                        {/*    <FloatActionWrapper size="small" className="float-action__wrapper" style={{background: "transparent"}}>*/}
                        {/*        {renderButtonViewer(contract)}*/}
                        {/*    </FloatActionWrapper>*/}
                        {/*</div>*/}
                    </div>
                ),
            width: 200,
            align: "left",
        },
        {
            title: "Trạng thái",
            dataIndex: "type",
            key: "type",
            render: (value, contract) =>
                (
                    <div>
                        {value==2?"Chờ ký":"đã ký"}
                    </div>
                ),
            width: 100,
            align: "center",
        },
        // {
        //     title: "Đơn hàng",
        //     dataIndex: "orderID",
        //     key: "orderID",
        //     render: (value, contract) => (
        //         <div>
        //             {value}
        //         </div>
        //     ),
        //     width: 160,
        //     align: "flex-end",
        // },
        {
            title: "Sản phẩm",
            dataIndex: "productName",
            key: "productName",
            width: 240,
            align: "left",
            render: (value, contract) => (
                <div>
                    <p style={{fontSize: 14, fontWeight: 600}}>{value}</p>
                    <span><Tag>{contract?.productItemName}</Tag></span>
                </div>
            ),
        },
        {
            title: "Ngày ký",
            dataIndex: "createDate",
            key: "createDate",
            width: 100,
            align: "center",
            render: (value, contract) => (
                <div>
                    {renderDate(value)}
                </div>
            )
        },
        // {
        //     title: "",
        //     dataIndex: "action",
        //     key: "action",
        //     width: 100,
        //     render: (text, record) => (
        //         <div className="d-flex-end justify-content-center align-items-center mh-36">
        //             <FloatActionWrapper size="small" className="float-action__wrapper" style={{background: "#fff"}}>
        //                 {renderButton(record)}
        //             </FloatActionWrapper>
        //         </div>
        //     ),
        //
        // },
    ]
    return (
        <>
            <ListUserStyled>
                <div>
                    <Search

                        onChangeKeyword={onChangeKeyword}
                        pagination={pagination}
                        handleFilter={handleFilter}
                    />
                    <Divider className="mv-16"/>
                </div>
                <Anchor
                    affix={false}
                    getContainer={() => document.getElementById("body-admin-scroll")}
                >
                    <Row gutter={[16, 16]}>
                        <Col style={{width: 0}} flex="auto">
                            <SpinCustom spinning={loading}>
                                <TableCustom
                                    isPrimary
                                    // onRow={record => {
                                    //     return {
                                    //         onClick: () => {
                                    //             setOpenModalUserDetail(record)
                                    //         },
                                    //     }
                                    // }}
                                    className="mb-6"
                                    dataSource={contracts}
                                    columns={columns}

                                    textEmpty="Không có hợp đồng"
                                    rowKey="contractID"

                                    sticky={{offsetHeader: -12}}
                                    scroll={{y: "100%", x: "800px"}}
                                    pagination={{
                                        defaultPage: dataBodySource.pageSize,
                                        showSizeChanger: true,
                                        pageSizeOptions: ['10', '20', '30'],
                                        total: totalPage,
                                        // onChange: (page, pageSize) => {
                                        //     setDataBodySource((prevState) => ({
                                        //         ...prevState,
                                        //         pageSize: pageSize || 10,
                                        //         currentPage: page || 1
                                        //     }));
                                        // },
                                        onChange: (page, pageSize) => {
                                            handleChangePage(page, pageSize);
                                        }
                                    }}
                                />
                            </SpinCustom>
                        </Col>
                    </Row>
                </Anchor>

            </ListUserStyled>
            {
                isShowContract && (

                    <ConTractViewPDF
                        onCancel={() => setIsShowContract(false)}
                        open={isShowContract}
                        contractPDF={{
                            titleContract: contractSelected?.contractName,
                            contractUrlPDF: contractSelected?.contractUrlPDF
                        }}
                    />
                )
            }
            {
                isShowContract && (

                    <ConTractViewPDF
                        onCancel={() => setIsShowContract(false)}
                        open={isShowContract}
                        contractPDF={{
                            titleContract: contractSelected?.contractName,
                            contractUrlPDF: contractSelected?.contractUrlPDF
                        }}
                    />
                )
            }
        </>

    )
}
