import {Anchor, Col, Divider, Row} from "antd";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {format, parseISO} from 'date-fns';
import SpinCustom from "../../../../components/Spin";
import {FloatActionWrapper} from "../../../../components/FloatAction/styles";
import TableCustom from "../../../../components/Table/CustomTable";
import {ListUserStyled} from "../../Company/styled";
import Search from "../components/Search";
import {PROMOTE_CODE_TYPE} from "../../../../constants/constants";
import ButtonCircle from "../../../../components/MyButton/ButtonCircle";
import CB1 from "../../../../components/Modal/CB1";
import Button from "../../../../components/MyButton/Button";
import {PromoteCodeCreate} from "../detail/PromoteCodeCreate";
import Swal from "sweetalert2";
import http from "../../../../services";
import {PromoteDetail} from "../detail/PromoteDetail";


export const PromoteListTable = ({
                                     code,
                                     refreshData,
                                     totalPage,
                                     pagination,
                                     getOrder,
                                     onChangeKeyword,
                                     handleFilter,
                                     handleRefeshStatus,
                                     handleRefreshUpdate
                                 }) => {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [showCancel, setShowCancel] = useState(false);
    const [orderId, setOrderId] = useState();
    const [isShowVoucherModal, setIsShowVoucherModal] = useState(false);
    const [isShowPromoteModal, setIsShowPromoteModal] = useState(false);
    const [isShowPromoteDetail, setIsShowPromoteDetail] = useState(false);
    const [voucherDetail, setVoucherDetail] = useState();
    const [dataBodySource, setDataBodySource] = useState({
        pageSize: 10,
        currentPage: 1,
    });


    const handleChangePage = (page, pageSize) => {
        const model = {...pagination, pageSize: pageSize || 10, currentPage: page || 1}
        getOrder(model);
    }


    const formatCurrency = (value) => {
        return new Intl.NumberFormat('vi-VN', {
            style: 'currency',
            currency: 'VND',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value).replace('VND', 'đ');
    };

    const showSuccess = (message) => {
        Swal.fire({
            icon: 'success',
            title: 'Thành Công',
            text: message,
        })
    };
    const RenderActiveButton = (voucher) => {

        if(voucher?.isChangeStatus&&!voucher?.status){
            return  (
                <ButtonCircle
                    title="kích hoạt mã giá"
                    iconName="green-accept"
                    style={{background: "#FFE9EC"}}
                    onClick={() => {
                        CB1({
                            title: `Bạn có chắc chắn muốn kích hoạt mã này không?`,
                            icon: "warning-usb",
                            okText: "Đồng ý",
                            onOk: async close => {
                                handleRefeshStatus(voucher.promoteCode,true);
                                http.delete(`Promote/ChangeStatus?PromoteCode=${voucher?.promoteCode}&Status=true`).then(resp => {
                                    if(!resp?.isError){
                                        // handleRefeshStatus(voucher?.promoteCode,true);
                                        handleRefreshUpdate();
                                        showSuccess(' Kích hoạt mã thành công')
                                    }
                                }).finally(() => {
                                    close();
                                })
                            },
                        })
                    }}
                />
            )
        }


    };

    const RenderInactiveButton = (voucher) => {
        if(voucher?.isChangeStatus&&voucher?.status){
            return  (
                <ButtonCircle
                    title="Hủy kích hoạt"
                    iconName="cancel"
                    style={{background: "#FFE9EC"}}
                    onClick={() => {
                        CB1({
                            title: `Bạn có chắc chắn muốn hủy kích hoạt mã này không?`,
                            icon: "warning-usb",
                            okText: "Đồng ý",
                            onOk: async close => {
                                handleRefeshStatus(voucher.promoteCode,true);
                                http.delete(`Promote/ChangeStatus?PromoteCode=${voucher?.promoteCode}&Status=false`).then(resp => {
                                    if(!resp?.isError){
                                        handleRefreshUpdate();
                                        // handleRefeshStatus(voucher?.promoteCode,false);
                                        showSuccess('Huỷ kích hoạt mã thành công')
                                    }
                                }).finally(() => {
                                    close();
                                })
                            },
                        })
                    }}
                />
            )
        }


    };
    const RenderDeleteButton = (voucher) => {
        if(voucher?.isDelete){
            return  (
                <ButtonCircle
                    title="Xóa mã giá"
                    iconName="delete-black"
                    style={{background: "#FFE9EC"}}
                    onClick={() => {
                        CB1({
                            title: `Bạn có chắc chắn muốn xóa mã này không?`,
                            icon: "trashRed",
                            okText: "Đồng ý",
                            onOk: async close => {
                                handleRefeshStatus(voucher.promoteCode,true);
                                http.delete(`Promote/Delete?PromoteCode=${voucher?.promoteCode}`).then(resp => {
                                    if(!resp?.isError){
                                        // handleRefeshStatus(voucher?.promoteCode,false);
                                        handleRefreshUpdate();
                                        showSuccess('Xoá mã thành công')
                                    }
                                }).finally(() => {
                                    close();
                                })
                            },
                        })
                    }}
                />
            )
        }


    };

    const renderEditButton=(voucher)=>{
        if(voucher?.isUpdate){
            return(
                <ButtonCircle
                    title="Cập nhật"
                    iconName="edit"
                    onClick={() => {
                        setIsShowPromoteDetail(true);
                        setVoucherDetail(voucher);
                    }}
                />
            )
        }
    }
    const renderButton=(voucher)=>{
        // if(voucher?.isDelete){
        //     return (
        //         <>
        //             {RenderActiveButton( voucher)}
        //             {RenderInactiveButton( voucher)}
        //             {renderEditButton(voucher)}
        //         </>
        //     )
        // }else{
        //     return (
        //         <>
        //             {RenderActiveButton( voucher)}
        //             {RenderInactiveButton( voucher)}
        //             {renderEditButton(voucher)}
        //             {RenderDeleteButton(voucher)}
        //         </>
        //     )
        // }
            return (
                <>
                    {RenderActiveButton( voucher)}
                    {RenderInactiveButton( voucher)}
                    {renderEditButton(voucher)}
                    {RenderDeleteButton(voucher)}
                </>
            )
    }
    const renderDate=(dateString)=>{
        const dateObject = parseISO(dateString);
        const formattedDate = format(dateObject, 'dd-MM-yyyy HH:mm:ss');
        return formattedDate;
    }
    const columns = [
        // {
        //     title: "Thể loại",
        //     dataIndex: "promoteType",
        //     key: "promoteType",
        //     render: (value) =>
        //         (
        //             value == PROMOTE_CODE_TYPE.VOUCHER ? 'Mã giảm giá' : 'Mã giá'
        //         ),
        //     width: 120,
        //     align: "center",
        // },
        {
            title: "Mã giá ",
            dataIndex: "promoteCode",
            key: "promoteCode",
            width: 160,
            align: "left",
        },
        {
            title: "Sản phẩm",
            dataIndex: "productName",
            key: "productName",
            render: (value, voucher) =>
                (
                    <div className={""}>
                        <p className={"max-line1"} style={{fontWeight:600}}>{value}</p>
                        <p className={"max-line1"}> Phân loại: {voucher?.productItemName}</p>
                    </div>
                ),
            width: 160,
            align: "left",
        },

        {
            title: "Số lượng mã phát hành",
            dataIndex: "promoteUsers",
            key: "promoteUsers",
            width: 60,
            align: "right",
            render: (value, voucher) =>
                (
                    <div className={""}>
                        {voucher?.promoteUsers.map(x=>x.quantityPromoteCode).reduce((previousValue, currentValue) =>
                            (previousValue+currentValue),0)}
                    </div>
                ),
        },
        {
            title: "Giá trị mã",
            dataIndex: "promoteValue",
            key: "promoteValue",
            width: 120,
            align: "right",
            render: (value, voucher) => (
                <>
                    {

                        (PROMOTE_CODE_TYPE.VOUCHER == voucher.promoteType) ? (
                            <p  style={{color:"red"}}>{value}%</p>
                        ) : (
                            <p  style={{color:"red"}}>{formatCurrency(value)}</p>
                        )
                    }
                </>
            )
        },
        {
            title: "Ngày hết hạn",
            dataIndex: "expiredDateCode",
            key: "expiredDateCode",
            width: 100,
            align: "center",
            render: (value, voucher) => (
                <>
                    {renderDate(value)}
                </>
            )
        },
        {
            title: "Trạng thái",
            dataIndex: "status",
            key: "status",
            width: 120,
            align: "left",
            render: (value, voucher) => (
                <div className="d-flex-end justify-content-center align-items-center mh-36">
                    <div>
                        {
                            !voucher?.status?"Không kích hoạt":"Kích hoạt"
                        }
                    </div>
                    <FloatActionWrapper size="small" className="float-action__wrapper" style={{background: "#fff"}}>
                        {renderButton(voucher)}
                    </FloatActionWrapper>
                </div>
            ),

        },
    ]
    return (
        <>
            <ListUserStyled>
                <div>
                    <Search

                        onChangeKeyword={onChangeKeyword}
                        pagination={pagination}
                        handleFilter={handleFilter}
                    />
                    <Divider className="mv-16"/>
                    <div className="title-type-1 d-flex justify-content-space-between align-items-center pb-16 pt-0 mb-16">
                        <div className="fs-24">
                            Danh sách mã giá
                            {/* ({dataSource?.total || 0}) : */}
                        </div>
                        <Row guter={[16, 16]}>
                            {/*{(*/}
                            {/*    <Col style={{marginRight:10}}>*/}
                            {/*        <Button*/}
                            {/*            btnType="primary"*/}
                            {/*            className="btn-hover-shadow"*/}
                            {/*            onClick={()=>{*/}
                            {/*                setIsShowVoucherModal(true)*/}
                            {/*            }}*/}
                            {/*        >*/}
                            {/*            Thêm voucher*/}
                            {/*        </Button>*/}
                            {/*    </Col>*/}
                            {/*)}*/}
                            {(
                                <Col>

                                    <Button
                                        btnType="primary"
                                        className="btn-hover-shadow"
                                        onClick={()=>{
                                            setIsShowPromoteModal(true)
                                        }}
                                    >
                                        Thêm mã giá
                                    </Button>
                                </Col>
                            )}

                        </Row>
                    </div>
                </div>
                <Anchor
                    affix={false}
                    getContainer={() => document.getElementById("body-admin-scroll")}
                >
                    <Row gutter={[16, 16]}>
                        <Col style={{width: 0}} flex="auto">
                            <SpinCustom spinning={loading}>
                                <TableCustom
                                    isPrimary
                                    // onRow={record => {
                                    //     return {
                                    //         onClick: () => {
                                    //             setOpenModalUserDetail(record)
                                    //         },
                                    //     }
                                    // }}
                                    className="mb-6"
                                    dataSource={code}
                                    columns={columns}
                                    textEmpty="Không có đơn hàng"
                                    rowKey="orderId"
                                    sticky={{offsetHeader: -12}}
                                    scroll={{y: "100%", x: "800px"}}
                                    pagination={{
                                        defaultPage: dataBodySource.pageSize,
                                        showSizeChanger: true,
                                        pageSizeOptions: ['10', '20', '30'],
                                        total: totalPage,
                                        // onChange: (page, pageSize) => {
                                        //     setDataBodySource((prevState) => ({
                                        //         ...prevState,
                                        //         pageSize: pageSize || 10,
                                        //         currentPage: page || 1
                                        //     }));
                                        // },
                                        onChange: (page, pageSize) => {
                                            handleChangePage(page, pageSize);
                                        }
                                    }}
                                />
                            </SpinCustom>
                        </Col>
                    </Row>
                </Anchor>

            </ListUserStyled>
            {/*{*/}
            {/*    isShowVoucherModal&&(*/}
            {/*        <VoucherCreate cancel={()=>setIsShowVoucherModal(false)} open={isShowVoucherModal}/>*/}
            {/*    )*/}
            {/*}*/}
            {
                isShowPromoteModal&&(
                    <PromoteCodeCreate cancel={()=>setIsShowPromoteModal(false)} open={isShowPromoteModal} handleRefreshUpdate={handleRefreshUpdate}/>
                )
            }
            {
                isShowPromoteDetail&&(
                    <PromoteDetail voucher={voucherDetail} cancel={()=>setIsShowPromoteDetail(false)}
                                   open={isShowPromoteDetail} handleRefreshUpdate={handleRefreshUpdate}/>

                )
            }
        </>

    )
}
