import { Form, Input, Modal, Space } from "antd"
import { useState } from "react"
import FlInput from "src/components/FloatingLabel/Input"
import Button from "src/components/MyButton/Button"
import { getRegexPassword } from "src/lib/utils"
import EmployeeService from "src/services/EmployeeService"
import SpinCustom from "../Spin"
import Notice from "../Notice"

function ChangePasswordAdmin({ onOk, detailInfo, reloadTable, ...props }) {
  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)

  const closeChangePassword = () => {
    props?.onCancel();
    form.resetFields();
  }

  async function handleSubmitChangePassword() {
    try {
      setLoading(true);
      await form.validateFields().then(async values => {
        const res = await EmployeeService.resetPassword({
          userID: detailInfo?.userID,
          password: values?.newPassword
        });
        if(res?.isError) return
        Notice({ msg: `Cập nhật nhân viên thành công !` });
        onOk && onOk();
        props?.onCancel();
        reloadTable();
      })
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      title="Đổi mật khẩu"
      footer={
        <Space size="middle">
          <Button btnType="third" size="medium" onClick={closeChangePassword}>
            Đóng
          </Button>
          <Button
            type="primary"
            onClick={handleSubmitChangePassword}
            loading={loading}
          >
            Cập nhật
          </Button>
        </Space>
      }
      {...props}
    >
      <SpinCustom spinning={loading}>
        <Form layout="vertical" form={form} name="form-change-password">
          <Form.Item
            label="Mật khẩu mới"
            name="newPassword"
            rules={[
              { required: true, message: "Vui lòng nhập mật khẩu mới" },
              {
                pattern: getRegexPassword(),
                message:
                  "Mật khẩu có chứa ít nhất 8 ký tự, trong đó có ít nhất một số và bao gồm cả chữ thường và chữ hoa và ký tự đặc biệt, ví dụ @, #, ?, !.",
              },
            ]}
          >
            <Input.Password placeholder="Nhập mật khẩu mới" />
          </Form.Item>
          <Form.Item
            label="Nhập lại mật khẩu mới" 
            name="reNewPassword"
            dependencies={["newPassword"]}
            rules={[
              { required: true, message: "Vui lòng nhập lại mật khẩu mới" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error("Mật khẩu không khớp!"))
                },
              }),
            ]}
          >
            <Input.Password placeholder="Nhập lại mật khẩu mới" />
          </Form.Item>
        </Form>
      </SpinCustom>
    </Modal>
  )
}

ChangePasswordAdmin.propTypes = {}

export default ChangePasswordAdmin
