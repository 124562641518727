import { Anchor, Avatar, Col, Divider, Form, Input, Row, Select, Space, Table, Tooltip, TreeSelect } from "antd"
import TextArea from "antd/es/input/TextArea"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import CustomModal from "src/components/Modal/CustomModal"
import Button from "src/components/MyButton/Button"
import Notice from "src/components/Notice"
import SpinCustom from "src/components/Spin"
import { STATUS_OPTION } from "src/constants/constants"
import ContactService from "src/services/ContactService"
import UserService from "src/services/UserService"
import ProductionService from "src/services/ProductionService"

const InsertUpateInventory = ({ detailInfo, modeShowPopup, onOk, reloadTable, ...props }) => {
    const [loading, setLoading] = useState(false);
    const [listProduct, setListProduct] = useState([]);
    const [listAllProductParent, setListAllProductParent] = useState([]); // list product phục vụ đển prevent việc chọn
    const [selectedValue, setSelectedValue] = useState(null);
    const [form] = Form.useForm();

    //#region useEffect

    useEffect(() => {
        getListProduct();
    }, [])

    useEffect(() => {
        if(detailInfo) {
            form.setFieldsValue(detailInfo);
            setSelectedValue(detailInfo?.productItemID);
        }
    }, [detailInfo]);

    //#endregion
    
    //#region function

    /**
     * @description lấy danh sách sản phẩm
     * @author hddung
     */
    async function getListProduct() {
        const res = await ProductionService.getAllProductAndProductItem();
        if(res?.isError) return
        const arrData = res?.object?.list || [];
        setListProduct(
            arrData.filter(x => x.lv == 1).map(x => ({
                value: x.id, 
                title: x.objectName, 
                children: getChildrenArr(x.id, arrData.filter(x => x.lv != 1))
            }))
        );
        setListAllProductParent(arrData.filter(x => x.lv == 1));
    }

    /**
     * @description đệ quy lấy ra chidrent của từng node
     * @author hddung
     */
    function getChildrenArr(parentID, data){
        const lstChildren = data.filter(x => x.parentID == parentID);
        
        return data?.length > 0 
        ? 
        //đệ quy tiếp tục với những node k thuộc parentID
        lstChildren.map(x => ({
            value: x.id, 
            title: x.objectName, 
            children: getChildrenArr(x.id, data.filter(x => x.parentID != parentID))
        })) 
        : []; 
    }

    /**
     * @description thêm mới tồn kho
     * @author hddung 
     */
    async function insertInventory(data) {
        const res = ProductionService.insertInventory(data);
        if(res?.isError) return
        Notice({
            isSuccess: true,
            msg: "Thêm tồn kho thành công" 
        });

        onOk && onOk();
        props?.onCancel();
        reloadTable();
    }

    /**
     * @description cập nhật tồn kho
     * @author hddung 
     */
    async function updateInventory(data) {
        const res = await ProductionService.updateInventory(data);
        if(res?.isError) return
        Notice({
            isSuccess: true,
            msg: "Cập nhật tồn kho thành công" 
        });

        onOk && onOk();
        props?.onCancel();
        reloadTable();
    }

    /**
     * @description thực hiện check mode để case function tương ứng
     * @author hddung
     */
    async function saveModeContent() {
        try {
            await form.validateFields().then(value => {

                if(detailInfo) {
                    updateInventory({
                        inventoryID: detailInfo?.inventoryID,
                        content: value.content
                    });
                } else {
                    insertInventory({
                        productItemID: value?.productItemID,
                        content: value?.content || ''
                    });
                }
            });
        } finally {
            setLoading(false);
        }
        
    }

    /**
     * @description thực hiện render cho title
     * @author hddung
     */
    function renderTitle() {
        switch(modeShowPopup) {
            case STATUS_OPTION.CHO_XAC_NHAN:
                return 'Xác nhận xử lý';
            case STATUS_OPTION.DANG_XU_LY:
                return 'Thêm phụ trách';
            case STATUS_OPTION.HOAN_THANH:
                return 'Hoàn thành';
        }
    }

    //#endregion

    const renderFooter = () => (
        <div className="d-flex justify-content-flex-end">
            <Button
                className="btn-hover-shadow"
                onClick={() => props?.onCancel()}
            >
                Đóng
            </Button>
            <Button
                btnType="primary"
                className="btn-hover-shadow"
                onClick={saveModeContent}
            >
                {detailInfo ? 'Cập nhật' : 'Lưu'}
            </Button>
        </div>
    )

    return (
        <CustomModal
            title={detailInfo ? 'Cập nhật tồn kho' : 'Thêm mới tồn kho'}
            footer={renderFooter()}
            width={600}
            {...props}
            style={{padding: '20px 20px 0px 10px'}}
        >
        <SpinCustom spinning={loading}>
            <Form
                form={form}
                layout="vertical"
            >
                <Row gutter={[16, 16]} className="justify-content-center">
                    <Col span={24}>
                        <Form.Item
                            label="Phân loại sản phẩm"
                            name="productItemID"
                            rules={[
                            {
                                required: true,
                                message: "Thông tin không được để trống",
                            },
                            ]}
                            valuePropName="select"
                        >
                            {listProduct?.length > 0 &&
                                <TreeSelect
                                    showSearch
                                    treeNodeFilterProp="title"
                                    style={{
                                        width: '100%',
                                    }}
                                    dropdownStyle={{
                                        maxHeight: 400,
                                        overflow: 'auto',
                                    }}
                                    placeholder="Chọn phân loại sản phẩm"
                                    allowClear
                                    value={selectedValue}
                                    treeDefaultExpandAll
                                    onChange={(record) => {
                                        if(listAllProductParent.find(x => x.id == record)) {
                                            setSelectedValue(null);
                                            return
                                        }
                                        setSelectedValue(record);
                                    }}
                                    treeData={listProduct}
                                />
                            }
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} className="justify-content-center">
                    <Col span={24}>
                        <Form.Item
                            label='Nội dung'
                            name="content"
                            rules={[
                            {
                                required: true,
                                message: "Thông tin không được để trống",
                            },
                            ]}
                        >
                            <Input.TextArea 
                                style={{height: '130px'}} 
                                onKeyDown={event => {
                                    if (event.key === 'Enter') {
                                        event.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </SpinCustom>
        </CustomModal>
    )
}

export default InsertUpateInventory