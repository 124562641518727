import './inforCard_Mobile.css'
import React from "react";

export default function InforCard_Mobile() {
    return (
        <div id="SECTION159" className="ladi-section ls-d">
            <div className="ladi-section-background"></div>
            <div className="ladi-overlay"></div>
            <div className={"pr__container"}>
                <div className={"pr-it pr-it__title"} style={{display: "flex"}}>
                    <div className={"col-6 pr__c_p"}>
                        <div className="pr__main-title">
                            <div style={{marginBottom: "6px"}}>
                                <h2 className="pri">LỢI ÍCH
                                    KHÁCH HÀNG SỬ DỤNG BẢN QUYỀN
                                </h2>
                            </div>
                            <div className={"line-fr"}></div>
                        </div>
                        <div>Những điều này bạn sẽ cảm nhận được khi đã sử dụng
                            sản phẩm do chúng tôi cung cấp
                        </div>
                    </div>
                    <div className={"col-6"} style={{display: "flex",flexWrap:"wrap"}}>

                        <div className={"col-12 col-md-6 i-prv"}>
                            <img
                                src={"https://w.ladicdn.com/s650x550/5ce50eeaa6078c5b1f6780ef/lovepik_com-500608939-the-age-of-computer-science-and-technology-education-20191201023001.jpg"}/>
                        </div>
                        <div className={"col-12 col-md-6 i-prv"}>
                            <img
                                src={"https://w.ladicdn.com/s650x550/5ce50eeaa6078c5b1f6780ef/lovepik_com-500410361-computer-science-and-technology-20191201023209.jpg"}/>
                        </div>
                    </div>
                </div>


                <div className={"pr-it"}>
                    <div className="pr__main-title"><h5 className="pri pri-cus">Hỗ trợ tính năng mới nhất</h5></div>
                    <div>Liên lục cập nhật (với Windows bản quyền) và
                        thương thích ngược đối với các bản Office hiện tại và phiên bản trước
                    </div>
                </div>

                <div className={"pr-it"}>
                    <div className="pr__main-title"><h5 className="pri pri-cus">Hỗ trợ cài đặt</h5></div>
                    <div>
                        Chúng tôi hỗ trợ kích hoạt bản quyền đối với
                        khách hàng thông qua phần mềm điều khiển màn hình từ xa (Teamviewer hoặc Ultraviewer)
                    </div>
                </div>
                <div className={"pr-it"}>
                    <div className="pr__main-title"><h5 className="pri pri-cus">Hiệu năng cao</h5></div>
                    <div>
                        Phần mềm bản quyền không can thiệp hệ thống,
                        nên hoạt động ở mức độ tối đa theo thiết lập nhà sản xuất
                    </div>
                </div>

                <div className={"img-dv__container"}>
                    <div className="ld__img">
                        <img src={"https://w.ladicdn.com/uploads/images/bfc1d1bf-af34-4fe2-9361-35f7f9120640.png"}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
