import React, {useEffect} from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {useMediaQuery} from "react-responsive";
import { useNavigate } from "react-router-dom";
import { ROUTER_KEY } from "src/constants/constants";
import ROUTER from "../../../router";
import { Badge } from "antd";
import IconHoverProduct from "../ProductList/iconHoverProduct";
export const ProductRelated = ({products}) => {
    const navigate = useNavigate();
    const isTabletOrMobile = useMediaQuery({query: '(max-width: 1224px)'});

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('vi-VN', {
            style: 'currency',
            currency: 'VND',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value).replace('₫', '');
    };

    function roundPercentage(decimalNumber) {
        // Làm tròn số thập phân về 2 chữ số
        const roundedNumber = Math.round(decimalNumber * 100) / 100;
        return `${roundedNumber}%`;
    }

    useEffect(() => {
    }, [products])
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        // <Carousel responsive={responsive}>
        //
        // </Carousel>

        products.length > 0 && (
            <div className="container partern box-pattern">

                <div>
                    <h4 className={"highlight-prod-title font-lg-bold  pri"}>Sản Phẩm Liên Quan</h4>
                    <p className={"color-gray-500"}> Danh sách những sản phẩm theo xu hướng mà có thể bạn sẽ
                        thích</p>
                </div>
                <div className="mt-0">
                    {/*<div className="list-products-5 list-products-home10">*/}
                    <Carousel
                        additionalTransfrom={0}
                        arrows
                        autoPlaySpeed={3000}
                        centerMode={false}
                        className=""
                        containerClass="container-with-dots"
                        dotListClass=""
                        draggable
                        focusOnSelect={false}
                        infinite
                        itemClass=""
                        keyBoardControl
                        minimumTouchDrag={80}
                        pauseOnHover
                        renderArrowsWhenDisabled={false}
                        renderButtonGroupOutside={false}
                        renderDotsOutside={false}
                        responsive={{
                            desktop: {
                                breakpoint: {
                                    max: 3000,
                                    min: 1024
                                },
                                items: 5,
                                partialVisibilityGutter: 40
                            },
                            mobile: {
                                breakpoint: {
                                    max: 464,
                                    min: 0
                                },
                                items: 1,
                                partialVisibilityGutter: 30
                            },
                            tablet: {
                                breakpoint: {
                                    max: 1024,
                                    min: 464
                                },
                                items: 3,
                                partialVisibilityGutter: 30
                            }
                        }}
                        rewind={false}
                        rewindWithAnimation={false}
                        rtl={false}
                        shouldResetAutoplay
                        showDots={false}
                        sliderClass=""
                        slidesToSlide={isTabletOrMobile?1:5}
                        swipeable
                    >

                            {products?.map(product => (

                                <div key={product.productID} className="card-grid-style-3 home6-style home7-style ctn-product" style={{marginRight: '10px'}}>
                                <div className="card-grid-inner card-product cpgr">
                                    <Badge.Ribbon 
                                        style={{
                                            display: product?.isSignContract ? 'block' : 'none', 
                                            minWidth: '56px'
                                        }} 
                                        text={<IconHoverProduct iconName={'sign-contract-ic'} title={'Ký hợp đồng'}/>}
                                        color="#fd9636"
                                    >
                                    <Badge.Ribbon 
                                        style={{
                                            display: !product?.isCheckStock ? 'block' : 'none', 
                                            minWidth: '56px', 
                                            top: product?.isSignContract ? '36px' : '8px'
                                        }} 
                                        text={<IconHoverProduct iconName={'stock-ic'} title={'Mua quá tồn kho'}/>}
                                        color="#fd9636"
                                    >
                                    <Badge.Ribbon 
                                        style={{
                                            display: product?.isBuyAutomatic ? 'block' : 'none', 
                                            minWidth: '56px', 
                                            top: product?.isSignContract && !product?.isCheckStock ? '66px' 
                                            : ((!product?.isSignContract && !product?.isCheckStock) || product?.isSignContract) ? '36px' : '8px'
                                        }} 
                                        text={<IconHoverProduct iconName={'automatic-ic'} title={'Mua bán tự động'}/>}
                                        color="#fd9636"
                                    >
                                    <div className="image-box">
                                        {/*<span className="label bg-brand-2">-{product.discount}%</span>*/}
                                        {
                                            (product?.rawDiscount > 0) ? (

                                                <span
                                                    className="label bg-brand-2">-{roundPercentage(product.rawDiscount)}</span>
                                            ) : ""
                                        }
                                        <a onClick={() => navigate(`/${product.slug || `san-pham${ROUTER_KEY.PRODUCT_DETAIL}`}-${product.productID}`)}>
                                            <img src={product.image} alt={product.productName}/>
                                        </a>
                                    </div>
                                    </Badge.Ribbon>
                                    </Badge.Ribbon>
                                    </Badge.Ribbon>
                                    <div className="info-right">
                                        <a className="font-xs color-gray-500 product-highlight-name txt-productshop-name"
                                           onClick={()=>navigate(`${ROUTER.PRODUCT_SHOP}?shopID=${product.shopID}`)}>
                                           {product.username}
                                        </a>
                                        <br/>
                                        <a className="color-brand-3 font-sm-bold  product-highlight-name txt-product-name"
                                           onClick={() => navigate(`/${product.slug || `san-pham${ROUTER_KEY.PRODUCT_DETAIL}`}-${product.productID}`)}
                                        >
                                            {product.productName}
                                        </a>
                                        <div className="rating prrt ">
                                            <div className="product-rate d-inline-block">
                                                <div className="product-rating"
                                                     style={{width: product.averageReview * 20 + "%"}}></div>
                                            </div>
                                            <span
                                                className="font-xs color-gray-500 font-medium"> ({product.quantityReview} ) | Đã bán {product.sold}</span>
                                        </div>
                                        <div className="price-info mb-10 price-con">
                                            {
                                                product.priceMin != -1
                                                && (
                                                    product.priceMax !== -1 ? (  //có  nhiều phân loại sản phẩm
                                                            (product?.priceMax === product?.priceMin) ? (
                                                                <strong className="font-lg-bold color-brand-3 price-main">
                                                                    <p>{formatCurrency(product.priceMin)}</p>
                                                                    <span>₫</span>
                                                                </strong>
                                                            ) : (
                                                                <>
                                                                    <strong
                                                                        className="font-lg-bold color-brand-3 price-main">
                                                                        <p>{formatCurrency(product.priceMin)}</p>
                                                                        <span>₫</span>
                                                                    </strong>
                                                                    <strong
                                                                        className="font-lg-bold color-brand-3 price-main dot">-</strong>
                                                                    <strong
                                                                        className="font-lg-bold color-brand-3 price-main">
                                                                        <p>{formatCurrency(product.priceMax)}</p>
                                                                        <span>₫</span>
                                                                    </strong>
                                                                </>
                                                            )

                                                        ) :
                                                        (
                                                            (product?.rawDiscount === 0) ? (
                                                                <strong
                                                                    className="font-lg-bold color-brand-3 price-main">
                                                                    <p>{formatCurrency(product.priceMin)}</p>
                                                                    <span>₫</span>
                                                                </strong>
                                                            ) : (
                                                                <>
                                                                    <strong
                                                                        className="font-lg-bold color-brand-3 price-main">
                                                                        <p>{formatCurrency(product.priceMin)}</p>
                                                                        <span>₫</span>
                                                                    </strong>
                                                                    <del className=" price-main old-sale-price">
                                                                        <p>{formatCurrency(product.priceMin)}</p>
                                                                        <span>₫</span>
                                                                    </del>
                                                                </>
                                                            )
                                                        )
                                                )
                                            }
                                            {
                                                product.priceMin == -1 && (
                                                    <>
                                                    <strong className="font-lg-bold color-brand-3 price-main">
                                                        Liên hệ
                                                    </strong>
                                                    { product.priceMax > 0 && (
                                                        <>
                                                            <strong
                                                                className="font-lg-bold color-brand-3 price-main dot">-</strong>
                                                            <strong
                                                                className="font-lg-bold color-brand-3 price-main">
                                                                <p className={"price-max-1 pr-bn"}>{formatCurrency(product.priceMax)}</p>
                                                                <span>₫</span>
                                                            </strong>
                                                        </>
                                                    )
                                                    }
                                                    </>
                                                )
                                            }
                                        </div>
                                        <div className="mt-10">
                                            <a className="btn btn-cart"
                                               onClick={() => navigate(`/${product.slug || `san-pham${ROUTER_KEY.PRODUCT_DETAIL}`}-${product.productID}`)}>Xem
                                                chi tiết</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        </Carousel>
                    {/*</div>*/}
                </div>
            </div>
        )

    )
}
