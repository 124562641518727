import { Tree } from "antd"
import { useEffect, useState } from "react"
import CB1 from "src/components/Modal/CB1"
import ButtonCircle from "src/components/MyButton/ButtonCircle"
import Notice from "src/components/Notice"
import SpinCustom from "src/components/Spin"
import { GUIDE_EMPTY, MODE_ACTION, USER_STATUS_ENUM } from "src/constants/constants"
import { convertTreeData } from "src/lib/utils"
import Category from "src/services/CategoryService"
import ModalAddDept from "../../Category/components/ModalAddCategory"
import DepartmentSevice from "src/services/DepartmentSevice"
import { TreeAnchorStyled } from "../styled"
import InsertDepartment from "../modal/InsertDepartment"

const TreeAnchor = ({ keyId, dataBody, setDataBody, getAllUser }) => {
  const [loading, setLoading] = useState(false);
  const [treeData, setTreeData] = useState([]);
  const [detailInfo, setDetailInfo] = useState();
  const [openPopupDepartment, setOpenPopupDepartment] = useState(false);
  const [modeInsertUpdate, setModeInsertUpdate] = useState(1);
  

  //#region useEffect

  useEffect(() => {
    getAllDepartment()
  }, [])

  //#endregion

  //#region function

  async function deleteDepartment(departmentID) {
    try{
      setLoading(true);
      const res = await DepartmentSevice.deleteDepartment(departmentID);
      if(res?.isError) return
      getAllDepartment();
      Notice({ msg: `Xóa phòng ban thành công !` });
    } finally {
      setLoading(false);
    }
  }

  /**
   * @description lấy ra toàn bộ danh sách phòng ban 
   */
  async function getAllDepartment() {
    try {
      setLoading(true);
      const res = await DepartmentSevice.getAllDepartment();
      if(res?.isError) return
      const arrData =  res?.object?.list;
      setTreeData(
        arrData.filter(x => x.departmentParentID == '00000000-0000-0000-0000-000000000000').map(x => ({
          title: x.departmentName,
          key: x.departmentID,
          isInsert: x.isInsert,
          isUpdate: x.isUpdate,
          isDelete: x.isDelete,
          level: 1,
          children: getChildrenArr(x.departmentID, x.departmentName, 1, arrData.filter(x => x.departmentParentID != '00000000-0000-0000-0000-000000000000'))
        }))
      )
      
      setDataBody({
        pageSize: 10,
        currentPage: 1,
        textSearch: "",
        status: USER_STATUS_ENUM.DANG_HOAT_DONG,
        departmentID: arrData.find(x => x.departmentParentID == '00000000-0000-0000-0000-000000000000')?.departmentID || ''
      });

    } finally {
      setLoading(false);
    }
  }

  /**
   * @description đệ quy lấy ra chidrent của từng node
   * @author hddung
   */
  function getChildrenArr(parentID, departmentName, level, data){
    const lstChildren = data.filter(x => x.departmentParentID == parentID);

    return data?.length > 0 
    ? 
    //đệ quy tiếp tục với những node k thuộc parentID
    lstChildren.map(x => ({
        title: x.departmentName, 
        key: x.departmentID,
        departmentParentName: departmentName,
        isInsert: x.isInsert,
        isUpdate: x.isUpdate,
        isDelete: x.isDelete,
        level: level + 1,
        children: getChildrenArr(x.departmentID, x.departmentName, level + 1, data.filter(x => x.departmentParentID != parentID))
    })) 
    : []; 
  }

  //#endregion
  
  return (
    <TreeAnchorStyled>
      <SpinCustom spinning={loading}>
        {
          !!treeData?.length && (
            <Tree
              defaultExpandAll
              selectedKeys={[dataBody?.departmentID || treeData.find(x => x.level == 1)?.key]}
              treeData={treeData}
              onSelect={(_, e) => {
                setDataBody((prevState) => ({
                  ...prevState,
                  departmentID: _[0] || ''
                }))
              }}
              titleRender={(nodeData, idx) => {
                return (
                  <div
                    key={nodeData?.key}
                    className="d-flex justify-content-space-between align-items-center mh-36"
                  >
                    <div className="text-ellipsis">{nodeData?.title}</div>
                    <div className="list-button-tree-hover">
                      {nodeData?.isInsert &&
                        <ButtonCircle
                          key="add-box"
                          title="Thêm phòng ban"
                          iconName="add-box"
                          onClick={() => {
                            setDetailInfo(nodeData);
                            setOpenPopupDepartment(true);
                            setModeInsertUpdate(MODE_ACTION.ADD);
                          }}
                        />
                      }
                      {nodeData?.isUpdate &&
                        <ButtonCircle
                          key="edit-black"
                          title="Sửa phòng ban"
                          iconName="edit-black"
                          onClick={() => {
                            setDetailInfo(nodeData);
                            setOpenPopupDepartment(true);
                            setModeInsertUpdate(MODE_ACTION.EDIT);
                          }}
                        />
                      }
                      {nodeData?.isUpdate &&
                        <ButtonCircle
                          key="delete-black"
                          title="Xóa phòng ban"
                          iconName="delete-black"
                          onClick={() => {
                            CB1({
                              title: `Bạn có chắc chắn muốn xoá danh mục
                              <strong> ${nodeData?.title}</strong> không?`,
                              icon: "trashRed",
                              okText: "Đồng ý",
                              onOk: async close => {
                                deleteDepartment(nodeData?.key)
                                close()
                              },
                            })
                          }}
                        />
                      }
                    </div>
                  </div>
                )
              }}
            />
          )
        }
      </SpinCustom>
      {openPopupDepartment && (
        <InsertDepartment
          open={openPopupDepartment}
          onCancel={() => {
            setDetailInfo(undefined);
            setOpenPopupDepartment(false);
          }}
          detailInfo={detailInfo}
          modeInsertUpdate={modeInsertUpdate}
          reloadTable={getAllDepartment}
          onOk={() => {}}
        />
      )}
    </TreeAnchorStyled>
  )
}

export default TreeAnchor
