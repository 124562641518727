import {
  apiGetList,
  apiGetListUserByDept,
  apiDeleteDept,
  apiInsertUpdate,
  apiGetAllDept,
  apiGetAllDepartment,
  apiInsertDepartment,
  apiUpdateDepartment,
  apiDeleteDepartment,
} from "./urls"
import http from "../index"
import QueryString from "qs"

const getListDept = params => http.get(apiGetList, { params })
const deleteDept = body =>
  http.patch(apiDeleteDept + `?${QueryString.stringify(body)}`)
const insertOrUpdate = body => http.post(apiInsertUpdate, body)
const getListUserByDept = params => http.post(apiGetListUserByDept, params)
const getAllDept = params => http.get(apiGetAllDept, { params })
const getAllDepartment = () => http.get(apiGetAllDepartment)
const insertDepartment = body => http.post(apiInsertDepartment, body)
const updateDepartment = body => http.post(apiUpdateDepartment, body)
const deleteDepartment = id => http.post(`${apiDeleteDepartment}?departmentID=${id}`)

const Department = {
  getListDept,
  deleteDept,
  insertOrUpdate,
  getListUserByDept,
  getAllDept,
  getAllDepartment,
  insertDepartment,
  updateDepartment,
  deleteDepartment,
}
export default Department
