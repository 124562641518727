import {
  apiGetAllDepartment,apiInsertDepartment,apiUpdateDepartment,apiDeleteDepartment
} from "./urls"
import http from "../index2"

const getAllDepartment = () => http.get(apiGetAllDepartment)
const insertDepartment = body => http.post(apiInsertDepartment, body)
//const updateDepartment = () => http.post(apiUpdateDepartment)
const deleteDepartment = id => http.post(`${apiDeleteDepartment}?DEPTID=${id}`)

const updateDepartment = body => http.post(apiUpdateDepartment, body);


const Department = {
  getAllDepartment,insertDepartment,updateDepartment,deleteDepartment

}

export default Department
