import moment from "moment"

export const fomatTimeFromNow = time => {
  //   const time = moment()
  //   return moment(time).fromNow()
  if (!(moment(time).diff(moment(), "days") < 0)) return moment(time).fromNow()
  else return moment(time).format("DD/MM/YYYY")
}

/**
 * @description format date hiển thị theo giờ, ngày, tháng, năm
 * @author HDDUNG
 */
export function formatDateAndTimeWithCurrentTime(date) {
  if(!date) return '';
  
  var currentDate = new Date();
  var d = new Date(date);
  var month = '' + (d.getMonth() + 1);
  var day = '' + d.getDate();
  var year = d.getFullYear();
  var hours = d.getHours();
  var minutes = d.getMinutes();

  if (month.length < 2)
      month = '0' + month;
  if (day.length < 2)
      day = '0' + day;
  if(hours < 10) 
      hours = '0' + hours;
  if(minutes < 10) 
      minutes = '0' + minutes;

  var time = hours + ':' + minutes

  if (year != currentDate.getFullYear()) {
      return [day, month, year].join('-') + ' ' + time;
  } else if (d.getDate() != currentDate.getDate() || d.getMonth() != currentDate.getMonth()) {
      return [day, month].join('-') + ' ' + time;
  } else {
      return time;
  }
}


/**
 * @description format date
 * @author HDDUNG
 */
export function formatDateTime(date) {
  if(!date) return '';

  return moment(date).format("DD-MM-YYYY hh:mm:ss");
};

/**
 * @description format date
 * @author HDDUNG
 */
export function formatDate(date) {
  if(!date) return '';

  return moment(date).format("DD-MM-YYYY");
};