import React, {useEffect, useState} from "react";

import http from "../../../../services";
import {CompanyCustomerListTable} from "./CompanyCustomerListTable";

    const defaultRoles={
        isImport: true,
        isInsert: true,
        isSendMultiMails:true
    }
export const CustomerCompanyManagement=()=>{

    const [customers, setCustomer] = useState([])
    const [totalPage, setTotalPage] = useState([])
    const [roles, setRoles] = useState(defaultRoles)
    const [statusSlected, setStatusselected] = useState(0);


    const [pagination, setPagination] = useState({
        "pageSize": 10,
        "currentPage": 1,
        "textSearch": "",
        "sttatus":1,
        // "gender": 0,
        "sortBy": 0,
        // "isBirthdayToday": 0,
        "relationshipStatus":0,

    })
    const getListContract = () => {
        http.post('/ManageCustomer/GetListBusiness',{...pagination})
            .then(res => {
                if (!res?.isError) {
                    setCustomer(res.object.list)
                    setTotalPage(res.object.count);
                    const newRoles={...roles,isImport: res?.object?.isImport, isInsert: res?.object?.isInsert, isSendMultiMails:res?.object?.isSendMultiMails}
                    setRoles(newRoles);
                }else{
                    setCustomer([]);
                }

            })
    }
    const handleFilter =(model)=>{
        setPagination(model)
    }
    const updateCustomer = (userID, updatedCustomer) => {
        // setCustomer((prevCustomer) =>
        //     prevCustomer.map((customer) =>
        //         (customer.userID === userID ? updatedCustomer : customer)
        //     )
        // );
        getListContract();
    };
    const updateStatus = (userID, updatedCustomer) => {
        setCustomer((prevCustomer) =>
            prevCustomer.map((customer) =>
                (customer.userID === userID ? updatedCustomer : customer)
            )
        );
        getListContract();
    };

    useEffect(() => {
        getListContract();
    }, [pagination]);




    const onChangeTab = (key: string) => {
        setCustomer([]);
        const newPagi = {...pagination, status: key, currentPage: 1};
        setPagination(newPagi);
    };

    const onChangeKeyword = (key: string) => {

        setCustomer([]);
        const newPagi = {...pagination, textSearch: key, currentPage: 1};
        setPagination(newPagi);
    }


    const handleChangePage=(model) => {
        setPagination(model)
    }
    return (
        <>
            <div className={" page-container"}>
                <div className={"main-nav__content"}>

                </div>

                <div className={"content-customers"}>
                    <CompanyCustomerListTable customers={customers} refreshData={updateCustomer} totalPage={totalPage}
                                              pagination={pagination} changePage={handleChangePage}
                                              onChangeKeyword={onChangeKeyword}
                                              handleFilter={handleFilter}
                                              refreshStatus={updateStatus}
                                              roles={roles}
                    />
                </div>

            </div>
        </>


    )

}
