import React, {useEffect, useState} from "react";

import http from "../../../../services";
import {VoucherListTable} from "./voucher-list-table";
import {PROMOTE_CODE_TYPE} from "../../../../constants/constants";
import Swal from "sweetalert2";

export const VoucherManagement = () => {

    const [codes, setCodes] = useState([])
    const [totalPage, setTotalPage] = useState([])

    const [statusSlected, setStatusselected] = useState(0);


    const [pagination, setPagination] = useState({
        pageSize: 10,
        currentPage: 1,
        type: PROMOTE_CODE_TYPE.VOUCHER,
        keyword: "",
        isDelete: 0,
        status:-1
    })
    const getListOrders = () => {
        http.post('/Promote/GetList', {...pagination})
            .then(res => {
                if (!res?.isError) {
                    setCodes(res.object.list)
                    setTotalPage(res.object.count);

                } else {
                    setTotalPage([]);
                }

            })
    }
    const handleFilter = (model) => {
        setPagination(model)
    }
    const updateOrder = (orderId, updatedOrder) => {
        setCodes((prevOrder) =>
            prevOrder.map((order) =>
                (order.orderId === orderId ? updatedOrder : order)
            )
        );
    };

    useEffect(() => {
        getListOrders();
        // console.log('pagi',pagination);
    }, [pagination]);

    useEffect(() => {

    }, [codes]);


    const onChangeKeyword = (key: string) => {

        setCodes([]);
        const newPagi = {...pagination, textSearch: key, currentPage: 1};
        setPagination(newPagi);
    }


    const handleChangePage = (model) => {
        setPagination(model)
    }
    const handleRefeshStatus = (id, status) => {
        // const newModel = codes.map(item => {
        //     if (item.promoteCode === id) {
        //         return {...item, isDelete: status};
        //     }
        //     return item;
        // })
        // setCodes(newModel)
        getListOrders();
    }
    const handleRefreshUpdate=()=>{
       getListOrders();
    }

    return (
        <>
            <div className={"page-container"}>
                {/*<div className={"main-nav__content"}>*/}

                {/*</div>*/}

                <div className={"content-orders"}>
                    <VoucherListTable code={codes} refreshData={updateOrder} totalPage={totalPage}
                                      pagination={pagination} getOrder={handleChangePage}
                                      onChangeKeyword={onChangeKeyword}
                                      handleFilter={handleFilter}
                                      handleRefeshStatus={handleRefeshStatus}
                                      handleRefreshUpdate={handleRefreshUpdate}
                    />
                </div>

            </div>
        </>


    )

}
