import {BackTop, Spin} from "antd"
import React, {useEffect} from "react"
import {useRoutes} from "react-router-dom"
import ROUTER from "./index"
import {HomePage} from "src/pages/HomePage/HomePage"
import HomePage2 from "src/pages/HomePage"
import {OrdersList} from "../pages/USER/Orders/OrderList"
import {ProductList} from "src/pages/Product/ProductList"
import {ProductDetail} from "src/pages/Product/ProductDetail"
import ProductShopList from "src/pages/Product/ProductShopList"
import {CartDetail} from "src/pages/Cart/CartDetail"
import Department from "src/pages/ADMIN/Department"
import Employee from "src/pages/ADMIN/Employee"
import EmployeeOracle from "src/pages/ADMIN/EmployeeOracle"
import EmployeeContact from "src/pages/ADMIN/EmployeeContact"
import Service from "src/pages/ADMIN/Service"
import Category from "src/pages/ADMIN/Category"
import Post from "src/pages/ADMIN/PostManager"
import CreatePost from "src/pages/ADMIN/CreatePost"
import News from "src/pages/ANONYMOUS/News"
import Tags from "src/pages/ADMIN/Tags"
import NewsDetail from "src/pages/ANONYMOUS/NewsDetail"
import Contact from "src/pages/Contact"
import {OrderDetail} from "../pages/USER/Orders/OrderDetail";
import {OrderContact} from "../pages/USER/Orders/OrderContact";
import {OrderManagement} from "../pages/ADMIN/Orders/List/OrderManagement";
import {OrderAdminDetail} from "../pages/ADMIN/Orders/Detail/OrderAdminDetail";
import {ROUTER_KEY} from "src/constants/constants"
import {OrderManagementConfirm} from "../pages/ADMIN/Orders/List/OrderManagementConfirm";
import {VoucherManagement} from "../pages/ADMIN/Voucher/list/voucher-management";
import {PromoteManagement} from "../pages/ADMIN/Voucher/list/promote-management";
import {ContractManagement} from "../pages/ADMIN/Contract/list/contract/ContractManagement";
import {FinanecDashBoard} from "../pages/USER/Dashboard/finance";
import {TemplateManagement} from "../pages/ADMIN/Contract/list/template/TemplateManagement";
import ListInventory from "src/pages/ADMIN/Inventory"
import {CustomerManagement} from "../pages/ADMIN/Customer/List/CustomerManagement";
import LogHistory from "src/pages/ADMIN/LogHistory"
import {BannerManager} from "../pages/ADMIN/BanneFooter";
import TransactionPayment from "src/pages/ADMIN/TransactionPayment"
import ManageWallet from "src/pages/ADMIN/ManageWallet"
import {SingatureManagement} from "../pages/ADMIN/Contract/list/signature/SingatureManagement";
import {CompanyCustomerListTable} from "../pages/ADMIN/Customer/ListCompany/CompanyCustomerListTable";
import {CustomerCompanyManagement} from "../pages/ADMIN/Customer/ListCompany/CustomerCompanyManagement";

// ANONYMOUS
const LoginGoogle = React.lazy(() => import("src/pages/LoginGoogle"))
const PublicRouters = React.lazy(() => import("src/pages/PublicRouters"))

const SvgViewer = React.lazy(() => import("src/pages/SvgViewer"))
const NotFound = React.lazy(() => import("src/pages/NotFound"))

// USER
const PrivateRoutes = React.lazy(() => import("src/pages/PrivateRoutes"))
const MyAccount = React.lazy(() => import("src/pages/USER/Dashboard"))
//const Orders = React.lazy(() => import("src/pages/USER/Orders"))

// ADMIN
const AminRoutes = React.lazy(() => import("src/pages/ADMIN/AminRoutes"))
const Role = React.lazy(() => import("src/pages/ADMIN/Role"))
// const ChatCenter = React.lazy(() => import("src/pages/ADMIN/ChatCenter"))
const Production = React.lazy(() => import("src/pages/ADMIN/Production"))
const OverView = React.lazy(() => import("src/pages/ADMIN/OverView"))
const Company = React.lazy(() => import("src/pages/ADMIN/Company"))
// const Post = React.lazy(() => import("src/pages/ADMIN/PostManager"))
//const HomePage = React.lazy(() => import("src/pages/HomePage"))


//#region function

  function LazyLoadingComponent({ children }) {
    return (
      <React.Suspense
        fallback={
          <div className="loading-center" style={{ height: "100vh" }}>
            <Spin />
          </div>
        }
      >
        {children}
      </React.Suspense>
    )
  }

  //#endregion

const routes = [
  {
    path: ROUTER.SVG_VIEWER,
    element: (
      <LazyLoadingComponent>
        <SvgViewer />
      </LazyLoadingComponent>
    ),
  },
  {
    path: ROUTER.LOGIN_GOOGLE,
    element: (
      <LazyLoadingComponent>
        <LoginGoogle />
      </LazyLoadingComponent>
    ),
  },
  // {
  //   path: ROUTER.SVG_VIEWER,
  //   element: (
  //     <LazyLoadingComponent>
  //       <SvgViewer />
  //     </LazyLoadingComponent>
  //   ),
  // },
  {
    path: ROUTER.NHAN_VIEN_ORACLE,
    element: (
      <LazyLoadingComponent>
        <EmployeeOracle />
      </LazyLoadingComponent>
    ),
  },

  // ADMIN
  {
    element: (
      <LazyLoadingComponent>
        <AminRoutes />
      </LazyLoadingComponent>
    ),
    children: [
      {
        path: ROUTER.LICH_SU_HOAT_DONG,
        element: (
          <LazyLoadingComponent>
            <LogHistory />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.QUAN_LY_VI,
        element: (
          <LazyLoadingComponent>
            <ManageWallet />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.LICH_SU_NAP_TIEN,
        element: (
          <LazyLoadingComponent>
            <TransactionPayment />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DOANH_NGHIEP,
        element: (
          <LazyLoadingComponent>
            <Company />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANH_SACH_DOANH_NGHIEP,
        element: (
          <LazyLoadingComponent>
            <CustomerCompanyManagement />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TONG_QUAN,
        element: (
          <LazyLoadingComponent>
            <OverView />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.PHAN_QUYEN,
        element: (
          <LazyLoadingComponent>
            <Role />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.BAI_VIET,
        element: (
          <LazyLoadingComponent>
            <Post />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANG_BAI,
        element: (
          <LazyLoadingComponent>
            <CreatePost />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.PHONG_BAN,
        element: (
          <LazyLoadingComponent>
            <Department />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.NHAN_VIEN,
        element: (
          <LazyLoadingComponent>
            <Employee />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.NHAN_VIEN_ORACLE,
        element: (
          <LazyLoadingComponent>
            <EmployeeOracle />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.SAN_PHAM,
        element: (
          <LazyLoadingComponent>
            <Production />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TEMPLATE_HOP_DONG,
        element: (
            <LazyLoadingComponent>
              <TemplateManagement />
            </LazyLoadingComponent>
        ),
      },
      {
        //Tồn kho
        path: ROUTER.TON_KHO,
        element: (
          <LazyLoadingComponent>
            <ListInventory />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.XAC_NHAN_DON_HANG,
        element: (
          <LazyLoadingComponent>
            <Production />
          </LazyLoadingComponent>
        ),
      },
      {
        path: `${ROUTER.ORDER_DETAIL_ADMIN}/:id`,
        element: (
            <LazyLoadingComponent>
              <OrderAdminDetail />
            </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANH_SACH_DON_HANG,
        element: (
            <LazyLoadingComponent>
              <OrderManagement />
            </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANH_SACH_DON_HANG_CHO_XAC_NHAN,
        element: (
            <LazyLoadingComponent>
              <OrderManagementConfirm/>
            </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANH_SACH_THE,
        element: (
          <LazyLoadingComponent>
            <Tags />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.QUAN_LY_LIEN_HE,
        element: (
          <LazyLoadingComponent>
            <EmployeeContact />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DICH_VU,
        element: (
          <LazyLoadingComponent>
            <Service />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.PHAN_LOAI,
        element: (
          <LazyLoadingComponent>
            <Category />
          </LazyLoadingComponent>
        ),
      },
      {
        path: `${ROUTER.GIAM_GIA}`,
        element: (
            <LazyLoadingComponent>
              <VoucherManagement />
            </LazyLoadingComponent>
        ),
      },
      {
        path: `${ROUTER.MA_GIA}`,
        element: (
            <LazyLoadingComponent>
              <PromoteManagement />
            </LazyLoadingComponent>
        ),
      },
      {
        path: `${ROUTER.TRA_CUU_HOP_DONG}`,
        element: (
            <LazyLoadingComponent>
              <ContractManagement />
            </LazyLoadingComponent>
        ),
      },
      // {
      //   path: `${ROUTER.THEM_TEMPLATE_HOP_DONG}`,
      //   element: (
      //       <LazyLoadingComponent>
      //         <ContractTemplateCreate />
      //       </LazyLoadingComponent>
      //   ),
      // },

      {
        path: `${ROUTER.CA_NHAN}`,
        element: (
            <LazyLoadingComponent>
              <CustomerManagement />
            </LazyLoadingComponent>
        ),
      },
      {
        path: `${ROUTER.QUAN_LY_BANNER_FOOTER}`,
        element: (
            <LazyLoadingComponent>
              <BannerManager />
            </LazyLoadingComponent>
        ),
      },
      {
        path: `${ROUTER.DANH_SACH_CHU_KY}`,
        element: (
            <LazyLoadingComponent>
              <SingatureManagement />
            </LazyLoadingComponent>
        ),
      },

    ],
  },

  //  USER
  {
    element: (
      <LazyLoadingComponent>
        <PrivateRoutes />
      </LazyLoadingComponent>
    ),
    children: [
      {
        path: `${ROUTER.NAP_TIEN}`,
        element: (
          <LazyLoadingComponent>
            <FinanecDashBoard />
          </LazyLoadingComponent>
        ),
      },
      {
        path: `${ROUTER.USER_INFOR}`,
        element: (
            <LazyLoadingComponent>
              <MyAccount />
            </LazyLoadingComponent>
        ),
      },


      {
        path: ROUTER.USER_ORDERS,
        element: (
          <LazyLoadingComponent>
            <OrdersList />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.HOME_PAGE,
        element: (
          <LazyLoadingComponent>
            <HomePage />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.CART_DETAIL,
        element: (
            <LazyLoadingComponent>
              <CartDetail />
            </LazyLoadingComponent>
        ),
      },
      {
        path: `${ROUTER.ORDER_DETAIL}/:id`,
        element: (
            <LazyLoadingComponent>
              <OrderDetail />
            </LazyLoadingComponent>
        ),
      },
      {
        path: `${ROUTER.ORDER_CONTACT}`,
        element: (
            <LazyLoadingComponent>
              <OrderContact />
            </LazyLoadingComponent>
        ),
      },

      // {
      //   path: ROUTER.PRODUCT_LIST,
      //   element: (
      //     <LazyLoadingComponent>
      //       <ProductList />
      //     </LazyLoadingComponent>
      //   ),
      // },
      // {
      //   path: ROUTER.PRODUCT_SHOP,
      //   element: (
      //     <LazyLoadingComponent>
      //       <ProductShopList />
      //     </LazyLoadingComponent>
      //   ),
      // },
      // {
      //   path: ROUTER.PRODUCT_DETAIL,
      //   element: (
      //     <LazyLoadingComponent>
      //       <ProductDetail />
      //     </LazyLoadingComponent>
      //   ),
      // },
    ],
  },

  //  ANONYMOUS
  {
    element: (
      <LazyLoadingComponent>
        <PublicRouters />
      </LazyLoadingComponent>
    ),
    children: [
      {
        path: ROUTER.HOME,
        element: (
          <LazyLoadingComponent>
            <HomePage />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.HOME2,
        element: (
          <LazyLoadingComponent>
            <HomePage2 />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.PRODUCT_LIST,
        element: (
          <LazyLoadingComponent>
            <ProductList />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.PRODUCT_SHOP,
        element: (
          <LazyLoadingComponent>
            <ProductShopList />
          </LazyLoadingComponent>
        ),
      },
      // {
      //   path: ROUTER.CART_DETAIL,
      //   element: (
      //     <LazyLoadingComponent>
      //       <CartDetail />
      //     </LazyLoadingComponent>
      //   ),
      // },
      {
        path: ROUTER.TIN_TUC,
        element: (
          <LazyLoadingComponent>
            <News />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.LIEN_HE,
        element: (
          <LazyLoadingComponent>
            <Contact />
          </LazyLoadingComponent>
        ),
      }
    ],
  },
  // {
  //   path: "*",
  //   element: (
  //     <LazyLoadingComponent>
  //       <NotFound />
  //     </LazyLoadingComponent>
  //   ),
  // },
]

const AppRouter = () => {
  
  /**
   * @description Thực hiện check ký tự tưởng ứng với path router 
   * Để naviagte sang router tương ứng.
   * @author hddung 
   * @returns 
   */
  function getPathBySlug(characterPath) {
    var dataPath = window.location.href.split('/');
    var slugPath = '';
    if(dataPath?.length > 0) {
      slugPath = dataPath[dataPath.length - 1].split(`-${getGuiIdByParamURL(dataPath[dataPath.length - 1])}`)[0];
    }

    return slugPath.endsWith(characterPath) || null;
  }

  /**
   * @description thực hiện get guid từ chuỗi
   * @author hddung  
   * @returns 
   */
  function getGuiIdByParamURL(id) {
    if(id) {
      var regex = /([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/;
      var ketQua = id.match(regex);
      if(!ketQua) return '';
      return ketQua[ketQua.length - 1];  
    }
  }
  
  //Do cần build liên tục router này để có thể gán hàm getPathBySlug bắt ký tự trên url nên mỗi lần khởi tạo router 
  //Bằng navigate sẽ được gán lại để có thể bắt được URL
  // ***** NOTE: đặt những router bắt path bằng function tại đây, xác định đứng element để gán
  const routerNew = [
    {
      // path: `${ROUTER.NOI_DUNG}/:id`,
      path: getPathBySlug(ROUTER_KEY.NOI_DUNG) ? '/:id' : '',
      element: (
        <LazyLoadingComponent>
          <NewsDetail />
        </LazyLoadingComponent>
      ),
    },
    {
      // path: `${ROUTER.PRODUCT_DETAIL}/:id`,
      path: getPathBySlug(ROUTER_KEY.PRODUCT_DETAIL) ? '/:id' : '',
      element: (
        <LazyLoadingComponent>
          <ProductDetail />
        </LazyLoadingComponent>
      ),
    },
    {
      path: getPathBySlug(ROUTER_KEY.DANH_SACH_SAN_PHAM) ? '/:id' : '',
      element: (
        <LazyLoadingComponent>
          <ProductList />
        </LazyLoadingComponent>
      ),
    },
  ];

  const url = window.location.href;
  const renderRouter = useRoutes([
    ...routes.slice(0, routes?.length - 1),
    { 
        element: routes[routes?.length - 1].element, 
        children : routes[routes?.length - 1].children.concat(...routerNew)
    }
  ])

  // Scroll lên đầu khi navigate
  useEffect(() => {
    if(url) {
      document.body.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });  
    }
  }, [url])


  return (
      <>
        {renderRouter}
        <BackTop />
      </>
        )
}
export default AppRouter
