import http from "../index"
import { 
    apiGetListCustomer,
    apiGetTransaction
} from "./urls"

const getListCustomer = body => http.post(apiGetListCustomer, body);
const getTransaction = body => http.post(apiGetTransaction, body);

const MoneyService = { 
    getListCustomer,
    getTransaction
}
export default MoneyService
