import styled from "styled-components";

export const ProductStyled = styled.div`
    border-radius: 4px;
    background-color: #ffffff;
    border: 1px solid #D5DFE4;
    position: relative;
    transition-duration: 0.2s;
    width: 19%;
    height: 500px;
    margin-right: 1.2%;
    margin-top: 40px;
    &:nth-child(5n) {
    margin-right: 0; /* Remove right margin for the third product in each row */
    }
    .discount{
        position: absolute;
        top: 15px;
        left: 15px;
        z-index: 2;
        border-radius: 30px;
        padding: 4px 10px;
        color: #ffffff;
        font-size: 12px;
        line-height: 12px;
        background-color: #FD9636;
    }
    .logo{
        background-color: #DBECE5;
        height: 300px;
        .logo-img{
            position: relative;
            text-align: center;
            width: 100%;
            display: inline-block;
            height: 220px;
            overflow: hidden;
            line-height: 165px;
            margin-bottom: 10px;
            margin-top: 43px;
        img{
            max-height: 100%;
            max-width: 95%;
            vertical-align: middle;
        }
        }
    }
    .review{
        display: block;
        width: 80%;
        margin: auto;
        margin-top: 21px;
        .website{
            p{
                color: #425A8B;
                font-family: "DM Sans", sans-serif;
                font-size: 12px !important;
                line-height: 18px !important;
                font-weight: 400;
            }
        }
        .name{
            p{
                font-family: "DM Sans", sans-serif;
                font-size: 14px !important;
                line-height: 20px !important;
                font-weight: bold !important;
            }
        }
        .rate{
            display: flex;
            align-items: center;
            p{
                margin-bottom:0px;
                color: #8C9EC5;
                font-family: "DM Sans", sans-serif;
                font-size: 12px !important;
                line-height: 18px !important;
                font-weight: 500 !important;
            }
        }
        .price{
            margin-top: 15px;
            .price-sale{
                color: #425A8B;
                font-family: "DM Sans", sans-serif;
                font-size: 20px;
                line-height: 28px;
                font-weight: bold;
            }
            .price-amount{
                text-decoration: line-through;
                font-size: 16px;
                line-height: 24px;
                color: #8C9EC5;
                margin-left: 17px;
                margin-top: 2px;
            }
        }
        .btn-view{
            border: 1px solid gray;
            border-radius: 5px;
            width: 200px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            display: none;
            cursor: pointer;
            p{
                margin-bottom: 0px;
                font-size: 14px;
                line-height: 20px;
                font-weight: bold;
                color: #425A8B;
            }
            &:hover{
                border: 1px solid #425A8B;
                background-color: #425A8B;
                p{
                    color: white;
                }
            }
        }
    }
    &:hover{
        transform: translateY(-2px);
        transition-duration: 0.2s;
        border: 1px solid #bebef7;
        .price-sale,
        .price-amount{
            display: none;
        }
        .btn-view{
            display: flex;
        }
    }
    cursor: pointer;
`