import { useEffect, useState } from "react";
import "../../assets/css/ChatBox/ChatBox.css"
import Button from "../../components/MyButton/Button";
import SvgIcon from "src/components/SvgIcon";
import { Image, Input, Spin, Upload, message as messageAnt } from "antd";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { setChatUserInfor } from "../../redux/appGlobal";
import STORAGE from "src/lib/storage";
import ChatBoxService from 'src/services/ChatBoxService'
import { forEach } from "lodash";
import { setReload, setReloadListChat } from "src/redux/chat";
import ReactDOMServer from 'react-dom/server';
import { PlusCircleOutlined, SendOutlined } from "@ant-design/icons";
import FileService from "src/services/FileService";
import { TYPE_CHAT } from "src/constants/constants";
import { formatDateAndTimeWithCurrentTime } from "src/lib/time";


function ChatBox({user}) {
    const [message, setMessage] = useState('');
    const [pageContent, setPageContent] = useState(1);
    const [listMessData, setListMessData] = useState([]);
    const [oldUser, setOldUser] = useState(null);
    const [totalPageContent, setTotalPageContent] = useState([]);
    const userInfo = JSON.parse(localStorage.getItem(STORAGE.USER_INFO));
    const { signalRConnection } = useSelector(state => state.appGlobal);
    const [isStop, setStop] = useState(false);
    const [scrollByChangePage, setScrollByChangePage] = useState(false);
    const [fileUrl, setfileUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    

    //#region useEffect

    useEffect(() => {
        loadMoreContentChat();
    }, []);

    useEffect(() => {
        if(!userInfo) closeChatBox();
    },[userInfo])

    /**
     * @description thực hiện lấy dữ liệu khi data thay đổi
     * @author hddung
     */
    useEffect(() => {
        if(!user.isRead && user?.roomId){
            readLastMess(user?.roomId, userInfo?.userID);
        }

        if(oldUser?.toUserId != user.toUserId) {
            if(pageContent != 1) {
                setPageContent(1);
            } else {
                getMessageInRoom()
            }
        }
        
    }, [user]);

    /**
     * add event signalR cho chatBox
     */
    useEffect(() => {
        if(Object.keys(signalRConnection).length > 0) {
            signalRConnection.on("SendMessageSuccess", (message) => {
                if(message) {
                    setListMessData((prevState) => [...prevState, ...[message]]);
                }
            })
        }
    }, [signalRConnection]);


    /**
     * @description thực hiện cuộn đến cuối khi list thay đổi
     * @author hddung
     */
    useEffect(() => {
        const totalPages = Math.ceil(totalPageContent / 20);
        if(pageContent && totalPages >= pageContent){
            getMessageInRoom();
        }

    }, [pageContent]);


    /**
     * @description thực hiện cuộn đến cuối khi list thay đổi
     * @author hddung
     */
    useEffect(() => {
        //check scroll do load trang hay do nhắn tin
        if(scrollByChangePage) {
            var box = document.getElementById("chatbox-content");
            box.scrollTo(0, box.offsetHeight);
            setScrollByChangePage(false);
        } else {
            scrollToLastContent();
        }
    }, [listMessData]);

    //#endregion

    //#region function

    /**
     * @description thực hiện upload ảnh
     * @author hddung 
     */
    const uploadFile = async file => {
        try {
        setLoading(true);
        const formData = new FormData();
        formData.append("InsertFileList", file);
        const res = await FileService.uploadFileList(formData);
        if (res?.isError) return;
        setfileUrl(res?.object[0]?.fileUrl);
        } finally {
        setLoading(false);
        }
    }

    const uploadProps = {
        accept: "image/*",
        multiple: false,
        fileList: [],
        beforeUpload: file => {
        if(handleBeforeUpload(file)) {
            uploadFile(file)
            return false
        }
        },
    }

    /**
     * @description đóng thanh chatbox
     * @author hddung
     */
    function closeChatBox() {
        // Thực reset biến redux để đóng thanh chatbox
        dispatch(setChatUserInfor({}));
    }

    /**
     * @description đọc tin nhắn gần nhất trong đoạn chat
     * @author hddung
     */
    async function readLastMess(roomId, userId) {
        // fix tạm userId
        if(roomId) {
            const res = await ChatBoxService.readLastestMessage(roomId, userId);
            if(res?.isError) return
            //sau khi đọc thành công sẽ load lại thông báo vào list danh sách
            dispatch(setReload(true));
            dispatch(setReloadListChat(true));
        }
    }

    /**
     * @description Lấy toàn bộ nội dung trong room chat
     * @author hddung
     */
    async function getMessageInRoom() {
        // fix tạm userId
        await ChatBoxService.getMessageInRoom({
            pageSize: 20,
            currentPage: pageContent,
            roomID: user?.roomId
        }).then( values =>{
            var results = values?.object?.list;
            setStop(results?.length < 20);
            if(oldUser?.toUserId != user.toUserId) {
                results.reverse();
                setOldUser(user);
            } else {
                //Đảo lại mảng và add vào đầu do mảng hiện tại do for chạy từ đầu đến cuối => cũ nhất lên đầu
                results.reverse().push(...listMessData);
            }


            setListMessData(results);
            setTotalPageContent(values?.object?.count);
        })
        .catch(error => {
            // console.log(error);
        });
    }

    /**
     * @description gọi API gửi tin nhắn
     * @author hddung
     */
    async function sendMessage(typeSend) {
        await ChatBoxService.sendMessage(
        {
            content: typeSend == TYPE_CHAT.TEXT ? message : fileUrl,
            toUserId: user?.toUserId,
            toUserName: user?.toUserName,
            contentType: typeSend || 1,
            toRoomId: user?.roomId,
            isSystemMessage: true
        })
        .then( values =>{
            const data = values?.object;

            setListMessData((prevState) => [...prevState, ...[data]]);
            if(typeSend == TYPE_CHAT.TEXT) {
                setMessage('');
            } else {
                setfileUrl('');
            }

            // scrollToLastContent();            
        })
        .catch(error => {
        });
    }

    /**
     * @description Thực hiện render câu chat
     * @author hddung
     * @returns messenger
     */
    function renderContentChat(value) {
        const currentSend = value?.fromUserId == userInfo?.userID;
        const styleIcon = currentSend ? {paddingRight: 4}: {paddingLeft: 4};
        const classIconName = currentSend ? "done-mess-send" : 'done-mess-send-black';
        
        return (
            <div className={"txt-mess-content " + (currentSend ? 'txt-right' : '')}>
                <div className="content">
                    {
                        value?.contentType == TYPE_CHAT.IMAGE
                        ? (<Image src={value?.content} className="content-img-chat" style={{ maxWidth: '250px', maxHeight: '250px', objectFit: 'cover' }}></Image>)
                        : (<p className="mess-content">{value?.content}</p>)
                    }
                    <p className={"mess-content time-send-mess d-flex align-items-center " + (currentSend ? '' : 'txt-color-black')}>
                        {formatDateAndTimeWithCurrentTime(value?.sendTime)} 
                        <SvgIcon name={classIconName} style={styleIcon}></SvgIcon>
                    </p>
                </div>
            </div>
        )
    }

    /**
     * @description thực hiện render tin nhắn và call API gửi tin
     * @author hddung
     */
    function sendMess() {
        if(fileUrl) {
            sendMessage(TYPE_CHAT.IMAGE);
        }

        if(message.trim()){
            sendMessage(TYPE_CHAT.TEXT);
        }
    }

    /**
     * @description lấy content theo page
     * @author hddung
     */
    function loadMoreContentChat() {
        var box = document.getElementById("chatbox-content");
        if(box.scrollTop == 0 && !isStop && listMessData?.length > 0 && box.offsetHeight != box.scrollHeight){
            setScrollByChangePage(true);
            setPageContent(pageContent + 1);
        }

    }

    /**
     * @description sroll đến cuối cùng
     * @author hddung
     */
    function scrollToLastContent() {
        var box = document.getElementById("chatbox-content");
        // luôn cuộn xuống khi gửi tin nhắn để luôn hiển thị được tin nhắn vừa gửi
        box.scrollTo(0, box.scrollHeight);
    }

    /**
     * @description remove file img
     * @author hddung
     */
    function removeFileURL() {
        setfileUrl('');
    }

    /**
     * @description validate file upload
     * @author hddung
     */
    function handleBeforeUpload(file) {
    const fileSize = file.size / 1024 / 1024; // Chuyển đổi dung lượng thành MB
        const isImage = file.type.startsWith('image/');

        if (fileSize > 5) {
            messageAnt.error('Dung lượng ảnh không được vượt quá 5MB!');
            return false; // Ngăn không cho tải lên
        }
        if (!isImage) {
            messageAnt.error('Chỉ được tải lên file ảnh!');
            return false; // Ngăn không cho tải lên
            }

        return true; // Cho phép tải lên
    };

    //#endregion

    return(
        <div class="layout-chatbox">
            <div className="ctn-chatbox">
                <div class="chatbox-header">
                    <div class="content-header-left">
                        <div class="avt-user">
                            <img alt="Ecom" src={user?.avatar} />
                        </div>
                        <div class="txt-user-name">
                            <span>{user?.toUserName}</span>
                        </div>
                    </div>
                    <Button btnType="btn-circle" shape="circle" iconName="remove" className="btn-close-chatbox" onClick={closeChatBox}/>
                </div>
                <div class="chatbox-content" id="chatbox-content" onScroll={loadMoreContentChat}>
                {/* đổ content vào đây */}
                {
                    listMessData?.map((value) => renderContentChat(value))
                }
                </div>
                <div class="chatbox-footer" style={{height: fileUrl ? '256px' : '39px'}}>
                    <div className="ctn-chat-img" style={{display: fileUrl ? 'block' : 'none'}}>
                        <div style={{margin: '0px 5px', padding: '4px 0px', position: 'relative'}}>
                            <Image src={fileUrl} className="content-img-chat" style={{ maxWidth: '150px', height: '100px', objectFit: 'cover' }}></Image>
                            <Button btnType="btn-circle" shape="circle" iconName="remove" className="close-img-chat" onClick={removeFileURL}/>
                        </div>
                    </div>
                    <Spin spinning={loading}>
                    <Input
                        style={{ width: "100%"}}
                        className="ip-chatbox"
                        onChange={value => setMessage(value?.target?.value)}
                        value={message}
                        placeholder="Nhập nội dung"
                        onPressEnter={sendMess}
                        prefix={
                            !fileUrl &&
                            (
                                <PlusCircleOutlined 
                                    style={{ fontSize: '20px',cursor: "pointer" }} 
                                    className="icon-ip-chatbox"
                                    onClick={() => {
                                        document.querySelector('.upload-chatbox .ant-upload input').click();
                                    }}
                                />
                            )
                        }
                        suffix={
                            <SendOutlined 
                                style={{ fontSize: '20px',cursor: "pointer" }} 
                                className="icon-ip-chatbox"
                                onClick={sendMess}
                            />
                        }
                    />
                    </Spin>
                </div>
            </div>

            <Upload className="upload-chatbox" {...uploadProps} style={{display: 'none'}}/>
        </div>
    );
}

export default ChatBox