import { DeleteOutlined } from "@ant-design/icons"
import { Col, Row, Space } from "antd"
import moment from "moment"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import CB1 from "src/components/Modal/CB1"
import ButtonCircle from "src/components/MyButton/ButtonCircle"
import Notice from "src/components/Notice"
import SpinCustom from "src/components/Spin"
import TableCustom from "src/components/Table/CustomTable"
import { SYSTEM_KEY } from "src/constants/constants"
import { getListComboByKey } from "src/lib/utils"
import ROUTER from "src/router"
import PostService from "src/services/PostService"
import Search from "./components/Search"
import TreeCategory from "./components/TreeCategory"
import { PostManagerStyled } from "./styled"
import CreatePost from "src/pages/ADMIN/CreatePost/index"

const PostManager = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [listPost, setListPost] = useState([])
  const [selectedNode, setSelectedNote] = useState()
  const [openPopupPost, setOpenPopupPost] = useState(false);
  const [postID, setPostId] = useState(false);
  const [isInsert, setIsInsert] = useState(false);
  

  const [total, setTotal] = useState()
  const [pagination, setPagination] = useState({
    CurrentPage: 1,
    PageSize: 20,
    Status: 0,
  })
  const [TextSearch, setTextSearch] = useState("")
  const { listSystemKey } = useSelector(state => state.appGlobal)
  const color = ["#FF720D", "#0D99FF", "#FF4648"]
  const listStatus = getListComboByKey(SYSTEM_KEY?.POST_STATUS, listSystemKey)
  const columns = [
    {
      title: "STT",
      dataIndex: "Index",
      key: "Index",
      width: 70,
      align: "center",
      render: (val, record, idx) => (
        <div className="text-center">
          {idx + 1 + pagination.PageSize * (pagination.CurrentPage - 1)}
        </div>
      ),
    },
    {
      title: "Tiêu đề bài viết",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Ngày đăng",
      dataIndex: "publicationTime",
      key: "publicationTime",
      align: "center",
      width: 150,
      render: text => text && moment(text).format("DD/MM/YYYY"),
    },

    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      width: 150,
      align: "center",
      render: (text, record) => {
        const status = listStatus?.find(i => i?.codeValue === text)
        return (
          <div className="d-flex justify-content-space-between align-items-center mh-36">
            <div
              className="text-ellipsis fw-600 text-center"
              style={{ color: color[text - 1] }}
            >
              {status?.description}
            </div>
            <div className="list-button-hover">{renderListButton(record)}</div>
          </div>
        )
      },
    },
  ]

  useEffect(() => {
    getListPost()
  }, [selectedNode, pagination])

  const getListPost = async () => {
    try {
      setLoading(true)

      const res = await PostService.getListPost({
        ...pagination,
        TextSearch,
        categoryPostID: selectedNode?.key,
      })

      var data = res?.object;

      if(!data) return

      setListPost(data?.Data);
      setTotal(data?.Total);
      setIsInsert(data?.IsInsert);
    } finally {
      setLoading(false)
    }
  }
  const deletePost = async postID => {
    try {
      setLoading(true)
      const res = await PostService.deletePost(postID)
      if (res.isError) return
      Notice({
        msg: "Xóa bài viết thành công !",
        isSuccess: true,
      })
      getListPost()
    } finally {
      setLoading(false)
    }
  }
  const renderListButton = record => (
    <Space>
      {!!record?.isUpdate && (
        <ButtonCircle
          title="Cập nhật"
          iconName="edit-green"
          style={{ background: "#DDFEF0" }}
          onClick={() => {
            // navigate(ROUTER.DANG_BAI, {
            //   state: { postID: record?.postID, selectedNode },
            // })
            setPostId(record?.postID);
            setOpenPopupPost(true);
          }}
        />
      )}
      {!!record?.isRemove && (
        <ButtonCircle
          title="Xóa"
          iconName="bin"
          style={{ background: "#FFE9EC" }}
          onClick={() =>
            CB1({
              title: ` Bạn có chắc chắn muốn xoá bài viết
            <strong> ${record?.Title}</strong> không?`,
              icon: <DeleteOutlined color="red" />,
              okText: "Đồng ý",
              onOk: async close => {
                deletePost(record?.postID)
                close()
              },
            })
          }
        />
      )}
      {!!record?.isRepost && (
        <ButtonCircle
          title="Đăng lại"
          iconName="re-post"
          style={{ background: "#EDF6FC" }}
          onClick={async () => {
            try {
              setLoading(true)
              const res = await PostService.rePost(record?.postID)
              if (res.isError) return
              Notice({
                msg: "Đăng lại bài viết thành công !",
                isSuccess: true,
              })
              getListPost()
            } finally {
              setLoading(false)
            }
          }}
        />
      )}
      {!!record?.isRemovePost && (
        <ButtonCircle
          title="Gỡ bài"
          iconName="cancel-post"
          style={{ background: "#FFE9EC" }}
          onClick={async () => {
            try {
              setLoading(true)
              const res = await PostService.cancelPost(record?.postID)
              if (res.isError) return
              Notice({
                msg: "Gỡ bài viết thành công !",
                isSuccess: true,
              })
              getListPost()
            } finally {
              setLoading(false)
            }
          }}
        />
      )}
    </Space>
  )
  return (
    <PostManagerStyled>
      <Row gutter={[16, 16]}>
        <Col className="left-content-admin">
          <div className="title-type-2">Danh sách danh mục</div>
        </Col>
        <Col className="right-content-admin">
          <div className="title-type-2">Danh sách bài viết</div>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col className="left-content-admin">
          <TreeCategory
            selectedNode={selectedNode}
            setSelectedNote={setSelectedNote}
          />
        </Col>
        <Col className="right-content-admin">
          <Search
            setTextSearch={setTextSearch}
            setPagination={setPagination}
            reloadTable={getListPost}
            isInsert={isInsert}
            onAdd={() =>
              navigate(ROUTER.DANG_BAI, {
                state: {
                  selectedNode,
                },
              })
            }
          />
          <SpinCustom spinning={loading}>
            <TableCustom
              isPrimary
              dataSource={listPost}
              columns={columns}
              textEmpty="Không có bài viết"
              sticky={{ offsetHeader: 85 }}
              pagination={{
                hideOnSinglePage: total <= 10,
                current: pagination.CurrentPage,
                pageSize: pagination.PageSize,
                responsive: true,
                total,
                locale: { items_per_page: "" },
                showSizeChanger: total > 10,
                onChange: (page, pageSize) => {
                  setPagination({
                    ...pagination,
                    CurrentPage: page,
                    PageSize: pageSize,
                  })
                },
              }}
              rowKey="postID"
              scroll={{ x: "800px" }}
            />
          </SpinCustom>{" "}
        </Col>
      </Row>

      {
        openPopupPost && (
          <CreatePost 
            open={openPopupPost}
            onOk={() => {}}
            reloadTable={getListPost}
            onCancel={() => {
              setOpenPopupPost(false);
            }}
            PostID={postID}
          />
        )
      }

    </PostManagerStyled>
  )
}

export default PostManager
