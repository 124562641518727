import CustomModal from "../CustomModal";
import {Button, Col, Form, Input, message, Modal, Rate, Row, Upload, UploadFile} from "antd";
import FlInput from "../../FloatingLabel/Input";
import {useEffect, useState} from "react";
import Swal from "sweetalert2";
import {useNavigate} from "react-router-dom";
import FileService from "../../../services/FileService";
import {getBase64} from "../../../lib/utils";
import {PlusOutlined} from "@ant-design/icons";
import http from "../../../services";

export default function FeedBackProductModal({visible, onCancel, refreshData, product_id, orderId,onLoading}) {
    const [form] = Form.useForm()

    const [rating, setRating] = useState(1);
    const [file, setFile] = useState(null);
    const navigate = useNavigate();
    const handleRatingChange = (value) => {
        // Lấy giá trị đánh giá từ người dùng khi họ thay đổi rating
        setRating(value);
    };
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState([]);
    const [isDisabled, setIsDisabled] = useState(true);
    const uploadFile = async (files) => {
        const formData = new FormData()
        formData.append("file", files)
        return  await FileService.uploadFile(formData);
    }
    const isPngOrJpeg = (file) => {
        const allowedExtensions = ['.png', '.jpeg', '.jpg'];
        const fileExtension = file.name.split('.').pop().toLowerCase();
        return allowedExtensions.includes('.' + fileExtension);
    };
    useEffect(()=>{
        if (!fileList||fileList.length==0){
        console.log(fileList);
            setIsDisabled(false);
        }
    },[fileList])

    const handleSubmit = () => {
        onLoading(true);
        form.validateFields().then(async form => {
            onLoading(true);
            // console.log('value', form.reason);
            let link = '';
            if(fileList.length){
                onCancel();
                uploadFile(fileList[0].originFileObj).then(resp => {
                    link = resp.object;
                    const model = {
                        "productID": product_id,
                        "contentRating": form.content,
                        "starRating": rating,
                        "image": link
                    }

                    http.post(`ProductReview/AddReivew?orderId=${orderId}`, model).then(resp => {
                        if(!resp.isError){
                            showSuccess();
                        }
                    }).catch(reason => {
                        throw reason;
                    })

                }).catch(reason => {
                    showError("Đã có lỗi xảy ra");
                    onLoading(false);
                    throw reason;
                })

            }else{
                const model = {
                    "productID": product_id,
                    "contentRating": form.content,
                    "starRating": rating,
                    // "image": link
                }
                http.post(`ProductReview/AddReivew?orderId=${orderId}`, model).then(resp => {
                    if(!resp.isError){
                        showSuccess();
                    }
                }).catch(reason => {
                    throw reason;
                })
            }
        }).catch(reason => {
            onLoading(false);
            // showError("Đã có lỗi xảy ra")
        })
    }
    const showSuccess = () => {
        onCancel();
        Swal.fire({
            icon: 'success',
            title: 'Thành Công',
            text: 'Bạn đã đánh giá đơn hàng thành công!',
        }).then(() => {
            navigate('/orders');
            refreshData();
        });
    };
    const showError = (message) => {
        onLoading(true)
        Swal.fire({
            icon: 'error',
            title: 'Thất bại',
            text: message,
        }).then(() => {

        });
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    };

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setPreviewImage(file.url || (file.preview));
        setPreviewOpen(true);
        const fileName = file.name || "file";
        setPreviewTitle(fileName);
    };
    const beforeUpload = (file) => {
        if (!isPngOrJpeg(file)) {
            message.error('Chỉ chấp nhận file PNG hoặc JPEG!');
            setIsDisabled(true)
            return false;
        }else{
            setIsDisabled(false);
        }
        return true;
    };

    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
        setFileList(newFileList);

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );
    return (
        <>
            <CustomModal
                title={"Đánh giá sản pẩm"}
                visible={visible}
                footer={
                    <div style={{display: "flex", justifyContent: "flex-end"}}>
                        <Button btnType="third" onClick={onCancel}>
                            Huỷ
                        </Button>
                        <Button btnType="primary"  style={{background:'#4096ff',borderColor:'#4096ff',color:"white"}} disabled={isDisabled} onClick={() => handleSubmit()}>
                            Đồng ý
                        </Button>
                    </div>
                }
                width={970}
                onCancel={onCancel}
            >


                    <Form form={form}>
                        <Row>

                            <Col span={24}>
                                <Form.Item name="rating">
                                    <label style={{fontWeight:600,marginRight:4}}>Đánh giá:</label>
                                    <Rate value={rating} onChange={handleRatingChange}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item name="image">
                                    <label style={{ fontWeight: 600, marginBottom: 6 }}>Ảnh đính kèm sản phẩm của bạn :</label>
                                    <>
                                        <Upload
                                            action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                                            listType="picture-card"
                                            fileList={fileList}
                                            onPreview={handlePreview}
                                            onChange={handleChange}
                                            beforeUpload={beforeUpload}
                                        >
                                            {fileList.length >= 1 ? null : uploadButton}
                                        </Upload>
                                        <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                                            <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                        </Modal>
                                    </>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>

                            <Col span={24}>
                                <div style={{marginBottom:6}}>
                                <label style={{fontWeight:600}} aria-required={true}>Nhận xét</label>
                                <span style={{color:"red"}}>*</span>
                                </div>
                                <Form.Item
                                    name="content"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Hãy nhập nhận xét!",
                                        },
                                    ]}
                                >
                                    <Input placeHolder={"Nhận xét"}/>
                                </Form.Item>
                            </Col>
                        </Row>

                    </Form>
            </CustomModal>
        </>
    );
}
