import { Anchor, Avatar, Col, Descriptions, Divider, Form, Input, List, Row, Select, Space, Table, Tooltip, Typography } from "antd"
import { useEffect } from "react"
import { useState } from "react"
import { useSelector } from "react-redux"
import CustomModal from "src/components/Modal/CustomModal"
import Button from "src/components/MyButton/Button"
import Notice from "src/components/Notice"
import SpinCustom from "src/components/Spin"
import TinyEditor from "src/components/TinyEditor"
import { STATUS_OPTION } from "src/constants/constants"
import ContactService from "src/services/ContactService"

const SendMail = ({ detailInfo, onOk, reloadTable, ...props }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    //#region function

    /**
     * @description Thực hiện gửi mail
     * @author hddung
     */
    async function sendMail() {
        try {
            setLoading(true);
            await form.validateFields().then(async (values) => {
                const res = await ContactService.replyContact(detailInfo?.id, {
                    to: detailInfo?.email,
                    subject: values?.subject,
                    body: values?.body
                });
                if(res?.isError) return
                Notice({
                    isSuccess: true,
                    msg: "Gửi mail thành công" 
                });
        
                onOk && onOk();
                props?.onCancel();
                reloadTable();
            })
        } 
        finally {
            setLoading(false);
        }
    }

    //#endregion

    const renderFooter = () => (
        <div className="d-flex justify-content-flex-end">
            <Button
                className="btn-hover-shadow"
                onClick={() => props?.onCancel()}
            >
                Đóng
            </Button>
            <Button
                btnType="primary"
                className="btn-hover-shadow"
                onClick={sendMail}
            >
                Gửi
            </Button>
        </div>
    )

    return (
        <CustomModal
            title={'Gửi mail'}
            footer={renderFooter()}
            width={1000}
            {...props}
        >
            <SpinCustom spinning={loading}>
                <div style={{ maxHeight: '630px', overflowY: 'auto', overflowX: 'hidden', padding: '0px 10px' }}>
                    <Form
                        form={form}
                        layout="vertical"
                    >
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Form.Item
                                    label="Tiêu đề"
                                    name="subject"
                                    rules={[
                                    {
                                        required: true,
                                        message: "Thông tin không được để trống",
                                    },
                                    ]}
                                >
                                    <Input placeholder="Nhập tiêu đề"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Form.Item
                                    label="Nội dung mail"
                                    name="body"
                                    trigger="onEditorChange"
                                    validateTrigger={["onEditorChange"]}
                                    rules={[
                                    {
                                        required: true,
                                        message: "Thông tin không được để trống",
                                    },
                                    ]}
                                >
                                    <TinyEditor setLoading={setLoading} required/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </SpinCustom>
        </CustomModal>
    )
}

export default SendMail