import Button from "../../../../components/MyButton/Button";
import Modal from "react-bootstrap/Modal";
import SignatureCanvas from "react-signature-canvas";
import SpinCustom from "../../../../components/Spin";
import React, {useRef, useState} from "react";
import http from "../../../../services";
import {Col, Form, Input, Row} from "antd";
import {useSelector} from "react-redux";
import Notice from "../../../../components/Notice";


// export const CreateSignature = () => {
//     const [showContract, setShowContract] = useState(false);
//     const handleShowContract = () => {
//         setShowContract(true);
//     }
//     const handleCancelContract = () => {
//         setShowContract(false);
//     }
//     return (
//         <>
//             <Button type="primary" onClick={() => handleShowContract()}>Thêm chữ ký</Button>
//             {
//                 showContract && (
//                     <CreateSignatureModal onCancel={() => handleCancelContract()}
//                                           open={() => handleShowContract(true)}
//                     />
//                 )
//             }
//         </>
//     )
// }

export const CreateSignatureModal = ({onCancel, open,refreshData}) => {
    const [loading, setLoading] = useState(false);
    const sigRef = useRef(null);
    const [signature, setSignature] = useState(null);
    const [content, setContent] = useState(null);
    const handleSignatureEnd = () => {
        setSignature(sigRef.current.toDataURL());
    };
    const {userInfo} = useSelector(state => state?.appGlobal)
    const handleSubmit = async () => {
        form.validateFields().then(values => {
            const model={
                "signatureName": values?.name,
                "signatureImage": content,
                "createUser": userInfo?.userID,
                "createDate": new Date().toISOString()
            }
            setLoading(true);
           http.post("Contract/CreateContractSignature",model).then(resp => {
               if(!resp.isError){
                   Notice({
                       isSuccess: true,
                       msg: 'Tạo mới chữ kí thành công!'
                   });
                   refreshData();
                   onCancel();
               }
               setLoading(false);
           }).finally(() => {
               setLoading(false);
           })
        })
    }

    const handleSave = () => {
        if (signature) {
            const htmlString = `
                    <img class="sign-item-sample" src="${signature}" style="height: 100px; width: auto;" alt="Chữ kí" />`;
            setContent(htmlString);
        }
    }
    const clearSignature = () => {
        sigRef.current.clear();
        setSignature(null);
    };
    const [form] = Form.useForm();

    return (
        <SpinCustom spinning={loading}>
            <div>
                <Modal show={open} onHide={onCancel} closable={false}
                       dialogClassName="cus-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>Chi tiết hợp đồng</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={"cus-modal-body"}>
                        <div className={" container contract-container"}>
                            <div className={"form-ctn__sign"}>
                                <Form
                                    form={form}
                                    layout="vertical">
                                    <Row gutter={[16, 16]}>
                                        <Col span={24}>
                                            <Form.Item
                                                name="name"
                                                label="Nhập tên chữ ký"
                                                required
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Thông tin không được để trống",
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Nhập tên người ký"/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label={"Chữ kí tay"} required={true} name={"signature"}
                                                       rules={[
                                                           {
                                                               required: content==null,
                                                               message: "Thông tin không được để trống",
                                                           },
                                                       ]}
                                            >
                                                <div className={"contract-container"}>
                                                    <div className={"contract-c"}
                                                         dangerouslySetInnerHTML={{__html: content || '<div></div>'}}>

                                                    </div>
                                                </div>

                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label={"Thực hiện kí tay"} className={"d-flex-end"}>
                                                <div className={"conten-sign"}>
                                                    <SignatureCanvas
                                                        penColor="black"
                                                        canvasProps={{className: 'signature'}}
                                                        ref={sigRef}
                                                        onEnd={handleSignatureEnd}
                                                        width={"400"}
                                                    />
                                                    <div style={{display: 'flex',justifyContent:"center"}}>
                                                        <Button disabled={loading} style={{marginRight: 6}}
                                                                onClick={clearSignature}>Làm mới</Button>
                                                        <Button disabled={loading} onClick={handleSave}>Lưu chữ
                                                            kí</Button>
                                                    </div>
                                                </div>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                </Form>
                            </div>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={onCancel} disabled={loading}>
                            Đóng
                        </Button>
                        <Button btnType="primary" variant="primary" disabled={loading} onClick={handleSubmit}>
                            Xác nhận
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </SpinCustom>

    )
}
