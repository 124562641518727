import { Form, Input } from "antd"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import CustomModal from "src/components/Modal/CustomModal"
import Button from "src/components/MyButton/Button"
import Notice from "src/components/Notice"
import { MODE_ACTION } from "src/constants/constants"
import DepartmentSevice from "src/services/DepartmentSevice"

const InsertDepartment = ({ onOk, detailInfo, modeInsertUpdate, reloadTable, ...props }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const {userInfo} = useSelector(state => state?.appGlobal);

    //#region useEffect

    useEffect(() => {
        if(detailInfo && modeInsertUpdate == MODE_ACTION.EDIT) {
            form.setFieldsValue({
                departmentName: detailInfo?.title
            });
        }
    }, [detailInfo, modeInsertUpdate])
    
    //#endregion

    //#region function

    /**
     * @description Thực hiện sự kiện lưu, cập nhật
     * @author hddung
     */
    const onSubmit = async () => {
        try {
        setLoading(true)
        await form.validateFields().then(value => {
            if(modeInsertUpdate == MODE_ACTION.ADD) {
                addDepartment(value);
            } else {
                editDepartment(value);
            }
        });
        } finally {
        setLoading(false)
        }
    }

    /**
     * @description Thực hiện call api cập nhật
     * @author hddung
     */
    async function editDepartment(value) {
        const res = await DepartmentSevice.updateDepartment({
            departmentID: detailInfo?.key,
            departmentName: value?.departmentName,
            level: detailInfo?.level,
            updateUser: userInfo?.username
        });
        if(res?.isError) return 
        Notice({ msg: `Cập nhật phòng ban thành công !` })
        onOk && onOk();
        props?.onCancel();
        reloadTable();
    }

    /**
     * @description Thực hiện call api thêm mới
     * @author hddung
     */
    async function addDepartment(value) {
        const res = await DepartmentSevice.insertDepartment({
            departmentParentID: detailInfo?.key,
            departmentName: value?.departmentName,
            level: detailInfo?.level + 1,
            createUser: userInfo?.username,
            accountID: userInfo?.userID
        });
        if(res?.isError) return
        Notice({ msg: `Thêm phòng ban thành công !` })
        onOk && onOk();
        props?.onCancel();
        reloadTable();
    }

    //#endregion


    const renderFooter = () => (
        <div className="d-flex justify-content-flex-end">
            <Button 
                onClick={() => {props.onCancel()}} 
                loading={loading}
            >
            Đóng
            </Button>
            <Button 
                btnType="primary" 
                onClick={onSubmit} 
                loading={loading}
            >
            {modeInsertUpdate == MODE_ACTION.EDIT ? "Cập nhật" : "Thêm"}
            </Button>
        </div>
    )
    return (
        <CustomModal
        title={modeInsertUpdate == MODE_ACTION.EDIT ? "Cập nhật phòng ban" : "Thêm phòng ban"}
        footer={renderFooter()}
        width={1024}
        {...props}
        style={{padding: '20px 20px 0px 10px'}}
        >
        <span>
            Phòng ban cha: <span className="fw-600">{modeInsertUpdate == MODE_ACTION.EDIT || !detailInfo?.departmentParentName ? detailInfo?.departmentParentName : detailInfo?.title}</span>
        </span>
        <div className="mb-16" />
        <Form form={form} layout="vertical">
            <Form.Item
            name="departmentName"
            label="Tên phòng ban"
            required
            rules={[{ required: true, message: "Thông tin không được để trống" }]}
            >
            <Input placeholder="Nhập tên phòng ban" />
            </Form.Item>
        </Form>
        </CustomModal>
    )
}

export default InsertDepartment
