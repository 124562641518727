import QueryString from "qs"
import {
  apiGetFeatureProduct,
  apiGetAllCategories,
  apiGuestGetListCate,
  apiGetListPostBycategoryPostID,
  apiGetCategoryPost,
  apiGetListPostRelate,
  apiGetDetailByG,
  apiGetListPostByTags,
  apiGetListShopProducts
} from "./urls"
import http from "../index"
const getFeatureProduct = () => http.get(apiGetFeatureProduct)
const getListShopProducts = body => http.post(apiGetListShopProducts, body)
const getAllCategories = () => http.get(apiGetAllCategories)
const getListPost = body => http.post(apiGetListPostBycategoryPostID, body)
const getListPostByTags = body => http.post(apiGetListPostByTags, body)
const guestGetListCate = () => http.get(apiGuestGetListCate)
const getCategoryPost = () => http.get(apiGetCategoryPost)
const getListPostRelate = params => http.get(apiGetListPostRelate, { params })
const getDetail = body => {
  const params = QueryString.stringify(body)
  return http.get(`${apiGetDetailByG}?${params}`)
}
const GuestServices = {
  getFeatureProduct,
  getListShopProducts,
  getAllCategories,
  getListPost,
  guestGetListCate,
  getCategoryPost,
  getDetail,
  getListPostRelate,
  getListPostByTags
}
export default GuestServices
