import { UserOutlined } from "@ant-design/icons"
import { Anchor, Avatar, Col, Divider, Row, Space, Table, Tooltip } from "antd"
import { useEffect, useState } from "react"
import { FloatActionWrapper } from "src/components/FloatAction/styles"
import CB1 from "src/components/Modal/CB1"
import Button from "src/components/MyButton/Button"
import ButtonCircle from "src/components/MyButton/ButtonCircle"
import Notice from "src/components/Notice"
import SpinCustom from "src/components/Spin"
import TableCustom from "src/components/Table/CustomTable"
import Search from "./components/Search"
import { ManageWalletStyle } from "./styled"
import ProductionService from "src/services/ProductionService"
import { SATUS_INVENTORY, STATUS_OPTION } from "src/constants/constants"
import MoneyService from "src/services/MoneyService"
import DetailWaller from "./modal/DetailWaller"

const ManageWallet = () => {
  const [loading, setLoading] = useState(false)

  //hddung
  const [dataBody, setDataBody] = useState({
    pageSize: 10,
    currentPage: 1,
    textSearch: "",
    gender: 0,
    relationshipStatus: 0,
    filterAccBalance: 1, 
    sortBy: 4
  });
  const [listDataSource, setListDataSource] = useState([]);
  const [totalDataSource, setTotalDataSource] = useState(0);

  const [openDetailWaller, setOpenDetailWaller] = useState(false);


  const [detailInfo, setDetailInfo] = useState(null);
  //hddung
  const columns = [
    {
      title: "STT",
      key: "index",
      width: 40,
      align: "center",
      render: (text, row, idx) => (
        <div className="text-center">
          {idx +
            1 +
            dataBody.pageSize * (dataBody.currentPage - 1)}
        </div>
      ),
    },
    {
      title: "Mã người dùng",
      dataIndex: "userID",
      key: "userID",
      width: 200,
      align: "left",
    },
    {
      title: "Tên tài khoản",
      dataIndex: "username",
      key: "username",
      width: 220,
      align: "left",
      render: value => <strong>{value}</strong>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 220,
      align: "left",
    },
    {
      title: "Số điện thoại",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: 100,
      align: "right",
    },
    {
      title: "Số dư tài khoản",
      dataIndex: "accountBalance",
      key: "accountBalance",
      width: 120,
      align: "right",
      render: value => formatCurrency(value),
    },
    
  ]
  
  //#region useEffect

  useEffect(() => {
    if(Object.keys(dataBody).length > 0) {
      getListCustomerWallet();
    }
  }, [dataBody])

  //#endregion

  //#region function

  /**
   * @description Format số tiền
   * @author hddung 
   */
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: 'VND',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(value).replace('VND', 'đ');
  };

  /**
   * @description thực hiện return trạng thái tương ứng status
   * @author hddung 
   */
  function renderTextStatus(type) {
    var str = 'Đã bán',
        color = 'red';

    if(!type) {
      str = 'Chưa bán';
      color = '#2dc258';
    }

    return (
      <div className="text-center text-ellipsis fw-600" style={{color: color}}>
        {str}
      </div>
    )

  }

  /**
   * @description get list danh sách ví
   * @author hddung 
   */
  async function getListCustomerWallet() {
    const res = await MoneyService.getListCustomer(dataBody);
    if(res?.isError) return
    setListDataSource(res?.object?.list);
    setTotalDataSource(res?.object?.count);
  }

  //#endregion

  return (
    <ManageWalletStyle>
      <div>
        <Search
          setDataBody={setDataBody}
          dataBody={dataBody}
        />
        <Divider className="mv-16" />
        <div className="title-type-1 d-flex justify-content-space-between align-items-center pb-16 pt-0 mb-16">
          <div className="fs-24">
            Quản lý ví
          </div>
        </div>
      </div>
      <Anchor
        affix={false}
        getContainer={() => document.getElementById("body-admin-scroll")}
      >
        <Row gutter={[16, 16]}>
          <Col style={{ width: 0 }} flex="auto">
            <SpinCustom spinning={loading}>
              <TableCustom
                isPrimary
                onRow={record => {
                  return {
                    onClick: () => {
                      setDetailInfo(record);
                      setOpenDetailWaller(true);
                    },
                  }
                }}
                className="mb-6"
                dataSource={listDataSource || []}
                columns={columns}
                textEmpty="Không có bản ghi nào!"
                rowKey="id"
                sticky={{ offsetHeader: -12 }}
                scroll={{ y: "100%", x: "800px" }}
                pagination={{ 
                  defaultPage: dataBody.pageSize, 
                  showSizeChanger: true, 
                  pageSizeOptions: ['10', '20', '30'], 
                  total: totalDataSource,
                  onChange: (page, pageSize) => {
                    setDataBody((prevState) => ({
                      ...prevState,
                      pageSize: pageSize || 10,
                      currentPage: page || 1
                    }));
                  },
                }}
              />
            </SpinCustom>
          </Col>
        </Row>
      </Anchor>
      
      { !!openDetailWaller &&
        <DetailWaller
          detailInfo={detailInfo} 
          onCancel={() => {
            setDetailInfo(undefined);
            setOpenDetailWaller(false);
          }}
          onOk={() => {}}
          open={openDetailWaller}
        />
      }

    </ManageWalletStyle>
  )
}

export default ManageWallet