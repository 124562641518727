import QueryString from "qs"
import {
  apiGetAllPostCommnetByType,
  apiPostCommentDelete,
  apiPostCommentInsert,
  apiPostCommentUpdate,
  apiPostCommentUserLike,
} from "./url"
import http from "../index"

const insertComment = body => http.post(apiPostCommentInsert, body)
const getAllPostCommnetByType = params =>
  http.get(apiGetAllPostCommnetByType, { params })

const likeComment = body => {
  const params = QueryString.stringify(body)
  return http.patch(`${apiPostCommentUserLike}?${params}`)
}

const deleteComment = body => {
  const params = QueryString.stringify(body)
  return http.patch(`${apiPostCommentDelete}?${params}`)
}

const updateComment = body => http.put(apiPostCommentUpdate, body)

const CommentServices = {
  insertComment,
  likeComment,
  deleteComment,
  updateComment,
  getAllPostCommnetByType,
}
export default CommentServices
