import {Col, DatePicker, Row, Select} from "antd"
import {useSelector} from "react-redux"
import FlInput from "src/components/FloatingLabel/Input"
import FlSelect from "src/components/FloatingLabel/Select"
import {ORDER_STATUS, RELATTION_STATUS} from "src/constants/constants"
import {SearchStyled} from "../../Production/styled";
import dayjs from "dayjs";
import {Option} from "antd/es/mentions";

export const SearchOrderHistory = ({onChangeKeyword,onFilter,pagination}) => {

    const items: TabsProps['items'] = [
        {
            key: 0,
            label: 'Tất cả',
        },
        {
            key: ORDER_STATUS.CHO_XAC_NHAN,
            label: 'Chờ xác nhận',
        },
        {
            key: ORDER_STATUS.CHO_KY,
            label: 'Chờ ký'
        },
        {
            key: ORDER_STATUS.HOAN_THANH,
            label: 'Hoàn thành',
        },
        {
            key: ORDER_STATUS.DA_HUY,
            label: 'Đã Huỷ',
        },
        {
            key: ORDER_STATUS.DA_DANH_GIA,
            label: 'Đã đánh giá',
        }
    ];
    return (
        <SearchStyled>
            <Row gutter={[16, 16]}>
                <Col flex="auto">
                    <FlInput
                        search
                        allowClear
                        label="Nhập ID đơn hàng hoặc tên sản phẩm"
                        onSearch={e => onChangeKeyword(e)}
                    />
                </Col>
                <Col lg={5} xs={24}>
                    <FlSelect
                        label="Trạng thái"
                        onChange={(e) => {
                            onFilter({...pagination,status:e})
                        }}
                        value={pagination.status}
                    >
                        {
                            items?.map(i=>(
                                <Option key={i.key} value={i.key}>
                                    {i?.label}
                                </Option>
                            ))
                        }
                    </FlSelect>
                </Col>


            </Row>
        </SearchStyled>
    )
}
